import React, { forwardRef, useEffect, useState } from 'react';
import { getDayFromDate, getPlainDate, getMonthFromDate } from '../../../Utils/DateUtils';
import { dateRangeObj } from '../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { AppConstant } from '../../Assests/AppConstant';
import CalenderIcon from '../../Assests/Svg/CalenderIcon';

const DateInput = forwardRef(({ onClick, themeSelected, disabled, dateRangeObj, currentLocation }, ref) => {
    const [startDateStr, setStartDateStr] = useState('');
    const [endDateStr, setEndDateStr] = useState('');

    useEffect(() => {

        if (disabled) {
            setStartDateStr('');
            setEndDateStr('');
        } else {
            if ((currentLocation === 'analytics' || currentLocation === 'reports' || currentLocation === 'report') && dateRangeObj.start !== dateRangeObj.end) {
                setStartDateStr(`${getMonthFromDate(dateRangeObj.start)} ${getDayFromDate(dateRangeObj.start)} ${getPlainDate(dateRangeObj.start)}`);
                if (dateRangeObj.end) {
                    setEndDateStr(` to ${getMonthFromDate(dateRangeObj.end)} ${getDayFromDate(dateRangeObj.end)} ${getPlainDate(dateRangeObj.end)}`)
                }
            } else {
                setStartDateStr(`${getMonthFromDate(dateRangeObj.start)} ${getDayFromDate(dateRangeObj.start)} ${getPlainDate(dateRangeObj.start)}`);
                setEndDateStr('');
            }
        }
    }, [dateRangeObj, currentLocation, disabled])

    return <button data-testid={AppConstant.dataTestId.dateSelection}
        className="d-flex flex-row justify-content-center align-items-center customInput border-1"
        onClick={onClick} ref={ref}>
        {
            disabled ?
                <span data-testid={AppConstant.dataTestId.dayLabel}></span>
                :
                <span data-testid={AppConstant.dataTestId.dayLabel}
                    className={`dayText ${themeSelected}`}>
                    {startDateStr + endDateStr}
                </span>
        }

        <div data-testid={AppConstant.dataTestId.calenderIcon} className="calenderContainer">
            <CalenderIcon themeSelected={themeSelected} />
        </div>
    </button>
})

export default DateInput