import React, { useEffect, useState } from 'react'
import './SSFaq.css'
import ScheduleProWrapper from '../../ScheduleProWrapper'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getSuperAdminSelector } from '../../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import { displayLoader, initiateSearch } from '../../../../../Assests/Utility';
import FaqTableRow from '../../../../LandingPage/Components/HelpSupportAdmin/Components/FAQSection/Component/FaqTableRow/FaqTableRow';
import WarehouseSearch from '../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../Assests/AppConstant';
import NoDataImg from '../../../../../Assests/Images/nodata-xsm.png'
import WarehouseSearchSS from '../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS';

export const SSFaq = () => {
    const dispatch = useDispatch();
    const superAdminUser = useSelector(getSuperAdminSelector);
    const { faqList,isListLoading } = superAdminUser;
    const [faqArr, setFaqArr] = useState([]);
    const [searchStr, setSearchStr] = useState('');

    useEffect(() => {
        if (faqList && faqList.length) {
            setFaqArr(getEnabledFAQs(faqList));
        }
    }, [faqList])

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(getEnabledFAQs(faqList), searchStr, '');
            setFaqArr(searchedArr);
        } else if (searchStr === '') {
            setFaqArr(getEnabledFAQs(faqList))
        }
    }, [searchStr])

    const getEnabledFAQs = (arr) => (
        arr.filter((faqObj) => {
            if (faqObj.EnableStatus) {
                return faqObj
            }
        })
    )

    useEffect(() => {
        getFAQList();
    }, [])

    const getFAQList = () => {
        const apiParams = {
            "CUID": null,
            "EnableStatus": null,
            "CRUDTYPE": "S",
            "QuestionText": null,
            "AnswerText": null,
            "HelpFeedbackID": null,
            "AppType":AppConstant.helpSupportFaq.appTypeText2.value
        }
        dispatch({ type: sagaActions.GET_FAQ_LIST, payload: apiParams });
    }

    const getTableRow = () => {
        return faqArr.map((faqObj, index) => {
            return <FaqTableRow data={faqObj} index={index} key={index} />
        })
    }

    const createFaqTable = () => {
        return (
            <div className='tableContainer-faq-ss'>
                <table>
                    <thead />
                    <tbody>
                        {getTableRow()}
                    </tbody>
                </table>
            </div>
        )
    }
 

    const showNoDataAvailable = () => {
        return (
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <img src={NoDataImg} alt='Opps....' />
                <br />
                <h6>{AppConstant.poManagement.emptyTable.text1}</h6>
            </div>
        )
    }
  return (
    <ScheduleProWrapper showAppointments={false} showNavBar={true}>
    <div className="my-subscription-ss-wrapper p-3">
    <div className='container-fluid faqSection'>
            <div className='d-flex justify-content-between faq-header-ss'>
                <h4>{AppConstant.helpSupportFaq.faqTitle}</h4>
                <div className='w-25 my-3 me-4 '>
                    <WarehouseSearchSS
                        placeholder={"Search FAQ's"}
                        inputValue={searchStr}
                        cancelSearch={() => setSearchStr('')}
                        onChangeHandler={(text) => setSearchStr(text)} />
                </div>
            </div>
            {faqArr && faqArr.length && !isListLoading ? createFaqTable() : isListLoading?displayLoader('light-mode',50,10):showNoDataAvailable()}
        </div>
        </div>
        </ScheduleProWrapper>
  )
}
