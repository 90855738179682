import {Uchain} from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { addSuccess, addFailure,initiateLoading,resetData } from '../redux/reducers/reducerSlices/GetTimeZoneReducer';
  function* getTimeZoneRequestHandler(data){
    try{
      yield put(initiateLoading());
     const response = yield call(Uchain.getTimeZoneDetails);
      yield put(addSuccess(response));
    }catch(error){
     yield put(addFailure(error));
    }
}
export function*  GetTimeZoneRequest() {
  yield takeEvery(sagaActions.GET_TIME_ZONE_DATA, getTimeZoneRequestHandler)
}



