import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Loader = (props) => {
    const { height, count, highlightColor, baseColor } = props;
    return (
        
        <Skeleton height={height} count={count} highlightColor={highlightColor} baseColor={baseColor} />
      );
}
 
export default Loader;