import React from 'react'
import { ReactComponent as LoginBg } from '../../Assests/Svg/loginBg.svg';
    import { renderToStaticMarkup } from 'react-dom/server';

const getLoginBg = () => {
    const svgString = encodeURIComponent(renderToStaticMarkup(<LoginBg />))
    return `url("data:image/svg+xml,${svgString}")`
}
const validateEmail = (input) => {
    const Emailcheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return Emailcheck.test(input)
}

const passwordValidation = (input) => {
    const checkPass = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    return checkPass.test(input)
}

const isValid = (type, data) => {
    switch (type) {
        case 'email':
            if (validateEmail(data) || data.length === 0) {
                return true
            }
            return false    
        case 'password':
                if (passwordValidation(data) || data.length === 0) {
                    return true
                }
                return false    
        default:
            break;
    }
}


export { getLoginBg, isValid }