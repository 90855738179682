import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    arrangeOrder: 'asc',
    arrangeKey: 'CreatedOn',
    isLoading: false,
    operationObj: {},
    gatePassData: null,
    gatePassDockList: [],
    warehouseListForGatePass: [],
    isWarehouseLoading: false,
}

export const GatePassReducer = createSlice({
    name: 'GatePassReducer',
    initialState,
    reducers: {
        getGatePassListSuccess: (state, action) => {
            state.isLoading = true;
            state.gatePassData = action.payload;
            state.initateLoading = false;
        },
        getGatePassListFailure: (state, action) => {
            state.isLoading = false;
            state.gatePassData = null;
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        updateOperationObj: (state, action) => {
            state.operationObj = action.payload
        },
        initiateGatePassDockList: (state) => {
            state.isLoading = true
        },
        clearGatePassError : (state) => {
            state.error = null
        },
        getGatePassDockListSuccess: (state, action) => {
            state.isLoading = false
            state.gatePassDockList = action.payload;
        },
        getGatePassDockListFailure: (state, action) => {
            state.isLoading = false
            state.error = action.payload;
        },
        clearGatePassDockList: (state) => {
            state.gatePassDockList = [];
        },
        clearError: (state) => {
            state.error = null
        },
        initiateWarehouseListForGatePass: (state) => {
            state.isWarehouseLoading = true
        },
        getWarehouseListForGatePassSuccess: (state, action) => {
            state.isWarehouseLoading = false
            state.warehouseListForGatePass = action.payload;
        },
        getWarehouseListForGatePassFailure: (state, action) => {
            state.isWarehouseLoading = false
            state.error = action.payload;
        },
        clearWarehouseListForGatePass: (state) => {
            state.warehouseListForGatePass = [];
        },
        clearWhError: (state) => {
            state.error = null
        },
        updateIsWarehouseLoading: (state, action) => {
            state.isWarehouseLoading = action.payload
        },
    }
});

export const { getGatePassListSuccess, getGatePassListFailure, updateIsLoading, updateOperationObj, initiateGatePassDockList, getGatePassDockListSuccess, getGatePassDockListFailure, clearGatePassDockList, clearError,initiateWarehouseListForGatePass, getWarehouseListForGatePassSuccess, getWarehouseListForGatePassFailure, clearWarehouseListForGatePass, clearWhError, clearGatePassError, updateIsWarehouseLoading } = GatePassReducer.actions;

// Selectors
export const getGatePassselector = (state) => {
    return state.GatePassReducer;
};

export default GatePassReducer.reducer;