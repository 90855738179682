import React, { useState } from 'react'
import { AppConstant } from '../../Assests/AppConstant';
import CustomButton from '../Common/CustomButton/CustomButton';
import SimpleDatePicker from '../DatePicker/SimpleDatePicker';
import Header from '../Header/Header';
import DownloadSectionBE from '../LandingPage/Components/DownloadSection/DownloadSectionBE';
import Navigationbar from '../Navigationbar/Navigationbar';
import WarehouseSearch from '../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import Detailed from './Detailed/Detailed';
import './ScheduleUpload.css'
import Summary from './Summary/Summary';
//
/**
 * Schedule Upload Wrapper component
 * @param {*} props 
 * @returns 
 */
const ScheduleUpload = (props) => {
    const [view, setView] = useState('S');
    const [searchStr, setSearchStr] = useState('');

    /**
     * Method is used to return view bases on selection
     */
    const getSelectedView = () => {
        if (view === 'S') {
            return <Summary />
        } else if (view === 'D') {
            return <Detailed searchStr={searchStr} />
        }
        return <></>
    }

    /**
     * Method is used to return summary button status
     * @returns 
     */
    const isSummaryButtonDisabled = () => view === 'S'

    /**
     * Method is used to return detailed button status
     * @returns 
     */
    const isDetailedButtonDisabled = () => view === 'D'

    /**
     * Method is used to return search component
     */
    const getSearchComponent = () => {
        return <div className='searchComp'>
            < WarehouseSearch
                placeholder={'Search'}
                inputValue={searchStr}
                cancelSearch={() => setSearchStr('')}
                onChangeHandler={(text) => setSearchStr(text)} />
        </div>
    }

    return (
        <>
            <div className='m-1 '>
                <Header {...props} />
                <Navigationbar {...props} />
            </div>
            <div className='m-4 d-flex justify-content-between'>
                <div>
                    <CustomButton
                        disabled={isSummaryButtonDisabled()}
                        isLoading={false}
                        title={AppConstant.smartScheduler.scheduleUpload.summary}
                        className={`addButton mx-1 ${view === 'S' ? '' : 'disabledActionColor'}`}
                        onClick={() => {
                            setView('S')
                        }} />
                    <CustomButton
                        disabled={isDetailedButtonDisabled()}
                        isLoading={false}
                        title={AppConstant.smartScheduler.scheduleUpload.detailed}
                        className={`addButton mx-1 ${view === 'D' ? '' : 'disabledActionColor'}`}
                        onClick={() => {
                            setView('D')
                        }}
                    />
                </div>
                <div className='d-flex flex-row'>
                    <SimpleDatePicker />
                    {
                        view === 'D' &&
                        getSearchComponent()
                    }
                </div>
            </div>
            {getSelectedView()}
        </>
    );
}

export default ScheduleUpload;