import { call, put, takeEvery } from 'redux-saga/effects';
import {Uchain} from '../redux/api/agent';
import { sagaActions } from './sagaActions';
import {getAllCommentsReducer, addCommentsReducer, addCommentFailure, clearAllComments} from '../redux/reducers/reducerSlices/CommentsReducerSlice'

  function* getAllCommentsSaga(data) {
    try {
      const response = yield call(Uchain.getComments, data.payload);
      yield put(getAllCommentsReducer(response));
    } catch (error) {
      yield put(clearAllComments());
    }
  }

  function* addCommentSaga(data) {
    try {
      const response = yield call(Uchain.addComment, data.payload);
      if(response === 'Success'){
        const paramsObj = {
          'notes_text' : data.payload.notes,
          'created_by' : data.payload.created_by,
          'create_date' : new Date().toISOString()
        };
        yield put(addCommentsReducer(paramsObj));
      } 
    } catch (error) {
      yield put(addCommentFailure());
    }
  }

  function* clearCommentsSaga() {
    try {
      yield put(clearAllComments());
    } catch (error) {
      
    }
  }
  

  export function* getAllComments() {
    yield takeEvery(sagaActions.GET_ALL_COMMENTS, getAllCommentsSaga)
  }

  export function* addComments() {
    yield takeEvery(sagaActions.ADD_COMMENT, addCommentSaga)
  }

  export function* clearComments() {
    yield takeEvery(sagaActions.CLEAR_COMMENTS, clearCommentsSaga)
  }