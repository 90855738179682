import UchainAxios from "./UchainAxios";
import FSAxios from "./FSAxios"
let myInterceptor;

function interceptor(accessToken) {
    myInterceptor = UchainAxios.interceptors.request.use(
        request => {
            if (accessToken) {
                // request.headers['x-api-key'] = accessToken;
                request.headers['Authorization'] = `Bearer ${accessToken}`;
            }
            return request;
        },
        error => {
            return Promise.reject(error);
        }
    );
}

function fsInterceptor(apiKey) {
    FSAxios.interceptors.request.use(
        request => {
            if (apiKey) {
                request.headers['x-api-key'] = apiKey
            }
            return request;
        },
        error => {
            return Promise.reject(error);
        }
    );
}

const removeInterceptor = () => UchainAxios.interceptors.request.eject(myInterceptor)

export { removeInterceptor, interceptor, fsInterceptor };