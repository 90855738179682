import React from 'react';
import Header from '../../../../Header/Header';
import Navigationbar from '../../../../Navigationbar/Navigationbar';
import UserRoleAdminScreen from './Components/UserRoleAdminScreen'

const UserRoleAdmin = (props) => {
    const { themeSelected } = props;
    return (
        <div className='container-fluid m-1'>
            <Header {...props} />
            <Navigationbar {...props} />
            <UserRoleAdminScreen className='mt-3' themeSelected={themeSelected} />
        </div>
    )
}

export default UserRoleAdmin;