// LeftInfoWithFormContainer
import React from "react";
import { AppConstant } from "../../../../Assests/AppConstant";
import "./LeftInfoWithFormContainer.css";

/** Component to render Static information on the left side of screenr in Carrier Sign up component */
const LeftInfoWithFormContainer = ({ formName, closeHelpModal, displayComponent, warehouseLogo }) => {
    const getIntroBulletsList = () => {
        const arr = [
            'Real Time Digital Appointment Scheduling',
            'Allows for Modifications, Updates and Provides Schedule Visibility',
            'Scalable Plug & Play Deployment',
            'Fully Customer Configurable Scheduling Specific to Facility Capabilities and Needs',
            'Allows for Dock segmentation',
            'Includes a Feature to Preset Reoccurring Appointment',
            'Allows for Users with Managed Permission Levels',
            'Local and Enterprise Visibility',
            'Auto Generated Email Confirmation of Scheduled Appointments and Changes'

        ]
        return (
            arr.map((ele, i) => {
                return (
                    <li className="fw-bold text-start m-0 p-0 lh-lg">{ele}</li>
                )
            })
        )
    }
    return (
        <>
            <div className="">
                <span className="hero-text">{formName}</span>
                <div className="row">
                    <div className="col-md-5 col-sm-12 m-0 p-0">
                        <div className="cust-support">
                            <div data-testid={AppConstant.dataTestId.logoContainer}
                                className='d-flex justify-content-center align-items-center logoContainerCarrier'>
                                <img className="login-logo" src={AppConstant.commonStrings.schedulerProLogo} />
                            </div>
                        </div>
                        <hr className="horizontal-ruler" />
                        <div className="tech-support">
                            <div className='info-sub-headings landingScreenContainerSignUp'>
                                <div className="d-flex m-0 p-0 ">
                                    <div className="infoContainer1 lh-lg ">
                                        <b>FreightSmith SchedulePro</b> is the ultimate solution for streamlining and simplifying warehouse appointment scheduling – the dynamic, yet cost-effective tool designed by experts in inbound warehouse operations refines the inbound delivery experience. FreightSmith SchedulePro is designed to meet the needs of warehouses of any size, while offering scalability and customization so businesses can optimize their receiving performance. Its easy-to-use interface allows carriers, shippers, and receivers to simplify communications and create accountability which builds a more structured process to reduce costs and minimize frustrations.
                                    </div>
                                </div>
                                <br />
                                <p className='d-flex'>
                                    For customer support, contact
                                    <a className="ms-1 cursor-pointer" href={'mailto:info@freightsmith.net'}>info@freightsmith.net</a>
                                    &nbsp;or&nbsp;
                                    <div className="d-flex">call <a className="text-dark ms-1 fw-bold contactNum" href="tel:+1.844.586.7729">1.844.586.7729</a></div>
                                </p>
                                <div className='seperatorLandingSignUp' />
                                <p className=''>
                                    The FreightSmith suite of products delivers instant ROI by removing delays in communication, expediting activity execution by digitizing operational processes, and providing delivery performance analytics at warehouses and distribution centers.
                                </p>
                                <p>Check out all FreightSmith products here:</p>
                                <ul>
                                    <li className='ms-4'><a target="_blank" className="ms-1 cursor-pointer" href={'https://freightsmith.net/ims/'}>FreightSmith IMS</a></li>
                                    <li className='ms-4'><a target="_blank" className="ms-1 cursor-pointer" href={'https://freightsmith.net/app/'}>FreightSmith Mobile App</a></li>
                                    <li className='ms-4'><a target="_blank" className="ms-1 cursor-pointer" href={'https://freightsmith.net/schedulepro/'}>FreightSmith SchedulePro</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-1 vb"></div>
                    <div className="col-md-6 col-sm-12 m-0 p-0">
                        {displayComponent()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LeftInfoWithFormContainer;