import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLoadSelector } from '../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import HeaderLoadStatusOverview from './Components/LoadStatusOverviewHeader/HeaderLoadStatusOverview';
import StatusDescriptionText from './Components/StatusOverViewContent/StatusOverviewComponents/StatusDescriptionText/StatusDescriptionText';
import { calculateTimeDifference } from '../../../../../Assests/Utility';
import { AppConstant } from '../../../../../Assests/AppConstant';

const LoadStatusOverview = (props) => {
    const getLoad = useSelector(getLoadSelector);
    const { selectedLoad } = getLoad;
    const { themeSelected } = props;
    const [stageArr, setStageArr] = useState([]);
    const [totalDwellTime, setTotalDwellTime] = useState('');
    const [isTruckLate, setIsTruckLate] = useState(false)

    const {
        appointment_time,
        check_in_time,
        check_in_to_in_door,
        in_door_time,
        in_door_to_lump_start,
        lump_start_time,
        lump_start_to_lump_end,
        lump_end_time,
        lump_end_to_bills_out,
        bills_out_time,
        truck_load_status,
    } = selectedLoad;

    useEffect(() => {
        setStage();
        const checkIfTruckLate = calculateTimeDifference(appointment_time, check_in_time);
        if(checkIfTruckLate.includes('-') || checkIfTruckLate === '0:00'){
            setIsTruckLate(false)
        }else{
            setIsTruckLate(true)
        }
    }, [selectedLoad]);

    const setStage = () => {

        const appointment_to_check_in = check_in_time && appointment_time ? calculateTimeDifference(appointment_time, check_in_time) : null;

        const stageNameArray = [AppConstant.loadManagement.statusOverview.appointmentTitle,
            AppConstant.loadManagement.statusOverview.checkinTitle,
            AppConstant.loadManagement.statusOverview.doorAssignmentTitle,
            AppConstant.loadManagement.statusOverview.unloadingStartTitle,
            AppConstant.loadManagement.statusOverview.unloadingEndTitle,
            AppConstant.loadManagement.statusOverview.releasedTitle];

        const stageTimeArray = [appointment_time, check_in_time, in_door_time,
            lump_start_time, lump_end_time, bills_out_time];

        const timeSpentArray = [appointment_to_check_in, check_in_to_in_door,
            in_door_to_lump_start, lump_start_to_lump_end, lump_end_to_bills_out];

        if (bills_out_time && check_in_time){
            const appointment_to_released = bills_out_time && appointment_time ? calculateTimeDifference(appointment_time, bills_out_time) : null;
            setTotalDwellTime(appointment_to_released);
        }

        const statusOverViewObjArray = stageNameArray.filter((ele) => truck_load_status !== undefined).map((stage, index) => {
            return {
                stageName: stage,
                stageInitTime: stageTimeArray[index] ? stageTimeArray[index] : '',
                stageTimeSpent: timeSpentArray[index] ? timeSpentArray[index] : '',
                currentStage: truck_load_status,
                currentStageIndex: stageNameArray.indexOf(truck_load_status),
                thisStage: index,
                showLine: index < 5 ? true : false,
            }
        })

        truck_load_status ? setStageArr(statusOverViewObjArray) : setStageArr([])
    }

    return (
        <div>
            <div className='d-flex justify-content-between'>
                <HeaderLoadStatusOverview themeSelected={themeSelected} />
                {(appointment_time && bills_out_time) ? <div className='text-end me-2 my-0 headerLoadStatusOverview'>{AppConstant.loadManagement.statusOverview.totalDwellTimeTitle} {totalDwellTime} hrs</div> : ''}
            </div>
            <div className='m-2 d-flex'>
                {
                    stageArr.map((stage) =>
                    (
                        <div className='flex-grow-1' key={stage.stageName}>
                            <StatusDescriptionText stage={stage} themeSelected={themeSelected} isTruckLate={isTruckLate} />
                        </div>
                    ))
                }
            </div>
            
        </div>
    );
}

export default LoadStatusOverview;