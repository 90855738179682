import React from 'react';
import Header from '../../../../Header/Header';
import Navigationbar from '../../../../Navigationbar/Navigationbar';
import CompanyGroupAdminScreen from './Components/CompanyGroupAdminScreen/CompanyGroupAdminScreen';


const CompanyGroupAdmin = (props) => {

    const { themeSelected } = props;

    return (
        <div className='container-fluid m-1'>
            <Header {...props} />
            <Navigationbar {...props} />
            <CompanyGroupAdminScreen className='mt-3' themeSelected={themeSelected} />
        </div>
    )
}

export default CompanyGroupAdmin;