import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultDashboardSelector } from '../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import DefaultDashboardWrapper from '../../Components/Dashboard/DefaultDashboard/Wrapper/DefaultDashboardWrapper';
import PODockStatusFilter from '../../Components/POFilters/PODockStatusFilter/PODockStatusFilter';
import CustomDashboard from '../../Components/Dashboard/CustomDashboard/CustomDashboardWrappper';
import _  from 'lodash';
import './Dashboard.css';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { shouldHavePermission } from '../../../../Assests/Utility';

const Dashboard = (props) => {
    const { themeSelected } = props;
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { dashboardType } = defaultDashboard;
    const userPermission = useSelector(getUserSelector);
    const { permissions } = userPermission;
    const [allowPermissionD, setAllowPermissionD] = useState(false)
    const [allowPermissionC, setAllowPermissionC] = useState(false)

    useEffect(() => {
        if(permissions.length){
            const warehouseManagementPermission = _.find(permissions, { 'FeatureID': '8', 'PageAccess': true });
            if(warehouseManagementPermission && !_.isEmpty(warehouseManagementPermission)){
                setAllowPermissionD(true);
                setAllowPermissionC(true);
            }else{
                let tempD = shouldHavePermission(permissions, '601')
                setAllowPermissionD(tempD);
                let tempC = shouldHavePermission(permissions, '602')
                setAllowPermissionC(tempC);
            }            
        }

    },[])
    
    return (
        <div className='container-fluid executor'>
            <Header {...props} />
            <Navigationbar {...props}/>
            <PODockStatusFilter themeSelected={themeSelected} showOnlyDockFilters={true} showDashboardBtn={true} allowPermissionD={allowPermissionD} allowPermissionC={allowPermissionC} />
            {
                dashboardType === 'Default' ? <DefaultDashboardWrapper themeSelected={themeSelected} /> :
                    <CustomDashboard themeSelected={themeSelected} />
            }
        </div>
    )
}

export default Dashboard;