import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedFiltersSelector, getSelectedDockFilterListSelector, getSelectedStatusFilterListSelector, } from '../../../../../../../../redux/reducers/reducerSlices/FiltersReducerSlice';
import { getTruckSelector } from '../../../../../../../../redux/reducers/reducerSlices/getTruckReducer';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { ReactComponent as CrossIcon } from '../../../../../../../Assests/Svg/cross-primary-blue.svg';
import { ReactComponent as CrossIconDark } from '../../../../../../../Assests/Svg/cross-icon-primary-dark.svg';
import { applyFilters, createStatusFilterList } from '../../../FilterUtilities'
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import _ from 'lodash-es';
import './SelectedDockStatusFilters.css';
import { getSelectedDateSelector } from '../../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';

const SelectedDockStatusFilters = (props) => {
    const dispatch = useDispatch();
    const { themeSelected } = props
    const selectedFiltersList = useSelector(getSelectedFiltersSelector);
    const selectedDockFiltersList = useSelector(getSelectedDockFilterListSelector);
    const selectedStatusFiltersList = useSelector(getSelectedStatusFilterListSelector);
    const getTruck = useSelector(getTruckSelector);
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { truckListMasterData, truckList } = getTruck;


    useEffect(() => {
        updateStatusFiltersCount()
    }, [truckList, selectedDockFiltersList, selectedStatusFiltersList, selectedFiltersList])

    const updateStatusFiltersCount = () => {
        if (_.isEqual(selectedDockFiltersList, selectedFiltersList) && selectedFiltersList.length && selectedDockFiltersList.length) {
            // Dock only
            const statusFilterArr = createStatusFilterList(truckList);
            dispatch({ type: sagaActions.SET_STATUS_FILTERS_LIST, payload: statusFilterArr });
        } else if (_.isEqual([...selectedDockFiltersList, ...selectedStatusFiltersList].sort(), [...selectedFiltersList].sort()) && selectedFiltersList.length && selectedDockFiltersList.length && selectedStatusFiltersList.length) {
            // dock and status
            const dockFiltersArr = applyFilters(selectedDockFiltersList, truckListMasterData);
            const statusFilterArr = createStatusFilterList(dockFiltersArr);
            dispatch({ type: sagaActions.SET_STATUS_FILTERS_LIST, payload: statusFilterArr });
        } else {
            // none OR Status only
            const statusFilterArr = createStatusFilterList(truckListMasterData);
            dispatch({ type: sagaActions.SET_STATUS_FILTERS_LIST, payload: statusFilterArr });
        }
    }

    const updateTruckArray = (filtersArr) => {
        const filterList = applyFilters(filtersArr, truckListMasterData);
        dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
        if (filterList.length !== 0) {
            dispatch({ type: sagaActions.UPDATE_TRUCK, payload: filterList })
        } else {
            dispatch({ type: sagaActions.UPDATE_TRUCK, payload: truckListMasterData })
        }
    }

    const deleteFilters = (ele) => {

        const currentList = [...selectedFiltersList];
        let updatedArray = currentList.filter((currentElement) => {
            return currentElement === ele ? null : currentElement;
        })
        dispatch({ type: sagaActions.DELETE_FILTER, payload: updatedArray });

        if (selectedDockFiltersList.includes(ele)) {
            const dockArr = selectedDockFiltersList.filter(e => e !== ele);
            dispatch({ type: sagaActions.SELECTED_DOCK_FILTERS_LIST, payload: dockArr });
        } else {
            const statusArr = selectedStatusFiltersList.filter(e => e !== ele);
            dispatch({ type: sagaActions.SELECTED_STATUS_FILTERS_LIST, payload: statusArr });
        }

        updateTruckArray(updatedArray);
    }

    const renderSelectedFilters = () => {
        return selectedFiltersList && selectedFiltersList.map(ele => {
            return <span
                key={ele}
                className={`container-fluid rounded-4 selectedFilterItem ${themeSelected}-selectedFilters lh-lg me-3 py-1 px-3`}>
                {ele.replaceAll('_', ' ')}

                {themeSelected === AppConstant.commonStrings.lightModeFilterString ?
                    <CrossIcon className='ms-1 my-1' data-testid={AppConstant.dataTestId.removeFilterButtonTestId}
                        onClick={() => deleteFilters(ele)} /> :
                    <CrossIconDark className='ms-1 my-1 crossIconSelectedFilters' data-testid={AppConstant.dataTestId.removeFilterButtonTestId}
                        onClick={() => deleteFilters(ele)} />
                }
            </span>
        })
    }

    return (
        <div className={`selectedDockStatusFilters mt-2 ${selectedFiltersList.length === 0 ? 'setBorderTop' : 'setFullBorder'}`}>
            {renderSelectedFilters()}
        </div>
    )
}

export default SelectedDockStatusFilters;