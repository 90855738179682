import React from 'react';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import HelpSupportFaq from './HelpSupportFaq/HelpSupportFaq';

const HelpSupportAdmin = (props) => {

    return (
        <div>
            <Header {...props} />
            <Navigationbar {...props} />
            <HelpSupportFaq {...props}/>

        </div>
    )
}

export default HelpSupportAdmin;