import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import './CarrierApptsListScreen.css';
import SendEmail from '../../../../sendEmail';
import Popup from '../../../../Common/Popup/Popup';
import { AppConstant } from '../../../../../Assests/AppConstant';
import CarrierApptListRows from './CarrierApptListRows/CarrierApptListRows';
import { smartScheduleSelector } from '../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { useDispatch } from 'react-redux';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
// @ts-ignore
import { useNavigate } from 'react-router-dom';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import InitalAppointmentSelectionScreen from '../../BookAppointments/Components/InitalAppointmentSelectionScreen/InitalAppointmentSelectionScreen';
import InitalCheckInForm from '../InitalCheckInForm';
import { getDriverCheckinReducer } from '../../../../../../redux/reducers/reducerSlices/DriverCheckinReducer';

const GuardsApptsListScreen = (props) => {

    const { data, themeSelected = 'light-mode', screenTypeClassname,selectedRowData ,  isCheckIn=true,inputData} = props;
    const driverData = useSelector(getDriverCheckinReducer);
    const [showSendMailModal, setShowSendEmailModal] = useState(false);
    const [selectedPoObj, setSelectedPoObj] = useState({});
    const [scheduleScreenType, setScheduleScreenType] = useState('');
    const ss = useSelector(smartScheduleSelector);
    const { searchedPOData } = ss;
    const user = useSelector(getUserSelector);
    const { userType } = user;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [showSuccess,setShowSuccess]=useState(false);
    const [selectedData,setSelectedData]=useState();
    const { branding } = user;
    const { Name, Zip } = branding
    useEffect(() => {
        if (_.size(searchedPOData)) {
            setScheduleScreenType('text')
        }
    }, [searchedPOData])
    
    useEffect(()=>{
        if(data.loads){
            setSelectedData(data.loads[0]?.pos.filter(e=>e.po_number===props.inputData.po));   
        }
        
    },[data])
    

   
    const ssSchedulehandler = (rowData) => {
        setShowSuccess(false);
     
     
    }

    const renderApptTableRows = () => {
        return  data.loads.map((ele, rowIndex) => {
            return (<CarrierApptListRows
                rowIndex={rowIndex}
                rowData={ele}
                triggerReCalculate={false}
               warehouseData={ data.warehouseData}
                ssSchedulehandler={(rowData) => ssSchedulehandler(rowData)}
                inputData={props.inputData} 
            />)
        })
    }

 

  

  

  
    const renderText = () => {
        if (_.size(data.loads)) {
            const masterPO = data.loads[0];
            if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Not Scheduled')) {
                return 'Click on Schedule to proceed'
            } else if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Scheduled')) {
                return 'Click on Reschedule to proceed'
            } else if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Completed')) {
                return 'Appointment is already completed'
            }
        }
    }

 
    const showAppointmentsList = () => {
        return (
            <div className={screenTypeClassname}>
               
                    <div className="p-0 rounded">
                        <table>
                            <thead />
                            {props.callReset===false && data&& renderApptTableRows()}
                        </table>
                    </div>
                    {/* <div className="col-6 mx-3 boxShadow-1">
                   {props.callReset===false && selectedData&&<InitalCheckInForm showSuccess={showSuccess} setShowSucces={setShowSuccess}  warehouseData={ data.warehouseData} DriverDetails={ data.DriverDetails} selectedRowData={selectedData[0]}    inputData={props.inputData}   clearScreen={props.clearScreen}/>}
                    </div> */}
                </div>
           

        )
    }

    const getColorIndicator = (colorString, textStr) => {
        return (
            <div className='d-flex justify-content-center align-items-center' >
                <div className=' colourIndicatorPOLookup' style={{ backgroundColor: colorString }} />
                <>{`${textStr}`}&nbsp;&nbsp;</>
            </div>
        )
    }

    const getColorsArr = () => {
        const colorsArr = [
            {
                name: 'Reschedule',
                color: '#CC4141'
            },
            {
                name: 'Not Scheduled',
                color: '#f77f00'
            },
            {
                name: 'Past Appointment',
                color: '#89DA96'
            },
        ]

        return (
            colorsArr.map((ele, i) => {
                return getColorIndicator(ele.color, ele.name)
            })
        )
    }


    const showNoDataError = () => {
        return (

            <div className='d-flex flex-column align-items-center m-4'>
                <div>

                <div className='bolderText text-danger'>{'We did not find this PO/Trailer in the system. Please verify the number, or try a different PO/Trailer.'}</div>
                <div > 
                <span className='bolderText'>Possible Causes: </span>
                    <ul className='subText'>
                        <li>PO number entered is not correct</li>
                        <li>PO is not on the schedule for today</li>
                        <li>PO is too early or a noshow from a previous day</li>
                        <li>If happening frequently (over half of attempts) please contact support 1(844) 586-7729</li>
                    </ul>
                </div>
                </div>
                </div>
        )
    }
    const getSuccessCheckIn=()=>{
       return <div  className="mt-5 d-flex justify-content-center w-80vw">
                        <span style={{color:'green'}} ><span className='d-flex justify-content-center'>Arrival Completed Successfully.</span>{props.inputData.transType===AppConstant.commonStrings.inBoundLoadTitle&&<span className='d-flex justify-content-center'>Instruct driver to proceed to <span  className='fw-bold mx-2'> Staging Area </span> &  <span  className='fw-bold mx-2'> Check-in </span> using the FreightSmith Mobile App.The driver will receive a text.</span>}</span>    
                    </div>
    }
    const getSuccessCheckOut=()=>{
        return    <div  className="mt-5 d-flex justify-content-center w-80vw">
        <span style={{color:'green'}} ><span className='d-flex justify-content-center'>Departure Completed Successfully.</span><span className='d-flex justify-content-center'>Instruct driver to proceed </span></span>
    </div>
    }

    return (
        <div>

            <div className='' />
            { data.loads && data.loads.length ?
                <div className='line-gap-2 f-16'>
                    {props.callReset===false ?  <div className='mt-2'>
                        {isCheckIn && driverData.data.checkindateandtime &&
                         <div className='textRed'>Load is already Checked In!</div>
                        }
                        {!isCheckIn && driverData.data.checkoutDateAndTime &&
                             <div className='textRed'>Load is already Checked Out!</div>
                        }
                        {/* <div className='f-18 mb-1'>Great News! We found the PO for: </div> */}
                       
                        <div className='f-14 fw-500'><i className="fa-solid fa-warehouse fa-sm"></i> {AppConstant.reports.truckUnloadSummaryReport.warehouse} : <span className='fw-normal'>{data.warehouseData.Name || '-'}</span></div>
                         <div className='f-14 fw-500'><i className="fa-solid fa-envelope"></i> {AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip} : <span className='fw-normal'>{data.warehouseData.Zip || '-'}</span></div>
                         <div className='f-14 fw-500'><i className="fa-solid fa-cubes"></i> {AppConstant.reports.truckUnloadSummaryReport.tableHeaders.dockNameStr} : <span className='fw-normal'>{data?.loads[0].dock_name || '-'}</span></div>              
                         <div className='f-14 fw-500'><i className="fa-solid fa-clock"></i> {AppConstant.smartScheduler.poSection.apptStartTime} : <span className='fw-normal'>{data?.loads[0]?.scheduled_time ? moment(data?.loads[0]?.scheduled_time, 'MMM D YYYY H:mm A').format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm): '-'}</span></div>
                         {driverData.data.checkindateandtime && <div className='f-14 fw-500'><i className="fa-solid fa-clock"></i> {AppConstant.driver.checkInTimeTitle} : <span className='fw-normal'>{moment(driverData.data?.checkindateandtime).format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm) || "-"}</span></div>}
                         {driverData.data.checkoutDateAndTime && <div className='f-14 fw-500'><i className="fa-solid fa-clock"></i> {AppConstant.driver.checkOutTimeTitle} : <span className='fw-normal'>{moment(driverData.data?.checkoutDateAndTime).format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm) || "-"}</span></div>}
                         <div className='seperator' />
                    <div className='f-16 fw-500'>All PO's are listed here:</div>
                    </div>
                    
                    :isCheckIn? getSuccessCheckIn():
                    getSuccessCheckOut()
                    }
                    
                    {showAppointmentsList()}
                   
                </div>
                :inputData.transType!==AppConstant.commonStrings.inBoundLoadTitle && isCheckIn?
                <div className='line-gap-2 f-16'>
                {props.callReset===false ? 
                 <div className='mt-2'>
                    {isCheckIn && driverData.data.checkindateandtime &&
                     <div className='textRed'>Load is already Checked In!</div>
                    }
                    {!isCheckIn && driverData.data.checkoutDateAndTime &&
                         <div className='textRed'>Load is already Checked Out!</div>
                    }
                    {/* <div className='f-18 mb-1'>Great News! We found the PO for: </div> */}
                   
                                <div className='f-14 fw-500'><i className="fa-solid fa-warehouse fa-sm"></i> {AppConstant.reports.truckUnloadSummaryReport.warehouse} : <span className='fw-normal'>{Name}</span></div>
                                <div className='f-14 fw-500'><i className="fa-solid fa-envelope"></i> {AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip} : <span className='fw-normal'>{Zip}</span></div>
                                {driverData.data.checkindateandtime && <div className='f-14 fw-500'><i className="fa-solid fa-clock"></i> {AppConstant.driver.checkInTimeTitle} : <span className='fw-normal'>{moment(driverData.data?.checkindateandtime).format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm) || "-"}</span></div>}
                                {driverData.data.checkoutDateAndTime && <div className='f-14 fw-500'><i className="fa-solid fa-clock"></i> {AppConstant.driver.checkOutTimeTitle} : <span className='fw-normal'>{moment(driverData.data?.checkoutDateAndTime).format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm) || "-"}</span></div>}
                     </div>
                      :isCheckIn? getSuccessCheckIn():
                      getSuccessCheckOut()
                    }
                     </div>
                  
                :<div className='w-80vw'>{showNoDataError()}</div>
            }
        </div>
    )
}

export default GuardsApptsListScreen;