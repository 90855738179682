import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { Uchain } from '../redux/api/agent';
import {
    addAppointment, clearAppointment, clearSSSearchedPO, getSSSearchedPO,
    getWPSchedulerData, intiateSSLoader, selectedPOforAppt, wpBreakData, wpCapacityData,
    wpLeaveData, updateSSRecommendationsArr, updateCarrierList, getDriverearchedPO, addAppointmentConfirmation, getDriverPOAppoint, sortDriverAppointTableData, intiateSSAppointmentLoader, initiateMultiplePOLoader, getAppointmentTrackerDetails, updateAppointmentTrackerDetailsError, addAppointmentByDate, getSearchedPOError, updateSearchedPOError, addSelectedDateForApptUpdate, updateIsDownloadLoading, resetDataToInitial, updateAppointmentReportSummary, updateCityWithZipcode, updateStartDate, updateEndDate, updateAppointmentHistory, updateMCDetails, updateDefaultDockValue,
    updateDateValidationResponse,
    updateAppointmentDocuments,
    clearAppointmentDocuments,
    updateAddPOResponse,
    updateCommanDownloadMessage,
    updateIsActiveCommanDownloadPopup,updateDeleteApptResponse
} from '../redux/reducers/reducerSlices/SmartScheduleReducer';
import _ from 'lodash';
import { showAlert } from '../App/Assests/Utility';
import { toast } from 'react-toastify';
import { AppConstant } from '../App/Assests/AppConstant';
import axios from 'axios';

function* intiateSSLoaderSaga(data) {
    try {
        yield put(intiateSSLoader(data.payload))
    } catch (error) {

    }
}

function* initiateSSAppointmentLoaderSaga(data) {
    try {
        yield put(intiateSSAppointmentLoader(data.payload))
    } catch (error) {

    }
}

function* initiateSSMultiplePoLoaderSaga(data) {
    try {
        yield put(initiateMultiplePOLoader(data.payload))
    } catch (error) {

    }
}

function* intiateSSPOSearchSaga(data) {
    try {
        yield put(selectedPOforAppt([]))
        const response = yield call(Uchain.intiateSSPoSearch, data.payload);
        if (response && _.size(response)) {
            yield put(getSSSearchedPO(response))
        } else {
            yield put(updateSearchedPOError(AppConstant.smartScheduler.searchAppointments.noSearchPODataUpdated))
            // showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.searchAppointments.noSearchPODataUpdated, 'light')
        }
        yield put(intiateSSLoader(false))
    } catch (error) {
        yield put(intiateSSLoader(false))
    }
}

function* updateSSPOSearchSaga(data) {
    try {
        yield put(getSSSearchedPO(data.payload))
        yield put(intiateSSLoader(false))
    } catch (error) {
        yield put(intiateSSLoader(false))
    }
}

function* updateSSPOSearchErrorSaga(data) {
    try {
        yield put(updateSearchedPOError(data.payload))
    } catch (error) {
    }
}



function* setSSselectedPOForApptSaga(data) {
    try {
        yield put(selectedPOforAppt(data.payload))
        // yield put(getSSSearchedPO([]))
    } catch (error) {

    }
}
function* clearSSPOSearchSaga(data) {
    try {
        const state = yield select();
        const { searchedPOData, appointmentDocumentsList } = state.smartScheduleReducer;
        if (_.size(searchedPOData) && !searchedPOData[0]?.AppointmentNumber) {
            if (_.size(appointmentDocumentsList)) {
                const getTasks = appointmentDocumentsList.map(el => {
                let fd = new FormData();
                fd.append('DocName', el.DocName); // Append the document name
                fd.append('AppointmentNumber', searchedPOData[0].AppointmentNumber)
                fd.append('CRUDTYPE', 'D');
                return call(Uchain.uploadAppointmentDocs, fd)
                })
                yield all(getTasks  )
            }
        }
        yield put(clearSSSearchedPO(data.payload))
    } catch (error) {

    }
}


function* addAppointmentSaga(data) {
    try {
        yield put(intiateSSLoader(true))
        let response = null;
        if (data.payload.CRUDTYPE === 'D') {
            response = yield call(Uchain.scheduleAppointment, data.payload.deleteApptData)
            const getDocs = yield call(Uchain.uploadAppointmentDocs, data.payload.deleteUploadDocsData)
            if (getDocs.length) {
                const delTasks = getDocs.map(el => {
                    let fd = new FormData();
                    fd.append('DocName', el.DocName); // Append the document name
                    fd.append('AppointmentNumber', el.AppointmentNumber)
                    fd.append('CRUDTYPE', 'BD')
                    return call(Uchain.uploadAppointmentDocs, fd)
                })
                yield all(delTasks);
            }
            yield put(updateDeleteApptResponse(response))
        } else {
            
            response = yield call(Uchain.scheduleAppointment, data.payload)
            yield put(updateDeleteApptResponse(response))

        }
        if (data.payload.CRUDTYPE !== 'D') {
            yield put(addAppointment(response))
        }
        yield put(addAppointmentConfirmation(true))
        yield put(intiateSSLoader(false))
    } catch (error) {
        yield put(intiateSSLoader(false))
        yield put(intiateSSAppointmentLoader(false))
        if (error?.response?.data?.message) {
            showAlert(toast.TYPE.ERROR, (error?.response?.data?.message), 'light');
        }
        else if (error?.response?.data && !error?.response?.data?.includes("UNAUTHORIZED")) {
            showAlert(toast.TYPE.ERROR, (error?.response?.data), 'light');
        }
    }
}


function* addAppointmenConfirmationSaga(data) {
    try {
        yield put(addAppointmentConfirmation(data.payload))
    } catch (error) {
    }
}

function* clearAppointmentSaga() {
    try {
        yield put(clearAppointment())
    } catch (error) {

    }
}

function* getWPschedulerDataSaga(data) {
    try {
        if (data.payload?.type === 'C') {
            yield put(getWPSchedulerData([]))
            yield put(addAppointment([]))
        } else {

            const response = yield call(Uchain.getScheduleData, data.payload)
            const obj = {
                ID: data.payload.IDWarehouse,
                type: 'USER',
                DateSelected: data.payload.DateSelected,
                CalendarView: data.payload.CalendarView
            }
            const response1 = yield call(Uchain.upcomingPastApptsCalendarApi, obj);
            let apptArray = []
            if (response1) {
                apptArray = [...response1?.pastAppointments, ...response1?.upcomingAppointments]
            }
            yield put(getWPSchedulerData(response))
            yield put(addAppointment(apptArray))
        }
    } catch (error) {

    }
}

function* initateWarehouseMetaDataSaga(data) {
    try {
        const { key, payload } = data.payload;
        if (key === 'wpCapacityData') {
            const response = yield call(Uchain.ssWarehouseCapacity, payload)
            yield put(wpCapacityData(response))
        } else if (key === 'wpBreakData') {
            const response = yield call(Uchain.ssWarehouseBreak, payload)
            yield put(wpBreakData(response))
        } else if (key === 'wpLeaveData') {
            const response = yield call(Uchain.ssWarehouseLeave, payload)
            yield put(wpLeaveData(response))
        }
    } catch (error) {

    }
}

function* addRemoveMorePOSaga(data) {
    try {
        const { payload, type, apiPayload } = data.payload;
        if (type === 'ADD') {
            const state = yield select();
            const { poForAppt, appointmentList } = state.smartScheduleReducer;
            const response = yield call(Uchain.addMorePos, payload);
            if (_.size(response) && payload.length === response.length) {
                 showAlert(toast.TYPE.SUCCESS, 'PO/SO added successfully');
                let uniqArray = _.uniqBy([...poForAppt, ...response], 'IDPO')
                uniqArray = _.compact(uniqArray)
                let uniqApptArray = _.uniqBy([...appointmentList, ...response], 'IDPO')
                uniqApptArray = _.compact(uniqApptArray)

                yield put(addAppointment(uniqApptArray))
                yield put(selectedPOforAppt(uniqArray))
                yield put(getSSSearchedPO(uniqArray))
                yield put(updateAddPOResponse(uniqArray))
            } else {
                let resArr=[]
                response && _.isArray(response) && response?.forEach((el)=>{
                    let obj={
                        PoNumber: el.PoNumber,
                        ReceivingZipCode:el.ReceivingZipCode,
                        IDDock:el.IDDock,
                        IDWarehouse:el.IDWarehouse,
                    }
                    resArr.push(obj)
                })

                const difference = _.differenceWith(data.payload.payload, resArr, _.isEqual);
                let pos=""
                difference && _.isArray(difference) && difference?.forEach((el)=>{
                    pos=pos+el.PoNumber+","
                })
                pos=pos?.slice(0,-1)
                 showAlert(toast.TYPE.ERROR, 'The PO/SO you selected is not valid for add-on '+pos);
                yield put(initiateMultiplePOLoader(false))
            }
        } else {
            const response = yield call(Uchain.scheduleAppointment, apiPayload);
            // yield put(addAppointment([...response]))
            yield put(selectedPOforAppt([...payload]))
            yield put(getSSSearchedPO([...payload]))
            showAlert(toast.TYPE.SUCCESS, 'PO/SO removed successfully');
        }
        yield put(intiateSSLoader(false))
    } catch (error) {
        yield put(intiateSSLoader(false))
        yield put(initiateMultiplePOLoader(false))
    }
}

function* ssRecommendationsListSaga(data) {
    try {
        yield put(intiateSSAppointmentLoader(true))
        const response = yield call(Uchain.ssRecommendationsApi, data.payload);
        if (_.isArray(response)) {
            yield put(updateSSRecommendationsArr(response));

        } else {
            yield put(updateSSRecommendationsArr([]));
            showAlert(toast.TYPE.ERROR, response?.RESULT,);

        }
        yield put(intiateSSLoader(false));
        yield put(intiateSSAppointmentLoader(false))
    } catch (error) {
        yield put(intiateSSLoader(false));
        yield put(intiateSSAppointmentLoader(false))
    }
}
function* ssGetCarrierListSaga(data) {
    try {
        const response = yield call(Uchain.getCarriers, data.payload);
        // yield put(intiateSSLoader(false));
        if (_.size(response?.carrierList)) {

            yield put(updateCarrierList(response?.carrierList));
        } else {
            yield put(updateCarrierList([]));
        }
    } catch (error) {
        // yield put(intiateSSLoader(false));
    }
}

function* intiateDriverPOSearchSaga(data) {
    try {
        const response = yield call(Uchain.getDriverPoDATA, data.payload);
        yield put(getDriverearchedPO(response))
        yield put(intiateSSLoader(false))
    } catch (error) {
        yield put(intiateSSLoader(false))
    }
}

function* intiateDriverAppointSaga(data) {
    try {
        const response = yield call(Uchain.getDriverAppointDATA, data.payload);
        yield put(getDriverPOAppoint(response))
        yield put(intiateSSLoader(false))
    } catch (error) {
        yield put(intiateSSLoader(false))
    }
}

function* getAppointmentTrackerSaga(data) {
    try {
        const response = yield call(Uchain.getAppointmentTrackerDetails, data.payload);
        if (!_.isEmpty(response) && response?.TotalCount > 0) {
            yield put(updateAppointmentTrackerDetailsError(false))
        } else {
            yield put(updateAppointmentTrackerDetailsError(true))
        }
        yield put(getAppointmentTrackerDetails(response))
    } catch (error) {
        yield put(updateAppointmentTrackerDetailsError(true))
    }
}

function* clearAppointmentTrackerSaga(data) {
    try {
        yield put(getAppointmentTrackerDetails(null))
    } catch (error) {
    }
}
function* addAppointmentByDateSaga(data) {
    try {
        const response = yield call(Uchain.getAppointmentsByDateApi, data.payload)
        // WP-2809 - Sorted in asc order based on ApptStartTime
        let sortedData = []
        if (_.size(response)) {
            sortedData = _.sortBy(response, 'ApptStartTime');
        }
        yield put(addAppointmentByDate(sortedData))

    } catch (error) {
        if (error?.response?.data && !error?.response?.data?.includes("UNAUTHORIZED")) {
            showAlert(toast.TYPE.ERROR, (error?.response?.data), 'light');
        }
    }
}

function* addSelectedDateForApptUpdateSaga(data) {
    try {
        yield put(addSelectedDateForApptUpdate(data.payload))
    } catch (error) {
    }
}
function* downloadCommonReportSaga(data) {
    try {
        yield put(updateIsDownloadLoading(true))
        
        if (data?.payload?.userDetails) { 
            const response = yield call(Uchain.downloadCommonReportsOptimized, data.payload);
            if (response?.success) {
                // showAlert(toast.TYPE.SUCCESS, response.message, 'light')
            } else {
                // showAlert(toast.TYPE.ERROR, response.message, 'light')
            }
            yield put(updateCommanDownloadMessage(response.message))
            yield put(updateIsActiveCommanDownloadPopup(true))
        } else {
            const response = yield call(Uchain.downloadCommonReports, data.payload);
            if (response) {
                window.open(response.fileUrl, '_blank');
                yield put(updateIsDownloadLoading(false))
            } else {
                showAlert(toast.TYPE.INFO, AppConstant.commonStrings.noDataFound, 'light')
                yield put(updateIsDownloadLoading(false))
            }
        }
        yield put(updateIsDownloadLoading(false))
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
        yield put(updateIsDownloadLoading(false))
    }
}
function* resetDataSaga() {
    try {
        yield put(resetDataToInitial())
    } catch (error) {

    }
}


function* getAppointmentReportSummarySaga(data) {
    try {
        yield put(intiateSSLoader(true))
        const response = yield call(Uchain.getAppointmentReportSummary, data.payload)

        yield put(updateAppointmentReportSummary(response))

    } catch (error) {
        if (error?.response?.data && !error?.response?.data.includes("UNAUTHORIZED")) {
            showAlert(toast.TYPE.ERROR, (error?.response?.data), 'light');
        }
    } finally {
        yield put(intiateSSLoader(false))
    }
}

function* getCityWithZipcodeSaga(data) {
    try {
        if (data.payload.type === 'G') {
            const response = yield call(Uchain.getCityWithZipcode, data.payload)
            const uniqResponse = _.uniqBy(response, 'label')
            yield put(updateCityWithZipcode(uniqResponse))
        } else {
            yield put(updateCityWithZipcode([]))
        }
        // yield put(intiateSSLoader(true))

    } catch (error) {
        yield put(updateCityWithZipcode([]))
    } finally {
        // yield put(intiateSSLoader(false))
    }
}

function* getAppointmentHistorySaga(data) {
    try {
        yield put(intiateSSLoader(true))
        const response = yield call(Uchain.getAppointmentHistory, data.payload)
        if (response) {
            yield put(updateAppointmentHistory(response))

        }

    } catch (error) {
        if (error?.response?.data) {
            showAlert(toast.TYPE.ERROR, (error?.response?.data), 'light');
        }
    } finally {
        yield put(intiateSSLoader(false))
    }
}
function* setStartDateSaga(data) {
    try {
        yield put(updateStartDate(data.payload))
    } catch (error) {

    }
}

function* setEndDateSaga(data) {
    try {
        yield put(updateEndDate(data.payload))
    } catch (error) {

    }
}

function* updateDefaultDockSaga(data) {
    try {
        if (data.payload.CRUDTYPE === 'I') {
            const response = yield call(Uchain.updateIMSSPDefaultDock, data.payload)
            if (response) {
                yield put(updateDefaultDockValue(response.DefaultIDDock))
            } else {
                yield put(updateDefaultDockValue(null))
            }
            showAlert(toast.TYPE.SUCCESS, "Default dock updated successfully", 'light');
        } else if (data.payload.CRUDTYPE === 'U') {
            yield put(updateDefaultDockValue(data.payload.DefaultIDDock))
        }

    } catch (error) {
        yield put(updateDefaultDockValue(null))
        showAlert(toast.TYPE.console.ERROR, "Default dock update failed", 'light');
    }
}
function* getMCDetailsSaga(data) {
    try {
        yield put(updateMCDetails(null))
        yield put(intiateSSLoader(true))
        const response = yield call(Uchain.getMCDetails, data.payload)
        if (response && response.carrierList && response.carrierList.length) {
            yield put(updateMCDetails(response.carrierList[0]))
        } else {
            yield put(updateMCDetails(null))
        }
    } catch (error) {
        if (error?.message && !error?.response?.data.includes("UNAUTHORIZED")) {
            showAlert(toast.TYPE.ERROR, "Invalid MC #", 'light');
            yield put(updateMCDetails(null))
        }
    } finally {
        yield put(intiateSSLoader(false))
    }
}

function* resetMCDetailsSaga(data) {
    try {
        yield put(updateMCDetails(null))
    } catch (error) {

    }
}

function* validateDateForAppointmentSaga(data) {
    try {
        yield put(updateDateValidationResponse(null))

        const response = yield call(Uchain.validateDateForAppointmentApi, data.payload)
        if (response) {
            yield put(updateDateValidationResponse(response))
        } else {
            yield put(updateDateValidationResponse(null))
        }
    } catch (error) {
        if (error?.message && !error?.response?.data.includes("UNAUTHORIZED")) {
            yield put(updateDateValidationResponse(null))
        }
    } finally {
    }
}

function* resetValidateDateForAppointmentSaga(data) {
    try {
        yield put(updateDateValidationResponse(null))
    } catch (error) {

    }
}

function* initiateUploadApptDocsSaga(data) {
    try {
        const state = yield select();
        const { appointmentDocumentsList } = state.smartScheduleReducer;
        const response = yield call(Uchain.uploadAppointmentDocs, data.payload.uploadData)
        if (data.payload.CRUDTYPE === 'I') {
            if (response.status && _.size(response?.data)) {   
                // 
                yield call(axios.put, response.data[0].signedurl, data.payload.file, {
                    headers: {
                        'Content-Type': data.payload.file.type,
                    },
                });
                const s3Url = `https://${process.env.REACT_APP_SPRO_UPLOAD_BUCKET}.s3.amazonaws.com/${response.data[0].DocName}`;
                if (data.payload.AppointmentNumber) {
                    const fd = new FormData();
                    fd.append('DOCSURL',s3Url); 
                    fd.append('DocName', response.data[0].DocName); // Append the document name
                    fd.append('AppointmentNumber', data.payload.AppointmentNumber)
                    fd.append('ApptDocsCreatedBy', response.data[0].ApptDocsCreatedBy);
                    fd.append('ApptDocsUpdatedBy', null);
                    fd.append('ID', null);
                    fd.append('CRUDTYPE', 'BI');
                    const responseBI = yield call(Uchain.uploadAppointmentDocs, fd)
                    yield put(updateAppointmentDocuments([...responseBI.data]))
                    if (response.status) showAlert(toast.TYPE.SUCCESS, response?.message);
                    else showAlert(toast.TYPE.ERROR, response?.message);
                } else {
                    const responseToStore = {
                        DOCSURL: s3Url,
                        DocName: response.data[0].DocName,
                        ApptDocsCreatedBy: response.data[0].ApptDocsCreatedBy
                    }
                    yield put(updateAppointmentDocuments([...appointmentDocumentsList, { ...responseToStore }]))
                    if (response.status) showAlert(toast.TYPE.SUCCESS, response?.message);
                    else showAlert(toast.TYPE.ERROR, response?.message);
                }
            }
        }else  if (data.payload.CRUDTYPE === 'BI') {
            yield put(updateAppointmentDocuments([...response.data]))
            if (response.status) showAlert(toast.TYPE.SUCCESS, response?.message);
            else showAlert(toast.TYPE.ERROR, response?.message);
        }else if (data.payload.CRUDTYPE === 'D' || data.payload.CRUDTYPE === 'BD') {
            const filterData = appointmentDocumentsList.filter(el => el.DocName !== data.payload.DocName)
            yield put(updateAppointmentDocuments([...filterData]))
            if (response.status) showAlert(toast.TYPE.SUCCESS, response?.message);
            else showAlert(toast.TYPE.ERROR, response?.message);
        } else if (data.payload.CRUDTYPE === 'S') {
            const response = yield call(Uchain.uploadAppointmentDocs, data.payload.uploadData)
            yield put(updateAppointmentDocuments([...response]))
        }
        yield put(intiateSSLoader(false))
    } catch (error) {
        if (error?.response?.statusText.includes('Large')) {
            showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.uploadAppointmentDocs.largeFileError);
        } else {
            showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong);
        }
        yield put(intiateSSLoader(false))
    }
}

function* clearUploadApptDocsSaga() {
    try {
        yield put(clearAppointmentDocuments([]))
    } catch (error) {

    }
}
function* closeCommanDownloadReportSaga() {
    try {
        yield put(updateCommanDownloadMessage(null))
        yield put(updateIsActiveCommanDownloadPopup(false))
    } catch (error) {

    }
}

function* resetDeleteApptResponseSaga() {
    try {
        yield put(updateDeleteApptResponse(null))
    } catch (error) {

    }
}

export function* intiateSSLoaderWatcher() {
    yield takeEvery(sagaActions.INTIATE_SS_LOADER, intiateSSLoaderSaga)
}
export function* initiateSSAppointmentLoaderWatcher() {
    yield takeEvery(sagaActions.INITIATE_SS_APPOINTMENT_LOADER, initiateSSAppointmentLoaderSaga)
}
export function* initiateSSMultiplePoLoaderWatcher() {
    yield takeEvery(sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, initiateSSMultiplePoLoaderSaga)
}
export function* intiateSSPOSearchWatcher() {
    yield takeEvery(sagaActions.INTIATE_SS_SEARCH_PO, intiateSSPOSearchSaga)
}
export function* updateSSPOSearchWatcher() {
    yield takeEvery(sagaActions.UPDATE_SS_SEARCH_PO, updateSSPOSearchSaga)
}
export function* setSSselectedPOForApptWatcher() {
    yield takeEvery(sagaActions.SET_SS_SELECTED_PO_FOR_APPT, setSSselectedPOForApptSaga)
}
export function* clearSSPOSearchWatcher() {
    yield takeEvery(sagaActions.CLEAR_SS_SEARCH_PO, clearSSPOSearchSaga)
}
export function* addAppointmenthWatcher() {
    yield takeEvery(sagaActions.ADD_APPOINTMENT, addAppointmentSaga)
}
export function* addAppointmenConfirmationthWatcher() {
    yield takeEvery(sagaActions.ADD_APPOINTMENT_CONFIRMATION, addAppointmenConfirmationSaga)
}
export function* clearAppointmentWatcher() {
    yield takeEvery(sagaActions.CLEAR_APPOINTMENT, clearAppointmentSaga)
}
export function* getWPschedulerDataWatcher() {
    yield takeEvery(sagaActions.GET_SCHEDULER_DATA, getWPschedulerDataSaga)
}
export function* initateWarehouseMetaDataWatcher() {
    yield takeEvery(sagaActions.INITIATE_WAREHOUSE_METADATA, initateWarehouseMetaDataSaga)
}
export function* addRemoveMorePOWatcher() {
    yield takeEvery(sagaActions.ADD_REMOVE_PO, addRemoveMorePOSaga)
}
export function* ssRecommendationsListWatcher() {
    yield takeEvery(sagaActions.SS_RECOMMENDATIONS_LIST_ACTION, ssRecommendationsListSaga)
}
export function* ssGetCarrierListWatcher() {
    yield takeEvery(sagaActions.SS_GET_CARRIER_LIST, ssGetCarrierListSaga)
}
export function* intiateDriverPOSearchWatcher() {
    yield takeEvery(sagaActions.INTIATE_GUARD_SEARCH_PO, intiateDriverPOSearchSaga)
}
export function* intiateDriverPOAppointWatcher() {
    yield takeEvery(sagaActions.INTIATE_GUARD__PO_APPOINTMENT, intiateDriverAppointSaga)
}
function* sortDriverAppointSaga(data) {
    yield put(sortDriverAppointTableData(data.payload))
}
export function* sortDriverPOAppointWatcher() {
    yield takeEvery(sagaActions.SORT_DRIVER_APPOINTMENT_TABLE, sortDriverAppointSaga)
}
export function* getAppointmentTrackerWatcher() {
    yield takeEvery(sagaActions.GET_APPOINTMENT_TRACKER, getAppointmentTrackerSaga)
}
export function* clearAppointmentTrackerWatcher() {
    yield takeEvery(sagaActions.CLEAR_APPOINTMENT_TRACKER, clearAppointmentTrackerSaga)
}
export function* addAppointmentByDateWatcher() {
    yield takeEvery(sagaActions.ADD_APPOINTMENT_BY_DATE, addAppointmentByDateSaga)
}
export function* updateSSPOSearchErrorWatcher() {
    yield takeEvery(sagaActions.UPDATE_SS_SEARCH_PO_ERROR, updateSSPOSearchErrorSaga)
}
export function* addSelectedDateForApptUpdateWatcher() {
    yield takeEvery(sagaActions.ADD_SELECTED_DATE_FOR_APPT_UPDATE, addSelectedDateForApptUpdateSaga)
}
export function* downloadCommonReportWatcher() {
    yield takeEvery(sagaActions.DOWNLOAD_REPORT_COMMON, downloadCommonReportSaga)
}
export function* resetDataWatcher() {
    yield takeEvery(sagaActions.CLEAR_SMART_SCHEDULE_REDUCER_DATA, resetDataSaga)
}
export function* getAppointmentReportSummaryWatcher() {
    yield takeLatest(sagaActions.APPOINTMENT_REPORT_SUMMARY, getAppointmentReportSummarySaga)
}
export function* getCityWithZipcodeWatcher() {
    yield takeEvery(sagaActions.GET_STATE_WITH_ZIPCODE, getCityWithZipcodeSaga)
}
export function* getAppointmentHistoryWatcher() {
    yield takeEvery(sagaActions.GET_APPOINTMENT_HISTORY, getAppointmentHistorySaga)
}
export function* setStartDateWatcher() {
    yield takeEvery(sagaActions.UPDATE_START_DATE, setStartDateSaga)
}
export function* setEndDateWatcher() {
    yield takeEvery(sagaActions.UPDATE_END_DATE, setEndDateSaga)
}
export function* updateDefaultDockWatcher() {
    yield takeEvery(sagaActions.UPDATE_DEFAULT_DOCK, updateDefaultDockSaga)
}
export function* getMCDetailsWatcher() {
    yield takeEvery(sagaActions.GET_MC_DETAILS, getMCDetailsSaga)
}
export function* resetMCDetailsWatcher() {
    yield takeEvery(sagaActions.RESET_MC_DETAILS, resetMCDetailsSaga)
}
export function* validateDateForAppointmentWatcher() {
    yield takeEvery(sagaActions.VALIDATE_DATE_FOR_APPOINTMENT, validateDateForAppointmentSaga)
}
export function* resetValidateDateForAppointmentWatcher() {
    yield takeEvery(sagaActions.RESET_VALIDATE_DATE_FOR_APPOINTMENT, resetValidateDateForAppointmentSaga)
}
export function* initiateUploadApptDocsWatcher() {
    yield takeEvery(sagaActions.UPLOAD_APPT_DOCS, initiateUploadApptDocsSaga)
}
export function* clearUploadApptDocsWatcher() {
    yield takeEvery(sagaActions.CLEAR_UPLOAD_APPT_DOCS, clearUploadApptDocsSaga)
}
export function* closeCommanDownloadReportWatcher() {
    yield takeEvery(sagaActions.CLOSE_COMMAN_DOWNLOAD_MESSAGE_POPUP, closeCommanDownloadReportSaga)
}
export function* resetDeleteApptResponseWatcher() {
    yield takeEvery(sagaActions.RESET_DELETE_APPT_RESPONSE, resetDeleteApptResponseSaga)
}


