import React, { useEffect, useState } from 'react';
import './CarrierApptListRows.css';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import moment from 'moment';
import { getDriverCheckinReducer } from '../../../../../../../redux/reducers/reducerSlices/DriverCheckinReducer';
import { useSelector } from 'react-redux';

const CarrierApptListRows = (props) => {
    const { rowData, rowIndex, ssSchedulehandler } = props;
    const driverData = useSelector(getDriverCheckinReducer);
    const [selectedData, setSelectedData] = useState();

    const renderHorizontalSeparator = () => {
        if (rowData.ApptScheStatus === 'Scheduled') {
            return {
                className: `w-100 horizontalSeperator apptScheduled`
            }
        } else if (rowData.ApptScheStatus === 'Not Scheduled') {
            return {
                className: `w-100 horizontalSeperator apptNotScheduled`
            }
        }
        else if (rowData.ApptScheStatus === 'Reschedule') {
            return {
                className: `w-100 horizontalSeperator rescheduled`
            }
        } else {
            return {
                className: `w-100 horizontalSeperator completed`
            }
        }
    }

    useEffect(() => {
        if (rowData) {
            setSelectedData(rowData?.pos);
        }

    }, [rowData])


    return (
        <tbody>
            <tr key={rowIndex}>
                <td>

                    <div>
                        <div>
                            {/* <div className='d-flex flex-column justify-content-center align-items-start pt-3 px-2'>
                                <span className='calendarDateText'>{rowData?.ApptStartTime ?
                                    moment(rowData.ApptStartTime).format('DD') : '--'}</span>
                                <span className='subText p-1'>{rowData?.ApptStartTime ?
                                    moment(rowData.ApptStartTime).format('MMM') : '--'}</span>
                            </div> */}
                           
                              
                                {selectedData&& selectedData.map((data)=>(
 <div className='d-flex flex-column justify-content-start px-1 pb-2 boxShadow-1 pt-2'>
   <div className='f-14 fw-600'>{AppConstant.poManagement.tableHeader.po} : {data.po_number}</div>

 <div className='f-14 fw-600'>{AppConstant.reports.adfatReport.dropdownLabels.vendor} : {data.vendor || '-'}</div>
<div className='f-14 fw-600'>{AppConstant.smartScheduler.poSection.commodity} : {data.commodity || '-'}</div>


 </div>
                                ))
                                   
                                }
                           
                        </div>
                        {/* <div className='d-flex justify-content-end px-2'>
                          
                            <CustomButton
                            disabled={false}
                            isLoading={false}
                            title="Check-In"
                            className={`addButton mb-2`}
                            onClick={() => ssSchedulehandler(rowData)}
                            />
                       
                       
                        </div> */}
                        {/* <div className={renderHorizontalSeparator().className} /> */}
                    </div>
                </td>
            </tr>
        </tbody>
    )
}

export default CarrierApptListRows;