import { createSlice } from '@reduxjs/toolkit';              

const initialState = {
    getListLoading: false,
    truckListMasterData : [],
    truckList: [],
    autoFetchPolist: false,
    // totalPages: 10,
    // currentPage: 1
    arrangeOrder: 'asc',
    arrangeKey: 'appointment_time',
}

export const getTruckReducerSlice = createSlice({
    name: 'getTruckReducer',
    initialState,
    reducers: {
        initiateGetlist: (state, action) => {
            state.getListLoading = true;
            // state.truckList = action.payload.truckList;
            // state.totalPages = action.payload.totalPages;
            // state.currentPage = action.payload.currentPage;
        },
        updateMainTruckList : (state, action) => {
            
            state.getListLoading = false;
            state.truckList = action.payload.truckList;
            state.truckListMasterData = action.payload.truckList;
        },
        getListSuccess: (state, action) => {
            state.getListLoading = false;
            state.truckList = action.payload.truckList;
            // state.totalPages = action.payload.totalPages;
            // state.currentPage = action.payload.currentPage;
        },
        getListFailure: (state) => {
            state.getListLoading = false;
            state.truckList = [];
            state.truckListMasterData = [];
            state.autoFetchPolist = false;
        },
        getModifiedListFailure:(state) => {
            state.getListLoading = false;
            state.truckList = [];
        },
        getUpdateListSuccess: (state, action) => {
            state.truckList = action.payload.truckList;
        },
        autoFetchPoListSuccess: (state, action) => {
            state.autoFetchPolist = action.payload;
        },
        updateTruckListSortOrder: (state, action) => {
            state.arrangeOrder = action.payload.order
            state.arrangeKey = action.payload.key
        }
    }
});

export const { initiateGetlist, getListSuccess, getListFailure, updateMainTruckList, getModifiedListFailure, getUpdateListSuccess,  autoFetchPoListSuccess, updateTruckListSortOrder} = getTruckReducerSlice.actions;

// Selectors
export const getTruckSelector = (state) => {   
    return state.getTruckReducer;
};

export default getTruckReducerSlice.reducer;
