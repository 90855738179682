import React from 'react';
import { useSelector } from 'react-redux';
import { getLoadSelector } from '../../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import { AppConstant } from '../../../../../../Assests/AppConstant';

import './DoorAssignmentHeader.css'

const DoorAssignmentHeaderIMSSP = (props) => {
    const { themeSelected } = props;
    const getLoad = useSelector(getLoadSelector);
    const { enableDoorSection, selectedLoad } = getLoad;
    /* Funtion to dispaly Door Assignment Text to user to let user know why they can't assign door
    */

    const showDoorAssignmentResctriction = () => {
        const {poNotApprovedText, truckinReleasedStateText} = AppConstant.doorManagement;
        let text = '';
        if(!enableDoorSection){
            text = poNotApprovedText;
        }else if(selectedLoad.bills_out_time || selectedLoad.outdoor_time){
            text = truckinReleasedStateText;
        }
        return text
    }
    return ( 
        <div className={`door-header-wrapper col ps-2 py-1 ${themeSelected}`} >
                    <div className='text-start title'>{AppConstant.doorManagement.loadheader}</div>
                    <div className='text-start subTitle py-1'>
                        <span>{AppConstant.doorManagement.loadSubheader1}</span>
                        <span><strong>{AppConstant.doorManagement.loadSubheader2}</strong></span>
                        <span>{AppConstant.doorManagement.loadSubheader3}</span>
                        <div><strong>{showDoorAssignmentResctriction()}</strong></div>
                    </div>
                </div>
     );
}
 
export default DoorAssignmentHeaderIMSSP;