import React, { useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { changeDateToGivenFormat, getFormattedDate, getCurrentDate } from '../../../../../../../Utils/DateUtils';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { fetchWhCapacityCommonFunc } from './Common/FetchWHCapacity';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { toast } from 'react-toastify';
import { showAlert, changeFormatWithoutTz } from '../../../../../../Assests/Utility';
import DrilldownTable from '../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import EditIcon from '../../../../../../Assests/Images/edit-icon-new.png'
import Popup from '../../../../../Common/Popup/Popup';
import './DailyOperations.css';
import moment from 'moment';
import DrilldownTableSS from '../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';
import { CustomDynamicFormSS } from '../../../../../Common/CustomForm/CustomDynamicFormSS';

const DailyOperationsSS = (props) => {

    const { themeSelected, whObj } = props
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const [isEditDisabled, setIsEditDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const { whMetaCapacityList, operationObj, arrangeOrder, arrangeKey } = whMetaData;
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id } = user;
    const [currentLabelsList, setCurrentLabelsList] = useState([]);
    const [isListLoading, setListLoading] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [whStartTimeState, setWhStartTimeState] = useState('');
    const [startTimeState, setStartTimeState] = useState('');
    const [wHEndTimeState, setWhEndTimeState] = useState('');
    const [wHEarliestApptTimeState, setEarliestApptTimeState] = useState('');
    const [wHLastApptTimeState, setWhLastApptTimeState] = useState('');
    const [editData, setEditData] = useState({});
    const [loading, setLoading] = useState(false);
    const [resetFormData, setResetFormData] = useState(0);
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showCreateButton, setShowCreateButton] = useState(false);

    const whCapacityTitleDD = [
        { id: 'WHStartTime', name: AppConstant.smartScheduler.breaksForm.tableHeaders.startTime },
        { id: 'WHEndTime', name: AppConstant.smartScheduler.breaksForm.tableHeaders.endTime },
        { id: 'EarliestApptHrs', name: AppConstant.smartScheduler.whCapacity.formComponent.earliestApptHours },
        { id: 'LastApptHrs', name: AppConstant.smartScheduler.whCapacity.formComponent.lastApptHours },
        { id: 'ApptAvailableColor', name: AppConstant.smartScheduler.whCapacity.formComponent.apptAvailableColor },
        { id: 'ApptNotAvailableColor', name: AppConstant.smartScheduler.whCapacity.formComponent.apptUnAvailableColor },
        { id: 'WHBreakColor', name: AppConstant.smartScheduler.whCapacity.formComponent.whBreakColor },
        { id: 'WHLeaveColor', name: AppConstant.smartScheduler.whCapacity.formComponent.whLeaveColor },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.actions },
    ]

    useEffect(() => {
        setCurrentLabelsList([]);

        setListLoading(true);

        dispatch({ type: sagaActions.RESET_WAREHOUSE_META_CAPACITY_ACTION })
        setTimeout(() => {
            setEditData({});
            fetchWhCapacityData();
        }, [1000])
    }, [])

    const createButtomStatus = (arr) => {
        const data = arr[0];
        setShowCreateButton(true);
        if (data.WHStartTime) setShowCreateButton(false);
        if (data.WHEndTime) setShowCreateButton(false);
        if (data.EarliestApptHrs) setShowCreateButton(false);
        if (data.LastApptHrs) setShowCreateButton(false);
        if (data.ApptAvailableColor) setShowCreateButton(false);
        if (data.ApptNotAvailableColor) setShowCreateButton(false);
        if (data.WHBreakColor) setShowCreateButton(false);
        if (data.WHLeaveColor) setShowCreateButton(false);
    }

    useEffect(() => {
        setLoading(false);
        if (whMetaCapacityList && whMetaCapacityList.length) {
            setIsLoading(false);
            setListLoading(false);
            setCurrentItemIndex('NA');
            setCurrentLabelsList(whMetaCapacityList);
            createButtomStatus(whMetaCapacityList)
        } else {
            if (!whMetaCapacityList) {
                setShowCreateButton(true);
                setListLoading(true);
            }
        }
    }, [whMetaCapacityList])

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Fetch Wh Capacity':
                    if (!operationObj.status) {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.whCapacity.createFetchListFailure);
                    }
                    break;
                case 'Update Wh Capacity':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.whCapacity.updateCapacityStatusSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.whCapacity.updateCapacityStatusFailure);
                    }
                    break;
                case 'Create Wh Capacity':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.whCapacity.createCapacityStatusSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.whCapacity.updateCapacityStatusFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    const handleApiResponse = (result, message) => {
        setIsLoading(false);
        setLoading(false);
        if (result === AppConstant.notifications.dataAddedSuccess) {
            setShowModal(false);
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            fetchWhCapacityData()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }

        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }

    const fetchWhCapacityData = () => {
        const { apiParams, operationObj } = fetchWhCapacityCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_CAPACITY_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const editWhCapacityAction = () => {
        const dataObj = whMetaCapacityList[0];
        const tempObj = {
            ...dataObj,
            WHStartTime: changeFormatWithoutTz(dataObj.WHStartTime, AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
            WHEndTime: changeFormatWithoutTz(dataObj.WHEndTime, AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
            EarliestApptHrs: changeFormatWithoutTz(dataObj.EarliestApptHrs, AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
            LastApptHrs: changeFormatWithoutTz(dataObj.LastApptHrs, AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
        }
        setEditData(tempObj);
        setShowModal(true);
    }

    const actionsButton = (groupObj, index) => {
        return (
            <div className='d-flex justify-content-center'>

                <img src={EditIcon} alt='edit' title={AppConstant.commonStrings.edit} className='editIconPO-ss' onClick={() => editWhCapacityAction(groupObj, index)} />
                {/* <i className="fa-solid fa-pen-to-square fa-lg color-black" title={AppConstant.commonStrings.edit} onClick={() => editWhCapacityAction(groupObj, index)}></i> */}
                {/* <button
                        className={`addButton`}
                        data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={false}
                        onClick={() => editWhCapacityAction(groupObj, index)}
                    >
                        {AppConstant.commonStrings.edit}
                    </button> */}

            </div>
        )
    }

    const TUSDatePickerInput = forwardRef(({ value, onClick }, ref) => {
        let dateStr = moment(value).hours() + ':' + moment(value).minutes();
        if (!value.includes('mm')) {
            const getHour = ("0" + moment(value).hour()).slice(-2);
            const getMin = ("0" + moment(value).minutes()).slice(-2);
            dateStr = getHour + ":" + getMin;
        }
        return (
            <button className="customDatePickerInput" onClick={onClick} ref={ref}>
                {dateStr !== 'NaN:NaN' ? dateStr : 'HH:MM'}
            </button>
        )
    })

    const formFieldList = [
        {
            size: "col-sm-6 mt-1",
            name: "WHStartTime",
            type: "custom-time-picker",
            customDatePickerConfig: {
                selectedDate: '',
                name: "WHStartTime",
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                customInput: <TUSDatePickerInput value={whStartTimeState} />,
            },
            position: 1,
            value: whStartTimeState,
            title: AppConstant.smartScheduler.whCapacity.formComponent.whStartTime,
            formValidation: {
                required: false,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "WHEndTime",
            type: "custom-time-picker",
            customDatePickerConfig: {
                placeholder: 'HH:MM',
                selectedDate: '',
                startDate: '',
                name: "WHEndTime",
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                customInput: <TUSDatePickerInput value={wHEndTimeState} />,
            },
            position: 2,
            value: wHEndTimeState,
            title: AppConstant.smartScheduler.whCapacity.formComponent.whEndTime,
            formValidation: {
                required: false,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "EarliestApptHrs",
            type: "custom-time-picker",
            customDatePickerConfig: {
                placeholder: 'HH:MM',
                selectedDate: '',
                startDate: '',
                name: "EarliestApptHrs",
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                customInput: <TUSDatePickerInput value={wHEarliestApptTimeState} />,
            },
            position: 3,
            value: wHEarliestApptTimeState,
            title: AppConstant.smartScheduler.whCapacity.formComponent.earliestApptHours,
            formValidation: {
                required: false,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "LastApptHrs",
            type: "custom-time-picker",
            customDatePickerConfig: {
                placeholder: 'HH:MM',
                selectedDate: '',
                startDate: '',
                name: "LastApptHrs",
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                customInput: <TUSDatePickerInput value={wHLastApptTimeState} />,
            },
            position: 4,
            value: wHLastApptTimeState,
            title: AppConstant.smartScheduler.whCapacity.formComponent.lastApptHours,
            formValidation: {
                required: false,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "ApptAvailableColor",
            type: "color",
            position: 5,
            title: AppConstant.smartScheduler.whCapacity.formComponent.apptAvailableColor,
            formValidation: {
                required: false,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "ApptNotAvailableColor",
            type: "color",
            position: 6,
            title: AppConstant.smartScheduler.whCapacity.formComponent.apptUnAvailableColor,
            formValidation: {
                required: false,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "WHBreakColor",
            type: "color",
            position: 7,
            title: AppConstant.smartScheduler.whCapacity.formComponent.whBreakColor,
            formValidation: {
                required: false,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "WHLeaveColor",
            type: "color",
            position: 8,
            title: AppConstant.smartScheduler.whCapacity.formComponent.whLeaveColor,
            formValidation: {
                required: false,
            }
        },
    ]

    const createForm = () => {
        return (
            <CustomDynamicFormSS
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
            />
        );
    };

    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        const operationObj = {
            type: '',
            status: ''
        }

        if (data.ID && Object.keys(editData).length) {
            const apiTempParams = {
                ...data,
                WHStartTime: changeDateToGivenFormat(data.WHStartTime, AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
                WHEndTime: changeDateToGivenFormat(data.WHEndTime, AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
                EarliestApptHrs: changeDateToGivenFormat(data.EarliestApptHrs, AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
                LastApptHrs: changeDateToGivenFormat(data.LastApptHrs, AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
                CRUDTYPE: "U",
                "CreatedOn": data.CreatedDate,
                "CreatedBy": data.CreatedBy,
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
            }
            operationObj.type = 'Update Wh Capacity'
            apiParams = { ...data, ...apiTempParams }
        } else {
            const apiTempParams = {
                ...currentLabelsList[0],
                WHStartTime: changeDateToGivenFormat(data.WHStartTime, AppConstant.commonStrings.HH_mm_dateFormat),
                WHEndTime: changeDateToGivenFormat(data.WHEndTime, AppConstant.commonStrings.HH_mm_dateFormat),
                EarliestApptHrs: changeDateToGivenFormat(data.EarliestApptHrs, AppConstant.commonStrings.HH_mm_dateFormat),
                LastApptHrs: changeDateToGivenFormat(data.LastApptHrs, AppConstant.commonStrings.HH_mm_dateFormat),
                ApptAvailableColor: data.ApptAvailableColor,
                ApptNotAvailableColor: data.ApptNotAvailableColor,
                WHBreakColor: data.WHBreakColor,
                WHLeaveColor: data.WHLeaveColor,
                CRUDTYPE: "U",
                "CreatedOn": data.CreatedDate,
                "CreatedBy": data.CreatedBy,
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss),
            }
            operationObj.type = 'Create Wh Capacity'
            apiParams = { ...data, ...apiTempParams }

        }
        dispatch({ type: sagaActions.WAREHOUSE_META_CAPACITY_ACTION, payload: apiParams, operationObj: operationObj })
    };

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const editWhCapacityPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: Object.keys(editData).length ? AppConstant.smartScheduler.whCapacity.editDataTitle : AppConstant.smartScheduler.whCapacity.addMetaData,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const closeCurrentpopup = () => {
        setEditData({});
        setShowModal(false);
    }

    const getColorIndicator = (colorString) => {
        return (
            <div className='d-flex justify-content-center align-items-center' >
                <div className='rounded-circle colourIndicator' style={{ backgroundColor: colorString }} />
                {`${colorString}`}
            </div>
        )
    }

    const openCreateForm = () => {
        setEditData({});
        setShowModal(true);
    }

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: index,
                0: el.WHStartTime ? changeFormatWithoutTz(el.WHStartTime, 'HH:mm') : '-',
                1: el.WHEndTime ? changeFormatWithoutTz(el.WHEndTime, 'HH:mm') : '-',
                2: el.EarliestApptHrs ? changeFormatWithoutTz(el.EarliestApptHrs, 'HH:mm') : '-',
                3: el.LastApptHrs ? changeFormatWithoutTz(el.LastApptHrs, 'HH:mm') : '-',
                4: el.ApptAvailableColor ? getColorIndicator(el.ApptAvailableColor) : '-',
                5: el.ApptNotAvailableColor ? getColorIndicator(el.ApptNotAvailableColor) : '-',
                6: el.WHBreakColor ? getColorIndicator(el.WHBreakColor) : '-',
                7: el.WHLeaveColor ? getColorIndicator(el.WHLeaveColor) : '-',
                8: actionsButton(el, index),
            }
        })
    }

    return (
        <div>
            {
                showCreateButton ?
                    <button
                        className={'addButton'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        onClick={() => openCreateForm()}>
                        {AppConstant.smartScheduler.whCapacity.addMetaData}
                    </button>
                    :
                    <div>
                        {
                            currentLabelsList &&
                            <DrilldownTableSS
                                themeSelected={themeSelected}
                                bodyData={currentLabelsList.length && formatListDataDrillDown(currentLabelsList)}
                                titleData={whCapacityTitleDD}
                                showCollapse={false}
                                showInput={false}
                                showSort={false}
                                arrangeOrder={arrangeOrder}
                                arrangeKey={arrangeKey}
                                drillDownReport={currentLabelsList}
                                initiateDrillDownReport={isListLoading}
                                loaderArray={9}
                                sortingAction={''}
                                subRoot={{ pathName: '' }}
                                showUTCTime={false}
                            />
                        }
                    </div>
            }
            {editWhCapacityPopup()}
        </div>
    )
}

export default DailyOperationsSS;