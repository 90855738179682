import React from 'react'
import SchedulerDatePicker from '../Components/SchedulerDatePicker/SchedulerDatePicker';
import { getCurrentDate } from '../../../../Utils/DateUtils';
import { AppConstant } from '../../../Assests/AppConstant';
import CustomDropDown from '../../Common/CustomDropDown/CustomDropDown';
import _ from 'lodash';

const SchedulerHeaderBar = (props) => {
  const { datePickerSelectedDate, changeDatePickerSelectedDate, allDock, onDockSelected } = props;
  
  return (
    <div className='d-flex flex-row justify-content-between align-items-center mx-2'>
      <div className='ms-2 d-flex justify-content-center align-items-center'>
        <SchedulerDatePicker {...props}
          disabledProps={false}
          maxDateProps={"NA"}
          minDateProps={getCurrentDate()}
          selectedDate={datePickerSelectedDate}
          onDateChange={(dates) => changeDatePickerSelectedDate(dates)}
          startDateProps={getCurrentDate()}
          endDateProps={''}
          selectedDateFormat={AppConstant.commonStrings.MM_DD_YYYY_dateFormat}
          selectsRangeProps={''}
          customInputExample={''}
          classNameProps={'px-1 border border-3 rounded my-2'}
          datePickerSelectedDate={datePickerSelectedDate}
        />
        {allDock && _.size(allDock) ? <div className='ms-2'>
          {
            <div className='d-flex flex-row fs-6 align-items-center ms-4'>
              <div className='me-2'>Select Dock :</div>
              <CustomDropDown themeSelected={props.themeSelected} data={allDock} placeHolder={'ALL'} onSelect={onDockSelected} keyStr={'dockName'} />
            </div>
            }
          </div>: ''}
      </div>      
      <div className='d-flex'>
        <div className='cursor-pointer'> <i className="fa fa-fw fa-refresh ss-icon"></i></div>
        <div className='cursor-pointer' onClick={() => props.openModel()}> <i className="fa fa-fw fa-plus ss-icon"></i></div>
      </div>
    </div>
  );
}

export default SchedulerHeaderBar;