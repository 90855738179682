import React, { useEffect, useState } from "react";
import "./DatePickerSS.css";
import { dayNames, monthNames } from "./DatePickerSSData";
import { getDateSlots, getNumberOfDaysInMonth, getTimeFromStart } from "./DatePickerSSUtils";
import CustomDate from "./CustomDate";

const DatePickerSS = ({ minDate, maxDate, selectedDate, setSelectedDate, setLoading, closeCalendar }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [dayList,setDayList] = useState([])
    // const [selectedDate, setSelectedDate] = useState(null);
    //    WP-3575 - When selected date value set caledar as per those days`
    useEffect(() => {
        if (selectedDate) {
            setCurrentMonth(new Date(selectedDate).getMonth())
            setCurrentYear(new Date(selectedDate).getFullYear())
        }
    }, [selectedDate])
    
    const nextMonth = () => {
        if (currentMonth < 11) {
            setCurrentMonth((prev) => prev + 1);
        } else {
            setCurrentMonth(0);
            setCurrentYear((prev) => prev + 1);
        }
    };
    const prevMonth = () => {
        if (currentMonth > 0) {
            setCurrentMonth((prev) => prev - 1);
        } else {
            setCurrentMonth(11);
            setCurrentYear((prev) => prev - 1);
        }
    };

    const handleSelction = (event) => {
        if (event.target.id === "day") {
            setLoading && setLoading(true)
            setSelectedDate(
                new Date(
                    currentYear,
                    currentMonth,
                    event.target.getAttribute("data-day")
                )
            );
            if (closeCalendar) {

                closeCalendar()
            }
        }
    };

    const isMaxAllowed = () => {
        if (
            maxDate && maxDate?.getTime() <=
            getTimeFromStart(getNumberOfDaysInMonth(currentYear, currentMonth),currentMonth,currentYear)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const isMinAllowed = () => {
        if (minDate && minDate?.getTime() >= getTimeFromStart(1,currentMonth,currentYear)) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(()=>{
        setDayList(getDateSlots(currentMonth,currentYear))
      },[currentMonth,currentMonth])

    return (
        <>
            <div className="picker-wrapper" data-testid="custom-date-picker">
                <div className="picker-header mb-2">
                    <button onClick={prevMonth} className={isMinAllowed() ? 'picker-navigation disabled' : "picker-navigation"} disabled={isMinAllowed()} data-testid='custom-date-picker-prev'>
                    <span className="font-default">  <i className="fa-solid fa-angle-left fa-sm"></i></span>
                    </button>
                    <p className="fw-bold">
                        {monthNames[currentMonth]} {currentYear}
                    </p>
                    <button onClick={nextMonth} className={isMaxAllowed() ? 'picker-navigation disabled' : "picker-navigation"} disabled={isMaxAllowed()}  data-testid='custom-date-picker-next'>
                       <span className="font-default"> <i className="fa-solid fa-angle-right fa-sm"></i></span>
                    </button>
                </div>
                <div className="picker-body my-2">
                {dayNames.map((day, index) => (
                        <p key={index} className="text-center"  data-testid='week-days'>{day}</p>
                    ))}
                </div>
                <div className="picker-body mt-2">
                    {dayList.map(
                        (day, index) => (
                            <CustomDate
                            key={index}
                            day={day}
                            maxDate={maxDate}
                            minDate={minDate}
                            currentMonth={currentMonth}
                            currentYear={currentYear}
                            selectedDate={selectedDate}
                            handleSelction={handleSelction}/>
                           
                        )
                    )}
                </div>
            </div>
            <div className='d-flex gap-3 mx-1 mb-2'>
      <div className='f-12 d-flex align-items-center gap-2'>
        <div className='code_date_available'/>
        Available Dates
        </div>
        <div className='f-12 d-flex align-items-center gap-2'>
        <div className='code_date_selected'/>
        Selected Date
     </div>
     </div>
        </>
    );
};
export default DatePickerSS;
