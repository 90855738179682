import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import CommonChart from '../../../Graphs/CommonChart';
import _ from 'lodash';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { useDispatch } from 'react-redux';
import { uuidv4 } from '../../../../../../../../Assests/Utility';

const DoorUsageSummary = (props) => {
    const { doorUsageSummaryChart, themeSelected, doorUsageData, toggleDoorUsageSummaryData, doorUsageSummarySelectedDockState } = props;
    const navigate = useNavigate();
    const [doorData, setDoorData] = useState(null);

    useEffect(() => {
        setDoorData(null);
        if (!_.isEmpty(doorUsageSummaryChart)) {
            setDoorData(doorUsageSummaryChart)
        }
    }, [doorUsageSummaryChart])

    const getSubRoot = () => {
        if (!_.isEmpty(doorUsageSummarySelectedDockState)) {
        navigate(`/analytics/reports/doorusagesummary/${doorUsageSummarySelectedDockState?.dock_name}`)
        }
    }

    const createBtn = () => {
        return (
            <div className='pt-5'>
                <button type="button" className={` buttonFunnelDash ${doorUsageData === 'Trucks' ? 'selectedButton' : 'unselectedButton'}  me-1`} onClick={() => toggleDoorUsageSummaryData('Trucks')}>{AppConstant.dashboard.defaultdashboard.doorUsageSummary.trucksHeading}</button>
                <button type="button" className={` buttonFunnelDash ${doorUsageData === 'Pallets' ? 'selectedButton' : 'unselectedButton'} me-1`} onClick={() => toggleDoorUsageSummaryData('Pallets')}>{AppConstant.dashboard.defaultdashboard.doorUsageSummary.palletsHeading}</button>
            </div>
        )
    }

    return (
        <div className={`col gridSeparator ms-2 ${themeSelected} col-12} bg-transparent pt-3`}>
            <div onClick={() => getSubRoot()} style={{ color: 'inherit', textDecoration: 'inherit', cursor: 'pointer'}}>
            <div className='d-flex col-12 pt-1 justify-content-between'>
                {/* <Link to="/analytics/reports/doorusagesummary" style={{ color: 'inherit', textDecoration: 'inherit' }}> */}
                    <span className='graphHeading'>{AppConstant.dashboard.defaultdashboard.doorUsageSummary.heading}</span>
                {/* </Link> */}
                {/* <span className='graphHeading'>{createBtn()}</span> */}
            </div>
            {/* <Link to="/analytics/reports/doorusagesummary" style={{ color: 'inherit', textDecoration: 'inherit' }}> */}
                <div className='d-flex flex-row pt-4'>
                    <div className="flex-grow-1 d-flex align-items-center justify-content-center responsive-chart detention-legend ">{doorData?.options?.labels ? <CommonChart data={doorData} key={uuidv4()} />: ''}</div>
                </div>
                {/* </Link> */}
                </div>
        </div>
    );
}

export default DoorUsageSummary;