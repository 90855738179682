import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import SectionColumn from './SectionColumn';

const SectionRow = (props) => {
    const { el, themeSelected, deleteSection, addChildren, index, widgetList, selectWidgetsItems, selectedWidgetsArr } = props;
    return (
        <Draggable key={el.id} draggableId={el.id} index={index}>
            {(provided) => (
                <div className='w-100 m-2' key={el.id} id={el.id} ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <div className="row w-100">
                        {
                            el.children.map(cEl => {
                                return (
                                    <>
                                        <SectionColumn
                                            key={cEl.id}
                                            cEl={cEl}
                                            el={el}
                                            themeSelected={themeSelected}
                                            deleteSection={(el, cEl) => deleteSection(el, cEl)}
                                            addChildren={(el) => addChildren(el)}
                                            selectWidgetsItems={(el, cEl, widget) => selectWidgetsItems(el, cEl, widget)}
                                            selectedWidgetsArr={selectedWidgetsArr}
                                            index={index}
                                            widgetList={widgetList}
                                        />
                                            {provided.placeholder}
                                    </>
                                )
                            })
                        }

                    </div>
                </div>
            )}
        </Draggable>
    );
}

export default SectionRow;
