
const Cell = (props) => {
  const {
    day,
    start,
    end,
    editable,
    height,
    children,
    showScheduleModal,
    setShowScheduleModal
  }= props;


  if (!editable) return null;


  return (
  <>
    <button style={{background:"none",border:"none"}} onClick={()=>{setShowScheduleModal(true)}}></button>

    </>
  );
};

export default Cell;
