import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import './CarrierApptsListScreen.css';
import SendEmail from '../../../../../sendEmail';
import Popup from '../../../../../Common/Popup/Popup';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import CarrierApptListRows from './CarrierApptListRows/CarrierApptListRows';
import { smartScheduleSelector } from '../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { useDispatch } from 'react-redux';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { useNavigate } from 'react-router-dom';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import InitalAppointmentSelectionScreen from '../InitalAppointmentSelectionScreen/InitalAppointmentSelectionScreen';
import AddMorePOPopup from '../../../../../SmartSchedule/Popups/AddMorePOPopup';
import { showAlert } from '../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import CustomTooltip from '../../../../../Common/Tooltip/CustomTooltip';
import SendEmailSS from '../../../../../sendEmailSS';

const CarrierApptsListScreen = (props) => {

    const { data, themeSelected = 'light-mode', screenTypeClassname } = props;
    const [showAddPoModel, setShowAddPoModel] = useState(false);
    const [showSendMailModal, setShowSendEmailModal] = useState(false);
    const [selectedPoObj, setSelectedPoObj] = useState({});
    const [scheduleScreenType, setScheduleScreenType] = useState('');
    const [triggerRecalculate, setTriggerRecalculate] = useState(false);
    const ss = useSelector(smartScheduleSelector);
    const { searchedPOData, poForAppt, initiateMultiplePO } = ss;
    const user = useSelector(getUserSelector);
    const { userType } = user;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selectedRowData, setSelectedRowData] = useState();
    useEffect(() => {
        if (_.size(searchedPOData) && props.showSuccess === false) {
            setScheduleScreenType('text')
        }
    }, [searchedPOData])


    useEffect(() => {
        if (Object.keys(selectedPoObj) && Object.keys(selectedPoObj).length > 0) {
            setShowSendEmailModal(true);
        }
    }, [selectedPoObj])

    useEffect(() => {
        renderScheduleAppointmentScreen()
    }, [scheduleScreenType])

    useEffect(() => {
        if (_.size(poForAppt) && showAddPoModel) {
            setTriggerRecalculate(true)
            initiateMultiplePO && dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: false });
            setScheduleScreenType('initialscreen')
            closeAddMorePOForm()
        } else {
            _.size(poForAppt) && setTriggerRecalculate(true)
        }
    }, [poForAppt])

    const ssSchedulehandler = (rowData) => {
        props?.setShowSuccess(false);
        setSelectedRowData(rowData);
        // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [rowData] })
        dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: searchedPOData })
        if (_.upperCase(userType) === _.upperCase('USER')) {
            // navigate('/wpscheduleappointments') // Commented to implement for new WP Schedule flow, 
            setScheduleScreenType('initialscreen')
        } else if (_.upperCase(userType) === _.upperCase('CARRIER')) {
            setScheduleScreenType('initialscreen')
        }
    }

    const renderApptTableRows = () => {
        return data.map((ele, rowIndex) => {
            if (rowIndex === 0) {
                return (<CarrierApptListRows
                    userType={userType}
                    rowIndex={rowIndex}
                    searchedPOData={data}
                    rowData={ele}
                    poForAppt={poForAppt}
                    openAddMorePOForm={openAddMorePOForm}
                    triggerRecalculate={triggerRecalculate}
                    emailClickHandler={(rowData) => openSendEmailForm(rowData)
                    }
                    ssSchedulehandler={(rowData) => ssSchedulehandler(rowData)}
                />)
            }
        })
    }

    const openSendEmailForm = (rowData) => {
        setSelectedPoObj(rowData);
    }

    const closeSendMailpopup = () => {
        setShowSendEmailModal(false);
        setSelectedPoObj({})
    }

    const formFieldListViewAppointment = [
        {

            type: "string",
            size: "col-sm-12",
            position: 1,
            name: "Po Number",
            value: selectedPoObj.PoNumber,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 2,
            name: "Dock",
            value: selectedPoObj.DockName,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 3,
            name: "Commodity",
            value: selectedPoObj.Commodity,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 4,
            name: "Buyer",
            value: selectedPoObj.BuyerName,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 5,
            name: "Vendor",
            value: selectedPoObj.VendorName,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 6,
            name: "Quantity",
            value: selectedPoObj.Quantity,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 7,
            name: "Cases",
            value: selectedPoObj.ItemsCases,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 8,
            name: "Pallets",
            value: selectedPoObj.Pallets,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 9,
            name: "Weight",
            value: selectedPoObj.Weight,
            formValidation: {
                required: true,
            },
            disabledField: true
        }, {

            type: "string",
            size: "col-sm-12",
            position: 10,
            name: "Warehouse",
            value: selectedPoObj.WarehouseName,
            formValidation: {
                required: true,
            },
            disabledField: true
        }, {

            type: "string",
            size: "col-sm-12",
            position: 10,
            name: "ZipCode",
            value: selectedPoObj.ReceivingZipCode,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 11,
            name: "Appointment Start",
            value: moment(selectedPoObj.ApptStartTime).format('DD/MM/YYYY HH:mm A'),
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 13,
            name: "Due Date",
            value: moment(selectedPoObj.DueDate).format('DD/MM/YYYY HH:mm A'),
            formValidation: {
                required: true,
            },
            disabledField: true
        }
    ]

    const openSendEmail = () => {
        return <SendEmailSS closeSendMailpopup={closeSendMailpopup} formFieldList={formFieldListViewAppointment} poDetails={selectedPoObj}></SendEmailSS>
    }

    const renderText = () => {
        if (_.size(data)) {
            const masterPO = data[0];
            const dueDate = moment(masterPO.DueDate).utc().startOf('day');
            const todayDate = moment().utc().startOf('day')
            if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Date Expired')) {
                return <span className='text-red'>{AppConstant.smartScheduler.searchAppointments.dateExpiredMassage}</span>
            }
            else if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Not Scheduled')) {
                return 'Click on Schedule to proceed'
            } else if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Scheduled')) {
                return 'Click on Reschedule to proceed'
            } else if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Completed')) {
                return 'Appointment is already completed'
            }
        }
    }

    const renderScheduleAppointmentScreen = () => {
        switch (scheduleScreenType) {
            case 'text':
                return (
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <span className='text-center bolderText'>{renderText()}</span>
                    </div>
                )
            case 'initialscreen':
                return (
                    <InitalAppointmentSelectionScreen showSuccess={props.showSuccess} setShowSucces={props.setShowSuccess} rowData={data} selectedRowData={selectedRowData} userType={userType} triggerReCalculate={triggerRecalculate} setTriggerRecalculate={setTriggerRecalculate} {...props} />
                )
            default:
                break;
        }
    }


    const openAddMorePOForm = () => {
        initiateMultiplePO && dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: false });
        if (scheduleScreenType === 'text') {
            showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.poSection.scheduleDataWarning);
            return;
        }
        setShowAddPoModel(true);
    };
    const closeAddMorePOForm = () => {
        // setEditData({});
        setShowAddPoModel(false);
    };
    const onAddRemoveHandler = (data, type) => {
        if (!_.isEmpty(selectedRowData)) {
            const masterPO = selectedRowData;
            const IDWarehouse = selectedRowData?.IDWarehouse;
            const IDDock = masterPO.IDDock;

            if (type === "ADD") {
                dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: true });
                // dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true });
                const newData = data.map((el) => {
                    const { poNumber, ReceivingZipCode } = el;
                    if (poNumber && ReceivingZipCode) {
                        return {
                            PoNumber: poNumber,
                            ReceivingZipCode,
                            IDDock,
                            IDWarehouse,
                        };
                    }
                });

                if (_.size(data)) {
                    //   Always send last array obj as it is the newly added value, previous PO numbers cannot be edited only deleted
                    const newData = data[data.length - 1]
                    const masterPO = poForAppt[0]
                    if (masterPO?.PORelationID === newData.poNumber) {
                        showAlert(toast.TYPE.ERROR, 'Same PO cannot be added as an add-on');
                        dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: false });
                        return
                    }
                    const paramData = {
                        PoNumber: newData.poNumber,
                        ReceivingZipCode: newData.ReceivingZipCode,
                        IDDock,
                        IDWarehouse,
                    }
                    dispatch({
                        type: sagaActions.ADD_REMOVE_PO,
                        payload: { payload: [paramData], type: type },
                    });
                }

            } else if (type === "REMOVE") {
                const newData = poForAppt.map((el) => {
                    if (el.PoNumber !== data.poNumber) {
                        return el;
                    } else {
                        return null;
                    }
                });
                const filteredData = _.compact(newData);
                const finalData = _.uniqBy(filteredData, "IDPO");
                const fData = _.find(poForAppt, { PoNumber: data.poNumber })
                if (!_.isEmpty(fData)) {
                    let wpID = null;
                    if (fData?.IDWarehouse) {
                        wpID = fData?.IDWarehouse
                    } else {
                        wpID = fData?.WarehouseData?.ID
                    }
                    const newItemPayload = {
                        PoNumber: fData?.PoNumber && fData?.PoNumber?.includes(',') ? fData?.PoNumber : fData?.PoNumber + ',',
                        IDWarehouse: wpID,
                        ReceivingZipCode: fData.ReceivingZipCode,
                        ApptStartDate: fData.ApptStartDate,
                        ApptStartTime: fData.ApptStartTime,
                        ApptEndDate: fData.ApptEndDate,
                        ApptEndTime: fData.ApptEndTime,
                        ApptStatus: fData.ApptStatus,
                        Door: fData.Door,
                        ApptCreatedBy: fData.ApptCreatedBy,
                        IDDock: fData.IDDock,
                        IDCarrier: fData.IDCarrier,
                        CRUDTYPE: 'D',
                        DeletedPos: ''
                    }
                    dispatch({
                        type: sagaActions.ADD_REMOVE_PO,
                        payload: { apiPayload: newItemPayload, type: type, payload: finalData },
                    });
                }
            }
        } else {
            console.log('props?.selectedRowData has no data')
        }
    };


    const openAddMorePO = () => {
        return (
            <AddMorePOPopup
                closeSendMailpopup={closeAddMorePOForm}
                zipCode={selectedRowData?.WarehouseData?.Zip}
                actionHandler={(data, type) => {
                    onAddRemoveHandler(data, type);
                }}
                initateLoading={initiateMultiplePO}
                poForAppt={poForAppt}
            />
        );
    };
    const showAddPoPopup = () => {
        const popupObj = {
            id: "addPO",
            modalLabel: "addPO",
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: "md",
            title: "Add PO's",
            bodyText: openAddMorePO(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle,
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
                        .saveButtonTitle,
                },
            },
        };

        return (
            <Popup
                {...popupObj}
                popupBtnHandler={(text) => () => ""}
                closepopup={closeAddMorePOForm}
                themeSelected={themeSelected}
                showModal={showAddPoModel}
            />
        );
    };

    const showAppointmentsList = () => {
        return (
            <div className={screenTypeClassname}>
                <div className="d-flex flex-row m-1 min-h-42">
                    <div className="col-5 boxShadow-1 p-0 mx-3">
                        <table className='h-100'>
                            <thead />
                            {renderApptTableRows()}
                        </table>
                    </div>

                    <div className="col-6 flex-fill mx-3 boxShadow-1 appointmentdatelist">
                        <div className='mx-2'>
                            {renderScheduleAppointmentScreen()}
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    const getColorIndicator = (colorString, textStr) => {
        return (
            <div className='d-flex justify-content-center align-items-center' >
                <div className=' colourIndicatorPOLookup' style={{ backgroundColor: colorString }} />
                <label className='appointmentStatusLable'>{`${textStr}`}&nbsp;&nbsp;</label>
            </div>
        )
    }

    const getColorsArr = () => {
        const colorsArr = [
            {
                name: 'Not Scheduled',
                color: '#CC4141'
            },
            {
                name: 'Scheduled',
                color: '#009933'
            },
            {
                name: 'Rescheduled',
                color: '#ff6600'
            },
            {
                name: 'Past Appointment',
                color: '#000000'
            },

        ]

        return (
            colorsArr.map((ele, i) => {
                return getColorIndicator(ele.color, ele.name)
            })
        )
    }

    const showSendEmailPopup = () => {
        const popupObj = {
            id: 'createAppointment',
            modalLabel: 'sendEmail',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: 'Send Email',
            bodyText: openSendEmail(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeSendMailpopup} themeSelected={themeSelected} showModal={showSendMailModal} />
    }

    const showNoDataError = () => {
        return (
            <div className='d-flex flex-column align-items-center noDataAvailable m-0 p-0'>
                <span className={`no-data-text1 ${themeSelected}`}>{'No Appointments available for this PO.'}</span>
            </div>
        )
    }

    return (
        <div>

            <div className='seperator' />
            {data && data.length ?
                <div>
                    <div className='d-flex justify-content-end fs-6'>
                        {/* <div className=''>{AppConstant.commonStrings.appointments}: </div> */}
                        <div className='d-flex'>
                            {getColorsArr()}
                        </div>
                    </div>
                    <div className='seperator' />

                    {showAppointmentsList()}
                    {showSendEmailPopup()}
                </div>
                :
                <div>{showNoDataError()}</div>
            }
            {showAddPoPopup()}
        </div>
    )
}

export default CarrierApptsListScreen;