import React, { useEffect, useState } from "react";
import { ReactComponent as UploadPic } from "../../../Assests/Svg/file-upload.svg";
import "./fileuploadSS.css";
import { AppConstant } from "../../../Assests/AppConstant";
/**
 * This is reusable component for uploading file with new UI
 * @param {} props 
 * showError = show/hide error variable
 * errorMessage = variable to display error message
 * isLoading = Loader variable
 * sizeConfig = file size
 * accept = format to accept
 * name = required by HTML input element
 * showImageLogo = display image logo
 * imageSrc = image source
 * title = Upload title or default title
 * @returns 
 */
const FileUpload = (props) => {
  const { showError, errorMessage, isLoading, sizeConfig, accept, name, showImageLogo, imageSrc, title } = props;
  const [error, seterror] = useState(showError);
  /**
   * useEffect to show error
   */
  useEffect(() => {
    seterror(showError);
  }, [showError]);

  return (
    <>
      <div className="file-up-container-ss" data-testid={AppConstant.dataTestId.fileUpload}>
        <div className="file-up-main-sec">
          <div className="file-rel">
            <span>
              <UploadPic />
              <span className="file-text">{title ? title : "Upload File"}</span>
            </span>
            <input
              type="file"
              className="file-input"
              name={name}
              accept={accept}
              data-testid={AppConstant.dataTestId.fileUploadInput}
              onInput={(data) => props.onFileChange(data.target.files[0], data.target.name)}
            />
            {showImageLogo && imageSrc && <img src={imageSrc} className="img-logo" data-testid={AppConstant.dataTestId.image} />}
          </div>
          {isLoading.loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              data-testid={AppConstant.dataTestId.loading}
            />
          )}

        </div>
        <div className="inst-text w-50">{sizeConfig}</div>
      </div>
      {error && (
        <span className="errorMessage" data-testid={AppConstant.dataTestId.validationError}>
          {errorMessage ? errorMessage : error}
        </span>
      )}
    </>
  );
};

export default FileUpload;
