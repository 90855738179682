import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { modifyPODisplay } from '../../../../../../../../Assests/Utility';
import DrilldownTable from '../../Common/DrilldownTable';

const DrillDownTop10LateLoads = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { top10LateLoadsDrillDown, arrangeOrder, arrangeKey, initiateDrillDownReport, selectedTop10LateLoad, dateRangeObj } = defaultDashboard;
    const userPermission = useSelector(getUserSelector);
    const {selectedWarehouse} = userPermission;

    let  unloadingTypeSummaryTitleDD = [
        { id: 'PoNum', name: 'Po' },
        // _.upperCase(subRoot?.subPathName) === 'VENDOR' ? { id: 'Vendor', name: 'Vendor' } : { id: 'CarrierName', name: 'Carrier' },
        { id: 'Vendor', name: 'Vendor' },
        { id: 'CarrierName', name: 'Carrier' },
        { id: 'Appointment', name: 'Appt Time' },
        { id: 'CheckedIn', name: 'Checked in' },
        { id: 'FistTimeLate', name: 'First Time Late' },
        { id: 'SomeTimesLate', name: 'Sometimes Late' },
        { id: 'AlwaysLate', name: 'Always Late' },
        
    ]
    const createPayload = () => {
        return {dock_id: dockIdArr.toString() === '' ? null : dockIdArr.toString(), isDrillDown: '1', warehouse_id: selectedWarehouse.ID, 'late_status' : selectedTop10LateLoad, type: subRoot?.subPathName,  filterByID: subRoot?.queryElement}
    }
    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true})
        dispatch({ type: sagaActions.GET_TOP_10_LATE_LOADS_DRILLDOWN, payload: createPayload() });
    }, [])

    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true})
        dispatch({ type: sagaActions.GET_TOP_10_LATE_LOADS_DRILLDOWN, payload: createPayload() });
    }, [dateRangeObj])

    useEffect(() => {
        if (top10LateLoadsDrillDown && top10LateLoadsDrillDown.length) {
            let data = formatDrillDownData(top10LateLoadsDrillDown)
            setDrilldownData(data)
            dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: false})
        }
    }, [top10LateLoadsDrillDown])

    const formatDrillDownData = (data) => {
        return data.map(el => {
            let splitArray = []
            if(el.PoNum){
                splitArray = el.PoNum.split(',')
            }
            return {       
                id: el.ID,
                0: modifyPODisplay(splitArray) || '-',
                1: el.Vendor || '-',
                2: el.CarrierName || '-',
                // 1:  _.upperCase(subRoot?.subPathName) === 'VENDOR' ? el.Vendor || '-': el.CarrierName || '-',
                3: el.Appointment || '-',
                4: el.CheckedIn || '-',
                5: el.FistTimeLate || '-',
                6: el.SomeTimesLate || '-',
                7: el.AlwaysLate || '-',
            }
        })
    }

    return (
        <DrilldownTable showUTCTime={true} themeSelected={themeSelected} bodyData={drilldownData} titleData={ unloadingTypeSummaryTitleDD} showCollapse={false} showInput={true} showSort={true} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={top10LateLoadsDrillDown} initiateDrillDownReport={initiateDrillDownReport} loaderArray={8} subRoot={subRoot} sortingAction={sagaActions.SORT_DRILLDOWN_REPORT} />
    );
}

export default DrillDownTop10LateLoads;