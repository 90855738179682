import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { sagaActions } from "../../../../../../../sagas/sagaActions";
import CustomButtonSS from "../../../../../Common/CustomButton/CustomButtonSS";
import { AppConstant } from "../../../../../../Assests/AppConstant";
import DrilldownTableSS from "../../../../../LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS";
import { getUserSelector } from "../../../../../../../redux/reducers/reducerSlices/UserReducer";
import { paytraceReducer } from "../../../../../../../redux/reducers/reducerSlices/PaytraceReducer";
import { showAlert } from "../../../../../../Assests/Utility";
import SSPopup from "../../../../../Common/Popup/SSPopup/SSPopup";
import successIcon from '../../../../../../Assests/Images/success-icon.png'
import errorIcon from '../../../../../../Assests/Images/error-icon.png'
/** This component renders new UI user subscription in table view */
const UserPaymentsListSS = (props) => {

    const { themeSelected, searchStr, selectedTab } = props;
    const dispatch = useDispatch();
    const user = useSelector(getUserSelector);
    const { cu_id } = user;
    const [isListLoading, setListLoading] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [selectedPaymenyObj, setSelectedPaymenyObj] = useState({});
    const { mySubscriptionList, operationObj } = useSelector(paytraceReducer);
    const [showModal, setShowModal] = useState(false);
    const [userPaymentsArr, setUserPaymentsArr] = useState([]);
     /** Initial useEffect */
    useEffect(() => {
        setShowModal(false)
        setSelectedPaymenyObj({})
        fetchMySubscriptions();
    }, [])

    /**
     * useEffect to render mySubscriptionList and set mySubscriptionList to local variable
     */
    useEffect(() => {
        if (mySubscriptionList && mySubscriptionList.SubscriptionList && mySubscriptionList.SubscriptionList.length) {
            setUserPaymentsArr(mySubscriptionList.SubscriptionList)
        } else {
            setUserPaymentsArr([])
        }
    }, [mySubscriptionList])

    useEffect(() => {
        if (operationObj.type && operationObj.type === 'Fetch Payments') {
            setListLoading(false);
            if (!operationObj.status) {
                showAlert(toast.TYPE.ERROR, "Unable to fetch subscriptions.", themeSelected);
            }
        }
    }, [operationObj])
     /**
     * Subscription list data title header array
     */
    const userPaymentsTitleDDSubscriptons = [
        { id: '', name: 'Serial No.' },
        { id: '', name: 'Subscription ID' },
        { id: '', name: 'Account No.' },
        { id: '', name: 'Subscription Start Date' },
        { id: '', name: 'Subscription End Date' },
        { id: '', name: 'Transaction Type' },
        { id: '', name: 'Transaction Date & Time' },
    ]
    const userPaymentsTitleDD = [
        { id: '', name: 'Serial No.' },
        { id: '', name: 'Subscription ID' },
        { id: '', name: 'Payment Mode' },
        { id: '', name: 'Card No.' },
        { id: '', name: 'Reference No.' },
        { id: '', name: 'Payment Status' },
        { id: '', name: 'Amount ($)' },
        { id: '', name: 'Transaction Type' },
        { id: '', name: <span>Transaction<br /> Date & Time</span> },
    ]
     /**
     * function to fetch subscription payments
     */
    const fetchMySubscriptions = () => {
        setListLoading(true)
        const apiParams = {
            "CuID": cu_id
        }

        const operationObj = {
            type: 'Fetch Payments',
            status: false
        }
        setTimeout(() => {
            dispatch({ type: sagaActions.MY_SUBSCRIPTIONS_LIST_ACTION, payload: apiParams, operationObj: operationObj });
        }, 700)
    }

    const viewPayment = (groupObj) => {
        setSelectedPaymenyObj(groupObj)
    }

    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className=''>
                    <CustomButtonSS
                        isLoading={currentItemIndex !== 'NA' && currentItemIndex === index ? true : false}
                        title={`View`}
                        className={`carrierApptBtn btnAction ${!groupObj.Status ? 'btnDelete' : 'btnEnable'}  mx-1`}
                        onClick={() => viewPayment(groupObj, index)}
                    />
                </span>
            </div>
        )
    }
     /**
     * function to view payments
     * @param {*} groupObj 
     */
    const viewPaymentPopup = (obj) => {
        setSelectedPaymenyObj(obj)
        setShowModal(true)
    }

    const actionButtons = (el, index) => {
        return (
            <div className='d-flex justify-content-center my-2'>
                <CustomButtonSS
                    isLoading={currentItemIndex !== 'NA' && currentItemIndex === index ? true : false}
                    title={`View`}
                    data-bs-toggle="modal"
                    className={`carrierApptBtn btnAction scheduled mx-1`}
                    onClick={() => viewPaymentPopup(el, index)}
                />
            </div>
        )
    }

    const showPaymentPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            alignType: 'center',
            type: 'info',
            title: AppConstant.smartScheduler.subscription.paymentSlip,
            bodyText: createPaymentPopup(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const filterPaymentList = (data) => {
        let newList = data.filter((ele) => ele.CancledOn === null)
        return newList
    }

    /**
     * format drilldown list data to render in table
     * Reusable component DrilldownTable needs data always in this format
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {
        if (selectedTab === 'subscriptionList') {
            return data.map((el, index) => {
                return {
                    id: index,
                    0: index + 1,
                    1: el.IDSubs,
                    2: el.AccountNumber,
                    3: el.SubsStart,
                    4: el.SubsEnd,
                    5: el.TransType || '-',
                    6: el.CreatedOn
                }
            })
        }
        else {
            return filterPaymentList(data).map((el, index) => {
                return {
                    id: index,
                    0: index + 1,
                    1: el.IDSubs,
                    2: el.PaymentMode,
                    3: el.CardNumber,
                    4: el.PaymentRefernceNumber,
                    5: el.PaymentStatus === "success" ? <span className=" table-status-icon"><img src={successIcon} alt="" /> Successful</span> : <span className=" table-status-icon"><img src={errorIcon} alt="" />{el.PaymentError}</span>,
                    6: '25',
                    7: el.TransType || '-',
                    8: el.PaymentDate
                }
            }

            )
        }

    }

    /**
     * Close popup handler
     */
    const closeCurrentpopup = () => {
        setSelectedPaymenyObj({})
        setShowModal(false)
    }

    const createPaymentPopup = () => {
        return (
            <div>
                <p>{`${AppConstant.smartScheduler.subscription.paymentIdTitle} ${selectedPaymenyObj.paymentId}`}</p>
                <p>{`${AppConstant.smartScheduler.subscription.paymentTitle} ${selectedPaymenyObj.payment}`}</p>
                <p>{`${AppConstant.smartScheduler.subscription.dateTitle} ${selectedPaymenyObj.date + ' ' + selectedPaymenyObj.time}`}</p>
            </div>
        )
    }

    return (
        <div className="mt-2 subscriptionList-ss">
            <DrilldownTableSS
                themeSelected={themeSelected}
                bodyData={userPaymentsArr.length && formatListDataDrillDown(userPaymentsArr)}
                titleData={selectedTab === 'subscriptionList' ? userPaymentsTitleDDSubscriptons : userPaymentsTitleDD}
                showCollapse={false}
                showInput={false}
                showSort={true}
                arrangeOrder={'arrangeOrder'}
                arrangeKey={'arrangeKey'}
                drillDownReport={userPaymentsArr}
                initiateDrillDownReport={isListLoading}
                loaderArray={8}
                sortingAction={''}
                subRoot={{ pathName: '' }}
                showUTCTime={false}
            />
            {showPaymentPopup()}
        </div>
    )
}

export default UserPaymentsListSS;