import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { getSuperAdminSelector } from '../../../../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import { useNavigate } from 'react-router-dom';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import CommonToggleSwitch from '../../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import CustomButton from '../../../../../../Common/CustomButton/CustomButton';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { initiateSearch } from '../../../../../../../Assests/Utility'
import './RolesList.css';

const RolesList = (props) => {

    const { themeSelected, editRoleAction, searchStr, allowPermissionE, allowPermissionA  } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rolesArr, setRolesArr] = useState([])
    const superAdmin = useSelector(getSuperAdminSelector);
    const { rolesList, initateLoading, arrangeKey, arrangeOrder } = superAdmin;

    const rolesTitleDD = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'RoleName', name: 'Name' },
        { id: 'RoleDescription', name: 'Description' },
        { id: 'CreatedOn', name: 'Created On' },
        { id: 'status', name: 'Status' },
        { id: 'actions', name: 'Actions' },
    ]

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(rolesList, searchStr, 'RoleName');
            setRolesArr(searchedArr);
        } else if (searchStr === '') {
            setRolesArr(rolesList)
        }
    }, [searchStr])

    useEffect(() => {
        getRolesList();
    }, [])

    useEffect(() => {
        if (rolesList.length) {
            initateLoading && dispatch({ type: sagaActions.INITIATE_SUPER_ADMIN_LOADING, payload: false })
            if (searchStr) {
                const searchedArr = initiateSearch(rolesList, searchStr, 'RoleName');
                setRolesArr(searchedArr);
            } else {
                setRolesArr(rolesList);
            }
        }
    }, [rolesList])

    const getRolesList = () => {
        const params = {
            "role_id": null,
            "role_name": null,
            "role_des": null,
            "enable_status": null,
            "crud_type": "S"
        }
        dispatch({ type: sagaActions.GET_ROLES_LIST, payload: params });
        dispatch({ type: sagaActions.INITIATE_SUPER_ADMIN_LOADING, payload: true })
    }

    const actionsButton = (roleObj) => {
        return (
            <div>
                <span className='' onClick={() => editClicked(roleObj)}>
                    <button
                        className={allowPermissionE ? 'addButton' : 'addButton disabled'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createRolesFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        onClick={() => editClicked(roleObj)}>
                        {AppConstant.commonStrings.edit}
                    </button>
                </span>
                &ensp;
                <span className='' onClick={() => assignFeatures(roleObj)}>
                    <CustomButton
                        className={allowPermissionA ? 'addButton' : 'addButton disabled'}
                        disabled={allowPermissionA ? '' : 'disabled'}
                        title={'Assign'}
                        isLoading={false}>
                    </CustomButton>
                </span>
            </div>
        )
    }

    const editClicked = (roleObj) => {
        editRoleAction(roleObj)
    }

    const assignFeatures = (obj) => {
        navigate('/rolefeatureseditlist', { state: { props: obj } })
    }

    const updateRoleApi = (roleObj) => {
        const params = {
            "role_id": roleObj.RoleID,
            "role_name": roleObj.RoleName,
            "role_des": roleObj.RoleDescription,
            "enable_status": roleObj.EnableStatus,
            "crud_type": "U"
        }
        dispatch({ type: sagaActions.GET_ROLES_LIST, payload: params });
    }

    const toggleStatus = (index) => {
        const tempArr = [...rolesArr];
        const tempObj = { ...tempArr[index] };
        tempObj.EnableStatus = !tempArr[index].EnableStatus;
        tempArr[index] = tempObj;
        setRolesArr([...tempArr]);
        updateRoleApi(tempObj)
    }

    const getToggleSwitch = (value, index) => {
        return (
            <div className='d-flex justify-content-center align-items-center py-1'>
                <CommonToggleSwitch value={value}
                    onChangeValue={(e) => toggleStatus(index)} allowPermissionD={true}/>
            </div>
        )

    }

    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                id: el.RoleID,
                0: el.serial_num,
                1: el.RoleName.toUpperCase(),
                2: el.RoleDescription,
                3: el.CreatedOn,
                4: getToggleSwitch(el.EnableStatus, index),
                5: actionsButton(el),
            }
        })
    }

    return (
        <DrilldownTable
            themeSelected={themeSelected}
            bodyData={rolesArr.length && formatListDataDrillDown(rolesArr)}
            titleData={rolesTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={rolesList}
            initiateDrillDownReport={initateLoading}
            loaderArray={6}
            sortingAction={sagaActions.SORT_SUPER_ADMIN_TABLE}
            subRoot={{ pathName: 'superAdmin' }}
            showUTCTime={false}
        />
    )
}

export default RolesList;