import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: true,
    data: '',
    arrangeOrder: 'asc',
    arrangeKey: 'CreatedOn',
}

export const GetWarehouseDataReducer = createSlice({
    name: 'GetWarehouseDataReducer',
    initialState,
    reducers: {
        initiateLoading: (state) => {
            state.loading = true
        },
        addSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        addFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        },
        resetData: (state, action) => {
            state.loading = false;
            state.data = '';
        },
        updateTableData: (state, action) => {
            const { drillDownReport, arrange, key } = action.payload;
            
            state[key] = drillDownReport;
            state.arrangeKey = arrange.key;
            state.arrangeOrder = arrange.order;
           
        },
    }
});

export const { addSuccess, addFailure, initiateLoading, resetData, updateTableData } = GetWarehouseDataReducer.actions;

// Selectors
export const getWarehouseDataReducer = (state) => {
    return state.GetWarehouseDataReducer;
};

export default GetWarehouseDataReducer.reducer;
