import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { getSuperAdminSelector } from '../../../../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import CommonToggleSwitch from '../../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import AdjustableTextComponent from '../../../../../../Common/AdjustableTextComponent/AdjustableTextComponent';
import { getAuthReducer } from '../../../../../../../../redux/reducers/reducerSlices/AuthReducer';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { showAlert, dateAndTimeLocal } from '../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import moment from 'moment';
import './FaqList.css';

const FaqList = (props) => {

    const { themeSelected, editFaqAction } = props;
    const dispatch = useDispatch();
    const [faqArr, setFaqArr] = useState([])
    const superAdminUser = useSelector(getSuperAdminSelector);
    const { faqList, initateLoading, arrangeOrder, arrangeKey } = superAdminUser;
    // const user = useSelector(getAuthReducer);
    const user = useSelector(getUserSelector);
    const { cu_id } = user;
    const [showAllText, setShowAllText] = useState([]);

    const FaqTitleDD = [
        { id: 'serial_num', name: AppConstant.helpSupportFaq.tableHeaders.serialNum },
        { id: 'QuestionText', name: AppConstant.helpSupportFaq.tableHeaders.questionsTitle },
        { id: 'AnswerText', name: AppConstant.helpSupportFaq.tableHeaders.answersTitle },
        { id: 'CreatedOn', name: AppConstant.helpSupportFaq.tableHeaders.createdOnTitle },
        { id: 'AppType', name: AppConstant.helpSupportFaq.tableHeaders.appType },
        { id: '', name: AppConstant.helpSupportFaq.tableHeaders.statusTitle },
        { id: '', name: AppConstant.commonStrings.edit },
    ]

    const getFaqList = () => {

        const apiParams = {
            "CUID": null,
            "EnableStatus": null,
            "CRUDTYPE": "S",
            "QuestionText": null,
            "AnswerText": null,
            "HelpFeedbackID": null,
        }

        dispatch({ type: sagaActions.GET_FAQ_LIST, payload: apiParams });
    }

    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_LOADING, payload: true });
        getFaqList();
    }, [])

    useEffect(() => {
        if (faqList && faqList.length) {
            setFaqArr(faqList);
            initateLoading && dispatch({ type: sagaActions.INITIATE_LOADING, payload: false })
            const updatedArr = faqList.map((faqObj, index) => {
                return ({ showExpanded: false, id: index })
            })
            setShowAllText(updatedArr)
        }
    }, [faqList])

    const updateFaqListApi = (faqObj) => {
        const params = {
            // "CUID": user?.auth?.username,
            "CUID": cu_id,
            "EnableStatus": faqObj.EnableStatus,
            "CRUDTYPE": "U",
            "QuestionText": faqObj.QuestionText,
            "AnswerText": faqObj.AnswerText,
            "HelpFeedbackID": faqObj.HelpFeedbackID,
            "AppType":faqObj.Apptype
        }

        dispatch({ type: sagaActions.GET_FAQ_LIST, payload: params })


    }

    const actionsButton = (faqObj) => {
        return (
            <div>
                <span className='' onClick={() => editFaqAction(faqObj)}>
                    <button
                        className='addButton addButtonFaq'
                        data-bs-toggle="modal"
                        data-bs-target={`#createFaqFormPopup1`}>
                        {AppConstant.commonStrings.edit}
                    </button>
                </span>
            </div>
        )
    }

    const toggleStatus = (index) => {
        if (cu_id) {
            const tempArr = [...faqArr];
            const tempObj = { ...tempArr[index] };
            tempObj.EnableStatus = !tempArr[index].EnableStatus;
            tempArr[index] = tempObj;
            setFaqArr([...tempArr]);
            updateFaqListApi(tempObj);
        } else {
            showAlert(toast.TYPE.ERROR, AppConstant.helpSupportFaq.alerts.cuIdError, 'light')
        }
    }

    const getToggleSwitch = (value, index) => {
        return (
            <div className='d-flex justify-content-center align-items-center py-1 toggleSwitchFaq'>
                <CommonToggleSwitch value={value}
                    onChangeValue={(e) => toggleStatus(index)} allowPermissionD={true} />
            </div>
        )
    }

    const expandAllText = (indexFaq, index) => {
        showAllText[index].showExpanded = !showAllText[index].showExpanded;
        setShowAllText([...showAllText])

    }

    const adjustLongText = (text, index, showAllButton) => {
        return (
            text && text.length ?
                <div className="adjustedFaqText ms-5">
                    <AdjustableTextComponent
                        showAllButton={text.length > 100 && showAllButton ? true : false}
                        indexFaq={index} className={'adjustedText'} text={text}
                        wordlLimit={100} showAll={showAllText}
                        onClickHandler={(indexFaq) => expandAllText(indexFaq, index)} />
                </div>
                :
                <div />
        )

    }

    const getDateTime = (dateTimeStr) => {
        let m = moment.tz(dateTimeStr, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', "America/Denver");
        m.tz(moment.tz.guess()).format();
        return <div className='faqCreatedOn'>{`${dateAndTimeLocal(m.toString()).date}\n${dateAndTimeLocal(m.toString()).time}`}</div>;
    }

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.HelpFeedbackID + '_' + el.CreatedOn,
                0: el.serial_num,
                1: adjustLongText(el.QuestionText, index, false),
                2: adjustLongText(el.AnswerText, index, true),
                3: getDateTime(el.CreatedOn),
                4: el.Apptype || '-',
                5: <div className='faqEnableStatus'>{getToggleSwitch(el.EnableStatus, index)}</div>,
                6: <div className='toggleSwitchFaq'>{actionsButton(el)}</div>,
            }
        })
    }

    return (
        faqArr &&
        <DrilldownTable
            themeSelected={'light-mode'}
            bodyData={faqArr.length && formatListDataDrillDown(faqArr)}
            titleData={FaqTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={faqList}
            initiateDrillDownReport={initateLoading}
            loaderArray={6}
            sortingAction={sagaActions.SORT_SUPER_ADMIN_TABLE}
            subRoot={{ pathName: 'faqAdminList' }}
            showUTCTime={false}
        />
    )
}

export default FaqList;