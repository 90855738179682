import React from 'react'
import './CarrierSignUpUpdated.css'
import TruckwithText from '../Container/MainLogin/SchedulePro/MainLogin/Components/TruckWithText'
import { AppConstant } from '../../../Assests/AppConstant'
import { CarrierSignUpFormSS } from './Components/CarrierSignUpFormSS'
import { CarrierPaymentForm } from './Components/CarrierPaymentForm'
import { CarrierSignUpFormSSNew } from './Components/CarrierSignUpFormSSNew'

/** This is wrapper component for mew UI Carrier Signup */
export const CarrierSignUpUpdated = () => {
    return (
        <div
            className="container-fluid d-flex justify-content-between p-3 carrier-signup-wrapper-ss"
            id="container"
        >
           
            <div className="inner_blocks" id='carrier__signup'>
                <div className='d-flex justify-content-center w-100'>
                    <div id="logo-holder-signup">
                        <img src={AppConstant.commonStrings.schedulerProLogo} alt="FrieghtSmith logo" />
                    </div>
                </div>
                {/* -------------------- Form Section ------------------------*/}
                {/* Below commented(CarrierSignUpFormSS) component includes working flow for carrier registartion with 
                paytrace payment integration module
                CarrierSignUpFormSSNew - This component is created as per ACME warehouse onboarding requirement.
                This component creates user without  paytrace payment integration module.
                Also there are other areas which are impacted. Kinldy check WP-2466 for branch details and task,
                */}
                {/* <CarrierSignUpFormSS /> */}
                <CarrierSignUpFormSSNew />
                {/* <CarrierPaymentForm /> */}
                {/* <div className="d-flex justify-content-center full-width">
                    <span className="text-gray">Terms & Conditions</span>
                </div> */}
            </div>
            <TruckwithText />
        </div>
    )
}
