import { put, takeEvery } from "redux-saga/effects";
import { storeSelectedDate } from "../redux/reducers/reducerSlices/DateSelectorReducer";
import { sagaActions } from "./sagaActions";

function* initiateStoreDate(data) {
    try {
        yield put(storeSelectedDate(data.payload))
    } catch (error) { }
}

export function* saveSelectedDate() {
    yield takeEvery(sagaActions.STORE_SELECTED_DATE, initiateStoreDate)
}
