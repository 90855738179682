import _ from 'lodash';
import { call, put, takeEvery, delay, select, takeLatest } from 'redux-saga/effects';
import { Uchain } from '../redux/api/agent';
import { sagaActions } from './sagaActions';
import { getGatePassListSuccess, getGatePassListFailure, updateIsLoading, updateOperationObj, getGatePassDockListSuccess, getGatePassDockListFailure, clearGatePassDockList, clearError, initiateWarehouseListForGatePass, getWarehouseListForGatePassSuccess, getWarehouseListForGatePassFailure, clearWarehouseListForGatePass, clearWhError, clearGatePassError,updateIsWarehouseLoading } from '../redux/reducers/reducerSlices/GatePassReducer';
import { toast } from 'react-toastify';
import { showAlert } from '../App/Assests/Utility';
import { AppConstant } from '../App/Assests/AppConstant';
import ErrorBoundary from '../App/Components/ErrorBoundary/ErrorBunday';

/**
 * Function to call the gatepass API and get the data and also validates if any error
 * @param {*} data 
 */
function* gatePassSaga(data) {
    try {
        let tempStatusObj = { ...data.operationObj };
        yield put(updateIsLoading(true));
        const response = yield call(Uchain.gatePassDetails, data.payload)
        if (tempStatusObj.type === 'Fetch GatePass' && response) {
            tempStatusObj.status = true
            yield put(getGatePassListSuccess(response))
            yield put(updateOperationObj(tempStatusObj));
        } else if (response.data && response.data === 'Successfully Inserted' || 'Successfully Updated' || 'Successfully Deleted') {
            tempStatusObj.status = true
            yield put(updateOperationObj(tempStatusObj));
        } else {
            tempStatusObj.status = false
            yield put(updateOperationObj(tempStatusObj));
        }
    } catch (error) {
        const tempStatusObj = {...data.operationObj}
        tempStatusObj.message = error?.response?.data?.data;
        tempStatusObj.status = false
        yield put(updateOperationObj(tempStatusObj));
    }
    finally {
        yield put(updateIsLoading(false));
    }
}

export function* getGatePassWatcher() {
    yield takeEvery(sagaActions.GET_GATEPASS_DETAILS, gatePassSaga)
}

/**
 * Function to get the docks for gatepass details
 * @param {*} data 
 */
function* dockListSaga(data) {
    try {
        const response = yield call(Uchain.getDocksForGatePass, data.payload);
        yield put(getGatePassDockListSuccess(response));
    } catch {
        yield put(getGatePassDockListFailure());
    }
}

function* clearDockListSaga() {
    try {
        yield put(clearGatePassDockList());
    } catch { }
}

function* clearDockErrorSaga() {
    try {
        yield put(clearError());
    } catch { }
}

export function* getGatePassDockListWatcher() {
    yield takeEvery(sagaActions.GET_GATEPASS_DOCK_DETAILS, dockListSaga)
}

export function* clearDockWatcher() {
    yield takeEvery(sagaActions.CLEAR_GATEPASS_DOCK, clearDockListSaga)
}

export function* clearDockErrorWatcher() {
    yield takeEvery(sagaActions.CLEAR_GATEPASS_DOCK_ERROR, clearDockErrorSaga)
}
/**
 * Function to get the warehouse details for gatepass
 * @param {*} data 
 */
function* warehouseListSaga(data) {
    try {
        yield put(updateIsWarehouseLoading(true))
        const response = yield call(Uchain.getWarehouseDetailsForGatePass, data.payload);
        yield put(getWarehouseListForGatePassSuccess(response));
    } catch(error) {
        // yield put(getWarehouseListForGatePassFailure(error));
    }
    finally { yield put(updateIsWarehouseLoading(false)) }
}

function* clearWarehouseListSaga() {
    try {
        yield put(clearWarehouseListForGatePass());
    } catch { }
}

function* clearWarehouseErrorSaga() {
    try {
        yield put(clearWhError());
    } catch { }
}

function* clearGatePassErrorSaga() {
    try {
        yield put(updateOperationObj(null));
    } catch { }
}

export function* getWarehouseListWatcher() {
    yield takeLatest(sagaActions.GET_WAREHOUSE_DETAILS_FOR_GATEPASS, warehouseListSaga)
}

export function* clearWarehouseListWatcher() {
    yield takeEvery(sagaActions.CLEAR_WAREHOUSE_DETAILS_FOR_GATEPASS, clearWarehouseListSaga)
}

export function* clearWarehouseErrorWatcher() {
    yield takeEvery(sagaActions.CLEAR_WAREHOUSE_DETAILS_FOR_GATEPASS_ERROR, clearWarehouseErrorSaga)
}

export function* clearGatePassErrorWatcher() {
    yield takeEvery(sagaActions.CLEAR_GATEPASS, clearGatePassErrorSaga)
}
