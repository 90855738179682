import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { toast } from 'react-toastify';

import { showAlert, initiateSearch } from '../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import DrilldownTable from '../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import Header from '../../../../../Header/Header';
import { getPoSSselector } from '../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import Navigationbar from '../../../../../Navigationbar/Navigationbar';

import WarehouseSearch from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import DownloadSection from '../../../DownloadSection/DownloadSection';
import { usePrevious } from '../../../../../Common/CustomHooks/usePrevious';
import html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import './DriverList.css';
import { getDriverCheckinReducer } from '../../../../../../../redux/reducers/reducerSlices/DriverCheckinReducer';
import moment from 'moment-timezone';
import { smartScheduleSelector } from '../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
const DriverAppointList = (props) => {
    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allApptsArr, setAllApptsArr] = useState([]);
  
    const userObj = useSelector(getUserSelector);
    const [searchStr, setSearchStr] = useState('');
    const { selectedWarehouse, cu_id, userAccountDetails, userType } = userObj;
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const divRef = useRef(null);
    const [showLoading, setShowLoading] = useState(true);
    const previousTable = usePrevious(currentTable);
    const ss = useSelector(smartScheduleSelector);
    const {
        driverAppointData,
        darrangeKey,
        darrangeOrder,
     
      } = ss;
    const apptsTitleDD = [
      { id: 'PoNumber', name: 'PO Number' },
        { id: 'Appointment', name: 'Appointment Date and Time' },
        { id: 'Vendor', name: 'Vendor Name' },
        { id: 'Items', name: 'Items' },
        { id: 'Cases', name: 'Cases' },
        { id: 'Pallets', name: 'Pallets' },
       
      
    ]

    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })

    useEffect(() => {
 setShowLoading(true);
        fetchAppts()
       
    }, [])

    useEffect(() => {
  if (driverAppointData ) {
    let loadings=false;
            setShowLoading(loadings);
            if(!loadings){
                setAllApptsArr(driverAppointData)
            }
         } 
    
  }, [driverAppointData])

    const fetchAppts = () => {
        setShowLoading(true);
      
        dispatch({ type: sagaActions.INTIATE_GUARD__PO_APPOINTMENT,payload: { "IDWarehouse":selectedWarehouse.ID} });
    }

    useEffect(() => {
        if (searchStr.length > 3 && driverAppointData.length) {
            const searchedArr = initiateSearch(driverAppointData, searchStr);
            setAllApptsArr(searchedArr);
        } else if (searchStr === '') {
            setAllApptsArr(driverAppointData)
        }
    }, [searchStr])


    const formatListDataDrillDown = (data) => {
      return data.map((el, index) => {
            return {
                id: index,
               0: el.PoNumber ,
                1: moment(el.Appointment).utc().format( AppConstant.commonStrings.MM_DD_YYYY__HH_mm) ,
                2: el.Vendor || '-',
                3: el.Items || '-',
                4: el.Cases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '-',
                5: el.Pallets || '-',
              
              
              
               }
        })
    }

    return (
        <div className=''>
            <div className='d-flex justify-content-between my-2 mx-3'>
                <div className='fw-bold'>{'Driver Appointments'}</div>
                <div className='d-flex w-25 justify-content-end'>
                    <div className='w-75'>
                        <WarehouseSearch
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                    <div className='d-flex align-items-center mx-3'>
                        <DownloadSection singlePageDownload={true} root={''} subRoot={''} name={'Appointments Report'} themeSelected={themeSelected} currentTable={currentTable} currentTableRef={currentTableRef} />
                    </div>
                </div>

            </div>
            <div className='allApptsListContainer' ref={divRef}>
                {<DrilldownTable
                    themeSelected={themeSelected}
                    bodyData={allApptsArr.length && formatListDataDrillDown(allApptsArr)}
                    titleData={apptsTitleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    arrangeOrder={darrangeOrder}
                    arrangeKey={darrangeKey}
                    drillDownReport={driverAppointData}
                    initiateDrillDownReport={showLoading}
                    loaderArray={6}
                    sortingAction={sagaActions.SORT_DRIVER_APPOINTMENT_TABLE}
                    subRoot={{ pathName: 'allApptsList' }}
                    showUTCTime={false}
                   // apiTime={selectedWarehouse.TimeZone}

                />}
            </div>
        </div>
    )
}

export default DriverAppointList;