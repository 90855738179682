import React, { useEffect } from 'react';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import { useSelector } from 'react-redux';
import { AppConstant } from '../../../../Assests/AppConstant';
import { ReactComponent as NoDataLightMode } from '../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../Assests/Svg/NoDataDarkMode.svg';
import ScheduledLoadStatusReport from './ReportsSection/ScheduledLoadStatusReportSection/ScheduledLoadStatusReport'
import TruckUnloadSummary from './ReportsSection/TruckUnloadSummaryReportSection/TruckUnloadSummaryReportSection';
import TruckUnloadStatistics from './ReportsSection/TruckUnloadStatisticsReportSection/TruckUnloadStatisticsReportSection';
import './Reports.css';
import ADFATwrapper from './ReportsSection/ADFAT/Wrapper/ADFATwrapper';
import { getReportsSelector } from '../../../../../redux/reducers/reducerSlices/ReportsReducerSlice';
import DriverList from './ReportsSection/DriverReport/DriverList';
import DriverAppointList from './ReportsSection/DriverReport/DriverAppointList';
import ScheduleProWrapper from '../../../SmartSchedule/SchedulePro/ScheduleProWrapper';
import FinanceReport from './ReportsSection/FinanceReports/FinanceReport/FinanceReport';
import FacilityRatingsWrapper from './ReportsSection/FacilityRatings/Wrapper/IMS/FacilityRatingsWrapper';
import UserRatingsWrapper from './ReportsSection/UserRatings/Wrapper/IMS/UserRatingsWrapper';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import DriverCheckout from './ReportsSection/DriverReport/DriverCheckout/DriverCheckout';
import LiveTrucks from './ReportsSection/DriverReport/LiveTrucks/LiveTrucks';
import AppointmentReportSummary from './ReportsSection/SSAppointmentsReport/AllAppointmentsList/AppointmentReportSummary';
import AllAppointmentsListSS from './ReportsSection/SSAppointmentsReport/AllAppointmentsList/AllAppointmentsListSS';


const Reports = (props) => {
    const { themeSelected } = props;
    const reportsSelector = useSelector(getReportsSelector);
    const { selectedReportObj } = reportsSelector;

    const showNoDataError = () => {
        return (
            <div className=' d-flex justify-content-center'>
                <div className='flex-column my-5'>
                    <div className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</div>
                    <div className={`no-data-text1 ${themeSelected} graphHeading text-center`}>{AppConstant.reports.selectReportTitleString}</div>
                </div>
            </div>
        )
    }

    const showCurrentReport = (selectedReportObj) => {

        if (selectedReportObj && selectedReportObj.ElementAccessID) {
            switch (selectedReportObj.ElementAccessID) {
                case "951":
                    return (<ADFATwrapper {...props} />);
                case "952":
                    return (<TruckUnloadSummary {...props} />)
                case "953":
                    return (<TruckUnloadStatistics {...props} />)
                case "954":
                    return (<ScheduledLoadStatusReport {...props} />)
                case "955":
                    // return (<AllAppointmentsList {...props} />)
                case "956":
                    return (<DriverList {...props} />)
                case "958":
                    return (<DriverAppointList {...props} />)
                case "959":
                    return (<FinanceReport {...props} />)
                case "960":
                    return (<FacilityRatingsWrapper {...props} />)
                case "961":
                    return (<DriverCheckout {...props} />)
                case "966":
                    return (<LiveTrucks {...props} />)
                case "967":
                    return (<UserRatingsWrapper {...props} />)
                default:

                    return showNoDataError();
            }
        } else {
            return showNoDataError()
        }
    }

    return (
        <>
            {(selectedReportObj && selectedReportObj.ElementAccessID !== '955' && selectedReportObj.ElementAccessID !== '968') ?
                <div className='m-1 '>
                    <Header {...props} />
                    <Navigationbar {...props} />
                    {Object.keys(selectedReportObj).length ? showCurrentReport(selectedReportObj) : ''}
                </div> :
                <ScheduleProWrapper showAppointments={false}>
                    {selectedReportObj.ElementAccessID ==='955' ?
                     <AllAppointmentsListSS {...props} />
                    :<AppointmentReportSummary {...props}/>
                }
                </ScheduleProWrapper>
            }
        </>
    )
}
export default Reports;