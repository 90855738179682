import _ from 'lodash';
import React, { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import NavDropDown from '../../SubNavigation/NavDropDown';

const PrimaryNavigation = (props) => {
    const { pNavList, getReportsArrayDropDown, location, showCustomSubNav, setShowCustomSubNav, onReportSelected,clickHandler } = props;
    const catMenu = useRef(null)
    const navigate = useNavigate()
    const closeOpenMenus = (e) => {
        if (catMenu.current && showCustomSubNav && !catMenu.current.contains(e.target)) {
            setShowCustomSubNav(false)
        }
    }
    document.addEventListener('mousedown', closeOpenMenus)
    return (
        <ul className='nav-list'>
            {
                _.size(pNavList) && pNavList.map((el, index) => {
                    if (el.NavigationType === 'Primary' && el.PageAccess) {
                        return (<li className={location?.pathname === el?.PageUrl ? "d-flex align-items-center w-100 nav-list-selected" : "d-flex align-items-center w-100"} key={el.PageDesc} onClick={() => {
                            if (el.PageName === "Reports") {
                                setShowCustomSubNav(!showCustomSubNav)
                            }
                            else {
                                clickHandler(el)
                                navigate(el.PageUrl)
                            }

                        }}>
                            {/* To Show Icons */}
                            {el?.FeatureIcon && <i className={el?.FeatureIcon} aria-hidden="true"></i>}
                            {
                                el.PageName !== "Reports" ? <a className=" nav-link text-centerw-100" aria-current="page" >
                                    <div className='ms-1'>{el.PageDesc}</div>
                                </a>
                                    : <div className='ms-1 d-flex justify-content-between w-100'>
                                        <span>{el.PageDesc}</span>
                                        <span><i className='fa-solid fa-angle-right'></i></span>
                                    </div>
                            }
                            {el.PageName === "Reports" &&
                                showCustomSubNav && <div className='sub-nav-ss' ref={catMenu}><NavDropDown data={getReportsArrayDropDown(el, index, el.featureRole, el.PageDesc, onReportSelected)} redirect={el.PageUrl} onReportSelected={(e, subEl) => onReportSelected(e, subEl)} parentIcon={el?.FeatureIcon}/>
                                </div>

                            }
                        </li>)
                    }
                })
            }
        </ul>
    )
}

export default PrimaryNavigation