import React from 'react';
/**
 * This is a reusable component for PDF viewer
 * @param {*} param
 * pdfUrl =  PDF URL
 * width = PDF height
 * height = PDF height
 * @returns 
 */
const PDFViewer = ({ pdfUrl, width, height }) => {
  return (
    <div>
      <embed src={pdfUrl} type="application/pdf" width={width} height={height} data-testid='pdf_viewer' />
    </div>
  );
};

export default PDFViewer;