import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { uuidv4 } from "../../../../../../Assests/Utility";

const CommonChart = props => {
  const { data   } = props;
  const [tempData, setTempData] = useState(null);

  useEffect(() => {
    setTempData(data)
  },[data])
  return (
   tempData ? <Chart
      width={tempData?.width}
      options={tempData?.options}
      series={tempData?.series}
      type={tempData?.options.chart.type}
    /> : ''
  );
};

export default CommonChart;
