import React,{useState} from 'react';
import { ReactComponent as SearchDropdownLightIcon } from '../../../../../../Assests/Svg/search-dropdown-light.svg';
import { ReactComponent as SearchDropdownDarkIcon } from '../../../../../../Assests/Svg/search-dropdown-dark.svg';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../sagas/sagaActions'
import './SearchPODropdown.css';

/**
 * This is a reusable component for Search PO dropdown
 * @param {*} props 
 * @returns 
 */
const SearchPODropdown = (props) => {
    const dispatch = useDispatch();
    const appConstPath = AppConstant.poManagement.searchPOSection;
    const { themeSelected } = props;
    const [selectedItem, setSelectedItem] = useState(appConstPath.poTitleStr);
    /**
     * Handler function for Dropdown icon
     * @returns 
     */
    const setDropdownIcon = () => {
        return (themeSelected === AppConstant.commonStrings.lightModeFilterString ? <SearchDropdownDarkIcon className='dropdownIcon'/> : <SearchDropdownLightIcon className='dropdownIcon'/>);
    }
    /**
     * Handler function on item select
     * @param {*} selectItem 
     */
    const selectSearchItemCategory = (selectItem) => {
        setSelectedItem(selectItem.target.innerHTML);
        props.selectDropDown(selectItem.target.innerHTML);
        dispatch({ type: sagaActions.RESET_FILTERS });
    }

    return (
        <div className='dropdown rounded-start py-1 px-2 searchPODropdown '>
            <button className="btn border-0 m-0 p-0 w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <div className=' d-flex justify-content-between'>
                    <span className={`${themeSelected}-searchDropDown`}>
                        {selectedItem}
                    </span>
                    <span className=''>
                        {setDropdownIcon()}
                    </span>
                </div>
                
            </button>
            <ul className={`dropdown-menu ${themeSelected === AppConstant.commonStrings.darkModeFilterString && "dropdown-menu-dark"}`}>
                <li><a className="dropdown-item" onClick={(item) => selectSearchItemCategory(item)}>{appConstPath.poTitleStr}</a></li>
                <li><a className="dropdown-item" onClick={(item) => selectSearchItemCategory(item)}>{appConstPath.carrierTitleStr}</a></li>
                <li><a className="dropdown-item" onClick={(item) => selectSearchItemCategory(item)}>{appConstPath.vendorTitleStr}</a></li>
            </ul>
        </div>
    )
}

export default SearchPODropdown;


