import _ from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Uchain } from '../redux/api/agent';
import { clearDockList, clearError, getDockListFailure, getDockListSuccess } from '../redux/reducers/reducerSlices/DockListReducer';
import { sagaActions } from './sagaActions';

function* dockListSaga(data) {
    try {
        const response = yield call(Uchain.getDockList, data.payload);
        yield put(getDockListSuccess(response));
    } catch {
        yield put(getDockListFailure());
    }
}

function* clearDockListSaga() {
    try {
        yield put(clearDockList());
    } catch { }
}

function* clearDockErrorSaga() {
    try {
        yield put(clearError());
    } catch { }
}

export function* getDockList() {
    yield takeEvery(sagaActions.DOCK_LIST, dockListSaga)
}

export function* clearDock() {
    yield takeEvery(sagaActions.CLEAR_DOCK, clearDockListSaga)
}

export function* clearDockError() {
    yield takeEvery(sagaActions.CLEAR_DOCK_ERROR, clearDockErrorSaga)
}

