import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedFiltersList : [],
    allFilters : [],
    isFilterViewExpanded : false,
    poListFilter : 'All',
    dockFiltersList : [],
    statusFiltersList : [],
    selectedDockFiltersList : [],
    selectedStatusFiltersList : [],
    isSearching : false,
}

export const FiltersReducerSlice = createSlice({
    name: 'filterReducer',
  initialState,
  reducers : {
    updateFilterListReducer : (state, action) => {
      state.selectedFiltersList = action.payload;
    },
    setPOListFilterReducer : (state, action) => {
      state.poListFilter = action.payload;
    },
    setFilterViewToggle : (state, action) => {
      state.isFilterViewExpanded = action.payload;
    },
    resetAllFilters: (state) => {
        state.selectedFiltersList = [];
        state.allFilters  = [];
        state.isFilterViewExpanded  = false;
        state.poListFilter  = 'All';
        state.selectedDockFiltersList = [];
        state.selectedStatusFiltersList = [];
    },
    setDockFiltersListReducer : (state, action) => {
      state.dockFiltersList = action.payload;
    },
    setStatusFiltersListReducer : (state, action) => {
      state.statusFiltersList = action.payload;
    },
    setSelectedDockFilterReducer : (state, action) => {
      state.selectedDockFiltersList = action.payload
    },
    setSelectedStatusFilterReducer : (state, action) => {
      state.selectedStatusFiltersList = action.payload
    },
    updateIsSearching : (state, action) => {
      state.isSearching = action.payload
    }
  }
});

export const { setPOListFilterReducer, setFilterViewToggle, resetAllFilters, updateFilterListReducer, setStatusFiltersListReducer, setDockFiltersListReducer, setSelectedDockFilterReducer, setSelectedStatusFilterReducer, updateIsSearching } = FiltersReducerSlice.actions;

// Selectors
export const getSelectedFiltersSelector = (state) => {
  return state.filterReducer.selectedFiltersList;
};

export const getAllFiltersListSelector = (state) => {
  return state.filterReducer;
};

export const getFilterViewExpanded = (state) => {
  return state.filterReducer;
};

export const getPOListFilterSelector = (state) => {
  return state.filterReducer.poListFilter;
};

export const getDockFilterListSelector = (state) => {
  return state.filterReducer.dockFiltersList;
};

export const getStatusFilterListSelector = (state) => {
  return state.filterReducer.statusFiltersList;
};

export const getSelectedDockFilterListSelector = (state) => {
  return state.filterReducer.selectedDockFiltersList;
};

export const getSelectedStatusFilterListSelector = (state) => {
  return state.filterReducer.selectedStatusFiltersList;
};

export const getIsSearchingStatusSelector = (state) => {
  return state.filterReducer.isSearching;
};

export default FiltersReducerSlice.reducer;
