export const fetchDockListCommonFunc = (selectedWarehouse, cuid) => {

    const apiParams = {
        "DockName": null,
        "PPH": null,
        "DockStatus": null,
        "ID": "",
        "CRUDTYPE": "S",
        "IDWarehouse": selectedWarehouse.ID,
        "StartTime": null,
        "EndTime": null,
        "CreatedOn": null,
        "CreatedBy": null,
        "UpdatedBy": null,
        "UpdatedOn": null,
        "operational_days":{
            sunday:null,
            monday:null,
            tuesday:null,
            wednesday:null,
            thursday:null,
            friday:null,
            saturday:null
        },
        "CUID": cuid || null,
    }

    const operationObj = {
        type: 'Fetch Dock',
        status: ''
    }
    
    return {apiParams : apiParams, operationObj: operationObj}
}