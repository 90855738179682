import React from 'react';
import FormDropDown from '../../../../Common/FormDropDown/FormDropDown';
import InputWithTtile from '../../../../Common/CustomInput/InputWithTtile';
import ToggleSwitch from '../../../../Common/ToggleSwitch/ToggleSwitch';

const FormComponent = (props) => {

    const { contentArr, themeSelected } = props;

    const createJsxEle = (ele) => {
        const baseEle = () => {
            if (ele.type === 'dropDown' && ele.extraComponent === null) {
                return (
                    <div>
                        <FormDropDown
                            themeSelected={themeSelected}
                            listArr={ele.dataArr}
                            selectedItem={ele.selectedItem} />
                    </div>
                )
            } else if (ele.type === 'input' && ele.extraComponent === null) {
                return (
                    <div>
                        <InputWithTtile
                            themeSelected={themeSelected}
                            title={ele.title}
                            placeHolder={ele.placeHolder}
                            showError={ele.showError}
                            errorMessage={ele.errorMessage}
                            handleErrors={() => {
                            }}
                            value={ele.value && ele.value}
                            onEmailChange={(evt) => ele.onEmailChange(evt)}
                            name={ele.name}
                            required={ele.required}
                            // disabled={ele?.disabled ? ele?.disabled: '' }
                        />
                    </div>
                )
            } else if (ele.type === 'toggleSwitch' && ele.extraComponent === null) {
                return (
                    <div className='d-flex justify-content-start'>
                        <ToggleSwitch
                            status={ele.value}
                            themeSelected={themeSelected}
                            title={ele.title}
                            onChangeValue={() => ele.onChange(ele.value)}
                        />
                    </div>
                )
            } else if (ele.type === 'label' && ele.extraComponent === null) {
                return (
                    <div className='d-flex justify-content-start graphHeading'>
                        {ele.title}
                    </div>
                )
            } else if (ele.type === 'checkbox' && ele.extraComponent === null) {
                return (
                    <div>
                        <div className="input-group mb-2">
                            <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox" value={ele.title} aria-label="" />
                            </div>
                            <input type="text" className="form-control" aria-label="" value={ele.title} />
                        </div>
                    </div>
                )
            }
            else if (ele.type === 'extra' && ele.extraComponent !== null) {
                return (ele.extraComponent)
            }
        }
        return baseEle();
    }

    const filterChildren = (index) => {
        return (
            contentArr.filter((ele) => {
                if (ele.rowNum === index) {
                    return ele
                }
            })
        )
    }

    const createMultiInstances = (filteredArr, ele) => {
        return (filteredArr.map((ele2, index) => (
            <div key={ele2 + index} className={`mx-2 ${ele2.extraComponent !== null ? 'd-flex ms-3 w-100 justify-content-end' : ' '}`}>
                {createJsxEle(ele2)}
            </div>)))
    }

    const addTableRows = () => {
        return contentArr.map((ele, i) => {
            const filteredArr = filterChildren(i);
            return (
                <tr key={ele + i}>
                    <td >
                        {
                            <div className={`py-1 px-2 mt-2 ${filteredArr.length > 1 ? 'd-flex' : 'justify-content-start'}`}>
                                {createMultiInstances([...filteredArr], ele)}
                            </div>
                        }
                    </td>
                </tr>
            )
        })
    }

    return (
        <div>
            <table>
                <thead />
                <tbody>
                    {addTableRows()}
                </tbody>
            </table>
        </div>
    )
}

export default FormComponent;