import React, { useState } from 'react'

/**
 * Component is used to render schedule upload summary data
 * @param {*} props 
 * @returns 
 */
const Summary = (props) => {

    return <div>Summary</div>
}

export default Summary