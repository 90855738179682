import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppConstant } from '../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { getTruckSelector } from '../../../../../../../redux/reducers/reducerSlices/getTruckReducer';
import {  getRemoteConfigSelector } from '../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import {  getDefaultDashboardSelector } from '../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import Loader from '../../../../../Loader/Loader.jsx';
import { dateAndTimeUTCFormat, isCheckedInMoreThan15Min, modifyPODisplay } from '../../../../../../Assests/Utility';
import { ReactComponent as Pending } from '../../../../../../Assests/Svg/Pending.svg';
import { ReactComponent as PendingApproval } from '../../../../../../Assests/Svg/PendingApproval.svg';
import { ReactComponent as NoDataLightMode } from '../../../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../../../Assests/Svg/NoDataDarkMode.svg';
import { ReactComponent as AwatingDriverConfirmIcon } from '../../../../../../Assests/Svg/await-driver-confirm-icon.svg';
import { ReactComponent as DoorAssignedIcon } from '../../../../../../Assests/Svg/door-assigned-icon.svg';
import { ReactComponent as PendingApprovalStatusIcon } from '../../../../../../Assests/Svg/pending-approval-status-icon.svg';
import { ReactComponent as PoApprovedIcon } from '../../../../../../Assests/Svg/po-approved-icon.svg';
import { ReactComponent as StagingIcon } from '../../../../../../Assests/Svg/staging-icon.svg';
import { ReactComponent as UnloadingIcon } from '../../../../../../Assests/Svg/unloading-icon.svg';
import { ReactComponent as DriverUnload } from '../../../../../../Assests/Svg/DriverUnload.svg';

import './TruckList.css';
import { getLoadSelector } from '../../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import { usePrevious } from '../../../../../Common/CustomHooks/usePrevious';
import _ from 'lodash';
import CustomTooltip from '../../../../../Common/Tooltip/CustomTooltip';

const TruckList = (props) => {

    const dispatch = useDispatch();
    const { themeSelected, viewChangeHandler } = props;
    const getTruck = useSelector(getTruckSelector);
    const getLoad = useSelector(getLoadSelector);
    const { selectedLoad } = getLoad;
    const { truckList, getListLoading, autoFetchPolist } = getTruck;
    const loaderArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const [intervalHandler, setIntervalHandler] = useState(null);
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { autoFetchExecutorInterval } = remoteConfig;
    const previousPoListInterval = usePrevious(intervalHandler);
    const dashboard = useSelector(getDefaultDashboardSelector)
    const { dateRangeObj } = dashboard
    const previousDateInterval = usePrevious(dateRangeObj);

    useEffect(() => {
        /* Auto Fetch PO-List after every 35 seconds */
        // if (intervalHandler) {
        //     setIntervalHandler(clearInterval(intervalHandler))
        // }
        // const settingInterval = setInterval(() => {
        //     /* To Resolve - http://10.10.0.10:8880/browse/WP-337
        //     More Door - Change Door consistently freezes screen after toggling between legend categories
        //     */
        //     const changeDoorPopupDOM = document.querySelector('#changeDoorPopup');
        //     if (changeDoorPopupDOM && !changeDoorPopupDOM.className.includes('show')) {
        //         if (intervalHandler !== previousPoListInterval) {
        //             fetchTruckList(false);
        //         }
        //     }
        // }, autoFetchExecutorInterval);
        // setIntervalHandler(settingInterval)

        const changeDoorPopupDOM = document.querySelector('#changeDoorPopup');
        if (changeDoorPopupDOM && !changeDoorPopupDOM.className.includes('show')) {
            if (intervalHandler !== previousPoListInterval) {
                fetchTruckList(false);
            }
        }
    }, [])

    useEffect(() => {
        if(dateRangeObj && dateRangeObj.start){
            if(previousDateInterval && previousDateInterval.start && previousDateInterval.start !== dateRangeObj.start){
                fetchTruckList(true);
            }
        }
        
    },[dateRangeObj])

    const fetchTruckList = (showLoader) => {

        showLoader && dispatch({ type: sagaActions.INTAITE_GET_TRUCK })
        // dispatch({ type: sagaActions.AUTO_FETCH_PO_LIST, payload: true });
        dispatch({ type: sagaActions.FETCH_TRUCK_LIST, payload: '' })
    }

    const changeHandler = e => {
        const id = e.target.closest('tr').id;
        if (id) {
            const updatedTruckList = truckList.map(el => {
                if (el.load_control_num === id) {
                    let newEl = { ...el }
                    newEl.checked = !newEl.checked
                    return newEl
                }
                else {
                    return el;
                }
            })
            dispatch({ type: sagaActions.UPDATE_TRUCK, payload: updatedTruckList })
        }
    }

    const selectionHandler = (e) => {
        const { id } = e.currentTarget;
        if (id !== selectedLoad.load_control_num) {
            dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
            let filterSelectedLoad = truckList.filter(el => el.load_control_num === id)
            if (filterSelectedLoad.length) {
                dispatch({ type: sagaActions.INTIATE_TRUCK_DETAILS });
                dispatch({ type: sagaActions.LOAD_TRUCK_DETAILS, payload: filterSelectedLoad[0] });
                dispatch({ type: sagaActions.GET_DOOR, payload: {dock_id: filterSelectedLoad[0].dock_id}})
                if (props.hasOwnProperty('viewChangeHandler')) viewChangeHandler()
            }
        }
    }

    useEffect(() => {
        if (truckList && truckList.length) {
            if (_.isEmpty(selectedLoad)) {
                let selectedLoadTemp = {}
                selectedLoadTemp = { ...selectedLoadTemp, ...truckList[0] }
                dispatch({ type: sagaActions.INTIATE_TRUCK_DETAILS })
                dispatch({ type: sagaActions.LOAD_TRUCK_DETAILS, payload: selectedLoadTemp })
                dispatch({ type: sagaActions.GET_DOOR, payload: { dock_id: selectedLoadTemp.dock_id } })
            } else if (!_.isEmpty(selectedLoad)) {
                const getLatestUpdatesSelectedLoad = truckList.filter(el => el.load_control_num === selectedLoad.load_control_num)
                dispatch({ type: sagaActions.LOAD_TRUCK_DETAILS, payload: getLatestUpdatesSelectedLoad.length ? getLatestUpdatesSelectedLoad[0] : selectedLoad });
                // dispatch({ type: sagaActions.AUTO_FETCH_PO_LIST, payload: false })
            }
        } else {
            dispatch({ type: sagaActions.RESET_LOAD_TRUCK_DETAILS })
        }
    }, [truckList])


    const emptyList = () => {
        return (
            <tr className='text-center align-middle' data-testid={AppConstant.dataTestId.emptyList}>
                <td colSpan={8} className='no-data-wrappper'>
                    <div className='d-flex flex-column align-items-center'>
                        <span className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</span>
                        <span className={`no-data-text1 ${themeSelected}`}>{AppConstant.poManagement.emptyTable.text1}</span>
                        <span className={`no-data-text2 ${themeSelected}`}>{AppConstant.poManagement.emptyTable.text2}</span>
                    </div>
                </td>
            </tr>
        )
    }

    const applyDoorcss = (el,i) => {
        let returncss = `col-md-auto ${themeSelected}`  
        if(!el.door_acknowledege && parseInt(el.door)){
            returncss = `${returncss} isNotDoorAcknowledge` 
        }  
        return returncss;
    }

    const renderTruck = () => {

        return truckList && truckList.length ? truckList.map((el,i) => {

            let morePoList = modifyPODisplay(el.po);
            const iconStatusHandler = (el) => {
                if(el.driver_unload){
                    return <DriverUnload />
                }
                switch (el.po_status) {
                    case AppConstant.poManagement.statusFilters.pending:
                        return <PendingApproval />
                    case AppConstant.poManagement.statusFilters.approved:
                        return <PoApprovedIcon />
                    case AppConstant.poManagement.statusFilters.staging:
                        return <StagingIcon />
                    case AppConstant.poManagement.statusFilters.unloading:
                        return <UnloadingIcon />
                    case AppConstant.poManagement.statusFilters.pendingApproval:
                        return <PendingApprovalStatusIcon />
                    case AppConstant.poManagement.statusFilters.awatingDriver:
                        return <AwatingDriverConfirmIcon />
                    case AppConstant.poManagement.statusFilters.doorAssigned:
                        return <DoorAssignedIcon />
                    default:
                        return <Pending fill='#106D96' />
                }
            }

            const defaultObj = {
                date: '-',
                time: '-'
            }

            const appointment_time_formated = el.appointment_time? dateAndTimeUTCFormat(el.appointment_time): defaultObj
            const check_in_time_formated = el.check_in_time ? dateAndTimeUTCFormat((el.check_in_time)): defaultObj
            
            return (
                <tr className={`text-center align-middle ${themeSelected} ${selectedLoad.load_control_num === el.load_control_num ? 'active' : ''} ${isCheckedInMoreThan15Min(el)}`} key={el.load_control_num} id={el.load_control_num} onClick={selectionHandler}>
                    {/* Asked By Rajesh to comment for now */}
                    {/* <td className={`col-md-auto ${themeSelected}`}>
                        <div className="d-flex flex-row justify-content-around align-items-center po-checkbox-icon-wrapper">
                            <input type="checkbox" style={{ width: '16px', height: '16px' }} checked={el.checked} onChange={changeHandler} />
                        </div>
                    </td> */}
                    <td className={`col-md-auto ${themeSelected} `}>
                        <div data-tip={`${el.po_status}`} data-for={`${el.po_status}_${el.load_control_num}`}>
                            <div className='po-icon-status'>
                                    {iconStatusHandler(el)}
                            </div>
                            <CustomTooltip
                                id={`${el.po_status}_${el.load_control_num}`} 
                                position='top'
                                type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                                multiline={false} />
                        </div>
                    </td>
                    <td className={`col-md-auto ${themeSelected} pl-2`}>
                        {morePoList}
                    </td>
                    <td className={`col-md-auto ${themeSelected}`}>
                        <div className="d-flex flex-column">
                            <span className=''>
                                {appointment_time_formated.date}
                            </span>
                            <span className=''>
                                {appointment_time_formated.time}
                            </span>
                        </div>
                    </td>
                    <td className={`col-md-auto bg-transparent ${themeSelected}`}>{el.carrier || '-'}</td>
                    <td className={`col-md-auto ${themeSelected}`}>
                        <div className="d-flex flex-column">
                            <span className=''>
                                {check_in_time_formated.date}
                            </span>
                            <span className=''>
                                {check_in_time_formated.time}
                            </span>
                        </div>
                    </td>
                    <td className={`col-md-auto ${themeSelected}`}>{el.dock || '-'}</td>
                    <td className={applyDoorcss(el,i)}>{el.door || '-'}</td>
                </tr>
            )
        }) : emptyList()

    }

    return (
        <tbody className='tableBody'>
            {
                !getListLoading ?
                    <>
                        {renderTruck()}
                    </>
                    :

                    loaderArray.map((_, i) => {
                        return (
                            <tr key={i} data-testid={AppConstant.dataTestId.tableLoaders + '_' + i}>

                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8].map((__, j) => {
                                        return (
                                            <td key={j}>
                                                <Loader height={35} count={1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                                                    baseColor={themeSelected === 'dark-mode' ? '#575757'
                                                        : ''}
                                                />
                                            </td>
                                        )

                                    })
                                }

                            </tr>
                        )
                    })
            }


        </tbody>
    )
}
export default TruckList;