import React, { useEffect, useState } from 'react'
import _ from 'lodash-es';
import './success.css';
import { useNavigate } from "react-router-dom";
import { AppConstant } from '../../../../../../Assests/AppConstant';
import moment from 'moment-timezone';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import SuccessImg from '../../../../../../Assests/Images/showSuccess.png'
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
export default function SuccessAppointScreenSS(props) {
  const { themeSelected, rowData ,showApptBtn=true, selectedDateForApptUpdates} = props;
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    if (_.size(rowData)) {
      setDate(moment(rowData[0]?.ApptStartTime).utc().format(AppConstant.commonStrings.Do_MMMM_YYYY))
      setTime(moment(rowData[0]?.ApptStartTime).utc().format(AppConstant.commonStrings.hh_mm_A_dateFormat))
      dispatch({
        type: sagaActions.ADD_SELECTED_DATE_FOR_APPT_UPDATE,
        payload: selectedDateForApptUpdates,
    });
    dispatch({type:sagaActions.RESET_VALIDATE_DATE_FOR_APPOINTMENT})
    }
  }, [rowData])


  return (
    <div className='row p-0' >
      <div className='success-wrapper'>
        <img src={SuccessImg} alt="Success" />
        <div className='display-content-center f-14 fw-bold'>Appointment Scheduled!</div>
        <p className='f-14 text-grey '>{AppConstant.commonStrings.appointmentSuccessMsg}</p>
        <div className='dataTime-container'>
          <p className='dateTime-key'>Date: <span className='dateTime-value'>{rowData[0].ApptStartTime && moment(rowData[0].ApptStartTime).utc().format(AppConstant.commonStrings.MMMM_Do_YYYY) || '--'}</span></p>
          <p className='dateTime-key'>Time: <span className='dateTime-value'>{rowData[0].ApptStartTime && moment(rowData[0].ApptStartTime).utc().format(AppConstant.commonStrings.hh_mm_A_dateFormat) || '--'}</span></p>
        </div>
        <div className='display-content-center py-2'>
          {showApptBtn &&
          <CustomButtonSS
            isLoading={''}
            title='My Appointments' className={"btn-highlighted text-style-no"}
            onClick={() => { navigate('/myappointments') }}
          />
}
        </div>
      </div>
    </div>
  )
}
