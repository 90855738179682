import {Uchain} from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { addSuccess, addFailure, initiateLoading as listLoading, updateTableData, resetData as resetWarehouseDataList } from '../redux/reducers/reducerSlices/GetWarehouseDataReducer';
import { addSuccess as getWareLocationListSuccess, addFailure  as getWareLocationListFail,resetData as resetWarehouseList } from '../redux/reducers/reducerSlices/GetWareHouseLocationList';
import { addWareSuccess, addWareFailure,resetData,initiateLoading } from '../redux/reducers/reducerSlices/AddWarehouseReducer';


function* getWarehouseListRequestHandler(data){
    try{
      yield put(listLoading(true));
      const response = yield call(Uchain.getWareHouseList, data.payload);
      yield put(addSuccess(response));
    }catch(error){
  
     yield put(addFailure(error));
    }
}
export function*  GetWarehouseListRequest() {
  yield takeEvery(sagaActions.GET_WAREHOUSE_LIST, getWarehouseListRequestHandler)
}

function* getWarehouseLocationListRequestHandler(data){
  try{
   const response = yield call(Uchain.getWarehouseLocationList);
    yield put(getWareLocationListSuccess(response));
  }catch(error){
   yield put(getWareLocationListFail(error));
  }
}
export function*  GetWarehouseLocationListRequest() {
yield takeEvery(sagaActions.GET_WAREHOUSE_LOCATION_LIST, getWarehouseLocationListRequestHandler)
}


function* addWarehouseRequestHandler(data){ 
  try{
   
    yield put(initiateLoading());
   
    const response = yield call(Uchain.addWarehouse, data.payload);
   yield put(addWareSuccess(response));
  }catch(error){
   
   yield put(addWareFailure(error));
  }
}


function* getSproWarehouseListRequestHandler(data) {
  try {
      yield put(listLoading(true));
      const response = yield call(Uchain.sproWarehouseManager, data.payload);
      yield put(addSuccess(response));
  } catch (error) {

      yield put(addFailure(error));
  }
}
export function* GetSproWarehouseListRequest() {
  yield takeEvery(sagaActions.SPRO_GET_WAREHOUSE_LIST, getSproWarehouseListRequestHandler)
}

function* addSproWarehouseRequestHandler(data) {
  try {

      yield put(initiateLoading());

      const response = yield call(Uchain.sproWarehouseManager, data.payload);
      yield put(addWareSuccess(response));
  } catch (error) {

      yield put(addWareFailure(error));
  }
}

export function* addSproWarehouseRequest() {
  yield takeEvery(sagaActions.SPRO_ADD_WAREHOUSE, addSproWarehouseRequestHandler)
}

function* sortWarehouseListSaga(data) {
  try {
      yield put(updateTableData(data.payload))
  } catch (error) {
  }
}

export function* addWarehouseRequest() {
  yield takeEvery(sagaActions.ADD_WAREHOUSE, addWarehouseRequestHandler)
}

function* resetWarehouseFormHandler() {
 yield put(resetData())
}

export function* resetWarehouseFormRequest() {
  yield takeEvery(sagaActions.RESET_WAREHOUSE, resetWarehouseFormHandler)
}

function* resetWarehouseListHandler() {
  yield put(resetWarehouseList())
 }

 function* resetWarehouseDataListHandler() {
  yield put(resetWarehouseDataList())
 }

 export function* resetWarehouseListRequest() {
  yield takeEvery(sagaActions.SPRO_RESET_WAREHOUSE_LIST, resetWarehouseDataListHandler)
}

export function* sortWarehouseListWatcher() {
  yield takeEvery(sagaActions.WAREHOUSE_LIST_SORTING, sortWarehouseListSaga)
}

export function* resetSproWarehouseFormRequest() {
  yield takeEvery(sagaActions.SPRO_RESET_WAREHOUSE, resetWarehouseFormHandler)
}

export function* sortSproWarehouseListWatcher() {
  yield takeEvery(sagaActions.SPRO_WAREHOUSE_LIST_SORTING, sortWarehouseListSaga)
}