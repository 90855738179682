import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import Popup from '../../../../../../Common/Popup/Popup';
import CompanyGroupList from '../CompanyGroupList/CompanyGroupList';
import { CustomDynamicForm } from '../../../../../../Common/CustomForm/CustomDynamicForm';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer'
import { getCountryList } from '../../../../../../../../redux/reducers/reducerSlices/GetCountryListReducer';
import { shouldHavePermission, sortArrByKey } from '../../../../../../../Assests/Utility'
import WarehouseSearch from '../../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import { LocationClient, SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";
import { Auth } from 'aws-amplify';
import './CompanyGroupAdminScreen.css';

const CompanyGroupAdminScreen = (props) => {

    const dispatch = useDispatch();
    const { themeSelected } = props;
    const [countryDataArr, setCountryDataArrArr] = useState([]);
    const countryData = useSelector(getCountryList);
    const [editData, setEditData] = useState({});
    const [resetFormData, setResetFormData] = useState(0);
    const [loading, setLoading] = useState(false);
    const user = useSelector(getUserSelector);
    const { closeCompanyGroupForm, error, permissions } = user;
    const [searchStr, setSearchStr] = useState('');
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const [reterivedLocationInfo, setReterivedLocationInfo] = useState({});
    const [allowPermissionC, setAllowPermissionC] = useState(false)
    const [allowPermissionE, setAllowPermissionE] = useState(false)
    const [allowPermissionD, setAllowPermissionD] = useState(false)
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (permissions.length) {
            let tempC = shouldHavePermission(permissions, '101')
            setAllowPermissionC(tempC);
            let tempE = shouldHavePermission(permissions, '102')
            setAllowPermissionE(tempE);
            let tempD = shouldHavePermission(permissions, '103')
            setAllowPermissionD(tempD);
        }
    }, [])

    const getLocationInfo = async (zipCode) => {

        const credentials = await Auth.currentUserCredentials();

        const client = new LocationClient({
            credentials,
            region: "us-west-2"
        });
        const params = {
            "IndexName": "RDC",
            "Text": zipCode,
            "MaxResults": 1,
        }
        const command = new SearchPlaceIndexForTextCommand(params)
        try {
            const data = await client.send(command);
            const result = data.Results[0]
            setReterivedLocationInfo(result);
        } catch (error) {
        }
    }

    useEffect(() => {
        if (reterivedLocationInfo && Object.keys(reterivedLocationInfo).length !== 0) {
            const getCountry = () => {
                if(reterivedLocationInfo.Place.Country === "USA"){
                    return 'United States'
                } else if(reterivedLocationInfo.Place.Country === "CAN"){
                    return 'Canada'
                } else if(reterivedLocationInfo.Place.Country === "MEX"){
                    return "Mexico"
                }
            }

            setParticularFieldValue({
                'locale': reterivedLocationInfo.Place.Municipality || reterivedLocationInfo.Place.Region,
                'state': reterivedLocationInfo.Place.Region,
                'country': getCountry(),
            });
        } else {
            setParticularFieldValue({
                'locale': '',
                'state': '',
                'country': ''
            });
        }
    }, [reterivedLocationInfo])

    useEffect(() => {
        if (closeCompanyGroupForm) {
            setLoading(false);
            dispatch({ type: sagaActions.UPDATE_ROLES_FORM_POPUP, payload: false });
            setShowModal(false);
            closeCurrentpopup();
        }
    }, [closeCompanyGroupForm])

    useEffect(() => {
        if (error) {
            setLoading(false);
            dispatch({ type: sagaActions.UPDATE_USER_ERROR, payload: false });
        }
    }, [error])

    useEffect(() => {
        dispatch({ type: sagaActions.GET_COUNTRY_DATA });
    }, [])

    useEffect(() => {
        if (countryData.data) {
            setCountryDataArrArr(sortArrByKey(countryData.data, 'country_name'));
        }
    }, [countryData.data]);

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const getCountryDataList = () => {
        return (
            countryDataArr &&
            countryDataArr.map((data, index) => {
                return (
                    <option key={data.country_name} value={data.country_name}>
                        {data.country_name}
                    </option>
                );
            })
        );
    };

    const formFieldList = [
        {
            name: "group_name",
            type: "text",
            size: "col-sm-6",
            position: 1,
            title:
                AppConstant.superUserAdmin.companyGroup.createCompanyGroupForm
                    .companyGroupNameTitleStr,
            formValidation: {
                required: true,
            },
        },
        {
            name: "email",
            type: "text",
            size: "col-sm-6",
            position: 2,
            title: AppConstant.login.email,
            formValidation: {
                required: true,
                validateEmail: true,
            },
        },
        {
            name: "contact_number",
            type: "text",
            size: "col-sm-6",
            position: 3,
            placeholder: AppConstant.user.popup.createUser.phone_placeHolder,
            title: AppConstant.superUserAdmin.company.createCompanyForm.contact,
            formValidation: {
                required: true,
                validateContactNum: true,
                customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.contactNumErrorMessage
            },
        },
        {
            name: "address",
            type: "textarea",
            size: "col-sm-6",
            position: 4,
            title:
                AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.address,
            formValidation: {
                required: true,
            },
        },
        {
            name: "zip",
            type: "text",
            size: "col-sm-6",
            position: 5,
            title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip,
            formValidation: {
                required: true,
                lengthValidation: {
                    customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zipCodeErrorMessage + '. '
                },
            },
        },
        {
            name: "locale",
            type: "text",
            size: "col-sm-6",
            position: 6,
            title: AppConstant.superUserAdmin.city,
            formValidation: {
                required: true,
            },
        },
        {
            name: "state",
            type: "text",
            size: "col-sm-6",
            position: 7,
            title:
                AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.state,
            formValidation: {
                required: true,
            },
        },
        {
            name: "country",
            type: "select",
            size: "col-sm-6",
            position: 8,
            title:
                AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.country,
            formValidation: {
                required: true,
            },
            getSelectData: getCountryDataList,
        },
        {
            name: "group_logo",
            type: "file",
            size: "col-sm-6 mt-3",
            position: 9,
            accept: "image/png,image/jpeg",
            title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.imageLogo,
            formValidation: {
                required: false,
            },
        },
    ]

    const editCompanyGroup = (data) => {

        const editData1 = {
            "company_group_id": data.CompanyGroupId,
            "group_name": data.GroupName,
            "email": data.Email,
            "contact_number": data.ContactNumber,
            "address": data.Address,
            "locale": data.Locale,
            "city": data.city,
            "zip": data.Zip,
            "state": data.State,
            "country": data.Country,
            "group_logo": data.GroupLogo,
            "enable_status": data.EnableStatus,
        }
        setShowModal(true);
        setEditData(editData1);
        setParticularFieldValue(editData1)
    }

    const getSummitedFormData = (data) => {

        setLoading(true);
        dispatch({ type: sagaActions.UPDATE_COMPANY_GROUP_FORM_POPUP, payload: false });

        if (data) {
            const extraParams = {
                company_group_id: null,
                enable_status: null,
                crud_type: null
            }
            if (data.company_group_id) {

                extraParams.company_group_id = data.company_group_id;
                extraParams.enable_status = data.enable_status
                extraParams.crud_type = 'U';
            } else {
                extraParams.company_group_id = null;
                extraParams.enable_status = 0;
                extraParams.crud_type = 'I';
            }
            const apiParams = { ...data, ...extraParams };

            dispatch({ type: sagaActions.UPDATE_COMPANY_GROUP, payload: apiParams });
        }
    };

    const getFormFieldData = (data, name) => {
        if (name === 'zip') {
            if (data.length >= 3) {
                getLocationInfo(data)
            }
        }
    };

    
    const closeCurrentpopup = () => {
        setEditData({});
        setShowModal(false);
    }
    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getFormFieldData={getFormFieldData}
                getSummitedFormData={getSummitedFormData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    const showCompanyGroupPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: Object.keys(editData).length ? AppConstant.superUserAdmin.companyGroup.editCompanyGroupTitle : AppConstant.superUserAdmin.companyGroup.createCompanyGroupTitle,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const openCreateForm = () => {
        setShowModal(true)
        setParticularFieldValue({})
    }

    return (
        <div>
            <div className='container-fluid d-flex py-2 text-start justify-content-between mt-1'>
                <button
                    className={allowPermissionC ? 'addButton' : 'addButton disabled'}
                    data-bs-toggle="modal"
                    // data-bs-target={`#createCompanyGroupFormPopup1`}
                    onClick={() => openCreateForm()}
                    disabled={allowPermissionC ? '' : 'disabled'}
                >
                    {AppConstant.superUserAdmin.companyGroup.createCompanyGroupTitle}
                </button>
                <div className='w-25'>
                    <WarehouseSearch
                        placeholder={'Search Company Groups'}
                        inputValue={searchStr}
                        cancelSearch={() => setSearchStr('')}
                        onChangeHandler={(text) => setSearchStr(text)} />
                </div>
            </div>
            <div className="d-flex justify-content-center companyGroupList">
                <CompanyGroupList searchStr={searchStr} editGroupAction={(groupObj) => editCompanyGroup(groupObj)} showPopup={() => showCompanyGroupPopup()} themeSelected={themeSelected} allowPermissionE={allowPermissionE} allowPermissionD={allowPermissionD} />
                {showCompanyGroupPopup()}
            </div>
        </div>
    )
}

export default CompanyGroupAdminScreen;