
import { differenceInMinutes, setHours } from "date-fns";
import { Fragment, useEffect, useRef, useState } from "react";
import { BORDER_HEIGHT } from "../helpers/constansts";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { smartScheduleSelector } from "../../../../../redux/reducers/reducerSlices/SmartScheduleReducer";
import { useSelector } from "react-redux";
import { getUserSelector } from "../../../../../redux/reducers/reducerSlices/UserReducer";
import { sagaActions } from "../../../../../sagas/sagaActions";
import { SSCarrierApptListScreen } from "../../SchedulePro/Components/DashBoard/Children/SSCarrierApptListScreen/SSCarrierApptListScreen";
import ModalSS from "../../SchedulePro/Components/Common/ModalSS/ModalSS";

const TodayEvents = (props) => {
  const {
    todayEvents,
    today,
    startHour,
    step,
    minuteHeight,
  } =props;

    const [showRescheduleModal,setShowRescheduleModal] = useState(false)
    const dispatch = useDispatch()
    const ss = useSelector(smartScheduleSelector);
    const { searchedPOData,poForAppt,appointmentList } = ss;
    const user = useSelector(getUserSelector);
    const { userType ,cu_id} = user;
    const [selectedRowData, setSelectedRowData] = useState();
    const [scheduleScreenType, setScheduleScreenType] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const firstUpdate = useRef(true);

    useEffect(()=>{
      if (searchedPOData.length) {
        setSelectedRowData(searchedPOData[0])
      }
    },[searchedPOData])

    useEffect(() => {
      if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }
      if (showSuccess) {
          dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: selectedRowData?.PoNumber, ReceivingZipCode: selectedRowData?.ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? cu_id : selectedRowData?.IDWarehouse } })
      }
  }, [showSuccess,appointmentList])

  const openReschedulePopup=(data) => {
  dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: data?.PoNumber, ReceivingZipCode: data?.ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? cu_id : data?.IDWarehouse } })
  dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [data] })
    // let ifApptExists = _.find(appointmentList, {
    //     PoNumber: data?.PoNumber,
    // });
    // let foundObject = [];
    // if (!_.isEmpty(ifApptExists)) {
    //     let { PORelationID } = ifApptExists;
    //     foundObject = _.filter(appointmentList, {
    //         PORelationID
    //     });
    // }
    // if (_.size(foundObject)) {
    //     //Needed when coming from myapointments
        
    //     dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: _.flatten(formatPOwithPORelationID(foundObject)) })
    //     // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: foundObject })
    // } else {
    //   console.log("else block");
    //     dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: data?.PoNumber, ReceivingZipCode: data?.ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? cu_id : data?.IDWarehouse } })
    //     dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [data] })
    // }
    ssSchedulehandler(data)
    setShowRescheduleModal(true)
}

const ssSchedulehandler = (rowData) => {
  setShowSuccess(false);
  setSelectedRowData(rowData);
  // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [rowData] })
  dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [rowData] })
  if (_.upperCase(userType) === _.upperCase('USER')) {
      // navigate('/wpscheduleappointments') // Commented to implement for new WP Schedule flow, 
      setScheduleScreenType('initialscreen')
  } else if (_.upperCase(userType) === _.upperCase('CARRIER')) {
      setScheduleScreenType('initialscreen')
  }
}

   const genrateColorCodes=(data,type)=>{
    if (data.ApptScheStatus.toLowerCase()==='completed') {
      if (type==="head") {
        return "event__completed__head"
    }
    else{
    return "event__completed"
    }
    }
    else if (data.ApptScheStatus && data.ApptScheStatus.toLowerCase()==="scheduled" && !data.Rescheduled) {
        if (type==="head") {
            return "event__scheduled__head"
        }
        else{
        return "event__scheduled"
    }
    }
    else if (data.ApptScheStatus && data.ApptScheStatus.toLowerCase()==="rescheduled" || data.Rescheduled) {
        if (type==="head") {
            return "event__rescheduled__head"
        }
        else{
        return "event__rescheduled"
        }
    }
    else{
        if (type==="head") {
            return "event__completed__head"
        }
        else{
        return "event__completed"
        }
    }
   }

    // const createShowMoreBody=()=>{
    //     return (
    //       <div className="d-flex flex-wrap gap-2">
    //    { _.isArray(morePoList) && morePoList.map((el,i)=>{
    //         return(
           
    //               <div
    //         key={i}
    //         className={genrateColorCodes(el)+" rs__event__item custom__event__ss"}
    //         style={{
    //           height: "80px",
    //           width:"200px",
    //         }}
          
    //       >
    //         <div style={{position:"relative",width:"100%",height:"100%"}}>
    //         <button style={{padding:"4px 6px",width:"auto",borderRadius:"4px"}} className={genrateColorCodes(el,"head")}
    //         onClick={()=>{
    //           openReschedulePopup(el);
    //           closeShowMorePopup()
    //         }}
    //         >    
    //         {el.PoNumber || el.title}
    //         </button>
    //         <div className="mt-2">
    //           <span style={{padding:"4px 6px",borderRadius:"4px",width:"auto"}} className={genrateColorCodes(el,"head")+ " f-12 fw-normal"}>
    //           {moment(el.ApptStartTime).utc().format(AppConstant.commonStrings.hh_mm_A_dateFormat)} - {moment(el.ApptEndTime).utc().format(AppConstant.commonStrings.hh_mm_A_dateFormat)} 
    //           </span>
    //         </div>
    //         </div>
    //         </div>
           
    //         )
    //       })
       
    //       }
    //          </div>
    //       )
    // }

    // const closeShowMorePopup=()=>{
    //     setShowMoreModel(false)
    //     setMorePoList([])
    // }

    // const showMorePoPopup = () => {
    //         const popupObj = {
    //           id: 'showmoreevent',
    //           modalLabel: 'showmoreevent',
    //           showClose: true,
    //           showfooter: false,
    //           showHeader: true,
    //           sendDriverNotification: false,
    //           modalSize: 'md',
    //           title: "More Appointments",
    //           alignType: 'start',
    //           bodyText: createShowMoreBody(),
    //           subText: "",
    //           keyboard: false,
    //           backdrop: "static",
    //           footerBtn: {
    //             btn1: {
    //               show: false,
    //             },
    //             btn2: {
    //               show: false,
    //             }
    //           },
    //         }
    
    //         return <SSPopup {...popupObj} popupBtnHandler={(text) => {}} closepopup={()=>closeShowMorePopup()}  showModal={showMoreModel} />
    //   }

      // const openShowMorePopup=(data) => {

      //   setMorePoList(data);
      //   setShowMoreModel(true)
      // }


  const reschedulePopUpBody=()=>{
    return(
      <div style={{minWidth:"60vw" ,minHeight:"70vh",maxWidth:"62vw"}} className="p-2">
        <p className="f-18 fw-600 p-2">Appointment</p>
      <SSCarrierApptListScreen data={searchedPOData} scheduleScreenType={scheduleScreenType} setScheduleScreenType={setScheduleScreenType} screenTypeClassname='ssappointmentListTable-bookappt' setShowSuccess={setShowSuccess} showSuccess={showSuccess} poForAppt={poForAppt} backBtn={false}/>
      </div>
    )
}


  return (
    <Fragment>
      {todayEvents.map((events, i) => {
        return(
          <div className="d-flex">
            {
                   events.map((event,index)=>{
        
                    const height = differenceInMinutes(event.end, event.start) * minuteHeight - BORDER_HEIGHT;
                    const minituesFromTop = differenceInMinutes(event.start, setHours(today, startHour));
                    const topSpace = minituesFromTop * minuteHeight;
                    const slots = height / 60;
                    const heightBorderFactor = slots * BORDER_HEIGHT;
            
                    const slotsFromTop = minituesFromTop / step;
                    const top = (topSpace + slotsFromTop);
                    const width=100/events.length;
                    const left=width*index;
                    return (
                      <div
                        key={i}
                        className={genrateColorCodes(event)+" rs__event__item custom__event__ss"}
                        style={{
                          height: height + heightBorderFactor,
                          top:top,
                          left:`${left}%`,
                          width:`${width}%`,
                          zIndex: "90",
                        }}
                        title={event.PoNumber}
                      >
                        <div style={{position:"relative",width:"100%",height:"100%"}}>
                        <button 
                        style={{padding:"4px 6px",width:"auto",borderRadius:"4px",color:"white"}} 
                        className={`${genrateColorCodes(event,"head")} week__event ${event.disabled?"disabled":""}`} 
                        disabled={event.disabled} 
                        onClick={()=>openReschedulePopup(event)}
                        >
                        {event.PoNumber || event.title}{event?.more_event_list?.length>0&&" ..."}
                          </button>
                          <div style={{position:"absolute",top:"0px",right:"0px",fontSize:"12px",padding:"3px 5px",borderRadius:"50%",zIndex:"99999999",width: '15px' ,height: '15px'}} className={event?.LoadType === 'INBOUND'? 'po-inbound': 'po-outbound'} title={event.LoadType}></div>
                        {/* {event?.show_more&&
                        <button style={{position:"absolute",bottom:"0px",right:"0px",fontSize:"12px",padding:"3px 5px",borderRadius:"4px",zIndex:"99999999"}} className={genrateColorCodes(event,"head")} onClick={()=>{openShowMorePopup(event.more_event_list)}}>
                            More
                        </button>
                        } */}
                        </div>
                      </div>
                    
                    );
                  })
            }
          </div>
        )
        
      })}
   
      {searchedPOData.length>0 &&
      <ModalSS toggleModal={()=>{
        setShowRescheduleModal(!showRescheduleModal);
        setShowSuccess(false);
        dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
        }}>
        {reschedulePopUpBody()}
      </ModalSS>
      }
    {/* {showMorePoPopup()} */}
    
    </Fragment>
  );
};

export default TodayEvents;
