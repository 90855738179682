import React from 'react'
import './SSAppointmentCard.css'

/**
 * Reusable components to display appointment details
 * @param {*} props 
 * @returns 
 */
const SSAppointmentCard = (props) => {
    const {apptData} = props;
  return (
    <div className='ss-appt-wrapper p-2'>
       <div className='d-flex justify-content-between  mb-2'>
                <div className='carrier-po-key'>
                    <i className="fa-solid fa-calendar"></i> <span className='ms-1'>Appointment No.</span>
                </div>
                <div className='carrier-po-value'>{apptData?.AppointmentNumber || "-"}</div>
            </div>
            <div className='d-flex justify-content-between  mb-2'>
                <div className='carrier-po-key w-40'>
                    <i className="fa-solid fa-user"></i> <span className='ms-1'>Appointment By</span>
                </div>
                <div className='carrier-po-value w-60'>{`${apptData?.FirstName || "-"} ${apptData?.LastName || "-"} ( ${apptData?.CarrierName || "-"} )`}</div>
            </div>
        </div>
  )
}

export default SSAppointmentCard