import React, { useEffect } from 'react';
import { AppConstant } from '../../../../Assests/AppConstant';
import { ReactComponent as DownloadIconLight } from '../../../../Assests/Svg/download-icon-light.svg';
import { ReactComponent as DownloadIconDark } from '../../../../Assests/Svg/download-icon-dark.svg';
import './DownloadSectionSS.css';

const DownloadSectionBE = (props) => {
    const { downloadData, themeSelected = "light-mode", isDisabled, isSchedulePro = false, isLoading } = props;

    return isSchedulePro ? (
        <button className={`${isDisabled ? 'disabled' : 'pointer'} download-section-ss p-1 px-2 pointer`} data-bs-toggle="dropdown" aria-expanded="false" disabled={isDisabled}>

            <button className={`${isDisabled ? 'disabled' : 'pointer'} d-flex justify-content-start align-items-center me-2 downloadSection no-bg no-border`}>
                {
                    isLoading &&
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    />
                }
                <span className='font-default'> <i className="fa-solid fa-download"></i> </span>
                <div className={`downloadButton-ss ms-1 bg-light ${themeSelected}`}>
                    {AppConstant.downloadSection.downloadTitleStr}</div>
            </button>
            <ul className="dropdown-menu text-center dropDownDownItemloadSection">
                <li><a className="dropdown-item" onClick={() => downloadData('pdf')} href="#">{AppConstant.downloadSection.pdfDownloadString}</a></li>
                <div className='seperator' />
                <li><a className="dropdown-item" onClick={() => downloadData('excel')} href="#">{AppConstant.downloadSection.excelDownloadString}</a></li>
            </ul>
        </button>

    )
        : (
            <button className={`${isDisabled ? 'disabled' : 'pointer'} dropdown border border-dark rounded-2 p-1 px-2 d-flex justify-content-start align-items-center`} data-bs-toggle="dropdown" aria-expanded="false" disabled={isDisabled}>

                <button className={`${isDisabled ? 'disabled' : 'pointer'} d-flex justify-content-start align-items-center me-2 downloadSection  no-bg no-border`} >
                    {
                        isLoading &&
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        />
                    }
                    {themeSelected === 'light-mode' ? <DownloadIconLight className='me-2' /> : <DownloadIconDark className='me-2' />}
                    <div className={`downloadButton ${themeSelected}`}>{AppConstant.downloadSection.downloadTitleStr}</div>
                </button>
                <ul className="dropdown-menu text-center dropDownDownItemloadSection">
                    <li><a className="dropdown-item" onClick={() => downloadData('pdf')} href="#">{AppConstant.downloadSection.pdfDownloadString}</a></li>
                    <div className='seperator' />
                    <li><a className="dropdown-item" onClick={() => downloadData('excel')} href="#">{AppConstant.downloadSection.excelDownloadString}</a></li>
                </ul>
            </button>

        )
}

export default DownloadSectionBE;