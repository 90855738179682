import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: false,
    data:''
} 

export const AddWarehouseReducer = createSlice({
    name: 'AddWarehouseReducer',
    initialState,
    reducers: {
        initiateLoading: (state) => {
          state.loading = true;
          state.error=null;
          state.data = ''; 
         },
        addWareSuccess: (state, action) => {
           state.loading = false;
           state.data = action.payload; 
           state.error=null;
        },
        addWareFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.data = ''; 
        },
        resetData:(state,action)=>{
            
            state.loading = false;
            state.data = ''; 
            state.error=null;
        }
    }
});

export const { addWareSuccess, addWareFailure,initiateLoading,resetData} = AddWarehouseReducer.actions;

// Selectors
export const addWarehouseReducer = (state) => {
    return state.AddWarehouseReducer;
};

export default AddWarehouseReducer.reducer;
