import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../Assests/AppConstant';
import _ from 'lodash';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import { shouldHavePermission } from '../../../../../Assests/Utility';
import { useNavigate } from 'react-router-dom';
import FaqSection from '../Components/FAQSection/FAQSection';
import './HelpSupportFaq.css';

const HelpSupportFaq = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { themeSelected } = props;
    const [selectedSection, setSelectedSection] = useState('FAQ');
    const [resetFormData, setResetFormData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState({});
    const userPermission = useSelector(getUserSelector);
    const { permissions } = userPermission;
    const [allowPermission, setAllowPermission] = useState(false);

    useEffect(() => {
        if (permissions.length) {
            const helpSupportAdminPermission = _.find(permissions, { 'FeatureID': '9', 'PageAccess': true });

            if (helpSupportAdminPermission && _.includes(window.location.href, 'faqmanagement')) {
                navigate('/faqadmin')
            }
        }

    }, [window.location.href])

    const showSelectedSection = () => {
        switch (selectedSection) {
            case 'FAQ':
                return <FaqSection themeSelected={themeSelected} />
        }
    }

    return (
        <div className='d-flex flex-column m-2'>
            <div>
                {showSelectedSection()}
            </div>

        </div>
    )
}

export default HelpSupportFaq;