export const fetchWhCapacityCommonFunc = (selectedWarehouse) => {

    const apiParams = {
        "PPH": null,
        "WHStartTime": null,
        "WHEndTime": null,
        "EarliestApptHrs": null,
        "LastApptHrs": null,
        "ApptAvailableColor": null,
        "ApptNotAvailableColor": null,
        "WHBreakColor": null,
        "WHLeaveColor": null,
        "WHType": null,
        "ID": selectedWarehouse.ID,
        "CRUDTYPE": "S",
        "CreatedOn": null,
        "CreatedBy": null,
        "UpdatedBy": null,
        "UpdatedOn": null,
    }

    const operationObj = {
        type: 'Fetch Wh Capacity',
        status: ''
    }

    return { apiParams: apiParams, operationObj: operationObj }
}