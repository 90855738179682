import {Uchain} from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { addFileUploadSuccess, addFileUploadFailure,initiateLoading,resetData } from '../redux/reducers/reducerSlices/FileuploadReducer';
  function* addUploadFileHandler(data){
    try{
      yield put(initiateLoading());
      const response = yield call(Uchain.uploadFile, data.payload.fd);
     
       response.name=data.payload.name;
     yield put(addFileUploadSuccess(response));
    }catch(error){
     yield put(addFileUploadFailure(error));
    }
}
export function* addUploadFileRequest() {
  yield takeEvery(sagaActions.FILE_UPLOAD, addUploadFileHandler)
}


function* resetFileUploadDataHandler() {
    yield put(resetData())
    
   }
   export function*  resetFileUploadDataRequest() {
     yield takeEvery(sagaActions.RESET_FILE_UPLOAD, resetFileUploadDataHandler)
   }
   