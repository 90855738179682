import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AppConstant } from '../../../Assests/AppConstant';
/**
 * This is a reusable component for rendering Popup using react bootstrap
 * @param {*} props 
 * modalClassname = user defined modal class
 * modalLabel = modal label
 * showClose = show close button true/false
 * showfooter = show footer true/false
 * footerBtn = footer buttons
 * title = modal title
 * bodyText = modal body text
 * popupBtnHandler = popup button handler
 * modalSize = modal size
 * subText = Modal Sub Text
 * sendDriverNotification = IMS Door assignment popup, checkbox to send driver notification
 * setSendDriverNotification = set driver notification
 * closepopup = close popup handler function
 * showModal = Show modal true/false
 * @returns 
 */
function Popup(props) {
  const { modalClassname, id, modalLabel, showClose, showfooter, showHeader, footerBtn, title, bodyText, popupBtnHandler, themeSelected, modalSize, subText, sendDriverNotification, setSendDriverNotification, backdrop, keyboard, className, closepopup, showModal } = props;
  return (
    <>
      <Modal show={showModal}
        size={modalSize}
        className={`${modalClassname}`}
        aria-labelledby="contained-modal-title-vcenter"
        data-testid={AppConstant.dataTestId.popup}
        centered >
        <Modal.Header className='my-modal-header'>
          <h5 className="modal-title" id={modalLabel}>{title}</h5>
          {showClose ? <button type="button" id="close-btn" data-testid={AppConstant.dataTestId.closeBtn} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closepopup}></button> : ''}
        </Modal.Header>
        <Modal.Body  >
          {bodyText}
          {subText ? <div className="modal-body m-0 p-0 pt-1">
            <input checked={sendDriverNotification} onClick={() => { setSendDriverNotification(!sendDriverNotification) }} value={sendDriverNotification} type="checkbox" name={'ad'} />
            <label className="px-2 heading" htmlFor={'ad'}>{subText}</label>
          </div> : ''}
        </Modal.Body>
        {showfooter ? <Modal.Footer>
          {footerBtn.btn1.show ? <button type="button" className={`btn btn-reschedule ${themeSelected}`} data-bs-dismiss="modal" onClick={() => { popupBtnHandler(footerBtn.btn1.text) }}>{footerBtn.btn1.text}</button>: ''}
          {footerBtn.btn2.show ? <button type="button" className={`btn btn-approve ${themeSelected}`} data-bs-dismiss="modal" onClick={() => { popupBtnHandler(footerBtn.btn2.text) }}>{footerBtn.btn2.text}</button>: ''}
        </Modal.Footer> : ""}
      </Modal>
    </>
  );
}



export default Popup;