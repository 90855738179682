export const customStylesLight = {
    control: (base, state) => ({
        ...base,
        background: "#fff",
        border: '1px solid #e6e6e6',
        color: '#000',
        minHeight: '42px',

        // match with the menu
        //   borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        // Removes weird border around container
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: '#404954',
            color: '#000'
        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        background: "#fff",
        color: '#000',
        // kill the gap
        marginTop: 0
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        background: "#fff",
        color: '#000',
        padding: 0,

    }),
    option: (base, state) => ({
        ...base,
        // kill the white space on first and last option
        background: "#fff",
        color: '#000',
        "&:hover": {
            // Overwrittes the different states of border
            background: "#68696b6b"
        }
    }),
    singleValue: base => ({
        ...base,
        color: '#000'
    }),
    input: base => ({
        ...base,
        color: '#000'
    })
};