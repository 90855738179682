import React from 'react';
import SPDoorAssignmentList from './DoorAssignmentList/SPDoorAssignmentList';

import './DoorAssignment.css';
import DoorAssignmentHeaderIMSSP from './DoorAssignmentHeader/DoorAssignmentHeaderIMSSP';

const DoorAssignmentIMSSP = (props) => {
    return <div className='doorSection py-2'>
       <DoorAssignmentHeaderIMSSP {...props}/>
       <SPDoorAssignmentList {...props}/>
    </div>
}
 
export default DoorAssignmentIMSSP;