import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputWithTtile from "./Common/CustomInput/InputWithTtile";
import TextAreaWithTtile from "./Common/CustomTextArea/TextAreaWithTtile";
import CustomButton from "./Common/CustomButton/CustomButton";
import { AppConstant } from "../Assests/AppConstant";
import moment from "moment";
import { toast } from "react-toastify";
import { showAlert, getFormattedContactNum } from "../Assests/Utility";
import { sendEmailForAppointment } from "../../redux/reducers/reducerSlices/sendEmailForAppointment";
import { smartScheduleSelector } from '../../redux/reducers/reducerSlices/SmartScheduleReducer'
import {
  validEmailRegex,
  validContactNumRegex,
} from "../../Utils/validationRegex";
import { sagaActions } from "../../sagas/sagaActions";

const SendEmail = ({ formName, closeSendMailpopup, userId, warehouseId, formFieldList, poDetails }) => {
  const ss = useSelector(smartScheduleSelector);
  const { poForAppt, appointmentList, wpSchedulerData, wpCapacityData, wpLeaveData, wpBreakData, initateLoading, searchedPOData } = ss;
  const [loading, setLoading] = useState(false);
  const helpData = useSelector(sendEmailForAppointment);
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState([
    {
      email: "",
    },
  ]);
  //validation code
  let [errors, setErrors] = useState([]);

  let [dirty, setDirty] = useState([]);

  const { email, name, phoneNo, issue, feedback } = inputs;

  let handleChange = (e, item) => {
    const { name, value } = e.target;
    item[name] = value;
    setInputs([...inputs])
  };

  useEffect(() => {
    onLoad();
  }, [helpData]);
  const onLoad = () => {
    if (helpData.loading === false) {
      setLoading(false);
      if (helpData.error) {
        showAlert(toast.TYPE.ERROR, helpData.error.message);
      }
      if (helpData.data) {
        closeSendMailpopup();
        showAlert(toast.TYPE.SUCCESS, "Email sent successfully");
        dispatch({ type: sagaActions.RESET_SEND_MAIL_FOR_APPOINTMENT });
      }
    }

  };
  useEffect(() => {
    validationDynamic(inputs.length);
    dispatch({ type: sagaActions.RESET_SEND_MAIL_FOR_APPOINTMENT })
  }, [])
  let validate = () => {

    let valid = true;
    for (let i = 0; i < inputs.length; i++) {
      Object.keys(errors[i]).forEach((con) => {
        errors[i][con] = [];
        if (con === "email") {
          if (!inputs[i][con]) {
            errors[i][con].push("This field is required");
            valid = false;
          }
          else {
            if (!validEmailRegex.test(inputs[i][con])) {
              errors[i][con].push(AppConstant.help.emailIncorrect);
              valid = false;
            }
          }
        }



      });
    }

    setErrors([...errors]);
    return valid;
  };
  let validationDynamic = (length) => {
    let dirtyData = [];
    let errorsData = [];
    for (let i = 0; i < length; i++) {
      dirtyData[i] = {
        email: false,

      };
      errorsData[i] = {
        email: []
      };
    }
    setDirty([...dirtyData]);
    setErrors([...errorsData]);
  };
  let blurChange = (e, index) => {
    let dirtyData = dirty[index];
    dirtyData[e.target.name] = true;
    setDirty([...dirty]);
    validate();
  };



  const onSubmit = async () => {

    for (let i = 0; i < inputs.length; i++) {
      Object.keys(dirty[i]).forEach((control) => {
        dirty[i][control] = true;
      });
    }
    setDirty([...dirty]);
    const appointmentData = poDetails.viewPO ? poDetails.viewPO[0] : poDetails
    if (validate()) {


      let data = {
        metadata: {
          PONumbers: appointmentData.PoNumber,
          IDWarehouse: appointmentData.IDWarehouse
        },
        email: inputs
      }

      setLoading(true);
      dispatch({ type: sagaActions.SEND_MAIL_FOR_APPOINTMENT, payload: data });
    }
  };

  const addMoreField = () => {
    setInputs([
      ...inputs,
      {
        email: "",
      },
    ]);
    validationDynamic(inputs.length + 1);
  };

  const removeField = (index) => {
    if (inputs.length < 2) {
      toast.error("Atlast one email field is required")
        ;
    }
    else {
      let indexData = [...inputs];
      indexData.splice(index, 1);
      setInputs(indexData);
      validationDynamic(inputs.length - 1);
    }

  };
  return (
    <>
      <div>
        <span
          data-testid={AppConstant.dataTestId.customInputTitle}
          className="inputTitle"
        >
          {AppConstant.help.email}&nbsp;{<span className="color-red">*</span>}
        </span>
        <div className="inputDiv">

          {inputs.map((singData, index) => (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>

                <input
                  data-testid={AppConstant.dataTestId.customInput}
                  type={"email"}
                  value={singData.email}
                  name="email"
                  style={{ width: "100%" }}
                  className={
                    errors.length > 0 && dirty.length > 0 && dirty[index]["email"] && errors[index]["email"][0]
                      ? "customTextInput customTextInputError"
                      : "customTextInput"
                  }

                  placeholder={AppConstant.help.enterEmail}
                  onBlur={(e) => blurChange(e, index)}
                  onInput={(e) =>
                    handleChange(e, singData)
                  }
                />
                &nbsp;
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeField(index)}
                ></i>

              </div>
              <span className="errorMessage">
                {errors.length > 0 && dirty.length > 0 && dirty[index]["email"] && errors[index]["email"][0]
                  ? errors[index]["email"][0]
                  : ""}
              </span>
            </>
          ))}

        </div>

      </div>
      <i
        className="fa fa-plus-square fa-lg "
        style={{ cursor: "pointer" }}
        aria-hidden="true"
        onClick={addMoreField}
      ></i>

      <div className="d-flex justify-content-end loginButtonContainer">
        <CustomButton
          isLoading={loading}
          title={AppConstant.help.helpButtonTitle}
          className={"loginButton"}
          onClick={onSubmit}
        />
      </div>
    </>
  );
};
export default SendEmail;
