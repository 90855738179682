import React, { useEffect, useState } from 'react'
import { formatDate_MM_DD_YYYY } from '../../../../../../../../Assests/Utility'
import DatePickerSS from '../../../../../../../DatePickerSS/DatePickerSS'
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { useDispatch } from 'react-redux';
import { smartScheduleSelector } from '../../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { useNavigate } from 'react-router-dom';

/**
 * Reusable component for sub header in appointment summary report
 * @param {*} props 
 * @returns 
 */
const AppointmentReportSummarySubHeader = (props) => {
    const {getData , selectedStartDate,setSelectedStartDate,selectedEndDate,setSelectedEndDate,isDrillDown, subRoot} = props;
    const dispatch = useDispatch()
    const [showStartDate , setShowStartDate] = useState(false)
    const ss = useSelector(smartScheduleSelector);
    const { appointmentReportSummary ,initateLoading} = ss;
    const [showEndDate , setShowEndDate] = useState(false)
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse,userType ,cu_id} = userPermission;
    const navigate= useNavigate()


    // /**
    //  * useEffect to fetch data on change of startDate and endDate
    //  */
    // useEffect(()=>{
    //     if (selectedStartDate && selectedEndDate) {
    //         setTimeout(()=>{
    //             getData()
    //         },[0])
    //     }
    // },[selectedStartDate,selectedEndDate])

  return (
    <div className=' d-flex gap-4 justify-content-end align-items-center my-3 mx-3'>
                    
                    <div className='d-flex appt-report-header'>
                    <div className="pos-rel">
                        <div className='d-flex align-items-center'>
                            <div>Start Date:</div>
                                <button
                                    className={`date-picker-btn h-100 mx-2 ${initateLoading && 'disabled'}`}
                                    onClick={() => setShowStartDate(!showStartDate)}
                                    disabled={initateLoading}
                                >
                                    <span className="btn-title">{selectedStartDate ?
                                        formatDate_MM_DD_YYYY(selectedStartDate) : 'Start Date'}</span>
                                    <i
                                        className="fa-regular fa-calendar"
                                        style={{ marginInline: "9px" }}
                                    ></i>

                                </button>
                                {
                                    showStartDate && <div className="appt-summary-ss-datePicker" >
                                        <DatePickerSS maxDate={new Date(selectedEndDate)}  setSelectedDate={setSelectedStartDate} selectedDate={selectedStartDate} closeCalendar={() => { setShowStartDate(!showStartDate) }} />
                                    </div>
                                }
                                </div>
                            </div>
                            <div className="pos-rel">
                            <div className='d-flex align-items-center'>
                            <div>End Date:</div>
                                <button
                                    className={`date-picker-btn h-100 mx-2 ${initateLoading && 'disabled'}`}
                                    onClick={() => setShowEndDate(!showEndDate)}
                                    disabled={initateLoading}
                                >
                                    <span className="btn-title">{selectedEndDate ?
                                        formatDate_MM_DD_YYYY(selectedEndDate) : 'End Date'}</span>
                                    <i
                                        className="fa-regular fa-calendar"
                                        style={{ marginInline: "9px" }}
                                    ></i>

                                </button>
                                {
                                    showEndDate && <div className="appt-summary-ss-datePicker">
                                        <DatePickerSS  setSelectedDate={setSelectedEndDate} minDate={new Date(selectedStartDate)} selectedDate={selectedEndDate} closeCalendar={() => { setShowEndDate(!showEndDate) }} />
                                    </div>
                                }
                                </div>
                            </div>
                            </div>
                   
                    </div>
  )
}

export default AppointmentReportSummarySubHeader