import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { closeUpdatesSocketConnection, initializeUpdatesSocketConnection } from '../../../../../Utils/updateSocket';
import { AppConstant } from '../../../../Assests/AppConstant';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import PODockStatusFilter from '../../Components/POFilters/PODockStatusFilter/PODockStatusFilter';
import POListFilters from '../../Components/POFilters/POListFilters/POListFilters';
import TruckSection from '../../Components/TruckSection/TruckSection';

import './LoadManagement.css';

const LoadManagement = (props) => {
    const { themeSelected } = props
    const location = useLocation();
    const dispatch = useDispatch()
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;

    useEffect(() => {
        const socketRef = initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose)

        return () => closeUpdatesSocketConnection(socketRef, selectedWarehouse.ID)
    }, [])

    /**
     * Method is used to fetch truck list
     */
    const fetchTruckList = () => {
        dispatch({ type: sagaActions.FETCH_TRUCK_LIST, payload: '' })
    }

    /**
     * Method is used to handle truck update and fetch latest truck data
     */
    const onUpdateSocketReceived = (message) => {
        fetchTruckList()
    }

    /**
     * Method is used to captcure socket error and re initate the connection 
     * @param {*} error 
     */
    const onUpdateSocketError = (error) => {
        initializeUpdatesSocketConnection(selectedWarehouse.ID, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose)
    }

    /**
     * Method is used to capture socket close
     * @param {*} data 
     */
    const onUpdateSocketClose = (data) => {}

    return (
        <div className='container-fluid executor'>
            <Header {...props} />
            <Navigationbar {...props} />
            <PODockStatusFilter themeSelected={themeSelected} />
            <POListFilters themeSelected={themeSelected} />

            <TruckSection themeSelected={themeSelected} type={ 'IMS'}/>
        </div>
    )
}

export default LoadManagement;