import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../sagas/sagaActions';
import Login from '../Login/Container/MainLogin/Login';
import './MainLandingPage.css';
import { AppConstant } from '../../Assests/AppConstant';
import { showLoader } from '../../Assests/Utility';

// This component render login screen either for IMS or SchedulePro(IMS/SS) based on branding API response
const MainLandingPage = (props) => {

    const dispatch = useDispatch();
    const user = useSelector(getUserSelector);
    const { branding, brandingLoader, brandingSuccess } = user;
    const backgroundImage = AppConstant.commonStrings.bkgImgLink;
// useEffect to trigger branding API
    useEffect(() => {
        dispatch({ type: sagaActions.CLEAR_BRANING })
        // set branding loader to true
        dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: true })
        // split URL
        const subdomain = window.location.hostname.split(".")[0];
        dispatch({
            type: sagaActions.INITIATE_BRANDING, payload: {
                 subdomain: subdomain,
                // subdomain: 'wegmansguardqa'  
            }
        });
    }, [])

// useEffect after getting branding data
    useEffect(() => {
        // if brandingSuccess is true 
        if (brandingSuccess) { 
            // if branding is not empty
            if (!_.isEmpty(branding)) {
                const { IsBranding, BackgroundImage } = branding;
                // if  IsBranding and BackgroundImage has data then render image
                if (IsBranding && BackgroundImage) {
                    const img = new Image();
                    img.src = BackgroundImage;
                    img.onload = () => {
                        setTimeout(() => {
                            dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: false })
                        }, 1000)
                    };
                } else {
                    // else render default image
                    const img = new Image();
                    img.src = backgroundImage;
                    img.onload = () => {
                        setTimeout(() => {
                            dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: false })
                        }, 1000)
                    };
                }
            } else {
                 // else render default image
                const img = new Image();
                img.src = backgroundImage;
                img.onload = () => {
                    setTimeout(() => {
                        dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: false })
                    }, 1000)
                };
            }

        }
    }, [brandingSuccess])

    return (
        <>
            {/* if Subdomain is Schedulepro then Scheduleprologin otherwise IMS login */}
            {
                brandingLoader ? showLoader(AppConstant.login.mainLandingLoaderText) : <Login />
            }
        </>
    );

}

export default MainLandingPage