import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultDashboardSelector } from '../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getSelectedFiltersSelector, getSelectedDockFilterListSelector } from '../../../../../../../redux/reducers/reducerSlices/FiltersReducerSlice';
import { getDockListSelector } from '../../../../../../../redux/reducers/reducerSlices/DockListReducer';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import "funnel-graph-js/dist/css/main.min.css";
import "funnel-graph-js/dist/css/theme.min.css";
import { useContainerDimensions } from '../../../../../Common/CustomHooks/useContainerDimensions';
import Loader from '../../../../../Loader/Loader.jsx';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { ReactComponent as NoDataLightMode } from '../../../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../../../Assests/Svg/NoDataDarkMode.svg';
import { createDocksIdArr } from '../../../POFilters/FilterUtilities';
import { Link, useNavigate } from 'react-router-dom';
import { getFormattedDate, YYYY_MM_DD_FORMAT, isDateSameOrBefore, getCurrentDate } from '../../../../../../../Utils/DateUtils';
import { getSelectedDateSelector } from '../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { funnelGraphData } from '../../../../../../Assests/GraphUtilities';
import FunnelGraphWidget from '../Graphs/FunnelGraphWidget/FunnelGraphWidget';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { usePrevious } from '../../../../../Common/CustomHooks/usePrevious';
import _ from 'lodash-es';
import './StatusOverviewDash.css';

const StatusOverviewDash = (props) => {

  const dispatch = useDispatch();
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const { themeSelected } = props;
  // const [selectedButton, setSelectedButton] = useState('all');
  const defaultDashboard = useSelector(getDefaultDashboardSelector);
  const { dashboardLoadStatusObj, autoFetch, selectedButtonStatusOverview, dateRangeObj } = defaultDashboard;
  const selectedFiltersList = useSelector(getSelectedFiltersSelector);
  const selectedDockFiltersList = useSelector(getSelectedDockFilterListSelector);
  const [titlesArr, setTitlesArr] = useState([]);
  const [quantityArr, setQuantityArr] = useState([]);
  const [legendsArr, setLegendsArr] = useState([]);
  const [timeArr, setTimeArr] = useState([]);
  const [colorArr, setColorArr] = useState([]);
  const [fetchingFunnelGraphData, setFetchingFunnelGraphData] = useState(false);
  const getDockList = useSelector(getDockListSelector);
  const { dockList } = getDockList;
  const [dockIdArr, setDockIdArr] = useState([]);
  const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
  const { selectedDate } = getSelectedDateSelectorObj;
  const userPermission = useSelector(getUserSelector);
  const { selectedWarehouse } = userPermission;
  const [isDataNull, setIsDataNull] = useState(false);
  const previousDateRangeObj = usePrevious(dateRangeObj);
  const [funnelDisabled, setFunnelDisabled] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    setFetchingFunnelGraphData(true);
    getDockId();
  }, [])

  useEffect(() => {
    setFunnelDisabled(true)
    if (dateRangeObj === {} || previousDateRangeObj === undefined) {
      return
    } else if (dateRangeObj && dateRangeObj.start && dateRangeObj.end) {
      const startDateCheck = _.isEqual(getFormattedDate('DD-MM-YYYY', dateRangeObj.start), getFormattedDate('DD-MM-YYYY', getCurrentDate()))
      const endDateCheck = _.isEqual(getFormattedDate('DD-MM-YYYY', dateRangeObj.end), getFormattedDate('DD-MM-YYYY', getCurrentDate()))
      if (startDateCheck && endDateCheck) {
        setFunnelDisabled(false)
        resetValues();
        setFetchingFunnelGraphData(true);
        hitDashboardLoadsApi(selectedButtonStatusOverview);
      } else {
      }
    }
  }, [dateRangeObj])

  useEffect(() => {
    resetValues();
    setFetchingFunnelGraphData(true);
  }, [dashboardLoadStatusObj])

  useEffect(() => {
    setDockIdArr([]);
    if (selectedDockFiltersList.length) {
      setDockIdArr(createDocksIdArr(selectedDockFiltersList, dockList));
    }
  }, [selectedDockFiltersList])

  useEffect(() => {
    setFetchingFunnelGraphData(true);
    resetValues();
    setTimeout(() => {
      if ((Object.keys(dashboardLoadStatusObj).length !== 0)) {
        makeGraphData();
        setFetchingFunnelGraphData(false);
      }
    }, 100)

  }, [themeSelected])

  useEffect(() => {
    showFunnelGraph();
  }, [timeArr])

  const resetValues = () => {
    setTitlesArr([]);
    setQuantityArr([]);
    setLegendsArr([]);
    setTimeArr([]);
    setColorArr([]);
  }

  //Implemeted real time refresh
  // useEffect(() => {
  //   if (autoFetch) {
  //     hitDashboardLoadsApi(selectedButtonStatusOverview)
  //   }
  // }, [autoFetch])

  useEffect(() => {
    if ((Object.keys(dashboardLoadStatusObj).length !== 0)) {
      resetValues();
      setTimeout(() => {
        setFetchingFunnelGraphData(false);
        makeGraphData();
      }, 100)

    }
  }, [dashboardLoadStatusObj])


  const getDockId = () => {
    dispatch({ type: sagaActions.DOCK_LIST, payload: { warehouse_id: selectedWarehouse.ID } })
  }

  const makeGraphData = () => {
    if (dashboardLoadStatusObj.appointment_count
      || dashboardLoadStatusObj.checkin_count
      || dashboardLoadStatusObj.door_assign_count
      || dashboardLoadStatusObj.released_count
      || dashboardLoadStatusObj.unloading_count) {
      setIsDataNull(false)
      const funnelGraphObj = funnelGraphData(dashboardLoadStatusObj, themeSelected);
      setTitlesArr(funnelGraphObj.titlesArr);
      setQuantityArr(funnelGraphObj.quantityArr);
      setLegendsArr(funnelGraphObj.legendsArr);
      setTimeArr(funnelGraphObj.timeArr);
      setColorArr(funnelGraphObj.colorsArr);
    } else {
      setIsDataNull(true)
    }
  }

  const toggleLoadsData = (loadStr) => {
    dispatch({
      type: sagaActions.UPDATE_SELECTED_BUTTON, payload: {
        key: 'selectedButtonStatusOverview',
        loadStr: loadStr
      }
    })
    setFetchingFunnelGraphData(true);
    resetValues();
    hitDashboardLoadsApi(loadStr);
    createBtn()
  }

  const hitDashboardLoadsApi = (loadsStr) => {
    const params = {
      "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
      "warehouse_id": selectedWarehouse.ID,
      "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
      "isDrillDown": "0",
    }
    dispatch({ type: sagaActions.GET_DASHBOARD_STATUS_TRUCK_LIST, payload: params });
  }

  const createBtn = () => {
    return <>
      <button type="button" className={`buttonFunnelDash ${selectedButtonStatusOverview === 'all' ? 'selectedButton' : 'unselectedButton'}  me-2`} onClick={() => toggleLoadsData('all')}>{AppConstant.dashboard.defaultdashboard.funnelGraph.sectionTitle.allLoadsButtonTitle}</button>
      <button type="button" className={`buttonFunnelDash ${selectedButtonStatusOverview === 'late' ? 'selectedButton' : 'unselectedButton'} me-2`} onClick={() => toggleLoadsData('late')}>{AppConstant.dashboard.defaultdashboard.funnelGraph.sectionTitle.lateLoadsButtonTitle}</button>
    </>
  }

  const displayGraph = () => {
    return (
      <FunnelGraphWidget
        legendsArr={legendsArr}
        quantityArr={quantityArr}
        timeArr={timeArr}
        colorArr={colorArr}
        height={height}
        width={width}
        themeSelected={themeSelected}
        containerId={'Funnel'}
        onClickAction={((stage) => {
          navigate(`/analytics/reports/loadstatus/${stage}`)
          
        })}
      />
    )
  }

  const showNoDataError = () => {
    return (
      <div className='d-flex flex-column align-items-center noDataAvailable'>
        <span className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</span>
        <span className={`no-data-text1 ${themeSelected}`}>{AppConstant.poManagement.emptyTable.text1}</span>
      </div>
    )
  }

  const showFunnelGraph = () => {
    return (
      <div className='m-0 p-0 '>
        {/* <Link to="/analytics/reports/loadstatus" style={{ color: 'inherit', textDecoration: 'inherit' }}> */}
          <div className={`graphHeading ${themeSelected}`}>{AppConstant.dashboard.defaultdashboard.funnelGraph.sectionTitle.loadStatusOverviewTitle}</div>
        {/* </Link> */}
        <div className='d-flex justify-content-start my-1'>
          {createBtn()}
        </div>
        {
          fetchingFunnelGraphData ?
            (<Loader height={height / 2} count={1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
              baseColor={themeSelected === 'dark-mode' ? '#575757' : ''}
            />)
            :
            ((Object.keys(dashboardLoadStatusObj).length && !isDataNull) ?
              <div>
                <div className='funnelGraphContainer'>
                  {displayGraph()}
                </div>
                <div className='backhauls'>
                  {AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.backhaulsAndDrops}
                </div>
                <span className='d-flex h-100 justify-content-center liveloads'>
                  {AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.liveloads}
                </span>
              </div>
              :
              showNoDataError())
        }
      </div>
    )
  }
  // funnelDisabled
  return (
    <div className={`col m-0 p-0 ${funnelDisabled ? '' : '' }`} ref={componentRef}>
      <div className='container-fluid m-0 p-0'>
        <div className='m-0 p-0'>
          {showFunnelGraph()}
        </div>
      </div>
    </div>
  )
}

export default StatusOverviewDash;


