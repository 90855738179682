import React from 'react'
import { getCurrentDate } from '../../../../Utils/DateUtils';
import { AppConstant } from '../../../Assests/AppConstant';
import _ from 'lodash';
import CustomButtonSS from '../../Common/CustomButton/CustomButtonSS';
import CustomDropDownSS from '../../Common/CustomDropDown/CustomDropDownSS';
import SchedulerDatePickerSS from '../Components/SchedulerDatePicker/SchedulerDatePickerSS';
import { useNavigate } from 'react-router-dom';


const SchedulerHeaderBarSS = (props) => {
  const { datePickerSelectedDate, changeDatePickerSelectedDate, allDock, onDockSelected, clearSearchedPOData,  shouldRender, selectedDock,  defaultDockValueState } = props;
  const navigate = useNavigate();

  return (
    <div className='d-flex flex-row justify-content-between align-items-center '>
      <div className=' d-flex justify-content-center align-items-center'>
        {/* <SchedulerDatePickerSS {...props}
          disabledProps={false}
          maxDateProps={"NA"}
          minDateProps={getCurrentDate()}
          selectedDate={datePickerSelectedDate}
          onDateChange={(dates) => changeDatePickerSelectedDate(dates)}
          startDateProps={getCurrentDate()}
          endDateProps={''}
          selectedDateFormat={AppConstant.commonStrings.MM_DD_YYYY_dateFormat}
          selectsRangeProps={''}
          customInputExample={''}
          classNameProps={'schedule-date-picker-ss'}
          datePickerSelectedDate={datePickerSelectedDate}
        /> */}
        {allDock && _.size(allDock) && shouldRender ? <div className='ms-2'>
          {
            <div className='d-flex flex-row fs-6 align-items-center '>
              {/* <div className='me-2 f-14'>Select Dock :</div> */}
              <p className="f-12">Select a Dock &nbsp; </p>{" "}
              <CustomDropDownSS themeSelected={props.themeSelected} data={allDock} placeHolder={selectedDock?.dockName || 'ALL'} onSelect={onDockSelected} keyStr={'dockName'} />
            </div>
          }
        </div> : ''}
        {allDock && selectedDock && _.size(allDock) && shouldRender && selectedDock?.dockName !== 'ALL' ? <div className='ms-2'>
          {
            <div className='mt-1'>
              <div className="input-container default-dock">
              <label htmlFor="defaultDock">
                <span className="btn_title f-12">{AppConstant.commonStrings.defaultDock}</span>
                </label>{" "}
                <div className='ms-2'>
                <input
                    type='checkbox'
                    value={defaultDockValueState}
                    name='defaultDock'
                  className={'customTextInputError'}
                  checked={defaultDockValueState ? true : false }
                    // onBlur={(data) => props.handleErrors(data.target.name)}
                    onInput={(data) => props.onDefaultDockChange(data.target.value, data.target.checked)}
                    // disabled={disabled || ''}
                />
                  </div>
            </div>
            </div>
          }
        </div> : ''}
        
      </div>
      <div className='d-flex gap-2'>
      <div className="d-flex gap-2">
    <div className="d-flex gap-3 mx-3">
      <div className="f-12 fw-600 d-flex align-items-center gap-2">
        <div className="code__schedule" />
        Scheduled
            </div>
        <div className="f-12 fw-600 d-flex align-items-center gap-2">
        <div className="code__reschedule" />
        Rescheduled
            </div>
        <div className="f-12 fw-600 d-flex align-items-center gap-2">
        <div className="code_completed" />
        Completed
      </div>
      <div className="f-12 fw-600 d-flex align-items-center gap-2">
        <div className="code__Leaves" />
        Holiday
      </div>
      <div className="f-12 fw-600 d-flex align-items-center gap-2">
        <div className="code_closed" />
        Closed
      </div>
      <div className="f-12 fw-600 d-flex align-items-center gap-2">
        <div className="code__break" />
        Breaks
      </div>
    </div>
    </div>
        {/* <div className='cursor-pointer'> <i className="fa fa-fw fa-refresh ss-icon"></i></div> */}
        {/* <div className='cursor-pointer' onClick={() => props.openModel()}> <i className="fa fa-fw fa-plus"></i></div> */}
      </div>
    </div>
  );
}

export default SchedulerHeaderBarSS;