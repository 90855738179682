import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import DrilldownTable from '../../Common/DrilldownTable';
import { convertGmtToDateRange } from '../../../../../../../../../Utils/DateUtils';
import DrilldownTableSS from '../../Common/DrilldownTableSS';
import { CustomPaginationSS } from '../../../../../../../Common/CustomPaginationSS/CustomPaginationSS';

const DrillDownDoorAssignment = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { doorAssignmentReportDrillDown, arrangeOrder, arrangeKey, initiateDrillDownReport, customDashboardDockSelectedList, dateRangeObj } = defaultDashboard;
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const [doorAssignmentSelectedDockState, setDoorAssignmentSelectedDockState] = useState(null)

    let unloadingTypeSummaryTitleDD = [
        { id: 'Door', name: 'Door' },
        { id: 'Control', name: 'Load Number' },
        { id: 'LumpStartTime', name: 'Lump Start' },
        { id: 'LumpEndTime', name: 'Lump End' },
        { id: 'BillsOutTime', name: 'Bills Out' },
        { id: 'InDetention', name: 'In Detention' },
        { id: 'NotinDetention', name: 'Not in Detention' },
        { id: 'NearingDetention', name: 'Nearing Detention' }
    ]

    useEffect(() => {
        dispatch({ type: sagaActions.RESET_DOOR_ASSIGNMENT_DRILLDOWN });
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        setDrilldownData([])
        return () => {
            dispatch({ type: sagaActions.RESET_DOOR_ASSIGNMENT_DRILLDOWN });
        }
    }, [])

    useEffect(() => {
        if (_.size(customDashboardDockSelectedList)) {
            const filterDoorUsageSummary = _.filter(customDashboardDockSelectedList, { name: 'Door Assignment' })
            if (_.size(filterDoorUsageSummary)) {
                setDoorAssignmentSelectedDockState(filterDoorUsageSummary[0])
            }

        }
    }, [customDashboardDockSelectedList])

    useEffect(() => {
        if (!_.isEmpty(doorAssignmentSelectedDockState)) {
            dispatch({ type: sagaActions.GET_DOOR_ASSIGNMENT, payload: createPayload() });
        }
    }, [doorAssignmentSelectedDockState])

    const createPayload = (pageNo,limit) => {
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        return {
            "dock_id": doorAssignmentSelectedDockState ? doorAssignmentSelectedDockState?.dock_id : null,
            "isDrillDown": "1",
            "warehouse_id": selectedWarehouse.ID,
            "fromDate": start !== "Invalid date" ? start : null,
            "endDate": end !== "Invalid date" ? end : null,
            "drillDownType": doorAssignmentSelectedDockState ? doorAssignmentSelectedDockState?.dock_id : null,
            "selectedDoor": null,
            "pageNo": pageNo || 1,
            "limit": limit || 50
        }
    }

    useEffect(() => {
        if (doorAssignmentReportDrillDown && doorAssignmentReportDrillDown?.data?.length) {
            let data = formatDrillDownData(doorAssignmentReportDrillDown.data)
            setDrilldownData(data)
            dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: false })
        } else if(initiateDrillDownReport && doorAssignmentReportDrillDown && !doorAssignmentReportDrillDown?.data?.length) {
            setDrilldownData([])
            initiateDrillDownReport && dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: false})
        }
    }, [doorAssignmentReportDrillDown?.data])

    useEffect(() => {
        if (!_.isEmpty(doorAssignmentSelectedDockState)) {
            dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
            dispatch({ type: sagaActions.GET_DOOR_ASSIGNMENT, payload: createPayload() });
        }
    }, [dateRangeObj])


    const formatDrillDownData = (data) => {
        return data.map(el => {
            return {
                id: `${el.Door}_${el.Control}`,
                0: el.Door || '-',
                1: el.Control || '-',
                2: el.LumpStartTime || '-',
                3: el.LumpEndTime || '-',
                4: el.BillsOutTime || '-',
                5: el.InDetention || '-',
                6: el.NotinDetention || '-',
                7: el.NearingDetention || '-',
            }
        })
    }

    const pageChange = (pageNo, limit) => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        dispatch({ type: sagaActions.GET_DOOR_ASSIGNMENT, payload: createPayload(pageNo, limit) })
    }

    return (
        <>
            <DrilldownTableSS showUTCTime={true} themeSelected={themeSelected} bodyData={drilldownData} titleData={unloadingTypeSummaryTitleDD} showCollapse={false} showInput={true} showSort={true} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={doorAssignmentReportDrillDown?.data} initiateDrillDownReport={initiateDrillDownReport} loaderArray={loaderArray} subRoot={subRoot} sortingAction={sagaActions.SORT_DOOR_ASSIGNMENT_DRILLDOWN} />
            {doorAssignmentReportDrillDown?.data?.length ?
                  <div className='f-14 detention-status-pagination'>
                    <CustomPaginationSS
                        pageChange={(pageNo, limit) => { pageChange(pageNo, limit) }}
                        pageNo={doorAssignmentReportDrillDown.pageNo}
                        pageSize={doorAssignmentReportDrillDown.limit}
                        totalElements={doorAssignmentReportDrillDown.totalElements}
                        totalPages={Math.ceil(doorAssignmentReportDrillDown.totalElements / doorAssignmentReportDrillDown.limit)}
                        last={doorAssignmentReportDrillDown.last}
                        first={doorAssignmentReportDrillDown.first}
                        data={doorAssignmentReportDrillDown.data}
                    />
                </div>: ''
            }
        </>
    );
}

export default DrillDownDoorAssignment;