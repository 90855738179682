import _, { random } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { getActiveCountryCode, getActiveUserCount, shouldHavePermission, showAlert, updateUserPayload } from '../../../../../../Assests/Utility';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import WarehouseSearchSS from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import { CustomDynamicFormSS } from '../../../../../Common/CustomForm/CustomDynamicFormSS';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';
import SPUserTable from './SPUserTable';
import '../SPUserWrapper.css'
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import { getRemoteConfigSelector } from '../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { getCountryList } from '../../../../../../../redux/reducers/reducerSlices/GetCountryListReducer';
/** This component render list of Schedule Pro user with
* CREATE - Create user in Warehouse with SSADMIN Role permissions
* At any giver time only n(SP_User_Creation_limit) number of active SSADMIN
* SP_User_Creation_limit - Limit from Config table
*/

const SPUserList = (props) => {
    const { themeSelected } = props;
    const [createUser, setCreateUser] = useState({});
    const [userPopupHeading, setUserPopupHeading] = useState(AppConstant.user.popup.createUser.title)
    const dispatch = useDispatch();
    const user = useSelector(getUserSelector);
    const { permissions, closeCompanyForm, error, cu_id, userType, parentWarehouse, userList } = user;
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState({});
    const [resetFormData, setResetFormData] = useState(0);
    const [allowPermissionC, setAllowPermissionC] = useState(false)
    const [allowPermissionE, setAllowPermissionE] = useState(false)
    const [allowPermissionA, setAllowPermissionA] = useState(false)
    const [allowPermissionD, setAllowPermissionD] = useState(false)
    const [allowPermissionRP, setAllowPermissionRP] = useState(false)
    const [allowPermissionRE, setAllowPermissionRE] = useState(false)
    const [searchStr, setSearchStr] = useState('');
    const remoteConfig=useSelector(getRemoteConfigSelector);
    const {allConfigration}=remoteConfig;
    const countryData = useSelector(getCountryList);

    /** Initial useEffect */
    useEffect(() => {
        if (permissions.length) {
            // get Sub permission for CREATE/EDIT/ASSIGN/DELETE/RESEND PASSWORD/RESEND EMAIL
            let tempC = shouldHavePermission(permissions, '509')
            setAllowPermissionC(tempC);
            let tempE = shouldHavePermission(permissions, '504')
            setAllowPermissionE(tempE);
            let tempA = shouldHavePermission(permissions, '505')
            setAllowPermissionA(tempA);
            let tempD = shouldHavePermission(permissions, '506')
            setAllowPermissionD(tempD);
            let tempRP = shouldHavePermission(permissions, '507')
            setAllowPermissionRP(tempRP);
            let tempRE = shouldHavePermission(permissions, '508')
            setAllowPermissionRE(tempRE);
        }
 
    }, [])
    useEffect(() => {
        setLoading(false)
        if (closeCompanyForm) {
            closepopup();
            document.getElementById('close-btn')?.click();
            dispatch({ type: sagaActions.UPDATE_COMPANY_FORM_POPUP, payload: false });
        }
    }, [closeCompanyForm])
    /** useEffect for Error */
    useEffect(() => {
        if (error) {
            setLoading(false);
            dispatch({ type: sagaActions.UPDATE_USER_ERROR, payload: false });
        }
    }, [error])
 
    /**
     *
     * @returns CREATE SP user popup
     */
    const showCreateUserPopup = () => {
        const popupObj = {
            id: 'createUserFormPopup',
            modalLabel: 'createUserFormPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            className: "createUserPopup",
            title: userPopupHeading,
            bodyText: createForm(),
            subText: "",
            keyboard: true,
            backdrop: true,
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.login.save
                }
            },
        }
 
 
 
        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closepopup} themeSelected={themeSelected} showModal={showModal} />
    }
    const showEditUserPopup = () => {
 
        const popupObj = {
            id: 'editUserFormPopup',
            modalLabel: 'editUserFormPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            className: "createUserPopup",
            title: AppConstant.user.popup.editUser.title,
            bodyText: createForm(),
            subText: "",
            keyboard: true,
            backdrop: true,
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.update
                }
            },
        }
 
        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closepopup} themeSelected={themeSelected} showModal={showModal} />
    }
 
    /**
     * Cancel button data object
     */
    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };
     /**
     * Submit button data object
     */
    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };
    /**
     * Edit click handler function
     * @param {*} data
     * @param {*} index
     */
    const editClicked = (data, index) => {
        const tempData = updateUserPayload(data, false);
        setShowModal(true);
        setEditData({ ...tempData })
        setUserPopupHeading(AppConstant.user.popup.editUser.title)
    }
    /**
     * Submit function handler
     * @param {*} data
     */
    const getSummitedFormData = (data) => {
        setLoading(true)
        dispatch({ type: sagaActions.UPDATE_COMPANY_FORM_POPUP, payload: false });
        if (data?.crud_type === 'U') {
            if (!_.isEmpty(data)) {
                const contactNum = data.phone_number.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '');
                data.phone_number = "+"+contactNum;
                dispatch({ type: sagaActions.UPDATE_USER_LIST, payload: updateUserPayload(data, true , cu_id, userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID) })
            } else {
                showAlert(toast.TYPE.WARNING, AppConstant.user.toast.blankForm, themeSelected)
            }
        } else {
            if (getActiveUserCount(userList) >= allConfigration?.SP_User_Creation_limit) {
                showAlert(toast.TYPE.ERROR, `User limit exceed ! Only ${allConfigration?.SP_User_Creation_limit} SSADMIN users can be active at a time.`, themeSelected)
                setLoading(false)
                return
            }
            setCreateUser({ ...data })
            if (!_.isEmpty(data)) {
                let tempData = { ...data }
                tempData.user_name = data.email
                tempData.p_cu_id = userType === AppConstant.userPermission.usertypes.superAdmin ? null : cu_id
                tempData.warehouseId = userType === AppConstant.userPermission.usertypes.superAdmin ? null : parentWarehouse.ID
                const contactNum = tempData.phone_number.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '');
                tempData.phone_number =  '+'+contactNum;
                dispatch({ type: sagaActions.REGISTER_USER, payload: tempData })
            } else {
                showAlert(toast.TYPE.WARNING, AppConstant.user.toast.blankForm, themeSelected)
            }
 
        }
    };
 
    const formFieldList = [
        {
            name: "email",
            type: "text",
            size: "col-md-12",
            position: 1,
            title: AppConstant.user.popup.createUser.fields.user_name,
            formValidation: {
                required: true,
                validateEmail: true,
            },
             isEditDisable: true
        },
        {
            name: "given_name",
            type: "text",
            size: "col-md-12",
            position: 2,
            title: AppConstant.user.popup.createUser.fields.given_name_title,
            formValidation: {
                required: true,
            },
        },
 
        {
            name: "family_name",
            type: "text",
            size: "col-md-12",
            position: 3,
            title:
                AppConstant.user.popup.createUser.fields.family_name_title,
            formValidation: {
                required: true,
            },
        },
        {
            name: "phone_number",
            type: "phone_with_country_code",
            size: "col-md-12",
            position: 3,
            title:
                AppConstant.user.popup.createUser.fields.phone_number_title,
                formValidation: {
                    required: true,
                    // validateContactNum: true,
                    // customError: AppConstant.commonStrings.phoneCustomError
                 
                },
            placeholder: AppConstant.user.popup.createUser.phone_placeHolder,
            activeCountriesListCodes: getActiveCountryCode(countryData?.data)
 
        },
 
    ];
 
    const createForm = () => {
        return (
            <CustomDynamicFormSS
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
            />
        );
    };
    const [showModal, setShowModal] = useState(false);
    const closepopup = () => {
 
        setEditData({});
        setShowModal(false);
    }
 
 
 
 
    return (<div className='mt-2'>
        <div className='container-fluid d-flex py-2 text-start justify-content-between sp-user-header'>
            <CustomButtonSS
                dataBsToggle="modal"
                dataBsTarget={`#createUserFormPopup`}
                onClick={() => {
                    setCreateUser({})
                    setUserPopupHeading(AppConstant.user.popup.createUser.title)
                    setShowModal(true);
                }}
                className={allowPermissionC ? 'sp-btn-highlighted' : 'sp-btn-highlighted disabled'}
                disabled={allowPermissionC ? '' : 'disabled'}
                // isLoading={isLoading}
                title={AppConstant.user.createBtnTitle}
            />
            <div className='w-25'>
                <WarehouseSearchSS
                    placeholder={'Search Users'}
                    inputValue={searchStr}
                    cancelSearch={() => setSearchStr('')}
                    onChangeHandler={(text) => setSearchStr(text)} />
            </div>
        </div>
 
 
        <div className="spuserListTable mt-1">
            <SPUserTable searchStr={searchStr}  {...props} setCreateUser={setCreateUser} allowPermissionE={allowPermissionE} allowPermissionA={allowPermissionA} allowPermissionD={allowPermissionD} allowPermissionRP={allowPermissionRP} allowPermissionRE={allowPermissionRE} editUserAction={(userObj, index) => editClicked(userObj, index)} />
            {showCreateUserPopup()}
            {/* {showEditUserPopup()} */}
 
        </div>
    </div>);
}
 
export default SPUserList;