import React, { useEffect, useState } from 'react'
import './LoginSS.css'
import usePasswordToggle from '../CustomHooks/usePasswordToggle'
import { AppConstant } from '../../../../../../Assests/AppConstant';
import TruckComponent from './Components/TruckComponent'
import InputWithTtileSS from '../../../../../Common/CustomInput/InputWithTtileSS';
import PasswordInputSS from '../../../../../Common/PasswordInput/PasswordInputSS';
import RememberMeSS from '../../../../components/RememberMeSS';
import { useSelector } from 'react-redux';
import { isUserRememberedReducer } from '../../../../../../../redux/reducers/reducerSlices/AuthReducer';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import { Link, useNavigate } from 'react-router-dom';
import HelpAndFeedbackSS from './Components/HelpAndFeedbackSS/HelpAndFeedbackSS';

// This component renders Schedule PRo login screen - New
export const LoginSS = (props) => {
    const { emailError, loginError, email, onEmailChange, initiateSignIn, getSignInLoading, setPassword, password
    } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const isUserRemembered = useSelector(isUserRememberedReducer);
    const isRememberMeChecked = (e) => {
        dispatch({ type: sagaActions.REMEMBER_USER, payload: e.target.checked })
    }

    return (
        <div className='login-wrapper' data-testid='login'>
            <div
                className="login-container-ss container-fluid d-flex justify-content-between p-4"
                id="container"
            >
                
                <div className="inner_blocks" id="right-block">
                    <div id="logo-holder">
                        <img src={AppConstant.commonStrings.schedulerProLogo} alt="FrieghtSmith logo" />
                    </div>
                    {/* -------------------- Form Section ------------------------*/}
                    <div className="form-section">
                        <form onSubmit={initiateSignIn}>
                            <h5>Login</h5>
                            <p id="login-sub-header">Enter your valid credentials</p>
                            <InputWithTtileSS
                                errorMessage={AppConstant.login.emailIncorrect}
                                showError={emailError}
                                value={email ? email : ''}
                                title={AppConstant.login.email} placeHolder={AppConstant.login.enterEmail}
                                onEmailChange={(e) => onEmailChange(e, 'email')}
                                required={true}
                                titleIcon={true}
                                titleIconClass={'fa-regular fa-envelope'}
                                customClass={'customTextInput'}
                            />

                            <br />
                            <PasswordInputSS
                                errorMessage={AppConstant.login.passwordIncorrect}
                                showError={loginError}
                                value={password ? password : ''}
                                title={AppConstant.login.password} placeHolder={AppConstant.login.enterPassword}
                                onPasswordChange={setPassword}
                                required={true}
                                customClass={'passwordTextInput'}
                            />
                            <br />
                            <RememberMeSS
                                isChecked={isUserRemembered} onChangeText={(e) => isRememberMeChecked(e)}
                            />


                            <div className="d-flex justify-content-between w-100 my-3">
                                <CustomButtonSS
                                    isLoading={getSignInLoading}
                                    title={AppConstant.login.loginButtonTitle} className={"btn-submit"}
                                    onClick={() => initiateSignIn()}
                                />
                                <Link to="schedulepro/recover">
                                    <CustomButtonSS
                                        isLoading={''}
                                        title={AppConstant.login.forgotPassword} className={"forgot-pass"}
                                        onClick={() => { }}
                                    />
                                </Link>



                            </div>
                        </form>
                    </div>
                    <div className="singleBtn">
                        <button id="reg-btn" data-testid='registration_button' className="modal_btns" onClick={() => navigate("schedulepro/signup")}>
                            <i className="fa-solid fa-user-plus"></i>
                            <span className="btn_title">Carrier and Vendor Registration</span>
                        </button>
                    </div>
                </div>
                <TruckComponent />
            </div>

        </div>
    )
}
