import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getFileUploadReducer } from '../../../../../../../redux/reducers/reducerSlices/FileuploadReducer';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { showAlert } from '../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import DefaultProfilePic from '../../../../../../Assests/Images/defaultProfilePic.png'
import { toast } from 'react-toastify';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';

/**
 * This component render user information
 * First name/Last Name/Location/Role/Email/Contact No.
 * with new UI
 * @param {*} props 
 * @returns 
 */
function UserInformationSS(props) {
    const { themeSelected, setTab } = props;
    const dispatch = useDispatch()
    const user = useSelector(getUserSelector);
    const fileUploadData = useSelector(getFileUploadReducer);
    const { userAccountDetails, cu_id, userType } = user
    const { FirstName, LastName, WarehouseName, RoleName, Email, Phone, ProfilePicture } = userAccountDetails;
    const [imageUploading, setImageUploading] = useState({ loading: false, name: "" });
     /**
    * useEffect for file upload
    */
    useEffect(() => {
        if (fileUploadData?.data) {
            setImageUploading({ loading: false, name: "" });
            if (fileUploadData.data.name) {
                dispatch({
                    type: sagaActions.GET_USER_ACCOUNT_DETAILS, payload: {
                        cu_id: cu_id,
                        crud_type: 'U',
                        profile_picture: fileUploadData.data.fileUrl
                    }
                })
                dispatch({ type: sagaActions.RESET_FILE_UPLOAD });
            }
        } else {
            if (fileUploadData?.error) {
                setImageUploading({ loading: false, name: "" });
                showAlert(toast.TYPE.ERROR, fileUploadData.error.message);
            }
        }

    }, [fileUploadData]);
    /**
       * file upload handler function
       * @param {*} fileData 
       * @param {*} name 
    */
    const onFileChange = async (fileData, name) => {
        setImageUploading({ loading: true, name: name });
        const fd = new FormData();
        fd.append("file", fileData);
        let fileUploadData = {
            name,
            fd
        }
        dispatch({ type: sagaActions.FILE_UPLOAD, payload: fileUploadData });

    };
    return (
        <div className='acc-settings-body'>
            <div className=' d-flex justify-content-start align-items-center gap-3 w-100'>
                <div className="profile-pic">
                    <label className="-label subText m-0" htmlFor="file">
                        <span><i className="fa fa-camera" aria-hidden="true"></i></span>
                        <span>{AppConstant.settings.userAccountTitle.changeProfilePic}</span>
                    </label>
                    <input id="file" type="file" name="pic" accept={`.jpg,.png,.jpeg`} onInput={(data) => onFileChange(data.target.files[0], data.target.name)} />
                    <img src={ProfilePicture ? ProfilePicture : DefaultProfilePic} id="output" alt='user' className='acc-settings-img' />
                    {/* <img src={ProfilePicture ? ProfilePicture : DefaultProfilePic} alt='user' className='acc-settings-img' /> */}
                </div>
                <div>
                    <h3 className='mb-2'>{FirstName + " " + LastName || '-'}</h3>
                    {/* <p className='text-highlighted f-12 mt-2 pointer'>edit profile</p> */}
                </div>
            </div>
            <div className='d-flex justify-content-start w-100 mt-4'>
                <div className='acc-settings-key'><i className="fa-solid fa-user"></i> {AppConstant.user.popup.createUser.fields.given_name_title}</div>
                <div className='acc-settings-value'>{FirstName || '-'}</div>
            </div>
            <div className='d-flex justify-content-start w-100 mt-4'>
                <div className='acc-settings-key'><i className="fa-solid fa-user"></i> {AppConstant.user.popup.createUser.fields.family_name_title}</div>
                <div className='acc-settings-value'>{LastName || '-'}</div>
            </div>
            {userType === AppConstant.userPermission.usertypes.user &&
                <>
                    <div className='d-flex justify-content-start w-100 mt-4'>
                        <div className='acc-settings-key'><i className="fa-solid fa-location-dot"></i> {AppConstant.settings.userAccountTitle.location}</div>
                        <div className='acc-settings-value'>{userType === AppConstant.userPermission.usertypes.user ? WarehouseName ? WarehouseName.slice(2) : '-' : WarehouseName}</div>
                    </div>

                    <div className='d-flex justify-content-start w-100 mt-4'>
                        <div className='acc-settings-key'><i className="fa-solid fa-newspaper"></i> {AppConstant.settings.userAccountTitle.role}</div>
                        <div className='acc-settings-value'>{userType === AppConstant.userPermission.usertypes.user ? RoleName ? RoleName.slice(2) : '-' : RoleName} </div>
                    </div>
                </>
            }
            <div className='d-flex justify-content-start w-100 mt-4'>
                <div className='acc-settings-key'><i className="fa-solid fa-envelope"></i> {AppConstant.settings.userAccountTitle.email}</div>
                <div className='acc-settings-value text-truncate'>{Email || '-'}</div>
            </div>
            <div className='d-flex justify-content-start w-100 mt-4'>
                <div className='acc-settings-key'><i className="fa-solid fa-phone"></i> {AppConstant.settings.userAccountTitle.phone}</div>
                <div className='acc-settings-value'>{Phone || '-'}</div>
            </div>
            <br />
            {/* <hr />
            <br />
            <div className='d-flex justify-content-start w-100 mt-4'>
                <div className='acc-settings-key'><i className="fa-solid fa-lock"></i> Password</div>
                <div className='acc-settings-value'>****************<span className='ms-3 text-highlighted f-14'>view</span></div>
            </div> */}
            <div className='d-flex justify-content-start w-100 mt-4'>
                <CustomButtonSS
                    isLoading={''}
                    isIcon={true}
                    iconClass={"fa-solid fa-star-of-life fa-sm"}
                    title={AppConstant.login.changePassword} className={"btn-highlighted "}
                    onClick={() => { setTab(AppConstant.settings.accountSettings.security) }}
                />
            </div>
        </div>
    )
}

export default UserInformationSS