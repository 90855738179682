import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDefaultDashboardSelector } from '../../../../redux/reducers/reducerSlices/DefaultDashboardReducer'
import { getScheduleUploadStatus } from '../../../../redux/reducers/reducerSlices/ScheduleUploadReducer'
import { sagaActions } from '../../../../sagas/sagaActions'
import {  getFormattedDate, YYYY_MM_DD_FORMAT } from '../../../../Utils/DateUtils'
import { initiateSearch } from '../../../Assests/Utility'
import DrilldownTable from '../../LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable'
import './Detailed.css'
//
/**
 * Component is used to render schedule upload warehouse wise data
 * @param {*} props 
 * @returns 
 */
const Detailed = (props) => {
    const { themeSelected, searchStr } = props
    const dispatch = useDispatch()
    const { dateRangeObj } = useSelector(getDefaultDashboardSelector)
    const { scheduleUploadData, arrangeOrder, arrangeKey, loading } = useSelector(getScheduleUploadStatus)
    const [scheduleUploadStatus, setScheduleUploadStatus] = useState([])

    /**
    * Useeffect is used to get schedule upload warehouse wise data on date change
    */
    useEffect(() => {
        if (dateRangeObj) {
            getScheduleUploadData()
        }
    }, [dateRangeObj])

    /**
    * Useeffect is used to get schedule upload warehouse wise data on date change
    */
    useEffect(() => {
        if (scheduleUploadData) {
            setScheduleUploadStatus(scheduleUploadData)
        }
    }, [scheduleUploadData])


    /**
     * Useeffect is used to set data based on search
     */
    useEffect(() => {
        if (searchStr.length > 1) {
            const searchedArr = initiateSearch(scheduleUploadStatus, searchStr, 'Warehouse');
            setScheduleUploadStatus(searchedArr || []);
        } else if (searchStr === '') {
            setScheduleUploadStatus(scheduleUploadData)
        }
    }, [searchStr])


    /**
     * Method is used to get schedule upload warehouse wise data
     */
    const getScheduleUploadData = () => {
        dispatch({
            type: sagaActions.GET_SCHEDULE_UPLOAD_STATUS, payload: {
                "type": "detailed",
                "fromDate": getFormattedDate(YYYY_MM_DD_FORMAT, dateRangeObj?.start),
                "toDate": getFormattedDate(YYYY_MM_DD_FORMAT, dateRangeObj?.end || dateRangeObj?.start),
            }
        })
    }

    /**
     * Method is used to form drilldown report data

     * @param {*} data 
     * @returns 
     */
    const getformatListDataDrillDown = () => {
        return scheduleUploadStatus.map((el, index) => {
            return {
                id: el.IDWarehouse,
                0: index + 1,
                1: el.Warehouse,
                2: el.CompletedCount,
                3: el.FailedCount,
                4: el.ProcessingCount,
                5: '-',
                6: '-',
                7: '-',

            }
        })
    }

    const headerData = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'Warehouse', name: 'Warehouse Name' },
        { id: 'CompletedCount', name: 'Completed' },
        { id: 'FailedCount', name: 'Failed' },
        { id: 'ProcessingCount', name: 'Processing' },
        { id: 'YettoStart', name: 'Yet to start' },
        { id: 'ProcesssType', name: 'Process Type' },
        { id: 'lastsync', name: 'Last Synced' }

    ]

    return <div className='scheduleUploadListContainer'>
        <DrilldownTable
            themeSelected={themeSelected}
            bodyData={getformatListDataDrillDown()}
            titleData={headerData}
            showCollapse={false}
            showInput={false}
            showSort={true}
            hasCollapsableContent={false}
            collapsableData={[]}
            accordionPrimaryIndex={1}
            drillDownReport={scheduleUploadStatus}
            initiateDrillDownReport={loading}
            loaderArray={8}
            sortingAction={sagaActions.SORT_SCHEDULE_UPLOAD_DATA}
            showUTCTime={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            subRoot={{ pathName: 'scheduleUploadObj' }}
        />
    </div>
}

export default Detailed