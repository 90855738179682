import React, { useEffect, useState } from 'react'
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import SSAppointmentHistory from '../AppointmentHistory/SSAppointmentHistory';
import moment from 'moment';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
/**
 * This is a wrapper compoment which provide option to select Recommendation or Manual Override
 * @param {*} props 
 * @returns 
 */
function ManualOverride(props) {
    const { handleOptionChangeParent,apptHistory, rowData, isCarrier } = props;
    const [selectedOption, setSelectedOption] = useState('R');
    /**
     * Handler function for storing Recommendation or Manual Override based on user selection
     * @param {*} option 
     */
    const handleOptionChange = (option) => {
      setSelectedOption(option);
  };
  
  const getDisclaimerText = () => {
    const startDate = moment(rowData[0]?.AppointmentBookStartTime).utc();
    const endDate = moment(rowData[0]?.AppointmentBookEndTime).utc();
    return `As per the Appointment lead time, your recommended Appointment booking window is from  ${moment(startDate).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat_with_Space)} to ${moment(endDate).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat_with_Space)}.`
  }

    return (
      <div>
        <SSAppointmentHistory data={apptHistory}/>
     
      <div className="d-flex justify-content-between ">
        
        <div className="container mt-4">
          <div className="d-flex justify-content-around gap-5 manual-override-ss">
            <div className='d-flex flex-column justify-content-start align-items-start'>
              <button className={selectedOption === 'MO' ? "btn-danger my-2" : "btn-unstyled btn-outlined my-2 px-3"} onClick={() => handleOptionChange('MO')}>
                {selectedOption === 'MO' && <i className="fa-solid fa-check"></i>} Manual Override
              </button>
              <span>Create appointment outside of shift hours or exceeding shift capacity. Override administration rules</span>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-start'>
              <button className={selectedOption === 'R' ? "btn-styled my-2" : "btn-unstyled btn-outlined my-2"} onClick={() => handleOptionChange('R')}>
                  {selectedOption === 'R' && <i className="fa-solid fa-check"></i>}{ AppConstant.commonStrings.recommendations} 
              </button>
              <span>Utilize appointments available within existing calendar parameters</span>
              </div>
          </div>
          <div className="mt-3">

            <CustomButtonSS
              type="submit"
              isLoading={false}
              className={"btn-highlighted f-14 ms-auto "}
              isIcon={false}
              iconClass=''
              onClick={() => handleOptionChangeParent(selectedOption)}
              title={'Next'}></CustomButtonSS>
          </div>
          </div>
        </div>
        {!isCarrier() && rowData.length && rowData[0]?.AppointmentBookStartTime && rowData[0]?.AppointmentBookEndTime ? <div className='text-danger mt-4'>*{ getDisclaimerText()}</div> : ''}
      </div>
  );
}

export default ManualOverride   