import { Uchain } from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { getClientId, getPaymentResponse, resetPaytraceData, cancelSubscriptionData, updateMySubscriptionListData, updateOperationObj, updatePaymentLoading, updateCardObj } from '../redux/reducers/reducerSlices/PaytraceReducer';
import { showAlert } from "../App/Assests/Utility";
import { toast } from "react-toastify";
import { AppConstant } from "../App/Assests/AppConstant";

function* getPaytraceClientIdSaga() {
    try {
        const response = yield call(Uchain.getPaytraceClientId);
        yield put(getClientId(response.clientKey));
    } catch (error) {
        // yield put(addFailure(error));
    }
}

function* hitPaytraceApiSaga(data) {
    try {
        const response = yield call(Uchain.hitPaytraceApymentApi, data.payload);
        yield put(getPaymentResponse(response));
    } catch (error) {
        yield put(getPaymentResponse(error));
    }
}

function* resetPaytraceApiSaga() {
    try {
        yield put(resetPaytraceData());
    } catch (error) {
    }
}

function* cancelSubscriptionApiSaga(data) {
    try {
        const response = yield call(Uchain.cancelCarrierSubscriptionApi, data.payload);
        yield put(cancelSubscriptionData(response));
    } catch (error) {
    }
}

function* resetCancelSubscriptionSaga() {
    try {
        yield put(cancelSubscriptionData({}));
    } catch (error) {
    }
}

function* mySubscriptionsListSaga(data) {
    const tempStatusObj = { ...data.operationObj }
    try {
        const response = yield call(Uchain.mySubscriptionsApi, data.payload);
        tempStatusObj.status = true
        yield put(updateMySubscriptionListData(response));
        yield put(updateOperationObj(tempStatusObj));
    } catch (error) {
        tempStatusObj.message = error.response.data;
        yield put(updateOperationObj(tempStatusObj));
    }
}

function* updateCardDetailsSaga(data) {
    try {
        yield put(updatePaymentLoading(true))
        const response = yield call(Uchain.updateCardDetails, data.payload);
        yield put(updatePaymentLoading(false))
        if (response) {
            let data={
                CUID:response[0].customer_id,
                CardNumber:response[0].credit_card.masked_number
            }
            const res=yield call(Uchain.storeCardDetails,data)
            yield put(updateCardObj(response[0]))
            if (response) {
                showAlert(toast.TYPE.SUCCESS, AppConstant.smartScheduler.carrierSignUp.paytrace.updateCardSuccessText)
            }
            else {
                showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong)
            }
        }
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
    }
}

export function* getPaytraceClientIdWatcher() {
    yield takeEvery(sagaActions.GET_PAYTRACE_CLIENT_ID, getPaytraceClientIdSaga)
}

export function* hitPaytraceApiWatcher() {
    yield takeEvery(sagaActions.HIT_PAYTRACE_PAYMENT_API, hitPaytraceApiSaga)
}


export function* resetPaytraceApiWatcher() {
    yield takeEvery(sagaActions.RESET_PAYTRACE_DATA, resetPaytraceApiSaga)
}

export function* cancelSubscriptionApiWatcher() {
    yield takeEvery(sagaActions.CANCEL_SUBSCRIPTION_ACTION, cancelSubscriptionApiSaga)
}

export function* resetCancelSubscriptionWatcher() {
    yield takeEvery(sagaActions.RESET_CANCEL_SUBCRIPTION_OBJ, resetCancelSubscriptionSaga)
}

export function* mySubscriptionsListWatcher() {
    yield takeEvery(sagaActions.MY_SUBSCRIPTIONS_LIST_ACTION, mySubscriptionsListSaga)
}

export function* updateCardDetailsWatcher() {
    yield takeEvery(sagaActions.UPDATE_CARD_DETAILS, updateCardDetailsSaga)
}