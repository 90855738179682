import React from 'react';
import { getFormattedDate, getMonthFromDate, getPlainDate } from '../../../../../../../Utils/DateUtils'
import { AppConstant } from '../../../../../../Assests/AppConstant';
import ClockPast from '../../../../../../Assests/Images/clock_icon_past.png';
import ClockUpcoming from '../../../../../../Assests/Images/clock_icon_upcoming.png';
import LocationUpcoming from '../../../../../../Assests/Images/location_upcoming.png';
import LocationPast from '../../../../../../Assests/Images/location_past.png';
import CustomTooltip from '../../../../../Common/Tooltip/CustomTooltip';
import _ from 'lodash-es';
import './MyApptsTableRow.css';
import { Carousel } from '../../../../../Common/CustomCrousal/Carousel';

const MyApptsTableRow = (props) => {
    const { themeSelected, apptData, onApptClickHandler, isPast, actionsButtonsArr, onEmailClickHandler, onDeleteClickHandler } = props;
    const footerTextColor = () => {
        return isPast ? 'past' : 'upcoming'
    }


    const showApptList = () => {
        if (_.isArray(apptData) && _.size(apptData)) {
            return apptData.map((rowData, index) => {
                return (
                    <div className={`shadow bg-body rounded w-inherit`}>
                        <div className='p-0 d-flex flex-column justify-content-between h-100' key={index}>
                            <div className=' d-flex justify-content-start p-2'>
                                <div className='container-fluid m-0 p-0 d-flex justify-content-between mb-2'>
                                    <div className={`fw-bold dateTitle ${footerTextColor()}`}>
                                        <p className='m-0 p-0 dateBolder'>{getPlainDate(rowData.ApptStartTime)}<br />{getMonthFromDate(rowData.ApptStartTime)}</p>
                                    </div>
                                    <div className='d-flex justify-content-end px-2'>
                                        {
                                            !isPast ?
                                                <div style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => onApptClickHandler(rowData)}
                                                >
                                                    <span data-tip={("Reschedule Appointment")} data-for='tooltip_id'>
                                                        <i className="fas fa-calendar-alt fa-md"></i>
                                                        <CustomTooltip id='tooltip_id' position='bottom' type='light' multiline={true} html={true} />
                                                    </span>
                                                </div>
                                                :
                                                ''
                                        }
                                        {
                                            !isPast ?
                                                <div style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => onDeleteClickHandler(rowData)}
                                                >
                                                    <span data-tip={("Delete")} data-for='tooltip_id'>
                                                        <i className="fas fa-trash fa-md"></i>
                                                        <CustomTooltip id='tooltip_id' position='bottom' type='light' multiline={true} html={true} />
                                                    </span>
                                                </div>
                                                :
                                                ''
                                        }
                                        {
                                            <div style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => onEmailClickHandler(rowData)}>
                                                <span data-tip={"Email"} data-for='Email'>
                                                    <i className="fa fa-fw fa-envelope fa-md"></i>
                                                    <CustomTooltip id='Email' position='bottom' type='light' multiline={true} html={true} />
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='detailsTextCarrier m-0 px-2 f-16'>
                                <div className='firstLine f-16'><b>PO:</b>&nbsp;{rowData.PoNumber || "-"}</div>
                                <div><b>Warehouse:</b>&nbsp;{rowData.WarehouseData.Name}</div>
                                <div><b>Zip Code:</b>&nbsp;{rowData.ReceivingZipCode}</div>
                                <div><b>Vendor:</b>&nbsp;{rowData.VendorName}</div>
                                <div><b>Commodity:</b>&nbsp;{rowData.Commodity}</div>
                                <div><b>Buyer:</b>&nbsp;{rowData.BuyerName}</div>
                                <div><b>Dock:</b>&nbsp;{rowData.DockName}</div>
                                {/* <div>Timeslot : &nbsp; {rowData.Door}</div> */}
                                <div className='d-flex align-items-center mt-3 fw-600'>
                                    {
                                        footerTextColor() === 'past' ?
                                            <img src={ClockPast}
                                                className={`me-3 locClockIcon filter-black`}
                                                alt="Date & Time"
                                            />
                                            :
                                            <img src={ClockUpcoming}
                                                className={`me-3 locClockIcon filter-green`}
                                                alt="Date & Time"
                                            />

                                    }
                                    <div className={`rescheduledTimeText ${footerTextColor()}`}>
                                        {
                                            getFormattedDate(AppConstant.commonStrings.HH_mm_dateFormat, rowData.ApptStartTime)
                                        }
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mb-3 fw-600'>
                                    {
                                        footerTextColor() === 'past' ?
                                            <img src={LocationPast}
                                                className={`me-3 locClockIcon filter-black`}
                                                alt="Date & Time"
                                            />
                                            :
                                            <img src={LocationUpcoming}
                                                className={`me-3 locClockIcon filter-green`}
                                                alt="Date & Time"
                                            />

                                    }
                                    <div className={`rescheduledTimeText ${footerTextColor()}`}>{rowData.WarehouseData.Address} {rowData.WarehouseData.City} {rowData.WarehouseData.State}, {rowData.WarehouseData.Zip}</div>
                                </div>

                            </div>
                            {_.size(apptData) > 1 &&
                                <div className='subText textRed'>* Multi PO's are associated with this PO, Use navigation<br /> keys above to see other PO's</div>
                            }
                            <div className={`horizontalSeperator ${_.lowerCase(rowData.ApptScheStatus)}`} />
                        </div>
                    </div>
                )
            })
        }

    }

    return (
        <Carousel items={showApptList() || []} />
    )
}

export default MyApptsTableRow;