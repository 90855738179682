import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from 'react-calendar-timeline/lib'
// make sure you include the timeline stylesheet or the timeline will not be styled
import { forwardRef, useState, useEffect, useRef } from 'react'
import 'react-calendar-timeline/lib/Timeline.css'
import './Scheduler.css'
import _ from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { AppConstant } from '../../Assests/AppConstant'
import { CustomDynamicForm } from '../Common/CustomForm/CustomDynamicForm'
import Popup from '../Common/Popup/Popup'
import { YYYY_MM_DD_FORMAT, changeDateToGivenFormat, getCurrentDate, getFormattedDate } from '../../../Utils/DateUtils'
import Header from '../Header/Header'
import Navigationbar from '../Navigationbar/Navigationbar'
import SendEmail from "../sendEmail";
import { smartScheduleSelector } from '../../../redux/reducers/reducerSlices/SmartScheduleReducer'
import { sagaActions } from '../../../sagas/sagaActions'
import CustomTooltip from '../Common/Tooltip/CustomTooltip'
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer'
import { createWPMetaData, displayLoader, showAlert,customStylesLight } from '../../Assests/Utility'
import ViewAppointmentPopup from './Popups/ViewAppointmentPopup'
import AddMorePOPopup from './Popups/AddMorePOPopup'
import RecommenedSlots from './Popups/RecommendedSlots/RecommendedSlots'
import SchedulerHeaderBar from './Children/SchedulerHeaderBar'
import CarrierApptsListScreen from '../LandingPage/SmartScheduler/BookAppointments/Components/ShowApptsList/CarrierApptsListScreen';

const Scheduler = (props) => {
  const { themeSelected } = props;
  const dispatch = useDispatch();
  const ss = useSelector(smartScheduleSelector);
  const { poForAppt, appointmentList, wpSchedulerData, wpCapacityData, wpLeaveData, wpBreakData, initateLoading, searchedPOData, carrierList, validationType } = ss;
  const user = useSelector(getUserSelector);
  const { cu_id, userAccountDetails, userType, selectedWarehouse } = user;
  const [items, setItems] = useState([]);
  const [masterGroups, setMasterGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [allDock, setAllDock] = useState([]);
  const [canvasData, setCanvasData] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bookApptModelData, setBookApptModelData] = useState({});
  const [resetFormData, setResetFormData] = useState(0);
  const [particularFieldValue, setParticularFieldValue] = useState("");
  const [currentCalendarType, setCurrentCalendarType] = useState('today');
  const [startDateState, setStartDateState] = useState(changeDateToGivenFormat(getCurrentDate(), AppConstant.commonStrings.MM_DD_YYYY_dateFormat));
  const [endDateState, setEndDateState] = useState(changeDateToGivenFormat(getCurrentDate(),
    AppConstant.commonStrings.MM_DD_YYYY_dateFormat));
  const currentDate = moment().startOf('day');
  const [visibleTimeStart, setVisibleTimeStart] = useState(currentDate.clone().hour(0));
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(currentDate.clone().hour(23).minute(59));
  const [startTimeState, setStartTimeState] = useState('');
  const [endTimeState, setEndTimeState] = useState('');
  const [shouldRender, setShouldRender] = useState(false);
  const [viewPoDetails, setViewPoDetails] = useState({});
  const [showAddPoModel, setShowAddPoModel] = useState(false);
  const [canvasTime, setCanvasTime] = useState('');
  const [bookApptModelTitle, setBookApptModelTitle] = useState('Book Appointment')
  const [showViewAppointmentModal, setShowViewAppointmentModal] = useState(false);
  const [showSearchPoModel, setShowSearchPoModel] = useState(false);
  const [posSearchList, setPoSearchList] = useState(null)
  const [carrierListState, setCarrierListState] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [holidayList, setHolidayList] = useState(null)
  const [showDeletePoApptModel, setShowDeletePoApptModel] = useState(false);
  const [datePickerSelectedDate, setDatePickerSelectedDate] = useState(getCurrentDate());
  const [wpCapacityDataState, setWPCapacityDataState] = useState(null);
  const [showValidationPopup, setShowValidationPopup] = useState(false);
  const [validationPopupText, setValidationPopupText] = useState('');
  const [disclaimersArray, setDisclaimersArray] = useState([]);

  const [showSearchError, setShowSearchError] = useState(false);
  const [leaveList, setLeaveList] = useState([]);


  useEffect(() => {
    setItems([])
    setPoSearchList(null)
    closeSearchPOForm()
    dispatch({type: sagaActions.CLEAR_SS_SEARCH_PO})
    dispatch({ type: sagaActions.CLEAR_APPOINTMENT })
    dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [] })
    // setStartDateState(visibleTimeStart)
    // setEndDateState(visibleTimeEnd)
    if (selectedWarehouse?.ID) {
      dispatch({ type: sagaActions.GET_SCHEDULER_DATA, payload: { IDWarehouse: selectedWarehouse?.ID } })

      const wpCapacityData = createWPMetaData('wpCapacityData', 'S', { IDWarehouse: selectedWarehouse?.ID })
      const wpBreakData = createWPMetaData('wpBreakData', 'S', { IDWarehouse: selectedWarehouse?.ID })
      const wpLeaveData = createWPMetaData('wpLeaveData', 'S', { IDWarehouse: selectedWarehouse?.ID })

      dispatch({ type: sagaActions.INITIATE_WAREHOUSE_METADATA, payload: { payload: wpCapacityData, key: 'wpCapacityData' } })
      dispatch({ type: sagaActions.INITIATE_WAREHOUSE_METADATA, payload: { payload: wpBreakData, key: 'wpBreakData' } })
      dispatch({ type: sagaActions.INITIATE_WAREHOUSE_METADATA, payload: { payload: wpLeaveData, key: 'wpLeaveData' } })
      
    }
  }, [])

  useEffect(() => {
    // setItems([])
    setPoSearchList(null)
    closeSearchPOForm()
    dispatch({ type: sagaActions.CLEAR_APPOINTMENT })
    if (_.size(poForAppt)) {
      const masterPO = poForAppt[0]
      // When Selecting PO for Appointment we need to check whether it is New or  Reschedule Appointment
      if (masterPO.ApptScheStatus === 'NotScheduled') {
        setBookApptModelTitle('Book Appointment')
      } else if (masterPO.ApptScheStatus === 'Scheduled') {
        setBookApptModelTitle('Reschedule Appointment')
      }
      // dispatch({ type: sagaActions.GET_SCHEDULER_DATA, payload: { IDWarehouse: masterPO?.IDWarehouse } })

      // const wpCapacityData = createWPMetaData('wpCapacityData', 'S', { IDWarehouse: masterPO?.IDWarehouse })
      // const wpBreakData = createWPMetaData('wpBreakData', 'S', { IDWarehouse: masterPO?.IDWarehouse })
      // const wpLeaveData = createWPMetaData('wpLeaveData', 'S', { IDWarehouse: masterPO?.IDWarehouse })

      // dispatch({ type: sagaActions.INITIATE_WAREHOUSE_METADATA, payload: { payload: wpCapacityData, key: 'wpCapacityData' } })
      // dispatch({ type: sagaActions.INITIATE_WAREHOUSE_METADATA, payload: { payload: wpBreakData, key: 'wpBreakData' } })
      // dispatch({ type: sagaActions.INITIATE_WAREHOUSE_METADATA, payload: { payload: wpLeaveData, key: 'wpLeaveData' } })

    }
  }, [poForAppt])

  useEffect(() => {
    if (_.size(masterGroups)) {
      setShouldRender(true);
    }
  }, [visibleTimeStart, visibleTimeEnd])
  
  useEffect(() => {
    if (_.size(carrierList)) {
      formCarrierListData()
    }
  }, [carrierList])

  useEffect(() => {
    if (_.size(searchedPOData)) {
      setShowSearchError(false);
      setPoSearchList([...searchedPOData])
    } else {
      setPoSearchList(null)
    }
  },[searchedPOData])

  useEffect(() => {
    if (_.size(poForAppt) && showModal) {
      if (bookApptModelTitle === 'Book Appointment') {
        // onCanvasClickHanlder(canvasData, canvasTime)
      } else if (bookApptModelTitle === 'Reschedule Appointment') {
        const { viewPO, itemId } = viewPoDetails;
        // onCanvasClickHanlder(itemId, viewPO?.startTime)y
        // onCanvasClickHanlder(itemId, new Date(viewPO?.ApptStartTime));
      }
      closeAddMorePOForm()
    }
  }, [poForAppt])

  useEffect(() => {
    if (_.size(wpSchedulerData)) {
      createSchedulerGroups()
    }
  }, [wpSchedulerData])

  useEffect(() => {
    if (_.size(appointmentList) || _.size(masterGroups)) {
      // form data from Appointment API to render in calendar
      // after deletimg PO if popup is open, we need to close it
      showViewAppointmentModal && closeViewAppointmentpopup()
      formCalendarFormatDataToRender(appointmentList)
      setLoading(false);
      setShowModal(false);
      setTimeout(() => {
        setShouldRender(true);
      }, 100)
    }
  }, [appointmentList])

  useEffect(() => {
    if (validationType === 'WP') {
      // DO WP LEVEL VALIDATION
      formHolidayData()
    } else if (validationType === 'DOCK') {
      // DO DOCK LEVEL VALIDATION
    }

  }, [wpLeaveData, validationType])

  useEffect(() => {
    if(_.size(wpCapacityData)){
      if (userType === 'USER') {
        let IDWarehouse = selectedWarehouse?.ID;
        const ifSameWPDataExists = _.find(wpCapacityData, { ID: IDWarehouse })
       
        if (ifSameWPDataExists) {
          setWPCapacityDataState(ifSameWPDataExists)
        } else {
          console.log('Either Warehouse data is not Present of there is mismatch in WP ID')
        }
      }
    }
  }, [wpCapacityData])
  const formLeaveData = (leaveArray) => {
    if (_.size(leaveArray)) {
      let hArray = [];
      let index = 1;
      leaveArray.forEach(obj => {
        const { LeaveStart, LeaveEnd, Status, Purpose, IDWarehouse, IDDock } = obj;  
        if (Status) {
          let mLeaveStart = moment(LeaveStart);
          let mLeaveEnd = moment(LeaveEnd);
          // Loop through each date between the start and end date
          while (mLeaveStart.isBefore(mLeaveEnd) || mLeaveStart.isSame(mLeaveEnd, 'day')) {
            hArray.push({ LeaveID: index, LeaveDate: mLeaveStart.clone(), Purpose, IDWarehouse, IDDock });
            mLeaveStart = mLeaveStart.add(1, 'day');
            index = index + 1;
          }
        }
      });
      return hArray;
    } else {
      console.log("No Holiday's Found")
    }
  }

  const formHolidayData = () => {
    if (_.size(wpLeaveData)) {
      let hArray = [];
      let index = 1;
      wpLeaveData.forEach(obj => {
        const { LeaveStart, LeaveEnd, Status, Purpose, IDWarehouse, IDDock } = obj;  
        if (Status) {
          let mLeaveStart = moment(LeaveStart);
          let mLeaveEnd = moment(LeaveEnd);
          // Loop through each date between the start and end date
          while (mLeaveStart.isBefore(mLeaveEnd) || mLeaveStart.isSame(mLeaveEnd, 'day')) {
            hArray.push({ LeaveID: index, LeaveDate: mLeaveStart.clone(), Purpose, IDWarehouse, IDDock });
            mLeaveStart = mLeaveStart.add(1, 'day');
            index = index + 1;
          }
        }
      });
      setHolidayList([...hArray])
    } else {
      console.log("No Holiday's Found")
    }
  }

  useEffect(() => {
  
    if (_.size(holidayList)) {
      // Need to Work
      const findIfExists = _.find(disclaimersArray, { type: 'HOLDIDAY' })
      if (!findIfExists) {
        let text = 'Warehouse is closed on '
        let allDates = ''
        holidayList.forEach(el => {
          allDates = allDates + ' ' + moment(el.LeaveDate).utc().format('MM/DD/YYYY')
        })
        text = text + allDates
        const temp = [{
          type: 'HOLDIDAY',
          text: text
        }, ...disclaimersArray];

        setDisclaimersArray([...temp])
      }
    }
    if (!_.isEmpty(wpCapacityDataState)) {
      const { WHStartTime, WHEndTime, ID } = wpCapacityDataState;
  
      const sTime = moment(WHStartTime).format('HH:mm A');
      const eTime = moment(WHEndTime).format('HH:mm A');
      const text = `This warehouse opens at ${sTime} and closes at ${eTime}`;
      const findIfExists = _.find(disclaimersArray, { type: 'WP', ID })
     
      if (!findIfExists) {
    
        setDisclaimersArray([...disclaimersArray, {
          ID:ID,
          type: 'WP',
          text: text
        }])
      }
    }
  },[holidayList, wpCapacityDataState])

  const formCalendarFormatDataToRender = (data) => {
    const formattingData = data.map((el, i) => {
      const filteredData = _.find(groups, { id: el.IDDock + '_' + el.Door });
      if (filteredData) {
      return {
        id:filteredData.id + '_' + el.PoNumber,
        group: filteredData.id,
        title: el.PoNumber,
        start_time: moment(el.ApptStartTime),
        end_time: moment(el.ApptEndTime)
        }
      }
      else {
        return
      }
    })
    const compactArray =  _.compact(formattingData)
    setItems([...compactArray])
    return;
  }
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="customDatePickerInput" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const TUSDatePickerInput = forwardRef(({ value, onClick }, ref) => {
    let dateStr = 'HH:MM';
    if (!value.includes('mm')) {
      const getHour = ("0" + new Date(value).getHours()).slice(-2);
      const getMin = ("0" + new Date(value).getMinutes()).slice(-2);
      dateStr = getHour + ":" + getMin
    }

    return (
      <button className="customDatePickerInput" onClick={onClick} ref={ref}>
        {dateStr}
      </button>
    )
  })

  const getFormFieldData = (data, name, control,inputs) => {   
   
    if ( name === 'start_date') {
        recalculateApptDurationHandler(canvasData,new Date(changeDateToGivenFormat(data, AppConstant.commonStrings.MM_DD_YYYY_dateFormat) +" "+ moment(inputs?.start_time).format("HH:mm")) )
     }
    if ( name === 'start_time') {
   
       recalculateApptDurationHandler(canvasData,new Date(changeDateToGivenFormat(inputs.start_date, AppConstant.commonStrings.MM_DD_YYYY_dateFormat) +" "+ moment(data).format("HH:mm")) )
    }
  };

  const openAddMorePOForm = () => {
    setShowAddPoModel(true);
  }
  const closeAddMorePOForm = () => {
    // setEditData({});
    setShowAddPoModel(false);
  }
  const openAddMorePO = () => {
    // if (_.size(poForAppt)) {
      const masterPO = poForAppt[0]
      return <AddMorePOPopup closeSendMailpopup={closeAddMorePOForm} zipCode={selectedWarehouse?.Zip} actionHandler={(data, type) => { onAddRemoveHandler(data, type) }} initateLoading={initateLoading} poForAppt={poForAppt} />
    // } 
  }

  const [showSendMailModal, setShowSendEmailModal] = useState(false);
  const openSendEmailForm = () => {
    setShowSendEmailModal(true);
  }
  const closeSendMailpopup = () => {
    // setEditData({});
    setShowSendEmailModal(false);
  }
  const openSendEmail = () => {
    return <SendEmail closeSendMailpopup={closeSendMailpopup} formFieldList={formFieldListViewAppointment} poDetails={viewPoDetails}></SendEmail>
  }
  const showSendEmailPopup = () => {
    const popupObj = {
      id: 'createAppointment',
      modalLabel: 'sendEmail',
      showClose: true,
      showfooter: false,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: 'md',
      title: 'Send Email',
      bodyText: openSendEmail(),
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: true,
          text: AppConstant.comments.cancelCommentButtonTitle
        },
        btn2: {
          show: true,
          text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
        }
      },
    }

    return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeSendMailpopup} themeSelected={themeSelected} showModal={showSendMailModal} />
  }
  const openCreateForm = (groupId) => {
    
    let disclaimerData=[...disclaimersArray];
    if(wpSchedulerData.length>0){
    let dockData   = _.find(wpSchedulerData, { dockId: groupId.dockId });
     const sTime = moment(dockData.DockStartTime).format('HH:mm A');
      const eTime = moment(dockData.DockEndTime).format('HH:mm A');
      const text = `This Dock opens at ${sTime} and closes at ${eTime}`;
     let dockTimeData=  _.find(disclaimerData, { type: 'DOCK-TIME' })
     if(dockTimeData){
       dockTimeData.ID=dockData.dockId;
       dockTimeData.text=text;
      
     }
     else{
      disclaimerData.push( {
        ID:dockData.dockId,
        type: 'DOCK-TIME',
        text: text
      });
   
     }

     let findIfExists = _.find(disclaimerData, { type: 'Dock-Leave' });
     if(dockData.DockLeaveDetails.length>0){
    let leaveList=formLeaveData(dockData.DockLeaveDetails);
    setLeaveList(leaveList);
  let textDock = 'This Dock is closed on '
    let allDates = ' '
    leaveList.forEach(el => {
      allDates = allDates  + moment(el.LeaveDate).utc().format('MM/DD/YYYY')+ ','
    })
    textDock = textDock + allDates
    if (!findIfExists) {
      disclaimerData.push({
        type: 'Dock-Leave',
        text: textDock
      });
   
    }   else{
      findIfExists.text=textDock;
     
    }
  }  else{
      if(findIfExists){
        findIfExists.text=null; 
      }
    }
    setDisclaimersArray([...disclaimerData])
         setShowModal(true)
       }
       else{
         alert("wait till data load")
       }

    // setParticularFieldValue(...particularFieldValue,{po_number: poForAppt?.PoNumber})
  }

  const closeCurrentpopup = () => {
    // setEditData({});
    setSelectedCarrier(null)
    setShowModal(false);
  }

  const formCarrierListData = () => {
    if (_.size(carrierList)) {
      const carrierData = carrierList.map(el => {
        return {
          value: el.carrier_id,
          label: el.carrier_name
        }
      })
      setCarrierListState([...carrierData])
    } else {
      setCarrierListState([])
    }
    
  }
  
  const onCarrierSelcted = (props) => {
    if (props.length > 2) {
      dispatch({ type: sagaActions.SS_GET_CARRIER_LIST, payload: { carrier: props } })
    }
}

const onChangeValue = (props) => {
 setSelectedCarrier(props)
  
}

const optionsFunc = (test) => {
}

const formatCreateLabel = (props) => {
}

  const formFieldList = [
    {
      name: "po_number",
      type: "text",
      size: "col-sm-6",
      position: 1,
      title:
        AppConstant.poManagement.tableHeader.po,
      formValidation: {
        required: true,
        // addIcon: true,
        // iconHandler: openAddMorePOForm
      },
      disabledField: false,
    },
    {
      name: "ReceivingZipCode",
      type: "text",
      size: "col-sm-6",
      position: 2,
      title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip,
      formValidation: {
        required: true,
        lengthValidation: {
          customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zipCodeErrorMessage + '. '
        },
      },
      disabledField: true,
    },
    {
      name: "carrier_name",
      type: "select_with_input_search_add_button",
      size: "col-sm-6 mb-3 fs-6",
      parentClass: 'inputTitle',
      componentClassName: 'mt-1 inputDiv border border-1 rounded-1 border-dark',
      inputTitle: 'Select Carrier',
      position: 3,
      title: AppConstant.reports.scheduledLoadStatus.tableHeaders.carrierName,
      customStylesLight: customStylesLight,
      placeholderText: 'Select a carrier from the list',
      onInputChange: onCarrierSelcted,
     // onChange: onChangeValue,
      defaultValue: !_.isEmpty(selectedCarrier) ? selectedCarrier:  'Select a carrier from the list',
      isLoading: false,
      isClearable: true,
      isSearchable: true,
      options: carrierListState,
      formatCreateLabel: formatCreateLabel,
      loadingMessage: 'Please wait loading....',
      formValidation: {
          required: true,
      },
  },
     // Requested by Rajest to comment
    // {
    //   name: "WarehouseName",
    //   type: "text",
    //   size: "col-sm-6",
    //   position: 3,
    //   title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.warehouseNameTitleStr,
    //   formValidation: {
    //     required: false,
    //   },
    //   disabledField: true
    // },
    // {
    //   name: "WarehousePPH",
    //   type: "text",
    //   size: "col-sm-6",
    //   position: 4,
    //   title: AppConstant.reports.truckUnloadSummaryReport.warehouse + ' ' + AppConstant.smartScheduler.dockCapacity.tableHeaders.palletsPerHour,
    //   formValidation: {
    //     required: false,
    //   },
    //   disabledField: true
    // },
    {
      name: "dock",
      type: "text",
      size: "col-sm-6",
      position: 4,
      title: AppConstant.poManagement.tableHeader.dock,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    // {
    //   name: "DockPPH",
    //   type: "text",
    //   size: "col-sm-6",
    //   position: 6,
    //   title: AppConstant.reports.adfatReport.dropdownLabels.dock + ' ' + AppConstant.smartScheduler.dockCapacity.tableHeaders.palletsPerHour,
    //   formValidation: {
    //     required: false,
    //   },
    //   disabledField: true
    // },
    {
      size: "col-sm-6 mt-1",
      name: "start_date",
      type: "custom-date-picker",
      customDatePickerConfig: {
        calendarType: currentCalendarType,
        placeholder: '',
        selectedDate: startDateState,
        startDate: getCurrentDate(),
        maxDate:"NA",
        name: "start_date",
        customInput: <ExampleCustomInput />,
        showTimeSelect: false
      },
      position: 5,
      value: startDateState,
      title:
        AppConstant.reports.adfatReport.dropdownLabels.startDate,
      formValidation: {
        required: true,
      }
    },
    {
      size: "col-sm-6 mt-1",
      name: "start_time",
      type: "custom-time-picker",
      customDatePickerConfig: {
        selectedDate: startTimeState,
        name: "start_time",
        showTimeSelect: true,
        showTimeSelectOnly: true,
        timeIntervals: 30,
        timeCaption: "Time",
        customInput: <TUSDatePickerInput value={startTimeState} />,
      },
      position: 6,
      value: startTimeState,
      title: AppConstant.smartScheduler.breaksForm.tableHeaders.startTime,
      formValidation: {
        required: true,
      }
    },
    // Requested by Rajest to comment
    // {
    //   size: "col-sm-6 mt-1",
    //   name: "end_date",
    //   type: "custom-date-picker",
    //   customDatePickerConfig: {
    //     calendarType: currentCalendarType,
    //     placeholder: '',
    //     selectedDate: endDateState,
    //     startDate: endDateState,
    //     name: "end_date",
    //     customInput: <ExampleCustomInput />,
    //     disableCalendar: startDateState ? false : true,
    //     showTimeSelect: false
    //   },
    //   position: 7,
    //   value: endDateState,
    //   title:
    //     AppConstant.reports.adfatReport.dropdownLabels.endDate,
    //   formValidation: {
    //     required: true,
    //   }
    // },
    // {
    //   size: "col-sm-6 mt-1",
    //   name: "end_time",
    //   type: "custom-time-picker",
    //   customDatePickerConfig: {
    //     placeholder: 'HH:MM',
    //     selectedDate: endTimeState,
    //     startDate: endTimeState,
    //     name: "end_time",
    //     showTimeSelect: true,
    //     showTimeSelectOnly: true,
    //     timeIntervals: 30,
    //     timeCaption: "Time",
    //     minTime: startTimeState,
    //     customInput: <TUSDatePickerInput value={endTimeState} />,
    //   },
    //   position: 8,
    //   value: endTimeState,
    //   title: AppConstant.smartScheduler.breaksForm.tableHeaders.endTime,
    //   formValidation: {
    //     required: true,
    //   }
    // }
  ]

  const cancelbtndata = {
    show: true,
    text: AppConstant.comments.resetbutton,
  };

  const submitbtnData = {
    show: true,
    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
      .saveButtonTitle,
  };

  const searchbtnData = {
    show: true,
    text: 'Search',
  };


  const getSummitedFormData = (data) => {
    setLoading(true);
    let allPOs = '';
    let payload_door = '';
    let payload_IDDock = '';
    let CRUDTYPE = '';
    let masterPO = poForAppt[0];
    let IDWarehouse = masterPO?.IDWarehouse
    let ReceivingZipCode = masterPO?.ReceivingZipCode
    if (bookApptModelTitle === 'Book Appointment') {
      CRUDTYPE = 'I'
      // while fresh appointment booking
      payload_door = canvasData.split('_')[1];
      payload_IDDock = canvasData.split('_')[0]
      if (_.size(poForAppt)) {
        masterPO = poForAppt[0];
        poForAppt.forEach(el => {
          allPOs = allPOs + el.PoNumber + ','
          return;
        })
      }
    } else if (bookApptModelTitle === 'Reschedule Appointment') {
      CRUDTYPE = "R"
      // while rescheduling appointment
      const { itemId, viewPO } = viewPoDetails    
      allPOs = data.po_number;
      payload_door = itemId.split('_')[1];
      payload_IDDock = itemId.split('_')[0]
      if (!allPOs.includes(',')) {
        allPOs = allPOs + ','
      }

      IDWarehouse = viewPO?.IDWarehouse
      ReceivingZipCode = viewPO?.ReceivingZipCode
    }
    
   const newItemPayload = {
      PoNumber: allPOs,
      IDWarehouse,
      ReceivingZipCode,
      ApptStartDate: moment(data?.start_date),
      ApptStartTime: moment(data?.start_time),
      ApptEndDate: moment(endDateState),
      ApptEndTime: moment(endTimeState),
      ApptStatus: 1,
      Door: payload_door,
      ApptCreatedBy: cu_id,
      IDDock: payload_IDDock,
      IDCarrier: data?.carrier_name,
      CRUDTYPE: CRUDTYPE,
     DeletedPos: '',
    }
    
    dispatch({ type: sagaActions.ADD_APPOINTMENT, payload: newItemPayload })
  };

  const createForm = () => {
    return (
      <CustomDynamicForm
        formFieldList={formFieldList}
        sort={true}
        submitbtn={submitbtnData}
        themeSelected={themeSelected}
        getFormFieldData={getFormFieldData}
        getSummitedFormData={getSummitedFormData}
        // editFormData={editData}
        loading={loading}
        resetFormData={resetFormData}
        setParticularFieldValue={particularFieldValue}
        disclaimer={true}
        disclaimersArray={disclaimersArray}
      />
    );
  };

  const showBookAppointmentPopup = () => {
    const popupObj = {
      id: 'createAppointment',
      modalLabel: 'createAppointment',
      showClose: true,
      showfooter: false,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: 'lg',
      title: bookApptModelTitle,
      bodyText: createForm(),
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: true,
          text: AppConstant.comments.cancelCommentButtonTitle
        },
        btn2: {
          show: true,
          text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
        }
      },
    }

    return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
  }

  const formFieldListViewAppointment = [
    {

      type: "string",
      size: "col-sm-12",
      position: 1,
      name: "Po Number",
      value: viewPoDetails?.viewPO?.PoNumber,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 2,
      name: "Dock",
      value: viewPoDetails?.viewPO?.DockName,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 3,
      name: "Commodity",
      value: viewPoDetails?.viewPO?.Commodity,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 4,
      name: "Buyer",
      value: viewPoDetails?.viewPO?.BuyerName,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 5,
      name: "Vendor",
      value: viewPoDetails?.viewPO?.VendorName,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 6,
      name: "Quantity",
      value: viewPoDetails?.viewPO?.Quantity,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 7,
      name: "Cases",
      value: viewPoDetails?.viewPO?.ItemsCases,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 8,
      name: "Pallets",
      value: viewPoDetails?.viewPO?.Pallets,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 9,
      name: "Weight",
      value: viewPoDetails?.viewPO?.Weight,
      formValidation: {
        required: true,
      },
      disabledField: true
    },{

      type: "string",
      size: "col-sm-12",
      position: 10,
      name: "Warehouse",
      value: viewPoDetails?.viewPO?.WarehouseData?.Name,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 10,
      name: "ZipCode",
      value: viewPoDetails?.viewPO?.ReceivingZipCode,
      formValidation: {
        required: true,
      },
      disabledField: true
    },
    {

      type: "string",
      size: "col-sm-12",
      position: 11,
      name: "Appointment Start",
      value: moment(viewPoDetails?.viewPO?.ApptStartTime).utcOffset(0, false).format('MM/DD/YYYY HH:mm A'),
      formValidation: {
        required: true,
      },
      disabledField: true
    },
     // Requested by Rajest to comment
    // {
    //   type: "string",
    //   size: "col-sm-12",
    //   position: 12,
    //   name: "Appointment End",
    //   value: moment(viewPoDetails?.viewPO?.endTime).format('DD/MM/YYYY HH:mm A'),
    //   formValidation: {
    //     required: true,
    //   },
    //   disabledField: true
    // },
    {

      type: "string",
      size: "col-sm-12",
      position: 13,
      name: "Due Date",
      value: moment(viewPoDetails?.viewPO?.DueDate).utcOffset(0, false).format('MM/DD/YYYY HH:mm A'),
      formValidation: {
        required: true,
      },
      disabledField: true
    }
  ]


  const viewAppointmentPopupBtns = [
    {
      show: true,
      text: 'Delete',
      handler: (e, type) => popupBtnHandler(e, type)
    },
    {
      show: true,
      text: 'Reschedule',
      handler: (e, type) => popupBtnHandler(e, type)
    },
    {
      show: true,
      text: 'Email Appointment',
      handler: (e, type) => popupBtnHandler(e, type)
    }
  ]

  const openViewAppointmentForm = () => {
    setShowViewAppointmentModal(true);
  }
  const closeViewAppointmentpopup = () => {
    // setEditData({});
    setShowViewAppointmentModal(false);
  }
  const openViewAppointmentPopup = () => {
    return <ViewAppointmentPopup closeSendMailpopup={closeViewAppointmentpopup} formFieldList={formFieldListViewAppointment} footerBtn={viewAppointmentPopupBtns} initateLoading={initateLoading} />
  }

  const popupBtnHandler = (e, type) => {
    const { viewPO, itemId } = viewPoDetails   
    switch (type) {
      case 'Reschedule':
        setBookApptModelTitle('Reschedule Appointment')
        closeViewAppointmentpopup();
            
        const filterDockData = _.find(wpSchedulerData, { dockId: itemId.split('_')[0]});
        
        setStartDateState(changeDateToGivenFormat(viewPO?.ApptStartDate, AppConstant.commonStrings.MM_DD_YYYY_dateFormat))
        setEndDateState(changeDateToGivenFormat(viewPO?.ApptEndDate, AppConstant.commonStrings.MM_DD_YYYY_dateFormat))
        setStartTimeState(moment(viewPO?.ApptStartTime))
        setEndTimeState(moment(viewPO?.ApptEndTime))
        setCanvasData(viewPO?.IDDock + '_' + viewPO?.Door );
        setSelectedCarrier({
          label: viewPO?.CarrierName,
          value:  viewPO?.IDCarrier
        })
        
        
         setParticularFieldValue({ carrier_name:viewPO?.IDCarrier,  dock: viewPO?.DockName, po_number: viewPO?.PoNumber, start_date: viewPO?.ApptStartDate, end_date: viewPO?.ApptEndDate, ReceivingZipCode: viewPO?.ReceivingZipCode, WarehouseName: viewPO?.WarehouseData?.Name || '-', start_time: viewPO?.ApptStartTime, end_time: viewPO?.ApptEndTime, WarehousePPH: wpCapacityData[0].PPH || 0, DockPPH: filterDockData?.PPH })
      
        // setParticularFieldValue({ dock: viewPO?.poDetails?.dockName, po_number: viewPO?.poDetails?.poNumber, start_date: viewPO?.startDate, end_date: viewPO?.endDate, ReceivingZipCode: viewPO?.poDetails?.zipCode, WarehouseName: fieldData?.WarehouseName || '-', start_time: viewPO?.startTime, end_time: viewPO?.endTime, WarehousePPH: wpCapacityData[0].PPH || 0, DockPPH: filterDockData?.PPH })
        openCreateForm(filterDockData)
        break;
      case 'Delete':
        setShowDeletePoApptModel(true);
        break;
      case 'Email Appointment':
        openSendEmailForm();
        break;

      default:
        break;
    }
  }


  const showAppointmentDetails = () => {
    const popupObj = {
      id: 'viewAppointment',
      modalLabel: 'viewAppointment',
      showClose: true,
      showfooter: false,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: 'md',
      title: 'Appointment Details',
      bodyText: openViewAppointmentPopup(),
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: false,
          text: AppConstant.comments.cancelCommentButtonTitle
        },
        btn2: {
          show: false,
          text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
        }
      },
    }

    return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeViewAppointmentpopup} themeSelected={themeSelected} showModal={showViewAppointmentModal} />
  }


  const calculteEndTimePPH = (wpPPH, dockPPH) => {
    let totalPallets = 0;
    // poForAppt depends on Book Appointment or
   
    if (bookApptModelTitle === 'Book Appointment') {
      poForAppt.forEach(el => {
        totalPallets = totalPallets + parseFloat(el?.Pallets)
        return
      })
    }
    else if (bookApptModelTitle === 'Reschedule Appointment') {
      totalPallets = parseFloat(viewPoDetails?.viewPO?.Pallets)

    }
  
    let calcPPHhour = '';
    let calcPPHmin = ''
    if (dockPPH) {
      calcPPHhour = totalPallets / dockPPH;
      calcPPHmin = totalPallets % dockPPH
    } else {
      calcPPHhour = totalPallets / wpPPH;
      calcPPHmin = totalPallets % wpPPH
    }
    return {
      calcPPHhour: calcPPHhour,
      calcPPHmin: calcPPHmin
    }
  }
  const onItemClickHandler = (itemId, e, time) => {
    e.stopPropagation()
    let foundObject = _.find(appointmentList, {PoNumber: itemId.split('_')[2]})
    setViewPoDetails({ itemId: itemId, viewPO: foundObject  })
    openViewAppointmentForm()
  }
  
  const dateHeaderRenderer = ({ headerContext, width, height, style }) => {
    const { intervalText } = headerContext;
  
    const isHoliday = holidayList.some((holiday) =>
      intervalText.isSame(holiday, "day")
    );
  
    // const isWeekend = weekends.includes(intervalText.day());
  
    const backgroundColor = isHoliday ? "red" : true ? "yellow" : "white";
  
    return (
      <div style={{ ...style, backgroundColor }} className="custom-header">
        {intervalText.format("ddd")}
      </div>
    );
  };
  
  const holidayValidationHandler = (IDDock, leaveDate) => {
    // Check validationType if WP or DOCK
    const mLeaveDate = moment(leaveDate).format(YYYY_MM_DD_FORMAT)
    if (validationType === 'WP') {
      const allLeaves = holidayList.map(el => moment(el.LeaveDate).format(YYYY_MM_DD_FORMAT))
      if (allLeaves.includes(mLeaveDate)) {
        return {
          status: true,
          message: `Please choose a different date for your appointment as selected date is a designated holiday and booking is not available.`
        };
      } else {
        return {
          status: false,
          message: ''
        };
      }
    }else if (validationType === 'DOCK') {
      alert('Functionality needs to be developed')
      return {
        status: false,
        message: ''
      };
    }
  }

  const WPCapacityValidationHandler = (IDDock, startTime) => {
    // Check validationType if WP or DOCK
    if (validationType === 'WP') {
      const { WHStartTime, WHEndTime } = wpCapacityDataState;
      const dTime = startTime.format('HH:mm:ss');
     
      const sTime = moment(WHStartTime).utc().format('HH:mm:ss');
      const eTime = moment(WHEndTime).utc().format('HH:mm:ss');
      const isTimeBetween = dTime >= sTime && dTime <= eTime;
     
      if (isTimeBetween) {
        return {
          status: false,
          message: ''
        };
      } else {
        return {
          status: true,
          message: `Please choose a timeslot that is between dock start and end time.`
        };
      }
    } else if (validationType === 'DOCK') {
      const ifDockExists = _.find(leaveList, {IDDock})
      if (ifDockExists) {
      const { LeaveStart, LeaveEnd } = ifDockExists;
      const dTime = startTime.format('HH:mm:ss');
      const sTime = moment(LeaveStart).format('HH:mm:ss');
      const eTime = moment(LeaveEnd).format('HH:mm:ss');
        const isTimeBetween = dTime >= sTime && dTime <= eTime;
        if (isTimeBetween) {
          return {
            status: false,
            message: ''
          };
        } else {
          return {
            status: true,
            message: `Please choose a different time that lies between Dock Start and End time`
          };
        }
      } else {
        console.log('Dock does not exist in dock capacity data')
      }
    }
  }

  const recalculateApptDurationHandler = (groupId, time, e) => {
    let gid = groupId;
    let pStartTime = new Date(time);
    // Check if holiday List exist then perform holiday validation
    if (_.size(holidayList)) {
      const { status, message } = holidayValidationHandler(gid?.split('_')[0], moment(pStartTime));
      if (status) {
        // showAlert(toast.TYPE.ERROR, message, themeSelected);
        setValidationPopupText(message);
        setShowValidationPopup(true);
        return;
      }
    }
    // Check if WPCapacityData exist then perform WP start Start and end time validation 
    if (!_.isEmpty(wpCapacityDataState)) {
      const { status, message } = WPCapacityValidationHandler(gid?.split('_')[0], moment(pStartTime));
      if (status) {
        // showAlert(toast.TYPE.ERROR, message, themeSelected);
        setValidationPopupText(message);
        setShowValidationPopup(true);
        return;
      }
    }
    const clickedTime = moment.utc(time).startOf('day').toDate();
    const filterDockData = _.find(wpSchedulerData, { dockId: gid.split('_')[0] || 0 });


   
    const { calcPPHhour, calcPPHmin } = calculteEndTimePPH(parseFloat(wpCapacityData[0].PPH), parseFloat(filterDockData.PPH))
    let mpStartTime = moment(pStartTime).add(calcPPHhour, 'hours').add(calcPPHmin, 'minutes')
    // Validate Appointement
    const { status, message } = handleAppointmentBookingValidation({ startTime: pStartTime, endTime: mpStartTime, groupId: gid })
    if (status) {
      showAlert(toast.TYPE.WARNING, message, themeSelected);
    } else {
      setStartTimeState(pStartTime);
      setCanvasData(groupId);
      setCanvasTime(time)
      if (bookApptModelTitle === 'Reschedule Appointment') {
        const splitData = groupId.split('_')
        gid = splitData[0] + '_' +splitData[1];
      }
      const filteredData = _.find(groups, { id: gid });
      const calcEndTime = changeDateToGivenFormat(mpStartTime, AppConstant.commonStrings.MM_DD_YYYY_dateFormat);

      setEndDateState(calcEndTime);
      setEndTimeState(mpStartTime);
      
      // Requested by Rajest to comment
      // showAlert(toast.TYPE.INFO, `Calculated End time is ${calcPPHhour} hours ${calcPPHmin} minutes. Your unloading will end at ${mpStartTime.format('HH:mm A')}`, themeSelected);
      if (bookApptModelTitle === 'Book Appointment') {
        const fieldData = formParticularFieldValue();
        setParticularFieldValue({ dock: filteredData.title, po_number: fieldData?.po_number, start_date: clickedTime, end_date: calcEndTime, ReceivingZipCode: selectedWarehouse?.Zip, WarehouseName: fieldData?.WarehouseName || '-', start_time: pStartTime.toString(), end_time: mpStartTime.toString(), WarehousePPH: wpCapacityData[0].PPH || 0, DockPPH: filterDockData.PPH })
      } else if ('Reschedule Appointment') {
        const { viewPO } = viewPoDetails;
        setParticularFieldValue({ po_number: viewPO?.PoNumber, start_date: clickedTime, end_date: calcEndTime, start_time: pStartTime.toString(), end_time: mpStartTime.toString(), WarehousePPH: wpCapacityData[0].PPH || 0, DockPPH: filterDockData?.PPH })
      }
     // openCreateForm()
    }
  };

  const onCanvasClickHanlder = (groupId, time, e) => {
    let gid = groupId;
    let pStartTime = new Date(time);
    // Check if holiday List exist then perform holiday validation
    if (_.size(holidayList)) {
      const { status, message } = holidayValidationHandler(gid?.split('_')[0], moment(pStartTime));
      if (status) {
        // showAlert(toast.TYPE.ERROR, message, themeSelected);
        setValidationPopupText(message);
        setShowValidationPopup(true);
        return;
      }
    }
    // Check if WPCapacityData exist then perform WP start Start and end time validation 
    if (!_.isEmpty(wpCapacityDataState)) {
      const { status, message } = WPCapacityValidationHandler(gid?.split('_')[0], moment(pStartTime));
      if (status) {
        // showAlert(toast.TYPE.ERROR, message, themeSelected);
        setValidationPopupText(message);
        setShowValidationPopup(true);
        return;
      }
    }
    const clickedTime = moment.utc(time).startOf('day').toDate();
    const filterDockData = _.find(wpSchedulerData, { dockId: gid.split('_')[0] || 0 });
    const { calcPPHhour, calcPPHmin } = calculteEndTimePPH(parseFloat(wpCapacityData[0].PPH), parseFloat(filterDockData.PPH))
    let mpStartTime = moment(pStartTime).add(calcPPHhour, 'hours').add(calcPPHmin, 'minutes')
    // Validate Appointement
    const { status, message } = handleAppointmentBookingValidation({ startTime: pStartTime, endTime: mpStartTime, groupId: gid })
    if (status) {
      showAlert(toast.TYPE.WARNING, message, themeSelected);
    } else {
      setStartTimeState(pStartTime);
      setCanvasData(groupId);
      setCanvasTime(time)
      if (bookApptModelTitle === 'Reschedule Appointment') {
        const splitData = groupId.split('_')
        gid = splitData[0] + '_' +splitData[1];
      }
      const filteredData = _.find(groups, { id: gid });
      const calcEndTime = changeDateToGivenFormat(mpStartTime, AppConstant.commonStrings.MM_DD_YYYY_dateFormat);
      setEndDateState(calcEndTime);
      setEndTimeState(mpStartTime)
      // Requested by Rajest to comment
      // showAlert(toast.TYPE.INFO, `Calculated End time is ${calcPPHhour} hours ${calcPPHmin} minutes. Your unloading will end at ${mpStartTime.format('HH:mm A')}`, themeSelected);
      const fieldData = formParticularFieldValue();
      setParticularFieldValue({ dock: filteredData.title, po_number: fieldData?.po_number, start_date: clickedTime, end_date: calcEndTime, ReceivingZipCode: selectedWarehouse?.Zip, WarehouseName: fieldData?.WarehouseName || '-', start_time: pStartTime.toString(), end_time: mpStartTime.toString(), WarehousePPH: wpCapacityData[0].PPH || 0, DockPPH: filterDockData.PPH })
      openCreateForm(filterDockData);
    }
  };

  const formParticularFieldValue = () => {
    let data = {
      po_number: [],
      ReceivingZipCode: ''
    }
    poForAppt.forEach((el, i) => {
      data.po_number.push(el.PoNumber)
      if (i === 0) {
        data.ReceivingZipCode = el.ReceivingZipCode;
        data.WarehouseName = el.WarehouseName;
      }
    })
    return {
      po_number: data.po_number.toString(),
      ReceivingZipCode: data.ReceivingZipCode,
      WarehouseName: data.WarehouseName
    }
  }

  // const hasEditAccessToAppointment = (foundObject) => {
  //   if (_.toLower(cu_id) !== _.toLower(foundObject?.carrierId)) {
  //     return {
  //       status: false,
  //       message: 'This appointment does not belong to you, hence edit is not possible'
  //     }
  //   } else {
  //     return {
  //       status: true,
  //       message: ''
  //     }
  //   }
  // }
  const handleAppointmentBookingValidation = ({ startTime, endTime, groupId }) => {
    // Check if PO already has appointment and it is not getting rescheduleds
    let masterPO = {}
    if (bookApptModelTitle === 'Book Appointment') {
    if (_.size(poForAppt)) {
      masterPO = poForAppt[0]
    
      if (_.size(poForAppt) && bookApptModelTitle !== 'Reschedule Appointment') {
        const ifApptExists = _.find(items, { title: masterPO?.PoNumber });
        if (!_.isEmpty(ifApptExists)) {
          return {
            status: true,
            message: AppConstant.smartScheduler.myAppointments.scheduleAppointmentExistsWarning,
          }
        }
      }
    }}
    else{
      masterPO = particularFieldValue
    }
      // Check if appointment is overlapping with another Appointment
      const gCanvasData = groupId.split('_');
      const isOverlap = items.some((el) => {
        const elDockTimeSlot = el?.group.split('_');
        return (
          el.start_time < endTime &&
          el.end_time > startTime && elDockTimeSlot[0] === gCanvasData[0] && elDockTimeSlot[1] === gCanvasData[1] && el.title !== masterPO?.PoNumber
        );
      });
      if (isOverlap) {
        // Handle the overlap conflict (e.g., display an error message)
        return {
          status: true,
          message: 'Appointment overlaps with an existing appointment.',
        }
      } else {
        return {
          status: false,
          message: ''
        }
      }
    
  };

  const groupRenderer = ({ group }) => {
    return (
      <div className="custom-group">
        <span className="title subText" data-tip={`${group.title}`} data-for={`${group.id}`}>{group.title}</span>
        <CustomTooltip id={group.id} position='top' type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
          multiline={false} />
      </div>
    )
  }

  const createSchedulerGroups = () => {
    let groupArray = [];
    let itemArray = [];
    let allDockArray = [{idDock: '0000-0000', dockName: 'ALL'}] 
    wpSchedulerData.forEach(el => {
      const { dockId, dockName, timeSlots } = el
     
      if (_.size(timeSlots)) {
        allDockArray.push({
          idDock: dockId,
          dockName: dockName
        })
        timeSlots.forEach(ele => {
          let obj = {
            id: dockId + '_' + ele.timeslotName,
            title: dockName + '-' + ele.timeslotName,
            tip: dockId + '_' + ele.timeslotName,
            idDock: dockId
          }
          groupArray.push(obj);
        })
      }
    })
    setMasterGroups([...groupArray])
    setGroups([...groupArray])
    setAllDock([...allDockArray])
  }

  const onAddRemoveHandler = (data, type) => {
    let IDDock = '';
    const masterPO = poForAppt[0];
      let IDWarehouse = selectedWarehouse?.ID;
      if (bookApptModelTitle === 'Book Appointment') {
        // while fresh appointment booking
        IDDock = canvasData.split('_')[0]
      } else if(bookApptModelTitle ===  'Reschedule Appointment') {
        // while rescheduling appointment
        const { itemId } = viewPoDetails    
        IDDock = itemId.split('_')[0]
      }
    if (type === 'ADD') {
      dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true })
      const newData = data.map(el => {
        const { poNumber, ReceivingZipCode } = el;
        if (poNumber && ReceivingZipCode) {
          return {
            PoNumber: poNumber,
            ReceivingZipCode,
            IDDock,
            IDWarehouse
          }
        }
      })
      dispatch({ type: sagaActions.ADD_REMOVE_PO, payload: { payload: newData, type: type } })
    } else if (type === 'REMOVE') {
      const newData = poForAppt.map(el => {
        if (el.PoNumber !== data.poNumber) {
          return el
        } else {
          return null
        }
      })
      const filteredData = _.compact(newData);
      const finalData = _.uniqBy(filteredData, 'IDPO')
      dispatch({ type: sagaActions.ADD_REMOVE_PO, payload: { payload: finalData, type: type } })
    }
  }

  const showAddPoPopup = () => {
    const popupObj = {
      id: 'addPO',
      modalLabel: 'addPO',
      showClose: true,
      showfooter: false,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: 'md',
      title: "Add PO's",
      bodyText: openAddMorePO(),
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: true,
          text: AppConstant.comments.cancelCommentButtonTitle
        },
        btn2: {
          show: true,
          text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
        }
      },
    }

    return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeAddMorePOForm} themeSelected={themeSelected} showModal={showAddPoModel} />
  }


  const showSearchPoAppointmentPopup = () => {
    const popupObj = {
      id: 'searchPOAppointment',
      modalLabel: 'searchPOAppointment',
      showClose: true,
      showfooter: false,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: 'xl',
      title: "Search PO",
      bodyText: createSearchPOForm(),
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: true,
          text: AppConstant.comments.cancelCommentButtonTitle
        },
        btn2: {
          show: true,
          text: AppConstant.poManagement.searchPOSection.searchTitleStr
        }
      },
    }

    return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeSearchPOForm} themeSelected={themeSelected} showModal={showSearchPoModel} />
  }

  const formFieldListSeachPo = [
    {
      name: "po_number",
      type: "text",
      size: "col-sm-6",
      position: 1,
      placeholder: 'Purchase Order',
      title:
        AppConstant.smartScheduler.poSection.poNumTitle,
      formValidation: {
        required: true,
      },
      disabledField: false,
    },
    {
      name: "ReceivingZipCode",
      type: "text",
      placeholder: 'Zip Code',
      size: "col-sm-6",
      position: 2,
      title: 'Receiving Zip Code',
      formValidation: {
        required: true,
        lengthValidation: {
          customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zipCodeErrorMessage + '. '
        },
      },
      disabledField: false,
    }]

  const openSearchPOForm = () => {
    setShowSearchPoModel(true);
  }
  const closeSearchPOForm = () => {
    setPoSearchList(null)
    setShowSearchPoModel(false);
  }

  const getSummitedFormDataSearchPO = (data) => {
    const { po_number, ReceivingZipCode } = data;
    if (po_number && ReceivingZipCode) {
      setShowSearchError(true)
      dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true })
      dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: po_number, ReceivingZipCode: ReceivingZipCode, UserType: userType, ID: userType === 'CARRIER' ? userAccountDetails?.CUID : selectedWarehouse?.ID } })     
  } else {
      showAlert(toast.TYPE.WARNING, 'Both PO and Zip Code are mandatory', 'light')
  }

  }

  const changeDatePickerSelectedDate = (dates) => {
    setShouldRender(false);
    const dateTemp = moment(dates);
    const visibleTimeStartTemp = dateTemp.clone().hour(0);
    const visibleTimeEndTemp = dateTemp.clone().hour(23).minute(59);
    setVisibleTimeStart(visibleTimeStartTemp);
    setVisibleTimeEnd(visibleTimeEndTemp);
    setDatePickerSelectedDate(dates);
  }

  const createSearchPOForm = () => {
    return (
      <>
      <CustomDynamicForm
        formFieldList={formFieldListSeachPo}
        sort={true}
        cancelBtn={cancelbtndata}
        submitbtn={searchbtnData}
        themeSelected={themeSelected}
        getFormFieldData={getFormFieldData}
        getSummitedFormData={getSummitedFormDataSearchPO}
        // editFormData={editData}
        loading={initateLoading}
        resetFormData={() => resetDataForSearch()}
        setParticularFieldValue={{}}
      />
        {posSearchList && posSearchList.length ? <CarrierApptsListScreen data={posSearchList} screenTypeClassname='ssappointmentListTable-wpflow'/> : showSearchError && !initateLoading && showNoDataAfterSearch() }
        </>
    );
  };

  const resetDataForSearch = () => {
    setResetFormData(0);
  }

  const showNoDataAfterSearch = () => {
    return (
        <span className={`d-flex justify-content-center text-center`}>{'No Appointments available for this PO.'}</span>
    )
  }

  const closeAppointmentValidationPopup = () => {
    setShowValidationPopup(false)
  }

  const showAppointmentValidationPopup = () => {

    const popupObj = {
      id: 'appointmentValidationPopup',
      modalLabel: 'appointmentValidationPopup',
      showClose: true,
      showfooter: true,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: 'lg',
      title: "Alert",
      bodyText: <div className='boldText'>{validationPopupText}</div>,
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: false,
          text: ''
        },
        btn2: {
          show: true,
          text: AppConstant.commonStrings.close
        },
      },
    }
    return (
      <Popup {...popupObj} popupBtnHandler={(text) => {closeAppointmentValidationPopup() }} closepopup={closeAppointmentValidationPopup} themeSelected={themeSelected} showModal={showValidationPopup} modalClassname={'deletePopupBkg'} />
    )
  }


  const showDeletePoPopup = () => {

    const popupObj = {
      id: 'deletePOAppt',
      modalLabel: 'deletePOAppt',
      showClose: true,
      showfooter: true,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: 'lg',
      title: "Delete Appointment",
      bodyText: <div className='navText'>Are you sure you want to delete this appointment?</div>,
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: true,
          text: AppConstant.commonStrings.yes
        },
        btn2: {
          show: true,
          text: AppConstant.commonStrings.no
        }
      },
    }
    return (
        <Popup {...popupObj} popupBtnHandler={(text) => deletePoApptPopupBtnHandler(text)} closepopup={closeDeletePOApptForm} themeSelected={themeSelected} showModal={showDeletePoApptModel} modalClassname={'deletePopupBkg'} />
    )
  }

  const closeDeletePOApptForm = () => {
    setShowDeletePoApptModel(false)
  }

  const deletePoApptPopupBtnHandler = (titleStr) => {

    if(titleStr === AppConstant.commonStrings.yes){
      const { viewPO, itemId } = viewPoDetails
      let masterPO = {}
      if (_.size(poForAppt)) {
        masterPO = poForAppt[0]
      }
          const newItemPayload = {
            PoNumber: viewPO?.PoNumber?.includes(',') ?  viewPO?.PoNumber : `${ viewPO?.PoNumber},`,
            IDWarehouse: viewPO?.IDWarehouse,
            ReceivingZipCode: viewPO?.ReceivingZipCode,
            ApptStartDate: null,
            ApptStartTime: null,
            ApptEndDate: null,
            ApptEndTime: null,
            ApptStatus: 1,
            Door: null,
            ApptCreatedBy: null,
            IDDock: itemId.split('_')[0],
            IDCarrier: viewPO?.IDCarrier,
            CRUDTYPE: 'D',
            DeletedPos: ''
          }
      dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true })
      dispatch({ type: sagaActions.ADD_APPOINTMENT, payload: newItemPayload })
      dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
      setBookApptModelTitle('Book Appointment')
      setShowDeletePoApptModel(false)
    } else {
      setShowDeletePoApptModel(false)
    }
  }

  const onDockSelected = (data) => {
    const { idDock } = data;
    if (idDock && idDock === '0000-0000') {
      createSchedulerGroups()
    } else {
    const filterGroupArry = _.filter(masterGroups, { idDock })
      setGroups([...filterGroupArry])
    }
  }

  return (
    <>
      <Header {...props} />
      <Navigationbar {...props} />
      <div className='scheduler-wrapper'>
        <SchedulerHeaderBar {...props} openModel={() => openSearchPOForm()} datePickerSelectedDate={datePickerSelectedDate} changeDatePickerSelectedDate={(dates) => changeDatePickerSelectedDate(dates)} allDock={ allDock} onDockSelected={(data) => onDockSelected(data)} />
        {shouldRender ? <Timeline
          groups={groups}
          items={items}
          groupRenderer={ groupRenderer}
          defaultTimeStart={visibleTimeStart}
          defaultTimeEnd={visibleTimeEnd}
          canResize={false}
          canMove={false}
          onCanvasClick={onCanvasClickHanlder}
          // onItemResize={onItemResizeHandler}
          onItemClick={onItemClickHandler}
          // visibleTimeStart={visibleTimeStart}
          // visibleTimeEnd={visibleTimeEnd}
          // verticalLineClassNameForTime={verticalLineClassNameForTime}
          // minZoom={60 * 60 * 24 * 1000}
          // maxZoom={60 * 60 * 24 * 1000}
          timeSteps={{
            second: 1,
            minute: 15,
            hour: 1,
            day: 1,
            month: 1,
            year: 1
          }}
          dateHeaderRenderer={dateHeaderRenderer}
        >
          <TimelineHeaders>
            
            <SidebarHeader>
              {({ getRootProps }) => {
                return <div {...getRootProps()}>Dock-TimeSlots</div>
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" headerRenderer={dateHeaderRenderer} />
            <DateHeader unit="hour"
              labelFormat="HH:mm"
              style={{ height: 50 }} />
          </TimelineHeaders>
        </Timeline> : <div className='w-100'>{displayLoader(themeSelected, 400, 1)}</div>}
      </div>
      {showBookAppointmentPopup()}
      {showSendEmailPopup()}
      {showAppointmentDetails()}
      {showAddPoPopup()}
      {showSearchPoAppointmentPopup()}
      {showDeletePoPopup()}
      {showAppointmentValidationPopup()}
    </>
  );
}

export default Scheduler;