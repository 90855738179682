import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assignedDock: null,
    isLoading: false,
    error: null
}

export const AssignDockReducer = createSlice({
    name: 'assignDockReducer',
    initialState,
    reducers: {
        initiateAssignDoor: (state) => {
            state.isLoading = true
        },
        assignDockSuccess: (state, action) => {
            state.isLoading = false
            state.assignedDock = action.payload;
        },
        assignDockFailure: (state, action) => {
            state.isLoading = false
            state.error = action.payload;
        },
        clearAssignedDock: (state) => {
            state.assignedDock = null;
        },
        clearError: (state) => {
            state.error = null
        },
    }
});


export const { assignDockSuccess, initiateAssignDoor, assignDockFailure, clearError, clearAssignedDock } = AssignDockReducer.actions;

// Selectors
export const getAssignDockSelector = (state) => {
    return state.assignDockReducer;
};

export default AssignDockReducer.reducer;
