import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Route,
    Routes,
    useNavigate
} from "react-router-dom";
import { toast } from "react-toastify";
import "./App.css";
import { AppConstant } from "./App/Assests/AppConstant";
import { showAlert } from "./App/Assests/Utility";
import AlertNotification from "./App/Components/Common/CommonAlerts/CommonAlerts";
import ErrorBoundary from "./App/Components/ErrorBoundary/ErrorBunday";
import DrillDownWrapper from './App/Components/LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Wrapper/DrillDownWrapper';
import CompanyAdmin from './App/Components/LandingPage/Components/SuperUserAdmin/CompanyAdmin/CompanyAdmin';
import CompanyGroupAdmin from './App/Components/LandingPage/Components/SuperUserAdmin/CompanyGroupAdmin/CompanyGroupAdmin';
import RoleFeaturesEditList from './App/Components/LandingPage/Components/SuperUserAdmin/RoleFeaturesEditScreen/RoleFeaturesEditScreen';
import UserRoleAdmin from './App/Components/LandingPage/Components/SuperUserAdmin/UserRoleAdmin/UserRoleAdmin';
import WarehouseAdmin from './App/Components/LandingPage/Components/SuperUserAdmin/WarehosueAdmin/WarehouseAdmin';
import ChangePassword from "./App/Components/Login/Container/ChangePassword/ChangePassword";
import Recover from './App/Components/Login/Container/Recover/Recover';
import SetPassword from "./App/Components/Login/Container/SetPassword/SetPassword";
import { ThemeContext } from "./Context/ThemeContext";
import { fsInterceptor, interceptor, removeInterceptor } from "./redux/api/Interceptor";
import UchainAxios from "./redux/api/UchainAxios";
import { getAuthReducer } from "./redux/reducers/reducerSlices/AuthReducer";
import { sagaActions } from "./sagas/sagaActions";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import Footer from './App/Components/Footer/Footer';
import FaqAdmin from './App/Components/LandingPage/Components/HelpSupportAdmin/FaqAdmin/FaqAdmin';
import HelpSupportAdmin from './App/Components/LandingPage/Components/HelpSupportAdmin/HelpSupportAdmin';
import Reports from './App/Components/LandingPage/Components/Reports/Reports';
import RequestsReceivedAdmin from './App/Components/LandingPage/Components/SuperUserAdmin/RequestsReceivedAdmin/RequestsReceivedAdmin';
import LoadManagement from './App/Components/LandingPage/Container/LoadManagement/LoadManagement';
import UserAssignWrappper from './App/Components/LandingPage/Container/User/UserAssignWrappper';
import UserWrapper from './App/Components/LandingPage/Container/User/UserWrapper';
import CarrierLandingScreen from './App/Components/LandingPage/SmartScheduler/CarrierLandingScreen/CarrierLandingScreen';
import SearchAppointments from './App/Components/LandingPage/SmartScheduler/SearchAppointments/SearchAppointments';
import HelpAndFeedInside from "./App/Components/Login/Container/HelpAndFeedBack/HelpAndFeedInside";
import RenewCarrierSubscriptionsForm from './App/Components/Login/RenewCarrierSubscriptions/RenewCarrierSubscriptionsForm';
import CarrierSignUp from './App/Components/Login/SignUpCarrier/CarrierSignUp';
import PaymentMethodContainer from './App/Components/Login/SignUpCarrier/FormCarrierSignUp/PaymentMethodsFormCarrier/PaymentMethodContainer';
import MainLandingPage from './App/Components/MainLandingPage/MainLandingPage';
import MySubscriptions from './App/Components/MySubscriptions/MySubscriptions';
import Settings from './App/Components/Settings/Settings';
import Scheduler from './App/Components/SmartSchedule/Scheduler';
import SuperWarehouse from './App/Components/SuperWarehouse/SuperWarehouse';
import firebaseConfig from './firebase-config';
import Guards from './App/Components/LandingPage/SmartScheduler/guards/Guards';
import { CarrierSignUpUpdated } from './App/Components/Login/SignUpCarrier/CarrierSignUpUpdated';
import MyAppointmentsSS from './App/Components/SmartSchedule/SchedulePro/Components/MyAppointment/MyAppointmentsSS';
import MySubscriptionsSS from './App/Components/SmartSchedule/SchedulePro/Components/MySubscriptionsSS/MySubscriptionsSS';
import RenewCarrierSubscriptionsSS from './App/Components/Login/RenewCarrierSubscriptions/RenewSubsParentSS/RenewSubscriptionParentSS';
import RecoverSS from './App/Components/Login/Container/Recover/RecoverSS';
import ChangePasswordSS from './App/Components/Login/Container/ChangePassword/ChangePasswordSS';
import SetPasswordSS from './App/Components/Login/Container/SetPassword/SetPasswordSS';
import CreatePoWrapper from './App/Components/LandingPage/SmartScheduler/POManagement/CreatePoWrapper';
import WhMetaDataMainScreenSS from './App/Components/LandingPage/SmartScheduler/WarehouseMetaData/WarehouseMetaDataMainScreenSS';
import BulkUploadPOSS from './App/Components/LandingPage/SmartScheduler/POManagement/BulkUploadPOSS';
import DashboardWrapper from './App/Components/SmartSchedule/SchedulePro/Components/DashBoard/Wrapper/DashboardWrapper';
import Dashboard from './App/Components/LandingPage/Container/Dashboard/Dashboard'
import WPSchedulerWHSS from './App/Components/SmartSchedule/WPSchedule/WPSchedulerWHSS';
import UserWrapperSS from './App/Components/SmartSchedule/SchedulePro/Components/UsersSS/UserWrapperSS';
import ManageAppointments from './App/Components/LandingPage/SmartScheduler/WarehouseMetaData/ManageAppointments';
import SchedulerNewSS from './App/Components/SmartSchedule/WPSchedule/SchedulerNewSS';
import CheckIn from './App/Components/LandingPage/SmartScheduler/CheckIn/CheckIn';
import SPLoadManagement from './App/Components/LandingPage/Container/LoadManagement/SPLoadManagement';
import SPUserWrapper from './App/Components/SmartSchedule/SchedulePro/Components/SPUser/SPUserWrapper';
import GuardCheckIn from './App/Components/LandingPage/SmartScheduler/guards/GuardCheckIn';
import CheckOutNew from './App/Components/LandingPage/SmartScheduler/CheckOut/CheckOutNew';
import { SSFaq } from './App/Components/SmartSchedule/SchedulePro/Components/Faq/SSFaq';
import ScheduleUpload from './App/Components/ScheduleUpload/ScheduleUpload';
import DrillDownAppointmetReportSummary from './App/Components/LandingPage/Components/Reports/ReportsSection/SSAppointmentsReport/AllAppointmentsList/DrillDownAppointmetReportSummary';
import DockConflictMainScreenSS from './App/Components/LandingPage/SmartScheduler/WarehouseMetaData/Forms/DockConflict/DockConflictMainScreenSS';
import Protected from './App/Components/Common/Protected';
import EclipseOpsPayment from './App/Components/LandingPage/Components/SuperUserAdmin/EclipseOpsPayment/EclipseOpsPayment';
import FsWarehouseNav from './App/Components/LandingPage/Components/SuperUserAdmin/FSWarehouse/FsWarehouseNav';
import FsPayWarehouseNav from './App/Components/LandingPage/Components/SuperUserAdmin/FSPayWarehouse/FsPayWarehouseNav';
import SproWarehouseAdmin from './App/Components/LandingPage/Components/SuperUserAdmin/SproWarehosueAdmin/SproWarehouseAdmin';
import GatePassNav from './App/Components/LandingPage/Components/SuperUserAdmin/GatePass/GatePassNav';

// The central routing component responsible for handling the entire project's routing.
function App() {
    const [showAccessDenied, setShowAccessDenied] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(getAuthReducer);

    useEffect(() => {
        // Clear local storage when component renders
        localStorage.removeItem('persist:uchainRoot')
        intiliazeRemoteConfigFirebase()

        window.addEventListener("unhandledrejection", function (e) {

            if (e.reason?.code === "NotAuthorizedException" || e.reason === "No current user") {
                //   store.dispatch({ type: 'USER_LOGOUT' })
                // console.log('unhandledrejection:  ', e);
                //   alert("session expired!!");
                // logoutUser()
            }
        });


        UchainAxios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error?.response?.status === 401 || error?.response?.status === 403) {
                if (window.location.pathname !== '/' && window.location.pathname !== '/carriersignup') {
                    setShowAccessDenied(true)
                } else if (window.location.pathname === '/') {
                    // Added to resolve WP-1520
                    setShowAccessDenied(true)
                }
            }
            return Promise.reject(error);
        });
        fsInterceptor(process.env.REACT_APP_FSAPIKEY)
    }, [])
    // This function initialize firebase remote config and gets constants stored such as 
    // autofetchNotificationInterval, autofetchDefDashboardInterval, autofetchCustomDashboardInterval, autoFetchExecutorInterval, DoorAssignedCountDownBanner
    const intiliazeRemoteConfigFirebase = async () => {
        // https://firebase.google.com/docs/web/setup#available-libraries
        // Your web app's Firebase configuration
        // For Firebase JS SDK v7.20.0 and later, measurementId is optional
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const remoteConfig = getRemoteConfig(app)
        // Set time manually to fetch remote config from firebase
        // remoteConfig.settings.minimumFetchIntervalMillis = 1000; // default is 12 hours, Every 12 hours Remote Config is fetched
        const isFetched = fetchAndActivate(remoteConfig).then(_ => {
            if (isFetched) {
                // Get all required values from Remote Config
                const autofetchNotificationInterval = getValue(remoteConfig, 'autofetchNotificationInterval');
                const autofetchDefDashboardInterval = getValue(remoteConfig, 'autofetchDefDashboardInterval');
                const autofetchCustomDashboardInterval = getValue(remoteConfig, 'autofetchCustomDashboardInterval');
                const autoFetchExecutorInterval = getValue(remoteConfig, 'autoFetchExecutorInterval');
                const doorAssignedTimer = getValue(remoteConfig, 'DoorAssignedCountDownBanner');
                // Save all values in RemoteConfig Reducer
                dispatch({ type: sagaActions.GET_AUTO_FETCH_NOTIFICATION_INTERVAL, payload: autofetchNotificationInterval['_value'] })
                dispatch({ type: sagaActions.GET_AUTO_FETCH_DEF_DASHBOARD_INTERVAL, payload: autofetchDefDashboardInterval['_value'] })
                dispatch({ type: sagaActions.GET_AUTO_FETCH_CUSTOM_DASHBOARD_INTERVAL, payload: autofetchCustomDashboardInterval['_value'] })
                dispatch({ type: sagaActions.GET_AUTO_FETCH_EXECUTOR_INTERVAL, payload: autoFetchExecutorInterval['_value'] })
                dispatch({ type: sagaActions.GET_DOOR_ASSIGNED_TIMER, payload: doorAssignedTimer['_value'] })

            } else {
                console.log('Issue in fetching remoteConfig', isFetched);
            }
        }).catch(err => {
            console.log('Issue in fetching remoteConfig', err);
        })

    }

// UseEffect for showAccessDenied page if user does not have access to any page/route
    useEffect(() => {
        if (showAccessDenied) {
            // dispatch to clear user signed in data
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER });
            // dispatch to reset all user data
            dispatch({ type: sagaActions.RESET_USER })
            logoutUser()
            // clear all browser local storage data
            window.localStorage.clear();
            // Added to resolve WP-1520
            if (window.location.pathname === '/') {
                setTimeout(() => {
                window.location.reload()
                setShowAccessDenied(false)
                    
                }, 100)
                 // show toast
                showAlert(toast.TYPE.WARNING, AppConstant.login.sessionExpiry, 'light')
            }
        }
    }, [showAccessDenied])
// useEffect to assign session token upon login
    useEffect(() => {
        if (user && user.sessionToken) {
            // always remove previous stored token and then assign new token
            removeInterceptor()
            interceptor(user.sessionToken);
        }
    }, [user.sessionToken])



    const [darkMode, setDarkMode] = useState(false);
    const [currentTheme, setCurrentTheme] = useState('light-mode');
    // useEffect fot theme channge drak/light
    useEffect(() => {
        darkMode ? setCurrentTheme('dark-mode') : setCurrentTheme('light-mode');
    }, [darkMode])

    // function to handle theme change
    const onThemeChange = () => {
        setDarkMode(!darkMode);
    }
    // function to handle user logout
    const logoutUser = () => {
        dispatch({type: sagaActions.CLEAR_SMART_SCHEDULE_REDUCER_DATA})
        removeInterceptor()
        
        // navigate to initial route
        navigate("/");
    }

    return (
        <ThemeContext.Consumer>
            {({ changeTheme }) => (
                <ErrorBoundary dispatch={dispatch} navigate={navigate} removeInterceptor={removeInterceptor}>
                    <AlertNotification theme={currentTheme === 'dark-mode' ? 'dark' : 'light'} />
                    <div className={`App ${currentTheme}`}>
                        <Routes>
                            <Route path="/" element={<MainLandingPage themeSelected={currentTheme} onThemeChangePass={() => onThemeChange()} />} />
                            <Route path="setPassword" element={<SetPassword />} />
                            <Route path="schedulepro/setPassword" element={<SetPasswordSS />} />
                            <Route path="recover" element={<Recover />} />
                            <Route path="schedulepro/recover" element={<RecoverSS />} />
                            <Route path="verifypassword" element={<ChangePassword />} />
                            <Route path="schedulepro/verifypassword" element={<ChangePasswordSS />} />
                            <Route exact path="loadmanagement" element={<Protected component={<LoadManagement themeSelected={currentTheme}
                                onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="sploadmanagement" element={<Protected component={<SPLoadManagement themeSelected={currentTheme}
                                onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="analytics" element={<Protected component={<Dashboard themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="analytics/reports/:drilldown" element={<Protected component={<DrillDownWrapper themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="analytics/reports/:drilldown/:drilldownType" element={<Protected component={<DrillDownWrapper themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="analytics/reports/:drilldown/:drilldownType/:DrillDownColumn" element={<Protected component={<DrillDownWrapper themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="user" element={<Protected component={<UserWrapper themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="user/assign" element={<Protected component={<UserAssignWrappper themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="company" element={<Protected component={<CompanyAdmin themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="warehouses" element={<Protected component={<WarehouseAdmin themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="sprowarehouses" element={<Protected component={<SproWarehouseAdmin themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="roles" element={<Protected component={<UserRoleAdmin themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="rolefeatureseditlist" element={<Protected component={<RoleFeaturesEditList themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="companygroup" element={<Protected component={<CompanyGroupAdmin themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="faqmanagement" element={<Protected component={<HelpSupportAdmin themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="faq" element={<Protected component={<HelpSupportAdmin themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="faqadmin" element={<Protected component={<FaqAdmin themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="warehousemanagement" element={<Protected component={<SuperWarehouse themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="requestreceived" element={<Protected component={<RequestsReceivedAdmin themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="settings" element={<Protected component={<Settings themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="report" element={<Protected component={<Reports themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="helpAndFeed" element={<Protected component={<HelpAndFeedInside themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="ScheduleAppointments" element={<Protected component={<Scheduler themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="schedulerweek" element={<Protected component={<WPSchedulerWHSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            {/* <Route exact path="BookAppointments" element={<BookAppointments themeSelected={currentTheme} onThemechange={() => onThemeChange()} />} />
                            */}
                            <Route exact path="BookAppointments" element={<Protected component={<DashboardWrapper themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="carriersignup" element={<CarrierSignUp themeSelected={currentTheme} onThemechange={() => onThemeChange()} />} />
                            <Route exact path="paymentmethodform" element={<PaymentMethodContainer themeSelected={currentTheme} onThemechange={() => onThemeChange()} />} />
                            <Route exact path="MyAppointments" element={<Protected component={<MyAppointmentsSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="searchappointments" element={<Protected component={<SearchAppointments themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="pomanagement" element={<Protected component={<CreatePoWrapper themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="whmetadata" element={<Protected component={<WhMetaDataMainScreenSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="bulkUploadPO" element={<Protected component={<BulkUploadPOSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="mysubscriptions" element={<Protected component={<MySubscriptionsSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="renewcarrsubsparent" element={<Protected component={<RenewCarrierSubscriptionsSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="renewcarrsubsform" element={<Protected component={<RenewCarrierSubscriptionsForm themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="landing" element={<Protected component={<CarrierLandingScreen themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="welcome" element={<Protected component={<CarrierLandingScreen themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="guards" element={<Protected component={<Guards themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="guardcheckin" element={<Protected component={<GuardCheckIn themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="guardcheckout" element={<Protected component={<CheckOutNew themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            {/* <Route exact path="guardcheckoutnew" element={<CheckOutNew themeSelected={currentTheme} onThemechange={() => onThemeChange()} />} /> */}
                            {/* <Route exact path="schedulepro" element={<ScheduleProWrapper themeSelected={currentTheme} onThemechange={() => onThemeChange()} />} /> */}
                            {/* <Route exact path="schedulepro/login" element={<LoginSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />} /> */}
                            <Route exact path="schedulepro/signup" element={<CarrierSignUpUpdated themeSelected={currentTheme} onThemechange={() => onThemeChange()} />} />
                            <Route exact path="mysubscriptionsold" element={<Protected component={<MySubscriptions themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="carriersubusers" element={<Protected component={<UserWrapperSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="manageappointments" element={<Protected component={<ManageAppointments themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="schedulerweek" element={<Protected component={<SchedulerNewSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="WPscheduleAppointments" element={<Protected component={<SchedulerNewSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="spusers" element={<Protected component={<SPUserWrapper themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="spfaq" element={<Protected component={<SSFaq themeSelected={currentTheme} onThemechange={() => onThemeChange()} />} />}/>
                            <Route exact path="checkin" element={<Protected component={<CheckIn themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="scheduleupload" element={<Protected component={<ScheduleUpload themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="report/appointmentsummary/:groupByType/:startDate/:endDate" element={<Protected component={<DrillDownAppointmetReportSummary themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="dockconflict" element={<DockConflictMainScreenSS themeSelected={currentTheme} onThemechange={() => onThemeChange()} />} />
                            <Route exact path="fspaypayment" element={<Protected component={<EclipseOpsPayment themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="fswarehouse" element={<Protected component={<FsWarehouseNav themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="fspaywarehouse" element={<Protected component={<FsPayWarehouseNav themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                            <Route exact path="gatepass" element={<Protected component={<GatePassNav themeSelected={currentTheme} onThemechange={() => onThemeChange()} />}/>} />
                        </Routes>
                    </div>
                    <div className='landscape-msg'>
                    Please switch to portrait mode.
                    </div>
                    {/* Asked by Rajesh on 19/09/2023 */}
                    <Footer themeSelected={currentTheme} />
                </ErrorBoundary>
            )}

        </ThemeContext.Consumer>
    );
}

export default App;
