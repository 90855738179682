import React from 'react'
import './SSAppointmentHistory.css'
import moment from 'moment';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import _ from 'lodash'
import NoDataImg from '../../../../../../../Assests/Images/nodata-xsm.png'
import { displayLoader } from '../../../../../../../Assests/Utility';

/**
 * Reusable component to display history of po/appt
 * @param {*} props 
 * @returns 
 */
const SSAppointmentHistory = (props) => {
  const {data,loading=false,themeSelected='light-mode'} = props;

  /**
   * Function to render if no data available
   * @returns 
   */
  const showNoDataAvailable = () => {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center w-100'>
            <img src={NoDataImg} alt='Opps....' />
            <br />
            <h6>No History Available</h6>
        </div>
    )
} 

  return (
    <div className='ss-appt-wrapper history-wrapper px-2 mt-2'>
         {_.size(data) && !loading ?
          <table className='table table-responsive table-hover table-summary mb-0'>
        <thead className='align-middle truck-list-title-ss'>
        <th className='col title-text-container f-12 '>Action</th>
        <th className='col title-text-container f-12'>By</th>
        <th className='col title-text-container f-12'>Reason</th>
        <th className='col title-text-container f-12'>On</th>
        </thead>
     
        <tbody className='appt-history-body'>
          { data?.map((appt,i)=>{
            return(
              <tr key={appt.Audit_Id}>
              <td className=''>{(appt.TransType==="Delete" ? 'Cancel':appt.TransType) || "-"}</td>
              <td className=''>{`${appt.FirstName || "-"} ${appt.LastName || "-"}`}
              </td>
              <td className=' word-break'>{appt.RescheduleReason || "-"}</td>
              <td className=''>{appt.Transdate ? moment(appt.Transdate).utc().format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss):"-"}</td>
          </tr>
            )
          })}
           </tbody>
    </table>: loading?
    displayLoader(themeSelected, 45, 5)
    :showNoDataAvailable() }
      </div>
  )
}

export default SSAppointmentHistory