import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userType: '',
    permissions: [],
    navigationBarList: [],
    userWarehouseList: [],
    userWarehouseEmptyList: false,
    parentWarehouse: {},
    selectedWarehouse: {},
    userList: [],
    userRoleDetailsList: [],
    initateLoading: false,
    cu_id: '',
    companyGroupList: [],
    companyList: [],
    selectedUser: {},
    allWarehouseList: [],
    arrangeOrder: 'asc',
    arrangeKey: 'Email',
    closeCompanyGroupForm: '',
    closeCompanyForm: false,
    userAccountDetails: {},
    error: false,
    branding: {},
    brandingSuccess: false,
    brandingError: false,
    brandingLoader: true,
    resendEmail: false,
    navigationList: [],
    resetUserPass: false,
    carrierFromSignUpUserObj: {},
    carrierSubUserList: [],
    actionLoader: false,
    addCarrierSpro: ''
}

export const UserReducer = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        updateUserType: (state, action) => {
            state.userType = action.payload;
        },
        updateUserPermissions: (state, action) => {
            state.permissions = action.payload;
        },
        updateNavigationBarList: (state, action) => {
            state.navigationBarList = action.payload;
        },
        resetUserPermission: (state) => {
            state.permissions = [];
            state.navigationBarList = [];
            state.navigationBarList = [];
            state.userWarehouseList = [];
            state.userWarehouseEmptyList = false;
            state.selectedWarehouse = {};
        },
        resetUser: (state) => {
            state.userType = '';
            state.permissions = [];
            state.navigationBarList = [];
            state.navigationBarList = [];
            state.userWarehouseList = [];
            state.userWarehouseEmptyList = false;
            state.selectedWarehouse = {};
            state.userList = [];
            state.userRoleDetailsList = [];
            state.initateLoading = false;
            state.companyGroupList = [];
            state.companyList = [];
            state.selectedUser = {};
            state.allWarehouseList = [];
            state.arrangeOrder = 'asc';
            state.userListarrangeKey = 'Email';
            state.userAccountDetails = {};
            state.branding = {};
            state.brandingSuccess = false;
            state.brandingError = false;
            state.brandingLoader = true;

        },
        resetUserWithoutBranding: (state) => {
            state.userType = '';
            state.permissions = [];
            state.navigationBarList = [];
            state.navigationBarList = [];
            state.userWarehouseList = [];
            state.userWarehouseEmptyList = false;
            state.selectedWarehouse = {};
            state.userList = [];
            state.userRoleDetailsList = [];
            state.initateLoading = false;
            state.companyGroupList = [];
            state.companyList = [];
            state.selectedUser = {};
            state.allWarehouseList = [];
            state.arrangeOrder = 'asc';
            state.userListarrangeKey = 'Email';
            state.userAccountDetails = {};
        },
        updateUserWarehouseList: (state, action) => {
            state.userWarehouseList = action.payload;
            state.userWarehouseEmptyList = false;
        },
        updateSelectedWarehouse: (state, action) => {
            state.selectedWarehouse = action.payload;
        },
        updateUserWarehouseEmptyList: (state, action) => {
            state.userWarehouseEmptyList = action.payload;
            state.initateLoading = false;
        },
        updateUserList: (state, action) => {
            state.userList = action.payload;
        },
        updateUserRoleDetailsList: (state, action) => {
            state.userRoleDetailsList = action.payload;
        },
        updateInitiateLoader: (state, action) => {
            state.initateLoading = action.payload
        },
        update_cu_id: (state, action) => {
            state.cu_id = action.payload
        },
        update_companyGroupList: (state, action) => {
            state.companyGroupList = action.payload
        },
        update_companyList: (state, action) => {
            state.companyList = action.payload
        },
        update_selectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
        updateAllWarehouseList: (state, action) => {
            state.allWarehouseList = action.payload
            state.initateLoading = false;
        },
        setCompanyList: (state, action) => {
            state.companyList = action.payload
        },
        updateTableData: (state, action) => {
            const { drillDownReport, arrange, key } = action.payload;
            state[key] = drillDownReport
            state.arrangeKey = arrange.key
            state.arrangeOrder = arrange.order
        },
        update_companyGroupFormHandler: (state, action) => {
            state.closeCompanyGroupForm = action.payload;
        },
        update_companyFormHandler: (state, action) => {
            state.closeCompanyForm = action.payload;
        },
        update_parent_warehouse: (state, action) => {
            state.parentWarehouse = action.payload;
        },
        get_user_account_details: (state, action) => {
            state.userAccountDetails = action.payload;
        },
        updateError: (state, action) => {
            state.error = action.payload;
        },
        updateBranding: (state, action) => {
            state.branding = action.payload;
        },
        updateBrandingSuccess: (state, action) => {
            state.brandingSuccess = action.payload;
            state.brandingError = false;
        },
        updateBrandingError: (state, action) => {
            state.brandingError = action.payload;
        },
        updateBrandingLoader: (state, action) => {
            state.brandingLoader = action.payload;
        },
        updateResendEmail: (state, action) => {
            state.resendEmail = action.payload;
        },
        updateNavigationList: (state, action) => {
            state.navigationList = action.payload;
        },
        updateResetUserPass: (state, action) => {
            state.resetUserPass = action.payload;
        },

        createCarrierFromSignUp: (state, action) => {
            state.carrierFromSignUpUserObj = action.payload;
        },

        clearBranding: (state) => {
            state.branding = {};
            state.brandingSuccess = false;
            state.brandingError = false;
            state.brandingLoader = true;
            state.userWarehouseList = []
        },
        updateCarrierSubUserList: (state, action) => {
            state.carrierSubUserList = action.payload;
        },
        updateActionLoader: (state, action) => {
            state.actionLoader = action.payload;
        },
        updateAddCarrierSpro: (state, action) => {
            state.addCarrierSpro = action.payload;
        }

    }

});


export const { updateUserType, updateUserPermissions, updateNavigationBarList, resetUserPermission,
    updateUserWarehouseList, updateSelectedWarehouse, updateUserWarehouseEmptyList, updateUserList, updateUserRoleDetailsList, updateInitiateLoader, update_cu_id, update_companyGroupList, update_companyList, update_selectedUser, updateAllWarehouseList,
    updateTableData, resetUser, setCompanyList, update_companyGroupFormHandler, update_companyFormHandler, update_parent_warehouse, get_user_account_details, updateError,
    updateBranding, updateBrandingSuccess, updateBrandingError, updateBrandingLoader, updateResendEmail, updateNavigationList, updateResetUserPass, createCarrierFromSignUp, clearBranding, resetUserWithoutBranding, updateCarrierSubUserList, updateActionLoader, updateAddCarrierSpro
} = UserReducer.actions;

// Selectors
export const getUserSelector = (state) => {
    return state.userReducer;
};

export default UserReducer.reducer;
