import React from "react";
import { AppConstant } from "../../../Assests/AppConstant";
/**
 * This is reusable component for enable/disable toggle switch
 * @param {*} props 
 * title = label for toggle
 * onChangeValue = toggle switch handler
 * @returns 
 */
const ToggleSwitch = (props) => {
    const { status, title, onChangeValue } = props;
    return (
        <div className='d-flex align-items-center' data-testid='toggle-switch'>
            {status ?
                <input className="form-check-input mb-1" type="checkbox" checked role="switch" id="flexSwitchCheckDefault"
                data-testid={AppConstant.dataTestId.customInput}
                    onChange={() => onChangeValue()} />
                :
                <input className="form-check-input mb-1" type="checkbox" role="switch"
                    id="flexSwitchCheckDefault"
                    data-testid={AppConstant.dataTestId.customInput}
                    onChange={() => onChangeValue()} />

            }
            <label className="form-check-label graphHeading ms-3" for="flexSwitchCheckDefault">{title}</label>
        </div>
    )
}

export default ToggleSwitch;