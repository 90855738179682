import React, { useEffect, useState } from 'react'
import { getRemoteConfigSelector } from '../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { useSelector } from 'react-redux';
import { formDropDownData, formDropDownDataforGaurd } from '../../../../../../Assests/Utility';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import { AppConstant } from '../../../../../../Assests/AppConstant';

/**
 * This is reusable component to select reason for reschedule
 * @param {*} props 
 * @returns 
 */
const SelectRescheuleReason = (props) => {
    const {rescheduleReason, setRescheduleReason,onSubmit,showButton=false, type,errorMsg=null} = props;
    const remoteConfig=useSelector(getRemoteConfigSelector);
    const {allConfigration}=remoteConfig;
    const [error,setError] = useState(null);

    /**
     * useEffect to handle error state
     */
    useEffect(()=>{
        setError(errorMsg)
    },[errorMsg])
    
    /**
     * Function to handle change in select 
     * @param {*} e 
     */
    function handleChange(e){
        if (e.target.value==='Select a reason') {
            setRescheduleReason(null)
        }
        else{
        setRescheduleReason(e.target.value)
    }
    }

    /**
     * Function to handle next button click 
     */
    function nextHandler(){
        if (validate()) {
            onSubmit();
        }
    }

    /**
     * Function to handle errors
     */
    function handleError(){
        validate();
    }

    /**
     * Function to validate select data
     * @returns 
     */
    function validate(){
        let errorData=null;
        if (!rescheduleReason) {
            errorData=AppConstant.help.required;
        }
        else{
            errorData=null;
        }
        setError(errorData);
        return errorData? false : true;
    }

     //Get Reasons DropDown Data
     const getReasonsList = () => formDropDownData(allConfigration, 'ReasonForRescheduleOrCancel')
  return (allConfigration['ReasonForRescheduleOrCancel']?.length?
    <div className='select-reason-wrapper w-100'>
        <p className='mb-2 f-16'>Select Reason For {type} <span className='color-red'>*</span></p>
        <select className='input-control w-100' onChange={(e)=>handleChange(e)} placeholder='Select' onBlur={handleError}>
            <option value={null}>Select a reason</option>
            {getReasonsList()}
        </select>
        {error ? <span className='color-red f-12'>{error}</span> :""}
        {showButton &&
        <div className='d-flex justify-content-end mt-2'>
        <CustomButtonSS
                    isLoading={false}
                    title={ AppConstant.commonStrings.next}
                    className={rescheduleReason===null ? "btn-submit disabled" : "btn-submit"}
                    onClick={() => { nextHandler() }}
                    disabled={false}
                />
        </div>
        }
    </div>:<p className='p-3 color-red fw-bold'>{AppConstant.errors.wentWrongReLogin}</p>
  )
}

export default SelectRescheuleReason