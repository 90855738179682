import React, { useEffect, useState } from 'react';
import { Link ,useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../sagas/sagaActions';
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer';
import _ from 'lodash';
import CustomDropDown from '../Common/CustomDropDown/CustomDropDown';
import './Navigationbar.css';
import { nav } from 'aws-amplify';
/**
 * This component render PRIMARY navigation bar
 * @param {*} props 
 * @returns 
 */
const Navigationbar = (props) => {

    const { themeSelected } = props;
    const userPermission = useSelector(getUserSelector);
    const dispatch = useDispatch()
    const { navigationBarList } = userPermission;
    const location = useLocation(); 

    /**useEffect for navigation bar list data */
    useEffect(() => {
        if (navigationBarList.length) {
            renderNavBar()
        }
    }, [navigationBarList])
    /** Click Handler function for Navigation bar */
    const clickHandler = (el) => {
        const { PageUrl, PageDesc } = el;
        if (PageDesc === 'Home' && PageUrl === '/warehousemanagement') {
            dispatch({ type: sagaActions.UPDATE_SELECTED_WAREHOUSE, payload: {} })
        }
        else if (PageDesc === 'Home' && PageUrl === '/') {
            dispatch({ type: sagaActions.RESET_USER_PERMISSIONS })
            dispatch({ type: sagaActions.PARENT_WAREHOUSE, payload: {} })
        }
    }
    /** Handler function for  report selection from dropdown*/
    const onReportSelected = (event, obj, index) => {
        dispatch({ type: sagaActions.UPDATE_SELECTED_REPORT, payload: event })
    }
    /** function to return JSX for reports dropdown in navigation bar */
    const getReportsArrayDropDown = (obj, index, dataArr, placeHolder, onItemSelected) => {
        const accessibleReportsArr = dataArr.filter((reportObj, index) => {
            if(reportObj.Permission){
                return reportObj
            }
        })
        return <CustomDropDown
            themeSelected={'light-mode'}
            data={accessibleReportsArr}
            titleStyle={'dropDownNavBarTitle'}
            placeHolder={placeHolder}
            onSelect={(event) => onItemSelected(event, obj, index)}
            keyStr={'ElementAccess'}
            hideSelectedItem={true} />
    }
    /**
     * 
     * @returns JSX for PRIMARY navigation
     */
    const renderNavBar = () => {
        let navBarArr = navigationBarList.map((el, index) => {
            if (el.NavigationType === 'Primary' && el.PageAccess) {
                return (<li className={location.pathname===el.PageUrl?"d-nav nav-item px-1 mainNavigationBar d-nav-active":"d-nav nav-item px-1 mainNavigationBar"} key={el.PageDesc}>
                    <Link className="nav-link text-center" aria-current="page" to={el.PageUrl} onClick={() => clickHandler(el)}>
                        {el.PageName === "Reports" ?
                            getReportsArrayDropDown(el, index, el.featureRole, el.PageDesc, onReportSelected)
                            :
                            <div className='navText'>{el.PageDesc}</div>}

                    </Link>
                </li>)
            }
        })
        return _.compact(navBarArr)
    }

    return (

        <div className='setBorderTop setBorderBottom'>

            <nav className={`d-navbar navbar navbar-expand ${themeSelected}`}>
                <div className="container-fluid">
                    <div className="navbar-expand w-100" id="navbarNav">
                        <ul className="navbar-nav d-flex flex-row flex-wrap">
                            {
                                navigationBarList.length ? renderNavBar() : ''
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navigationbar;