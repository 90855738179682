import React, { useEffect, useState } from 'react'
import { sagaActions } from '../../../../../sagas/sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthReducer, isUserRememberedReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import { isValid } from '../../utils';
import "./Recover.css"
import { toast } from 'react-toastify';
import { showAlert, formatFirstCharBold } from '../../../../Assests/Utility';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import _ from 'lodash-es';
import InputWithTtileSS from '../../../Common/CustomInput/InputWithTtileSS';
import CustomButtonSS from '../../../Common/CustomButton/CustomButtonSS';
import TruckComponent from '../MainLogin/SchedulePro/MainLogin/Components/TruckComponent';
import { smartScheduleSelector } from '../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';

/**
 * This component is used to render new UI for forgot password which helps users to reset password in case he forgot password
 * @param {*} props 
 * @returns JSX
 */
const RecoverSS = (props) => {
    const dispatch = useDispatch();
    const { themeSelected } = props;
    const [email, setEmail] = useState("")
    const [emailError, setemailError] = useState(false)
    const [isloading, setisloading] = useState(false)
    const { passwordRecover, error } = useSelector(getAuthReducer);
    const navigate = useNavigate();
    const userPermission = useSelector(getUserSelector);
    const { branding } = userPermission;
    const ss = useSelector(smartScheduleSelector);
    const [bgImage, setBgImage] = useState('')
    const [warehouseLogo, setWarehouseLogo] = useState('')

     /**
     * Function to handle email change 
     * @param {*} emailId 
     * @returns 
     */
    const onEmailChange = (emailId) => {
        setEmail(emailId)
        if (isValid('email', emailId)) {
            return setemailError(false)
        }
        return setemailError(true)
    }

    /**
     * useEffect for successfull password reset, if verfication code is sent then redirect user to verfiy passsword
     */
    useEffect(() => {
        if (passwordRecover) {
            setisloading(false);
            showAlert(toast.TYPE.SUCCESS, AppConstant.notifications.login.recoverEmailSent, themeSelected);
            dispatch({ type: sagaActions.RECOVER_PASSWORD_SUCCESS })
            // redirect verifypassword screen
            navigate("/schedulepro/verifypassword");
        } else if (error) {
            setisloading(false);
            if (error && error.code === 'NotAuthorizedException') {
                showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.followInitialLoginStep);
                navigate('/')
            } else if (error?.message) {
                showAlert(toast.TYPE.ERROR, error?.message);
            }
            else {
                showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong);
            }
            dispatch({ type: sagaActions.CLEAR_SIGNIN_ERROR })
        }
    }, [passwordRecover, error])
     /** 
     * useEffect for branding data
     */
    useEffect(() => {
        if (!_.isEmpty(branding)) {
            const { IsBranding, WarehouseLogo, BackgroundImage } = branding
            if (IsBranding) {
                // if branding is true then use branding image and logo
                setBgImage(BackgroundImage)
                setWarehouseLogo(WarehouseLogo)
            }
        }
    }, [branding])

     // Recover password handler function
    const initiateRecoverPassword = () => {
        if (email) {
            setisloading(true)
            dispatch({ type: sagaActions.RECOVER_PASSWORD_INITIATED, payload: email })
        }
        else {
            showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.blankEmail);
        }
    }

    return (
        <div className='login-wrapper' data-testid='recover'>
            <div
                className="login-container-ss container-fluid d-flex justify-content-between p-4"
                id="container"
            >
              
                <div className="inner_blocks" id="right-block">
                    <div id="logo-holder">
                        <img src={AppConstant.commonStrings.schedulerProLogo} alt="FrieghtSmith logo" />
                    </div>
                    {/* -------------------- Form Section ------------------------*/}
                    <div className="form-section">
                        <div className='d-flex flex-column justify-content-start align-items-start'>
                            <div className=''>
                                <h5>{AppConstant.login.recover.t1}</h5>
                                <p id="login-sub-header m-0">{AppConstant.login.recover.t2}</p>
                            </div>
                            <div className='w-100 my-3'>
                                <InputWithTtileSS
                                    errorMessage={AppConstant.login.emailIncorrect}
                                    showError={emailError}
                                    value={email ? email : ''}
                                    title={AppConstant.login.email} placeHolder={AppConstant.login.enterEmail}
                                    onEmailChange={onEmailChange}
                                    handleErrors={()=>{}}
                                    required={true}
                                    titleIcon={true}
                                    titleIconClass={'fa-regular fa-envelope'}
                                    customClass={'customTextInput'}
                                />
                                <div className="d-flex justify-content-between w-100 my-3">
                                    <CustomButtonSS
                                        isLoading={isloading}
                                        title={AppConstant.commonStrings.submit} className={"btn-submit"}
                                        onClick={() => initiateRecoverPassword()}
                                    />
                                </div>
                                <div className="fs-10 d-flex flex-column justify-content-center align-items-center p-0 mt-2">
                                    <p>{AppConstant.login.recover.tryLoginText}</p>
                                    <span>
                                        <a
                                            href="/"
                                            style={{ color: "#10C6F5" }}
                                            className="mx-1 fw-semibold"
                                        >
                                            {AppConstant.login.recover.tryLoginButtonTitle}
                                        </a>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TruckComponent />
            </div>
        </div>

    )
}

export default RecoverSS;
