import React, { useState } from 'react';
import SearchPODropdown from './SearchPOComponents/SearchPODropDown/SearchPODropdown';
import SearchPOTextInput from './SearchPOComponents/SearchPOTextInput/SearchPOTextInput';
import { AppConstant } from '../../../../Assests/AppConstant';
import './SearchPO.css';
import _ from 'lodash';

/**
 * This is a reusable component for Search PO
 * @param {*} props 
 * @returns 
 */
const SearchPO = (props) => {
    const { themeSelected } = props
    const appConstPath = AppConstant.poManagement.searchPOSection;
    const [categorySelected, setCategorySelected] = useState(appConstPath.poTitleStr);
    // const {permissions} = useSelector(getUserSelector)
    let allowPermission = false

    // useEffect(() => {
    //     if(permissions.length){
    //         allowPermission = shouldHavePermission(permissions, 'Po-Search')
    //     }
    // pointer-event-none

    // },[])

    return (
        <div className={`${!allowPermission ? '' : ''}`}>
            <div className={`d-flex flex-row mx-2 ${!allowPermission ? '' : ''} ${themeSelected}`}>
                <SearchPODropdown  {...props}
                    selectDropDown={(categorySelected) => setCategorySelected(categorySelected)} />
                <SearchPOTextInput {...props} categorySelected={categorySelected} />
            </div>
        </div>
    )
}

export default SearchPO;