import React from 'react'
import './ModalSS.css'


const ModalSS = ({ toggleModal, children }) => {
    return (
        <div className='modal-overlay '>

            <div className='modalSS-body-ss popout'>
                <button className='close-btn-modal p-3' onClick={toggleModal} >
                    <span className='font-default'>
                    <i className="fa-solid fa-xmark fa-lg" data-testid='modal-close'></i>
                    </span>
                    </button>
                <div>
                    {children}
                </div>
            </div>

        </div>
    )
}
export default ModalSS
