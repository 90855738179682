import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { removeInterceptor } from '../../../../../redux/api/Interceptor';
import { useDispatch } from 'react-redux';
import Popup from '../../../Common/Popup/Popup';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import DefaultProfilePic from '../../../../Assests/Images/defaultProfilePic.png';
import './ProfileMenu.css'
import { toast } from 'react-toastify';
import _ from 'lodash';
import { shouldHavePermission, showAlert } from '../../../../Assests/Utility';
import { getCurrentDate } from '../../../../../Utils/DateUtils';


const ProfileMenu = (props) => {
    const { onThemechange, themeSelected } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const user = useSelector(getUserSelector);
    const { userAccountDetails, permissions, userType } = user;
    const { FirstName, ProfilePicture } = userAccountDetails;
    const [isUserSubscribed, setIsUserSubscribed] = useState(true);
    const [allowPermission, setAllowPermission] = useState(false);
    /**
     * Initial useEffect
     */
    useEffect(() => {
        if (permissions.length) {
            const warehouseManagementPermission = _.find(permissions, { 'FeatureID': '8', 'PageAccess': true });
            if (warehouseManagementPermission && !_.isEmpty(warehouseManagementPermission)) {
                setAllowPermission(true)
            } else {
                let temp = shouldHavePermission(permissions, '957');
                setAllowPermission(temp)
            }
        }

    }, [])

    /**
     * use 
     */
    useEffect(() => {
        if (userType === AppConstant.userPermission.usertypes.carrier && userAccountDetails.SubscriptionStatus === "INACTIVE") {
            setIsUserSubscribed(false)
        }
    }, [isUserSubscribed, userAccountDetails])

    const clickHandler = (type) => {
        switch (type) {
            case AppConstant.settings.dropdownMenu.theme:
                onThemechange()
                break;
            default:
                break;
        }

    }

    const secondaryNavBar = (el) => {
        const { PageUrl, PageDesc } = el;
        if (PageDesc === 'Home' && PageUrl === '/warehousemanagement') {
            dispatch({ type: sagaActions.UPDATE_SELECTED_WAREHOUSE, payload: {} })
        }
        else if (PageDesc === 'Home' && PageUrl === '/') {
            dispatch({ type: sagaActions.RESET_USER_PERMISSIONS })
            dispatch({ type: sagaActions.PARENT_WAREHOUSE, payload: {} })
        }
    }

    const logoutPopupHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            removeInterceptor()
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
            dispatch({ type: sagaActions.RESET_USER })
            showAlert(toast.TYPE.INFO, AppConstant.login.logout, 'light')
            window.localStorage.clear();
            navigate("/");
            const dateRangeObjTemp = {
                'start': '',
                'end': ''
            }
            dispatch({ type: sagaActions.UPDATE_DATE_RANGE, payload: dateRangeObjTemp })
            // If logging out from UserWarehouse Page then trigger branding API again
            if (location.pathname === '/') {
                dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: true })
                const subdomain = window.location.hostname.split(".")[0];
                dispatch({
                    type: sagaActions.INITIATE_BRANDING, payload: {
                        subdomain: subdomain
                    }
                });
            }

        }
    }
    const [showModal, setShowModal] = useState(false);
    const closepopup = () => {
        setShowModal(false);
    }

    const showLogoutPopup = () => {
        return <Popup {...AppConstant.settings.logoutPopupAttr} popupBtnHandler={(text) => {
            setShowModal(false);
            logoutPopupHandler(text)
        }} themeSelected={themeSelected} showModal={showModal} closepopup={closepopup} />
    }

    const getSecondaryNavigationList = () => {

        const secondaryNavTemp = permissions.map((el) => {
            if (el.NavigationType === 'Secondary' && el.PageAccess) {
                if (isUserSubscribed) {
                    return (<li className="" key={el.PageDesc}>
                        <Link className="dropdown-item m-0 p-0 " aria-current="page" to={el.PageUrl} onClick={() => clickHandler(el)}>
                            <li><a className="dropdown-item secondaryNavigationLink" href="#">{el.PageName}</a></li>
                        </Link>
                    </li>)
                }

            }
        })
        return _.compact(secondaryNavTemp)
    }

    return (<>
        <div className='text-capitalize'>{FirstName}</div>
        <div className='dropdown header-dropdown'>
            <button className="btn p-1" type="button" id="dropdownProfile" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span>
                    <img src={ProfilePicture ? ProfilePicture : DefaultProfilePic} className='profile-pic-logo' id="output" width="60" />
                </span>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownProfile">
                {permissions && permissions.length ? getSecondaryNavigationList() : <div />}
                <li onClick={() => clickHandler(AppConstant.settings.dropdownMenu.theme)}><a className="dropdown-item" href="#">{AppConstant.settings.dropdownMenu.theme}</a></li>
                {/* {isUserSubscribed && allowPermission && <li onClick={() => clickHandler(AppConstant.settings.dropdownMenu.accountSettings)}><Link className="dropdown-item" to="/settings" style={{ color: 'inherit', textDecoration: 'inherit' }}>{AppConstant.settings.dropdownMenu.accountSettings}</Link></li>} */}
                {<li data-bs-toggle="modal" data-bs-target={`#logoutPopup`} onClick={() => { setShowModal(true); clickHandler(AppConstant.settings.dropdownMenu.logout) }}><a className="dropdown-item" href="#">{AppConstant.settings.dropdownMenu.logout}</a></li>}
            </ul>
            <div />

        </div>
        {showLogoutPopup()}
    </>
    )
}

export default ProfileMenu;