import React from 'react';
import {ReactComponent as ProfileIcon} from '../../../../../../Assests/Svg/user-profile-icon.svg';
import './CommentRow.css';

const CommentRow = (props) => {
    const {name, comment, date, themeSelected} = props
    return(
        <tr className= {`commentRow d-flex container-fluid  mx-0 my-0 p-2  ${themeSelected} `} colSpan='1'>
            <td className='commentRow container-fluid m-0 p-0'>
                <span className='commentRow d-flex justify-content-start align-items-center'>
                    <ProfileIcon className='profileIcon' />
                    <div className='w-100 bg-transparent'>
                        <span className='bg-transparent d-flex justify-content-between'>
                            <p className={`nameText  ${themeSelected} ms-2 m-0 p-0`}>{name}</p>
                            <p className={`dateText ${themeSelected} ms-2 m-0 p-0`}>{date}</p>
                        </span>
                        <p className={`commentText ${themeSelected} ms-2 m-0 p-0`}>{comment}</p>
                    </div>
                </span>
            </td>
        </tr>
        );
}

export default CommentRow;