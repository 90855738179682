import React, { useState } from 'react'
import './AccountsSettingsModalSS.css'
import ModalSS from '../Common/ModalSS/ModalSS'
import { AppConstant } from '../../../../../Assests/AppConstant'
import UserInformationSS from './Components/UserInformationSS'
import SettingsChangePasswordSS from './Components/SettingsChangePasswordSS'
function AccountsSettingsModalSS(props) {
    const { toggleModal } = props;
    const [tab, setTab] = useState(AppConstant.settings.accountSettings.personalInformation);
    return (
        <ModalSS toggleModal={toggleModal}>
            <div className='d-flex acc-settings-wrapper'>
                <div className='acc-settings-nav'>
                    <p><i className="fa-solid fa-user-gear"></i> {AppConstant.settings.dropdownMenu.accountSettings}</p>
                    <ul className='nav-list mt-3'>
                        <li className={tab === AppConstant.settings.accountSettings.personalInformation ? "nav-list-selected" : ''} onClick={() => setTab(AppConstant.settings.accountSettings.personalInformation)}>
                            {AppConstant.settings.accountSettings.personalInformation}
                        </li>
                        <li className={tab === AppConstant.settings.accountSettings.security ? "nav-list-selected" : ''} onClick={() => setTab(AppConstant.settings.accountSettings.security)}>
                            {AppConstant.settings.accountSettings.security}
                        </li>
                    </ul>
                </div>
                {tab === AppConstant.settings.accountSettings.personalInformation &&
                    <UserInformationSS {...props} setTab={setTab} />
                }
                {tab === AppConstant.settings.accountSettings.security &&
                    <SettingsChangePasswordSS {...props} />
                }
            </div>
        </ModalSS>
    )
}

export default AccountsSettingsModalSS