import { Fragment, useEffect, useState } from "react";
import enUS from "date-fns/locale/en-US";
import {
  startOfWeek,
  addDays,
  format,
  eachMinuteOfInterval,
  isToday,
  endOfDay,
  startOfDay,
  addMinutes,
  set,
} from "date-fns";

import { calcCellHeight, calcMinuteHeight, convertToJSDate } from "../helpers/genrrals";
import TodayTypo from "./TodayType";
import Cell from "./Cell";
import TodayEvents from "./TodayEvents";
import _ from "lodash"
import moment from "moment";
import { AppConstant } from "../../../../Assests/AppConstant";
import Hour from "./Hour";

const Week = ({dateSelected,events,startHour=0,endHour=24,breakList,showScheduleModal,setShowScheduleModal,selectedDock,leaveList}) => {
  const [dockLeavesList,setDockLeavesList] = useState([]);
  const direction = "ltr";
  const height = 600;
  const locale = enUS;
  const selectedDate = new Date(dateSelected);
  const weekStartOn = 0;
  const weekDays = [0, 1, 2, 3, 4, 5, 6];
  const hourFormat = "12";
  const step = 60;
  const _weekStart = startOfWeek(selectedDate, { weekStartsOn: weekStartOn });
  const daysList = weekDays.map((d) => addDays(_weekStart, d));
  const weekStart = startOfDay(daysList[0]);
  const weekEnd = endOfDay(daysList[daysList.length - 1]);
  const START_TIME = set(selectedDate, {
    hours: startHour,
    minutes: 0,
    seconds: 0,
  });
  const END_TIME = set(selectedDate, {
    hours: endHour,
    minutes: -step,
    seconds: 0,
  });
  
  const hours = eachMinuteOfInterval(
    {
      start: START_TIME,
      end: END_TIME,
    },
    { step }
  );
  const CELL_HEIGHT = calcCellHeight(height, hours.length);
  const MINUTE_HEIGHT = calcMinuteHeight(CELL_HEIGHT, step);
  const hFormat = hourFormat === "12" ? "hh:mm a" : "HH:mm";

  /**
   * Function to get non operational day
   * @param {*} date 
   * @returns 
   */
  const getNonOperationalDay=(date)=>{
    let isNonOperational=false
    const todaysDay=format(date, "eeee", { locale }).toLowerCase().toString();
    if (selectedDock && selectedDock.length && selectedDock[0].dockId!=="0000") {
        if (!selectedDock[0][todaysDay]) {
          isNonOperational=true
        }else{
          isNonOperational=false
        }
    }
    else{
      isNonOperational=false
    }
    return isNonOperational
  }

  /**
   * useEffect to filter leaves based on selected dock
   */
  useEffect(()=>{
    if (selectedDock && leaveList.length) {
      const dockLeaves=leaveList.filter((el)=>el.IDDock===selectedDock[0].dockId && el.Status)
      setDockLeavesList(dockLeaves)
    }else{
      setDockLeavesList([])
    }
   
  },[selectedDock,leaveList])

  /**
   * Function to get leave based on date and dock selected
   * @param {*} date 
   * @returns 
   */
  const getIsClosedToday=(date) => {
    let finalLeave=[]
    if (dockLeavesList.length) {
    finalLeave=dockLeavesList.filter((el)=>convertToJSDate(moment(el.LeaveStart).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss)).getTime()===date.getTime() || convertToJSDate(moment(el.LeaveEnd).utc().format(AppConstant.commonStrings.yyyy_mm_dd_HH_MM_ss)).getTime()===date.getTime())
    }
    return finalLeave
  }

    return (

      <>
        <div className="mt-3 overflow-y week-scroll" style={{maxHeight:"75vh"}}>
        <div className="TableGrid sticky-element"
        >
          <span className="rs__cell rs__time"></span>
          {daysList.map((date, i) => {
            
            return(
            <span
              key={i}
              className={`rs__cell rs__header ${isToday(date) ? "rs__today_cell" : ""} ${getNonOperationalDay(date) && 'back-closed'} ${getIsClosedToday(date).length && 'bgRed'}`}
              style={{ height: "60px" }}
            >           
                <TodayTypo
                isNonOperational={getNonOperationalDay(date)}
                isClosedToday={getIsClosedToday(date)}
                  date={date}
                  locale={locale}
                />
            </span>
          )})}
        </div>
        <div className="TableGrid">
          {hours.map((h, i) => (
            <Fragment key={i}>
              <span className={`rs__cell `}>
              <Hour
              CELL_HEIGHT={CELL_HEIGHT}
              h={h}
              hFormat={hFormat}
              locale={locale}
              today={new Date()}
              minuteHeight={MINUTE_HEIGHT}
              startHour={startHour}
              step={step}
              breakList={breakList}
              selectedDate={selectedDate}
              showScheduleModal={showScheduleModal}
              setShowScheduleModal={setShowScheduleModal}
              />
              </span>
              {daysList.map((date, ii) => {
                const start = new Date(`${format(date, "yyyy/MM/dd")} ${format(h, hFormat)}`);
                const end = addMinutes(start, step);
                return (
                  <span key={ii} className={`rs__cell ${isToday(date) ? "rs__today_cell" : ""}`}>
                    {i === 0 && (
                      <div className="d-flex">
                      <TodayEvents
                        todayEvents={events}
                        today={date}
                        minuteHeight={MINUTE_HEIGHT}
                        startHour={startHour}
                        step={step}
                      />
                       {/* <TodayEvents
                        todayEvents={events}
                        today={date}
                        minuteHeight={MINUTE_HEIGHT+200}
                        startHour={startHour}
                        step={step}
                      /> */}
                      </div>
                    )}
                    <Cell
                      start={start}
                      end={end}
                      day={date}
                      height={CELL_HEIGHT}
                      editable={true}
                     showScheduleModal={showScheduleModal}
                     setShowScheduleModal={setShowScheduleModal}
                    />
                  </span>
                );
              })}
            </Fragment>
          ))}
        </div>
        </div>
      </>
    );
  };



export default Week ;
