import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import './DrilldownTableSS.css'

const DrilldownTableHeaderSS = (props) => {
    const { themeSelected, data, showInput, showSort, showCollapse, arrangeOrder, drillDownReport, subRoot, sortingAction ,isPaginated} = props;
    const dispatch = useDispatch();
    const [checkAll, setCheckAll] = useState(false);

    const titleClickHandler = e => {
        const getID = e.target.id ? e.target.id : e.target.closest('th').id
        let arrange = {
            key: getID
        }
        if (getID !== 'checkAll') {
            if (arrangeOrder === 'asc') {
                arrange.order = 'desc'
            } else {
                arrange.order = 'asc'
            }
        }

        const key = dataToBeUpdated()
        const newdata = _.orderBy(drillDownReport, [arrange.key], [arrange.order]);
        const data = {
            drillDownReport: newdata,
            arrange,
            key,
            isPaginated
        }

        dispatch({ type: sortingAction, payload: data })
        // if(sortingAction === sagaActions.SORT_USER_TABLE){
        //     dispatch({ type: sagaActions.SORT_USER_TABLE, payload: data })
        // }else{

        //     dispatch({ type: sagaActions.SORT_DRILLDOWN_REPORT, payload: data })
        // }
    }

    const dataToBeUpdated = () => {
        const { unloadinghours, loadstatus, appointmentcompliance, detentionstatus, unloadingTypeSummary,
            doorUsageSummary, doorstatus, top10LateLoad, unscheduledpo, user, userAssign, superAdmin,
            companyGroup, companyList, faqAdminList, feedbackAdminList, issuesAdminList, warehouseAdminList, poSSList,
            whMetaDocksObj, whMetaLeavesObj, whMetaBreaksObj, whMetaTimeslotObj, allApptsListObj,poSSResponse, fsPayPOHistory } = AppConstant.allRoutes;
        switch (subRoot.pathName) {
            case unloadinghours.path:
                return unloadinghours.storeObject
            case loadstatus.path:
                return loadstatus.storeObject
            case appointmentcompliance.path:
                return appointmentcompliance.storeObject
            case detentionstatus.path:
                return detentionstatus.storeObject
            case unloadingTypeSummary.path:
                return unloadingTypeSummary.storeObject
            case doorUsageSummary.path:
                return doorUsageSummary.storeObject
            case doorstatus.path:
                return doorstatus.storeObject
            case top10LateLoad.path:
                return top10LateLoad.storeObject
            case unscheduledpo.path:
                return unscheduledpo.storeObject
            case user.path:
                return user.storeObject
            case userAssign.path:
                return userAssign.storeObject
            case superAdmin.path:
                return superAdmin.storeObject
            case companyGroup.path:
                return companyGroup.storeObject
            case companyList.path:
                return companyList.storeObject
            case faqAdminList.path:
                return faqAdminList.storeObject
            case feedbackAdminList.path:
                return feedbackAdminList.storeObject
            case issuesAdminList.path:
                return issuesAdminList.storeObject
            case warehouseAdminList.path:
                return warehouseAdminList.storeObject
            case poSSList.path:
                return poSSList.storeObject
            case whMetaDocksObj.path:
                return whMetaDocksObj.storeObject
            case whMetaLeavesObj.path:
                return whMetaLeavesObj.storeObject
            case whMetaBreaksObj.path:
                return whMetaBreaksObj.storeObject
            case whMetaTimeslotObj.path:
                return whMetaTimeslotObj.storeObject
            case allApptsListObj.path:
                return allApptsListObj.storeObject
            case poSSResponse.path:
                return poSSResponse.storeObject
            case fsPayPOHistory.path:
                return fsPayPOHistory.storeObject
            default:
                break;
        }
    }
    return (

        <thead>
            <tr className={`text-center align-middle ${themeSelected} truck-list-title-ss`} onClick={titleClickHandler} >
                {showCollapse && <th scope="col" className={`col px-0 ${themeSelected}`}></th>}
                {
                    data.length && data.map(el => {
                        return (
                            <th scope="col" className={`col px-0 ${themeSelected} title-text-container f-12`} id={el.id} key={el.id}>
                                <span>{el.name}</span>
                                {showSort && <span className='font-default'> <i className="fa-solid fa-sort"></i></span>}
                            </th>
                        )
                    })
                }
            </tr>
        </thead>

    );
}

export default DrilldownTableHeaderSS;