import React from 'react';
import { AppConstant } from '../../../../../../Assests/AppConstant';

import './POHeader.css';

const POHeader = () => {
    return ( 
        <div className='text-start ps-2 loadPOHeading' data-testid={AppConstant.dataTestId.poApprovalHeaderText}>
            {AppConstant.poManagement.poApproval.poHeader}
        </div>
     );
}
 
export default POHeader;