import React, { useState, useEffect } from 'react';
import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { showAlert } from '../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { getSuperAdminSelector } from '../../../../../../../../redux/reducers/reducerSlices/SuperAdminReducerSlice';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import RoleFeaturesHead from '../RoleFeaturesHead/RoleFeaturesHead';
import RoleFeaturesRow from '../RoleFeaturesRow/RoleFeaturesRow';
import CustomButton from '../../../../../../Common/CustomButton/CustomButton';
import Loader from '../../../../../../Loader/Loader';
import { Link } from 'react-router-dom';
import { ReactComponent as NoDataLightMode } from '../../../../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../../../../Assests/Svg/NoDataLightMode.svg';
import './RoleFeaturesList.css';

const RoleFeaturesListPage = (props) => {

    const dispatch = useDispatch();
    const { themeSelected, data } = props;
    const [roleFeaturesArr, setRoleFeaturesArr] = useState([]);
    const superAdminSagaObj = useSelector(getSuperAdminSelector);
    const { roleFeaturesList, assignFeaturesResponse } = superAdminSagaObj;
    const [editedRoleFeaturesArray, setEditedRoleFeaturesArray] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedDropDownNavigation, setSelectedDropDownNavigation] = useState({});
    const user = useSelector(getUserSelector);
    const { navigationList } = user;

    useEffect(() => {
        setRoleFeaturesArr([])
        if(roleFeaturesList.length>0){
            setRoleFeaturesArr(roleFeaturesList);
        }
      
    }, [roleFeaturesList])

    useEffect(() => {
        setIsUpdating(false)
        if (assignFeaturesResponse && assignFeaturesResponse.length && assignFeaturesResponse[0].RESULT === 'ERROR IN PERMISSION UPDATE') {
            setEditedRoleFeaturesArray([])
        }
    }, [assignFeaturesResponse])

    useEffect(() => {
        getRolesFeatureList();
        getNavigationApi();
    }, [])

    const getRolesFeatureList = () => {
        const params = {
            "role_id": data && data.props && data.props.RoleID,
        }
        dispatch({ type: sagaActions.GET_ROLES_FEATURE_LIST, payload: params });
    }

    const getNavigationApi = () => {
        dispatch({ type: sagaActions.UPDATE_NAVIGATION_API, payload: {} });
    }

    const onChangeAccess = (featureObj, onChangeAccess, rowIndex, featureIndex, navigationEvent) => {
       // setRoleFeaturesArr([])
        let tempArr = JSON.parse(JSON.stringify(roleFeaturesArr));
        const params = {
            "role_id": data.props.RoleID,
            "feature_id": '',
            "feature_page_access": "",
            "role_feature_id": '',
            "role_feature_permission": '',
            "element_access": '',
            "crud_type": "U",
            "navigation_type": featureObj.NavigationType
        }
        if (onChangeAccess === 'pageAccess') {
            const tempObj = { ...tempArr[rowIndex] };
            tempObj.PageAccess = !tempArr[rowIndex].PageAccess;
            tempArr[rowIndex] = tempObj;
            tempArr[rowIndex].featureRole.map((obj) => {
                obj.Permission = false
            })
        } else if (onChangeAccess === 'featureAccess') {
            tempArr[rowIndex].featureRole[featureIndex].Permission = !tempArr[rowIndex].featureRole[featureIndex].Permission
        } else if (onChangeAccess === 'navigationEvent') {
            tempArr[rowIndex].NavigationType = navigationEvent
        }

        params.feature_id = tempArr[rowIndex].FeatureID;
        params.feature_page_access = tempArr[rowIndex].PageAccess;

        if (onChangeAccess === 'featureAccess') {
            params.role_feature_id = tempArr[rowIndex].featureRole[featureIndex].RoleFeatureID
            params.role_feature_permission = tempArr[rowIndex].featureRole[featureIndex].Permission;
            params.element_access = tempArr[rowIndex].featureRole[featureIndex].ElementAccess;
        } else if (onChangeAccess === 'pageAccess') {
            params.element_access = tempArr[rowIndex].PageName;
        } else if (onChangeAccess === 'navigationEvent') {
            params.navigation_type = navigationEvent;
        }
        const prevArr = editedRoleFeaturesArray.filter((paramsObj, index) => {
            if (paramsObj.feature_id === params.feature_id && paramsObj.role_feature_id === params.role_feature_id && paramsObj.NavigationType === params.navigation_type) {
                return null;
            } else {
                return paramsObj;
            }
        })

        const compactArr = _.compact(prevArr);
        const selectedFeaturesArr = [...compactArr, params];
        setEditedRoleFeaturesArray(selectedFeaturesArr);
        setTimeout(() => {
            setRoleFeaturesArr([...tempArr]);
        }, 10)
    }

    const updateRolesFeaturesList = () => {
        if (editedRoleFeaturesArray.length) {
            setIsUpdating(true)
            dispatch({ type: sagaActions.ASSIGN_ROLES_FEATURE_LIST, payload: editedRoleFeaturesArray });
            setEditedRoleFeaturesArray([]);
        } else {
            showAlert(toast.TYPE.ERROR, AppConstant.superUserAdmin.rolesFeaturesAdmin.toast.noDataSelected, 'light')
        }
    }

    const renderTableRows = () => {
        return roleFeaturesArr.map((ele, rowIndex) => {
            return (<RoleFeaturesRow
                key={ele + rowIndex}
                rowData={ele}
                rowIndex={rowIndex}
                navigationDropDownList={navigationList}
                selectedDropdownValue={selectedDropDownNavigation}
                onChange={(featureObj, accessParam, rowIndex, featureIndex, navigationEvent) => onChangeAccess(featureObj, accessParam, rowIndex, featureIndex, navigationEvent)}
            />)
        })
    }

    const showLoader = () => {
        return (
            <div>
                <Loader height={70} count={10} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
                    baseColor={themeSelected === 'dark-mode' ? '#575757' : ''}
                />
            </div>
        )
    }

    return (
        <div className='mx-4 mt-2'>
            <span className='d-flex justify-content-between align-items-center'>
                <span>
                    <Link to="/roles" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <span><i className='mx-3 fa fa-arrow-left'></i></span>
                    </Link>
                    <span className="titleHeader pt-3">{data && data.props && data.props.RoleName}</span>
                </span>

                <span>
                    <CustomButton
                        title={'Submit'}
                        className={'p-1 px-3 mx-5 ms-3 addButton'}
                        isLoading={false}
                        disabled={false}
                        loadingTitle={''}
                        onClick={() => updateRolesFeaturesList()}
                    />
                </span>
            </span>
            {
                !isUpdating ?
                    roleFeaturesList && roleFeaturesList.length ?
                        <div className='container-fluid roleFeaturesTable'>
                            <table className={`table table-hover ${themeSelected} m-1 mt-3 p-0 tableContainer`}>
                                <RoleFeaturesHead />
                                {renderTableRows()}
                            </table>
                        </div>
                        :
                        <div className='d-flex flex-column align-items-center noDataAvailable'>
                            <span className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</span>
                            <span className={`no-data-text1 ${themeSelected}`}>{AppConstant.poManagement.emptyTable.text1}</span>
                        </div>
                    :
                    showLoader()
            }
        </div>
    )

}

export default RoleFeaturesListPage;