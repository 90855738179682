import {Uchain} from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { addSuccess, addFailure,initiateLoading,resetData } from '../redux/reducers/reducerSlices/GetLumpingFaciltyReducer';
  function* getLumpingFaciltyListRequestHandler(data){
    try{
      yield put(initiateLoading());
     const response = yield call(Uchain.getLumpingFacilityDetails);
      yield put(addSuccess(response));
    }catch(error){
     yield put(addFailure(error));
    }
}
export function*  GetLumpingFaciltyListRequest() {
  yield takeEvery(sagaActions.GET_LUMPING_FACILTY_DATA, getLumpingFaciltyListRequestHandler)
}



