import { filter, isEmpty } from "lodash-es"
import { AppConstant } from "../../../../../../Assests/AppConstant"

const formPOApproveData = (payloadData, poData) => payloadData.filter(e => e !== poData)

const shouldSelectPO = (data) => {
    if (data && data.status === "Approved") {
        return true
    }
    return null
}

const shouldDisableApproveAndReschedule = (selectedLoad,dockSelected) => {
    const unscheduled_pos = filter(selectedLoad.unscheduled_pos, (el) => el.status === AppConstant.poStatus.PENDING)
    return isEmpty(unscheduled_pos) || (isEmpty(selectedLoad.dock_id) && isEmpty(dockSelected))
}

export { formPOApproveData, shouldSelectPO, shouldDisableApproveAndReschedule }