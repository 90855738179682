import  React ,{ useState } from "react";
import {
    format,
    subMonths,
    addMonths,
    startOfWeek,
    addDays,
    isSameDay,
    lastDayOfWeek,
    getWeek,
    addWeeks,
    subWeeks
} from "date-fns";
import './WeekCalenderSS.css'

const WeekCalenderSS = ({ selectedDate, setSelectedDate, addSelectedDate }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
    // const [selectedDate, setSelectedDate] = useState(new Date());

    const changeMonthHandle = (btnType) => {
        if (btnType === "prev") {
            setCurrentMonth(subMonths(currentMonth, 1));
        }
        if (btnType === "next") {
            setCurrentMonth(addMonths(currentMonth, 1));
        }
    };

    const changeWeekHandle = (btnType) => {
        //console.log("current week", currentWeek);
        if (btnType === "prev") {
            //console.log(subWeeks(currentMonth, 1));
            setCurrentMonth(subWeeks(currentMonth, 1));
            setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
        }
        if (btnType === "next") {
            //console.log(addWeeks(currentMonth, 1));
            setCurrentMonth(addWeeks(currentMonth, 1));
            setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
        }
    };

    const onDateClickHandle = (day, dayStr) => {
        // setSelectedDate(day);
        addSelectedDate(day)
        // showDetailsHandle(dayStr);
    };

    const renderHeader = () => {
        const dateFormat = "MMM yyyy";
        return (
            <div className="week-picker-header">
                <div
                    className="week-cal-navigation"
                    onClick={() => changeMonthHandle("prev")}
                >
                    <i className="fa-solid fa-angle-left fa-sm"></i>
                </div>
                <div className="">
                    <span>{format(currentMonth, dateFormat)}</span>
                </div>

                <div
                    className="week-cal-navigation"
                    onClick={() => changeMonthHandle("next")}
                >
                    <i className="fa-solid fa-angle-right fa-sm"></i>
                </div>
            </div>
        );
    };

    const renderCells = () => {
        const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
        const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
        const dateFormat = "d";
        const dateFormatweek = "EEE";
        let startDateWeek = startOfWeek(currentMonth, { weekStartsOn: 0 });
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={
                            isSameDay(day, selectedDate) ? "week-day-selected" : "week-day"
                        }
                        key={day}
                        onClick={() => {
                            const dayStr = format(cloneDay, "ccc dd MMM yy");
                            onDateClickHandle(cloneDay, dayStr);
                        }}
                    >
                        <div className="week-day-name">
                            {" "}
                            {format(addDays(startDateWeek, i), dateFormatweek)}
                        </div>
                        <div className="week-day-number">{formattedDate}</div>
                    </div>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <div className="week-date-row" key={day}>
                    <button
                        className="week-cal-navigation"
                        onClick={() => changeWeekHandle("prev")}
                    >
                        <i className="fa-solid fa-angle-left fa-sm"></i>
                    </button>
                    {days}
                    <button
                        className="week-cal-navigation"
                        onClick={() => changeWeekHandle("next")}
                    >
                        <i className="fa-solid fa-angle-right fa-sm"></i>
                    </button>
                </div>
            );
            days = [];
        }
        return <div className="cal-data-wrapper">{rows}</div>;
    };

    return (
        <div className="week-calendar">
            {renderHeader()}

            <div className="week-cal-data">{renderCells()}</div>
        </div>
    );
};

export default WeekCalenderSS;

