import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TruckList from './TruckList/TruckList';
import TruckListTitle from './TruckListTitle/TruckListTitle';
import { getTruckSelector } from '../../../../../../redux/reducers/reducerSlices/getTruckReducer';

import "./TruckListSection.css";

const TruckListSection = (props) => {

    // const dispatch = useDispatch();
    const {themeSelected} = props;
    const getTruck = useSelector(getTruckSelector);
    const {truckList } = getTruck;
   
    /* const btnHandler = () => {
        let newPoData = {...getTruck}
            newPoData.currentPage = newPoData.currentPage + 1;
        dispatch({ type: sagaActions.INTAITE_GET_TRUCK, payload: newPoData })
        setTimeout(() => {
            dispatch({ type: sagaActions.LOAD_MORE_TRUCK_LIST })
        }, 1500);
    } */

   /*  const loadMoreHandler = () => {
        if(totalPages === currentPage){
            return null
        } else if(currentPage < totalPages) {
            return (
                <button className="btn btn-primary text-center" style={{ height: '35px', width: '50%', margin: '0 auto' }} onClick={btnHandler}>Load More</button>
            )
        }
        else if(currentPage > totalPages){
            let newPoData = {...getTruck}
            --newPoData.currentPage
            dispatch({ type: sagaActions.UPDATE_TRUCK, payload: newPoData })
            return null
        }
    } */

    return (
        <div className={truckList && truckList.length ? `pOListSection row` : `pOListSection-empty row`}>
            <table className={`table table-responsive table-hover ${themeSelected}`}>
                <TruckListTitle {...props}/>
                <TruckList {...props}/>
            </table>
            {/* <div className="row" style={{ height: '40px' }}>
                {!getListLoading ? loadMoreHandler() :
                    <button className="btn btn-primary text-center" style={{ height: '35px', width: '50%', margin: '0 auto' }} type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>}
            </div> */}
        </div>
    )
}

export default TruckListSection;