export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  
  export const dayNames = ["Su","Mo", "Tu", "We", "Th", "Fr", "Sa"];
  