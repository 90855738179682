import React from 'react'
import { Link } from "react-router-dom";
import { AppConstant } from '../../../Assests/AppConstant'


const ClickToResend = () => {
    return (
        <Link to="/recover">
        <button className='feedbackButton'>
            <span className='navText'>{AppConstant.login.resendCode}</span>
        </button>
        </Link>
    )
}

export default ClickToResend
