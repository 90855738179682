import React,{useEffect, useState} from 'react';
/**
 * This is a reusable component for updating div width/height
 * used in Custom dashboard and load status overview graph
 * @param {*} myRef 
 * @returns 
 */
export const useContainerDimensions = myRef => {
  /**
   * 
   * @returns width and height
   */
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })
  
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    /**
     * useEffect for ref(resizing)
     */
    useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
  };