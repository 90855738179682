import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  warehouseList: [],
  rolesList: [],
  roleFeaturesList: [],
  assignFeaturesResponse: [],
  initateLoading: false,
  arrangeOrder: 'asc',
  arrangeKey: 'CreatedOn',
  feedbackList: [],
  issuesList: [],
  faqList: [],
  closeRolesForm: true,
  requestsReceivedDropdownsList: {},
  openNotificationObj:{},
  error: false,
  isListLoading:false
}

export const SuperAdminReducerSlice = createSlice({
  name: 'superAdminReducerSlice',
  initialState,
  reducers: {
    getWarehouseList: (state, action) => {
      state.warehouseList = action.payload;
    },
    updateRolesList: (state, action) => {

      state.rolesList = action.payload;
    },
    updateRolesFeaturesList: (state, action) => {

      state.roleFeaturesList = action.payload;
    },
    assignFeaturesToRoles: (state, action) => {
      state.assignFeaturesResponse = action.payload;
    },
    updateInitiateLoader: (state, action) => {
      state.initateLoading = action.payload
    },
    updateIsListLoader: (state, action) => {
      state.isListLoading = action.payload
    },
    updateIssuesList: (state, action) => {
      state.issuesList = action.payload
    },
    updateFeedbackList: (state, action) => {
      state.feedbackList = action.payload
    },
    updateTableData: (state, action) => {
      const { drillDownReport, arrange, key } = action.payload;
      state[key] = drillDownReport;
      state.arrangeKey = arrange.key;
      state.arrangeOrder = arrange.order;
    },
    updateFaqList: (state, action) => {
      state.faqList = action.payload
    },
    update_rolesFormHandler: (state, action) => {
      state.closeRolesForm = action.payload;
    },
    updateRequestsReceivedDropdownsList: (state, action) => {
      state.requestsReceivedDropdownsList = action.payload;
    },
    updateOpenNotificationsList: (state, action) => {
      state.openNotificationObj = action.payload;
    },
    updateError: (state, action) => {
      state.error = action.payload;
    },
  }
});

export const { getWarehouseList, updateRolesList, updateRolesFeaturesList, assignFeaturesToRoles, 
  updateInitiateLoader, updateTableData, updateIssuesList, updateFeedbackList, updateFaqList, 
  update_rolesFormHandler, updateRequestsReceivedDropdownsList, updateOpenNotificationsList, updateError,updateIsListLoader } = SuperAdminReducerSlice.actions;

// Selectors
export const getSuperAdminSelector = (state) => {
  return state.superAdminReducerSlice;
};


export default SuperAdminReducerSlice.reducer;