import {Uchain} from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { addHelpSuccess, addHelpFailure,initiateLoading,resetData } from '../redux/reducers/reducerSlices/HelpAndFeedbackReducer';
  function* addHelpRequestHandler(data){
    try{
      yield put(initiateLoading());
      const response = yield call(Uchain.addHelpRequest, data.payload);
       yield put(addHelpSuccess(response));
    }catch(error){
     yield put(addHelpFailure(error));
    }
}
export function* addHelpRequest() {
  yield takeEvery(sagaActions.ADD_HELP, addHelpRequestHandler)
}

function* addFeedbackRequestHandler(data){ 
  try{
    yield put(initiateLoading());
    const response = yield call(Uchain.addFeedbackRequest, data.payload);
     yield put(addHelpSuccess(response));
  }catch(error){
   yield put(addHelpFailure(error));
  }
}
export function* addFeedbackRequest() {
  yield takeEvery(sagaActions.ADD_FEEDBACK, addFeedbackRequestHandler)
}

function* resetHelpFormHandler() {
 yield put(resetData())
 
}
export function* resetHelpFormRequest() {
  yield takeEvery(sagaActions.RESET_HELP_FEED, resetHelpFormHandler)
}


