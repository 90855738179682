import { Uchain } from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { driverCheckinSuccess, driverCheckinFailure, initiateLoading, resetData, sortTableData, editDriverReportLoader } from '../redux/reducers/reducerSlices/DriverCheckinReducer';
import { showAlert } from "../App/Assests/Utility";
import { toast } from "react-toastify";
function* driverCheckinHandler(data) {
  try {
    if (data.payload.CRUDTYPE === 'S' || data.payload.CRUDTYPE === 'SS') {
      yield put(initiateLoading());
    } else if (data.payload.CRUDTYPE === 'E') {
      // yield put(editDriverReportLoader())
    }
    const response = yield call(Uchain.driverCheckinApi, data.payload);
  
    if (data.payload.CRUDTYPE === 'E') {
      showAlert(toast.TYPE.SUCCESS, 'Record updated succesfully', 'light')
      const nPayload = {
        "CRUDTYPE": data.payload.reloadCRUD, "WhId": data.payload.WhId, limit: data.payload.limit, pageNo: data.payload.pageNo, "fromDate": data.payload.fromDate,
        "endDate": data.payload.endDate, "searchParam": data.payload.searchParamAcutal,
        trailerType:data.payload.trailerTypeAcutal,
        memo: data.payload.memoAcutal,
        "transType": data.payload.transTypeAcutal
      }
      const responsenew = yield call(Uchain.driverCheckinApi, nPayload);
      responsenew.CRUDTYPE = data.payload.CRUDTYPE;
      yield put(driverCheckinSuccess(responsenew));
    } else {
      response.CRUDTYPE = data.payload.CRUDTYPE;
      yield put(driverCheckinSuccess(response));
    }
  } catch (error) {
    yield put(driverCheckinFailure(error));
    if (data.payload.CRUDTYPE === 'E') {
      showAlert(toast.TYPE.ERROR, 'Record update unsuccesfull', 'light')
    }
  }
}

// export function* editDriverReportCheckSaga() {
//   yield takeEvery(sagaActions.DRIVER_CHECKIN, driverCheckinHandler)
// }

function* resetDriverCheckinDataHandler() {
  yield put(resetData())

}

function* sortDriverCheckSaga(data) {
  try {
    yield put(sortTableData(data.payload))
  } catch (error) {
  }
}


function* editDriverReportLoaderCheckSaga(data) {
  yield put(editDriverReportLoader(data.payload))
}

function* liveTruckReportSaga(data) {
  try {
    if (data.payload.CRUDTYPE === 'S') {
      yield put(initiateLoading());
    } else if (data.payload.CRUDTYPE === 'E') {
      // yield put(editDriverReportLoader())
    }
    const response = yield call(Uchain.liveTruckReport, data.payload);

    if (data.payload.CRUDTYPE === 'E') {
      showAlert(toast.TYPE.SUCCESS, 'Record updated succesfully', 'light')
      const nPayload = {
        "CRUDTYPE": "S", "WhId": data.payload.WhId, limit: data.payload.limit, pageNo: data.payload.pageNo, "fromDate": data.payload.fromDate,
        "endDate": data.payload.endDate, "searchParam": data.payload.searchParamAcutal,
        trailerType:data.payload.trailerTypeAcutal,
        memo: data.payload.memoAcutal,
        "transType": data.payload.transTypeAcutal
      }
      const responsenew = yield call(Uchain.liveTruckReport, nPayload);
      responsenew.CRUDTYPE = data.payload.CRUDTYPE;
      yield put(driverCheckinSuccess(responsenew));
    } else {
      response.CRUDTYPE = data.payload.CRUDTYPE;
      yield put(driverCheckinSuccess(response));
    }
  } catch (error) {
    yield put(driverCheckinFailure(error));
    if (data.payload.CRUDTYPE === 'E') {
      showAlert(toast.TYPE.ERROR, 'Record update unsuccesfull', 'light')
    }
  }
}

export function* driverCheckinRequest() {
  yield takeEvery(sagaActions.DRIVER_CHECKIN, driverCheckinHandler)
}

export function* resetDriverCheckinDataRequest() {
  yield takeEvery(sagaActions.RESET_DRIVER_CHECKIN, resetDriverCheckinDataHandler)
}

export function* sortDriverCheckWatcher() {
  yield takeEvery(sagaActions.SORT_DRIVER_ARRIVAL_TABLE, sortDriverCheckSaga)
}

export function* liveTruckReportWatcher() {
  yield takeEvery(sagaActions.DRIVER_LIVE_TRUCK, liveTruckReportSaga)
}

export function* editDriverReportLoaderCheckWatcher() {
  yield takeEvery(sagaActions.DRIVER_REPORT_EDIT_LOADER, editDriverReportLoaderCheckSaga)
}

export function* editDriverReportInOutCheckWatcher() {
  yield takeEvery(sagaActions.DRIVER_REPORT_EDIT_IN_OUT, driverCheckinHandler)
}
export function* editDriverReportLiveTruckCheckWatcher() {
  yield takeEvery(sagaActions.DRIVER_REPORT_EDIT_LIVE_TRUCK, liveTruckReportSaga)
}