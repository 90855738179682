import React from 'react';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { ReactComponent as Standard } from '../../../../../../../Assests/Svg/StandardDoor.svg';
import { ReactComponent as TallDoor } from '../../../../../../../Assests/Svg/TallDoor.svg';
import { ReactComponent as NoDoor } from '../../../../../../../Assests/Svg/NoDoor.svg';
import _ from 'lodash';
import './DoorAssignmentCard.css';

const DoorAssignmentCard = (props) => {
    const { themeSelected, className, door, door_status, door_type, clickHandler, type, selected, isDoorReleased } = props;
    const { api_door_status_available, api_door_status_occupied, api_door_status_assigned, display_door_status_available
        , display_door_status_occupied, display_door_status_assigned, api_door_status_in_use,
        api_door_status_inoperable, api_door_status_out_of_order,
        api_door_status_cleaning, api_door_status_available_in_use, display_door_status_inoperable,
        display_door_status_in_use, api_door_status_blocked, display_door_status_blocked,
        api_door_status_blocked_shipping_trailer, api_door_status_blocked_floor,
        api_door_status_blocked_backhaul_dropped, api_door_status_blocked_backhaul_dropped_in_use,
        api_door_status_not_available, display_door_status_not_available, api_door_status_empty, display_more_door_status_available
    } = AppConstant.doorManagement.assignDoorText
    
    const renderDoorStatus = (doorStatus) => {
        if(isDoorReleased){
            return 'Released'
        }
        switch (doorStatus) {
            case api_door_status_empty:
                return type === 'more_doors' ?  display_more_door_status_available : display_door_status_available;
            case api_door_status_available:
                return type === 'more_doors' ?  display_more_door_status_available : display_door_status_available;
            case api_door_status_occupied:
                return type === 'more_doors' ? display_door_status_in_use : display_door_status_occupied;
            case api_door_status_available_in_use:
                return display_door_status_in_use
            // case api_door_status__in_use:
            //     return display_door_status_in_use
            case api_door_status_in_use:
                return display_door_status_in_use
            case api_door_status_assigned:
                return display_door_status_assigned
            case api_door_status_inoperable:
                return display_door_status_inoperable;
            case api_door_status_out_of_order:
                return display_door_status_inoperable;
            case api_door_status_cleaning:
                return display_door_status_inoperable;
            case api_door_status_blocked:
                return display_door_status_blocked;
            case api_door_status_blocked_shipping_trailer:
                return display_door_status_blocked;
            case api_door_status_blocked_floor:
                return display_door_status_blocked;
            case api_door_status_blocked_backhaul_dropped:
                return display_door_status_blocked;
            case api_door_status_blocked_backhaul_dropped_in_use:
                return display_door_status_blocked;
            case api_door_status_not_available:
                return display_door_status_not_available;
            default:
                break;
        }
    }

    const renderDoorIcon = () => {
        const { standard, highCube, noDoorAvailable } = AppConstant.doorManagement.doorType;
        switch (_.upperCase(door_type)) {
            case standard:
                return <Standard />
            case highCube:
                return <TallDoor />
            case noDoorAvailable:
                return <NoDoor />
            default:
                return <Standard /> /* In case there is no door_type we default it to Standard */
        }
    }
    return (
        <div className={`door-card-wrapper d-flex flex-column justify-content-between align-items-start m-2 ${className} ${themeSelected} ${selected ? 'selected' : ''} `} onClick={clickHandler}>
            <div className='px-2'>{renderDoorIcon()}</div>
            <div className='px-2 doorNumber'>{door}</div>
            <div className='doorStatus w-100'>{renderDoorStatus(door_status)}</div>
        </div>
    );
}

export default DoorAssignmentCard;