import React from 'react'

const DownArrow = (props) => {
    const getStroke = () => {
        if (props.themeSelected === "light-mode") {
            return "#424242"
        }
        return "#F9F9F9"
    }
    return (
        <div>
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L3.97 6L0 0H8Z" fill={getStroke()} />
            </svg>
        </div>
    )
}

export default DownArrow
