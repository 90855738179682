import React, { useEffect, useState } from 'react';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getAuthReducer } from '../../../../../../../redux/reducers/reducerSlices/AuthReducer';
import { initiateSearch, formatContactNumNNN, showAlert, getActiveEmailAccessUserCount ,getActiveSMSAccessUserCount } from '../../../../../../Assests/Utility';
import DrilldownTableSS from '../../../../../LandingPage/Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import EditIcon from '../../../../../../Assests/Images/edit-icon-new.png'
import CustomTooltip from '../../../../../Common/Tooltip/CustomTooltip';
import { getRemoteConfigSelector } from '../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { toast } from 'react-toastify';
import _ from 'lodash';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';

// This component is for SchedulePro user List and render all users with SSADMIN role in a warehouse
const SPUserTable = (props) => {
    const navigate = useNavigate();
    // Data from props
    const { themeSelected, allowPermissionE, allowPermissionA, allowPermissionD, editUserAction, searchStr, allowPermissionRP, allowPermissionRE } = props;
    const [userListData, setUserListData] = useState([]);
    const [resenEmailLoader, setResenEmailLoader] = useState(false)
    const [resetUserPassLoader, setResetUserPassLoader] = useState(false)
    const user = useSelector(getUserSelector)
    const { initateLoading, userList, userType, arrangeOrder, arrangeKey, cu_id, parentWarehouse, resendEmail, resetUserPass } = user;
    const userAuth = useSelector(getAuthReducer);
    const { user_name } = userAuth;
    const dispatch = useDispatch();
    //Get Config Data from Redux
    const remoteConfig=useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [selectedObj, setSelectedObj] = useState(null)

    // Initial useEfect when component renders
    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_LOADING, payload: true })
        setTimeout(() => {

            dispatch({ type: sagaActions.UPDATE_USER_LIST, payload: createPayload() })
        },[100])
    }, [])

    // Use effect when there is change in userList
    useEffect(() => {
        initateLoading && dispatch({ type: sagaActions.INITIATE_LOADING, payload: false })
        if (userList.length) {
            if (searchStr) {
                const searchedArr = initiateSearch(userList, searchStr, 'UserName');
                setUserListData(searchedArr);
            } else {
                setUserListData(userList);
            }
        }
    }, [userList])

    // Use effect when there is change in search string - Searching
    useEffect(() => {
        // atleast 3 characters to begin searching
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(userList, searchStr, 'UserName');
            setUserListData(searchedArr);
        } else if (searchStr === '') {
            setUserListData(userList)
        }
    }, [searchStr])

    // Use effect when resend email functionality is triggered
    useEffect(() => {
        if(resendEmail){
            setResenEmailLoader(false)
            dispatch({ type: sagaActions.UPDATE_RESEND_EMAIL, payload: false})
        }
    },[resendEmail])

    // Use effect when reset user  password functionality is triggered
    useEffect(() => {
        if(resetUserPass){
            setResetUserPassLoader(false)
            dispatch({ type: sagaActions.UPDATE_RESET_USER_PASSWORD, payload: false})
        }
    },[resetUserPass])

    // Reusable function to create payload for create User API
    const createPayload = () => {
        return {
            user_name: user_name, email: user_name, user_type: userType, enable_status: 0, cu_id:  null, crud_type: 'S', first_name: null, last_name: null, phone: null,
            p_cu_id: userType === AppConstant.userPermission.usertypes.superAdmin ? null : cu_id,
            warehouseId: userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID, user_add_type: true
        }
    }

    // Reusable function to create payload for update User API
    const updatePayload = (user, key) => {
        return {
            user_name: user.UserName, email: user.Email, user_type: user.UserType, enable_status: user.EnableStatus, cu_id: user.CUID, crud_type: key, first_name: user.FirstName, last_name: user.LastName, phone: user.Phone,
            p_cu_id: userType === AppConstant.userPermission.usertypes.superAdmin ? null : cu_id,
            warehouseId: userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID, user_add_type: true
        }
    }

    // User Table Headers
    const warehouseTitleDD = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'UserName', name: 'Username' },
        { id: 'Email', name: 'Email' },
        { id: 'FirstName', name: 'First Name' },
        { id: 'LastName', name: 'Last Name' },
        { id: 'Phone', name: 'Phone No.' },
        { id: 'CreatedOn', name: 'Created on' },
        { id: 'LastLogon', name: 'Last Logon' },
        { id: 'status', name: 'Status' },
        { id: 'actions', name: 'Actions' },
    ]

    // Click handler for action button - EDIT/DELETE/ASSIGN
    const clickHandler = (key, user) => {
        dispatch({ type: sagaActions.UPDATE_SELECTED_USER, payload: user })
        let tempUser;
        switch (key) {
            case AppConstant.commonStrings.edit:
                editUserAction(user)
                break;
            case AppConstant.commonStrings.disable:
                dispatch({ type: sagaActions.UPDATE_USER_LIST, payload: { user_name: user.UserName,
                    p_cu_id: cu_id,
                    warehouseId: userType === AppConstant.userPermission.usertypes.superAdmin ? null :parentWarehouse.ID,
                     crud_type: 'D' } })

                break;
            case AppConstant.commonStrings.delete:
                setSelectedObj(user)
                setShowDeleteModel(true)    
                    break;
            case AppConstant.commonStrings.assign:
                dispatch({ type: sagaActions.RESET_USER_ROLE_ASSIGN })
                navigate('/user/assign')
                break;
            case AppConstant.commonStrings.resendEmail:
                setResenEmailLoader(user.CUID)
                dispatch({ type: sagaActions.INITIATE_RESEND_EMAIL, payload: { username: user.Email } })
                break;
            case AppConstant.commonStrings.resetPassword:
                setResetUserPassLoader(user.CUID)
                dispatch({ type: sagaActions.INITIATE_RESET_USER_PASSWORD, payload: { username: user.Email } })
                break;
            case AppConstant.commonStrings.SMSAccess:
                toggleSMSAccess(user);
                break;
            case AppConstant.commonStrings.emailAccess:
                 tempUser = { ...user };
                // tempUser.EmailAccess = !user.EmailAccess
                if (!_.isEmpty(tempUser)) {
                    if (tempUser.EmailAccess) {
                        const emailAccessCount = getActiveEmailAccessUserCount(userList)
                        if (emailAccessCount <= 1) {
                            showAlert(toast.TYPE.ERROR, AppConstant.dock.dockAlert.emailAccessWarning, themeSelected)
                            return;
                        }
                        
                    }

                    let payload = {
                        user_add_type: true,
                    user_name: tempUser.UserName,
                    email: tempUser.Email,
                    enable_status: tempUser.EnableStatus,
                    cu_id: tempUser.CUID,
                    user_type: tempUser.UserType,
                    crud_type: 'U',
                    first_name: tempUser.FirstName,
                    last_name: tempUser.LastName,
                    phone: tempUser.Phone,
                    p_cu_id: cu_id,
                    warehouseId: tempUser.WarehouseID,
                    EmailAccess: !tempUser.EmailAccess,
                    SMSAccess: tempUser.SMSAccess
                }
                    dispatch({ type: sagaActions.UPDATE_USER_LIST, payload })
                } 
              
                break;

            default:
                break;
        }
    }

    /**
     * Function to enable/disable sms access
     * @returns 
     */
    const toggleSMSAccess=(userData)=>{
        let tempUser = { ...userData };
        if (!_.isEmpty(tempUser)) {
            if (tempUser.SMSAccess) {
                const SMSAccessCount = getActiveSMSAccessUserCount(userList)
                if (SMSAccessCount <= 1) {
                    showAlert(toast.TYPE.ERROR, AppConstant.errors.SMSAccess, themeSelected)
                    return;
                }
                    
            }
            let payload = {
                user_add_type: true,
                user_name: tempUser.UserName,
                email: tempUser.Email,
                enable_status: tempUser.EnableStatus,
                cu_id: tempUser.CUID,
                user_type: tempUser.UserType,
                crud_type: 'U',
                first_name: tempUser.FirstName,
                last_name: tempUser.LastName,
                phone: tempUser.Phone,
                p_cu_id: cu_id,
                warehouseId: tempUser.WarehouseID,
                EmailAccess: tempUser.EmailAccess,
                SMSAccess: !tempUser.SMSAccess
            }
                dispatch({ type: sagaActions.UPDATE_USER_LIST, payload })
            } 
    }

    // Function to get validate active users in warehouse
    const getActiveUserCount = () => {
        if (_.size(userList)) {
            return _.size(_.groupBy(userList, "EnableStatus").true)
        }
        else {
            return 0
        }
    }

    // Function to Enable/Disable user
    const toggleStatus = (data, index) => {

        if (data?.EnableStatus) {
            const response = _.find(userList, { 'UserID': data.UserID });
            let tempObj = {...response}
            if(!_.isEmpty(tempObj)){
                 let tempArr = JSON.parse(JSON.stringify(userListData));
                tempObj.EnableStatus = !tempArr[index].EnableStatus;
            tempArr[index] = tempObj;
            setUserListData(tempArr)
            dispatch({ type: sagaActions.UPDATE_USER_LIST, payload: updatePayload(tempObj, 'D') })
            }
        } else {
            if (getActiveUserCount() < allConfigration?.SP_User_Creation_limit
        ) {
            const response = _.find(userList, { 'UserID': data.UserID });
            let tempObj = {...response}
            if(!_.isEmpty(tempObj)){
                 let tempArr = JSON.parse(JSON.stringify(userListData));
                tempObj.EnableStatus = !tempArr[index].EnableStatus;
            tempArr[index] = tempObj;
            setUserListData(tempArr)
            dispatch({ type: sagaActions.UPDATE_USER_LIST, payload: updatePayload(tempObj, 'D') })
            }
        }
        else {
            showAlert(toast.TYPE.ERROR, `User limit exceed ! Only ${allConfigration?.SP_User_Creation_limit} SSADMIN users can be active at a time.`, themeSelected)
        }   
        }

            
    }

    // Function to render Enable/Disable buttons
    const getToggleSwitch = (data, value, index) => {
        return (
            <span className='px-1'>
            <CustomButtonSS
                onClick={() => { toggleStatus(data, index) }}
                title={data.EnableStatus ? AppConstant.commonStrings.disable : AppConstant.commonStrings.enable}
                className={`${!data.EnableStatus ? ' btnDisable-ss ' : 'btnEnable-ss'} mx-2`}
                isLoading={false}
            />
        </span>
        )

    }

    // Function to render Action buttons EDIT/DELETE/ASSIGN
    const actionsButton = (el) => {
        return (
            <div className='d-flex flex-row align-items-center action-icon-wrapper'>
                <span className='px-1'>
                <img src={EditIcon} alt='edit' className='editIconPO-ss' data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        onClick={() => clickHandler(AppConstant.commonStrings.edit, el)} />
                </span>
                {/* <span className='px-1'>
                </span> */}
                <span className={allowPermissionRE ? 'px-1' : 'px-1 disabled'} data-tip={"Resend Email"} data-for='Resend Email' onClick={() => clickHandler(AppConstant.commonStrings.resendEmail, el)}>

                    <i className="fa fa-fw fa-envelope fa-lg"></i>
                    <CustomTooltip id='Resend Email' position='bottom' type='light' multiline={true} html={true} />

                </span>
                <span className={allowPermissionRP ? 'px-1' : 'px-1 disabled'} data-tip={"Reset Password"} data-for='Reset Password' onClick={() => clickHandler(AppConstant.commonStrings.resetPassword, el)}>
                    <i className="fa fa-unlock-alt fa-lg"></i>
                    <CustomTooltip id='Reset Password' position='bottom' type='light' multiline={true} html={true} />

                </span>
                <span className={true ? 'px-1 pt-1' : 'px-1 disabled'} data-tip={"SMS Access"} data-for='SMS Access'>
                    <input
                        type='checkbox'
                        value={el?.SMSAccess}
                        name='SMSAccess'
                        className={'customTextInputError'}
                        checked={el?.SMSAccess}
                        // onBlur={(data) => props.handleErrors(data.target.name)}
                        onChange={() => {  clickHandler(AppConstant.commonStrings.SMSAccess, el) }}
                    disabled={el?.EnableStatus ? false: true}
                    />
                    <CustomTooltip id='SMS Access' position='bottom' type='light' multiline={true} html={true} />
                    </span>
                <span className={true ? 'px-1 pt-1' : 'px-1 disabled'} data-tip={"Email Access"} data-for='Email Access'>
                    <input
                        type='checkbox'
                        value={el?.EmailAccess}
                        name='emailAccess'
                        className={'customTextInputError'}
                        checked={el?.EmailAccess}
                        // onBlur={(data) => props.handleErrors(data.target.name)}
                        onChange={() => {  clickHandler(AppConstant.commonStrings.emailAccess, el) }}
                    disabled={el?.EnableStatus ? false: true}
                    />
                    <CustomTooltip id='Email Access' position='bottom' type='light' multiline={true} html={true} />

                </span>

                <span className={true ? 'px-1' : 'px-1 disabled'} data-tip={"Delete Access"} data-for='Delete Access'>
                <CustomButtonSS
                        disabled={ false}
                        className={`no-border no-bg common-hover`}
                        onClick={() => clickHandler(AppConstant.commonStrings.delete, el)}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                        isToolTip={true}
                        dataTip={"Delete Access"}
                        dataFor={"tooltip_id_delete_access"}
                    />
              </span>
            </div>
        )
    }

    // format Drilldown body data to render
    const formatListData = (data) => {
        return data.map((el, index) => {
            return {
                id: `${el.UserID}_${el.LastName}`,
                0: el.serial_num,
                1: el.UserName || '-',
                2: el.Email,
                3: el.FirstName || '-',
                4: el.LastName || '-',
                5: el.Phone ? formatContactNumNNN(el.Phone) : '-',
                6: el.CreatedOn,
                7: el.LastLogon || '-',
                8: getToggleSwitch(el, el.EnableStatus, index),
                9: actionsButton(el),
            }
        })
    }

    /**
     * delete popup handler to delete the records based on the confirmation
     * @param {*} text 
     */
const popupBtnHandler = (text) => {
    if (text === AppConstant.commonStrings.no) {
        setShowDeleteModel(false)
        setSelectedObj(null)
    }
    else {
        if(_.upperCase(cu_id) === _.upperCase(selectedObj?.CUID)){
            showAlert(toast.TYPE.ERROR, 'You cannnot delete yourself, contact your admininstrator.', themeSelected)
            setShowDeleteModel(false)
            return 
        }
        dispatch({
            type: sagaActions.UPDATE_USER_LIST, payload:
                updatePayload(selectedObj, 'R')
        }
        )
    }
    setShowDeleteModel(false)
}

     /**
     * Helper to show the delete conformation pop-up
     * @returns 
     */
     const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: 'Delete user warehouse access',
            bodyText: `${AppConstant.commonStrings.areYouSure} delete ${parentWarehouse?.Name} access for user ${selectedObj?.UserName}?`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    return (
        <>
            <DrilldownTableSS
                themeSelected={themeSelected}
                bodyData={userListData.length && formatListData(userListData)}
                titleData={warehouseTitleDD}
                showCollapse={false}
                showInput={false}
                showSort={true}
                arrangeOrder={arrangeOrder}
                arrangeKey={arrangeKey}
                drillDownReport={userList}
                initiateDrillDownReport={initateLoading}
                loaderArray={10}
                sortingAction={sagaActions.SORT_USER_TABLE}
                subRoot={{ pathName: 'user' }}
                showUTCTime={false}
            />
              {showDeleteConfirmation()}
        </>
    )
}

export default SPUserTable;