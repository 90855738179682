import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: false,
    scheduleUploadData: [],
    arrangeOrder: 'asc',
    arrangeKey: 'Warehouse',
}

export const ScheduleUploadStatus = createSlice({
    name: 'scheduleUploadStatus',
    initialState,
    reducers: {
        initiateLoading: (state) => {
            state.loading = true;
            state.error = null;
            state.scheduleUploadData = [];
        },
        getScheduleUploadSuccess: (state, action) => {
            state.loading = false;
            state.scheduleUploadData = action.payload;
            state.error = null;
        },
        getScheduleUploadFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.scheduleUploadData = [];
        },
        resetScheduleUploadStatus: (state, action) => {
            state.loading = false;
            state.scheduleUploadData = [];
            state.error = null;
        },
        updateTableData: (state, action) => {
            const { drillDownReport, arrange, key, isPaginated } = action.payload;
            if (isPaginated) {
                state[key].data = drillDownReport
            }
            else {
                state[key] = drillDownReport
            }
            state.arrangeKey = arrange.key
            state.arrangeOrder = arrange.order
        },
    }
});

export const { getScheduleUploadSuccess, getScheduleUploadFailure, initiateLoading, resetScheduleUploadStatus, updateTableData } = ScheduleUploadStatus.actions;

// Selectors
export const getScheduleUploadStatus = (state) => {
    return state.scheduleUploadStatus;
};

export default ScheduleUploadStatus.reducer;
