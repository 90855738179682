import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLoadSelector } from '../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { displayLoader } from '../../../../../Assests/Utility';

import './LoadDetails.css'

const LoadDetails = (props) => {
    const { themeSelected } = props;
    const getLoad = useSelector(getLoadSelector);
    const { getLoadLoading, selectedLoad } = getLoad;
    const { trailer, tractor, items, cases, driver_name, driver_phone, vendor_name } = selectedLoad;


    const renderLoadDetails = () => {
        if (getLoadLoading) {
            return loadDetailsJSX(true)
        } else if (!getLoadLoading && !_.isEmpty(selectedLoad)) {
            return loadDetailsJSX(false)
        }else{
            return loadDetailsJSX(false)
        }
    }

    const loadDetailsJSX = (showLoader) => {
        return <>
            <div className="col-xs-1 col-sm-1 col-md-1 d-flex flex-column align-items-start load-details-container">
                <span className='load-details-title'>{AppConstant.loadManagement.purchaseOrderDetails.trailer}</span>
                {showLoader ? <span className='load-details-value w-100 pe-1'><div className='w-100'>{displayLoader(themeSelected)}</div></span> : <span className='load-details-value'>{trailer || '-'}</span>}
            </div>
            <div className="col-xs-1 col-sm-1 col-md-2 d-flex flex-column align-items-start">
                <span className='load-details-title'>{AppConstant.loadManagement.purchaseOrderDetails.tractor}</span>
                {showLoader ? <span className='load-details-value w-100 pe-1'><div className='w-100'>{displayLoader(themeSelected)}</div></span> : <span className='load-details-value'>{tractor || '-'}</span>}
            </div>
            <div className="col-xs-1 col-sm-1 col-md-1 d-flex flex-column align-items-start">
                <span className='load-details-title'>{AppConstant.loadManagement.purchaseOrderDetails.items}</span>
                {showLoader ? <span className='load-details-value w-100 pe-1'><div className='w-100'>{displayLoader(themeSelected)}</div></span> : <span className='load-details-value'>{items || '-'}</span>}
            </div>
            <div className="col-xs-1 col-sm-1 col-md-1 d-flex flex-column align-items-start">
                <span className='load-details-title'>{AppConstant.loadManagement.purchaseOrderDetails.cases}</span>
                {showLoader ? <span className='load-details-value w-100 pe-1'><div className='w-100'>{displayLoader(themeSelected)}</div></span> : <span className='load-details-value'>{cases || '-'}</span>}
            </div>
            <div className="col-xs-1 col-sm-1 col-md-2 d-flex flex-column align-items-start">
                <span className='load-details-title'>{AppConstant.loadManagement.purchaseOrderDetails.vendor_name}</span>
                {showLoader ? <span className='load-details-value w-100 pe-1'><div className='w-100'>{displayLoader(themeSelected)}</div></span> : <span className='load-details-value text-start'>{vendor_name || '-'}</span>}
            </div>
            <div className="col-xs-1 col-sm-1 col-md-3 d-flex flex-column align-items-start">
                <span className='load-details-title'>{AppConstant.loadManagement.purchaseOrderDetails.driver_name}</span>
                {showLoader ? <span className='load-details-value w-100 pe-1'><div className='w-100'>{displayLoader(themeSelected)}</div></span> : <span className='load-details-value driver-name text-truncate'>{driver_name || '-'}</span>}
            </div>
            <div className="col-xs-1 col-sm-1 col-md-3 d-flex flex-column align-items-start">
                <span className='load-details-title'>{AppConstant.loadManagement.purchaseOrderDetails.driver_phone}</span>
                {showLoader ? <span className='load-details-value w-100 pe-1'><div className='w-100'>{displayLoader(themeSelected)}</div></span> : <span className='load-details-value'>{driver_phone || '-'}</span>}
            </div>
        </>
    }

    return (
        <div className={`d-flex flex-row flex-nowrap load-details-wrapper justify-content-between py-2 ps-2`}>
            {renderLoadDetails()}

        </div>);
}

export default LoadDetails;