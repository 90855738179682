export const fetchLeavesListCommonFunc = (selectedWarehouse) => {

    const apiParams = {
        "IDWHLeave": null,
        "IDWarehouse": selectedWarehouse.ID,
        "Purpose": null,
        "LeaveStart": null,
        "LeaveEnd": null,
        "Status": 1,
        "CreatedBy": null,
        "UpdatedBy": null,
        "CRUDTYPE": "S",
        "CreatedOn": null,
        "UpdatedOn": null,
    }

    const operationObj = {
        type: 'Fetch Leaves',
        status: ''
    }

    return { apiParams: apiParams, operationObj: operationObj }
}

export const fetchLeavesDocksListCommonFunc = (selectedWarehouse) => {

    const apiParams = {
        "IDDockLeave": null,
        "IDWarehouse": selectedWarehouse.ID,
        "Description": null,
        "LeaveStart": null,
        "LeaveEnd": null,
        "Status": 1,
        "CreatedBy": null,
        "UpdatedBy": null,
        "CRUDTYPE": "S",
        "IDDock": null,
        "CreatedOn": null,
        "UpdatedOn": null,
    }

    const operationObj = {
        type: 'Fetch Leaves',
        status: ''
    }

    return { apiParams: apiParams, operationObj: operationObj }
}