import React, { useState } from 'react';
import { useEffect } from 'react';
import SectionRow from './SectionRow';

const SectionWrapper = (props) => {
    const { sectionData, deleteSection, themeSelected, addChildren, widgetList, selectWidgetsItems, selectedWidgetsArr } = props;

    useEffect(() => {
        createSection()
    }, [sectionData])

    const createSection = () => {
        return sectionData.map((el, i) => {
            return (
                <SectionRow
                    el={el}
                    themeSelected={themeSelected}
                    deleteSection={(el, cEl) => deleteSection(el, cEl)}
                    selectWidgetsItems={(el, cEl, widget) => selectWidgetsItems(el, cEl, widget)}
                    selectedWidgetsArr={selectedWidgetsArr}
                    addChildren={(el) => addChildren(el)}
                    index={i}
                    key={el.id}
                    widgetList={widgetList}
                />

            )
        })
    }
    return (
        sectionData.length ? createSection() : ''
    );
}

export default SectionWrapper;