import React from 'react'

/**
 * Reusable component for multi select option
 * @param {*} props 
 * @returns 
 */
const MultiSelectOption = (props) => {
    const {data,isSelected,onSelected} = props;
  return (
    <div className='d-flex multi-select-option gap-2' onClick={()=>onSelected(data.name)}>
        <input type='checkbox' name={data.name} value={data.name} checked={isSelected}/>
        <span>{data.name}</span>
    </div>
  )
}

export default MultiSelectOption