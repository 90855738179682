import React from 'react';
import { AppConstant } from '../../../../Assests/AppConstant';
import { ReactComponent as SearchPoLightIcon } from '../../../../Assests/Svg/search-po-light.svg';
import { ReactComponent as SearchPoDarkIcon } from '../../../../Assests/Svg/search-po-dark.svg';
import { ReactComponent as CrossIconBlack } from '../../../../Assests/Svg/cross-icon-black.svg';
import './WarehouseSearch.css';
/**
 * This is reusable component for search
 * renders an input box with search icon
 * @param {*} props 
 * @returns 
 */
const WarehouseSearch = (props) => {
    const { themeSelected, getLoadLoading, onChangeHandler, inputValue, cancelSearch, placeholder } = props;
    /**
     * 
     * @returns saerch icon based on theme
     */
    const setSearchIcon = () => {
        return (themeSelected === AppConstant.commonStrings.lightModeFilterString ? <SearchPoDarkIcon className='searchPOIcon' /> : <SearchPoLightIcon className='searchPOIcon' />);
    }
    /**
     * on change handler function
     * @param {*} event 
     */
    const onChangeText = (event) => {
        onChangeHandler(event.target.value);
    }

    return (
        <div>
            <div className={` ${themeSelected}`}>
                <span className='d-flex justify-content-start searchWarehouseInput p-1'>
                    <span className='py-1 px-2 justify-content-center align-items-center'>
                        {setSearchIcon()}
                    </span>
                    <input className={`m-0 p-1 px-2 w-100 border-0 bg-transparent searchWarehouseInputField ${themeSelected}`}
                        placeholder={placeholder}
                        onChange={(event) => onChangeText(event)}
                        value={inputValue}
                        disabled={getLoadLoading ? true : false}
                    />
                    {
                        inputValue && inputValue.length ?
                            <div className='py-1 px-1 justify-content-center align-items-center cancelSearchIcon'>
                                <CrossIconBlack onClick={() => cancelSearch()} />
                            </div> :
                            ''
                    }
                </span>
            </div></div>
    )
}

export default WarehouseSearch;