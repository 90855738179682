import React, { useEffect, useState } from 'react'
import _ from 'lodash-es';
import './SSCarrierPO.css'
import moment from 'moment';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import { formParticularFieldValueForAssociatedPO } from '../../../../../../../Assests/Utility';
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';
import UploadSproDocumentsPopup from '../../../../../../Common/UploadSproDocumentsPopup/UploadSproDocumentsPopup';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';

export const SSCarrierPO = (props) => {
    const { showDelete = true, poList } = props;
    const { rowData, onEmailClickHandler, rowIndex, ssSchedulehandler, triggerRecalculate, poForAppt, openAddMorePOForm, themeSelected, searchedPOData, userType, isPast, onDeleteClickHandler ,showBtn=true ,showSuccess=false, appointmentDocumentsList, uploadDocsHandler, docDeleteHandler, initateLoading, showUpload } = props;
    const [poNumber, setPoNumber] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [showDocQuestionModal, setShowDocQuestionModal] = useState(false);
    const [showUploadDocsModal, setShowUploadDocsModal] = useState(false)
    const [addPOBtn, setAddPOBtn] = useState(false);
    const [selectedPo, setSelectedPo] = useState(rowData);
    const [index, setIndex] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        if (_.size(poList)) {
            setIndex(poList?.findIndex((x) => { return x === rowData }) + 1)
        }
    }, [poList])

    useEffect(() => {
        if (rowData) {
            setSelectedPo(rowData)
        }
    }, [rowData])

    useEffect(() => {
        if (_.size(searchedPOData)) {
            const fieldData = formParticularFieldValueForAssociatedPO(searchedPOData)
            setPoNumber(fieldData?.po_number)
        } else {
            setPoNumber(selectedPo?.PoNumber)
        }
    }, [searchedPOData])

    useEffect(() => {
        if (_.size(poForAppt)) {
            const fieldData = formParticularFieldValueForAssociatedPO(poForAppt)
            setPoNumber(fieldData?.po_number)
        }
    }, [triggerRecalculate, poForAppt])
    if (!_.isEmpty(selectedPo && selectedPo?.WarehouseData?.TimeZone)) {
        moment.tz.setDefault(selectedPo?.WarehouseData?.TimeZone);
        // Set Warehouse Timezone   
    }

    const [isScheduleDisabled, setIsScheduleDisabled] = useState(null);
    const getScheduleDisabled = () => {
        const dueDate = moment(selectedPo.DueDate).utc().startOf('day');
            const todayDate = moment().utc().startOf('day')
        if (selectedPo && selectedPo.ApptScheStatus === 'Date Expired') {
            return {
                className: `disabled mx-2 mb-2`,
                disable: true
            };
        }
        else {
            return {
                className: renderBtnData()?.className,
                disable: renderBtnData()?.disabledSchedule
            }
        }
    }


    useEffect(() => {
        if (showUploadDocsModal) {
           fetchUploadDocsDataForAppt()
        }

    }, [showUploadDocsModal])
    
    const fetchUploadDocsDataForAppt = () => {
        if (rowData?.AppointmentNumber) {
            dispatch({type: sagaActions.INTIATE_SS_LOADER, payload: true})
            let fd = new FormData();
            const apptNumber = rowData?.AppointmentNumber;
            fd.append('AppointmentNumber', apptNumber)
            fd.append('file', null); // Assuming 'file' is the file object
            fd.append('docName', null); // Append the document name
            fd.append('ApptDocsCreatedBy', null);
            fd.append('ApptDocsUpdatedBy', null);
            // fd.append('ID', null);
            fd.append('CRUDTYPE', 'S');
            dispatch({
                type: sagaActions.UPLOAD_APPT_DOCS,
                payload: {
                    CRUDTYPE: 'S',
                    uploadData: fd
                }
            });
        }
    }

    function showPopup() {
        if (_.size(searchedPOData) && searchedPOData[0]?.Rescheduled) {
            setShowModal(false);
            return;
        }
        setShowModal(true);
    }
    /**
     * Handler function to open Upload document questionnaire popup
     * @returns 
     */
    const showDocQuestionModalHandler = () => {
        if (_.size(searchedPOData) && searchedPOData[0]?.Rescheduled) {
            setShowDocQuestionModal(false);
            return;
        }
        setShowDocQuestionModal(true);
    }
    const closepopup = () => {
        setShowModal(false);
        userType === 'CARRIER' && setShowDocQuestionModal(true)
    }

    useEffect(() => {
        setIsScheduleDisabled(getScheduleDisabled());
    }, [selectedPo?.DueDate])

    const renderBtnData = () => {
        let currentTime = moment().utc()
        let validApptLifeTime = moment(selectedPo?.ApptEndTime).add(selectedPo?.ApptLifeTime,"hours")
        
        if (selectedPo?.ApptScheStatus?.toLowerCase() === 'scheduled' || selectedPo?.ApptScheStatus?.toLowerCase() === 'rescheduled') {
            return {
                title: `Reschedule`,
                className: `pointer mb-2 me-2`,
                disabledSchedule: false,
                disabledEmail: false
            }
        } 
        else if (selectedPo?.ApptScheStatus?.toLowerCase() === 'not scheduled') {
            return {
                title: `Schedule`,
                className: isScheduleDisabled?.disable ? `disabled mx-2 mb-2 me-2` : `pointer mb-2 me-2`,
                disabledSchedule: false,
                disabledEmail: true
            }
        } 
        else if (selectedPo?.ApptScheStatus?.toLowerCase() === 'completed' && _.upperCase(userType) === 'CARRIER') {
            return {
                title: `Completed`,
                className: `disabled mb-2 me-2`,
                disabledSchedule: true,
                disabledEmail: true
            }
        }
        else if (selectedPo?.ApptScheStatus?.toLowerCase() === 'date expired') {
            return {
                title: `Expired`,
                className: `disabled mb-2 me-2`,
                disabledSchedule: true,
                disabledEmail: true
            }
        }
        else if (isPast && currentTime > validApptLifeTime) {
            return {
                title: `Completed`,
                className: `disabled mb-2 me-2`,
                disabledSchedule: true,
                disabledEmail: true
            }
        }
        else{
            return {
                title: `Reschedule`,
                className: `pointer mb-2 me-2`,
                disabledSchedule: false,
                disabledEmail: true
            }
        }
    }

    const renderHorizontalSeparator = () => {

        if (selectedPo?.ApptScheStatus?.toLowerCase() === 'scheduled') {
            return {
                className: `w-100 horizontalSeperator apptScheduled`
            }
        } else if (selectedPo?.ApptScheStatus?.toLowerCase() === 'not scheduled') {
            return {
                className: `w-100 horizontalSeperator apptNotScheduled`
            }
        }
        else if (selectedPo?.ApptScheStatus?.toLowerCase() === 'rescheduled') {
            return {
                className: `w-100 horizontalSeperator rescheduled`
            }
        } else {
            return {
                className: `w-100 horizontalSeperator completed`
            }
        }
    }

    const getHoverText = (selectedPo) => {
        if (selectedPo.ApptScheStatus === 'Scheduled') {
            return AppConstant.commonStrings.reschedule
        } else if (selectedPo.ApptScheStatus === 'Not Scheduled') {
            return AppConstant.commonStrings.schedule
        } else {
            return AppConstant.commonStrings.completed
        }
    }
    const popupObj = {
        id: "multiPOAlert1",
        modalLabel: "multiPOAlert1",
        showClose: true,
        showfooter: true,
        showHeader: true,
        sendDriverNotification: false,
        modalSize: "md",
        title: 'Add more PO/SO',
        bodyText: <div className='boldText'>{AppConstant.smartScheduler.addMorePoPopupText.body}</div>,
        subText: "",
        keyboard: false,
        backdrop: "static",
        alignType: 'center',
        type: 'info',
        footerBtn: {
            btn1: {
                show: true,
                text: AppConstant.commonStrings.yes,
            },
            btn2: {
                show: true,
                text: AppConstant.commonStrings.no,
            },
        },
    };

    const [showMorePOModal, setShowMorePOModal] = useState(false)

    const closeSelectPopopup = () => {
        setShowMorePOModal(false)
    }

    const popupObjUploadDocs = {
        id: "multiPOAlert2",
        modalLabel: "multiPOAlert2",
        showClose: true,
        showfooter: true,
        showHeader: true,
        sendDriverNotification: false,
        modalSize: "md",
        title: 'Upload Documents',
        bodyText: <div className='boldText'>{AppConstant.smartScheduler.uploadAppointmentDocs.body}</div>,
        subText: "",
        keyboard: false,
        backdrop: "static",
        alignType: 'center',
        type: 'info',
        footerBtn: {
            btn1: {
                show: true,
                text: AppConstant.commonStrings.yes,
            },
            btn2: {
                show: true,
                text: AppConstant.commonStrings.no,
            },
        },
    };

    const closeUploadDocsPopopup = () => {
        setShowDocQuestionModal(false)
    }

    const poListfun = () => {
        return (
            <div>
                {_.size(poList) && poList.map((data, index) => {
                    return (
                        <div className='d-flex justify-content-between w-100 my-2' key={index} >
                            <p className='carrier-po-value f-14 fw-600'>{index + 1}. {data?.PoNumber}</p>
                            <p className='pointer carrier-po-value f-14 fw-600 text-secondary' onClick={() => {
                                setSelectedPo(data);
                                setIndex(index + 1);
                                closeSelectPopopup();
                            }}>{selectedPo?.PoNumber === data?.PoNumber ? <span className='color-highlight'>Active</span> : 'View'}</p>
                        </div>
                    )
                })}
            </div>
        )
    }

    const showMorePoPopUp = () => {
        const popupshowMorePoObj = {
            id: 'popupshowMorePoObj',
            modalLabel: 'showMorePo',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'sm',
            title: 'PO/SO List',
            bodyText: poListfun(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: false,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: false,
                    text: AppConstant.commonStrings.no
                }
            },
        }
        return <SSPopup {...popupshowMorePoObj} popupBtnHandler={() => { }} closepopup={closeSelectPopopup} themeSelected={themeSelected} showModal={showMorePOModal} />
    }

    const popupHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            openAddMorePOForm()
        }
        if (text === AppConstant.commonStrings.no) {
            ssSchedulehandler(selectedPo)
        }
        closepopup()
    }

    const popupUploadDocBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            setShowUploadDocsModal(true)
        }
        if (text === AppConstant.commonStrings.no) {
            ssSchedulehandler(selectedPo)
        }
        closeUploadDocsPopopup()
    }


    const colorCodeGenrate = () => {

        if (rowData?.ApptScheStatus?.toLowerCase() === 'scheduled') {
            return {
                className: `carrier-ss-head apptScheduled d-flex align-items-center`
            }
        } else if (rowData?.ApptScheStatus?.toLowerCase() === 'not scheduled') {
            return {
                className: `carrier-ss-head apptNotScheduled d-flex align-items-center`
            }
        }
        else if (rowData?.ApptScheStatus?.toLowerCase() === 'rescheduled') {
            return {
                className: `carrier-ss-head rescheduled d-flex align-items-center`
            }
        } else {
            return {
                className: `carrier-ss-head completed d-flex align-items-center`
            }
        }
    }
    const colorCodeGenrateForText = () => {

        if (rowData?.ApptScheStatus?.toLowerCase() === 'scheduled') {
            return {
                className: `color-ss-carrier apptScheduled`
            }
        } else if (rowData?.ApptScheStatus?.toLowerCase() === 'not scheduled') {
            return {
                className: `color-ss-carrier apptNotScheduled `
            }
        }
        else if (rowData?.ApptScheStatus?.toLowerCase() === 'rescheduled') {
            return {
                className: `color-ss-carrier rescheduled`
            }
        } else {
            return {
                className: `color-ss-carrier completed `
            }
        }
    }
    const colorCodeGenrateForSeprater = () => {

        if (rowData?.ApptScheStatus?.toLowerCase() === 'scheduled') {
            return {
                className: `carrier-po-vl apptScheduled`
            }
        } else if (rowData?.ApptScheStatus?.toLowerCase() === 'not scheduled') {
            return {
                className: `carrier-po-vl apptNotScheduled `
            }
        }
        else if (rowData?.ApptScheStatus?.toLowerCase() === 'rescheduled') {
            return {
                className: `carrier-po-vl rescheduled`
            }
        } else {
            return {
                className: `carrier-po-vl completed `
            }
        }
    }

    return (
        <div className='carrier-po-wrapper p-2' data-testid="appt-card">
            <div className={colorCodeGenrate()?.className} >
                <div className='carrier-po-date-container'>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <span className={colorCodeGenrateForText()?.className + ' carrier-po-month'}>{selectedPo?.ApptStartTime ?
                            moment(selectedPo.ApptStartTime).utc().format('MMM').toUpperCase() : '-'}</span>
                        <span className={colorCodeGenrateForText()?.className + ' carrier-po-day'}>{selectedPo?.ApptStartTime ?
                            moment(selectedPo.ApptStartTime).utc().format('DD') : "-"}</span>
                    </div>
                </div>
                <div className={colorCodeGenrateForSeprater()?.className + ' carrier-po-vl'}></div>
                <div className='d-flex flex-column justify-content-around ms-2 h-100'>
                    <div className={colorCodeGenrateForText()?.className + ' carrier-po-time-container'}><i className={colorCodeGenrateForText()?.className + " fa-regular fa-clock"} ></i>{selectedPo.ApptStartTime ? moment(selectedPo.ApptStartTime).utc().format(AppConstant.commonStrings.hh_mm_A_dateFormat) : '-'}</div>
                    <div className={colorCodeGenrateForText()?.className + ' carrier-po-location-container'}><i className={colorCodeGenrateForText()?.className + " fa-solid fa-location-dot"}></i>{selectedPo?.WarehouseData?.Address ? selectedPo?.WarehouseData?.Address : ''}</div>
                </div>
            </div>
            <div className='pt-3 d-flex flex-column justify-content-between h-87'>
                <div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-cube" ></i> <span className='ms-1'>{_.upperCase(selectedPo.LoadType) === 'INBOUND'? AppConstant.poManagement.tableHeader.po: AppConstant.poManagement.tableHeader.so}<span className='f-10'>{_.size(poList) > 1 && ` : ${index} Of ${_.size(poList)}`}</span></span>
                        </div>
                        <div className='carrier-po-value'>{selectedPo?.PoNumber}</div>
                    </div>
                    {/* {poNumber && <div className='d-flex justify-content-between mb-2'>
                    <div className='carrier-po-key'>
                        <i className="fa-solid fa-cube" ></i> <span className='ms-1'>Asso. Po</span>
                    </div>
                    <div className='carrier-po-value'>{poNumber}</div>
                </div>} */}
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-warehouse fa-sm"></i> <span className='ms-1'>Warehouse</span>
                        </div>
                        <div className='carrier-po-value w-50'>{selectedPo?.WarehouseData?.Name}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-envelope"></i> <span className='ms-1'>ZIP Code</span>
                        </div>
                        <div className='carrier-po-value'>{selectedPo?.ReceivingZipCode}</div>
                    </div>
                    {/* <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-calendar"></i> <span className='ms-1'>Appt. No.</span>
                        </div>
                        <div className='carrier-po-value'>{selectedPo?.AppointmentNumber || "-"}</div>
                    </div> */}
                    <hr className='my-2' />
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-user"></i> <span className='ms-1'>Vendor</span>
                        </div>
                        <div className='carrier-po-value'>{selectedPo?.VendorName ? selectedPo?.VendorName : '-'}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-store"></i> <span className='ms-1'>Buyer</span>
                        </div>
                        <div className='carrier-po-value'>{selectedPo?.BuyerName ? selectedPo?.BuyerName : '-'}</div>
                    </div>
                    {/* <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key w-40'>
                            <i className="fa-solid fa-user"></i> <span className='ms-1'>Appt. By</span>
                        </div>
                        <div className='carrier-po-value w-60'>{`${selectedPo.FirstName || "-"} ${selectedPo.LastName || "-"} ( ${selectedPo.CarrierName || "-"} )`}</div>
                    </div> */}
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-suitcase"></i> <span className='ms-1'>Commodity</span>
                        </div>
                        <div className='carrier-po-value'>{selectedPo?.Commodity ? selectedPo?.Commodity : '-'}</div>
                    </div>

                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-cubes"></i> <span className='ms-1'>{`Dock | ${AppConstant.dashboard.defaultdashboard.doorUsageSummary.palletsHeading}`}</span>
                        </div>
                        <div className='carrier-po-value w-50'>{selectedPo?.DockName ? selectedPo?.DockName : ''}{` | ${selectedPo?.Pallets ? selectedPo?.Pallets : '-'}`}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key w-50'>
                            <i className="fa-solid fa-sitemap"></i> <span className='ms-1'>{`${AppConstant.loadManagement.purchaseOrderDetails.items} | ${AppConstant.loadManagement.purchaseOrderDetails.cases}`}</span>
                        </div>
                        <div className='carrier-po-value w-50'>{selectedPo?.Quantity ? selectedPo?.Quantity : '-'} | {selectedPo?.ItemsCases ? selectedPo?.ItemsCases : '-'}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key'>
                            <i className="fa-solid fa-calendar"></i> <span className='ms-1'>Due Date</span>
                        </div>
                        <div className='carrier-po-value text-danger'>{moment(selectedPo?.DueDate).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat) || ''}</div>
                    </div>
                    <div className='d-flex justify-content-between  mb-2'>
                        <div className='carrier-po-key w-50'>
                            <i className="fa-solid fa-user"></i> <span className='ms-1'>Carrier</span>
                        </div>
                        <div className='carrier-po-value w-50 text-truncate' title={selectedPo?.CarrierName || '-'}>{selectedPo?.CarrierName || '-'}</div>
                    </div>
                    {showBtn &&
                    <div className={addPOBtn ? 'd-flex justify-content-between   mb-2 w-100 ' : 'd-flex justify-content-end   mb-2 w-100 '}>
                        {(addPOBtn && !showSuccess) && <CustomButtonSS
                            disabled={false}
                            isLoading={false}
                            title={'+ Add PO/SO'}
                            className='carrier-po-add__po-btn'
                            // onClick={userType==='CARRIER'?() => showPopup():() => ssSchedulehandler(selectedPo)}
                            onClick={() => {
                                openAddMorePOForm()
                            }}

                            />}
                            
                        {_.size(poList) > 1 &&
                            <CustomButtonSS
                                disabled={false}
                                isLoading={false}
                                title={'Show PO/SO List'}
                                className='carrier-po-add__po-btn'
                                // onClick={userType==='CARRIER'?() => showPopup():() => ssSchedulehandler(selectedPo)}
                                onClick={() => {
                                    setShowMorePOModal(true)
                                }}

                            />
                        }
                        {/* Carousel UI */}
                        {/* <div className='d-flex justify-content-around align-items-center carrier-po-crousal-control'>
                        <button><i className="fa-solid fa-angle-left"></i></button>
                        <div><b>1</b> of 3</div>
                        <button><i className="fa-solid fa-angle-right"></i></button>
                    </div> */}
                    </div>
                    }
                </div>
                {showBtn &&
                <div className='d-flex justify-content-between mt-2'>
                    {rowIndex === 0 && <CustomButtonSS
                        disabled={isScheduleDisabled?.disable}
                        isLoading={false}
                        title={renderBtnData()?.title}
                        className={`carrier-po-reschedule-btn ${renderBtnData()?.className}`}
                        // onClick={userType==='CARRIER'?() => showPopup():() => ssSchedulehandler(selectedPo)}
                            onClick={() => {
                                ssSchedulehandler(rowData)
                                    setAddPOBtn(true)
                                    userType === 'CARRIER' && showPopup()
                                    userType === 'USER' && showDocQuestionModalHandler()
                        }}

                    />
                    }
                    <div className='d-flex justify-content-end'>
                        {
                            (showDelete && !isPast) &&
                            <CustomButtonSS
                                disabled={renderBtnData()?.disabledEmail}
                                isLoading={false}

                                className={`carrier-po-btn-small ${renderBtnData()?.className}`}
                                onClick={() => onDeleteClickHandler(rowData)}
                                isIcon={true}
                                iconClass={"fa-regular fa-trash-can"}
                            />
                        }
                        {
                            selectedPo.ApptScheStatus !== 'Not Scheduled' &&
                            <CustomButtonSS
                                disabled={renderBtnData()?.disabledEmail}
                                isLoading={false}

                                className={`carrier-po-btn-small ${renderBtnData()?.className}`}
                                onClick={() => onEmailClickHandler(rowData)}
                                isIcon={true}
                                iconClass={"fa-regular fa-envelope"}
                            />
                            }
                             { (addPOBtn && !showSuccess) || rowData?.ApptScheStatus?.toLowerCase() !== 'not scheduled'? <CustomButtonSS
                            disabled={false}
                            isLoading={false}
                            title=''
                            className='carrier-po-btn-small'
                            onClick={() => setShowUploadDocsModal(true)}
                            isIcon={true}
                            iconClass={"fa-regular fa-file-lines"}
                            isToolTip={true}
                            dataTip={"tooltip_id_upload_docs"}
                            dataFor={"Upload Document"}
                                
                        />: ''}
                    </div>
                </div>
                }
            </div>

            <SSPopup
                {...popupObjUploadDocs}
                popupBtnHandler={(text) => popupUploadDocBtnHandler(text)}
                showModal={showDocQuestionModal}
                showfooter={true}
                closepopup={closeUploadDocsPopopup}
                themeSelected={themeSelected}
            />

            <SSPopup
                {...popupObj}
                popupBtnHandler={(text) => popupHandler(text)}
                showModal={showModal}
                showfooter={true}
                closepopup={closepopup}
                themeSelected={themeSelected}
            />

            {showUploadDocsModal ? <UploadSproDocumentsPopup showModal={showUploadDocsModal} setCloseModal={setShowUploadDocsModal} uploadDocsHandler={uploadDocsHandler} docDeleteHandler={docDeleteHandler } appointmentDocumentsList={appointmentDocumentsList} showDelete={showDelete} showUpload={showUpload} initateLoading={initateLoading} /> : ''}

            {showMorePoPopUp()}
        </div >
    )
}
