import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { getCurrentDate } from '../../../../../../../../Utils/DateUtils';
import { initiateSearch, showAlert, changeFormatWithoutTz } from '../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import moment from 'moment';
import './DockDoorConflictForm.css';
import DrilldownTableSS from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';

const DockDoorConflictFormSS = (props) => {

    const { themeSelected, dockPayload } = props;
    const dispatch = useDispatch();
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { operationObj, arrangeOrder, arrangeKey, dockConflictObj } = whMetaReducer;
    const [isListLoading, setListLoading] = useState(false);
    const [metaTimeslotArr, setMetaTimeslotArr] = useState([]);
    const [dockDoorConflictArr, setdockDoorConflictArr] = useState([]);
    const user = useSelector(getUserSelector);
    const { cu_id } = user;
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [selectedObj, setSelectedObj] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showPreCheckModal, setShowPreCheckModal] = useState(false);

    const dockTimeslotsTitleDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'TimeSlotName', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.timeslot },
        { id: 'CreatedDate', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.CreatedOn },
        { id: 'status', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.status },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    /**
     * handles the dock object
     */
    useEffect(() => {
        if (dockConflictObj && dockConflictObj.timeSlots.length) {
            setShowLoading(false);
            setdockDoorConflictArr(dockConflictObj.timeSlots)
        } else {
            setdockDoorConflictArr([])
            setShowLoading(false)
        }

    }, [dockConflictObj.timeSlots])

    /**
     * handles API response
     */
    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Delete Timeslot':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.timeslots.deleteTimeslotSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.timeslots.deleteTimeslotFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    /**
     * Handles API response based on success and failure
     * @param {*} result 
     * @param {*} message 
     */
    const handleApiResponse = (result, message) => {
        setCurrentItemIndex('NA')
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            dispatchDockConflict()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        setListLoading(false);
        dispatch({ type: sagaActions.RESET_OPERATION_OBJECT_WH });
    }

    const dispatchDockConflict = () => {

        dispatch({ type: sagaActions.GET_DOCK_CONFLICT_DETAILS, payload: dockPayload });
    }

    const deleteHandler = (data, index) => {
        setSelectedObj(data, index)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }
    /**
     * handle the pop selection, calls API
     * @param {*} text 
     */
    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedObj(null)
            setSelectedIndex(null)
        }
        else {

            let isAppointmentsAvailable = false;
            if (dockConflictObj && dockConflictObj.appointments.length > 0) {
                 dockConflictObj.appointments.forEach(element => {
                    if (element.Door === selectedObj.TimeSlotName) {
                        isAppointmentsAvailable = true;
                    }
                });
            }

            if (isAppointmentsAvailable === true) {
                setShowPreCheckModal(true)
            } else {
                setCurrentDeleteIndex(selectedIndex)
                const apiParams = {
                    // "Warehouse": selectedObj.Warehouse,
                    "Dock": selectedObj.Name,
                    "DoorType": selectedObj.DoorType,
                    "TimeSlotName": selectedObj.TimeSlotName,
                    // "DisplayOrderNo": selectedObj.DisplayOrderNo,
                    "DoorStatus": true,
                    "IDDock": selectedObj.IDDock,
                    "ID": selectedObj.ID,
                    // "IDWarehouse": selectedObj.IDWarehouse,
                    "CRUDTYPE": "D",
                    "TimeSlotStatus": selectedObj.TimeSlotStatus,
                    "CreatedOn": selectedObj.CreatedDate,
                    "CreatedBy": selectedObj.CreatedBy,
                    "UpdatedBy": cu_id,
                    "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                }

                const operationObj = {
                    type: 'Delete Timeslot',
                    status: ''
                }

                dispatch({ type: sagaActions.DELETE_TIMESLOT_ACTION, payload: apiParams, operationObj: operationObj })
            }
        }
        setShowDeleteModel(false)
    }
    /** shows the dlete confirmation pop */
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.timeslots.deleteTimeslotTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.smartScheduler.timeslots.deleteTimeslotTitle}`,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
    }

    const showPreCheck = () => {
        const popupObj = {
            id: 'adminUpdateOperationPreCheck',
            modalLabel: 'adminUpdateOperationPreCheck',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.smartScheduler.timeslots.editTimeslotTitle,
            bodyText: AppConstant.superUserAdmin.warehouseAdmin.preCheckBlockerMsgForDockConflict,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.close
                },
                btn2: {
                    show: false,
                },
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => { popupPrecheckHandler(text) }} closepopup={() => { closePreCheckpopup() }} themeSelected={themeSelected} showModal={showPreCheckModal} />
    }

    const closePreCheckpopup = () => {
        setShowPreCheckModal(false)
    }

    const popupPrecheckHandler = (text) => {
        if (text === AppConstant.commonStrings.close) {
            setShowPreCheckModal(false)
        }
        else {
            setShowPreCheckModal(false)
        }
    }

    /** used to create custom button */
    const actionsButton = (groupObj, index) => {
        return (
            <div>
                <span className='d-flex justify-content-center align-items-center gap-2' >
                    <CustomButtonSS
                        disabled={false}
                        isLoading={currentDeleteIndex !== 'NA' && currentDeleteIndex === index ? true : false}
                        className={`no-border no-bg`}
                        onClick={() => { deleteHandler(groupObj, index) }}
                        isIcon={true}
                        iconClass={"fa-regular fa-trash-can fa-lg"}
                    />
                </span>
            </div>
        )
    }

    /** format the data */
    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.RoleID,
                0: index + 1,
                1: el.TimeSlotName || AppConstant.commonStrings.hypen,
                // 3: el.DisplayOrderNo || '-',
                2: `${moment(el.CreatedDate).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedDate).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || AppConstant.commonStrings.hypen,
                3: el.DoorStatus ? 'Enabled' : 'Disabled',
                4: actionsButton(el, index),
            }
        })
    }

    return (
        <>
            {dockDoorConflictArr &&
                <DrilldownTableSS
                    themeSelected={themeSelected}
                    bodyData={dockDoorConflictArr.length && formatListDataDrillDown(dockDoorConflictArr)}
                    titleData={dockTimeslotsTitleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    arrangeOrder={arrangeOrder}
                    arrangeKey={arrangeKey}
                    drillDownReport={dockDoorConflictArr}
                    initiateDrillDownReport={isListLoading}
                    loaderArray={6}
                    sortingAction={sagaActions.SORT_WH_META_TABLE_ACTION}
                    subRoot={{ pathName: 'whMetaTimeslotArr' }}
                    showUTCTime={false}
                />}
            {showDeleteConfirmation()}
            {showPreCheck()}
        </>
    )

}

export default DockDoorConflictFormSS;