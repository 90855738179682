// src/components/DocumentUploadPopup.js
import React, { useEffect, useState, useRef } from 'react';
import { AppConstant } from '../../../Assests/AppConstant';
import SSPopup from '../Popup/SSPopup/SSPopup';
import CustomButtonSS from '../CustomButton/CustomButtonSS';
import NoDataFoundSS from '../../SmartSchedule/SchedulePro/Components/Common/NoDataFoundSS';
import _ from 'lodash-es';
import './UploadSproDocumentsPopup.css'
import { showAlert } from '../../../Assests/Utility';
import { toast } from 'react-toastify';

/**
 * Reusable compoent to render upload document Popup with upload button and list pf uploaded documents
 * @param {*} props 
 * @returns 
 */

const UploadSproDocumentsPopup = (props) => {
    const { themeSelected, showModal, setCloseModal, showDelete, appointmentDocumentsList, uploadDocsHandler, docDeleteHandler, initateLoading, showUpload } = props;
    const [show, setShow] = useState(false);
    const [docs, setDocs] = useState([]);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [docName, setDocName] = useState('');
    /**
     * useEffect to show and hide modal
     */
    useEffect(() => {
        setShow(showModal)
    }, [showModal])

    /**
     * useEffect to for loader
     */
    useEffect(() => {
        setLoading(initateLoading)
    }, [initateLoading])
    
    /**
     * useEffect to set available document data
     */
    useEffect(() => {
        if (_.size(appointmentDocumentsList)) {
            setDocs([...appointmentDocumentsList])
        } else {
            setDocs([])
        }
        setFile(null);
        setDocName('');
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the file input
        }
    },[appointmentDocumentsList])

    /**
     * fuction to close modal
     */
    const handleClose = () => {
        setShow(false)
        setCloseModal(false)
    };
    
    // const handleShow = () => setShow(true);
    /**
     * file upload handler function
     * @param {*} e 
     */
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    /**
     * Submit selected files handler function
     */
    const handleUpload = () => {
        if (!docName || !file) {
            let errorMessage = 'Please provide the following required fields:\n';
            if (!docName) {
                errorMessage += '- Document Name\n';
            }
            if (!file) {
                errorMessage += '- Choose Document';
            }
            showAlert(toast.TYPE.WARNING, errorMessage, 'light')
            return;
        }
        if (file.size > 20 * 1024 * 1024) {
            showAlert(toast.TYPE.WARNING, 'File size exceeds the 20MB limit.', 'light')
            return;
        }
        if (file && docName) {
            setLoading(true)
            uploadDocsHandler(file, docName)
        }
    };
    /**
     * Delete document handler function
     * @param {*} doc 
     */
    const handleDelete = (doc) => {
        docDeleteHandler(doc);
    };
    /**
     * Document name handler function
     * @param {*} e 
     */
    const handleDocNameChange = (e) => {
        setDocName(e.target.value);
    };
    /**
     * function to format document name and display
     * remove fist part document and display only user enetered document name
     * @param {*} str 
     * @returns 
     */
    const getDocName = (str) => {
        if (!str || str.trim().length === 0) {
            return '-';
        }
        const [prefix, suffix] = str.split('-');
        return suffix;
    }
    
    const getUploadDocsBody = () => {
        return (
            <div className="modal-body">
                {showUpload ? <form className='text-start'>
                    
                    <div className="mb-3 w-100">
                        <label htmlFor="docName" className="form-label">Document Name
                        <span className="text-danger">*</span>
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="docName"
                            value={docName}
                            onChange={handleDocNameChange}
                            placeholder={AppConstant.smartScheduler.uploadAppointmentDocs.docNamePlaceholderText}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Choose Document
                        <span className="text-danger">*</span>
                        </label>
                        <input className="form-control" name="file" type="file" id="formFile" ref={fileInputRef} onInput={handleFileChange} accept=".doc, .docx, .xls, .xlsx, .pptx, .pdf, .png, .jpg, .jpeg" />
                        <small className="form-text textRed f-12">{AppConstant.smartScheduler.uploadAppointmentDocs.docsDisclaimerText}</small>
                    </div>
                    <CustomButtonSS
                        disabled={docs.length < 3 ? false: true}
                        isLoading={loading}
                        title={'Upload'}
                        className={`btn-submit ${docs.length < 3 ? '' :'disabledUploadBtn'}`}
                        onClick={handleUpload}
                    />

                </form>: ''}
                {
                    loading ? <div className="d-flex justify-content-center mt-3">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    </div> : docs.length === 0 ? <NoDataFoundSS themeSelected={themeSelected} title={AppConstant.smartScheduler.uploadAppointmentDocs.noUploadDocsTitle} imageClass='imageClass' /> :
                            <ul className="list-group mt-3">
                            {docs.map((doc, index) => (
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                    {getDocName(doc.DocName)}
                                    <div>
                                        <CustomButtonSS
                                            disabled={false}
                                            isLoading={false}
                                            className={`carrier-po-btn-small`}
                                            onClick={() => { 
                                                window.open(doc.DOCSURL, '_blank');
                                            }}
                                            isIcon={true}
                                            iconClass={"fa-regular fa-eye"}
                                        />
                                        {showDelete ?
                                             <CustomButtonSS
                                             disabled={false}
                                             isLoading={false}
                                             className={`carrier-po-btn-small`}
                                             onClick={() => { handleDelete(doc)}}
                                             isIcon={true}
                                             iconClass={"fa-regular fa-trash"}
                                         /> :''}
                                    </div>
                                </li>
                            ))}
                                </ul>
                }

            </div>
        )
    }

    const uploadDocObj = {
        id: "uploadDocsPopup",
        modalLabel: "uploadDocsPopup",
        showClose: true,
        showfooter: true,
        showHeader: true,
        sendDriverNotification: false,
        modalSize: "md",
        title: AppConstant.smartScheduler.uploadAppointmentDocs.title,
        bodyText: getUploadDocsBody(),
        subText: "",
        keyboard: false,
        backdrop: "static",
        alignType: 'center',
        type: 'info',
        footerBtn: {
            btn1: {
                show: false,
                text: AppConstant.commonStrings.yes,
            },
            btn2: {
                show: false,
                text: AppConstant.commonStrings.no,
            },
        },
    }

    return (
        <>
            {show ? <SSPopup {...uploadDocObj} popupBtnHandler={() => { }} closepopup={handleClose} themeSelected={themeSelected} showModal={show} /> : ''}

        </>
    );
};

export default UploadSproDocumentsPopup;
