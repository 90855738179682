import React from 'react'
import NoData from '../../../../../Assests/Images/nodata-sm.png'
import { AppConstant } from '../../../../../Assests/AppConstant'

function NoDataFoundSS(props) {
    const { title, imageClass } = props;
    return (
         <div className='d-flex flex-column align-items-center justify-content-center w-100' data-testid='no-data-ss'>
            <img className={ imageClass} src={NoData} alt='Opps....' />
         <br />
         <h6>{title ? title :AppConstant.commonStrings.noDataFound}</h6>
     </div>
    )
}

export default NoDataFoundSS