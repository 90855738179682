import React, { useEffect, useState } from 'react'
import _ from 'lodash-es';
import { SSCarrierPO } from '../SSCarrierPO/SSCarrierPO'
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { smartScheduleSelector } from '../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import moment from 'moment';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import InitalAppointmentSelectionScreen from '../../../../../../LandingPage/SmartScheduler/BookAppointments/Components/InitalAppointmentSelectionScreen/InitalAppointmentSelectionScreen';
import AddMorePOPopupSS from '../../../Common/Popups/AddMorePOPopupSS';
import { formParticularFieldValueForAssociatedPO, formatPOwithPORelationID, showAlert } from '../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import './SSCarrierApptListScreen.css'
import SendEmailSS from '../../../../../../sendEmailSS';
import SelectRescheuleReason from '../../../../../../LandingPage/SmartScheduler/BookAppointments/Components/SelectReasonForRescheduleOrCancel/SelectRescheuleReason';

export const SSCarrierApptListScreen = (props) => {

    const { data, themeSelected = 'light-mode', screenTypeClassname,backBtn=true } = props;
    const [showAddPoModel, setShowAddPoModel] = useState(false);
    const [showSendMailModal, setShowSendEmailModal] = useState(false);
    const [selectedPoObj, setSelectedPoObj] = useState({});
    const [scheduleScreenType, setScheduleScreenType] = useState('');
    const [triggerRecalculate, setTriggerRecalculate] = useState(false);
    const ss = useSelector(smartScheduleSelector);
    const { searchedPOData, poForAppt, initiateMultiplePO, initateLoading, appointmentConfirmation, appointmentDocumentsList, addPOResponse } = ss;
    const user = useSelector(getUserSelector);
    const { userType } = user;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selectedRowData, setSelectedRowData] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCancelAppointmentModal, setShowCancelAppointmentModal] = useState(false);
    const [rescheduleReason, setRescheduleReason] = useState(null);
    const [showRescheduleReason, setShowRescheduleReason] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null)
    
    useEffect(() => {
        props?.setShowSuccess(false)
        if (appointmentConfirmation) {
            dispatch({
                type: sagaActions.ADD_APPOINTMENT_CONFIRMATION,
                payload: false,
              });
        }
        return () => {
            dispatch({type: sagaActions.CLEAR_UPLOAD_APPT_DOCS})
        }
    },[])

    useEffect(() => {
        if (_.size(searchedPOData) && props.showSuccess === false) {
            dispatch({
                type: sagaActions.ADD_APPOINTMENT_CONFIRMATION,
                payload: false,
            });
            setScheduleScreenType('text')
        }
    }, [searchedPOData])

    useEffect(()=>{
        if (addPOResponse && addPOResponse.length) {
            closeAddMorePOForm()
        }
    }, [addPOResponse])
    
    useEffect(() => {
        if (Object.keys(selectedPoObj) && Object.keys(selectedPoObj).length > 0) {
            if (selectedPoObj.action === 'email') {
                setShowSendEmailModal(true);
            } else if (selectedPoObj.action === 'delete') {
                setShowDeleteModal(true);
            }
        }
    }, [selectedPoObj])

    useEffect(() => {
        if (appointmentConfirmation && selectedPoObj.action === 'delete') {
            setSelectedPoObj({});
            setShowDeleteModal(false)
            dispatch({
                type: sagaActions.ADD_APPOINTMENT_CONFIRMATION,
                payload: false,
            });
            navigate('/myappointments')
        } else {
            if (appointmentConfirmation) {
                dispatch({
                    type: sagaActions.ADD_APPOINTMENT_CONFIRMATION,
                    payload: false,
                });
            }
        }
    }, [appointmentConfirmation])
    

    useEffect(() => {
        renderScheduleAppointmentScreen()
    }, [scheduleScreenType])

    useEffect(() => {
        if (_.size(poForAppt) && showAddPoModel) {
            setTriggerRecalculate(true)
            initiateMultiplePO && dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: false });
            setScheduleScreenType('initialscreen')
            // closeAddMorePOForm()
        } else {
            _.size(poForAppt) && setTriggerRecalculate(true)
        }
    }, [poForAppt])

    const ssSchedulehandler = (rowData) => {
        props?.setShowSuccess(false);
        setSelectedRowData(rowData);
        // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [rowData] })
        dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: searchedPOData })
        if (_.upperCase(userType) === _.upperCase('USER')) {
            // navigate('/wpscheduleappointments') // Commented to implement for new WP Schedule flow, 
            setScheduleScreenType('initialscreen')
        } else if (_.upperCase(userType) === _.upperCase('CARRIER')) {
            setScheduleScreenType('initialscreen')
        }
    }

    const openAddMorePOForm = () => {
        initiateMultiplePO && dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: false });
        if (scheduleScreenType === 'text') {
            showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.poSection.scheduleDataWarning, 'light');
            return;
        }
        setShowAddPoModel(true);
    };

    const closeAddMorePOForm = () => {
        // setEditData({});
        setShowAddPoModel(false);
    };
    const onAddRemoveHandler = (data, type) => {
        if (!_.isEmpty(selectedRowData)) {
            const masterPO = selectedRowData;
            const IDWarehouse = selectedRowData?.IDWarehouse;
            const IDDock = masterPO.IDDock;
            const LoadType = masterPO.LoadType;

            if (type === "ADD") {
                dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: true });
                // dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true });
                const newData = data.map((el) => {
                    const { poNumber, ReceivingZipCode } = el;
                    if (poNumber && ReceivingZipCode) {
                        return {
                            PoNumber: poNumber,
                            ReceivingZipCode,
                            IDDock,
                            IDWarehouse,
                            LoadType
                        };
                    }
                });

                if (_.size(data)) {
                    //   Always send last array obj as it is the newly added value, previous PO numbers cannot be edited only deleted
                    // const newData = data[data.length - 1]
                    const masterPO = poForAppt[0]
                    let isSamePO=false
                    newData.forEach((el)=>{
                        if (masterPO?.PORelationID === el.PoNumber) {
                            isSamePO=true
                        }
                    })
                    if (isSamePO) {
                        showAlert(toast.TYPE.ERROR, 'Same PO/SO cannot be added as an add-on');
                        dispatch({ type: sagaActions.INITIATE_SS_MULTIPLE_PO_LOADER, payload: false });
                        return
                    }
                    const paramData = {
                        PoNumber: newData.poNumber,
                        ReceivingZipCode: newData.ReceivingZipCode,
                        IDDock,
                        IDWarehouse,
                    }
                    dispatch({
                        type: sagaActions.ADD_REMOVE_PO,
                        payload: { payload: newData, type: type },
                    });
                }

            } else if (type === "REMOVE") {
                const newData = poForAppt.map((el) => {
                    if (el.PoNumber !== data.poNumber) {
                        return el;
                    } else {
                        return null;
                    }
                });
                const filteredData = _.compact(newData);
                const finalData = _.uniqBy(filteredData, "IDPO");
                const fData = _.find(poForAppt, { PoNumber: data.poNumber })
                if (!_.isEmpty(fData)) {
                    let wpID = null;
                    if (fData?.IDWarehouse) {
                        wpID = fData?.IDWarehouse
                    } else {
                        wpID = fData?.WarehouseData?.ID
                    }
                    const newItemPayload = {
                        // Asked by Srini to handle delete from BE
                        // PoNumber: fData?.PoNumber && fData?.PoNumber?.includes(',') ? fData?.PoNumber : fData?.PoNumber + ',',
                        PoNumber: fData?.PoNumber,
                        IDWarehouse: wpID,
                        ReceivingZipCode: fData.ReceivingZipCode,
                        ApptStartDate: fData.ApptStartDate,
                        ApptStartTime: fData.ApptStartTime,
                        ApptEndDate: fData.ApptEndDate,
                        ApptEndTime: fData.ApptEndTime,
                        ApptStatus: fData.ApptStatus,
                        Door: fData.Door,
                        ApptCreatedBy: fData.ApptCreatedBy,
                        IDDock: fData.IDDock,
                        IDCarrier: fData.IDCarrier,
                        CRUDTYPE: 'D',
                        DeletedPos: ''
                    }
                    dispatch({
                        type: sagaActions.ADD_REMOVE_PO,
                        payload: { apiPayload: newItemPayload, type: type, payload: finalData },
                    });
                }
            }
        } else {
            console.log('props?.selectedRowData has no data')
        }
    };

    const openAddMorePO = () => {
        return (
            <AddMorePOPopupSS
                closeSendMailpopup={closeAddMorePOForm}
                zipCode={selectedRowData?.WarehouseData?.Zip}
                actionHandler={(data, type) => {
                    onAddRemoveHandler(data, type);
                }}
                initateLoading={initiateMultiplePO}
                poForAppt={poForAppt}
            />
        );
    };
    const showAddPoPopupSS = () => {
        const popupObj = {
            id: "addPO",
            modalLabel: "addPO",
            showClose: true,
            showfooter: false,
            showHeader: true,

            sendDriverNotification: false,
            modalSize: "md",
            title: "Add PO/SO's",
            bodyText: openAddMorePO(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle,
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
                        .saveButtonTitle,
                },
            },
        };

        return (
            <SSPopup
                {...popupObj}
                type='info'
                alignType='center'
                popupBtnHandler={(text) => () => ""}
                closepopup={closeAddMorePOForm}
                themeSelected={themeSelected}
                showModal={showAddPoModel}
            />
        );
    };

    const checkIfIsPast = (el) => {
        if (el.length) {
            let tempEl = el[0];
            if (_.upperCase(tempEl?.ApptScheStatus) === 'COMPLETED' || _.upperCase(tempEl?.ApptScheStatus) === 'NOT SCHEDULED') {
                return true
            } else {
                return false
            }
        }
    }
    // Function to validate if the appointment is past or not and render upload popup content accordingly
    const uploadDocumentValidation = (el) => {
        if (el.length) {
            let tempEl = el[0];
            if (_.upperCase(tempEl?.ApptScheStatus) === 'COMPLETED' && userType && _.upperCase(userType)==='CARRIER') {
                return true
            } else {
                return false
            }
        }
    }

    const renderApptTableRows = () => {

        return formatPOwithPORelationID(data).map((ele, rowIndex) => {
            if (rowIndex === 0) {
                return (<SSCarrierPO
                    isPast={checkIfIsPast(ele)}
                    userType={userType}
                    rowIndex={rowIndex}
                    searchedPOData={data}
                    rowData={ele[0]}
                    poList={ele}
                    poForAppt={poForAppt}
                    showSuccess={props.showSuccess}
                    openAddMorePOForm={openAddMorePOForm}
                    triggerRecalculate={triggerRecalculate}
                    onEmailClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'email' })}
                    onDeleteClickHandler={(rowData) => setSelectedPoObj({ ...rowData, 'action': 'delete' })}
                    // onEmailClickHandler={(rowData) => openSendEmailForm(rowData)
                    // }
                    ssSchedulehandler={(rowData) => ssSchedulehandler(rowData)}
                    appointmentDocumentsList={appointmentDocumentsList}
                    uploadDocsHandler={uploadDocsHandler}
                    docDeleteHandler={docDeleteHandler}
                    initateLoading={initateLoading}
                    showDelete={uploadDocumentValidation(ele)? false: true}
                    showUpload={uploadDocumentValidation(ele)? false: true}
                />)
            }
        })


    }

    const renderText = () => {
        if (_.size(data)) {
            const masterPO = data[0];
            let currentTime = moment().utc()
        let validApptLifeTime = moment(masterPO?.ApptEndTime).add(masterPO?.ApptLifeTime,"hours")
            const dueDate = moment(masterPO.DueDate).utc().startOf('day');
            const todayDate = moment().utc().startOf('day')
            if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Date Expired')) {
                return <span className='text-red'>{AppConstant.smartScheduler.searchAppointments.dateExpiredMassage}</span>
            }
            else if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Not Scheduled')) {
                return 'Click on Schedule to proceed'
            } else if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Scheduled')) {
                return 'Click on Reschedule to proceed'
            } else if (_.upperCase(masterPO.ApptScheStatus) === _.upperCase('Completed')) {
                return _.upperCase(userType) === 'CARRIER' || currentTime > validApptLifeTime ? 'Appointment is already completed' : 'Click on Reschedule to proceed'
            }
        }
    }

    const renderScheduleAppointmentScreen = () => {
        switch (scheduleScreenType) {
            case 'text':
                return (
                    <div className='d-flex justify-content-center align-items-center w-full'>
                        <span className='text-center bolderText'>{renderText()}</span>
                    </div>
                )
            case 'initialscreen':
                return (
                    <InitalAppointmentSelectionScreen showSuccess={props.showSuccess} setShowSucces={props.setShowSuccess} rowData={data} selectedRowData={selectedRowData} userType={userType} triggerReCalculate={triggerRecalculate} setTriggerRecalculate={setTriggerRecalculate} {...props} />
                    //     <div className='calender-wrapper'>
                    //     <p className='fw-semibold p-2'>Select Date & Time</p>
                    //     <div className='w-75'>
                    //         <DatePickerSS minDate={new Date(2023, 9, 23)} maxDate={new Date(2023, 9, 28)} />
                    //     </div>
                    // </div>
                )
            default:
                break;
        }
    }

    const formFieldListViewAppointment = [
        {

            type: "string",
            size: "col-sm-12",
            position: 1,
            name: "Po Number",
            value: selectedPoObj.PoNumber,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 2,
            name: "Dock",
            value: selectedPoObj.DockName,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 3,
            name: "Commodity",
            value: selectedPoObj.Commodity,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 4,
            name: "Buyer",
            value: selectedPoObj.BuyerName,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 5,
            name: "Vendor",
            value: selectedPoObj.VendorName,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 6,
            name: "Quantity",
            value: selectedPoObj.Quantity,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 7,
            name: "Cases",
            value: selectedPoObj.ItemsCases,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 8,
            name: "Pallets",
            value: selectedPoObj.Pallets,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 9,
            name: "Weight",
            value: selectedPoObj.Weight,
            formValidation: {
                required: true,
            },
            disabledField: true
        }, {

            type: "string",
            size: "col-sm-12",
            position: 10,
            name: "Warehouse",
            value: selectedPoObj.WarehouseName,
            formValidation: {
                required: true,
            },
            disabledField: true
        }, {

            type: "string",
            size: "col-sm-12",
            position: 10,
            name: "ZipCode",
            value: selectedPoObj.ReceivingZipCode,
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 11,
            name: "Appointment Start",
            value: moment(selectedPoObj.ApptStartTime).format('DD/MM/YYYY HH:mm A'),
            formValidation: {
                required: true,
            },
            disabledField: true
        },
        {

            type: "string",
            size: "col-sm-12",
            position: 13,
            name: "Due Date",
            value: moment(selectedPoObj.DueDate).format('DD/MM/YYYY HH:mm A'),
            formValidation: {
                required: true,
            },
            disabledField: true
        }
    ]

    const openSendEmailForm = (rowData) => {
        setSelectedPoObj(rowData);
    }

    const closeSendMailpopup = () => {
        setShowSendEmailModal(false);
        setSelectedPoObj({})
    }
    const openSendEmail = () => {
        return <SendEmailSS closeSendMailpopup={closeSendMailpopup} formFieldList={formFieldListViewAppointment} poDetails={selectedPoObj}></SendEmailSS>
    }
    const showSendEmailPopup = () => {
        const popupObj = {
            id: 'createAppointment',
            modalLabel: 'sendEmail',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: 'Send Email',
            bodyText: openSendEmail(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeSendMailpopup} themeSelected={themeSelected} showModal={showSendMailModal} />
    }

    function createWarningText(mPO, associatedPO, arr) {
        return <div className='boldText'>
            <span> Are you sure, you want to Cancel Appointment for PO/SO: {mPO} </span>
            {arr.length > 1 ? <><span> and its associated PO/SOs :  </span>
                <span className='textRed'>{associatedPO} ?</span></> : <span>?</span>}
        </div>; // This will render HTML
    }
    
    const deletePoAppt = () => {

        const newItemPayload = {
            PoNumber: selectedPoObj.PORelationID,
            IDWarehouse: selectedPoObj.WarehouseData.ID,
            ReceivingZipCode: selectedPoObj.ReceivingZipCode,
            ApptStartDate: selectedPoObj.ApptStartDate,
            ApptStartTime: selectedPoObj.ApptStartTime,
            ApptEndDate: selectedPoObj.ApptEndDate,
            ApptEndTime: selectedPoObj.ApptEndTime,
            ApptStatus: selectedPoObj.ApptStatus,
            Door: selectedPoObj.Door,
            ApptCreatedBy: selectedPoObj.ApptCreatedBy,
            IDDock: selectedPoObj.IDDock,
            IDCarrier: selectedPoObj.IDCarrier,
            CRUDTYPE: 'D',
            DeletedPos: '',
            rescheduleReason: rescheduleReason
        }
        let fd = new FormData();
        fd.append('DocName', null); // Append the document name
        fd.append('AppointmentNumber', selectedPoObj?.AppointmentNumber)
        fd.append('CRUDTYPE', 'S');
        dispatch({
            type: sagaActions.ADD_APPOINTMENT, payload: {
                deleteApptData: newItemPayload,
                deleteUploadDocsData: fd,
                CRUDTYPE: 'D',
        } })
        // setDeletePO(true);
        // setListLoading(true);
        // setSelectedPoObj({})
    }

    /**
     * Function to render delete po popup body
     * @param {*} mPO 
     * @param {*} associatedPO 
     * @param {*} arr 
     * @returns 
     */
    function deletePopupBody(mPO, associatedPO, arr){
        return(
            <>
            {createWarningText(mPO, associatedPO, arr)}
            <br/>
            {<SelectRescheuleReason rescheduleReason={rescheduleReason} setRescheduleReason={setRescheduleReason} onSubmit={()=>{setShowRescheduleReason(false)}} type='Cancel' errorMsg={errorMsg}/>}
            </>
        )
    }

    const showDeletePopup = () => {
        const masterPO = selectedPoObj;
        const fieldDataAssociated = formParticularFieldValueForAssociatedPO(searchedPOData)
                const popupObj = {
                    id: 'deleteAppointment',
                    modalLabel: 'deleteEmail',
                    showClose: true,
                    showfooter: true,
                    showHeader: true,
                    sendDriverNotification: false,
                    modalSize: 'md',
                    title: AppConstant.commonStrings.cancelApptTitle,
                    bodyText: deletePopupBody(masterPO?.PoNumber, fieldDataAssociated?.po_number, searchedPOData),
                    subText: "",
                    keyboard: false,
                    backdrop: "static",
                    footerBtn: {
                        btn1: {
                            show: true,
                            text: AppConstant.commonStrings.yes,
                            loading: initateLoading
                        },
                        btn2: {
                            show: true,
                            text: AppConstant.commonStrings.no
                        }
                    },
                }

                return <SSPopup {...popupObj} popupBtnHandler={(text) => deletePoPopupHandler(text)} closepopup={closeDeletePopopup} themeSelected={themeSelected} showModal={showDeleteModal} />
    }

    const cancelApptPopupHandler = async(text) => {
        if (text === AppConstant.commonStrings.proceed) { 
            dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
        } 
        setShowCancelAppointmentModal(false)
    }

    const closeCancelApptPopup = () => {
        setShowCancelAppointmentModal(false);
    }

    const showCancelAppointmentPopup = () => {
        // const masterPO = selectedPoObj;
        // const fieldDataAssociated = formParticularFieldValueForAssociatedPO(searchedPOData)
                const popupObj = {
                    id: 'cancelAppointment',
                    modalLabel: 'cancelAppointment',
                    showClose: true,
                    showfooter: true,
                    showHeader: true,
                    sendDriverNotification: false,
                    modalSize: 'md',
                    title: 'Cancel appointment scheduling',
                    bodyText: AppConstant.smartScheduler.myAppointments.cancelAppointmentNotificationText,
                    subText: "",
                    keyboard: false,
                    backdrop: "static",
                    footerBtn: {
                        btn1: {
                            show: true,
                            text: AppConstant.commonStrings.proceed,
                            // loading: initateLoading
                        },
                        btn2: {
                            show: true,
                            text: AppConstant.commonStrings.cancel
                        }
                    },
                }

                return <SSPopup {...popupObj} popupBtnHandler={(text) => cancelApptPopupHandler(text)} closepopup={closeCancelApptPopup} themeSelected={themeSelected} showModal={showCancelAppointmentModal} />
    }
    

    const deletePoPopupHandler = (text) => {

        if (text === AppConstant.commonStrings.yes) {
            if (!rescheduleReason) {
                setErrorMsg(AppConstant.help.required)
            }
            else{
                deletePoAppt()
                setRescheduleReason(null)
                setErrorMsg(null)
            }
        } else {
            setShowDeleteModal(false)
            setSelectedPoObj({})
            setRescheduleReason(null)
            setErrorMsg(null)
        }
    }

    const closeDeletePopopup = () => {
        setShowDeleteModal(false);
        setRescheduleReason(null)
        setErrorMsg(null)
    }

    /**
     * Handler function to dispatch payload for upload document
     * @param {*} data 
     * @param {*} docName 
     */
    const uploadDocsHandler = async (data, docName) => {
        dispatch({type: sagaActions.INTIATE_SS_LOADER, payload: true})
        let fd = new FormData();
        if (data && docName) {
            const ApptDocsCreatedBy = user?.cu_id;
            // fd.append('file', data); // Assuming 'file' is the file object
            fd.append('contentType', data.type)
            fd.append('DocName', docName); // Append the document name
            fd.append('AppointmentNumber', searchedPOData[0].AppointmentNumber)
            fd.append('ApptDocsCreatedBy', ApptDocsCreatedBy);
            fd.append('ApptDocsUpdatedBy', null);
            fd.append('ID', null);
            // fd.append('CRUDTYPE', searchedPOData[0].AppointmentNumber ? 'BI' :'I');
            fd.append('CRUDTYPE', 'I');
            
        } else {
            console.log('Data or DocName not present')
        }
        dispatch({
            type: sagaActions.UPLOAD_APPT_DOCS,
            payload: {
                CRUDTYPE: 'I',
                uploadData: fd,
                file: data,
                AppointmentNumber: searchedPOData[0].AppointmentNumber
            }
        });
    }
    /**
     * Handler function to dispatch payload to delete document
     * @param {*} data 
     */
    const docDeleteHandler = (data) => {
        let fd = new FormData();
        fd.append('DocName', data.DocName); // Append the document name
        fd.append('AppointmentNumber', searchedPOData[0].AppointmentNumber)
        fd.append('CRUDTYPE', searchedPOData[0].AppointmentNumber ? 'BD' :'D');
        dispatch({
            type: sagaActions.UPLOAD_APPT_DOCS,
            payload: {
                CRUDTYPE: searchedPOData[0].AppointmentNumber ? 'BD' :'D',
                uploadData: fd,
                DocName: data.DocName
            }
        });

    }

    const backBtnHandler = () => {
        if (_.size(data) && !data[0]?.AppointmentNumber && _.size(appointmentDocumentsList)) {
           setShowCancelAppointmentModal(true)
            
        } else {
            dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
        }
    }

    return (
        <div className='d-flex justify-content-between gap-3 w-100 po-schedule-ss'>
            <div>
                {backBtn &&
                <CustomButtonSS
                    type="submit"
                    isLoading={false}
                    className='secondary-btn-ss mb-2 fw-semibold'
                    isIcon={true}
                    iconClass='fa-solid fa-angle-left'
                    onClick={backBtnHandler}
                    title={'Order Search'}></CustomButtonSS>
                }
                {_.size(data) && renderApptTableRows()}
            </div>
            {_.size(data) && renderScheduleAppointmentScreen()}
            {showAddPoPopupSS()}
            {showSendEmailPopup()}
            {showDeletePopup()}
            {showCancelAppointmentPopup()}
        </div>
    )
}
