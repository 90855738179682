import React from 'react'
import UserInformation from '../UserInformation/UserInformation';
import SettingChangePassword from '../SettingsChangePassword/SettingsChangePassword';
/**
 * This component is wrapper component for
 * UserInformation and SettingChangePassword
 * @param {*} props 
 * @returns 
 */
const AccountSettings = (props) => {
    const { themeSelected } = props;
    return ( 
        <div className={`container-fluid settings-wrapper ${themeSelected} p-0 m-0`}>
        <div className="row mx-4 mt-4 mb-2">
            <   div className={`col-4 user-info-wrapper ${themeSelected}`}>
                <UserInformation {...props}/>
            </div>
            <div className={`col-7 user-password-wrapper ${themeSelected} ms-2 flex-fill`}>
                <SettingChangePassword  {...props}/>
            </div>
        </div>
    
</div>
     );
}
 
export default AccountSettings;