import _, { random } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import WarehouseSearchSS from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS';
import { CustomDynamicFormSS } from '../../../../../Common/CustomForm/CustomDynamicFormSS';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';
import { shouldHavePermission, showAlert, updateCarrierSubUserPayload, updateUserPayload } from '../../../../../../Assests/Utility';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import UserTable from '../../../../../LandingPage/Components/UserWrapper/Children/UserTable';
import UserTableSS from './UserTableSS';


const UserListSS = (props) => {
    const { themeSelected } = props;
    const [createUser, setCreateUser] = useState({});
    const [userPopupHeading, setUserPopupHeading] = useState(AppConstant.user.popup.createUser.title)
    const dispatch = useDispatch();
    const user = useSelector(getUserSelector);
    const { permissions, closeCompanyForm, error, cu_id, userType, parentWarehouse, userAccountDetails, initateLoading, carrierSubUserList, actionLoader } = user;
    const { IDCarrier } = userAccountDetails;
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState({});
    const [resetFormData, setResetFormData] = useState(0);
    const [searchStr, setSearchStr] = useState('');

    const getActiveUserCount = () => {
        if (_.size(carrierSubUserList)) {
            return _.size(_.groupBy(carrierSubUserList, "EnableStatus").true)
        }
        else {
            return 0
        }
    }

    useEffect(() => {
        closepopup()
    }, [carrierSubUserList])


    const showCreateUserPopup = () => {
        const popupObj = {
            id: 'createUserFormPopup',
            modalLabel: 'createUserFormPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            className: "createUserPopup",
            title: userPopupHeading,
            bodyText: createForm(),
            subText: "",
            keyboard: true,
            backdrop: true,
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.login.save
                }
            },
        }



        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closepopup} themeSelected={themeSelected} showModal={showModal} />
    }
    const showEditUserPopup = () => {

        const popupObj = {
            id: 'editUserFormPopup',
            modalLabel: 'editUserFormPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            className: "createUserPopup",
            title: AppConstant.user.popup.editUser.title,
            bodyText: createForm(),
            subText: "",
            keyboard: true,
            backdrop: true,
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.update
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closepopup} themeSelected={themeSelected} showModal={showModal} />
    }


    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const editClicked = (data, index) => {
        const tempData = updateUserPayload(data, false);
        setShowModal(true);
        setEditData({ ...tempData })
        setUserPopupHeading(AppConstant.user.popup.editUser.title)
    }

    const getSummitedFormData = (data) => {

        setLoading(true)
        if (data?.crud_type === 'U') {
            if (!_.isEmpty(data)) {
                let tempData = { ...data }
                tempData.user_name = data.email
                tempData.p_cu_id = userType === AppConstant.userPermission.usertypes.superAdmin ? null : cu_id
                const contactNum = tempData.phone_number.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '');
                tempData.phone_number = '+1' + contactNum;
                tempData.IDCarrier = IDCarrier;
                tempData.createdby = cu_id;
                dispatch({ type: sagaActions.UPDATE_CARRIER_SUB_USER, payload: updateCarrierSubUserPayload(tempData) })
            } else {
                showAlert(toast.TYPE.WARNING, AppConstant.user.toast.blankForm, themeSelected)
            }
        } else {
            setCreateUser({ ...data })
            if (!_.isEmpty(data)) {
                let tempData = { ...data }
                tempData.user_name = data.email
                tempData.p_cu_id = userType === AppConstant.userPermission.usertypes.superAdmin ? null : cu_id
                const contactNum = tempData.phone_number.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '');
                tempData.phone_number = '+1' + contactNum;
                tempData.IDCarrier = IDCarrier;
                dispatch({ type: sagaActions.REGISTER_CARRIER_SUB_USER, payload: tempData })
            } else {
                showAlert(toast.TYPE.WARNING, AppConstant.user.toast.blankForm, themeSelected)
            }
        }
        setLoading(false)
    };

    const formFieldList = [
        {
            name: "email",
            type: "text",
            size: "col-md-12",
            position: 1,
            title: AppConstant.user.popup.createUser.fields.user_name,
            formValidation: {
                required: true,
                validateEmail: true,
            },
            isEditDisable: true
        },
        {
            name: "given_name",
            type: "text",
            size: "col-md-12",
            position: 2,
            title: AppConstant.user.popup.createUser.fields.given_name_title,
            formValidation: {
                required: true,
            },
        },

        {
            name: "family_name",
            type: "text",
            size: "col-md-12",
            position: 3,
            title:
                AppConstant.user.popup.createUser.fields.family_name_title,
            formValidation: {
                required: true,
            },
        },
        {
            name: "phone_number",
            type: "text",
            size: "col-md-12",
            position: 3,
            title:
                AppConstant.user.popup.createUser.fields.phone_number_title,
            formValidation: {
                required: true,
                validateContactNum: true,
                customError: AppConstant.commonStrings.phoneCustomError
            },
            placeholder: AppConstant.user.popup.createUser.phone_placeHolder

        },

    ];

    const createForm = () => {
        return (
            <CustomDynamicFormSS
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={false}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                editFormData={editData}
                loading={actionLoader}
                resetFormData={resetFormData}
                // customDisableSave={true}
            />
        );
    };
    const [showModal, setShowModal] = useState(false);
    const closepopup = () => {

        setEditData({});
        setShowModal(false);
    }




    return (<div className='mt-2'>
        <h4 className='container-fluid'>Users</h4>
        <br />
        <div className='container-fluid d-flex py-2 text-start justify-content-between sp-user-header'>
            <CustomButtonSS
                dataBsToggle="modal"
                dataBsTarget={`#createUserFormPopup`}
                onClick={() => {
                    if (getActiveUserCount() < 2) {
                        setCreateUser({})
                        setUserPopupHeading(AppConstant.user.popup.createUser.title)
                        setShowModal(true);
                    }
                    else {
                        showAlert(toast.TYPE.ERROR, AppConstant.commonStrings.carrierSubUserLimitExceed, themeSelected)
                    }
                }}
                className={'btn-highlighted f-14 '}
                disabled={false}
                // isLoading={isLoading}
                title={AppConstant.user.createBtnTitle}
            />
            <div className=''>
                <WarehouseSearchSS
                    placeholder={'Search Users'}
                    inputValue={searchStr}
                    cancelSearch={() => setSearchStr('')}
                    onChangeHandler={(text) => setSearchStr(text)} />
            </div>
        </div>


        <div className="userListTable mt-1">
            <UserTableSS searchStr={searchStr}  {...props} setCreateUser={setCreateUser} allowPermissionED={getActiveUserCount} editUserAction={(userObj, index) => editClicked(userObj, index)} />
            {showCreateUserPopup()}
            {/* {showEditUserPopup()} */}

        </div>
    </div>);
}

export default UserListSS;