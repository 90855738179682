import React, { useEffect, useState } from 'react'
import CustomButton from '../../../Common/CustomButton/CustomButton';
import Seperator from '../../../Common/Seperator/Seperator';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import PasswordInput from '../../../Common/PasswordInput/PasswordInput';
import ClickToResend from '../../components/ClickToResend';
import { showAlert, formatFirstCharBold } from '../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { isValid, isValidPassword, isValidSubmission } from '../../utils';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import _ from 'lodash-es';
import TryLogin from '../../components/TryLogin';
import CustomButtonSS from '../../../Common/CustomButton/CustomButtonSS';
import InputWithTtileSS from '../../../Common/CustomInput/InputWithTtileSS';
import TruckComponent from '../MainLogin/SchedulePro/MainLogin/Components/TruckComponent';
import PasswordInputSS from '../../../Common/PasswordInput/PasswordInputSS';

const ChangePasswordSS = (props) => {
    const dispatch = useDispatch()
    const { themeSelected } = props;
    const [verficationCode, setVerificationCode] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [verficationError, setVerficationError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [isloading, setisloading] = useState(false)
    const user = useSelector(getAuthReducer);
    const navigate = useNavigate();
    const userPermission = useSelector(getUserSelector);
    const { branding } = userPermission;
    const [bgImage, setBgImage] = useState('')
    const [warehouseLogo, setWarehouseLogo] = useState('');
    const backgroundImage = AppConstant.commonStrings.bkgImgLink;

    useEffect(() => {
        if (user.isPasswordResetSuccess) {
            showAlert(toast.TYPE.SUCCESS, AppConstant.notifications.login.passwordResetSuccess);
            dispatch({ type: sagaActions.RESET_PASSWORD_SUCCESS })
            navigate("/")
            setisloading(false)
        } else {
            setisloading(false)
        }
    }, [user.isPasswordResetSuccess])

    useEffect(() => {
        if (isValid('password', password) && isValid('password', confirmPassword)) {
            setPasswordError(false)
        } else {
            setPasswordError(true)
        }
    }, [password, confirmPassword])

    useEffect(() => {
        if (verficationCode.length > 6) {
            setVerficationError(true)
        } else {
            setVerficationError(false)
        }
    }, [verficationCode])

    const initiateChangePassword = () => {
        if (isValid('password', password) && isValid('password', confirmPassword) && password === confirmPassword) {
            if (verficationCode) {
                if (password.length === 0) {
                    showAlert(toast.TYPE.WARNING, AppConstant.notifications.login.blankPassword, themeSelected)

                }
                else {
                    setisloading(true)
                    dispatch(({ type: sagaActions.RESET_PASSWORD_INTIATED, payload: { password: password, code: verficationCode, email: user.recoveryEmail, themeSelected } }))
                    setisloading(false)
                }
            }
            else {
                showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.verficationCodeBlank, themeSelected);
            }
        }
        else {
            if (!isValid('password', password) || !isValid('password', confirmPassword)) {
                showAlert(toast.TYPE.WARNING, AppConstant.login.passwordValidation, themeSelected)
            } else {
                showAlert(toast.TYPE.WARNING, AppConstant.notifications.login.passConformPassCheck, themeSelected)

            }
        }
    }
    useEffect(() => {
        if (!_.isEmpty(branding)) {
            const { IsBranding, WarehouseLogo, BackgroundImage } = branding
            if (IsBranding) {
                setBgImage(BackgroundImage)
                setWarehouseLogo(WarehouseLogo)
            }
        }
    }, [branding])
    return (
        <div className='login-wrapper'>
            <div
                className="container-fluid d-flex justify-content-between p-4"
                id="container"
            >
                <TruckComponent />
                <div className="inner_blocks" id="right-block">
                    <div id="logo-holder">
                        <img src={AppConstant.commonStrings.schedulerProLogo} alt="FrieghtSmith logo" />
                    </div>
                    {/* -------------------- Form Section ------------------------*/}
                    <div className="form-section">
                        <div className='d-flex flex-column justify-content-start align-items-start'>
                            <div className='my-2'>
                                <h5>{AppConstant.login.changePassword}</h5>
                                <p id="login-sub-header m-0">{AppConstant.login.recover.t3}</p>
                            </div>
                            <div className='w-100 my-2'>
                                <PasswordInputSS
                                    errorMessage={AppConstant.login.verficationCodeIncorrect}
                                    showError={verficationError}
                                    title={AppConstant.login.verficationCodeTitle} placeHolder={AppConstant.login.enterVerificationCode}
                                    onPasswordChange={(e) => setVerificationCode(e)} />
                                <PasswordInputSS
                                    errorMessage={AppConstant.login.passwordValidation}
                                    showError={passwordError}
                                    title={AppConstant.login.newPassword} placeHolder={AppConstant.login.enterHere}
                                    onPasswordChange={(e) => setPassword(e)} disabled={verficationCode.length < 5 ? true : false} showInfo={true} passwordInfo={AppConstant.login.recover.passwordInfoTooltip} />
                                <PasswordInputSS
                                    errorMessage={AppConstant.login.passwordValidation}
                                    showError={passwordError}
                                    title={AppConstant.login.confirmNewPassword} placeHolder={AppConstant.login.enterHere}
                                    onPasswordChange={(e) => setConfirmPassword(e)}
                                    disabled={verficationCode.length < 5 ? true : false} />
                                <div className="d-flex justify-content-between w-100 my-3">
                                    <CustomButtonSS
                                        isLoading={isloading}
                                        title={AppConstant.commonStrings.submit} className={"btn-submit"}
                                        onClick={() => initiateChangePassword()}
                                    />
                                    <span>
                                        <a
                                            href="/schedulepro/recover"
                                            style={{ color: "#10C6F5" }}
                                            className="mx-1 fw-semibold"
                                        >
                                            {AppConstant.login.resendCode}
                                        </a>
                                    </span>
                                </div>
                                <div className="fs-10 d-flex flex-column justify-content-center align-items-center p-0 mt-2">
                                    <p>{AppConstant.login.recover.tryLoginText}</p>
                                    <span>
                                        <a
                                            href="/"
                                            style={{ color: "#10C6F5" }}
                                            className="mx-1 fw-semibold"
                                        >
                                            {AppConstant.login.recover.tryLoginButtonTitle}
                                        </a>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordSS;
