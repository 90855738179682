import React from 'react';
/**
 * This is reusable component for enable/disable toggle switch
 * @param {*} props 
 * title = label for toggle
 * value = toggle value
 * onChangeValue = toggle switch handler
 * allowPermissionD = permisson to whether toggle switch will be active or disabled
 * @returns 
 */
const CommonToggleSwitch = (props) => {
    const { title, value, onChangeValue, allowPermissionD } = props;

    return (
        <div className="form-check form-switch " data-testid='common_toogle_switch'>
            {
                value ?
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={value}
                        id="flexSwitchCheckChecked"
                        checked
                        onChange={(event) => onChangeValue(event)}
                        disabled={allowPermissionD ? false : true}
                        data-testid='common_toogle_switch_enabled'
                    />
                    :
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={value}
                        id="flexSwitchCheckChecked"
                        onChange={(event) => onChangeValue(event)
                        }
                        disabled={allowPermissionD ? false : true}
                         data-testid='common_toogle_switch_disabled'
                    />
            }
            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{title}</label>
        </div>
    )
}
export default CommonToggleSwitch;