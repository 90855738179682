import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    getLoadLoading: false,
    selectedLoad: {},
    doorList: [],
    enableDoorSection: false,
    assignedDoor: [],
    confirmDoor: false,
    confirmDoorLoader: false,
    dock_id: '',
    startDoorAcknowledgementTimer: false,
    truck_load_status : '',
    releasedDoorLoadsArr : false,
}

export const getLoadReducerSlice = createSlice({
    name: 'getLoadReducer',
    initialState,
    reducers: {
        initiateGetLoad: (state) => {
            state.getLoadLoading = true;
            state.selectedLoad = {};
            state.doorList = []
        },
        getLoadSuccess: (state, action) => {
            state.getLoadLoading = false;
            state.selectedLoad = { ...action.payload.response, dock_id: action.payload.dock_id, 
                truck_load_status : action.payload.truck_load_status };
            state.dock_id = action.payload.dock_id;
            state.truck_load_status = action.payload.truck_load_status;
        },
        getDoorListSuccess: (state, action) => {
            state.getLoadLoading = false;
            state.doorList = action.payload.doorList;
        },
        getDoorListFailure: (state) => {
            state.getLoadLoading = false;
            state.doorList = [];
        },
        assignDoorSuccess: (state, action) => {
            state.assignedDoor = action.payload
            state.startDoorAcknowledgementTimer = action.payload[0].driverNotified ? true: false
        },
        alreadyAssignDoorSuccess: (state, action) => {
            state.assignedDoor = action.payload
        },
        assignDoorFailure: (state) => {
            state.assignedDoor = []
        },
        getLoadFailure: (state) => {
            state.getLoadLoading = false;
            state.assignedDoor = [];
            state.dock_id = '';
            state.selectedLoad = {};
            state.truck_load_status = '';
            // state.enableDoorSection = false;
            // state.assignedDoor = {};
        },
        resetTimer: (state) => {
            state.startDoorAcknowledgementTimer = false
        },
        enableDoor: (state, action) => {
            state.enableDoorSection = action.payload
        },
        updateDockID: (state, action) => {
            state.dock_id = action.payload.dock;
            state.selectedLoad = { ...state.selectedLoad, dock_id: action.payload.dock };
        },
        releaseDoorReducer: (state, action) => {
            state.releasedDoorLoadsArr = action.payload;
        },
       confirmDoor: (state, action) => {
            state.confirmDoor = action.payload;
        },
        confirmDoorLoader: (state, action) => {
            state.confirmDoorLoader = action.payload;
        },
        updateStartDoorAcknowledgementTimer: (state, action) => {
            state.startDoorAcknowledgementTimer = action.payload
        }
    }
});

export const { initiateGetLoad, getLoadSuccess, getLoadFailure, getDoorListSuccess, alreadyAssignDoorSuccess, assignDoorSuccess, assignDoorFailure, resetTimer, enableDoor, getDoorListFailure, updateDockID, releaseDoorReducer, confirmDoor, confirmDoorLoader, updateStartDoorAcknowledgementTimer } = getLoadReducerSlice.actions;

// Selectors
export const getLoadSelector = (state) => {
    return state.getLoadReducer;
};

export default getLoadReducerSlice.reducer;
