import React, { useEffect, useState } from 'react';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import PODockStatusFilter from '../../Components/POFilters/PODockStatusFilter/PODockStatusFilter';
import UserAssign from '../../Components/UserWrapper/Wrapper/UserAssign';

const UserAssignWrappper = (props) => {
    const {themeSelected} = props
   

  

    return (
                <div className='container-fluid executor'>
                    <Header {...props}/>
                    <Navigationbar {...props}/>
                    {/* <PODockStatusFilter themeSelected={themeSelected} /> */}
                    <UserAssign themeSelected={themeSelected} />
                    
                    
                </div>
    )
}

export default UserAssignWrappper;