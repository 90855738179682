import React, { useEffect, useState } from 'react';

import ScheduleProWrapper from '../../ScheduleProWrapper';
import UserList from '../../../../LandingPage/Components/UserWrapper/Wrapper/UserList';
import './UserWrapperSS.css'
import UserListSS from './Children/UserListSS';

const UserWrapperSS = (props) => {
    const { themeSelected } = props
    return (
        <ScheduleProWrapper>
            <div className='container-fluid user-ss-wrapper p-3'>
                <UserListSS themeSelected={themeSelected} />
            </div>
        </ScheduleProWrapper>
    )
}

export default UserWrapperSS;