import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import { fetchDockListCommonFunc } from '../DocksMeta/Common/FetchDockList';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { getWhMetaDataReducerSlice } from '../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { showAlert, changeFormatWithoutTz } from '../../../../../../Assests/Utility';
import { fetchTimeslotListCommonFunc } from './Common/FetchTimeslotsList';
import { getCurrentDate } from '../../../../../../../Utils/DateUtils';
import { toast } from 'react-toastify';
import _ from 'lodash-es';
import './DockDoorsForm.css';
import WarehouseSearchSS from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';
import { CustomDynamicFormSS } from '../../../../../Common/CustomForm/CustomDynamicFormSS';
import TimeslotMetaListSS from './DockMetaList/TimeslotMetaListSS';
import { useNavigate } from 'react-router-dom';

const DockDoorsFormSS = (props) => {

    const { themeSelected, allowPermissionD = true, whObj } = props;
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [searchStr, setSearchStr] = useState('');
    const [editData, setEditData] = useState({});
    const [loading, setLoading] = useState(false);
    const [resetFormData, setResetFormData] = useState(0);
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id } = user;
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const { whMetaDocksList, operationObj ,preCheckList} = whMetaData;
    const [showPreCheckModal, setShowPreCheckModal] = useState(false);
    const navigate = useNavigate()
    const firstUpdate = useRef(true);

    useEffect(() => {
        dispatch({type:sagaActions.UPDATE_PRE_CHECK_LIST,payload:[]})
        fetchDockList();
    }, [])

    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
          }
        if (preCheckList.length>0) {
            setShowModal(false)
            setLoading(false)
            setShowPreCheckModal(true)
        }
    },[preCheckList])

    useEffect(() => {

        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Add Timeslot':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.timeslots.createTimeslotSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.timeslots.createTimeslotFailure);
                    }
                    break;
                case 'Edit Timeslot':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.timeslots.updateTimeslotSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.timeslots.updateTimeslotFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    const handleApiResponse = (result, message) => {
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            setShowModal(false)
            fetchTimeslotList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        setLoading(false)
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }

    const fetchTimeslotList = () => {
        const { apiParams, operationObj } = fetchTimeslotListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_TIMESLOT_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const getDockList = () => {
        const docksList = whMetaDocksList && whMetaDocksList.length && whMetaDocksList.map((ele, index) => {
            if (ele && ele.DockStatus) {
                return (
                    <option key={ele.Name} value={ele.ID}>
                        {ele.Name}
                    </option>
                );
            } else {
                return null;
            }
        })
        return _.compact(docksList);
    }

    const fetchDockList = () => {
        const { apiParams, operationObj } = fetchDockListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    };

    const formFieldList = [
        {
            name: "IDDock",
            type: "select",
            size: "col-sm-6",
            position: 1,
            title: 'Docks',
            formValidation: {
                required: true,
            },
            getSelectData: getDockList,
        },
        {
            name: "TimeSlotName",
            type: "text",
            size: "col-sm-6",
            position: 2,
            title: 'Timeslot',
            formValidation: {
                required: true,
            },
        },
        // {
        //     name: "DisplayOrderNo",
        //     type: "number",
        //     size: "col-sm-6",
        //     position: 3,
        //     title: 'Order of Display',
        //     formValidation: {
        //         required: true,
        //         validateMinValue: {
        //             minValue: 1,
        //         }
        //     },
        // },
        {
            name: "TimeSlotStatus",
            type: "radio",
            size: "col-sm-12 mt-2 radioButtonText",
            position: 3,
            title: 'Select:',
            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: "Schedule Visible Externally",
                value: "ALL"
            },
            {
                label: "Schedule Visible to Receiver Only",
                value: "WH"
            }]
        },
    ]

    const createForm = () => {
        return (
            <CustomDynamicFormSS
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
            />
        );
    };

    const editTimeslot = (data) => {
        const editData1 = {
            "Warehouse": data.Warehouse,
            "Dock": data.Dock,
            "DoorType": data.DoorType,
            "TimeSlotName": data.TimeSlotName,
            "DisplayOrderNo": data.DisplayOrderNo,
            "DoorStatus": data.DoorStatus,
            "IDDock": data.IDDock,
            "ID": data.ID,
            "IDWarehouse": data.IDWarehouse,
            "CRUDTYPE": "U",
            "TimeSlotStatus": data.TimeSlotStatus,
            "CreatedOn": data.CreatedDate,
            "CreatedBy": cu_id,
        }
        setShowModal(true);
        setEditData(editData1);
        setParticularFieldValue(editData1);
    }

    const createPayload=(data,type) => {
   
        if (type==="Edit") {
            return {
                "TimeSlotName": data.TimeSlotName,
                "DisplayOrderNo": data.DisplayOrderNo,
                "DoorStatus": data.DoorStatus,
                "IDDock": data.IDDock,
                "ID": data.ID,
                "IDWarehouse": data.IDWarehouse,
                "CRUDTYPE": "U",
                "TimeSlotStatus": data.TimeSlotStatus,
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            }
        }
        else{
            return {
                "TimeSlotName": data.TimeSlotName,
                "DisplayOrderNo": data.DisplayOrderNo,
                "DoorStatus": null,
                "IDDock": data.IDDock,
                "ID": null,
                "IDWarehouse": whObj.ID,
                "CRUDTYPE": "I",
                "TimeSlotStatus": data.TimeSlotStatus,
                "CreatedBy": cu_id,
                "CreatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            }
        }
    }

    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        const operationObj = {
            type: '',
            status: '',
        }
        if (data.ID) {
            const apiTempParams = createPayload(data,"Edit")
            operationObj.type = 'Edit Timeslot'
            apiParams = { ...data, ...apiTempParams }
        }
         else {
            apiParams = createPayload(data,"Create")
            operationObj.type = 'Add Timeslot'
        }
        dispatch({ type: sagaActions.WAREHOUSE_META_TIMESLOT_ACTION, payload: apiParams, operationObj: operationObj })
    };

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const closeCurrentpopup = () => {
        setEditData({});
        setShowModal(false);
    }

    const showCreateTimeslotPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            title: !Object.keys(editData).length ? AppConstant.smartScheduler.timeslots.createTimeslotTitle : AppConstant.smartScheduler.timeslots.editTimeslotTitle,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const openCreateForm = () => {
        setShowModal(true)
        setEditData({})
    }

    const closePreCheckpopup = () => {
        setShowPreCheckModal(false)
    }

    const popupBtnHandler=(text)=>{
        if (text===AppConstant.commonStrings.viewDetailsStr) {
            navigate("/manageappointments")
        }
        else{
            setShowPreCheckModal(false)
        }
    }


    const showPreCheck = () => {
        const popupObj = {
            id: 'adminUpdateOperationPreCheck',
            modalLabel: 'adminUpdateOperationPreCheck',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title:  AppConstant.smartScheduler.timeslots.editTimeslotTitle,
            bodyText: AppConstant.superUserAdmin.warehouseAdmin.preCheckBockerMsg,            
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.viewDetailsStr
                },
                btn2: {
                    show: false,   
                },
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => {popupBtnHandler(text)} } closepopup={()=>{closePreCheckpopup()}} themeSelected={themeSelected} showModal={showPreCheckModal} />
    }

    return (
        <div>
            <div className='container-fluid d-flex py-2 text-start justify-content-between mt-1'>
                <button
                    className={'btn-highlighted f-12'}
                    data-bs-toggle="modal"
                    onClick={() => openCreateForm()}
                >
                    {AppConstant.smartScheduler.timeslots.createTimeslotTitle}
                </button>
                <div className='ms-5'>
                    <WarehouseSearchSS
                        placeholder={'Search Timeslots'}
                        inputValue={searchStr}
                        cancelSearch={() => setSearchStr('')}
                        onChangeHandler={(text) => setSearchStr(text)} />
                </div>
            </div>
            <div className="d-flex justify-content-center timeslotMetaList">
                <TimeslotMetaListSS whObj={whObj} {...props} allowPermissionE={true} searchStr={searchStr} editTimeSlotAction={(groupObj) => editTimeslot(groupObj)} showPopup={() => showCreateTimeslotPopup()} themeSelected={themeSelected} allowPermissionD={allowPermissionD} getSummitedFormData={(data,confirm,updateStatus)=>{getSummitedFormData(data,confirm,updateStatus)}}/>
                {showCreateTimeslotPopup()}
                {showPreCheck()}
            </div>
        </div>
    )
}

export default DockDoorsFormSS;