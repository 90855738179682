import React, { memo, useEffect, useState } from 'react'
import './SSAppointments.css'
import WeekCalenderSS from '../../../../../../WeekCalenderSS/WeekCalenderSS'
import CarrierApptCardSS from './Components/CarrierApptCardSS'
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS'
import { AppConstant } from '../../../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../../../sagas/sagaActions'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash';
import { smartScheduleSelector } from '../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer'
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer'
import moment from 'moment'
import NoDataTracker from '../Common/NoDataTracker/NoDataTracker'
import { getPoSSselector } from '../../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer'
 const SSAppointments = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const ss = useSelector(smartScheduleSelector);
    const { appointmentListByDate, selectedDateForApptUpdates } = ss;
    const user = useSelector(getUserSelector);
    const { userType, selectedWarehouse, userAccountDetails } = user;
    const pOSS = useSelector(getPoSSselector);
    const { upcomingPastApptObj } = pOSS;

    const [selectedDate, setSelectedDate] = useState(null);
    const [showNewApptButton, setShowNewApptButton] = useState(false)

    useEffect(()=>{
        if (location.pathname.toLowerCase()!=='/bookappointments') {
            setShowNewApptButton(true)
        }
    },[location])

    useEffect(() => {
        if (selectedDateForApptUpdates) {
            setSelectedDate(selectedDateForApptUpdates)
        }
    }, [selectedDateForApptUpdates])

    const addSelectedDate = (date) => {
        dispatch({
            type: sagaActions.ADD_SELECTED_DATE_FOR_APPT_UPDATE,
            payload: date,
        });
    }

    const formatdata = (data) => {
        let result = []
        const tempArr = data.length > 0 && data.map(ele => {
            if (ele.PORelationID === ele.PoNumber) {
                return ({ ...ele, masterPO: true })
            }
            else {
                return ({ ...ele, masterPO: false })
            }
        })
        let newlist = _.groupBy(tempArr, 'PORelationID')
        let finaldata = Object.values(newlist);
        finaldata.forEach(element => {
            let l = element.sort((a, b) => {
                if (a.masterPO) {
                    return -1;
                } else {
                    return 1;
                }
            })
            result.push(l)
        });
        // Sort each sub-array based on ApptStartTime
        const sortedResponse = result.map(subArray => _.orderBy(subArray, ['ApptStartTime'], ['asc']));
        // Sort the main array based on the ApptStartTime of the first element in each sub-array
        const finalSortedResponse = _.sortBy(sortedResponse, subArray => subArray[0]?.ApptStartTime);
        return finalSortedResponse;
    }
    useEffect(() => {
        if (userType && selectedDate && userAccountDetails) {
            const data = {
                ID: userType === 'CARRIER' ? userAccountDetails?.IDCarrier : selectedWarehouse?.ID,
                type: userType,
                DateSelected: moment(selectedDate).tz(moment.tz.guess()).format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm_ss)
            }
            dispatch({ type: sagaActions.ADD_APPOINTMENT_BY_DATE, payload: data })
        }
    }, [selectedDate,upcomingPastApptObj])


    return (
        <div className='ss-appt-container'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <p><i className="fa-regular fa-calendar"></i> Appointments</p>
                <button className='btn-carrier-appt-seconday' onClick={() => {
                    navigate('/myappointments')

                }}>{AppConstant.commonStrings.viewAllStr}</button>
            </div>
            <WeekCalenderSS selectedDate={new Date(selectedDateForApptUpdates)} setSelectedDate={setSelectedDate} addSelectedDate={(date) => addSelectedDate(date)} />
            {/* Search commented asked by Srini */}
            {/* <div className='search-container-ss my-3'>
                <input type='text' className='form-control-ss search-control-ss' placeholder='Search' />
                <i className="fa-solid fa-magnifying-glass search-control-ss-icon"></i>
            </div> */}
            <br />
            <hr />
            <div className='overflowY-auto carrier-aapt-card-container'>
                {appointmentListByDate.length ? formatdata(appointmentListByDate).map((ele, index) => {
                    return (
                        <div key={index}>
                            <CarrierApptCardSS rowData={ele} />
                        </div>
                    )
                }) : <NoDataTracker />

                }

               {showNewApptButton && <div className='d-flex justify-content-end align-items-end ss-appt-container-float-btn'>
                    <CustomButtonSS
                        isIcon={true}
                        iconClass={'fa-solid fa-plus info-label-icon'}
                        title={AppConstant.smartScheduler.searchAppointments.newApptBtnText}
                        className={"button-bg rounded mb-3"}
                        onClick={() => {
                            dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
                            navigate('/BookAppointments')
                        }}
                    />
                </div>}
            </div>
        </div>
    )
}
export default memo(SSAppointments)