import React from 'react';
import CommonCheckBox from '../../../../../../Common/CommonCheckBox/CommonCheckBox';
import CustomDropDown from "../../../../../../Common/CustomDropDown/CustomDropDown";
import CommonToggleSwitch from '../../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import './RoleFeaturesRow.css';

const RoleFeaturesRow = (props) => {
    const { rowData, onChange, rowIndex, selectedDropdownValue, navigationDropDownList } = props

    const createTaskListItems = (taskArr) => {
        const arr = taskArr.map((ele, index) => {
            if (ele.ElementAccess) {
                return (<li key={ele + index} className='py-2 list-group-item border-0 bg-transparent bg-info'>
                    {ele.ElementAccess}
                </li>)
            }

        })
        if (arr && arr.length) {
            return arr
        } else {
            return [<li />]
        }
    }

    const getFeatureAccessArr = (taskArr, isEditable, isAccessDisabled) => {
        const arr = taskArr.map((ele, featureIndex) => {
            if (ele.ElementAccess) {
                return (
                    <li key={ele + featureIndex} className='list-group-item bg-transparent border-0 py-1'>
                        {<CommonCheckBox isEditable={isEditable} isEnabled={ele.Permission} isAccessDisabled={isAccessDisabled} onChangeValue={() => onChange(rowData, 'featureAccess', rowIndex, featureIndex, '')} />}
                    </li>
                )
            }
        })
        if (arr && arr.length) {
            return arr
        } else {
            return [<li />]
        }
    }

    const getDropDown = (obj, index, dataArr, placeHolder) => {
        return <CustomDropDown themeSelected={'light-mode'} data={navigationDropDownList} placeHolder={placeHolder ? placeHolder : 'Select'}
            onSelect={(event) => onChange(obj, 'navigationEvent', index, null, event.type)}
            keyStr='type' />
    }

    return (
        <tbody >
            <tr className='text-center'>
                <td scope="col">{rowIndex + 1}</td>
                <td scope="col">{rowData.PageName}</td>
                <td scope="col">
                    <span className='d-flex justify-content-center'>{
                        <div>
                            <CommonCheckBox isEditable={true} isEnabled={rowData.PageAccess} isAccessDisabled={rowData.isAccessDisabled} onChangeValue={() => onChange(rowData, 'pageAccess', rowIndex, null, '')} />
                        </div>
                    }</span>
                </td>
                <td scope="col">
                    <span className='d-flex justify-content-center'>{
                        <div>
                            {getDropDown(rowData, rowIndex, navigationDropDownList, rowData.NavigationType)}
                        </div>
                    }</span>
                </td>
                <td scope="col">
                    <ol className='list-group'>
                        {createTaskListItems(rowData.featureRole)}
                    </ol>
                </td>
                <td scope="col">
                    <span className='d-flex justify-content-center'>
                        <ol className='list-group'>
                            {getFeatureAccessArr(rowData.featureRole, rowData.PageAccess, rowData.isAccessDisabled)}
                        </ol >
                    </span>
                </td >
            </tr >
        </tbody >
    )
}

export default RoleFeaturesRow;