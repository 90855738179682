import { all } from 'redux-saga/effects';
import { alreadyAssignedDoor, assignDoor, changeDoor, enableDoorSection, getDoorList, initiateLoadLoader, loadTruckDetails, removeAssignDoor, resetDoorAcknowlegmentTimer, resetLoadTruckDetails, updateDockId, releaseDoor, resetReleaseDoor, initiateConfirmDoorWatcher, updateConfirmDoorLoaderWatcher, updateConfirmDoorWatcher } from './getLoadSaga';
import { saveSelectedDate } from './DateSelectorSaga';
import { autoFetchPoList, getTruck, initiateTruckLoader, sortPoList, updateTruckList } from './getTruckSaga';
import { getSelectedFilters, addSelectedFilters, deleteSelectedFilters, isFilterViewExpaned, setPOListFilter, resetFilters, setDockFiltersList, setStatusFiltersList, setSelectedDockFiltersList, setSelectedStatusFiltersList, setIsSearchingStatus } from './POFiltersSaga';
import { clearSignInError, signIn, clearSignedInUser, recoverPasswordInitiated, recoverPasswordSuccess, setPassword, clearSetPasswordData, resetPasswordInitiated, resetPasswordSuccess, rememberUserSaga, userChangePasswordWatcher, userChangePassworSuccessdWatcher, userChangePassworErrorWatcher, saveDataFromSignUpHubWatcher } from './AuthSaga';
import { getAllComments, addComments, clearComments } from './CommentsSaga'
import { approvePO, clearApprovePOStatus, initiatePOLoader } from './POSaga';
import { clearDock, clearDockError, getDockList } from './getDockListSaga';
import { assignDock, clearAssignDock, clearAssignDockError } from './AssignDockSaga';
import { dashboarsLoadStatusList, getAppointmentComplianceWatcher, getDetentionStatus, getunloadingHours, updateAutoFetchWatcher, getTaskItemsDue, getunloadingHoursDrillDown, sortDrillDownReport, initiateDrillDownReport, dashboarsLoadStatusDrillDown, updateSelectedButtonStatusLoad, getAppointmentComplianceWatcherDrillDown, getDetentionStatusDrillDownWatchcer, setTaskItemDue, updateSectionDataWatcher, updateGetWidgetDataWatcher, getTop10Loads, updateSelectedCustomWidgetsWatcher, resetFunnelGraphDataWatcher, getDetentionPercentageWatcher, updateSectionDataAPIWatcher, getDoorStatusWatcher, getDoorUsageSummaryWatcher, unloadingTypeSummaryWatcher, unloadingTypeSummaryDrillDownWatcher, getDoorStatusDrillDownWatcher, getUnscheduledPOWidgetWatcher, getUnscheduledPODrillDownWatcher, getTop10LoadsDrillDownWatcher, updateDateRangeWatcher, updateCustomDashBoardDockSelectedWatcher, clearDoorStatusWatcher, resetDoorUsageSummaryWatcher, sortDashboardStatusDrillDownReportWatcher, getDoorAssignmentReportWatcher, getDoorAssignmentLoaderWatcher, resetDoorAssignmentDrillDownWatcher, sortDoorAssignmentReportWatcher, updateWebSocketWatcher } from './DefaultDashboardSaga';
import { getDefDashboardInterval, getDoorAssignedTimer, getExecutorInterval, getCustomDashboardInterval, updateNotificationsTimerWatcher, getConfigrationWatcher } from './RemoteConfigSaga';
import { addHelpRequest, addFeedbackRequest, resetHelpFormRequest } from "./HelpAndFeedBack"
import { addUploadFileRequest, resetFileUploadDataRequest } from "./FileUploadSaga";
import { CompanyGroupRequest } from "./CompanyGroupSaga";
import { CompanyRequest, resetCompanyFormRequest } from "./CompanySaga";
import { GetWarehouseListRequest, GetWarehouseLocationListRequest, addWarehouseRequest, resetWarehouseFormRequest, sortWarehouseListWatcher, GetSproWarehouseListRequest, addSproWarehouseRequest, resetWarehouseListRequest} from "./WarehouseSaga";
import { GetLumpingFaciltyListRequest } from "./LumpingFaciltySaga";
import { GetTimeZoneRequest } from "./TimeZoneSaga";
import { GetTaxCodeRequest, resetTaxFormRequest } from "./TaxCodeSaga";
import { GetCountryListRequest } from "./CountrySaga";
import { getUserPermissionWatcher, getUserTypeWatcher, registerUserWatcher, resetUserPermissionWatcher, resetUserRoleAssignWatcher, resetUserWatcher, sortUserTableWatcher, updateAllWarehouseWatcher, updateCompanyGroupWatcher, updateCompanyWatcher, updateInitiateLoaderWatcher, updateNavBarWatcher, updateSelectedUserWatcher, updateSelectedWarehouseWatcher, updateUserRoleAssignWatcher, updatUserListWatcher, updatUserWarehouseListWatcher, getCompanyListWatcher, updateCompanyGroupPopupWatcher, updateCompanyPopupWatcher, updateParentWarehouseWatcher, getAllWarehouseWatcher, updateUserAccountDetailsWatcher, updateUserErrorWatcher, initiateBrandingWatcher, brandingSuccessWatcher, brandingErrorWatcher, brandingLoaderWatcher, updateResendEmailWatcher, initiateResendEmailWatcher, updateNavigationApiWatcher, initiaiteResetuserPassWatcher, updateResetuserPassWatcher, updateLastLogonWatcher, createCarrierFromSignUpWatcher, resetCarrierSignUpTempDataWatcher, updateUserAccountRenewDetailsWatcher, brandingClearWatcher, resetUserWatcherWithoutBranding, registerCarrierSubUserSagaWatcher, updateCarrierSubUserSagaWatcher, addCarrierSproWatcher, deleteUserWatcher } from './UserSaga';
import { getRolesListWatcher, getRolesFeaturesListWatcher, assignFeaturesToRolesWatcher, updateInitiateSuperAdminLoaderWatcher, sortSuperAdminTableWatcher, updateIssuesListWatcher, updateFeedbackListWatcher, updateFaqWatcher, updateRolesPopupWatcher, getRequestsReceivedDropDownsListWatcher, getOpenNotificationsListWatcher, updateErrorWatcher } from './SuperAdminSaga';
import { intiateLoaderWatcher, updateADFATWatcher, updateCarrierListWatcher, updateTruckUnloadSummaryWatcher, updateVendorListWatcher, updateTruckUnloadStatsWatcher, updateScheduledLoadStatusWatcher, clearADFATWatcher, selectedReportWatcher } from './ReportsSaga';
import { addSendMailForAppointRequest, resetSendMailForAppointRequest } from './SendMailForAppointSaga';
import {
  addAppointmenthWatcher, addRemoveMorePOWatcher, clearAppointmentWatcher,
  clearSSPOSearchWatcher, getWPschedulerDataWatcher, initateWarehouseMetaDataWatcher,
  intiateSSLoaderWatcher, intiateSSPOSearchWatcher,
  setSSselectedPOForApptWatcher, ssGetCarrierListWatcher, ssRecommendationsListWatcher, updateSSPOSearchWatcher, intiateDriverPOSearchWatcher, addAppointmenConfirmationthWatcher, intiateDriverPOAppointWatcher, sortDriverPOAppointWatcher, initiateSSAppointmentLoaderWatcher, initiateSSMultiplePoLoaderWatcher, getAppointmentTrackerWatcher, addAppointmentByDateWatcher, clearAppointmentTrackerWatcher, updateSSPOSearchErrorWatcher, addSelectedDateForApptUpdateWatcher, downloadCommonReportWatcher, getAppointmentReportSummaryWatcher, resetDataWatcher, getCityWithZipcodeWatcher, setStartDateWatcher, setEndDateWatcher, getAppointmentHistoryWatcher, getMCDetailsWatcher, resetMCDetailsWatcher, updateDefaultDockWatcher,
  resetValidateDateForAppointmentWatcher,
  validateDateForAppointmentWatcher,
  initiateUploadApptDocsWatcher,
  clearUploadApptDocsWatcher,
  closeCommanDownloadReportWatcher,
  resetDeleteApptResponseWatcher
} from './SmartScheduleSaga';
import { getPaytraceClientIdWatcher, hitPaytraceApiWatcher, cancelSubscriptionApiWatcher, resetCancelSubscriptionWatcher, resetPaytraceApiWatcher, mySubscriptionsListWatcher, updateCardDetailsWatcher } from './PaytraceSaga';
import { docksListRequestHandlerWatcher, leavesListRequestHandlerWatcher, breaksListRequestHandlerWatcher, whCapacityHandlerWatcher, whTimeslotHandlerWatcher, sortWhMetaTableWatcher, resetWHCapacityHandlerWatcher, leavesDockListRequestHandlerWatcher, breaksDocksListRequestHandlerWatcher, whResetTimeslotHandlerWatcher, resetDocksListRequestHandlerWatcher, updatePreCheckListWatcher, preCheckForAdminOperationWatcher, dockConflictDetailsWatcher, whTimeslotDeleteHandlerWatcher, deletePOsAndApptsHandlerWatcher, resetOperationObjectWhWatcher, dockTypeListWatcher } from './WhMetaDataSaga';
import { ssUpdatePOListWatcher, resetOperationObjWatcher, sortSsPoTableWatcher, ssPoExcelUploadWatcher, ssUpcomingPastApptsWatcher, ssApptsListWatcher, resetApptsListWatcher, resetSSApptsListWatcher, ssResetPOListWatcher, ssFinanceReportWatcher, resetSSFinanceReportWatcher, updateFacilityRatingsWatcher, resetFacilityRatingsWatcher, downloadFacilityRatingReportWatcher, updateUserRatingsWatcher, resetUserRatingsWatcher, downloadUserRatingReportWatcher,appointmentReportHistoryListWatcher,resetAppointmentReportHistoryListWatcher } from './POSmartSchedulerSaga';
import { driverCheckinRequest, editDriverReportInOutCheckWatcher, editDriverReportLiveTruckCheckWatcher, editDriverReportLoaderCheckWatcher, liveTruckReportWatcher, resetDriverCheckinDataRequest, sortDriverCheckWatcher } from "./Driver-CheckinSaga";
import { addCheckInWatcher, getCheckSearchPoWatcher } from './CheckInSaga';
import { getScheduleUpload, sortScheduleUploadData } from './ScheduleUploadSaga';
import { clearEclipseOpsPaymentListWatcher, clearEclipseServeFeeWatcher, clearfsPayPOHistoryWatcher, fsPayGetPOHistoryWatcher, getEclipseOpsPaymentLoadWatcher, getEclipseServeFeeWatcher, getManualPaymentMethodsWatcher, initiateFSPayLoadingWatcher, manualPaymentFSWatcher, shareReceiptWatcher, updateRealTimeFSPaymentListWatcher } from './EclipseOpsPaymentLoadSaga';
import { getFsWarehouseWatcher, fsPayWarehouseWatcher } from './FsWarehouseSaga';
import { getGatePassWatcher,getGatePassDockListWatcher,clearDockWatcher,clearDockErrorWatcher,getWarehouseListWatcher,clearWarehouseListWatcher,clearWarehouseErrorWatcher, clearGatePassErrorWatcher } from './GatePassSaga';
export default function* rootSaga() {
  yield all([
    initiateTruckLoader(), getTruck(), updateTruckList(), sortPoList(),
    getSelectedFilters(), addSelectedFilters(), deleteSelectedFilters(), isFilterViewExpaned(), setPOListFilter(), resetFilters(), setDockFiltersList(), setStatusFiltersList(), setSelectedDockFiltersList(), setSelectedStatusFiltersList(), setIsSearchingStatus(),
    initiateLoadLoader(), loadTruckDetails(), saveSelectedDate(), signIn(), clearSignInError(), clearSignedInUser(),
    recoverPasswordInitiated(),
    recoverPasswordSuccess(), resetPasswordInitiated(), resetPasswordSuccess(), resetLoadTruckDetails(),
    getDoorList(), assignDoor(), alreadyAssignedDoor(), changeDoor(), removeAssignDoor(), getAllComments(),
    addComments(), clearComments(), resetDoorAcknowlegmentTimer(), initiatePOLoader(), approvePO(),
    enableDoorSection(), clearApprovePOStatus(), getDockList(), clearDock(), clearDockError(),
    assignDock(), clearAssignDock(), clearAssignDockError(),
    updateDockId(), releaseDoor(), resetReleaseDoor(), autoFetchPoList(), setPassword(), clearSetPasswordData(),
    rememberUserSaga(),
    dashboarsLoadStatusList(), getunloadingHours(), getDetentionStatus(), getAppointmentComplianceWatcher(),
    updateAutoFetchWatcher(), setTaskItemDue(), getTop10Loads(),
    getExecutorInterval(), getCustomDashboardInterval(), getDoorAssignedTimer(),
    CompanyGroupRequest(), CompanyRequest(), GetWarehouseListRequest(),
    GetLumpingFaciltyListRequest(), GetTimeZoneRequest(), GetWarehouseLocationListRequest(), GetTaxCodeRequest(),
    GetCountryListRequest(), addWarehouseRequest(), resetWarehouseFormRequest(),

    getTaskItemsDue(), getDefDashboardInterval(), getunloadingHoursDrillDown(), sortDrillDownReport(),
    initiateDrillDownReport(), updateNotificationsTimerWatcher(),
    dashboarsLoadStatusDrillDown(), updateSelectedButtonStatusLoad(), getAppointmentComplianceWatcherDrillDown(),
    getDetentionStatusDrillDownWatchcer(),
    updateSectionDataWatcher(), updateGetWidgetDataWatcher(), updateSelectedCustomWidgetsWatcher(),
    resetFunnelGraphDataWatcher(), getDetentionPercentageWatcher(),
    unloadingTypeSummaryWatcher(), unloadingTypeSummaryDrillDownWatcher(), updateSectionDataAPIWatcher(),
    getDoorStatusWatcher(),
    getDoorUsageSummaryWatcher(), getDoorStatusDrillDownWatcher(), getUnscheduledPOWidgetWatcher(),
    getUnscheduledPODrillDownWatcher(), getTop10LoadsDrillDownWatcher(),
    addHelpRequest(), addFeedbackRequest(), resetHelpFormRequest(),
    addUploadFileRequest(), resetFileUploadDataRequest(),
    getUserTypeWatcher(), getUserPermissionWatcher(), updateNavBarWatcher(), resetUserPermissionWatcher(), resetUserWatcher(),
    updatUserWarehouseListWatcher(), updateSelectedWarehouseWatcher(), registerUserWatcher(), getCompanyListWatcher(),
    updateInitiateLoaderWatcher(), updatUserListWatcher(), updateUserRoleAssignWatcher(),
    updateCompanyGroupWatcher(), updateCompanyWatcher(), updateSelectedUserWatcher(),
    updateAllWarehouseWatcher(), getRolesListWatcher(), getRolesFeaturesListWatcher(),
    sortUserTableWatcher(), assignFeaturesToRolesWatcher(), updateInitiateSuperAdminLoaderWatcher(),
    resetTaxFormRequest(), resetCompanyFormRequest(), sortSuperAdminTableWatcher(), updateIssuesListWatcher(),
    updateFeedbackListWatcher(),
    resetUserRoleAssignWatcher(), updateCompanyGroupPopupWatcher(), updateCompanyPopupWatcher(),
    updateParentWarehouseWatcher(), getAllWarehouseWatcher(), updateFaqWatcher(), updateDateRangeWatcher(),
    userChangePasswordWatcher(), userChangePassworSuccessdWatcher(), updateRolesPopupWatcher(),
    userChangePassworErrorWatcher(), updateUserAccountDetailsWatcher(), getRequestsReceivedDropDownsListWatcher(),
    getOpenNotificationsListWatcher(), updateErrorWatcher(), updateUserErrorWatcher(), sortWarehouseListWatcher(), initiateBrandingWatcher(), brandingSuccessWatcher(), brandingErrorWatcher(), brandingLoaderWatcher(),
    updateTruckUnloadSummaryWatcher(), updateADFATWatcher(), intiateLoaderWatcher(), updateCarrierListWatcher(),
    updateVendorListWatcher(), updateTruckUnloadStatsWatcher(), updateScheduledLoadStatusWatcher(), clearADFATWatcher(), updateResendEmailWatcher(), initiateResendEmailWatcher(),
    updateNavigationApiWatcher(), initiaiteResetuserPassWatcher(), updateResetuserPassWatcher(),
    selectedReportWatcher(), addSendMailForAppointRequest(), resetSendMailForAppointRequest(),
    updateLastLogonWatcher(), intiateSSPOSearchWatcher(), setSSselectedPOForApptWatcher(), clearSSPOSearchWatcher(), intiateSSLoaderWatcher(), addAppointmenthWatcher(), addAppointmenConfirmationthWatcher(), clearAppointmentWatcher(), getWPschedulerDataWatcher(),
    getPaytraceClientIdWatcher(), hitPaytraceApiWatcher(), cancelSubscriptionApiWatcher(), resetCancelSubscriptionWatcher(), resetPaytraceApiWatcher(),
    saveDataFromSignUpHubWatcher(), createCarrierFromSignUpWatcher(), resetCarrierSignUpTempDataWatcher(),
    docksListRequestHandlerWatcher(), leavesListRequestHandlerWatcher(), breaksListRequestHandlerWatcher(), whCapacityHandlerWatcher(), whTimeslotHandlerWatcher(), sortWhMetaTableWatcher(), resetWHCapacityHandlerWatcher(),
    ssUpdatePOListWatcher(), resetOperationObjWatcher(), sortSsPoTableWatcher(), initateWarehouseMetaDataWatcher(), ssPoExcelUploadWatcher(), ssUpcomingPastApptsWatcher()
    , addRemoveMorePOWatcher(), ssRecommendationsListWatcher(), ssGetCarrierListWatcher(),
    mySubscriptionsListWatcher(), updateUserAccountRenewDetailsWatcher(), ssApptsListWatcher(), resetApptsListWatcher(), resetSSApptsListWatcher(), ssResetPOListWatcher(), updateSSPOSearchWatcher(),
    leavesDockListRequestHandlerWatcher(), breaksDocksListRequestHandlerWatcher(), driverCheckinRequest(), resetDriverCheckinDataRequest(), sortDriverCheckWatcher(), intiateDriverPOSearchWatcher(),
    whResetTimeslotHandlerWatcher(), brandingClearWatcher(), resetDocksListRequestHandlerWatcher(), intiateDriverPOAppointWatcher(), sortDriverPOAppointWatcher(), resetUserWatcherWithoutBranding(), initiateSSAppointmentLoaderWatcher(), initiateSSMultiplePoLoaderWatcher(), getAppointmentTrackerWatcher(), addAppointmentByDateWatcher(), clearAppointmentTrackerWatcher(),
    updateSSPOSearchErrorWatcher(), addSelectedDateForApptUpdateWatcher(),
    ssFinanceReportWatcher(), resetSSFinanceReportWatcher(), updateCardDetailsWatcher(),
    updateCarrierSubUserSagaWatcher(), registerCarrierSubUserSagaWatcher(), updateFacilityRatingsWatcher(), resetFacilityRatingsWatcher(), updatePreCheckListWatcher(), preCheckForAdminOperationWatcher(), updateCustomDashBoardDockSelectedWatcher(), clearDoorStatusWatcher(), resetDoorUsageSummaryWatcher(),
    downloadFacilityRatingReportWatcher(),
    getConfigrationWatcher(),
    getCheckSearchPoWatcher(), addCheckInWatcher(), initiateConfirmDoorWatcher(), updateConfirmDoorLoaderWatcher(), updateConfirmDoorWatcher(), sortDashboardStatusDrillDownReportWatcher(),
    downloadCommonReportWatcher(), getDoorAssignmentReportWatcher(), getDoorAssignmentLoaderWatcher(),
    resetDoorAssignmentDrillDownWatcher(), sortDoorAssignmentReportWatcher(), getConfigrationWatcher(), editDriverReportLoaderCheckWatcher(), liveTruckReportWatcher(), editDriverReportInOutCheckWatcher(), editDriverReportLiveTruckCheckWatcher(),
    updateUserRatingsWatcher(), resetUserRatingsWatcher(), downloadUserRatingReportWatcher(), updateWebSocketWatcher(), getScheduleUpload(), sortScheduleUploadData(), getAppointmentReportSummaryWatcher(),  resetDataWatcher(), addCarrierSproWatcher(), getCityWithZipcodeWatcher(),
    getAppointmentHistoryWatcher(),
    setStartDateWatcher(), setEndDateWatcher(), getMCDetailsWatcher(), resetMCDetailsWatcher(),
    updateDefaultDockWatcher(),appointmentReportHistoryListWatcher(),resetAppointmentReportHistoryListWatcher(),dockConflictDetailsWatcher(), whTimeslotDeleteHandlerWatcher(),deletePOsAndApptsHandlerWatcher(), resetOperationObjectWhWatcher(),
    validateDateForAppointmentWatcher(),resetValidateDateForAppointmentWatcher(), getEclipseOpsPaymentLoadWatcher(), getFsWarehouseWatcher(), fsPayWarehouseWatcher(),
    shareReceiptWatcher(),clearEclipseOpsPaymentListWatcher(),updateRealTimeFSPaymentListWatcher()
    , initiateUploadApptDocsWatcher(), clearUploadApptDocsWatcher(), initiateFSPayLoadingWatcher(),manualPaymentFSWatcher(),getEclipseServeFeeWatcher(), closeCommanDownloadReportWatcher(),clearEclipseServeFeeWatcher(),getManualPaymentMethodsWatcher(),GetSproWarehouseListRequest(), addSproWarehouseRequest(), resetWarehouseListRequest(), fsPayGetPOHistoryWatcher(), clearfsPayPOHistoryWatcher(),
    getGatePassWatcher(), getWarehouseListWatcher(), clearWarehouseListWatcher(), clearWarehouseErrorWatcher(), getGatePassDockListWatcher(), clearDockWatcher(), clearDockErrorWatcher(), clearGatePassErrorWatcher(), resetDeleteApptResponseWatcher(),
    deleteUserWatcher(), dockTypeListWatcher()
  ]);
}
