import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    paymentsUserList: [],
    operationObj: {},
    arrangeOrder: 'asc',
    arrangeKey: 'CreatedOn',
}

export const UserPaymentsReducerSlice = createSlice({
    name: 'userPaymentsReducerSlice',
    initialState,
    reducers: {
        getWhMetaDockList: (state, action) => {
            state.whMetaDocksList = action.payload;
        },
        updateOperationObj: (state, action) => {
            state.operationObj = action.payload
        },
        updateTableData: (state, action) => {
            const { drillDownReport, arrange, key } = action.payload;
            state[key] = drillDownReport
            state.arrangeKey = arrange.key
            state.arrangeOrder = arrange.order
        },
    }
});

export const { getWhMetaDockList, updateOperationObj, updateTableData } = UserPaymentsReducerSlice.actions;

// Selectors



export default UserPaymentsReducerSlice.reducer;