import _ from 'lodash';
import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import { Uchain } from '../redux/api/agent';
import { sagaActions } from './sagaActions';
import { getEclipseOpsPaymentSuccess, updateIsLoading, getEclipseOpsPaymentFailure, updateInitiateLoading, updateEclipseOpsPaymentList, updateLastEvaluatedKey, clearEclipseOpsPaymentList, updateLastPage, updateRealTimePaymentData, updateEclipseServiceFee, updateBtnLoading, updateManualPaymentMethodsList, updatedFsPayPOHistory } from '../redux/reducers/reducerSlices/EclipseOpsPaymentLoadReducer';
import { toast } from 'react-toastify';
import { showAlert } from '../App/Assests/Utility';
import { AppConstant } from '../App/Assests/AppConstant';

/**
 * Function to call the eclipse ops payment API and get the data and also validates if any error
 * @param {*} data 
 */
function* getEclipseOpsPaymentLoadSaga(data) {
    try {
        yield put(updateIsLoading(true));
        if (!data.payload.LastEvaluatedKey) {
            yield put(clearEclipseOpsPaymentList())
            yield put(updateLastPage(false))
        }
        const response = yield call(Uchain.getEclipseOpsPaymentLoadDetails, data.payload)
        if (response) {
            yield put(getEclipseOpsPaymentSuccess(response))
            if (response.data.LastEvaluatedKey) {
                yield put(updateLastEvaluatedKey(response.data.LastEvaluatedKey))
            } else {
                yield put(updateLastPage(true))
                // yield put(updateLastEvaluatedKey(null))
            }
            yield put(updateEclipseOpsPaymentList(response.data.Items))
        }
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
        yield put(getEclipseOpsPaymentFailure())
    }
    finally {
        yield put(updateIsLoading(false));
    }
}

function* shareReceiptSaga(data) {
    try {
        yield put(updateInitiateLoading(true));
        const response = yield call(Uchain.SendEclipseReceipt, data.payload)
        if (response.toLowerCase() === "success") {
            showAlert(toast.TYPE.SUCCESS, AppConstant.eclipseOpsPaymentLoad.shareReceiptAlert, 'light')
        }
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
    }
    finally {
        yield put(updateInitiateLoading(false));
    }
}

function* clearEclipseOpsPaymentListSaga() {
    try {
        yield put(clearEclipseOpsPaymentList())
    } catch (error) {

    }
}

function* updateRealTimeFSPaymentListSaga(data) {
    try {
        yield put(updateRealTimePaymentData(data.payload))
    } catch (error) {

    }
}

function* initiateFSPayLoadingSaga(data){
    try {
        yield put(updateIsLoading(data.payload))
    } catch (error) {
        
    }
}

function* manualPaymentFSSaga(data) {
    try {
        yield put(updateInitiateLoading(true));
        const response = yield call(Uchain.ManualPaymentApi, data.payload)
        if (response) {
            showAlert(toast.TYPE.SUCCESS, AppConstant.eclipseOpsPaymentLoad.manualPaymentSuccess, 'light')
        }
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
    }
    finally {
        yield put(updateInitiateLoading(false));
    }
}

function* getEclipseServeFeeSaga(data) {
    try {
        yield put(updateEclipseServiceFee(null));
        yield put(updateBtnLoading(true));
        const response = yield call(Uchain.GetEclipseOpsServiceFee, data.payload)
        if(response){
            yield put(updateEclipseServiceFee(response));
        }
       
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
    }
    finally {
        yield put(updateBtnLoading(false));
    }
}

function* clearEclipseServeFeeSaga(){
    try {
        yield put(updateEclipseServiceFee(null))
    } catch (error) {
        
    }
}

function* getManualPaymentMethodsSaga() {
    try {
        yield put(updateBtnLoading(true));
        const response = yield call(Uchain.GetManualPaymentMethods)
        if(response && response.Items.length){
            yield put(updateManualPaymentMethodsList(response.Items));
        }
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.eclipseOpsPaymentLoad.paymentMethodsFetchError, 'light')
    }
    finally {
        yield put(updateBtnLoading(false));
    }
}

/**
 * Function is used to make API call to get FS pay PO history
 * @param {*} data 
 */
function* fsPayGetPOHistorySaga(data) {
    try {
        yield put(updateIsLoading(true));
        const response = yield call(Uchain.getFSPOHistory, data.payload)
        yield put(updatedFsPayPOHistory(response))
        
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
        yield put(updatedFsPayPOHistory([]))
    }
    finally {
        yield put(updateIsLoading(false));
    }
}

function* clearfsPayPOHistorySaga(data) {
    try {
        yield put(updateIsLoading(false));
        yield put(updatedFsPayPOHistory([]))
        
    } catch (error) {
        yield put(updateIsLoading(false));
        yield put(updatedFsPayPOHistory([]))
    }
}

export function* getEclipseOpsPaymentLoadWatcher() {
    yield takeEvery(sagaActions.GET_ECLIPSE_OPS_PAYMENT_LOAD_DETAILS, getEclipseOpsPaymentLoadSaga)
}

export function* shareReceiptWatcher() {
    yield takeEvery(sagaActions.SHARE_RECEIPT_FOR_ECLIPSE_OPS_PAYMENT_LOAD, shareReceiptSaga)
}

export function* clearEclipseOpsPaymentListWatcher() {
    yield takeEvery(sagaActions.CLEAR_ECLIPSE_OPS_PAYMENT_LIST, clearEclipseOpsPaymentListSaga)
}

export function* updateRealTimeFSPaymentListWatcher() {
    yield takeEvery(sagaActions.UPDATE_REAL_TIME_FS_PAYMENT_LIST, updateRealTimeFSPaymentListSaga)
}

export function* initiateFSPayLoadingWatcher() {
    yield takeEvery(sagaActions.INITIATE_FSPAY_LOADING, initiateFSPayLoadingSaga)
}

export function* manualPaymentFSWatcher() {
    yield takeEvery(sagaActions.FS_PAY_MANUAL_PAYMENT, manualPaymentFSSaga)
}

export function* getEclipseServeFeeWatcher() {
    yield takeEvery(sagaActions.GET_ECLIPSE_SERVICE_FEE, getEclipseServeFeeSaga)
}

export function* clearEclipseServeFeeWatcher() {
    yield takeEvery(sagaActions.CLEAR_ECLIPSE_SERVICE_FEE, clearEclipseServeFeeSaga)
}

export function* getManualPaymentMethodsWatcher() {
    yield takeEvery(sagaActions.GET_MANUAL_PAYMENT_METHODS_LIST, getManualPaymentMethodsSaga)
}

export function* fsPayGetPOHistoryWatcher() {
    yield takeEvery(sagaActions.GET_FS_PAY_PO_HISTORY, fsPayGetPOHistorySaga)
}

export function* clearfsPayPOHistoryWatcher() {
    yield takeEvery(sagaActions.RESET_FS_PAY_PO_HISTORY, clearfsPayPOHistorySaga)
}
