import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { initiateSearch } from '../../../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import DrilldownTable from '../../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import WarehouseSearch from '../../../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import DownloadSection from '../../../../../DownloadSection/DownloadSection';
import { usePrevious } from '../../../../../../../Common/CustomHooks/usePrevious';
import moment from 'moment-timezone';
import { getPoSSselector } from '../../../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import CustomDropDown from '../../../../../../../Common/CustomDropDown/CustomDropDown';
import './FacilityRatingsTabular.css'
import PaginationIMS from '../../../../../../../Common/Pagination/PaginationIMS';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../../../../Components/DatePicker/DatePicker.css"
import CustomDatePickerMMYYYYonly from '../../../../../../../DatePicker/Month/CustomDatePickerMMYYYYonly';
import { CustomPaginationSS } from '../../../../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { getRemoteConfigSelector } from '../../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';


const FacilityRatingsTabular = (props) => {
    const { themeSelected = 'light-mode', view, startDate, setStartDate, endDate, setEndDate, handleStartYearChange, handleEndYearChange, handleStartDateChange, handleEndDateChange, isError, setCurrentFilterDetails } = props;
    const dispatch = useDispatch();
    const [facilityRatingsList, setFacilityRatingsList] = useState([]);
    const pOSS = useSelector(getPoSSselector);
    const { facilityRatingsReportList, facilityRatingsAllData, arrangeOrder, arrangeKey } = pOSS;
    const userObj = useSelector(getUserSelector);
    const [searchStr, setSearchStr] = useState('');
    const { selectedWarehouse, cu_id, userType } = userObj;
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const divRef = useRef(null);
    const [showLoading, setShowLoading] = useState(true);
    const previousTable = usePrevious(currentTable);
    // moment.tz.setDefault(selectedWarehouse?.TimeZone);
    const [currentFilterSelected, setCurrentFilterSelected] = useState({ name: 'All' });
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    //Get Config from store
    const remoteConfig=useSelector(getRemoteConfigSelector);
    const {allConfigration}=remoteConfig;
    const {PaginationDefaultLimit}=allConfigration
    
    useEffect(()=>{
        setCurrentFilterDetails(currentFilterSelected)
    },[currentFilterSelected])

    const apptsTitleDD = [
        // { id: 'serial_num', name: 'S.No' },
        { id: 'first_name', name: 'First Name' },
        { id: 'last_name', name: 'Last Name' },
        { id: 'facility_rating', name: 'Ratings' },
        { id: 'timeStamp', name: 'Rated on' }
    ]

    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })

    useEffect(() => {
        dispatch({ type: sagaActions.RESET_FACILITY_RATINGS_ACTION });
        setShowLoading(true);
        setFacilityRatingsList([]);
        return () => {
            dispatch({ type: sagaActions.RESET_FACILITY_RATINGS_ACTION });
            setFacilityRatingsList([]);
        }
    }, [])

    useEffect(() => {
        if (!isError && startDate && endDate) {
            fetchFacilityRatings()
        }
    }, [startDate, endDate, currentFilterSelected, isError])

    // useEffect(() => {
    //     fetchFacilityRatings()
    // }, [currentFilterSelected, currentPage])

    useEffect(() => {
        if (facilityRatingsReportList && facilityRatingsReportList.length) {
            setFacilityRatingsList(facilityRatingsReportList)
            if (searchStr && searchStr.length > 3) {
                const searchedArr = initiateSearch(facilityRatingsReportList, searchStr, 'GroupName');
                setFacilityRatingsList(searchedArr);
            }
        } else {
            setFacilityRatingsList([])
        }
    }, [facilityRatingsReportList])


    useEffect(() => {
        if (_.upperCase(facilityRatingsAllData?.status) === 'SUCCESS') {
            setShowLoading(false);
            // setFacilityRatingsList(facilityRatingsReportList)
            if (facilityRatingsAllData?.data?.currentPage) {
                setCurrentPage(facilityRatingsAllData?.data?.currentPage || 1)
                setTotalPages(facilityRatingsAllData?.data?.totalPages || 1)
            }
        } else {
            setShowLoading(false)
            setCurrentPage(facilityRatingsAllData?.data?.currentPage || 1)
            setTotalPages(facilityRatingsAllData?.data?.totalPages || 1)
        }

    }, [facilityRatingsAllData])

    /**
     * Function to call facility rationg api
     * @param {*} pageNo 
     * @param {*} limit 
     */
    const fetchFacilityRatings = (pageNo=1,limit=PaginationDefaultLimit) => {
        if (userType === 'USER') {
            // const start = dateRangeObj?.start ? moment(dateRangeObj.start).utc() : null;
            // const end = dateRangeObj?.end ? moment(dateRangeObj.end).utc() : null;
            setShowLoading(true);
            const eDate = moment(endDate).hours(23).minutes(59).seconds(59)
            const params = {
                "warehouse_id": selectedWarehouse.ID,
                "startDate": moment(startDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "endDate": moment(eDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "currentPage": pageNo,
                "itemsPerPage": limit,
                "rating": currentFilterSelected?.name === 'All' ? null : currentFilterSelected?.name,
                "type": view
            }
            dispatch({ type: sagaActions.UPDATE_FACILITY_RATINGS, payload: params });
        } else if (userType === 'SUPERADMIN') {
            // console.log("🚀 SUPERADMIN: See what will happen in this case")
        } else {
            // console.log(userType, " 🚀: This report is not accessible")
        }
    }

    useEffect(() => {
        if (searchStr.length > 3 && facilityRatingsReportList.length) {
            const searchedArr = initiateSearch(facilityRatingsReportList, searchStr, 'GroupName');
            setFacilityRatingsList(searchedArr);
        } else if (searchStr === '') {
            setFacilityRatingsList(facilityRatingsReportList)
        }
    }, [searchStr])

    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                id: el.load_id,
                // 0: index + 1,
                0: el.first_name,
                1: el.last_name,
                2: el.facility_rating,
                3: moment(el.timeStamp) || '-',
            }
        })
    }


    const getBodyData = () => {
        if (facilityRatingsList && facilityRatingsList.length) {
            return formatListDataDrillDown(facilityRatingsList)
        }
        else {
            return []
        }
    }

    const showCustomDropDownForFilters = () => {
        const filterDataArr = [
            {
                name: 'All',
            },
            {
                name: 'Great',
            },
            {
                name: 'Good',
            },
            {
                name: 'Okay',
            }
        ]
        return (
            <div className='d-flex align-items-center'>
                <div className='me-2 titleFiltersLeaveMeta'>{AppConstant.commonStrings.select} :</div>
                <CustomDropDown themeSelected={themeSelected} data={filterDataArr} placeHolder={currentFilterSelected.name} onSelect={(event) => setCurrentFilterSelected(event)} keyStr={'name'} />
            </div>
        )
    }

/**
 * Function to call fetch facilty ration fn on page change
 * @param {*} pageNo 
 * @param {*} limit 
 */
    const pageChange=(pageNo,limit)=>{
         fetchFacilityRatings(pageNo,limit)
    }

    return (
        <div className=''>
            <div className='d-flex justify-content-between my-2 mx-3'>
                <div className='fw-bold'>{'Facility Ratings'}</div>


                <div className='d-flex justify-content-end align-items-end'>
                    <div className='d-flex justify-content-end align-items-center'>
                        <CustomDatePickerMMYYYYonly wrapperClassName='mx-2' date={startDate} setDate={setStartDate} maxDate={new Date()} handleCalendarChange={(d) => handleStartYearChange(d)} label='Start Date' themeSelected={themeSelected}
                            onChangeHandler={handleStartDateChange}
                        />

                        <CustomDatePickerMMYYYYonly wrapperClassName='' date={endDate} setDate={setEndDate} handleCalendarChange={(d) => handleEndYearChange(d)} label='End Date' maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)} themeSelected={themeSelected} onChangeHandler={handleEndDateChange} />
                    </div>
                    <div className='ms-2 w-30'>
                        {
                            showCustomDropDownForFilters()
                        }
                    </div>
                    <div className='ms-2 w-50'>
                        <WarehouseSearch
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                    <div className='d-flex align-items-center mx-3'>
                        {/* <DownloadSection singlePageDownload={true} root={''} subRoot={''} name={'Facility Ratings'} themeSelected={themeSelected} currentTable={currentTable} currentTableRef={currentTableRef} /> */}
                    </div>
                </div>

            </div>
            <div className='facilityRatingsListContainer' ref={divRef}>
                <DrilldownTable
                    themeSelected={themeSelected}
                    bodyData={getBodyData()}
                    titleData={apptsTitleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    hasCollapsableContent={false}
                    collapsableData={[]}
                    accordionPrimaryIndex={1}
                    arrangeOrder={arrangeOrder}
                    arrangeKey={arrangeKey}
                    drillDownReport={facilityRatingsList}
                    initiateDrillDownReport={showLoading}
                    loaderArray={4}
                    sortingAction={sagaActions.SORT_SS_USER_TABLE}
                    subRoot={{ pathName: 'facilityRatingsObj' }}
                    showUTCTime={true}
                />
            </div>
            {/* {facilityRatingsList && facilityRatingsList.length ? <PaginationIMS wrapperPositionClass="mx-1 d-flex justify-content-center" currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} isLoading={ showLoading} /> : ''} */}
            {!_.isEmpty(facilityRatingsAllData) && facilityRatingsAllData?.data?.Items?.length  ?
            <div className='f-14 detention-status-pagination'>
                <CustomPaginationSS
                pageNo={facilityRatingsAllData.data.currentPage}
                pageSize={facilityRatingsAllData.data.itemsPerPage}
                first={(Number(facilityRatingsAllData.data.currentPage)===1)?true:false}
                last={Math.ceil(facilityRatingsAllData.data.totalCount/Number(facilityRatingsAllData.data.itemsPerPage))===(Number(facilityRatingsAllData.data.currentPage))?true:false}
                totalElements={facilityRatingsAllData.data.totalCount}
                totalPages={facilityRatingsAllData.data.totalPages}
                pageChange={(pageNo,limit)=>{pageChange(pageNo,limit)}}
            />
        </div>:""
            }
        </div>
    )
}

export default FacilityRatingsTabular