import React, { useEffect, useState } from 'react';
import './CarrierApptListRows.css';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import _ from 'lodash'
import moment from 'moment-timezone';
import { getFormattedDate } from '../../../../../../../../Utils/DateUtils';
import CustomButton from '../../../../../../Common/CustomButton/CustomButton';
import { formParticularFieldValueForAssociatedPO } from '../../../../../../../Assests/Utility';
import CustomTooltip from '../../../../../../Common/Tooltip/CustomTooltip';
import Popup from '../../../../../../Common/Popup/Popup';

const CarrierApptListRows = (props) => {

    const { rowData, emailClickHandler, rowIndex, ssSchedulehandler, triggerRecalculate, poForAppt, openAddMorePOForm, themeSelected, searchedPOData, userType } = props;
    const [poNumber, setPoNumber] = useState('')
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        if (_.size(searchedPOData)) {
            const fieldData = formParticularFieldValueForAssociatedPO(searchedPOData)
            setPoNumber(fieldData?.po_number)
        } else {
            setPoNumber(rowData.PoNumber)
        }
    }, [searchedPOData])

    useEffect(() => {
        if (_.size(poForAppt)) {
            const fieldData = formParticularFieldValueForAssociatedPO(poForAppt)
            setPoNumber(fieldData?.po_number)
        }
    }, [triggerRecalculate, poForAppt])
    if (rowData.lenght) {
        moment.tz.setDefault(rowData[0].WarehouseData.TimeZone); // Set Warehouse Timezone   
    }

    const [isScheduleDisabled, setIsScheduleDisabled] = useState(null);
    const getScheduleDisabled = () => {
        if (moment(rowData.DueDate).isBefore(moment())) {
            return {
                className: `disabled mx-2 mb-2`,
                disable: true
            };
        }
        else {
            return {
                className: renderBtnData().className,
                disable: renderBtnData().disabledSchedule
            }
        }
    }

    function showPopup() {
        setShowModal(true);
    }
    const closepopup = () => {
        setShowModal(false);
    }

    useEffect(() => {
        setIsScheduleDisabled(getScheduleDisabled());
    }, [rowData.DueDate])

    const renderBtnData = () => {
        if (rowData?.ApptScheStatus.toLowerCase() === 'scheduled' || rowData?.ApptScheStatus.toLowerCase() === 'rescheduled') {
            return {
                title: `Reschedule`,
                className: `pointer mx-2 mb-2`,
                disabledSchedule: false,
                disabledEmail: false
            }
        } else if (rowData?.ApptScheStatus.toLowerCase() === 'not scheduled') {
            return {
                title: `Schedule`,
                className: isScheduleDisabled?.disable ? `disabled mx-2 mb-2` : `pointer mx-2 mb-2`,
                disabledSchedule: false,
                disabledEmail: true
            }
        } else if (rowData?.ApptScheStatus.toLowerCase() === 'completed') {
            return {
                title: `Completed`,
                className: `disabled mx-2 mb-2`,
                disabledSchedule: true,
                disabledEmail: false
            }
        }
    }

    const renderHorizontalSeparator = () => {

        if (rowData?.ApptScheStatus?.toLowerCase() === 'scheduled') {
            return {
                className: `w-100 horizontalSeperator apptScheduled`
            }
        } else if (rowData?.ApptScheStatus?.toLowerCase() === 'not scheduled') {
            return {
                className: `w-100 horizontalSeperator apptNotScheduled`
            }
        }
        else if (rowData?.ApptScheStatus?.toLowerCase() === 'rescheduled') {
            return {
                className: `w-100 horizontalSeperator rescheduled`
            }
        } else {
            return {
                className: `w-100 horizontalSeperator completed`
            }
        }
    }

    const getHoverText = (rowData) => {
        if (rowData.ApptScheStatus === 'Scheduled') {
            return AppConstant.commonStrings.reschedule
        } else if (rowData.ApptScheStatus === 'Not Scheduled') {
            return AppConstant.commonStrings.schedule
        } else {
            return AppConstant.commonStrings.completed
        }
    }

    const popupObj = {
        id: "multiPOAlert1",
        modalLabel: "multiPOAlert1",
        showClose: true,
        showfooter: true,
        showHeader: true,
        sendDriverNotification: false,
        modalSize: "md",
        title: '',
        bodyText: <div className='boldText'>{AppConstant.smartScheduler.addMorePoPopupText.body}</div>,
        subText: "",
        keyboard: false,
        backdrop: "static",
        footerBtn: {
            btn1: {
                show: true,
                text: AppConstant.commonStrings.yes,
            },
            btn2: {
                show: true,
                text: AppConstant.commonStrings.no,
            },
        },
    };

    const popupHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            openAddMorePOForm()
        }
        if (text === AppConstant.commonStrings.no) {
            ssSchedulehandler(rowData)
        }
        closepopup()
    }


    return (
        <>
            <tbody>
                <tr key={rowIndex}>
                    <td>

                        <div className='h-100'>
                            <div className='carrierapplist-container'>

                                <div className='d-flex justify-content-between'>

                                    <div className='d-flex flex-column justify-content-center align-items-start pt-3 px-2 mb-2'>

                                        <span className='calendarDateText'>{rowData?.ApptStartTime ?

                                            moment(rowData.ApptStartTime).format('DD') : ""}</span>

                                        <span className='subText'>{rowData?.ApptStartTime ?

                                            moment(rowData.ApptStartTime).format('MMM') : <span className='text-16'><b>{rowData?.ApptScheStatus}</b></span>}</span>

                                    </div>
                                    {/* Only show 1 PO card and show Button when clicked on Schedule/Reschedule */}
                                    {rowIndex === 0 && _.size(poForAppt) ? <div data-for="m-po-tooltip" data-tip={("Multiple Po")} className='align-self-end pe-4 multiPO'
                                        onClick={() => openAddMorePOForm()}>
                                        {/* <CustomButton
                                    disabled={false}
                                    isLoading={false}
                                    title={'Add More PO'}
                                    className={`addButton btn-main w-inherit`}
                                    onClick={() =>openAddMorePOForm()}
                                /> */}


                                        <i className="fa-solid fa-plus" style={{ fontSize: '26px', color: '#000' }}> </i>

                                        <CustomTooltip
                                            id={'m-po-tooltip'}
                                            title="Click to add more PO's"
                                            position='top'
                                            type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'dark' : 'light'}
                                            multiline={true} />
                                    </div> : ''}
                                </div>
                                {/* <div className='d-flex flex-column justify-content-center align-items-start pt-3 px-2 mb-2'>
                                <span className='calendarDateText'>{rowData?.ApptStartTime ?
                                    moment(rowData.ApptStartTime).format('DD') : ""}</span>
                                <span className='subText'>{rowData?.ApptStartTime ?
                                    moment(rowData.ApptStartTime).format('MMM') : <span className='text-16'><b>{rowData?.ApptScheStatus}</b></span>}</span>
                            </div> */}
                                <div className='d-flex flex-column justify-content-start px-2 pb-2 f-16'>
                                    {rowData?.PoNumber && <div><b>{AppConstant.poManagement.tableHeader.po}:</b> {rowData?.PoNumber}</div>}
                                    {poNumber && <div><b>{AppConstant.poManagement.tableHeader.associatedPo}:</b> {poNumber}</div>}
                                    {(rowData.WarehouseData.Name || rowData.ReceivingZipCode) && <div><b>{rowData.WarehouseData.Name && AppConstant.reports.truckUnloadSummaryReport.warehouse}{rowData.ReceivingZipCode && " | " + AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip}:</b> {rowData.WarehouseData.Name && rowData.WarehouseData.Name || ''} {rowData.ReceivingZipCode && "| " + rowData.ReceivingZipCode || ''}</div>}
                                    {/* <div>{AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip} : {rowData.ReceivingZipCode || '-'}</div> */}
                                    {(rowData.VendorName || rowData.BuyerName) && <div><b>{rowData.VendorName && AppConstant.reports.adfatReport.dropdownLabels.vendor}{rowData.BuyerName && " | " + AppConstant.smartScheduler.poSection.buyer}:</b> {rowData.VendorName && rowData.VendorName || ''} {rowData.BuyerName && "| " + rowData.BuyerName || ''}</div>}
                                    {(rowData.Commodity || rowData.Quantity || rowData.ItemsCases) && <div><b>{rowData.Commodity && AppConstant.smartScheduler.poSection.commodity}{rowData.Quantity && " | " + AppConstant.loadManagement.purchaseOrderDetails.items}{rowData.ItemsCases && " | " + AppConstant.loadManagement.purchaseOrderDetails.cases}:</b> {rowData.Commodity && rowData.Commodity || ''} {rowData.Quantity && "| " + rowData.Quantity || ''} {rowData.ItemsCases && "| " + rowData.ItemsCases || ''}</div>}
                                    {/* <div>{AppConstant.smartScheduler.poSection.buyer}  : {rowData.BuyerName || '-'}</div> */}
                                    {(rowData.DockName || rowData.Door) && <div><b>{rowData.DockName && AppConstant.reports.truckUnloadSummaryReport.tableHeaders.dockNameStr}{rowData.Door && " | " + AppConstant.smartScheduler.docksTimeSlots.tableHeaders.timeslot}:</b> {rowData.DockName || ''} {rowData.Door && "| " + rowData.Door || ''}</div>}
                                    {rowData?.ApptStartTime && <div><b>{AppConstant.smartScheduler.poSection.apptStartTime}:</b> {rowData?.ApptStartTime ? getFormattedDate(AppConstant.commonStrings.HH_mm_dateFormat, rowData.ApptStartTime) : ''}</div>}
                                    {rowData.DueDate && <div><b>{AppConstant.smartScheduler.poSection.dueDate}:</b> <b className='text-red'>{getFormattedDate(AppConstant.commonStrings.MM_DD_YYYY_dateFormat, rowData.DueDate) || ''}</b></div>}
                                </div>
                            </div>
                            <div className='d-flex justify-content-end px-2'>
                                {/* {
                                <div onClick={() => ssSchedulehandler(rowData)}
                                    className={renderBtnData().className}
                                >
                                    <span data-tip={getHoverText(rowData)} data-for='status'>
                                        <i className="fas fa-calendar-alt fa-lg"></i>
                                        <CustomTooltip id='status' position='top' type='light' multiline={true} html={true} />
                                    </span>
                                </div>
                            } */}
                                {rowIndex === 0 && <CustomButton
                                    disabled={isScheduleDisabled?.disable}
                                    isLoading={false}
                                    title={renderBtnData()?.title}
                                    className={`addButton ${renderBtnData()?.className}`}
                                    // onClick={userType==='CARRIER'?() => showPopup():() => ssSchedulehandler(rowData)}
                                    onClick={() => {
                                        ssSchedulehandler(rowData)
                                        userType === 'CARRIER' && showPopup()
                                    }}

                                />
                                }
                                {/* {
                                rowData.ApptScheStatus !== 'Not Scheduled' &&
                                <div style={{ cursor: 'pointer' }} onClick={() => emailClickHandler(rowData)}>
                                    <span data-tip={"Email"} data-for='Email'>
                                        <i className="fa fa-fw fa-envelope fa-lg"></i>
                                        <CustomTooltip id='Email' position='top' type='light' multiline={true} html={true} />
                                    </span>
                                </div>

                            } */}
                                {
                                    rowData.ApptScheStatus !== 'Not Scheduled' &&
                                    <CustomButton
                                        disabled={renderBtnData().disabledEmail}
                                        isLoading={false}
                                        title={AppConstant.login.email}
                                        className={`addButton ${renderBtnData()?.className}`}
                                        onClick={() => emailClickHandler(rowData)}
                                    />
                                }
                            </div>
                            <div className={renderHorizontalSeparator().className} />
                        </div>
                    </td>
                </tr>
            </tbody>
            <div className="mt-1">
                <Popup
                    {...popupObj}
                    popupBtnHandler={(text) => popupHandler(text)}
                    showModal={showModal}
                    showfooter={true}
                    closepopup={closepopup}
                    themeSelected={themeSelected}
                />
            </div>
        </>
    )
}

export default CarrierApptListRows;