import { AppConstant } from "../App/Assests/AppConstant";
import { validEmailRegex, validContactNumRegex, allowOnlyNumber, validPasswordRegex, allowOnlyText } from "./validationRegex";
import _ from 'lodash-es';
export const validateRequired = (fieldName, data, errorData) => {
  if (!data) {
    errorData[fieldName].push(AppConstant.help.required);
    return false;
  }
  return true;
};
export const validateLength = (fieldName, data, errorData, minLength, maxLength, customError) => {
  if (maxLength != undefined && minLength != undefined) {
    if (minLength !== maxLength) {
      if (data.length >= maxLength || data.length < minLength) {
        if (customError) {
          errorData[fieldName].push(customError);
        } else {
          errorData[fieldName].push(
            AppConstant.generalError.lengthBet + minLength + " & " + maxLength + ' characters.'
          );
        }

        return false;
      }
    }
    else {
      if (data.length > maxLength || data.length < minLength) {
        errorData[fieldName].push(
          AppConstant.generalError.lengthequal + minLength
        );
        return false;
      }
    }

  }
  if (maxLength != undefined && minLength == undefined) {
    if (data.length > maxLength) {
      errorData[fieldName].push(
        AppConstant.generalError.lengthGreater + maxLength
      );
      return false;
    }
  }
  if (minLength != undefined && maxLength == undefined) {
    if (data.length < minLength) {
      if (customError) {
        errorData[fieldName].push(
          customError
        );
      }
      else {
        errorData[fieldName].push(
          AppConstant.generalError.lengthLess + minLength
        );
      }
      return false;
    }
  }

  return true;
};
export const validateEmail = (fieldName, data, errorData) => {
  if (!validEmailRegex.test(data)) {
    errorData[fieldName].push(AppConstant.help.emailIncorrect);
    return false;
  }
  return true;
}

export const validateContactNum = (fieldName, data, errorData, minLength=14) => {
  if (!validContactNumRegex.test(data)) {
    errorData[fieldName].push(AppConstant.help.contactIncorrect);
    return false;
  }
  if (data.length < minLength) {
    errorData[fieldName].push(AppConstant.help.contactIncorrect);
    return false;
  }
  return true;
}

export const validatePassword = (fieldName, data, errorData) => {
  if (!validPasswordRegex.test(data)) {
    errorData[fieldName].push(AppConstant.help.passwordIncorrect);
    return false;
  }
  return true;
}
export const validateConfirmPassword = (fieldName, data, errorData, passwordText) => {
  if (!_.isEqual(passwordText, data)) {
    errorData[fieldName].push(AppConstant.help.confirmPasswordIncorrect);
    return false;
  }
  return true;
}

export const validateFieldIsNum = (fieldName, data, errorData) => {
  if (!allowOnlyNumber.test(data)) {
    errorData[fieldName].push(AppConstant.help.onlyNumberAllowed);
    return false;
  } return true;
}
export const validateFieldIsText = (fieldName, data, errorData) => {
  if (!allowOnlyText.test(data)) {
    errorData[fieldName].push(AppConstant.help.onlyTextAllowed);
    return false;
  } return true;
}

export const validateCarrierList = (fieldName, data, errorData) => {
  if (!data) {
    errorData[fieldName].push(AppConstant.help.required);
  }
}
export const validateFieldMinValue = (fieldName, data, errorData, minValue) => {
  if (data < minValue) {
    errorData[fieldName].push(`${AppConstant.commonStrings.errorLengthValidation} ${minValue}`);
    return false;
  }
  return true;
}
export const validateStringIsOnlyNumber = (fieldName, data, errorData, minValue) => {
   // Check if the input value is a positive number and greater than or equal to zero
   const isValid = !isNaN(data) && data !== '' && parseFloat(data) >= 0;
  // if false which means data is not valid return false and  error data with field name and custom error message
  // else return true which means data is valid
  if (!isValid) {
    errorData[fieldName].push(`${AppConstant.commonStrings.onlyPositiveIntegerAllowed}`);
    return false;
  }
  return true;
}
