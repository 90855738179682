import { formMultiSelectDataForPayload } from "../../../../../Assests/Utility"

export const fetchPoListCommonFunc = (selectedWarehouse,pageNo,limit,searchParams,filters) => {

    const apiParams = {
        "pageNo":pageNo,
        "limit":limit,
        "searchParams":searchParams ? searchParams : "",
        "PoNumber": null,
        "OrderNumber": null,
        "VendorName": null,
        "BuyerName": null,
        "Commodity": null,
        "Cases": null,
        "PalletsCount": null,
        "Weight": null,
        "CRUDTYPE": "S",
        "Quantity": null,
        "DueDate": null,
        "ProduceType": null,
        "ZipCode": null,
        "CreatedOn": null,
        "CreatedBy": null,
        "WarehouseId": selectedWarehouse.ID,
        "IDPO": null,
        "WarehouseName": null,
        "DockName": null,
        "PoStatus": null,
        "Door": null,
        "UpdatedBy": null,
        "UpdatedOn":null,
        "selectedFilters":filters && filters.length ? formMultiSelectDataForPayload(filters) : 'Not Scheduled,'
    }

    const operationObj = {
        type: 'Fetch POs',
        status: ''
    }

    return {apiParams : apiParams, operationObj: operationObj}
}