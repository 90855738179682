/**
 * Method to establish connect to socket and listen for truck live updates
 * @param {*} warehouseID 
 * @param {*} url 
 * @param {*} onMessage 
 * @param {*} onError 
 * @param {*} onClose 
 * @returns 
 */
export const initializeUpdatesSocketConnection = (warehouseID, url, onMessage, onError, onClose, date) => {
    const ws = new WebSocket(url + `?warehouseID=${warehouseID}`);

    ws.onopen = () => {
    }

    ws.onmessage = (e) => {
        onMessage(e.data, date)
    };

    ws.onerror = (e) => {
        onError(e.message)
    };

    ws.onclose = (e) => {
        onClose(e)
    };

    return ws
}

/**
 * Method is used to close truck update socket connection
 * @param {*} socketRef 
 * @returns 
 */
export const closeUpdatesSocketConnection = (socketRef, IDWarehouse) => {
    // Close Socket connection only if it is open state

    if (socketRef.readyState === WebSocket.OPEN) {
        // Assuming socketRef is a valid WebSocket object
        const closeCode = 1000; // Standard code for a normal closure
        socketRef.close(closeCode, IDWarehouse);
    }
}
