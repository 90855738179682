import React, { useEffect, useState } from "react"
import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsSelector } from '../../../../../../../../redux/reducers/reducerSlices/ReportsReducerSlice';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import DrilldownTableBody from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableBody';
import { AppConstant } from "../../../../../../../Assests/AppConstant";
import { numberWithCommas } from '../../../../../../../Assests/Utility'


const TruckUnloadSummaryList = (props) => {

    const dispatch = useDispatch();
    const { themeSelected, isLoading } = props;
    const reportsSelector = useSelector(getReportsSelector);
    const { truckUnloadSummary } = reportsSelector;
    const [truckUnloadSummaryArr, setTruckUnloadSUmmryArr] = useState([]);

    const unloadSummaryTitleDD = [
        { id: 'Dock', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.dockNameStr },
        { id: 'TrucksUnloaded', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.trucksUnloadedStr },
        { id: 'TrucksUnloadedPct', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.truckUnloadedPctVendorStr },
        { id: 'EndPallets', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.endPallets },
        { id: 'PalletsperHour', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.palletsPerHour },
        { id: 'AvgUnloadTime', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.avgUnloadTime },
        { id: 'LoadswithUnloadDelay', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.loadsWithUnloadsDelay },
        { id: 'AvgCheckoutTime', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.avgCheckOutTime },
        { id: 'LoadswithCheckOutDelay', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.loadsWithCheckOutDelay },
        { id: 'LoadsArrivedLate', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.loadsArrivedLate },
        { id: 'AvgDoorAssigntoUnloadFinish', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.avgDoorAssignedToUnloadFinish },
        { id: 'AvgDoorAssigntoBillin', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.avgDoorAssignedToBillsIn },
        { id: 'AvgBillintoLumpStart', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.avgBillsInToUnloadStart },
        { id: 'AvgAppttoBillout', name: AppConstant.reports.truckUnloadSummaryReport.tableHeaders.avgApptToBillsOut },
    ]


    useEffect(() => {
        if (truckUnloadSummary && truckUnloadSummary.length) {

            setTruckUnloadSUmmryArr(truckUnloadSummary);
        }
    }, [truckUnloadSummary])

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: index,
                0: <div className="border border-1">{el.Dock ? el.Dock : '-'}</div>,
                1: <div className="border border-1">{el.TrucksUnloaded ? numberWithCommas(el.TrucksUnloaded) : '-'}</div>,
                2: <div className="border border-1">{el.TrucksUnloadedPct ? numberWithCommas(el.TrucksUnloadedPct) : '-'}</div>,
                3: <div className="border border-1">{el.EndPallets ? numberWithCommas(el.EndPallets) : '-'}</div>,
                4: <div className="border border-1">{el.PalletsperHour ? numberWithCommas(el.PalletsperHour) : '-'}</div>,
                5: <div className="border border-1">{el.AvgUnloadTime ? numberWithCommas(el.AvgUnloadTime) : '-'}</div>,
                6: <div className="border border-1">{el.LoadswithUnloadDelay ? numberWithCommas(el.LoadswithUnloadDelay) : '-'}</div>,
                7: <div className="border border-1">{el.AvgCheckoutTime ? numberWithCommas(el.AvgCheckoutTime) : '-'}</div>,
                8: <div className="border border-1">{el.LoadswithCheckOutDelay ? numberWithCommas(el.LoadswithCheckOutDelay) : '-'}</div>,
                9: <div className="border border-1">{el.LoadsArrivedLate ? numberWithCommas(el.LoadsArrivedLate) : '-'}</div>,
                10: <div className="border border-1">{el.AvgDoorAssigntoUnloadFinish ? numberWithCommas(el.AvgDoorAssigntoUnloadFinish) : '-'}</div>,
                11: <div className="border border-1">{el.AvgDoorAssigntoBillin ? numberWithCommas(el.AvgDoorAssigntoBillin) : '-'}</div>,
                12: <div className="border border-1">{el.AvgBillintoLumpStart ? numberWithCommas(el.AvgBillintoLumpStart) : '-'}</div>,
                13: <div className="border border-1">{el.AvgAppttoBillout ? numberWithCommas(el.AvgAppttoBillout) : '-'}</div>,
            }
        })
    }

    return (
        <div className="mx-2 py-2">
            
            <table className="m-0 p-0">
                <thead>
                    <tr>
                        {
                            unloadSummaryTitleDD.map((el) => {
                                return (
                                    <th colSpan={el.colSpan} scope="col" className={`col px-0 ${themeSelected} truckUnloadSummaryHeading text-center border border-1`} id={el.id} key={el.id}>
                                        <span className='text-center'>{el.name}</span>
                                    </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <DrilldownTableBody
                    themeSelected={themeSelected}
                    data={truckUnloadSummaryArr && truckUnloadSummaryArr && formatListDataDrillDown(truckUnloadSummaryArr)}
                    showCollapse={false}
                    showInput={false}
                    showSort={false}
                    initiateDrillDownReport={false}
                    loaderArray={17}
                    sortKey={true}
                    showUTCTime={true}
                />
            </table>
        </div>
    )
}

export default TruckUnloadSummaryList