
import { format, isToday } from "date-fns";
import CustomTooltip from "../../../Common/Tooltip/CustomTooltip";
import { AppConstant } from "../../../../Assests/AppConstant";



const TodayTypo = ({ date, onClick, locale,isNonOperational ,isClosedToday}) => {

  
  return (
    <div>
      <div
        style={{
          fontWeight: isToday(date) ? "bold" : "inherit",
        }}
        color={isToday(date) ? "primary" : "inherit"}
        className={onClick ? "rs__hover__op" : "d-flex gap-1"}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) onClick(date);
        }}
      >
        {format(date, "dd", { locale })}  
        {isNonOperational && 
        <>
        <div
        className="f-16 pointer color-red "
          data-testid={AppConstant.dataTestId.operational_days}
          data-tip={'Non Operational Day'}
          data-for={AppConstant.dataTestId.operational_days}
        > [!]</div> 
        <CustomTooltip
        id={AppConstant.dataTestId.operational_days}
        position="bottom"
        type="dark"
        multiline={true}
      />
      </>
        }

           
      </div>
      <div
        color={isToday(date) ? "primary" : "inherit"}
        style={{
          fontWeight: isToday(date) ? "bold" : "inherit",
          fontSize: 11,
        }}
      >
        {format(date, "eee", { locale })} 
        
      </div>
      {isClosedToday && isClosedToday.length ? 
      <>
      <div className="f-12"
       data-testid={AppConstant.dataTestId.facility_closer}
       data-tip={isClosedToday[0].Description}
       data-for={AppConstant.dataTestId.facility_closer}
      >{`Holiday !`}
        </div>
         <CustomTooltip
         id={AppConstant.dataTestId.facility_closer}
         position="bottom"
         type="dark"
         multiline={true}
       />
       </>
        :""}
    </div>
  );
};

export default TodayTypo;
