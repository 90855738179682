import _ from 'lodash';
import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import { Uchain } from '../redux/api/agent';
import { sagaActions } from './sagaActions';
import { getFsWarehouseListSuccess, updateIsLoading, getFsWarehouseListFailure, updateOperationObj, fsPayWarehouseListFailure, fsPayWarehouseListSuccess } from '../redux/reducers/reducerSlices/FsWarehouseReducer';
import { toast } from 'react-toastify';
import { showAlert } from '../App/Assests/Utility';
import { AppConstant } from '../App/Assests/AppConstant';

/**
 * Function to call the FS Warehouse API and get the data and also validates if any error
 * @param {*} data 
 */
function* getFsWarehouseSaga(data) {
    try {
        let tempStatusObj = { ...data.operationObj };
        yield put(updateIsLoading(true));
        const response = yield call(Uchain.getFSWarehouse, data.payload)
        if (tempStatusObj.type === 'Fetch Warehouse' && response) {
            tempStatusObj.status = true
            yield put(getFsWarehouseListSuccess(response))
            yield put(updateOperationObj(tempStatusObj));
        } else if (response.data && response.data === 'Successfully Inserted' || 'Successfully Updated' || 'Successfully Deleted') {
            tempStatusObj.status = true
            yield put(updateOperationObj(tempStatusObj));
        } else {
            tempStatusObj.status = false
            yield put(updateOperationObj(tempStatusObj));
        }
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
        yield put(getFsWarehouseListFailure())
    }
    finally {
        yield put(updateIsLoading(false));
    }
}

/**
 * Function is used to make API call to perform the FS pay warehouse CRUD operations
 * @param {*} data 
 */
function* fsPayWarehouseSaga(data) {
    try {
        let tempStatusObj = { ...data.operationObj };
        yield put(updateIsLoading(true));
        const response = yield call(Uchain.fsPayWarehouseDetails, data.payload)
        if (tempStatusObj.type === 'Fetch Warehouse' && response) {
            tempStatusObj.status = true
            yield put(fsPayWarehouseListSuccess(response))
            yield put(updateOperationObj(tempStatusObj));
        } else if (response.data && response.data === 'Successfully Inserted' || 'Successfully Updated' || 'Successfully Deleted') {
            tempStatusObj.status = true
            yield put(updateOperationObj(tempStatusObj));
        } else {
            tempStatusObj.status = false
            yield put(updateOperationObj(tempStatusObj));
        }
    } catch (error) {
        showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.wentWrong, 'light')
        yield put(fsPayWarehouseListFailure())
    }
    finally {
        yield put(updateIsLoading(false));
    }
}

export function* getFsWarehouseWatcher() {
    yield takeEvery(sagaActions.GET_FS_WAREHOUSE_DETAILS, getFsWarehouseSaga)
}

export function* fsPayWarehouseWatcher() {
    yield takeEvery(sagaActions.GET_FS_PAY_WAREHOUSE_DETAILS, fsPayWarehouseSaga)
}