import _ from 'lodash';
import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const NavDropDown = (props) => {
  const { data, redirect, onReportSelected ,parentIcon=null } = props;

  return (
    <ul className='nav-list py-0'>
      {
        _.size(data) && data.map(el => {
          return (
            <>
              <Link className="w-100" aria-current="page" to={redirect} ><li className="d-flex w-100" key={el?.ElementAccessID} onClick={(e) => onReportSelected(e, el)}>
                {parentIcon? <i className={parentIcon} aria-hidden="true"></i> : <i className='fa-solid fa-globe' aria-hidden="true"></i>}
                &nbsp;{el?.ElementAccess}
              </li>
              </Link>
            </>
          )
        })
      }

    </ul>
  )
}

export default NavDropDown