import React, { useEffect } from 'react'
import { AppConstant } from '../../../../Assests/AppConstant';
import { showLoader } from '../../../../Assests/Utility';

// This compenent render gaurd login loader
const GaurdLogin = (props) => {
    useEffect(() => {
        props?.autoSignin()
    },[])
    return ( 
        <div>
            {showLoader(AppConstant.login.autoSignInText)}
        </div>
     );
}
 
export default GaurdLogin;