import React from 'react';
import Header from '../../../../Header/Header';
import Navigationbar from '../../../../Navigationbar/Navigationbar';
import RoleFeaturesListPage from './Components/RoleFeaturesList/RoleFeaturesList';
import { useLocation } from 'react-router-dom';

const RoleFeaturesEditList = (props) => {
    const location = useLocation();

    return(
        <div>
            <Header {...props} />
            <Navigationbar {...props} />
            <RoleFeaturesListPage {...props} data={location.state} />
        </div>
    )

}

export default RoleFeaturesEditList