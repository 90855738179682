import React,{ useEffect } from 'react';
import FunnelGraph from '../FunnelGraphLibrary/src/js/main'
import "./cssfiles/main.css";
import "./cssfiles/theme.css";
import "./cssfiles/styles.css";
import './funnel.css';
import _ from 'lodash-es'

  const FeaturedInfo = (props) => {

    const {labelsArr, valuesArr, subLabelsArr, labelsValue, colorArr, legendsArr, height, width, themeSelected, containerId, onClickAction } = props;

    useEffect(() => {
      initGraph();
    }, [])

    const initGraph = () => {
      const dataObj = {
        labels: [...labelsArr],
        subLabels: [...subLabelsArr],
        colors: [...colorArr],
        values: [...valuesArr],
        subTitles : [...labelsValue]
      }
      if(dataObj.labels.length > 0) showGraph(dataObj);
    }

    const showGraph = (dataObj) => {

      const graph = new FunnelGraph({
        container: "."+containerId,
        gradientDirection: "horizontal",
        gradient:'none',
        maintainAspectRatio : "false",
        data: dataObj,
        displayPercent: false,
        direction: "horizontal",
        width: parseInt(width),
        height: parseInt(height),
        subLabelValue : 'percent',
        themeSelected : themeSelected
      });
      graph.draw();
      const stageElements = document.querySelectorAll('.svg-funnel-js__label'); // Change the selector to match your funnel elements

      stageElements.forEach((element, index) => {
        element.addEventListener('click', (event) => {
          // Handle the click event here
          const clickedElement = event.target;
          if (clickedElement) {
            const closestAncestor = clickedElement.closest('.svg-funnel-js__label');
            if (closestAncestor) {
              const title = closestAncestor.querySelector('.label__title')
              if (title) {
                const titleText = title?.textContent;
                if (titleText) {
                  const splitText = titleText.split(' ')
                  if (_.size(splitText)) {
                    // You can trigger any specific actions or custom logic here based on the click.
                    onClickAction(splitText[0])
                  }
                }
              }
            }
          }
        });
      });
    }

    return (
        <div className="m-3 me-0 pe-0 ">
          <div className={`${containerId}`} />
        </div>
    );

  }
 export default FeaturedInfo;
