import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    auth: null,
    error: null,
    sessionToken: '',
    getSignInLoading: false,
    passwordRecover: false,
    verficationCode: '',
    setPasswordLoading: false,
    setPasswordStatus: null,
    setPasswordError: null,
    recoveryEmail: '',
    isPasswordResetSuccess: false,
    rememberUser : false,
    user_name: '',
    changePasswordSuccess: false,
    changePasswordError: false
}

export const AuthReducer = createSlice({
    name: 'authReducer',
    initialState,
    reducers: {
        signin: (state) => {
            state.getSignInLoading = true;
        },
        getSignSuccess: (state, action) => {
            const {response, email} = action.payload
            state.getSignInLoading = false;
            state.sessionToken = response.signInUserSession?.idToken.jwtToken
            state.auth = response;
            state.user_name = email;
        },
        getSignFailure: (state, action) => {
            state.getSignInLoading = false;
            state.sessionToken = ''
            state.auth = null;
            state.error = action.payload
        },
        clearError: (state) => {
            state.getSignInLoading = false;
            // state.auth = null;
            state.error = null;
            state.setPasswordError = null
        },
        clearUser: (state) => {
            state.getSignInLoading = false;
            state.auth = null;
            state.error = null
            state.sessionToken = '';
            state.recoveryEmail = '';
        },
        passwordRecoverInitiated: (state, action) => {
            state.passwordRecover = true;
            state.recoveryEmail =  action.payload
        },
        passwordRecoverSuccess: (state) => {
            state.passwordRecover = false;
        },
        passwordRecoverFailure: (state, action) => {
            state.passwordRecover = false;
            state.recoveryEmail = '';
            state.auth = null;
            state.error = action.payload
        },
        passwordResetInitiated: (state) => {
            state.isPasswordResetSuccess = true;
        },
        passwordResetSuccess: (state) => {
            state.isPasswordResetSuccess = false;
            state.passwordRecover = false;
            state.verficationCode = '';
        },
        setPasswordInt: (state) => {
            state.setPasswordLoading = true;
        },
        setPasswordSuccess: (state, action) => {
            state.setPasswordLoading = false;
            state.setPasswordStatus = action.payload;
            state.sessionToken = action.payload.signInUserSession?.idToken.jwtToken;
        },
        setPasswordFailure: (state, action) => {
            state.setPasswordLoading = false;
            state.setPasswordError = action.payload;
            state.sessionToken = '';
        },
        clearSetPassword: (state) => {
            state.setPasswordLoading = false;
            state.setPasswordStatus = null;
            state.setPasswordError = null
        },
        rememberUserReducer: (state, action) => {
            state.rememberUser = action.payload;
        },
        changePasswordUserReducer: (state, action) => {
            state.changePasswordSuccess = action.payload;
            state.changePasswordError = false;
        },
        changeErrorPasswordUserReducer: (state, action) => {
            state.changePasswordError = action.payload;
        }

    }
});

export const { signin, getSignSuccess, getSignFailure, clearError, clearUser, passwordRecoverInitiated, passwordRecoverSuccess, passwordRecoverFailure,setPasswordInt, setPasswordSuccess, setPasswordFailure, clearSetPassword, passwordResetInitiated, passwordResetSuccess, rememberUserReducer, changePasswordUserReducer, changeErrorPasswordUserReducer } = AuthReducer.actions;
        
// Selectors
export const getAuthReducer = (state) => {
    return state.authReducer;
};

export const isUserRememberedReducer = (state) => {
    return state.authReducer.rememberUser;
};

export default AuthReducer.reducer;
