import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initateLoading: false,
  error: false,
  truckUnloadSummary: [],
  adfatReport: [],
  adfatReportResponse: [],
  vendorList: [],
  carrierList: [],
  truckUnloadStats: [],
  scheduledLoadStatusList : [],
  selectedReportObj : {}
}

export const ReportsReducerSlice = createSlice({
  name: 'reportsReducerSlice',
  initialState,
  reducers: {
    updateLoader: (state, action) => {
      state.initateLoading = action.payload
    },
    updateTruckUnloadSummaryList : (state, action) => {
      state.truckUnloadSummary = action.payload
    },
    updateADFATReport : (state, action) => {
      state.adfatReport = action.payload
    },
    updateADFATReportResponse : (state, action) => {
      state.adfatReportResponse = action.payload
    },
    updateCarrierList: (state, action) => {
      state.carrierList = action.payload
    },
    updateVendorList: (state, action) => {
      state.vendorList = action.payload
    },
    updateTruckUnloadStatsList : (state, action) => {
      state.truckUnloadStats = action.payload
    },
    updateScheduledLoadStatusList : (state, action) => {
      state.scheduledLoadStatusList = action.payload
    },
    updateSelectedReport : (state, action) => {
      state.selectedReportObj = action.payload
    },
  }
});

export const { updateResponseData, updateTruckUnloadSummaryList, updateADFATReport, updateLoader, updateCarrierList, updateVendorList, updateTruckUnloadStatsList, updateScheduledLoadStatusList, updateSelectedReport, updateADFATReportResponse } = ReportsReducerSlice.actions;

// Selectors
export const getReportsSelector = (state) => {
  return state.reportsReducerSlice;
};


export default ReportsReducerSlice.reducer;