import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import './FinanceReport.css';
import moment from 'moment-timezone';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { initiateSearch } from '../../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import { getPoSSselector } from '../../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import WarehouseSearch from '../../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import DownloadSection from '../../../../DownloadSection/DownloadSection';
import { usePrevious } from '../../../../../../Common/CustomHooks/usePrevious';
import SimpleDatePicker from '../../../../../../DatePicker/SimpleDatePicker';
import { getDefaultDashboardSelector } from '../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';

const FinanceReport = (props) => {
    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const [financeReportData, setfinanceReportData] = useState([]);
    const pOSS = useSelector(getPoSSselector);
    const { ssFinanceReport, arrangeOrder, arrangeKey, ssFinanceReportSummary } = pOSS;
    const [searchStr, setSearchStr] = useState('');
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const divRef = useRef(null);
    const [showLoading, setShowLoading] = useState(true);
    const previousTable = usePrevious(currentTable);
    const dashboard = useSelector(getDefaultDashboardSelector)
    const { dateRangeObj } = dashboard

    const reportTitleDD = [
        // { id: 's_no', name: AppConstant.reports.ssFinanceReport.S_NO },
        { id: 'CarrierName', name: AppConstant.reports.ssFinanceReport.carrier },
        { id: 'MCR', name: AppConstant.reports.ssFinanceReport.mcr },
        { id: 'Name', name: AppConstant.superUserAdmin.companyGroup.tableHeader.name },
        // { id: 'FirstName', name: AppConstant.reports.ssFinanceReport.firstName },
        // { id: 'LastName', name: AppConstant.reports.ssFinanceReport.lastName },
        { id: 'SusStart', name: AppConstant.reports.ssFinanceReport.date },
        // { id: 'approvalCode', name: AppConstant.reports.ssFinanceReport.approvalCode },
        { id: 'PaymentRefernceNumber', name: AppConstant.reports.ssFinanceReport.tansID },
        { id: 'AccountNumber', name: AppConstant.reports.ssFinanceReport.accNum },
        { id: 'Amount', name: AppConstant.reports.ssFinanceReport.amount },
    ]

    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })

    useEffect(() => {
        dispatch({ type: sagaActions.RESET_APPTS_LIST_ACTION });
        setShowLoading(true);
        setfinanceReportData([]);
       
    }, [])

    useEffect(() => {
        setTimeout(() => {
            fetchFinanceReport()
        }, [500])
    }, [dateRangeObj])


    useEffect(() => {
        if (ssFinanceReport && ssFinanceReport.length > 0) {
            setShowLoading(false);
            setfinanceReportData(ssFinanceReport)
        } else {
            setShowLoading(false)
            setfinanceReportData([])
        }
    }, [ssFinanceReport])

    const fetchFinanceReport = () => {
        setShowLoading(true);
        const start = dateRangeObj?.start ? moment(dateRangeObj.start).format('YYYY-MM-DD') : null;
        const end = dateRangeObj?.end ? moment(dateRangeObj.end).format('YYYY-MM-DD') : null;
        const apiParams = {
            EndDate: start === end ? null : end,
            StartDate: start,
            fromDate: null,
            toDate: null
        }

        dispatch({
            type: sagaActions.SS_FINANCE_REPORT_ACTION, payload: apiParams
        });
    }

    useEffect(() => {
        if (searchStr.length > 3 && ssFinanceReport.length > 0) {
            const searchedArr = initiateSearch(ssFinanceReport, searchStr, 'GroupName');
            setfinanceReportData(searchedArr);
        } else if (searchStr === '') {
            setfinanceReportData(ssFinanceReport)
        }
    }, [searchStr])


    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                id: el.RowID,
                // 0: index + 1,
                0: el.CarrierName || '-',
                1: el.MCR || '-',
                2: el.FirstName ? `${el.FirstName} ${el.LastName}` : '-',
                // 3: el.FirstName || '-',
                // 4: el.LastName || '-',
                3: moment(el.ActualSubsStart).format('MM/DD/YYYY') || '-',
                // 6: '-',
                4: el.PaymentRefernceNumber,
                5: el.AccountNumber || '-',
                6: el.Amount || '-',
            }
        })
    }

    return (
        <div className=''>
            <div className='d-flex justify-content-between my-2 mx-3'>
                <div className='fw-bold'>{AppConstant.reports.ssFinanceReport.finanaceReport}</div>
                <div className='d-flex w-65 justify-content-end'>
                    {/* <div className='w-40 mx-3 d-flex justify-content-center align-items-center'>
                        <SimpleDatePicker />
                    </div> */}
                    <div className='w-40'>
                        <WarehouseSearch
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                    <div className='d-flex align-items-center mx-3 w-20'>
                        <DownloadSection singlePageDownload={true} root={''} subRoot={''} name={'Appointments Report'} themeSelected={themeSelected} currentTable={currentTable} currentTableRef={currentTableRef} />
                    </div>
                </div>

            </div>
            <div>
                <div className='d-flex flex-column'>
                    <div className='mx-3'>
                        <div className='fw-bold f-16'>Summary View</div>
                        <div className='px-2 boldText'>
                        <div>
                            <span>Total Count of Subscribers: </span>
                            <span>{ssFinanceReportSummary?.TotalSubscriptionCount || '0'}</span>
                        </div>
                        <div>
                            <span>Active Subscriber Amount: </span>
                            <span>{'$' + ssFinanceReportSummary?.TotalSubscriptionAmount || '0'}</span>
                        </div>
                        <div>
                            <span>Cancelled Subscriptions: </span>
                            <span>{ssFinanceReportSummary?.TotalSubscriptionsCanceledCount || '0'}</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='allSubsListContainer' ref={divRef}>
                    <DrilldownTable
                    themeSelected={themeSelected}
                    bodyData={financeReportData && _.size(financeReportData) ? formatListDataDrillDown(financeReportData): []}
                    titleData={reportTitleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    arrangeOrder={arrangeOrder}
                    arrangeKey={arrangeKey}
                    drillDownReport={ssFinanceReport}
                    initiateDrillDownReport={showLoading}
                    loaderArray={7}
                    sortingAction={sagaActions.SORT_SS_USER_TABLE}
                    subRoot={{ pathName: 'financeReportObj' }}
                    showUTCTime={true}
                />
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default FinanceReport