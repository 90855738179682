import React, { useEffect } from 'react'
import { getAuthReducer } from '../../../redux/reducers/reducerSlices/AuthReducer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * Reusable component to secure protected routes
 * @param {*} props 
 * @returns 
 */
const Protected = (props) => {
    const {component} = props;
    const user = useSelector(getAuthReducer);
    const navigate = useNavigate();

    /**
     * useEffect to navigate user to login screen if not loged in
     */
    useEffect(()=>{
        if (!user.sessionToken) {
            navigate("/");
        }
    },[user.sessionToken])
    
  return (!user?.sessionToken?
    "":<>{component}</>
  )
}

export default Protected