import React, { useEffect, useState } from "react"
import _ from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsSelector } from '../../../../../../../../redux/reducers/reducerSlices/ReportsReducerSlice';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import DrilldownTableBody from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableBody'
import { AppConstant } from "../../../../../../../Assests/AppConstant";

const TruckUnloadStatisticsList = (props) => {

    const dispatch = useDispatch();
    const { selectedDate, selectedWarehouse, loading, setLoading } = props;
    const { themeSelected } = props;
    const reportsSelector = useSelector(getReportsSelector);
    const { truckUnloadStats } = reportsSelector;
    const [truckUnloadStatsArr, setTruckUnloadStatsArr] = useState([]);

    useEffect(() => {
        setLoading(false)
        setTruckUnloadStatsArr([])
        if (truckUnloadStats && truckUnloadStats.length) {
            setTruckUnloadStatsArr(truckUnloadStats);
        }
    }, [truckUnloadStats])

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            if (el.col1 !== ''
                && el.col2 === null
                && el.col3 === null
                && el.col4 === null
                && el.col5 === null
                && el.col6 === null
                && el.col7 === null
                && el.col8 === null) {
                return {
                    id: index,
                    0: <div className="h1TruckUnloadSummary text-start ms-2 mt-3">{el.col1}</div>,
                    1: '',
                    2: '',
                    3: '',
                    4: '',
                    5: '',
                    6: '',
                    7: '',
                    8: ''
                }
            } else if (
                el.col1 === null
                && el.col2 !== null && el.col2 !== 'Sunday'
                && el.col3 !== null && el.col3 !== 'Monday'
                && el.col4 !== null && el.col4 !== 'Tuesday'
                && el.col5 !== null && el.col5 !== 'Wednesday'
                && el.col6 !== null && el.col6 !== 'Thursday'
                && el.col7 !== null && el.col7 !== 'Friday'
                && el.col8 !== null && el.col8 !== 'Saturday'
                && el.col9 === 'Total') {
                return {
                    id: index,
                    0: <div className="h2TruckUnloadSummary border border-1">Date</div>,
                    1: <div className="h2TruckUnloadSummary border border-1">{el.col2}</div>,
                    2: <div className="h2TruckUnloadSummary border border-1">{el.col3}</div>,
                    3: <div className="h2TruckUnloadSummary border border-1">{el.col4}</div>,
                    4: <div className="h2TruckUnloadSummary border border-1">{el.col5}</div>,
                    5: <div className="h2TruckUnloadSummary border border-1">{el.col6}</div>,
                    6: <div className="h2TruckUnloadSummary border border-1">{el.col7}</div>,
                    7: <div className="h2TruckUnloadSummary border border-1">{el.col8}</div>,
                    8: ''
                }
            } else if (
                el.col1 === null
                && el.col2 === "Sunday"
                && el.col3 === "Monday"
                && el.col4 === "Tuesday"
                && el.col5 === "Wednesday"
                && el.col6 === "Thursday"
                && el.col7 === "Friday"
                && el.col8 === "Saturday"
                && el.col9 === 'Total') {
                return {
                    id: index,
                    0: <div className="h2TruckUnloadSummary border border-1">Days</div>,
                    1: <div className="h2TruckUnloadSummary border border-1">{el.col2}</div>,
                    2: <div className="h2TruckUnloadSummary border border-1">{el.col3}</div>,
                    3: <div className="h2TruckUnloadSummary border border-1">{el.col4}</div>,
                    4: <div className="h2TruckUnloadSummary border border-1">{el.col5}</div>,
                    5: <div className="h2TruckUnloadSummary border border-1">{el.col6}</div>,
                    6: <div className="h2TruckUnloadSummary border border-1">{el.col7}</div>,
                    7: <div className="h2TruckUnloadSummary border border-1">{el.col8}</div>,
                    8: <div className="h2TruckUnloadSummary border border-1">Total</div>
                }
            } else {
                return {
                    id: index,
                    0: <div className="border border-1">{el.col1}</div>,
                    1: <div className="border border-1">{el.col2 ? el.col2 : 0}</div>,
                    2: <div className="border border-1">{el.col3 ? el.col3 : 0}</div>,
                    3: <div className="border border-1">{el.col4 ? el.col4 : 0}</div>,
                    4: <div className="border border-1">{el.col5 ? el.col5 : 0}</div>,
                    5: <div className="border border-1">{el.col6 ? el.col6 : 0}</div>,
                    6: <div className="border border-1">{el.col7 ? el.col7 : 0}</div>,
                    7: <div className="border border-1">{el.col8 ? el.col8 : 0}</div>,
                    8: <div className="border border-1">{el.col9 ? el.col9 : 0}</div>
                }
            }

        })
    }

    return (
        <div className="mx-2" id="truckUnloadStatsTable">
            
            {
                truckUnloadStatsArr && truckUnloadStatsArr.length ?
                    <table className="m-0 p-0">
                        <thead />
                        <DrilldownTableBody
                            themeSelected={themeSelected}
                            data={truckUnloadStatsArr && truckUnloadStatsArr && formatListDataDrillDown(truckUnloadStatsArr)}
                            showCollapse={false}
                            showInput={false}
                            showSort={false}
                            initiateDrillDownReport={loading}
                            loaderArray={17}
                            showUTCTime={true}
                        />
                    </table>
                    : <div />
            }

        </div>
    )

}

export default TruckUnloadStatisticsList;