import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConstant } from "../../../../../../../Assests/AppConstant";
import { sagaActions } from "../../../../../../../../sagas/sagaActions";

import Popup from "../../../../../../Common/Popup/Popup";
import moment from "moment"
import SproWarehouseList from "../SproWarehouseList/SproWarehouseList";
import { getCompanyGroupReducer } from "../../../../../../../../redux/reducers/reducerSlices/CompanyGroupReducer";
import { getCompanyReducer } from "../../../../../../../../redux/reducers/reducerSlices/CompanyReducer";
import { CustomDynamicForm } from "../../../../../../Common/CustomForm/CustomDynamicForm";
import { getTimeZoneReducer } from "../../../../../../../../redux/reducers/reducerSlices/GetTimeZoneReducer";
import { getCountryList } from "../../../../../../../../redux/reducers/reducerSlices/GetCountryListReducer";
import { showAlert, initiateSearch, sortArrByKey, shouldHavePermission, sortAndFilterArrByKey } from "../../../../../../../Assests/Utility";
import { toast } from "react-toastify";
import { GlobalModal } from "../../../../../../Login/components/Modal/GloabalModal"
import { random } from "lodash-es";
import WarehouseSearch from "../../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch";
import { getUserSelector } from "../../../../../../../../redux/reducers/reducerSlices/UserReducer";
import { getWarehouseDataReducer } from "../../../../../../../../redux/reducers/reducerSlices/GetWarehouseDataReducer";
import { addWarehouseReducer } from "../../../../../../../../redux/reducers/reducerSlices/AddWarehouseReducer";

/**
 * Used to get the list of spro warehouse details pass those data as props
 * to the spro warehouse list component 
 * and also perform the CRUD operations for the spro warehouse creation
 * @param {*} props 
 * @returns 
 */
const SproWarehouseAdminScreen = (props) => {
  const { themeSelected } = props;
  const [loading, setLoading] = useState(false);
  const warehouseData = useSelector(getWarehouseDataReducer);
  const [warehouseListData, setWarehouseListData] = useState([]);

  const { arrangeKey, arrangeOrder } = warehouseData;
  const addFormDataReturn = useSelector(addWarehouseReducer);

  const companyGroupData = useSelector(getCompanyGroupReducer);
  const timeZoneData = useSelector(getTimeZoneReducer);
  const companyData = useSelector(getCompanyReducer);
  const countryData = useSelector(getCountryList);

  const [loaderId, setLoaderId] = useState({ loading: false, id: '' });
  const [comapnyArr, setComapnyArr] = useState([]);
  const [comapnyGroupArr, setComapnyGroupArr] = useState([]);
  const [lumpDataArr, setLumpDataArr] = useState([]);
  const [timeZoneDataArr, setTimeZoneDataArrDataArr] = useState([]);
  const [taxCodeDataArr, setTaxCodeDataArrDataArr] = useState([]);
  const [countryDataArr, setCountryDataArrArr] = useState([]);
  const [editData, setEditData] = useState({});

  const [searchStr, setSearchStr] = useState('');
  const dispatch = useDispatch();
  const [warehouseListLoader, setWarehouseListLoader] = useState(true)
  const [allowPermissionC, setAllowPermissionC] = useState(false)
  const [allowPermissionE, setAllowPermissionE] = useState(false)
  const [allowPermissionD, setAllowPermissionD] = useState(false)
  const user = useSelector(getUserSelector);
  const { permissions } = user;

  useEffect(() => {
    dispatch({type: sagaActions.SPRO_RESET_WAREHOUSE_LIST})

    if (permissions.length) {
      let tempC = shouldHavePermission(permissions, '301')
      setAllowPermissionC(tempC);
      let tempE = shouldHavePermission(permissions, '302')
      setAllowPermissionE(tempE);
      let tempD = shouldHavePermission(permissions, '303')
      setAllowPermissionD(tempD);
    }

    getWareHouseList();
    dispatch({ type: sagaActions.RESET_COMPANY_DATA });
    dispatch({ type: sagaActions.COMPANY_GROUP, payload: { crud_type: "S" } });
    dispatch({ type: sagaActions.GET_TIME_ZONE_DATA });
    dispatch({ type: sagaActions.GET_COUNTRY_DATA });
    
    return () => {
      dispatch({type: sagaActions.SPRO_RESET_WAREHOUSE_LIST})
    }

  }, [])

  useEffect(() => {
    if (searchStr.length > 3) {
      const searchedArr = initiateSearch(warehouseData.data, searchStr, 'WarehouseName');
      setWarehouseListData(searchedArr);
    } else if (searchStr === '') {
      setWarehouseListData(warehouseData.data)
    }
  }, [searchStr])

  const getWareHouseList = () => {
    dispatch({ type: sagaActions.SPRO_GET_WAREHOUSE_LIST, payload: { "crud_type":'S' } });

  }

  useEffect(() => {
    setWarehouseListLoader(warehouseData.loading);
    if (warehouseData.data) {
      if (searchStr) {
        const searchedArr = initiateSearch(warehouseData.data, searchStr, 'WarehouseName');
        setWarehouseListData(searchedArr);
      } else {
        setWarehouseListData(warehouseData.data);
      }
    }
  }, [warehouseData]);
  //country set data
  useEffect(() => {
    if (countryData.data) {
      setCountryDataArrArr(sortArrByKey(countryData.data, 'country_name'));
    }
  }, [countryData.data]);

  const getCountryDataList = () => {
    return (
      countryDataArr &&
      countryDataArr.map((data, index) => {
        return (
          <option key={data.country_name} value={data.country_name}>
            {data.country_name}
          </option>
        );
      })
    );
  };

  useEffect(() => {
    if (timeZoneData.data) {
      setTimeZoneDataArrDataArr(sortArrByKey(timeZoneData.data, 'Description'));
    }
  }, [timeZoneData.data]);
  const getTimeZoneList = () => {
    return (
      timeZoneDataArr &&
      timeZoneDataArr.map((data, index) => {
        return (
          <option key={data.ID} value={data.ID}>
            {data.Description}
          </option>
        );
      })
    );
  };
  
  useEffect(() => {
    if (companyGroupData.data) {
      setComapnyGroupArr(sortArrByKey(companyGroupData.data, 'GroupName'));
    }
  }, [companyGroupData.data]);
  const getCompanyGroupList = () => {
    return (
      comapnyArr &&
      comapnyGroupArr.map((data, index) => {
        return (
          data?.EnableStatus &&
          <option key={data.CompanyGroupId} value={data.CompanyGroupId}>
            {data.GroupName}
          </option>
        );
      })
    );
  };
  const getFormFieldData = (data, name) => {

    if (name === "companyGroup") {
      dispatch({
        type: sagaActions.COMPANY_DATA,
        payload: { crud_type: "S", company_group_id: data },
      });
    }
  };
  useEffect(() => {
    if (companyData.data) {
      setComapnyArr(sortAndFilterArrByKey(companyData.data, 'CompanyName'));
    }
    else {
      setComapnyArr([]);
    }
  }, [companyData.data]);
  const getCompanyList = () => {

    return (
      comapnyArr &&
      comapnyArr.map((data, index) => {
        return (
          data?.EnableStatus &&
          <option key={data.CompanyId} value={data.CompanyId}>
            {data.CompanyName}
          </option>
        );
      })
    );
  };

  let formFieldList = [
    {
      name: "companyGroup",
      type: "select",
      size: "col-sm-6",
      position: 1,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
          .companyGroup,
      formValidation: {
        required: true,
      },

      getSelectData: getCompanyGroupList,
    },
    {
      name: "company_id",
      type: "select",
      size: "col-sm-6",
      position: 2,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.company,
      formValidation: {
        required: true,
      },
      getSelectData: getCompanyList,
    },
    {
      name: "wh_name",
      type: "text",
      size: "col-sm-6",
      position: 3,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
          .warehouseNameTitleStr,
      formValidation: {
        required: true,
      },
    },
    {
      name: "wh_date_activated",
      type: "datetime-local",
      size: "col-sm-6",
      position: 4,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
          .warehouseDateActivated,
      formValidation: {
        required: true,
      },
    },
   
    {
      name: "time_zone_id",
      type: "select",
      size: "col-sm-6",
      position: 5,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.timezone,
      formValidation: {
        required: true,
      },
      getSelectData: getTimeZoneList,
    },
   
    {
      name: "address",
      type: "textarea",
      size: "col-sm-6",
      position: 6,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.address,
      formValidation: {
        required: true,
      },
    },
    {
      name: "city",
      type: "text",
      size: "col-sm-6",
      position: 7,
      title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.city,
      formValidation: {
        required: true,
      },
    },
    {
      name: "zip",
      type: "text",
      size: "col-sm-6",
      position: 8,
      title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zip,
      formValidation: {
        required: true,
        allowOnlyNumber: true,
        lengthValidation: {
          minLength: 1,
          maxLength: 6,
          customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.zipCodeErrorMessage
        },
      },
    },
    {
      name: "state",
      type: "text",
      size: "col-sm-6",
      position: 9,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.state,
      formValidation: {
        required: true,
      },
    },

    {
      name: "country",
      type: "select",
      size: "col-sm-6",
      position: 10,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.country,
      formValidation: {
        required: true,
      },
      getSelectData: getCountryDataList,
    },
    {
      name: "sub_domain_name",
      type: "text",
      size: "col-sm-6",
      position: 11,
      placeholder: 'Companyname.frieghtsmith.com',
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.subdomain,
      formValidation: {
        required: true,
      },
      getSelectData: getCountryDataList,
    },
    {
      name: "region_code",
      type: "text",
      size: "col-sm-6",
      position: 12,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
          .regionCode,
      formValidation: {
        required: true,
        lengthValidation: {
          minLength: 4,
          maxLength: 4,
        },
      },

    },
    {
      name: "support_email",
      type: "text",
      size: "col-sm-6",
      position: 13,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
          .sEmail,
      formValidation: {
        validateEmail: true,
      },

    },
    {
      name: "is_branding",
      type: "radio",
      size: "col-sm-6",
      position: 14,
      title:
        AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
          .branding,
      formValidation: {
        required: true,
      },
      radiconfig: [{
        label: "on",
        value: true
      },
      {
        label: "off",
        value: false
      }]

    },
    {
      name: "wh_logo",
      type: "file",
      size: "col-sm-6",
      position: 15,
      accept: "image/png,image/jpeg",
      title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.imageLogo,


    },
    {
      name: "bg_image",
      type: "file",
      size: "col-sm-6",
      position: 16,
      title: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.imageback,
      accept: "image/png,image/jpeg",

    },
  ];




  const getSummitedFormData = (data) => {
    setLoading(true);
    if (!data.id) {
      data.id = "";
      data.crud_type = "I";
      data.wh_de_activated_date = "";
      data.wh_date_activated = moment(data.wh_date_activated).format('YYYY-MM-DD hh:mm')
      dispatch({ type: sagaActions.SPRO_ADD_WAREHOUSE, payload: data });
    }
    else {

      data.id = editData.id;
      data.crud_type = "U";
      data.wh_de_activated_date = "";
      data.wh_date_activated = moment(data.wh_date_activated).format('YYYY-MM-DD hh:mm')
      dispatch({ type: sagaActions.SPRO_ADD_WAREHOUSE, payload: data });
    }


  };
  useEffect(() => {

    if (addFormDataReturn && loading || addFormDataReturn && loaderId.loading) {
      if (addFormDataReturn.loading === false) {
        setLoading(false);
        setLoaderId({ loading: false, id: '' });
        if (addFormDataReturn.data) {

          getWareHouseList();

          setShowModal(false);
          showAlert(toast.TYPE.SUCCESS, addFormDataReturn.data.RESULT);
          dispatch({ type: sagaActions.SPRO_RESET_WAREHOUSE });


        }
        else {
          if (addFormDataReturn.error) {

            showAlert(toast.TYPE.ERROR, addFormDataReturn.error.message);
          }
        }
      }
    }
  }, [addFormDataReturn])
  const editClicked = (data) => {
    dispatch({
      type: sagaActions.COMPANY_DATA,
      payload: { crud_type: "S", company_group_id: data.CompanyGroupId, },
    });

    

    let editData1 = {
      "id": data.ID,
      "wh_de_activated_date": "",
      "companyGroup": data.CompanyGroupId,
      "company_id": data.CompanyID,
      "wh_name": data.WarehouseName,
      "wh_date_activated": moment(data.DateActivated).format('YYYY-MM-DD hh:mm'),
      "region_code": data.RegionCode,
      "time_zone_id": data.IdTimezone,
      "address": data.Address,
      "city": data.City,
      "zip": data.Zip,
      "state": data.State,
      "country": data.Country,
      "wh_logo": data.WarehouseLogo,
      "sub_domain_name": data.Subdomain,
      "bg_image": data.BackgroundImage,
      "support_email": data.SupportEmail,
      "is_branding": data.IsBranding?.toString()
    }
    setEditData(editData1);
    setShowModal(true);
  }

  const changeWarehouseStatus = (data) => {
    let deleteData = {
      id: data.ID,
      crud_type: "D",
      wh_de_activated_date: null,
    }

    if (!data.DateDeactivated) {
      deleteData.wh_de_activated_date = moment(new Date()).format('YYYY-MM-DD hh:mm')
    }
    setLoaderId({ loading: true, id: data.ID });

    dispatch({ type: sagaActions.SPRO_ADD_WAREHOUSE, payload: deleteData });
  }
  const [showModal, setShowModal] = useState(false);
  const closepopup = () => {

    setEditData({});
    setShowModal(false);
  }
  const submitbtnData = {
    show: true,
    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
      .saveButtonTitle,
  };
  const cancelbtndata = {
    show: true,
    text: AppConstant.comments.resetbutton,
  };
  const createForm = () => {
    return (
      <CustomDynamicForm
        formFieldList={formFieldList}
        sort={true}
        cancelBtn={cancelbtndata}
        submitbtn={submitbtnData}
        themeSelected={themeSelected}
        getSummitedFormData={getSummitedFormData}
        getFormFieldData={getFormFieldData}
        editFormData={editData}
        loading={loading}

      />
    );
  };

  const popupObj = {
    id: "createWarehouseFormPopup1",
    modalLabel: "createWarehouseFormPopup1",
    showClose: true,
    showfooter: true,
    showHeader: true,
    sendDriverNotification: false,
    modalSize: "xl",
    title: Object.keys(editData).length ? AppConstant.superUserAdmin.warehouseAdmin.buttonTitles
      .editWarehouseButtonTitle :
      AppConstant.superUserAdmin.warehouseAdmin.buttonTitles
        .createWarehouseButtonTitle,
    bodyText: createForm(),
    subText: "",
    keyboard: false,
    backdrop: "static",
    footerBtn: {
      btn1: {
        show: true,
        text: AppConstant.comments.cancelCommentButtonTitle,
      },
      btn2: {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
          .saveButtonTitle,
      },
    },
  };



  return (
    <div className="pt-2">

      <div className='container-fluid d-flex py-2 text-start justify-content-between'>
        <button
          className={'addButton'}
          data-bs-toggle="modal"
          onClick={() => setShowModal(true)}
        >
          {
            AppConstant.superUserAdmin.warehouseAdmin.buttonTitles
              .createWarehouseButtonTitle
          }
        </button>
        <div className='w-25'>
          <WarehouseSearch
            placeholder={'Search Warehouses'}
            inputValue={searchStr}
            cancelSearch={() => setSearchStr('')}
            onChangeHandler={(text) => setSearchStr(text)} />
        </div>
      </div>

      <div className="roleListTable mt-1">

        <SproWarehouseList propsObj={props} userObj={user} warehouseListLoader={warehouseListLoader} warehouseListData={warehouseListData} editClicked={editClicked} allowPermissionE={allowPermissionE}
          allowPermissionD={allowPermissionD} changeWarehouseStatus={changeWarehouseStatus} loaderId={loaderId} themeSelected={themeSelected} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} />
        <Popup
          {...popupObj}
          showModal={showModal}
          showfooter={false}
          closepopup={closepopup}
          themeSelected={themeSelected}

        />
      </div>
    </div>
  );
};

export default SproWarehouseAdminScreen;
