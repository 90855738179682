import { numberWithCommas } from './DashboardUtility';
import { AppConstant } from './AppConstant';
import moment from 'moment/moment';

const unloadingHoursDonut = {
    width: 400,
    options: {
        colors: ['#19BAFF', '#0073A5'],
        legend: {
            show: true,
            position: 'right',
            offsetY: 25,
            // horizontalAlign: 'center', 
        },
        labels: ["Truck", "Pallets"],
        stroke: {
            show: false,
            width: 0
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true, label: 'Total',
                            fontSize: '22px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            label: 'Total',
                            formatter: (w) => {
                                const total = w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                                return numberWithCommas(total)
                            }
                        },
                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        show: true
                    }
                }
            }
        ],
        tooltip: {
            y: {
                formatter: function (value) {
                    return numberWithCommas(value)
                }
            }
        },
        dataLabels: {
            formatter: function (val, opts) {
                return ''
            },
        },
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    return config.w.config.series[config.dataPointIndex]

                }
            }
        },
    },
}
const unloadingHoursLineTruck = {
    width: 350,
    options: {
        dataLabels: {
            enabled: false
        },
        labels: ["Truck"],
        stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0,
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: [0, 6, 12, 18, 24],
        },
        chart: {
            height: '550px',

            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        legend: {
            show: true,
            position: 'right',
            offsetY: 25,
            // horizontalAlign: 'center', 
        },
    },
}
const unloadingHoursLinePallet = {
    width: 350,
    options: {
        dataLabels: {
            enabled: false
        },
        labels: ["Pallets"],
        stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0,
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: [0, 6, 12, 18, 24],
        },
        chart: {
            height: '450px',

            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        legend: {
            show: true,
            position: 'right',
            offsetY: 25,
            // horizontalAlign: 'center', 
        },
    },
}
const detentionLineData = {
    width: 400,
    options: {
        dataLabels: {
            enabled: false
        },
        labels: ["Within 30 min of Detention", "Not in Detention", "Fully in Detention"],
        stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0,
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: [0, 6, 12, 18, 24],
        },
        colors: ['#BE432C', '#882F20', '#DD8070'],
        chart: {
            height: '550px',

            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        legend: {
            show: false
        },
    },


};
const detentionDonutdata = {
    width: 600,
    options: {
        colors: ['#BE432C', '#882F20', '#DD8070'],
        legend: {
            show: true,
            position: 'right',
            horizontalAlign: 'center',
        },
        labels: ["Not in Detention", "In Detention (+2hrs from Appt. Time)", "Nearing Detention (Within 30 minutes of detention)"],
        stroke: {
            show: false,
            width: 0
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            fontSize: '11px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            label: 'Total',
                            formatter: (w) => {
                                const total = w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                                return numberWithCommas(total)
                            }
                        },
                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        show: true
                    }
                }
            }
        ],
        tooltip: {
            y: {
                formatter: function (value) {
                    return numberWithCommas(value)
                }
            }
        },
        dataLabels: {
            enabled : true
        },
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    localStorage.setItem("dententionStatus", config.dataPointIndex)
                    return config.w.config.series[config.dataPointIndex]

                }
            }
        },
    },

};
const appointmentComplianceData = {
    width: 480,
    className: 'detention-legend',
    options: {
        colors: ['#004C6D', '#0073A5', '#009CDE', '#19BAFF', '#52CBFF', '#8CDCFF', '#ACE6FF', '#CCEFFF'],
        legend: {
            show: true,
            position: 'right',
            offsetY: 25,
            horizontalAlign: 'center', 
        },
        labels: ["On time", "Early" , " Late Greater than 1 hour", "Late Less than 1 hour"],
        // labels: ["Early", "On time", "No Show", "Late above 1 hour", "Late below 1 hour", "Cancelled",
        // "Unscheduled", "Reschedule"],
        stroke: {
            show: false,
            width: 0
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            fontSize: '11px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            label: 'Total',
                            formatter: (w) => {
                                const total = w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                                return numberWithCommas(total)
                            }
                        },
                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        show: true
                    }
                }
            }
        ],
        tooltip: {
            y: {
                formatter: function (value) {
                    return numberWithCommas(value)
                }
            }
        },
        dataLabels: {
            enabled : true
        },
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    localStorage.setItem("appointmentComplience", config.dataPointIndex)
                    return config.w.config.series[config.dataPointIndex]

                }
            }
        }
    },
};
const top10LateLoadsData = {
    width: 450,
    className: 'detention-legend',
    options: {
        colors: ['#004C6D', '#0073A5', '#009CDE', '#19BAFF', '#52CBFF', '#8CDCFF', '#ACE6FF', '#CCEFFF'],
        legend: {
            show: true,
            position: 'right',
            offsetY: 25,
            // horizontalAlign: 'center', 
        },
        labels: ["Early", "On time", "Late above 1 hour", "Late below 1 hour"],
        // labels: ["Early", "On time", "No Show", "Late above 1 hour", "Late below 1 hour", "Cancelled",
        // "Unscheduled", "Reschedule"],
        stroke: {
            show: false,
            width: 0
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            fontSize: '22px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            label: 'Total',
                            formatter: (w) => {
                                const total = w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                                return numberWithCommas(total)
                            }
                        },
                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        show: true
                    }
                }
            }
        ],
        tooltip: {
            y: {
                formatter: function (value) {
                    return numberWithCommas(value)
                }
            }
        },
        dataLabels: {
            formatter: function (val, opts) {
                return ''
            },
        },
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    return config.w.config.series[config.dataPointIndex]
                }
            }
        }
    },
}
const doorUsageData = {
    width: 450,
    options: {
        type: "line",
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
        },
        stroke: {
            width: 3, // Control the line width
          },
          markers: {
            size: 6,
          },
          plotOptions: {
            bar: {
              columnWidth: '20%', // Adjust the column width as needed
            },
          },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        show: true
                    }
                }
            }
        ],
        tooltip: {
            y: {
                formatter: function (value) {
                    return numberWithCommas(value)
                }
            }
        },
        xaxis: {
          type: ''
        },
        yaxis: [{
          title: {
            text: 'Trucks',
          },
        
        }, {
          opposite: true,
          title: {
            text: 'Pallets'
          }
        }],
        chart: {
            type: 'line',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    // localStorage.setItem("doorUsageSummary", config.dataPointIndex)
                    return config.w.config.series[config.dataPointIndex]

                }
            },
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            }
        },
    },

};
const doorStatusData = {
    width: 350,
    className: 'detention-legend',
    options: {
        colors: ['#DD8070', '#BE432C', '#BE432C', '#BE432C'],
        legend: {
            show: true,
            position: 'right',
            offsetY: 25,
        },
        labels: ["Available", "Blocked", "Inoperable", "In Use"],
        stroke: {
            show: false,
            width: 0
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true, label: 'Total',
                            fontSize: '22px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            label: 'Total',
                            formatter: (w) => {
                                const total = w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                                return numberWithCommas(total)
                            }
                        },
                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        show: true
                    }
                }
            }
        ],
        tooltip: {
            y: {
                formatter: function (value) {
                    return numberWithCommas(value)
                }
            }
        },
        dataLabels: {
            formatter: function (val, opts) {
                return ''
            },
        },
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    localStorage.setItem("doorStatus", config.dataPointIndex)
                    return config.w.config.series[config.dataPointIndex]

                }
            }
        }
    },
};
const doorStatusDataPie = {

  width: 350,
  className: 'detention-legend',
      options: {
        chart: {
          width: 380,
              type: 'pie',
              events: {
                dataPointSelection: (event, chartContext, config) => {
                    localStorage.setItem("doorStatus", config.dataPointIndex)
                    return config.w.config.series[config.dataPointIndex]

                },
            }
          },
          colors: ['#e36120','#98e85f', '#d40614', '#c73c5c', '#c73c8d', '#c73c8d', '#9e53b5', '#1013b5', '#dae657', '#e68757'],
          labels: AppConstant.dashboard.defaultdashboard.doorStatus.labels,
          dataLabels: {
            enabled: true,
            // formatter: function (val, opts) {
            //   var percent = ((opts.w.globals.series[opts.seriesIndex] / opts.w.globals.seriesTotals[opts.seriesIndex]) * 100).toFixed(2);
            //   return AppConstant.dashboard.defaultdashboard.doorStatus.labels[opts.seriesIndex] + ' - ' + percent + '%';
            // },
          },
          legend: {
            show: true, // Display legends
            position: 'top', // Position of the legends (you can use 'top', 'bottom', 'left', or 'right')
            horizontalAlign: 'center', // Alignment of the legends
          },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            }
          }
          }]
      },

};

const appointmentReportSummaryDataPie = {

    width: 500,
    className: 'detention-legend',
        options: {
          chart: {
            width: 500,
                type: 'pie',
                events: {
                  dataPointSelection: (event, chartContext, config) => {
                      localStorage.setItem("appointmentReportSummary", config.dataPointIndex)
                      return config.w.config.series[config.dataPointIndex]
  
                  },
              }
            },
            colors: [AppConstant.charts.appointmentSummaryReport.colors.warehouse ,AppConstant.charts.appointmentSummaryReport.colors.carrier],
            labels: AppConstant.appointmentReportSummary.labels,
            dataLabels: {
              enabled: true,
              // formatter: function (val, opts) {
              //   var percent = ((opts.w.globals.series[opts.seriesIndex] / opts.w.globals.seriesTotals[opts.seriesIndex]) * 100).toFixed(2);
              //   return AppConstant.dashboard.defaultdashboard.doorStatus.labels[opts.seriesIndex] + ' - ' + percent + '%';
              // },
            },
            legend: {
              show: true, // Display legends
              position: 'top', // Position of the legends (you can use 'top', 'bottom', 'left', or 'right')
              horizontalAlign: 'center', // Alignment of the legends
            },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              }
            }
            }]
        },
  
  };

const funnelGraphData = (dashboardLoadStatusObj, themeSelected) => {
    const funnelGraphObj = {
        titlesArr: [
            AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.appointmentTitle,
            AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.staggingDwellTitle,
            AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.dockingDwellTitle,
            AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.unloadingDwellTitle,
            AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.paperworkDwellTitle
        ],
        quantityArr: [
            parseInt(dashboardLoadStatusObj.appointment_count),
            parseInt(dashboardLoadStatusObj.checkin_count),
            parseInt(dashboardLoadStatusObj.door_assign_count),
            parseInt(dashboardLoadStatusObj.unloading_count),
            parseInt(dashboardLoadStatusObj.released_count),
        ],
        legendsArr: [
            `${AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.appointmentTitle} (${dashboardLoadStatusObj.appointment_count})`,
            `${AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.staggingDwellTitle} (${dashboardLoadStatusObj.checkin_count})`,
            `${AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.dockingDwellTitle} (${dashboardLoadStatusObj.door_assign_count})`,
            `${AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.unloadingDwellTitle} (${dashboardLoadStatusObj.unloading_count})`,
            `${AppConstant.dashboard.defaultdashboard.funnelGraph.titleStrings.paperworkDwellTitle} (${dashboardLoadStatusObj.released_count})`,
        ],
        timeArr: [
            "-",
            moment.duration(dashboardLoadStatusObj.checkin_time).asMinutes(),
            moment.duration(dashboardLoadStatusObj.door_assign_time).asMinutes(),
            moment.duration(dashboardLoadStatusObj.unloading_time).asMinutes(),
            moment.duration(dashboardLoadStatusObj.released_time).asMinutes(),
        ],
        colorsArr: themeSelected === 'light-mode' ?
            ['#006F9F', '#2B4A60', '#5B494D', '#934A3C', '#B04C35']
            :
            ['#006F9F', '#3F6C8C', '#835E66', '#AB4A38', '#B04C35']
    }

    return funnelGraphObj

}

const unloadingTypeSummaryDonutData = {
    width: 440,
    options: {
        colors: ['#19BAFF', '#0073A5'],
        legend: {
            show: true,
            position: 'right',
            offsetY: 25,
            // horizontalAlign: 'center', 
        },
        labels: ["Vendor Unload", "Lumper Unload"],
        stroke: {
            show: false,
            width: 0
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true, 
                            label: 'Freight Unloads',
                            fontSize: '22px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            formatter: (w) => {
                                const total = w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                                return numberWithCommas(total)
                            }
                        },
                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        show: true
                    }
                }
            }
        ],
        tooltip: {
            y: {
                formatter: function (value) {
                    return numberWithCommas(value)
                }
            }
        },
        dataLabels: {
            formatter: function (val, opts) {
                return ''
            },
        },
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    return config.w.config.series[config.dataPointIndex]

                }
            }
        },
    },
}

const unscheduledPOData = {
    width: 500,
    className: 'detention-legend',
    options: {
        colors: ['#DD8070', '#BE432C', '#BE432C'],
        legend: {
            show: true,
            position: 'right',
            offsetY: 25,
        },
        labels: ["Unscheduled Po's Pending", "Unscheduled Po's Approved", "Scheduled Po's"],
        stroke: {
            show: false,
            width: 0
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true, label: 'Total',
                            fontSize: '14px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            label: 'Total',
                            formatter: (w) => {
                                const total = w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                                return numberWithCommas(total)
                            }
                        },
                    }
                }
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        show: true
                    }
                }
            }
        ],
        tooltip: {
            y: {
                formatter: function (value) {
                    return numberWithCommas(value)
                }
            }
        },
        dataLabels: {
            formatter: function (val, opts) {
                return ''
            },
        },
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    return config.w.config.series[config.dataPointIndex]

                }
            }
        }
    },
};

const appointmentTrackerDonut = {
    width: 300,
    options: {
        colors: ['#67bad0', '#eaecee'],
        legend: {
            show: false,
            position: 'right',
            horizontalAlign: 'center',
        },
        labels: ['Scheduled', 'Completed'],
        stroke: {
            show: false,
            width: 0
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10,
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            fontSize: '11px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            label: 'Total',
                            formatter: (w) => {
                                const total = w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                                return numberWithCommas(total)
                            }
                        },
                    }
                }
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: false,
    
                },
                dataLabels: {
                    enabled: false
                },
            }
        }],
        
        dataLabels: {
            enabled : false
        },
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    return config.w.config.series[config.dataPointIndex]

                }
            }
        },
    },
}

const facilityRatingColumnGraph = {
    options: {
        chart: {
            type: 'bar',
            height: 500,
        },
        plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
            },
            
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + '%'; // Add '%' sign at the end of the value
            },
        },
        legend: {
            show: true, // Display legends
            position: 'left', // Adjust the position as needed (top, bottom, right, left)
          },
        tooltip: {
            y: {
              formatter: function (val) {
                return val + '%'; // Add '%' sign at the end of the value
              },
            },
        },
    },
      yaxis: {
        title: {
          text: '',
        },
    },
      
      fill: {
        opacity: 1,
      },
}

/**
 *  This function is used to create dynamic user rating column graph 
 */
const userRatingColumnGraph = {
    options: {
        chart: {
            type: 'bar',
            height: 500,
        },
        plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
            },
            
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + '%'; // Add '%' sign at the end of the value
            },
        },
        legend: {
            show: true, // Display legends
            position: 'left', // Adjust the position as needed (top, bottom, right, left)
          },
        tooltip: {
            y: {
              formatter: function (val) {
                return val + '%'; // Add '%' sign at the end of the value
              },
            },
        },
    },
      yaxis: {
        title: {
          text: '',
        },
    },
      
      fill: {
        opacity: 1,
      },
}

const doorAssignmentColumnStackedGraph = {
    options: {
        chart: {
            type: 'bar',
            height: 100,
            stacked: true,
        },
        labels: ['Door Assigned to Lump Start', 'Lump Start to Lump End', 'Lump End to Bills Out', 'In Detention', 'Not In Detention', 'Nearing Detention'],
        plotOptions: {
            
            bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },

            
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val; // Add '%' sign at the end of the value
            },
        },
        legend: {
            show: true, // Display legends
            position: 'bottom', // Adjust the position as needed (top, bottom, right, left)
          },
        tooltip: {
            y: {
              formatter: function (val) {
                return val; // Add '%' sign at the end of the value
              },
            },
        },
    },
      yaxis: {
        title: {
          text: '',
        },
    },
      
      fill: {
        opacity: 1,
      },
}

export { unloadingHoursDonut, unloadingHoursLineTruck, unloadingHoursLinePallet,
    detentionLineData, detentionDonutdata, appointmentComplianceData, top10LateLoadsData,
    funnelGraphData, unloadingTypeSummaryDonutData, doorUsageData,
    doorStatusData, unscheduledPOData, appointmentTrackerDonut,facilityRatingColumnGraph, doorStatusDataPie, doorAssignmentColumnStackedGraph, userRatingColumnGraph,appointmentReportSummaryDataPie }
