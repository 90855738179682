
import '../CarrierSignUpUpdated.css'
import { CustomDynamicFormSS } from '../../../Common/CustomForm/CustomDynamicFormSS'
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppConstant } from '../../../../Assests/AppConstant';
import { useNavigate } from 'react-router-dom';
import { sagaActions } from '../../../../../sagas/sagaActions';
import Auth from "@aws-amplify/auth";
import { Hub } from 'aws-amplify';
import { smartScheduleSelector } from '../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { showAlert, createNavBar, removeLastCharacterFromString, formatContactNumForAWS, getActiveCountryCode } from '../../../../Assests/Utility';
import { toast } from 'react-toastify';
import Popup from '../../../Common/Popup/Popup';
import { getAuthReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { interceptor, removeInterceptor } from '../../../../../redux/api/Interceptor';
import _, { isNil } from 'lodash-es';
import { customStylesLight } from '../FormCarrierSignUp/Components/Styles/Styles';
import '../FormCarrierSignUp/FormCarrierSignUp.css';
import { getCountryList } from '../../../../../redux/reducers/reducerSlices/GetCountryListReducer';
export const CarrierSignUpFormSSNew = (props) => {
    const dispatch = useDispatch();
    const { themeSelected } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showIFrame, setShowIFrame] = useState(false);
    const [showPaymentSuccessView, setShowPaymentSuccessView] = useState(false);
    const [resetFormData, setResetFormData] = useState(0);
    const [editData, setEditData] = useState({});
    const [passwordText, setPasswordText] = useState('');
    const [confirmPasswordText, setConfirmPasswordText] = useState('');
    const [userSignUpData, setUserSignUpData] = useState({});
    const [userSignUpDataState, setUserSignUpDataState] = useState({});
    const [userFirstName, setUserFirstName] = useState('');
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const userPermission = useSelector(getUserSelector);
    const { userType, permissions, userAccountDetails, carrierFromSignUpUserObj, addCarrierSpro } = userPermission;
    const user = useSelector(getAuthReducer);
    const [carrierListState, setCarrierListState] = useState([]);
    const [selectedCarrier, setSelectedCarrier] = useState(null)
    const ss = useSelector(smartScheduleSelector);
    const { carrierList,mcDetails, initateLoading } = ss;

    const [searchableSelectFormData, setSearchableSelectFormData] = useState(null);
    const [MCNumber,setMCNumber] = useState(null);
    const countryData = useSelector(getCountryList);


    /**
     * useEffect to call MC# API
     */
    useEffect(()=>{
        let tout;
        if (MCNumber) {
            tout=setTimeout(()=>{
                dispatch({ type:sagaActions.GET_MC_DETAILS, payload:{"mcNumber":MCNumber}})
            },1000)
        }
        return ()=>{
            clearTimeout(tout);
            dispatch({ type:sagaActions.RESET_MC_DETAILS, payload:null})
        }
    },[MCNumber])

    /**
     * useEffect to set MC# data in CreatableSelect
     */
    useEffect(()=>{
        if (mcDetails) {
            let searchedCarrier={
                "value": mcDetails?.carrier_id,
                "label": mcDetails?.carrier_name
            }
            setSearchableSelectFormData(searchedCarrier)
            setSelectedCarrier({
                value:mcDetails?.carrier_id,
                label:mcDetails?.carrier_name
        })
            setParticularFieldValue({...userSignUpDataState,carrier_name:mcDetails?.carrier_name});
        }else{
            setSearchableSelectFormData(null)
            setSelectedCarrier(null)
            setParticularFieldValue({...userSignUpDataState,carrier_name:""});
        }
    },[mcDetails])

    /**
     * Initial useEffect
     */
    useEffect(() => {
        // Clear all previous stored data
        window.localStorage.clear();
        deleteUser();
        setPasswordText('');
        setConfirmPasswordText('');
        setParticularFieldValue({
            'first_name': '',
            'last_name': '',
            'email': '',
            'contact_number': '',
            'password_text': '',
            'confirm_password_text': '',
            'mcr_code': '',
            'carrier_name': ''
        });
        setShowIFrame(false);
        setShowPaymentSuccessView(false);
        setConfirmPasswordText(false);
        setSearchableSelectFormData(null)
        setParticularFieldValue({})
        setSelectedCarrier(null)
        dispatch({ type: sagaActions.RESET_CARRIER_FROM_SIGNUP_TEMP_DATA });
        if (!_.isEmpty(addCarrierSpro)) {
            dispatch({type: sagaActions.ADD_CARRIER_SCHEDULE_PRO, payload: {type: 'C'}})
        }
        dispatch({ type:sagaActions.RESET_MC_DETAILS, payload:null})
    }, [])
    /**
     * useEffect to get carrier list
     */
    useEffect(() => {
        if (_.size(carrierList)) {
            formCarrierListData()
        }
    }, [carrierList])

    useEffect(() => {
        //do this after user is created
        if (carrierFromSignUpUserObj && Object.keys(carrierFromSignUpUserObj).length > 0) {
            if (carrierFromSignUpUserObj && carrierFromSignUpUserObj.response && carrierFromSignUpUserObj.response.status !== 200) {
                let msg = AppConstant.notifications.login.wentWrong;
                if (carrierFromSignUpUserObj.response?.data?.message) {
                    msg = carrierFromSignUpUserObj.response?.data?.message;
                }
                showAlert(toast.TYPE.ERROR, msg, 'light')
                setLoading(false)
                setSelectedCarrier(null)
                setShowIFrame(false);
                setShowPaymentSuccessView(false);
                deleteUser()
            } else {
                //show success sign up
                setLoading(false)
                setSelectedCarrier(null)
                setShowIFrame(true)
                setShowPaymentSuccessView(true);
                setUserSignUpDataState({})
                setSearchableSelectFormData(null)
                setSelectedCarrier(null)
                dispatch({
                    type: sagaActions.GET_USER_ACCOUNT_DETAILS, payload: {
                        cu_id: user?.auth?.username,
                        crud_type: 'S',
                        profile_picture: null
                    }
                })
                // API to update user Last Logon
                dispatch({
                    type: sagaActions.UPDATE_LAST_LOGON, payload: {
                        cu_id: user?.auth?.username,
                    }
                })

            }
        }
    }, [carrierFromSignUpUserObj])
    /**
     * Useffect to fetch userAccountDetails
     */
    useEffect(() => {
        if (!_.isEmpty(userAccountDetails)) {
            if (userAccountDetails.EnableStatus) {
                dispatch({
                    type: sagaActions.GET_USER_TYPE,
                    payload: {
                        cu_id: user?.auth?.username
                    }
                })
            } else {
            }
        } else {
        }
    }, [userAccountDetails])
     /**
     * Useffect to fetch user pwermissions
     */
    useEffect(() => {
        if (user.sessionToken) {
            if (userType === 'CARRIER') {
                dispatch({
                    type: sagaActions.GET_USER_PERMISSIONS,
                    payload: {
                        warehouse_id: null,
                        user_type: userType,
                        cu_id: user?.auth?.username,
                    }
                })
            } else {
            }
        }
    }, [userType])
    /**
     * useEffect once we have permissions
     */
    useEffect(() => {
        if (user.sessionToken && !_.isEmpty(permissions)) {
            // Fetch config when carrier signup auto login
            dispatch({type:sagaActions.GET_CONFIGRATION})
            if (permissions?.message === AppConstant.userPermission.userPermissionNoData) {
                removeInterceptor()
                dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
                dispatch({ type: sagaActions.RESET_USER })
                navigate("/")
                window.localStorage.clear();
                showAlert(toast.TYPE.ERROR, AppConstant.userPermission.toast.userPermissionFail);
                return
            }
            const dynamicNav = createNavBar(permissions, userType)
            dispatch({ type: sagaActions.UPDATE_NAV_BAR, payload: dynamicNav })
            dispatch({
                type: sagaActions.UPDATE_USER_ACCOUNT_DETAILS_RENEW, payload: {
                    ...userAccountDetails, "SubscriptionStatus": "ACTIVE"
                }
            })
            redirectUser();
        } else {
            // console.log('Access issue: Logout User')
        }
    }, [permissions])
    /**
     * function to redirect user
     */
    const redirectUser = () => {
        const page = _.find(permissions, function (x) { return x.PageAccess === true && x.NavigationType === 'Primary' })
        if (!_.isEmpty(page)) {
            navigate(page.PageUrl);
            dispatch({ type: sagaActions.RESET_CARRIER_FROM_SIGNUP_TEMP_DATA });
        } else {
            console.log('No permission found after carrier signed up');
        }
    }
    /**
     * useEffect once user is created in AWS
     */
    useEffect(() => {
        if (!isNil(user.auth) && user.sessionToken) {
            removeInterceptor();
            interceptor(user.sessionToken);
            createNewUserInDb(user)
        }
    }, [user])
    /**
     * useEffect for userSignUpData
     */
    useEffect(() => {
        if (!_.isEmpty(userSignUpData)) {
            afterSubmitHandler()
        }
    }, [userSignUpData])

    /**
     * UseEffect of add carrier SPro
     */

    useEffect(() => {
        if (!_.isEmpty(addCarrierSpro)) {
            setCarrierListState([...carrierListState, {value:addCarrierSpro?.carrier_id, label: addCarrierSpro?.carrier_name }])
            setSelectedCarrier({
                value: addCarrierSpro?.carrier_id,
                label: addCarrierSpro?.carrier_name
            })
            setSearchableSelectFormData({
                value: addCarrierSpro?.carrier_id,
                label: addCarrierSpro?.carrier_name
            })
            setParticularFieldValue({...userSignUpDataState, carrier_name: addCarrierSpro?.carrier_id})
            dispatch({type: sagaActions.ADD_CARRIER_SCHEDULE_PRO, payload: {type: 'C'}})
        }

    }, [addCarrierSpro])
    
    /**
     * function to create user in AWS
     */
    const afterSubmitHandler = () => {
        let contactNumTemp = userSignUpData.contact_number;
        if (userSignUpData.contact_number.length > 14) {
            contactNumTemp = removeLastCharacterFromString(userSignUpData.contact_number);
        }
        setParticularFieldValue({
            'first_name': userSignUpData.first_name || '',
            'last_name': userSignUpData.last_name || '',
            'email': userSignUpData.email || '',
            'contact_number': contactNumTemp || '',
            'password_text': '',
            'confirm_password_text': '',
            'mcr_code': userSignUpData.mcr_code || '',
            'carrier_name': selectedCarrier,
        });

        Hub.listen('auth', ({ payload }) => {
            const { event } = payload;
            if (event === 'autoSignIn') {
                const dataTemp = {
                    response: { ...payload.data, 'carrier_name': selectedCarrier },
                    email: particularFieldValue.email
                }
                dispatch({ type: sagaActions.SAVE_DATA_FROM_SIGNUP_LISTNER_TO_REDUX, payload: dataTemp });
            } else if (event === 'autoSignIn_failure') {
                showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.carrierSignUp.savingUserDataError, 'light');
            }
        })

        registerUserOnAWS()
            .then((resp) => {
            console.log("🚀 ~ .then ~ resp:", resp)
        })
        .catch((err) => {
            setShowIFrame(false)
            const errorStr = `${AppConstant.smartScheduler.carrierSignUp.registerUserError} ${err}`
            showAlert(toast.TYPE.ERROR, errorStr, 'light')
            setLoading(false)
        })
    }

    const createNewUserInDb = (usernew) => {
        const selCarrier = _.find(carrierList, { carrier_id: particularFieldValue.carrier_name });
        let tempData = { ...particularFieldValue };
        tempData.Email = usernew.auth.attributes.email;
        tempData.CUID = usernew.auth.username;
        tempData.contact_number = usernew.auth.attributes.phone_number;
        tempData.phone = usernew.auth.attributes.phone_number;
        tempData.MCR = particularFieldValue.mcr_code;
        tempData.CarrierName = selCarrier && selCarrier.carrier_name ? selCarrier.carrier_name: searchableSelectFormData?.label;
        tempData.FirstName = usernew.auth.attributes.given_name;
        tempData.UserName = usernew.auth.attributes.given_name;
        tempData.LastName = usernew.auth.attributes.family_name;
        tempData.UserType = 'CARRIER';
        tempData.EnableStatus = 1;
        tempData.CreatedOn = null;
        tempData.CreatedBy = null;
        tempData.CRUDTYPE = 'I';
        tempData.carrier_name= particularFieldValue.carrier_name instanceof Object ? particularFieldValue.carrier_name.label:particularFieldValue.carrier_name
        tempData.IDCarrier = particularFieldValue.carrier_name instanceof Object ? particularFieldValue.carrier_name.value:particularFieldValue.carrier_name;
         dispatch({ type: sagaActions.CREATE_CARRIER_FROM_SIGNUP, payload: tempData });
    }

    const deleteUser = () => {
        try {

            // await Auth.deleteUser();
            removeInterceptor()
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
            dispatch({ type: sagaActions.RESET_USER_WITHOUT_BRANDING, payload: true })
            setShowIFrame(false);
            dispatch({ type: sagaActions.RESET_PAYTRACE_DATA });
            setParticularFieldValue({
                'first_name': '',
                'last_name': '',
                'email': '',
                'contact_number': '',
                'password_text': '',
                'confirm_password_text': '',
                'mcr_code': '',
                'carrier_name': ''
            });
            window.localStorage.clear();
        }
        catch (error) {
            console.log(AppConstant.smartScheduler.carrierSignUp.deleteUserError, error);
        }
    }

    const onCarrierSelcted = (props) => {
        if (props.length > 2) {
            dispatch({ type: sagaActions.SS_GET_CARRIER_LIST, payload: { carrier: props } })
        }
    }

    const onChangeValue = (props) => {
        setSelectedCarrier(props)
    }

    const formatCreateLabel = (props) => {
    }

    const onCreateSelected = (props) => {
        const contactNum = userSignUpDataState?.contact_number.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '').replaceAll('+','');
        const payload = {
            "name": props,
            "contact_phone": "+"+contactNum,
            "first_name": userSignUpDataState?.first_name,
            "last_name": userSignUpDataState?.last_name,
            type: 'A'
        }
        dispatch({type: sagaActions.ADD_CARRIER_SCHEDULE_PRO, payload})  
    }


    const formCarrierListData = () => {
        if (_.size(carrierList)) {
            const carrierData = carrierList.map(el => {
                return {
                    value: el.carrier_id,
                    label: el.carrier_name
                }
            })
            setCarrierListState([...carrierData])
        } else {
            setCarrierListState([])
        }
    }

    const formFieldList = [
        {
            name: "first_name",
            type: "text",
            size: "col-sm-12 col-md-6 col-lg-6 ",
            position: 1,
            title: AppConstant.user.popup.createUser.fields.given_name_title,
            titleIcon: true,
            titleIconClass: 'fa-regular fa-user info-label-icon',
            formValidation: {
                required: true
            },
        },
        {
            name: "last_name",
            type: "text",
            size: "col-sm-12 col-md-6 col-lg-6 ",
            position: 2,
            title: AppConstant.user.popup.createUser.fields.family_name_title,
            titleIcon: true,
            titleIconClass: 'fa-regular fa-user info-label-icon',
            formValidation: {
                required: true,
            },
        },
        {
            name: "email",
            type: "text",
            size: "col-sm-12 col-md-6 col-lg-6 ",
            position: 3,
            title: AppConstant.user.popup.createUser.fields.user_name,
            titleIcon: true,
            titleIconClass: 'fa-regular fa-envelope  info-label-icon',
            formValidation: {
                required: true,
                validateEmail: true,
            },
        },
        {
            name: "contact_number",
            type: "phone_with_country_code",
            size: "col-sm-12 col-md-6 col-lg-6 ",
            position: 4,
            placeholder: AppConstant.user.popup.createUser.phone_placeHolder,
            title: AppConstant.settings.userAccountTitle.phone,
            titleIcon: true,
            titleIconClass: 'fa-solid fa-phone  info-label-icon',
            formValidation: {
                required: true,
                // validateContactNum: true,
                // customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.contactNumErrorMessage
            },
            activeCountriesListCodes: getActiveCountryCode(countryData?.data)

        },
        {
            name: "password_text",
            type: "password",
            size: "col-sm-12 col-md-6 col-lg-6 password__feild",
            position: 5,
            value: passwordText,
            title: AppConstant.login.password,
            titleIcon: true,
            titleIconClass: 'fa-solid fa-star-of-life fa-xs info-label-icon',
            formValidation: {
                checkPassword: true,
                required: true,
            },
        },
        {
            name: "confirm_password_text",
            type: "password",
            size: "col-sm-12 col-md-6 col-lg-6 password__feild",
            position: 6,
            value: confirmPasswordText,
            title: AppConstant.login.confirm_password,
            titleIcon: true,
            titleIconClass: 'fa-solid fa-star-of-life fa-xs info-label-icon',
            formValidation: {
                required: true,
                passwordText: passwordText,
                confirmPassword: true
            },
        },
        {
            name: "mcr_code",
            type: "text",
            size: "col-sm-12 col-md-6 col-lg-6 ",
            position: 7,
            title: AppConstant.smartScheduler.carrierSignUp.carrierFormTitles.mcrCodeTitle,
            titleIcon: true,
            titleIconClass: 'fa-regular fa-id-card  info-label-icon',
            formValidation: {
            },
        },
        {
            name: "carrier_name",
            type: "select_with_input_search_add_button_temp",
            size: "col-sm-12 col-md-6 col-lg-6",
            parentClass: 'inputTitle',
            componentClassName: 'custom-select',
            inputTitle: 'Select Carrier',
            position: 8,
            title: AppConstant.reports.scheduledLoadStatus.tableHeaders.carrierName,
            titleIcon: true,
            titleIconClass: 'fa-solid fa-bag-shopping info-label-icon',
            customStylesLight: customStylesLight,
            placeholderText: 'Type here...',
            onInputChange: onCarrierSelcted,
            onChange: onChangeValue,
            defaultValue: !_.isEmpty(selectedCarrier) ? selectedCarrier : 'Select a carrier from the list',
            isLoading: initateLoading,
            isClearable: false,
            isSearchable: true,
            options: carrierListState,
            formatCreateLabel: formatCreateLabel,
            loadingMessage: 'Please wait loading....',
            formValidation: {
                required: true,
            },
            handleCreateOption: onCreateSelected,
            searchableSelectFormData: searchableSelectFormData,
            disabled:initateLoading
        },
    ]

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: 'Submit',
    };

    const getFormFieldData = (data, name, control, inputs,searchableSelectFormData) => {
        if (name === 'password_text') {
            setPasswordText(data);
        } else if (name === 'confirm_password_text') {
            setConfirmPasswordText(data);
        } else if (name === "carrier_name") {
            setSelectedCarrier(data)
            if (searchableSelectFormData) {
                setSearchableSelectFormData(searchableSelectFormData)
            } 
        }else if (name === "mcr_code") {
          setMCNumber(data)
        }
        setUserSignUpDataState({...userSignUpDataState, [name]:data})
    };

    const getSummitedFormData = async (data) => {
        setLoading(true)
        setUserSignUpData({ ...data, 'carrier_name': selectedCarrier })
        setUserFirstName(data.first_name)

    };
    /**
     * 
     * @returns AWS response once user is created in AWS
     */
    const registerUserOnAWS = async () => {
        let contactNumTemp = userSignUpData.contact_number;
        if (userSignUpData.contact_number.length > 14) {
            contactNumTemp = removeLastCharacterFromString(userSignUpData.contact_number);
        }
        const contactNum = contactNumTemp.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '').replaceAll('+','');
        const userSignUp = await Auth.signUp({
            username: userSignUpData.email,
            password: userSignUpData.password_text,
            attributes: {
                given_name: userSignUpData.first_name,
                family_name: userSignUpData.last_name,
                phone_number: "+"+contactNum,
                email: userSignUpData.email,
                'custom:isCarrierSignup': "true"
            },
            autoSignIn: {
                enabled: true,
            }
        }).then((resp) => {
            return resp
        })
            .catch((err) => {
                setShowIFrame(false)
                setLoading(false)
                setSelectedCarrier(null)
                showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.carrierSignUp.carrierFormTitles.signUpError + ' ' + err.toString().split(':')[1].toString(), 'light');
                deleteUser()
                return err
            })

        return userSignUp;
    }
    /**
     * 
     * @returns Static text
     */
    const showCancelSignUpText = () => {
        const arr = ['Easy, Accurate & detailed Scheduling Appointment Calendar',
            'Integrated Warehouse & Carrier Calendar Schedules',
            'Bulk Purchase Order Upload Template or Manual PO Scheduling',
            'Appointment Management & Views of upcoming & past appointments',
            'Real-time email confirmation of Appointments for scheduled, rescheduled, or Deleted'
        ]

        return (
            arr.map((ele, i) => {
                return (
                    <li>{ele}</li>
                )
            })
        )
    }
    /**
     * 
     * @returns static UI
     */
    const createForm = () => {
        return (
            <div className='fs-6 p-2'>
                <div className='fs-5 fw-bolder my-2'>
                    This is the last step for the sign-up process.
                    If you cancel now you will have to sign-up again.<br />
                </div>
                <div className='fs-5 fw-bolder my-2'>
                    You will miss out on the following benefits:<br />
                </div>
                <div className='paymentInfoText '>
                    <ul>
                        {showCancelSignUpText()}
                    </ul>
                </div>
                <div className='fs-5 my-2'>
                    {AppConstant.smartScheduler.carrierSignUp.signOutConfirmMessage}
                </div>
            </div>
        );
    };
    /**
     * 
     * @returns cancel popup object
     */
    const showCancelConfirmationPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: false,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            title: AppConstant.smartScheduler.carrierSignUp.signOutConfirmMessage,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.no
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => popUpLogoutHandler(text)} closepopup={() => closeCurrentpopup()} themeSelected={themeSelected} showModal={showCancelModal} />
    }
    /**
     * Logout popup confiration handler
     * @param {*} title 
     */
    const popUpLogoutHandler = (title) => {
        if (title === AppConstant.commonStrings.no) {
            setShowCancelModal(false);
        } else {
            navigate('/')
        }
    }
    /**
     * function to close popup
     */
    const closeCurrentpopup = () => {
        setEditData({});
        setSelectedCarrier(null);
        showCancelModal(true);
    }
    /**
     * 
     * @returns Loader
     */
    const showFinalSignupLoader = () => {
        return (
            <div className="spinner-border" role="status"><span className="sr-only">{AppConstant.commonStrings.loading}...</span></div>
        )
    }

    return (
        <div className="signup-form-section">

            {
                !showIFrame ?
                    <div >
                        <div className="mx-0 px-3">
                            <h5>New Account</h5>
                            <p className='mb-2' id="login-sub-header">Create your account now</p>
                        </div>
                        <CustomDynamicFormSS
                            formFieldList={formFieldList}
                            sort={true}
                            cancelBtn={cancelbtndata}
                            submitbtn={submitbtnData}
                            themeSelected={themeSelected}
                            getFormFieldData={getFormFieldData}
                            getSummitedFormData={getSummitedFormData}
                            editFormData={editData}
                            loading={loading}
                            resetFormData={resetFormData}
                            setParticularFieldValue={particularFieldValue}
                        />
                    </div>
                    :
                    <div className='d-flex flex-column p-1'>
                        {
                            !showPaymentSuccessView ?
                               ''
                                :
                                <div className=' pb-3'>
                                    <div className='payment-heading-text'>{`${AppConstant.commonStrings.welcome} ${userFirstName},`}</div>
                                    <div className='my-4 fs-8'>{AppConstant.smartScheduler.carrierSignUp.signUpMessage1} <b className='fs-5'>{AppConstant.commonStrings.freightSmith}</b></div>
                                    <div className='mt-4  fs-5'>{AppConstant.smartScheduler.carrierSignUp.signUpMessage2}</div>
                                    {showFinalSignupLoader()}
                                </div>
                        }
                        {showCancelConfirmationPopup()}
                    </div>
            }
            {
                <div className="fs-10 d-flex justify-content-between p-0">
                    {
                        !showIFrame ?
                            <div className="d-flex flex-row justify-content-end">
                                <p id="form-btns">
                                    Already have account?
                                    <a
                                        href="/"
                                        style={{ color: "#10C6F5" }}
                                        className="mx-1 fw-semibold"
                                    >
                                        LOGIN
                                    </a>
                                </p>
                            </div>

                            :
                            ''
                    }

                </div>
            }
        </div>
    )
}
