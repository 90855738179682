import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstant } from '../../../../../Assests/AppConstant';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { getAuthReducer } from '../../../../../../redux/reducers/reducerSlices/AuthReducer';
import CustomButton from '../../../../Common/CustomButton/CustomButton';
import {usePrevious} from '../../../../Common/CustomHooks/usePrevious';
import { getCommentsListSelector, addCommentFailureSelector } from '../../../../../../redux/reducers/reducerSlices/CommentsReducerSlice';
import './InputComments.css';
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import { shouldHavePermission } from '../../../../../Assests/Utility';
import _ from 'lodash';

const InputSectionCommentsIMSSP = (props) => {
    const dispatch = useDispatch();
    const { load_control_num, themeSelected } = props;
    const user = useSelector(getAuthReducer);
    const isCommentUploaded = useSelector(addCommentFailureSelector);
    const commentList = useSelector(getCommentsListSelector);
    const [commentText, setCommentText] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [didReach500Limit, setDidReach500Limit] = useState(false);
    const previousState = usePrevious(isCommentUploaded);
    const userPermission = useSelector(getUserSelector);
    const { permissions } = userPermission;
    const [allowPermission, setAllowPermission] = useState(false);

    useEffect(() => {
        if(permissions.length){
            const warehouseManagementPermission = _.find(permissions, { 'FeatureID': '8', 'PageAccess': true });
            if(warehouseManagementPermission && !_.isEmpty(warehouseManagementPermission)){
                setAllowPermission(true)
            }else{
                let temp = shouldHavePermission(permissions, '706');
            setAllowPermission(temp)
            }            
        }

    },[])

    useEffect(() => {
        setIsLoading(false);
    },[isCommentUploaded]);

    useEffect(() => {
        commentText.length > 499 ? setDidReach500Limit(true) : setDidReach500Limit(false);
    }, [commentText]);

    useEffect(() => {
        setIsLoading(false);
    }, [commentList])

    const onChangeText = (textArea) => {
        setCommentText(textArea.target.value);
    }

    const sendComment = () => {
        if(commentText){
            const addCommentObj = {
                "load_control_num" : load_control_num,
                "notes": commentText,
                "created_by": user.auth.challengeParam ? user.auth.challengeParam.userAttributes.email : user.auth.attributes.email
            }
            setIsLoading(true);
            dispatch({ type: sagaActions.ADD_COMMENT, payload : addCommentObj});
            cancelComment();
        }
    }

    const cancelComment = () => {
        setCommentText('');
    }

    const showAlert = () => {
        // if(isCommentUploaded === previousState){
        //     const theme = themeSelected === 'dark-mode' ? 'dark' : 'light';
        //     return <AlertNotification position='top-right' autoClose='5000' type='error' theme={theme} title= {AppConstant.comments.addCommentsFailure}/>;
        // } else {
        //     return ''
        // }
    }


    return (
        <div>
            <textarea maxLength={500} className="form-control inputComments "
                autoCapitalize='none'
                type="text" placeholder={AppConstant.comments.placeholderComments}
                rows="2" value={commentText} onChange={(textArea) => onChangeText(textArea)} disabled={allowPermission ? '': 'disabled'}/>
            <span className='d-flex justify-content-between'>
                <div>
                    {didReach500Limit ? <p className='characterLengthErrorMessage' >{AppConstant.comments.characterLimitMessage}</p> : ''}
                </div>
                <span className='container-fluid buttonsSection px-0 mx-0'>
                    <button className={`cancelButton me-2 ${themeSelected} ${allowPermission ? '': 'disabled'}`} onClick={() => cancelComment()} disabled={allowPermission ? '': 'disabled'}>
                        {AppConstant.comments.cancelCommentButtonTitle}
                    </button>
                    <CustomButton
                            disabled={allowPermission ? '' : 'disabled'}
                            isLoading={isLoading}
                            title={AppConstant.comments.addCommentButtonTitle}
                            className={`addButton ${allowPermission ? '': 'disabled'}`}
                            onClick={() => sendComment()} 
                            />
                </span>
            </span>
            {isCommentUploaded ? '' : showAlert()}
        </div>
    )
}

export default InputSectionCommentsIMSSP;

