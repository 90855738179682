import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { AppConstant } from '../../../../Assests/AppConstant'
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash-es';
import { ReactComponent as NoDataLightMode } from '../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../Assests/Svg/NoDataDarkMode.svg';
import MyAppointmentsSS from '../../../SmartSchedule/SchedulePro/Components/MyAppointment/MyAppointmentsSS';
import './RenewSubsCriptionParentSS.css'
import MySubscriptionsSS from '../../../SmartSchedule/SchedulePro/Components/MySubscriptionsSS/MySubscriptionsSS';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { toast } from 'react-toastify';
import { showAlert } from '../../../../Assests/Utility';
import SSPopup from '../../../Common/Popup/SSPopup/SSPopup';
import { removeInterceptor } from '../../../../../redux/api/Interceptor';
/**
 * This component renders new UI Renew Carrier Subscription with basic permissions
 * such as RenewSubscription/MyAppointments/PaymentsList
 * @param {*} props 
 * @returns 
 */
const RenewCarrierSubscriptionsSS = (props) => {

    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const [formName, setFormName] = useState(AppConstant.login.carrierSignUp);
    const [showFormModal, setShowFormModal] = useState(true);
    const [bgImage, setBgImage] = useState('');
    const userPermission = useSelector(getUserSelector);
    const { branding, userAccountDetails } = userPermission;
    const [warehouseLogo, setWarehouseLogo] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchStr, setSearchStr] = useState('');
    const navigate = useNavigate();
    const [selectedButton, setSelectedButton] = useState('MySubscriptions');
    /**
     * useEffect to display current tab
     */
    useEffect(() => {
        showCurrentTab()
    }, [selectedButton])

    const logoutPopupHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            removeInterceptor()
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
            dispatch({ type: sagaActions.RESET_USER })
            showAlert(toast.TYPE.INFO, AppConstant.login.logout, 'light')
            window.localStorage.clear();
            navigate("/")
            // If logging out from UserWarehouse Page then trigger branding API again
            if (location.pathname === '/') {
                dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: true })
                const subdomain = window.location.hostname.split(".")[0];
                dispatch({
                    type: sagaActions.INITIATE_BRANDING, payload: {
                        subdomain: subdomain
                    }
                });
            }

        }
    }
    const [showModal, setShowModal] = useState(false);
    /**
     * function to close popup
     */
    const closepopup = () => {
        setShowModal(false);
    }
    /**
     * function to render logout popup
     */
    const showLogoutPopup = () => {
        return <SSPopup {...AppConstant.settings.logoutPopupAttr} popupBtnHandler={(text) => {
            setShowModal(false);
            logoutPopupHandler(text)
        }} themeSelected={themeSelected} showModal={showModal} closepopup={closepopup} />
    }

    /**
     * 
     * @returns return component based on selected option
     */
    const showCurrentTab = () => {

        switch (selectedButton) {
            case "MyAppointments":
                return <MyAppointmentsSS showHeader={false} showNavBar={false} showAppointments={false} actionsButtonsArr={['Email']} />
            case "MySubscriptions":
                return <MySubscriptionsSS {...props} searchStr={searchStr} showNavBar={false} themeSelected={themeSelected} />
            default:
                return showNoDataError();
        }
    }
    /**
     * 
     * @returns JSX for tab buttons
     */
    const showTilesList = () => {
        return (
            <div className='d-flex justify-content-between w-100 align-items-center mt-2'>
                <div className='d-flex justify-content-between align-items-center'>
                    {<div className={`buttonReport mx-1 ${selectedButton === 'MySubscriptions' ? 'btn-highlighted' : 'navbar-btns-inactive'}`} onClick={() => setSelectedButton('MySubscriptions')}>{'MySubscriptions'}</div>}
                    {<div className={`buttonReport mx-1 ${selectedButton === 'MyAppointments' ? 'btn-highlighted' : 'navbar-btns-inactive'}`} onClick={() => setSelectedButton('MyAppointments')}>{'My Appointments'}</div>}
                </div>
                <button className='navbar-btns-inactive buttonReport mx-1' onClick={() => { setShowModal(true) }}>{AppConstant.settings.dropdownMenu.logout}</button>
            </div>
        )
    }
    /**
     * 
     * @returns JSX for No data
     */
    const showNoDataError = () => {
        return (
            <div className=' d-flex justify-content-center'>
                <div className='flex-column my-5'>
                    <div className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</div>
                    <div className={`no-data-text1 ${themeSelected} graphHeading text-center`}>{AppConstant.reports.selectReportTitleString}</div>
                </div>
            </div>
        )
    }
    /** 
     * useEffect for branding data
     */
    useEffect(() => {
        if (!_.isEmpty(branding)) {
            const { IsBranding, WarehouseLogo, BackgroundImage } = branding
            if (IsBranding) {
                setBgImage(BackgroundImage)
                setWarehouseLogo(WarehouseLogo)
            }
        }
    }, [branding])

    // const closeHelpModal = () => {
    //     setShowFormModal(false);
    //     navigate('/');
    // };

    // const getCarrierSignUpForm = () => {
    // }

    // const renewSubscription = () => {
    //     navigate('/renewcarrsubsform')
    // }

    return (
        <div className='renew-subs-ss-page'>
            {/* <Header {...props} /> */}
            <div className="m-4 d-flex flex-column m-0 p-0">
                <div className="d-flex justify-content-between m-0 p-0">
                    <div className='d-flex container-fluid flex-column m-0 p-0'>
                        <p className='f-18 fw-bold'>Hi {userAccountDetails?.FirstName?.toString().charAt(0).toUpperCase() + userAccountDetails?.FirstName?.slice(1)},</p>
                        <p className='f-14 '>Your subcription was expired. Click <strong className='fw-bold'>Renew</strong> to continue.</p>
                        {showTilesList()}
                        <div className='seperator' />

                        {showCurrentTab()}
                        {showLogoutPopup()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenewCarrierSubscriptionsSS;
