import React, { useState } from 'react';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import SearchAppointmentsInput from './Components/SearchAppointmentInput/SearchAppointmentsInput';
import ShowApptCard from './Components/ShowAppointmentCard/ShowApptCard';

const SearchAppointments = (props) => {

    const { themeSelected } = props;
    const [showAppointmentsList, setShowApppointmentsList] = useState(false);

    const displayAppointmentsList = () => {
        setShowApppointmentsList(!showAppointmentsList);
    }

    return (
        <div className='container-fluid m-1'>
            <Header {...props} />
            <Navigationbar {...props} />
            <div className='d-flex justify-content-center'>
                <SearchAppointmentsInput {...props} searchAppointment={() => displayAppointmentsList()} />
            </div>
            <div className='d-flex justify-content-center'>
                {showAppointmentsList ? <ShowApptCard /> : <div>No data found.</div>}
            </div>
        </div>
    )
}

export default SearchAppointments;