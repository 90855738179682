import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import CommonToggleSwitch from '../../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import { initiateSearch } from '../../../../../../../Assests/Utility'
import './CompanyGroupList.css';

const CompanyGroupList = (props) => {

    const { themeSelected, editGroupAction, searchStr, allowPermissionE, allowPermissionD } = props;
    const dispatch = useDispatch();
    const [companyGroupArr, setCompanyGroupArr] = useState([])
    const user = useSelector(getUserSelector);
    const { companyGroupList, initateLoading, arrangeKey, arrangeOrder } = user;

    const companyGroupTitleDD = [
        { id: 'serial_num', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.logo },
        { id: 'GroupName', name: AppConstant.superUserAdmin.companyGroup.tableHeader.name },
        { id: 'CreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'ContactNumber', name: AppConstant.superUserAdmin.companyGroup.tableHeader.contactNum },
        { id: 'Email', name: AppConstant.superUserAdmin.companyGroup.tableHeader.email },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.status },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.actions },
    ]

    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_LOADING, payload: true });
        getCompanyGroupList();
    }, [])

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(companyGroupList, searchStr, 'GroupName');
            setCompanyGroupArr(searchedArr);
        } else if (searchStr === '') {
            setCompanyGroupArr(companyGroupList)
        }
    }, [searchStr])

    useEffect(() => {
        setCompanyGroupArr([]);
        if (!companyGroupList.length) {
            dispatch({ type: sagaActions.INITIATE_LOADING, payload: true });
            getCompanyGroupList();
        } else if (companyGroupList && companyGroupList.length) {
            if (searchStr) {
                const searchedArr = initiateSearch(companyGroupList, searchStr, 'GroupName');
                setCompanyGroupArr(searchedArr);
            } else {
                setCompanyGroupArr(companyGroupList);
                // companyGroupListSingleRow
            }
        }
        initateLoading && dispatch({ type: sagaActions.INITIATE_LOADING, payload: false })

    }, [companyGroupList])

    const getCompanyGroupList = () => {
        dispatch({ type: sagaActions.UPDATE_COMPANY_GROUP, payload: createCompanyGroupPayload('S') });
    }

    const actionsButton = (groupObj) => {
        return (
            <div>
                <span className='' onClick={() => editGroupAction(groupObj)}>
                    <button
                        className={allowPermissionE ? 'addButton' : 'addButton disabled'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        >
                        {AppConstant.commonStrings.edit}
                    </button>
                </span>
            </div>
        )
    }

    const createCompanyGroupPayload = (crudType) => {
        return {
            company_group_id: null,
            group_name: null,
            group_logo: null,
            contact_number: null,
            address: null,
            state: null,
            zip: null,
            country: null,
            locale: null,
            email: null,
            enable_status: 0,
            crud_type: crudType
        }
    }

    const updateCompanyGroupApi = (roleObj) => {
        const params = {
            company_group_id: roleObj.CompanyGroupId,
            group_name: roleObj.GroupName,
            group_logo: roleObj.GroupLogo,
            contact_number: roleObj.ContactNumber,
            address: roleObj.Address,
            state: roleObj.State,
            zip: roleObj.Zip,
            country: roleObj.Country,
            locale: roleObj.Locale,
            email: roleObj.Email,
            enable_status: roleObj.EnableStatus,
            crud_type: 'U'
        }
        
        dispatch({ type: sagaActions.UPDATE_COMPANY_GROUP, payload: params })
    }

    const toggleStatus = (index) => {
        const tempArr = [...companyGroupArr];
        const tempObj = { ...tempArr[index] };
        tempObj.EnableStatus = !tempArr[index].EnableStatus;
        tempArr[index] = tempObj;
        setCompanyGroupArr([...tempArr]);
        updateCompanyGroupApi(tempObj);
    }

    const getToggleSwitch = (value, index) => {
        return (
            <div className='d-flex justify-content-center align-items-center py-1'>
                <CommonToggleSwitch value={value}
                    onChangeValue={(e) => toggleStatus(index)} allowPermissionD={allowPermissionD} />
            </div>
        )
    }

    const getCompanyGroupLogo = (el, index) => {
        return (
            <div className='d-flex justify-content-center'>
                <img className='companyGroupLogoContainer' src={el.GroupLogo ? el.GroupLogo : AppConstant.superUserAdmin.companyGroup.tableHeader.placeholderIconLink} alt={'Logo'} />
            </div>
        )
    }

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.RoleID,
                0: el.serial_num,
                1: getCompanyGroupLogo(el, index),
                2: el.GroupName,
                3: el.CreatedOn,
                4: el.ContactNumber,
                5: el.Email,
                6: getToggleSwitch(el.EnableStatus, index),
                7: actionsButton(el),
            }
        })
    }

    return (
        companyGroupArr &&
        <DrilldownTable
            themeSelected={themeSelected}
            bodyData={companyGroupArr.length && formatListDataDrillDown(companyGroupArr)}
            titleData={companyGroupTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={companyGroupList}
            initiateDrillDownReport={initateLoading}
            loaderArray={8}
            sortingAction={sagaActions.SORT_USER_TABLE}
            subRoot={{ pathName: 'companyGroup' }}
            showUTCTime={false}
        />
    )
}

export default CompanyGroupList;