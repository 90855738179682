
export const calcMinuteHeight = (cellHeight, step) => {
    return Math.ceil(cellHeight) / step;
  };
  
  export const calcCellHeight = (tableHeight, hoursLength) => {
    return Math.max(tableHeight / hoursLength, 60);
  };

   export   function convertToJSDate(inputDate, dateFormat="yyyy-mm-dd HH:MM:ss") {
  const formatTokens = {
    'yyyy': '(\\d{4})',
    'dd': '(\\d{2})', 
    'mm': '(\\d{2})',   
    'HH': '(\\d{2})',   
    'MM': '(\\d{2})',  
    'ss': '(\\d{2})'    
  };

  const regexPattern = new RegExp(
    dateFormat.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
               .replace(/(dd|mm|yyyy|HH|MM|ss)/g, match => formatTokens[match]),
  );

  const match = regexPattern.exec(inputDate);

  if (!match) {
    throw new Error('Invalid date format or input date string.');
  }

  const day = parseInt(match[3], 10);
  const month = parseInt(match[2], 10) - 1; 
  const year = parseInt(match[1], 10);
  const hour = parseInt(match[4], 10);
  const minute = parseInt(match[5], 10);
  const second = parseInt(match[6], 10);

  const jsDate = new Date(year, month, day, hour, minute, second);

  return jsDate;
}
export const isBetween=(data,start,end)=>{
  if (data>=start && data<end) {
      return true
  }
  else{
  return false;
}
}