import React from 'react';
import {AppConstant} from '../../../../../../../Assests/AppConstant';
import './HeaderLoadStatusOverview.css'
const HeaderLoadStatusOverview = () => {

    return (
        <div className='text-start ms-2 mt-1 headerLoadStatusOverview '>
            {AppConstant.loadManagement.statusOverview.statusOverviewTitle}
        </div>
    )
}

export default HeaderLoadStatusOverview