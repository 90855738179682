import { call, put, takeEvery } from 'redux-saga/effects';
import {Uchain} from '../redux/api/agent'
import { sagaActions } from './sagaActions';
import { updateFilterListReducer, setFilterViewToggle, setPOListFilterReducer, resetAllFilters, setDockFiltersListReducer, setStatusFiltersListReducer, setSelectedDockFilterReducer, setSelectedStatusFilterReducer, updateIsSearching} from '../redux/reducers/reducerSlices/FiltersReducerSlice';

  function* getSelectedFiltersListSaga(action) {
    try {
      const response = yield call(Uchain.SampleData);
      yield put(updateFilterListReducer(response));
    } catch (error) {
      
    }
  }

  function* addFiltersSaga(addFilter) {
    try {
      yield put(updateFilterListReducer(addFilter.payload));
    } catch (error) {
      
    }
  }

  function* deleteFiltersSaga(deleteFilter) {
    try {
      // const response = yield call(Uchain.SampleData);
      yield put(updateFilterListReducer(deleteFilter.payload));
    } catch (error) {
      
    }
  }

  function* setPOListFilterSaga(poListFilter) {
    try {
      // const response = yield call(Uchain.SampleData);
      yield put(setPOListFilterReducer(poListFilter.payload));
    } catch (error) {
      
    }
  }

  function* isFilterViewExpandedSaga(isExpanded) {
    try {
      // const response = yield call(Uchain.SampleData);
      yield put(setFilterViewToggle(isExpanded.payload));
    } catch (error) {
      
    }
  }

  function* resetAllFiltersSaga() {
    try {
      yield put(resetAllFilters());
    } catch (error) {
      
    }
  }

  function* setDockFiltersSaga(dockFiltersList) {
    try {
      yield put(setDockFiltersListReducer(dockFiltersList.payload));
    } catch (error) {
      
    }
  }

  function* setStatusFiltersSaga(statusFiltersList) {
    try {
      yield put(setStatusFiltersListReducer(statusFiltersList.payload));
    } catch (error) {
    }
  }
  
  function* setSelectedDockFiltersSaga(selectedDockFilter) {
    try {
      yield put(setSelectedDockFilterReducer(selectedDockFilter.payload));
      // yield put(resetAllFilters());
    } catch (error) {
    }
  }

  function* setSelectedStatusFiltersSaga(selectedStatusFilter) {
    try {
      // yield put(resetAllFilters());
      yield put(setSelectedStatusFilterReducer(selectedStatusFilter.payload));
    } catch (error) {
    }
  }

  function* updateIsSearchingSaga(isSearching) {
    try {
      yield put(updateIsSearching(isSearching.payload));
    } catch (error) {
    }
  }

  export function* getSelectedFilters() {
    yield takeEvery(sagaActions.GET_SELECTED_FILTERS_LIST, getSelectedFiltersListSaga)
  }

  export function* addSelectedFilters() {
    yield takeEvery(sagaActions.ADD_FILTER, addFiltersSaga)
  }

  export function* deleteSelectedFilters() {
    yield takeEvery(sagaActions.DELETE_FILTER, deleteFiltersSaga)
  }

  export function* setPOListFilter() {
    yield takeEvery(sagaActions.SET_PO_LIST_FILTERS, setPOListFilterSaga)
  }

  export function* isFilterViewExpaned() {
    yield takeEvery(sagaActions.SET_FILTERS_EXPANDED, isFilterViewExpandedSaga)
  }
 
  export function* resetFilters() {
    yield takeEvery(sagaActions.RESET_FILTERS, resetAllFiltersSaga)
  }

  export function* setDockFiltersList() {
    yield takeEvery(sagaActions.SET_DOCK_FILTERS_LIST, setDockFiltersSaga)
  }

  export function* setStatusFiltersList() {
    yield takeEvery(sagaActions.SET_STATUS_FILTERS_LIST, setStatusFiltersSaga)
  }

  export function* setSelectedDockFiltersList() {
    yield takeEvery(sagaActions.SELECTED_DOCK_FILTERS_LIST, setSelectedDockFiltersSaga)
  }

  export function* setSelectedStatusFiltersList() {
    yield takeEvery(sagaActions.SELECTED_STATUS_FILTERS_LIST, setSelectedStatusFiltersSaga)
  }

  export function* setIsSearchingStatus() {
    yield takeEvery(sagaActions.IS_SEARCHING, updateIsSearchingSaga)
  }