import _ from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignDockSelector } from '../../../../../../../redux/reducers/reducerSlices/AssignDockReducer';
import { getDockListSelector } from '../../../../../../../redux/reducers/reducerSlices/DockListReducer';
import { getLoadSelector } from '../../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import { getPOReducer } from '../../../../../../../redux/reducers/reducerSlices/POReducer';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { displayLoader, shouldHavePermission } from '../../../../../../Assests/Utility';
import CustomButton from '../../../../../Common/CustomButton/CustomButton';
import CustomTooltip from '../../../../../Common/Tooltip/CustomTooltip';
import CustomDropDown from '../../../../../Common/CustomDropDown/CustomDropDown';
import { isEmpty } from "lodash-es"

import './POUnscheduled.css'
import { formPOApproveData, shouldDisableApproveAndReschedule, shouldSelectPO } from './utils';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import Popup from '../../../../../Common/Popup/Popup';

const POUnscheduledIMSSP = (props) => {
    const APPROVED = "Approved"
    const RESCHEDULED = "Rescheduled"
    const dispatch = useDispatch()
    const { themeSelected } = props;
    const getLoad = useSelector(getLoadSelector);
    const poStatus = useSelector(getPOReducer);
    const getDockList = useSelector(getDockListSelector);
    const getAssignedDock = useSelector(getAssignDockSelector);
    const { assignedDock, isLoading } = getAssignedDock
    const { getLoadLoading, selectedLoad, enableDoorSection } = getLoad;
    const { dockList } = getDockList;
    const { unscheduled_pos } = selectedLoad;
    const [poApproveData, setpoApproveData] = useState([])
    const [loadStatus, setloadStatus] = useState()
    const [dockSelected, setdockSelected] = useState();
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse, permissions } = userPermission;
    const [allowPermission, setAllowPermission] = useState(false);
    const [showApproveConfirmationPopUp, setshowApproveConfirmationPop] = useState(false)
    const [approvePopupObj, setapprovePopupObj] = useState({})
    const [statusText, setStatusText] = useState(AppConstant.poManagement.poApproval.poScheduledSubText)

    useEffect(() => {
        if (permissions.length) {
            const warehouseManagementPermission = _.find(permissions, { 'FeatureID': '8', 'PageAccess': true });
            if (warehouseManagementPermission && !_.isEmpty(warehouseManagementPermission)) {
                setAllowPermission(true)
            } else {
                let temp = shouldHavePermission(permissions, '704');
                setAllowPermission(temp)
            }
        }
    }, [])

    useEffect(() => {
        if (unscheduled_pos) {
            const temp = unscheduled_pos.filter((el) => {
                return !shouldSelectPO(el)
            })
            if (temp && temp.length > 0) {
                setStatusText(AppConstant.poManagement.poApproval.poUnscheduledSubText)
            } else {
                setStatusText(AppConstant.poManagement.poApproval.poScheduledSubText)
            }
        }
    }, [unscheduled_pos])

    const renderPOs = () => {
        if (getLoadLoading) {
            return renderUnscheduledPos(true)
        } else if (!getLoadLoading && !_.isEmpty(selectedLoad) && _.size(unscheduled_pos)) {
            return renderUnscheduledPos(false)
        } else {
            return null
        }
    }

    const onPOApproveOrReschedule = (status) => {
        setloadStatus(status)
        if (_.isEmpty(selectedLoad.dock_id)) {
            dispatch({ type: sagaActions.INITIATE_PO_STATUS })
            initiateAssignDoor()
        } else {
            initiateApproveOrReschedulePO(status)
        }
    }

    const initiateApproveOrReschedulePO = (status) => {
        setloadStatus(status)
        if (!_.isEmpty(poApproveData)) {
            const data = {
                "load_control_num": selectedLoad.load_control_num,
                "po_status": status,
                "pos": poApproveData
            }
            dispatch({ type: sagaActions.INITIATE_PO_STATUS })
            dispatch({ type: sagaActions.APPROVE_PO, payload: data })
        }
    }

    const initiateAssignDoor = () => {
        try {
            const data = { "dock_id": dockSelected?.dock_id, "load_control_num": selectedLoad.load_control_num }
            dispatch({ type: sagaActions.ASSIGN_DOCK, payload: data })
        } catch { }
    }

    useEffect(() => {
        if (poStatus.poStatus === "Success") {
            setpoApproveData([])
            dispatch({ type: sagaActions.CLEAR_APPROVE_PO_STATUS })
            dispatch({ type: sagaActions.INTIATE_TRUCK_DETAILS });
            dispatch({ type: sagaActions.LOAD_TRUCK_DETAILS, payload: selectedLoad })
            dispatch({ type: sagaActions.GET_DOOR, payload: { dock_id: selectedLoad.dock_id } })
            dispatch({ type: sagaActions.AUTO_FETCH_PO_LIST, payload: true })
            dispatch({ type: sagaActions.FETCH_TRUCK_LIST, payload: '' })
        }
    }, [poStatus])

    useEffect(() => {
        if (!_.isEmpty(assignedDock)) {
            dispatch({ type: sagaActions.UPDATE_DOCK_ID, payload: assignedDock })
            initiateApproveOrReschedulePO(loadStatus)
            dispatch({ type: sagaActions.CLEAR_ASSIGN_DOCK })
        }
    }, [assignedDock])

    useEffect(() => {
        if (!_.isEmpty(getAssignedDock.error)) {
            dispatch({ type: sagaActions.CLEAR_ASSIGN_DOCK_ERROR })
            //alert need to be implemented
        }
    }, [getAssignedDock.error])

    useEffect(() => {
        if (!_.isEmpty(selectedLoad) && _.isEmpty(selectedLoad.dock_id)) {
            dispatch({ type: sagaActions.DOCK_LIST, payload: { warehouse_id: selectedWarehouse.ID } })
        }
    }, [selectedLoad])

    useEffect(() => {
        if (selectedLoad?.load_id) {
            setpoApproveData([])
            setdockSelected({})
        }
    }, [selectedLoad?.load_id])

    const setPOApproveData = (e) => {
        if (e.target.checked) {
            return setpoApproveData([...poApproveData, e.target.value])
        }
        return setpoApproveData(formPOApproveData(poApproveData, e.target.value))
    }

    const onDockSelected = (data) => setdockSelected(data)

    const getPopupContent = (data) => <div className='d-flex flex-column'>
        <span>
            {
                selectedLoad?.dock_id
                    ? `${AppConstant.commonStrings.areYouSure} ${data.content} PO ${poApproveData} ?`
                    // : (data.content === AppConstant.poManagement.poApproval.poRescheduleBtn
                    //     ? `${AppConstant.commonStrings.areYouSure} ${data.content} PO ${poApproveData.toString()} ?`
                        : `${AppConstant.commonStrings.areYouSure} ${data.content} PO ${poApproveData.toString()} with Selected Dock: ${dockSelected.dock_name} ?`
            }
        </span>
    </div>

const showConfirmationPopUp = (data) => {
    const popupObj = {

        id: 'approveReschedulePopup',
        modalLabel: 'approveReschedulePopup',
        showClose: true,
        showfooter: true,
        showHeader: true,
        keyboard: true,
        backdrop: true,
        title: 'Confirmation',
        bodyText: getPopupContent(data),
        subText: '',
        modalSize: '',
        footerBtn: {
            btn1: {
                show: true,
                text: AppConstant.commonStrings.no
            },
            btn2: {
                show: true,
                text: AppConstant.commonStrings.yes
            }
        },
        showModal: true
    }
    setapprovePopupObj({ ...popupObj, ...data })
    setTimeout(() => {
        setshowApproveConfirmationPop(true)
    }, 500)
}
    const renderUnscheduledPos = (showLoader) => {
        return showLoader ? <div className='d-flex flex-row align-items-stretch justify-content-start m-3'>
            <div className={`col-4 px-3 py-2 ${themeSelected}`}>
                {displayLoader(themeSelected)}
            </div>
            <div className='col-8 px-3 py-2'>
                {displayLoader(themeSelected)}
            </div>

        </div> :

            <div className='poScheduled-wrapper d-md-flex flex-md-row d-sm-flex flex-sm-column align-items-stretch justify-content-start m-3'>
                <div className={`d-flex flex-column justify-content-center poHeading-wrapper col-md-4 col-sm-12 py-2 ${themeSelected}`}>
                    <div className='heading align-self-start ps-3'>{AppConstant.poManagement.poApproval.poUnscheduled}</div>
                    <div className='subText align-self-start text-start ps-3'>{statusText}</div>
                </div>

                <div className='col-md-8 col-sm-12 d-flex flex-row flex-wrap px-3 py-1'>
                    {!selectedLoad.dock_id ? <div className='col-12 py-3 d-flex justify-content-start'>
                        <div className='d-flex flex-column justify-content-start dropDownContainer'>
                            <div className='col-12 py-2 text-start assignDockText'>{AppConstant.dock.SELECT_DOCK}</div>
                            <CustomDropDown themeSelected={props.themeSelected} data={dockList} placeHolder={AppConstant.dock.SELECT_DOCK} onSelect={onDockSelected} keyStr={'dock_name'} />
                        </div>
                    </div> : ''}
                    {unscheduled_pos.length && unscheduled_pos.map((el, i) => {
                        return <div className='table-title-checkBox d-flex flex-row justify-content-center align-items-center pe-3 py-1' key={el.poNumber + "_" + i} data-tip={`Items: ${el.items || '-'} Cases: ${el.cases || '-'}`} data-for={el.poNumber + "_" + i}>
                            <input checked={shouldSelectPO(el)} disabled={allowPermission ? shouldSelectPO(el) : 'disabled'} onClick={setPOApproveData} value={el.poNumber} type="checkbox" name={el.poNumber} />
                            <label className="px-2 heading" htmlFor={el.poNumber}>{el.poNumber}</label>
                            <CustomTooltip id={el.poNumber + "_" + i} position='top' type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                                multiline={false} />
                        </div>
                    })}
                    <div className="col-12 py-2 d-grid gap-4 d-flex justify-content-start poApproveBtn" data-for={AppConstant.poManagement.poApproval.selectDock} data-tip={AppConstant.poManagement.poApproval.selectDock}>
                        <CustomButton
                            disabled={allowPermission ? enableDoorSection || (shouldDisableApproveAndReschedule(selectedLoad, dockSelected?.dock_id) || isEmpty(poApproveData)) : 'disabled'}
                            loadingTitle={AppConstant.poStatus.APPROVING}
                            isLoading={(poStatus.poActionLoading || isLoading) && loadStatus === APPROVED}
                            title={AppConstant.poManagement.poApproval.poApprovalBtn} className={"btn btn-approve"}
                            onClick={() => showConfirmationPopUp({ content: AppConstant.poManagement.poApproval.poApprovalBtn, action: () => onPOApproveOrReschedule(APPROVED) })} />
                        <CustomButton
                            disabled={allowPermission ? enableDoorSection || (shouldDisableApproveAndReschedule(selectedLoad, dockSelected?.dock_id) || isEmpty(poApproveData)) : 'disabled'}
                            loadingTitle={AppConstant.poStatus.RESCHEDULING}
                            isLoading={poStatus.poActionLoading && loadStatus === RESCHEDULED}
                            title={AppConstant.poManagement.poApproval.poRescheduleBtn} className={`btn btn-reschedule ${themeSelected}`}
                            onClick={() => showConfirmationPopUp({ content: AppConstant.poManagement.poApproval.poRescheduleBtn, action: () => onPOApproveOrReschedule(RESCHEDULED) })} />
                        {
                            shouldDisableApproveAndReschedule(selectedLoad, dockSelected?.dock_id) && isEmpty(selectedLoad.dock_id) && <CustomTooltip id={AppConstant.poManagement.poApproval.selectDock} position='top' type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                                multiline={false} />
                        }
                    </div>
                </div>
            </div>

    }

    return (
        <>
            {
                renderPOs()

            }
            {
                showApproveConfirmationPopUp &&

                <Popup {...approvePopupObj}
                    popupBtnHandler={(data) => {
                        if (data === "Yes") {
                            approvePopupObj.action()
                        }
                        setshowApproveConfirmationPop(false)
                    }} closepopup={() => { setshowApproveConfirmationPop(false) }} themeSelected={themeSelected} />
            }
        </>
    );
}

export default POUnscheduledIMSSP;