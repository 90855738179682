import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateSelectorReducer, { getSelectedDateSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { getDefaultDashboardSelector } from '../../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import { modifyPODisplay } from '../../../../../../../../Assests/Utility';
import DrilldownTable from '../../Common/DrilldownTable';
import { getFormattedDate, YYYY_MM_DD_FORMAT } from '../../../../../../../../../Utils/DateUtils';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';

const DrillDownUnloadingHours = (props) => {
    const { themeSelected, loaderArray, subRoot, dockIdArr } = props;
    const dispatch = useDispatch();
    const [drilldownData, setDrilldownData] = useState([]);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { unloadingHoursdrillDownReport, arrangeOrder, arrangeKey, initiateDrillDownReport, dateRangeObj } = defaultDashboard;
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { selectedDate } = getSelectedDateSelectorObj;
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;

    let unloadingHoursTitleDD = [
        { id: 'PoNumbers', name: 'PO' },
        { id: 'AppointmentTime', name: 'Appt Time' },
        { id: 'NoOfPallets', name: 'No. of Pallets' },
        { id: 'UnloadingStartTime', name: 'Unloading Start' },
        { id: 'UnloadingEndTime', name: 'Unloading End' },
        { id: 'UnloadingDwell', name: 'Unloading Dwell' }
    ]
    const createPayload = () => {
        return { date: getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), dock_id: dockIdArr.toString() === '' ? null : dockIdArr.toString(), isDrillDown: '1', warehouse_id: selectedWarehouse.ID }
    }
    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        dispatch({
            type: sagaActions.GET_UNLOADING_HOURS_DRILLDOWN, payload: createPayload()
        });
    }, [])

    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        dispatch({
            type: sagaActions.GET_UNLOADING_HOURS_DRILLDOWN, payload: createPayload()
        });
    }, [dateRangeObj])

    useEffect(() => {
        if (unloadingHoursdrillDownReport && unloadingHoursdrillDownReport.length) {

            let data = formatDrillDownData(unloadingHoursdrillDownReport)
            setDrilldownData(data)
        }
    }, [unloadingHoursdrillDownReport])

    const formatDrillDownData = (data) => {
        return data.map(el => {
            let splitArray = []
            if (el.PoNumbers) {
                splitArray = el.PoNumbers.split(',')
            }
            return {
                id: el.Id,
                0: modifyPODisplay(splitArray) || '-',
                1: el.AppointmentTime || '-',
                2: el.NoOfPallets || '-',
                3: el.UnloadingStartTime || '-',
                4: el.UnloadingEndTime || '-',
                5: el.UnloadingDwell ? el.UnloadingDwell + 'hr' : '-',
            }
        })
    }

    return (
        <DrilldownTable showUTCTime={true} themeSelected={themeSelected} bodyData={drilldownData} titleData={unloadingHoursTitleDD} showCollapse={false} showInput={true} showSort={true} arrangeOrder={arrangeOrder} arrangeKey={arrangeKey} drillDownReport={unloadingHoursdrillDownReport} initiateDrillDownReport={initiateDrillDownReport} loaderArray={loaderArray} subRoot={subRoot} sortingAction={sagaActions.SORT_DRILLDOWN_REPORT} />
    );
}

export default DrillDownUnloadingHours;