import React from 'react';
import { AppConstant } from '../../../Assests/AppConstant';
import { ReactComponent as SearchDropdownLightIcon } from '../../../Assests/Svg/search-dropdown-light.svg';
import { ReactComponent as SearchDropdownDarkIcon } from '../../../Assests/Svg/search-dropdown-dark.svg';
import './FormDropDown.css'
/**
 * This is a reusale component that renders dropdown in form component
 * @param {*} props 
 * listArr = data from dropdown
 * selectedItem = selected dropdown item 
 * @returns 
 */
const FormDropDown = (props) => {
    const { themeSelected, listArr, selectedItem } = props;
    /**
     * 
     * @returns Dropdown icon based on theme
     */
    const setDropdownIcon = () => {
        return (
            themeSelected === AppConstant.commonStrings.lightModeFilterString ?
                <SearchDropdownDarkIcon className='dropdownIcon' /> :
                <SearchDropdownLightIcon className='dropdownIcon' />
        );
    }

    return (
        <div className=''>
            <div className='dropdown rounded py-0 px-2 formDropdown ' data-testid='form-dropdown'>
                <button className="btn border-0 m-0 p-0 w-100 h-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className='d-flex justify-content-between mx-1'>
                        <span className={`${themeSelected}-formDropdown `}>
                            {selectedItem}
                        </span>
                        <span className='ms-2'>
                            {setDropdownIcon()}
                        </span>
                    </div>
                </button>
                <ul className={`dropdown-menu  w-100 ${themeSelected === AppConstant.commonStrings.darkModeFilterString && "dropdown-menu-dark"}`}>
                    {listArr}
                </ul>
            </div>
        </div>
    )
}

export default FormDropDown;