import React, { useEffect, useState } from 'react'
import './HelpAndFeedbackSS.css'
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import ModalSS from '../../../../../../../SmartSchedule/SchedulePro/Components/Common/ModalSS/ModalSS';
import CustomButtonSS from '../../../../../../../Common/CustomButton/CustomButtonSS';
import InputWithTtileSS from '../../../../../../../Common/CustomInput/InputWithTtileSS';
import FileUploadSS from "../../../../../../../Common/CommanFileUpload/fileUploadSS";
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import { getFormattedContactNum, showAlert } from '../../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getHelpAndFeedbackReducer } from '../../../../../../../../../redux/reducers/reducerSlices/HelpAndFeedbackReducer';
import { getFileUploadReducer } from '../../../../../../../../../redux/reducers/reducerSlices/FileuploadReducer';
import { validContactNumRegex, validEmailRegex } from '../../../../../../../../../Utils/validationRegex';
import TextAreaWithTtileSS from '../../../../../../../Common/CustomTextArea/TextAreaWithTtileSS';
import { ReactComponent as FileIcon } from "../../../../../../../../Assests/Svg/file_icon.svg";

// This component render new design for Help and feedback
function HelpAndFeedbackSS(props) {
    const { toggleModal, formName, closeHelpModal, userId, warehouseId, heading, isIcon, iconClass } = props;
    const [loading, setLoading] = useState(false);
    const [imageUploading, setImageUploading] = useState(false);
    const dispatch = useDispatch();
    const helpData = useSelector(getHelpAndFeedbackReducer);
    const fileUploadData = useSelector(getFileUploadReducer);
    const [inputs, setInputs] = useState({
        email: "",
        name: "",
        phoneNo: "",
        issue: "",
        feedback: "",
        file: [],
        userId: userId,
        warehouseId: warehouseId
    });
    //validation code
    let [errors, setErrors] = useState({
        email: [],
        name: [],
        phoneNo: [],
        issue: [],
        feedback: [],
    });

    let [dirty, setDirty] = useState({
        email: false,
        name: false,
        phoneNo: false,
        issue: false,
        feedback: false,
    });

    const { email, name, phoneNo, issue, feedback } = inputs;

    let handleChange = (data, name) => {
        setInputs((inputs) => ({ ...inputs, [name]: data }));
    };
    const handleErrors = (name) => {
        setDirty((dirty) => ({ ...dirty, [name]: true }));
        validate();
    }

    let validate = () => {
        let validArray = [];

        let errorData = {
            email: [],
            name: [],
            phoneNo: [],
            issue: [],
            feedback: [],
        };

        let valid = true;

        valid = validateName(inputs.name, errorData);
        validArray.push(valid);
        valid = validateEmail(inputs.email, errorData);
        validArray.push(valid);
        valid = validatePhoneNo(inputs.phoneNo, errorData);
        validArray.push(valid);
        if (formName === AppConstant.help.formName) {
            valid = validateIssue(inputs.issue, errorData);
            validArray.push(valid);
        } else {
            valid = validateFeedback(inputs.feedback, errorData);
            validArray.push(valid);
        }
        setErrors(errorData);

        if (validArray.includes(false)) {
            return false;
        }
        else {
            return true
        }
    };
    /**
     * Validate email using regex
     * @param {*} email 
     * @param {*} errorData 
     * @returns  true/false
     */
    const validateEmail = (email, errorData) => {
        if (!email) {
            errorData.email.push(AppConstant.help.required);
            return false;
        } else {
            if (!validEmailRegex.test(email)) {
                errorData.email.push(AppConstant.help.emailIncorrect);
                return false;
            }
            return true;
        }
    };
    /**
     * Validate phoneNo using regex
     * @param {*} phoneNo 
     * @param {*} errorData 
     * @returns true/false
     */
    const validatePhoneNo = (phoneNo, errorData) => {
        if (!phoneNo) {
            errorData.phoneNo.push(AppConstant.help.required);
            return false;
        } else {
            if (!validContactNumRegex.test(phoneNo)) {
                errorData.phoneNo.push(AppConstant.help.contactIncorrect);
                return false;
            }
        }
        return true;
    };

    /**
     * Validate feedback using regex
     * @param {*} feedback 
     * @param {*} errorData 
     * @returns true/false
     */
    const validateFeedback = (feedback, errorData) => {
        if (!feedback) {
            errorData.feedback.push(AppConstant.help.required);
            return false;
        } else {
            if (feedback.length > 500) {
                errorData.feedback.push(AppConstant.help.lengthExceeded);
                return false;
            }
            return true;
        }
    };
    /**
     * Validate issue using regex
     * @param {*} issue 
     * @param {*} errorData 
     * @returns true/false
     */
    const validateIssue = (issue, errorData) => {
        if (!issue) {
            errorData.issue.push(AppConstant.help.required);
            return false;
        } else {
            if (issue.length > 500) {
                errorData.issue.push(AppConstant.help.lengthExceeded);
                return false;
            }
            return true;
        }
    };
    /**
     * Validate name 
     * @param {*} name 
     * @param {*} errorData 
     * @returns true/false
     */
    const validateName = (name, errorData) => {
        if (!name) {
            errorData.name.push(AppConstant.help.required);
            return false;
        }
        return true;
    };
    /**
     * Submit Hanlder
     */
    const onSubmit = async () => {
        let dirtyData = dirty;
        Object.keys(dirty).forEach((control) => {
            dirtyData[control] = true;
        });
        setDirty(dirtyData);
        if (validate()) {
            setLoading(true);
            const params = {
                ...inputs,
                phoneNumber: inputs.phoneNo,
                fileOne: inputs.file.length ? inputs.file[0].fileUrl : null,
                fileTwo: (inputs.file.length && inputs.file.length > 1) ? inputs.file[1].fileUrl : null,
                ownedBy: null,
                status: "OPEN",
                crud_type: 'I'
            }
            if (formName === AppConstant.help.formName) {
                const issueParams = { ...params, feedback: null, type: 'Issue' }
                dispatch({ type: sagaActions.ADD_HELP, payload: issueParams });

            } else {
                const feedbackParams = { ...params, issue: null, type: 'FeedBack' }
                dispatch({ type: sagaActions.ADD_FEEDBACK, payload: feedbackParams });

            }
        }
    };
    // useEffect for helpdata
    useEffect(() => {
        onLoad();
    }, [helpData]);
    /**
     * Load help and feedback data
     */
    const onLoad = () => {
        if (helpData.loading === false) {
            setLoading(false);
            if (helpData.error) {
                showAlert(toast.TYPE.ERROR, helpData.error.message);
            }
            if (helpData.data) {
                toggleModal()
                if (formName === AppConstant.help.formName) {
                    showAlert(toast.TYPE.SUCCESS, AppConstant.notifications.help.help);
                    dispatch({ type: sagaActions.RESET_HELP_FEED });
                } else {
                    showAlert(toast.TYPE.SUCCESS, AppConstant.notifications.help.feedback);
                    dispatch({ type: sagaActions.RESET_HELP_FEED });
                }
            }
        }

    };
    // useEffect to upload file(image) on server
    useEffect(() => {
        if (fileUploadData?.data) {
            setImageUploading(false);
            const imageName = fileUploadData?.data?.fileUrl.split("/").pop();
            setInputs({
                ...inputs,
                file: [...inputs.file, { fileUrl: fileUploadData?.data?.fileUrl, imageName }],
            });
            dispatch({ type: sagaActions.RESET_FILE_UPLOAD });

        }
        if (fileUploadData?.error) {
            setImageUploading(false);
            showAlert(toast.TYPE.ERROR, helpData?.error?.message);
        }
    }, [fileUploadData]);
    /**
     * function to handle file change
     * @param {*} fileData 
     * @param {*} name 
     */
    const onFileChange = async (fileData, name) => {
        if (inputs.file.length < 2) {
            setImageUploading(true);
            const fd = new FormData();
            fd.append("file", fileData);
            let fileUploadData = {
                name,
                fd
            }
            dispatch({ type: sagaActions.FILE_UPLOAD, payload: fileUploadData });
        } else {
            // Validation alert check for files - More than 2 image not allowed
            showAlert(toast.TYPE.ERROR, AppConstant.notifications.help.errorExceeded);
        }
    };

    return (
        <ModalSS toggleModal={toggleModal}>
            <div className='d-flex helpAndFeedback-wrapper'>
                <div className='help_and_feedback_left'>
                    <p className='help_and_feedback_heading'>{isIcon && <i className={iconClass} />} {heading}</p>
                    <div className='f-11 mt-5'>For Customer or Technical support contact us:</div>
                    <ul className='help_and_feedback_list f-12 mt-3'>
                        <li>
                            <i className="fa-regular fa-envelope"></i> {AppConstant.commonStrings.businessEmail}
                        </li>
                        <li>
                            <i className="fa-solid fa-phone"></i> 1-855-752-2878
                        </li>
                    </ul>
                    <div className='f-11 mt-5'>For Application Feedback or Request contact us:</div>
                    <ul className='help_and_feedback_list f-12 mt-3'>
                        <li>
                            <i className="fa-regular fa-envelope"></i>
                             {/* itdev@uchaininc.com */}
                             {AppConstant.commonStrings.businessEmail}
                        </li>
                        <li>
                            <i className="fa-solid fa-phone"></i> 720-762-9068
                        </li>
                    </ul>
                </div>
                <div className='help_and_feedback_right'>

                    <div className="help-form-group-ss">
                        <div className="">
                            <InputWithTtileSS
                                showError={dirty["name"] && errors["name"][0] ? errors["name"] : ""}
                                handleErrors={handleErrors}
                                name="name"
                                required={true}
                                value={name ? name : ""}
                                title={AppConstant.help.name}
                                placeHolder={AppConstant.help.enterName}
                                onEmailChange={handleChange}
                                titleIcon={true}
                                titleIconClass={'fa-regular fa-user'}
                                customClass={'customTextInput'}

                            />
                        </div>
                        <div className="">
                            <InputWithTtileSS
                                showError={
                                    dirty["email"] && errors["email"][0] ? errors["email"] : ""
                                }
                                required={true}
                                handleErrors={handleErrors}
                                name="email"
                                value={email ? email : ""}
                                title={AppConstant.help.email}
                                placeHolder={AppConstant.help.enterEmail}
                                onEmailChange={handleChange}
                                titleIcon={true}
                                titleIconClass={'fa-regular fa-envelope'}
                                customClass={'customTextInput'}
                            />
                        </div>
                        <div className="">
                            <InputWithTtileSS
                                showError={
                                    dirty["phoneNo"] && errors["phoneNo"][0] ? errors["phoneNo"] : ""
                                }
                                required={true}
                                handleErrors={handleErrors}
                                name="phoneNo"
                                value={phoneNo ? getFormattedContactNum(phoneNo) : ""}
                                title={AppConstant.help.phone}
                                placeHolder={AppConstant.user.popup.createUser.phone_placeHolder}
                                onEmailChange={handleChange}
                                titleIcon={true}
                                titleIconClass={'fa-solid fa-phone'}
                                customClass={'customTextInput'}
                            />
                        </div>
                        <div className="">
                            <FileUploadSS
                                accept={"image/png,image/jpeg,.pdf"}
                                sizeConfig={AppConstant.help.imageSizeConfig}
                                onFileChange={onFileChange}
                                name="file"
                                isLoading={imageUploading}
                            />
                        </div>


                    </div>
                    <div className="mt-4">
                        {formName === AppConstant.help.formName ? (
                            <TextAreaWithTtileSS
                                showError={
                                    dirty["issue"] && errors["issue"][0] ? errors["issue"] : ""
                                }
                                required={true}
                                handleErrors={handleErrors}
                                name="issue"
                                value={issue ? issue : ""}
                                title={AppConstant.help.issue}
                                placeHolder={AppConstant.help.enterIssue}
                                onEmailChange={handleChange}
                                titleIcon={true}
                                titleIconClass='fa-regular fa-circle-question'
                            />
                        ) : (
                            <TextAreaWithTtileSS
                                showError={
                                    dirty["feedback"] && errors["feedback"][0]
                                        ? errors["feedback"]
                                        : ""
                                }
                                required={true}
                                name="feedback"
                                handleErrors={handleErrors}
                                value={feedback ? feedback : ""}
                                title={AppConstant.help.feedback}
                                placeHolder={AppConstant.help.enterIssue}
                                onEmailChange={handleChange}
                                titleIcon={true}
                                titleIconClass='fa-regular fa-message'
                            />
                        )}
                    </div>

                    {inputs.file.map((imgData, index) => (
                        <div className="border-box my-4" key={index}>
                            <span className="box-main-area">
                                <FileIcon />
                                <span className="pdlft-2">{imgData.imageName}</span>
                            </span>
                            <span
                                className="cursor-pointer"
                                onClick={() => {
                                    inputs.file.splice(index, 1);
                                    setInputs({ ...inputs });
                                }}
                            >
                                {/* <Closemodal /> */}
                            </span>
                        </div>
                    ))}
                    <div className="d-flex justify-content-start w-100 mt-3">
                        <CustomButtonSS
                            isLoading={loading}
                            title={AppConstant.help.helpButtonTitle}
                            className={"btn-highlighted w-25 f-14"}
                            onClick={onSubmit}
                        />
                    </div>


                </div>
            </div>
        </ModalSS>
    )
}

export default HelpAndFeedbackSS