import React from 'react'

const CalenderIcon = (props) => {
    const getStroke = () => {
        if (props.themeSelected === "light-mode") {
            return "#575757"
        }
        return "#ADADAD"
    }
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.75 1C7.75 0.585786 7.41421 0.25 7 0.25C6.58579 0.25 6.25 0.585786 6.25 1V2.25H5C2.37665 2.25 0.25 4.37665 0.25 7V17C0.25 19.6234 2.37665 21.75 5 21.75H17C19.6234 21.75 21.75 19.6234 21.75 17V7C21.75 4.37665 19.6234 2.25 17 2.25H15.75V1C15.75 0.585786 15.4142 0.25 15 0.25C14.5858 0.25 14.25 0.585786 14.25 1V2.25H7.75V1ZM7.75 4.75V4V3V2.25H6.25V3V4V4.75H7.75ZM14.25 4.75V4V3V2.25H15.75V3V4V4.75H14.25ZM1.90675 6C1.80498 6.31503 1.75 6.65109 1.75 7V17C1.75 18.7949 3.20507 20.25 5 20.25H17C18.7949 20.25 20.25 18.7949 20.25 17V7C20.25 6.65109 20.195 6.31503 20.0933 6H1.90675Z" fill={getStroke()} />
            </svg>
        </div>
    )
}

export default CalenderIcon
