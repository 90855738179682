import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../Common/CustomButton/CustomButton';
import InputWithTtile from '../../../../Common/CustomInput/InputWithTtile';
import '../../guards/BookApptsGuardsSearch/BookApptsGuardsSearch.css';
import { useDispatch } from 'react-redux';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../Assests/AppConstant';
import CustomTooltip from '../../../../Common/Tooltip/CustomTooltip';

import { getFormattedContactNum } from '../../../../../Assests/Utility';


const BookApptsCheckInSearch = (props) => {

    const dispatch = useDispatch()
    const { searchAppointment, onEmailChange, initateLoading, themeSelected = 'light-mode' } = props;
    const [isLoading, setIsLoading] = useState(initateLoading)
    const [inputs, setInputs] = useState({
        po: "",
      });
    let [errors, setErrors] = useState({
        po: [],
       });
    
      let [dirty, setDirty] = useState({
        po: false,
      });

    useEffect(() => {
        // Clear Saerched PO results when components unmount
        return () => {
            dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO })
        }
    }, [])

    useEffect(() => {
        setIsLoading(initateLoading)
    }, [initateLoading])

    const emailAppiontment = () => {

    }
    let handleChange = (data, name) => {
        setInputs((inputs) => ({ ...inputs, [name]: data }));
      };
    const handleErrors = (name) => {
        setDirty((dirty) => ({ ...dirty, [name]: true }));
        validate();
      }
     let validate = () => {
    let validArray = [];
   
    let errorData = {
        po: [],
    };
   
      let valid = true;
      
    valid = validatePo(inputs.po, errorData);
    validArray.push(valid);
    validArray.push(valid);
   
      setErrors(errorData);
  
     if (validArray.includes(false)) {
      return false;
    }
    else {
      return true
    }
  };
  const validatePo = (po, errorData) => {
    if (!po) {
      errorData.po.push(AppConstant.help.required);
      return false;
    } 
  };

  useEffect(()=>{
    if(props.callReset){
      reset()
    }
  
  },[props.callReset])
  const reset=()=>{
    setInputs({
      po: "",
    });
    setErrors({
      po: []
     });
  
     setDirty({
      po: false,   
    });
  }
  const onSubmit = async () => {
    let dirtyData = dirty;
    Object.keys(dirty).forEach((control) => {
      dirtyData[control] = true;
    });
    setDirty(dirtyData);
   if (validate()) {
    searchAppointment(inputs);
    }
  };
    return (
        <div className='mt-3'>
            <div className='d-flex justify-content-center'>
                <div className='w-50 '>
                    <div className='fw-bold text-center'>{AppConstant.smartScheduler.searchAppointments.searchHeading}</div>
                    <div className='d-flex justify-content-center'>
                        <div className='m-2 w-50 '>
                            <InputWithTtile
                              showError={dirty["po"] && errors["po"][0] ? errors["po"] : ""}
                                title={'Please enter PO Number here'}
                                subTitle={'Please enter PO Number here '}
                                titleToolTipText={''}
                                placeHolder={'Please enter PO Number here'}
                                superScriptText={
                                    <>&nbsp;<sup className='hoverSuperScript'>[?]</sup>&nbsp;:</>
                                }
                                toolTipId='po-search-tool-tip'
                                name="po"
                                required={true}
                                value={inputs.po ? inputs.po : ""}
                                handleErrors={handleErrors}
                                onEmailChange={handleChange}
                               />
                        
                            <CustomTooltip
                                id={'po-search-tool-tip'}
                                position='top'
                                title={
                                  <p>
                                      Enter the PO number of Driver load here.<br />
                                      The PO number may also be listed as a Customer Order Number.<br/>
                                      PO numbers are usually 6 or 7-digits long and may include numbers and letters.<br/>
                                      If the Driver has more than one PO or Customer Order Number, enter the first PO listed. 
                                  </p>
                              }
                                type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                                multiline={false} />
                        </div>
                  
                     
                    </div>
                    <div className="d-flex justify-content-center ms-2 mt-0 pt-0">   <a href='#' className='poImageHyperlinkText text-danger m-0 p-0' onClick={() => props.showPdfHelperPopup()}>Need help locating PO on BOL?</a></div>
                    <div className='mt-3 d-flex justify-content-center'>
                        <CustomButton
                            isLoading={isLoading}
                            title={'Search'}
                            className={"loginButton w-25"}
                            onClick={onSubmit}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BookApptsCheckInSearch;