import React, { useEffect, useState } from "react";
import { AppConstant } from "../../../Assests/AppConstant";
import { getCurrentDate, MM_DD_YYYY_FORMAT, getFormattedDate } from '../../../../Utils/DateUtils';
import DatePicker from "react-datepicker";
import { getFormattedContactNum } from '../../../Assests/Utility';
import { ReactComponent as Eye } from "../../../Assests/Svg/eye.svg"
import { ReactComponent as EyeOpened } from "../../../Assests/Svg/eyeOpen.svg"
import moment from "moment-timezone";
import "./CustomFieldWithTitle.css";
/**
 * This is reusable component for which used to reder different type of input box
 * Used is form component
 * @param {*} props 
 * @returns 
 */
const CustomFieldWithTtile = (props) => {
  const {
    title,
    placeHolder,
    showError,
    errorMessage,
    value,
    name,
    required,
    type,
    listData,
    radiconfig,
    customDatePickerConfig,
    disabledField,
    addIcon,
    hideElement,
    iconHandler,
    className,
    editData,
    isEditDisable,
    loading = false,
    optionsList
  } = props;
 
  const [error, seterror] = useState(showError);
  const [showPassword, setshowPassword] = useState(false)
  /**
   * ruseEffect for error
   */
  useEffect(() => {
    seterror(showError);
  }, [showError]);

  /**
   * Show password handler function
   * @returns 
   */
  const handleShowPassword = () => setshowPassword(!showPassword)
  /**
   * 
   * @returns JSX for password eye open or close
   */
  const getEyeImage = () => showPassword
    ? <EyeOpened />
    : <Eye />
  /**
   * function to check if date is weekend or not
   * @param {*} date 
   * @returns 
   */
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 6;
  };
 
  return (
    <div className="d-flex flex-column">
      <span
        data-testid={AppConstant.dataTestId.customInputTitle}
        className="inputTitle"
      >
        {!hideElement ?
          <>{title}&nbsp;{required && <span className="color-red">*</span>}</>
          :
          !hideElement.isHidden ? <>{''}</> : <>{title}&nbsp;{required && <span className="color-red">*</span>}</>
        }
        {
          loading &&  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"
          />
        }
 
      </span>
      {type === "textarea" ? (
        <textarea
          cols={4}
          rows={4}
          data-testid={AppConstant.dataTestId.customInput}
          value={value}
          name={name}
          className={error ? "customTextArea customError" : "customTextArea"}
          placeholder={placeHolder}
          onBlur={(data) => props.handleErrors(data.target.name)}
          onChange={(data) =>
            props.onChange(data.target.value, data.target.name)
          }
        />
      ) : type === "select" ? (
 
        hideElement && !hideElement.isHidden ?
          <div /> :
          <select
            data-testid={AppConstant.dataTestId.customInput}
            value={value}
            name={name}
            className={isEditDisable && editData && Object.keys(editData).length != 0 && editData[name] ? 'disabled customSelect' : error ? "customSelect customError" : "customSelect"}  
            onBlur={(data) => props.handleErrors(data.target.name)}
            onChange={(data) =>
              props.onChange(data.target.value, data.target.name)
            }
            disabled={disabledField ? disabledField : false}
          >
            <option value="">Select {title}</option>
              {listData && listData()}
              {optionsList && optionsList.length && optionsList.map((el,i)=>{
                            return(
                                <option key={el.value} value={el.value}>
                                {el.name}
                            </option>
                            )
                        })}
          </select>
      ) : type === "radio" ? (
        <>
          <div style={{ display: "flex" }} className={className || ''}>
            {radiconfig && radiconfig.map((singData, index) => (
              <div className="form-check form-check-inline" key={index}>
                <input
                  data-testid={AppConstant.dataTestId.customInput}
                  type="radio"
                  value={singData.value}
                  checked={value.toString() === singData.value.toString()}
                  name={name}
                  className="form-check-input"
                  placeholder={placeHolder}
                  onBlur={(data) => props.handleErrors(data.target.name)}
                  onChange={(data) =>
                    props.onChange(data.target.value, data.target.name)
                  }
                  disabled={disabledField}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  {singData.label}
                </label>
              </div>
            ))}
          </div>
        </>
      ) : type === "checkbox" ? (
        <input
          data-testid={AppConstant.dataTestId.customInput}
          type="checkbox"
          value={value}
          name={name}
          className="form-check-input"
          placeholder={placeHolder}
          onBlur={(data) => props.handleErrors(data.target.name)}
          onInput={(data) =>
            props.onChange(data.target.checked, data.target.name)
          }
        />
      ) : type === "custom-date-picker" ? (
        <DatePicker
          name={name}
          value={(value && value !== AppConstant.commonStrings.MM_DD_YYYY_dateFormat) ? JSON.stringify(getFormattedDate(MM_DD_YYYY_FORMAT, moment(value).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat))).replaceAll('"', '') : AppConstant.commonStrings.MM_DD_YYYY_dateFormat}
          onChange={(date) => props.onChange(date, customDatePickerConfig.name ? customDatePickerConfig.name : 'custom-date-picker')}
          dateFormat={AppConstant.commonStrings.MM_DD_YYYY_dateFormat}
          customInput={customDatePickerConfig && customDatePickerConfig.customInput}
          minDate={customDatePickerConfig && customDatePickerConfig.startDate ? new Date(customDatePickerConfig.startDate) : ''}
          disabled={customDatePickerConfig && customDatePickerConfig.disableCalendar ? true : false}
          maxDate={customDatePickerConfig && customDatePickerConfig.maxDate ? customDatePickerConfig.maxDate : getCurrentDate()}
          filterDate={customDatePickerConfig && customDatePickerConfig.calendarType === 'Weekly' && isWeekend}
          showMonthYearPicker={customDatePickerConfig && customDatePickerConfig.calendarType === 'Monthly' ? true : false}
          timeCaption={customDatePickerConfig?.timeCaption ? customDatePickerConfig?.timeCaption : ''}
 
        />
      ) : type === "custom-time-picker" ? (
        <DatePicker
          name={name}
          value={value ? value : AppConstant.commonStrings.hh_mm_ss_dateFormat}
          onChange={(date) => props.onChange(date, customDatePickerConfig.name ? customDatePickerConfig.name : 'custom-time-picker')}
          dateFormat={AppConstant.commonStrings.HH_MM_dateFormat}
          customInput={customDatePickerConfig && customDatePickerConfig.customInput}
          disabled={customDatePickerConfig && customDatePickerConfig.disableCalendar ? true : false}
          showTimeSelect={true}
          filterTime={customDatePickerConfig && customDatePickerConfig.filterPassedTime}
          timeIntervals={customDatePickerConfig?.timeIntervals ? customDatePickerConfig?.timeIntervals : '30'}
          showTimeSelectOnly={true}
          timeCaption={customDatePickerConfig?.timeCaption ? customDatePickerConfig?.timeCaption : ''}
        // minTime={customDatePickerConfig.minTime}
        // maxTime={customDatePickerConfig.maxTime}
        />
      ) : type === "color" ? (
        <div className="d-flex align-items-center mt-2">
          <div className='d-flex align-items-center'>
            <input
              data-testid={AppConstant.dataTestId.customInput}
              type={type ? type : "text"}
              value={value}
              name={name}
              className={"customTextInput border-0 m-0 p-0 "}
              placeholder={placeHolder}
              onBlur={(data) => props.handleErrors(data.target.name)}
              onInput={(data) =>
                props.onChange(data.target.value, data.target.name)
              }
              disabled={disabledField}
            />
            <input
              data-testid={AppConstant.dataTestId.customInput}
              type={"text"}
              value={value}
              name={name}
              className={"customTextInput m-0 p-1 ms-3"}
              placeholder={placeHolder}
              onBlur={(data) => props.handleErrors(data.target.name)}
              onInput={(data) =>
                props.onChange(data.target.value, data.target.name)
              }
              disabled={disabledField}
            />
          </div>
        </div>
      ) : type === "password" ? (
        <div className="d-flex justify-content-between align-items-center passwordTextContianer">
          <input
            data-testid={AppConstant.dataTestId.customInput}
            type={showPassword ? "text" : "password"}
            value={name === 'contact_number' || name === 'phone_number' ? getFormattedContactNum(value) : value}
            name={name}
            className={error ? "w-100 m-0 p-0 border-0 text-start customError bg-transparent" : "w-100 m-0 p-0 border-0 text-start bg-transparent"}
            placeholder={placeHolder}
            onBlur={(data) => props.handleErrors(data.target.name)}
            onInput={(data) =>
              props.onChange(data.target.value, data.target.name)
            }
            disabled={disabledField}
            autoComplete="new-password"
          />
          <button className='border-0 bg-transparent m-0 p-0' onClick={handleShowPassword} data-testid={AppConstant.dataTestId.passwordEyeButton}>
            {
              getEyeImage()
            }
          </button>
        </div>
      ) : type === "emptyspace" ? (
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="bg-transparent border-0"
          />
        </div>
      ) : type === 'date' ?
        (
          <div className="d-flex justify-content-center align-items-center">
            <input
              data-testid={AppConstant.dataTestId.customInput}
              type={type}
              value={value ? value : 'yyyy-MM-dd'}
              name={name}
              className={error ? "w-100 customTextInput customError" : "w-100 customTextInput"}
              placeholder={placeHolder}
              min={customDatePickerConfig && customDatePickerConfig.startDate ? moment(customDatePickerConfig.startDate).format('yyyy-MM-dd') : ''}
              max={customDatePickerConfig && customDatePickerConfig.maxDate ? moment(customDatePickerConfig.maxDate).format('yyyy-MM-dd') : ''}
              onBlur={(data) => props.handleErrors(data.target.name)}
              onInput={(data) =>
                props.onChange(data.target.value, data.target.name)
              }
              disabled={disabledField}
            />
            {addIcon ? <i
              className="fa fa-plus-square fa-lg "
              style={{ cursor: "pointer" }}
              aria-hidden="true"
              onClick={iconHandler}
            ></i> : ''}
          </div>
        )
        : (  hideElement && !hideElement.isHidden ?
          <div /> :
          <div className="d-flex justify-content-center align-items-center">
            <input
              data-testid={AppConstant.dataTestId.customInput}
              type={type ? type : "text"}
              value={name === 'contact_number' || name === 'phone_number' ? getFormattedContactNum(value) : value}
              name={name}
              className={isEditDisable && editData && Object.keys(editData).length != 0 && editData[name] ? 'text-grey disabled w-100 customTextInput' : error ? "w-100 customTextInput customError" : "w-100 customTextInput"}
              placeholder={placeHolder}
              min={customDatePickerConfig && customDatePickerConfig.startDate ? moment(customDatePickerConfig.startDate).format('YYYY-MM-DD') : ''}
              max={customDatePickerConfig && customDatePickerConfig.maxDate ? moment(customDatePickerConfig.maxDate).format('YYYY-MM-DD') : ''}
              onBlur={(data) => props.handleErrors(data.target.name)}
              onInput={(data) =>
                props.onChange(data.target.value, data.target.name)
              }
              disabled={disabledField || isEditDisable && editData && Object.keys(editData).length != 0 && editData[name]}
            />
            {addIcon ? <i
              className="fa fa-plus-square fa-lg "
              style={{ cursor: "pointer" }}
              aria-hidden="true"
              onClick={iconHandler}
              data-testid={'add_icon'}
            ></i> : ''}
          </div>
        )}
 
      {
        error && (
          <span className="errorMessage" data-testid={AppConstant.dataTestId.validationError}>
            {errorMessage ? errorMessage : error}
          </span>
        )
      }
    </div >
  );
};
 
export default CustomFieldWithTtile;
 