import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';

import { areObjectsEqual, displayLoader, getFormattedContactNum, showAlert } from '../../../../Assests/Utility';
// @ts-ignore
import { toast } from 'react-toastify';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { smartScheduleSelector } from '../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';


import { AppConstant } from '../../../../Assests/AppConstant';
import Popup from '../../../Common/Popup/Popup';
import PDFViewer from '../../../Common/PdfViewer/PdfViewer';
import CheckInApptsListScreen from './components/CheckInApptsListScreen';
import BookApptsCheckInSearch from './components/BookApptsCheckInSearch';
import { CheckInSelector } from '../../../../../redux/reducers/reducerSlices/CheckInReducer';
import moment from 'moment';
import CustomButton from '../../../Common/CustomButton/CustomButton';
import { getTimeDiffInMinutes } from '../../../../../Utils/DateUtils';

const CheckIn = (props) => {

    const { themeSelected } = props;
    const dispatch = useDispatch();
    const userPermission = useSelector(getUserSelector);
    const {  userType, selectedWarehouse, cu_id } = userPermission;
    const ss = useSelector(smartScheduleSelector);
    const { initateLoading, guardSearchPoData, appointmentList } = ss;
    const [didSearched, setDidSearched] = useState(false);
  const [scheduleScreenType, setScheduleScreenType] = useState('')
    const [searchPoDataState, setSearchPoDataState] = useState(null)
const [inputData,setInputData]=useState();
const [callReset,setCallReset]=useState(false);
const [showHelpModal, setShowHelpModal] = useState(false);
const checkin=useSelector(CheckInSelector)
const {checkInLoad,isLoading,checkInError , checkInResponse,initiateLoading} = checkin;
const [showCheckInData,setShowCheckInData] = useState(true)
const [showModal,setShowModal] = useState(false)


useEffect(() => {
  
    setShowHelpModal(false);
    setDidSearched(false)
}, [])

useEffect(()=>{
    if (searchPoDataState && _.isArray(searchPoDataState.loads )&& didSearched) {
        const diff= getTimeDiffInMinutes(moment().utc().format("MMM DD YYYY h:mmA"), searchPoDataState.loads[0].scheduled_time_utc)
        if(diff<150 ){
            setShowCheckInData(true)
        }
        else{
            setShowCheckInData(false)
            setShowModal(true)
        }
    }
},[searchPoDataState])



    useEffect(() => {
        if (checkInLoad) {
            setSearchPoDataState(checkInLoad[0])
            
        }
        else{
            setSearchPoDataState(null)
        }
      
    }, [checkInLoad])
const clearScreen=()=>{
    setCallReset(true);
}
    const showFindPOPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: 'Trouble locating Driver PO Number ?',
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showHelpModal} />
    }

    const closeCurrentpopup = () => {
        setShowHelpModal(false);
    }
    const createForm = () => {
        const poHelperArr = [
            'The PO number may also be listed as a Customer Order Number.',
            'PO numbers are usually 6 or 7-digits long and may include numbers and letters.',
            'If the Driver has more than one PO or Customer Order Number, enter the first PO listed. ',
        ]
        return (
            <div className=''>
                <ol>
                    {
                        poHelperArr.map((ele) => {
                            return <li className='fs-6'>{ele}</li>
                        })
                    }
                    
                </ol>
                <PDFViewer pdfUrl={AppConstant.commonStrings.findPoPdfHelperFile} width={'100%'} height={"500px"} />
            </div>
        );
    };


    const displayAppointmentsList = (inputs) => {
        setSearchPoDataState([])
        setCallReset(false);
            setDidSearched(true)
            setInputData(inputs);
            dispatch({ type: sagaActions.INTIATE_CHECKIN_SEARCH_PO, payload: { po_number: inputs.po, warehouses:[selectedWarehouse.ID],coords:null} })
      

    }


    const showDateTimeConfirmPopup = () => {
        const popupObj = {
            id: 'showDateTimeConfirmPopup',
            modalLabel: 'showDateTimeConfirmPopup',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: "Alert",
            bodyText:  <p>
            Your scheduled appointment time {searchPoDataState && _.isArray(searchPoDataState.loads) && searchPoDataState.loads[0].scheduled_time} is greater than 2.5 hours.
            <br />
            Are you still want to proceed ?
          </p>,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.no
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => {btnClickHandler(text)}} closepopup={closepopup} themeSelected={themeSelected}  showModal={showModal}/>
    }

    const btnClickHandler = (text) => {
      if (text === AppConstant.commonStrings.yes) {
        setShowCheckInData(true);
        closepopup();
      } else {
        setShowCheckInData(false);
        setDidSearched(false);
        setSearchPoDataState(null);
        setInputData("");
        closepopup();
      }
    };

    const closepopup=()=>{
        setShowModal(false)
    }

    return (
      <div className="container-fluid m-1">
        <Header {...props} />
        <Navigationbar {...props} />
        <BookApptsCheckInSearch
          {...props}
          searchAppointment={displayAppointmentsList}
          initateLoading={initiateLoading}
          showPdfHelperPopup={() => setShowHelpModal(true)}
          scheduleScreenType={scheduleScreenType}
          setScheduleScreenType={setScheduleScreenType}
          callReset={callReset}
        />
        {
         initiateLoading ? (
          <div className="w-100">{displayLoader(themeSelected, 150, 5)}</div>
        ) : (
          (didSearched &&
          searchPoDataState && showCheckInData) && (
            <CheckInApptsListScreen
              data={searchPoDataState}
              scheduleScreenType={scheduleScreenType}
              setScheduleScreenType={setScheduleScreenType}
              screenTypeClassname=""
              inputData={inputData}
              clearScreen={clearScreen}
              callReset={callReset}
            />
          )
        )}
        {showFindPOPopup()}
        {showDateTimeConfirmPopup()}
      </div>
    );
}

export default CheckIn;