import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dockList: [],
    isLoading: false,
    error: null
}

export const DockListReducer = createSlice({
    name: 'getDockList',
    initialState,
    reducers: {
        initiateGetDockList: (state) => {
            state.isLoading = true
        },
        getDockListSuccess: (state, action) => {
            state.isLoading = false
            state.dockList = action.payload;
        },
        getDockListFailure: (state, action) => {
            state.isLoading = false
            state.error = action.payload;
        },
        clearDockList: (state) => {
            state.dockList = [];
        },
        clearError: (state) => {
            state.error = null
        },
    }
});


export const { getDockListSuccess, initiateGetDockList, getDockListFailure, clearError, clearDockList } = DockListReducer.actions;

// Selectors
export const getDockListSelector = (state) => {
    return state.getDockList;
};

export default DockListReducer.reducer;
