import React, { useState } from 'react';
import { ReactComponent as CrossIcon } from '../../../../../../../../Assests/Svg/cross-icon-black.svg';
import { ReactComponent as CrossIconWhite } from '../../../../../../../../Assests/Svg/cross-icon-white.svg';
import { ReactComponent as AddIcon } from '../../../../../../../../Assests/Svg/add-icon.svg';
import './FaqTableRow.css';


const FaqTableRow = (props) => {

    const { data, index } = props;
    const [expanded, setExpandex] = useState(false)

    const toggleIcon = () => {
        setExpandex(!expanded);
    }

    return (
        <tr>
            <td>
                <div className="d-flex justify-content-center border-0 m-2 py-1 w-100"
                    data-bs-toggle="collapse"
                    href={`#multiCollapse${index}`}
                    role="button"
                    aria-expanded="false"
                    aria-controls="multiCollapseExample1"
                    onClick={() => toggleIcon()}
                >
                    <div className='w-50 d-flex justify-content-between text-start questionsText'>
                        <div>{index + 1}. {data.QuestionText}?</div>
                        <div className='ms-2'>
                            {expanded ? <CrossIcon /> : <AddIcon />}
                        </div>
                    </div>
                </div>
                <div className="collapse multi-collapse" id={`multiCollapse${index}`}>
                    <div className="d-flex justify-content-center border-0 m-0 p-0 w-100">
                        <div className='w-50 answerText'>
                            {data.AnswerText}
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center py-2'>
                    <div className='seperatorFaq' />
                </div>
            </td>
        </tr>
    )
}

export default FaqTableRow;