import React, { useEffect, useState } from 'react'
import CustomButton from '../../../Common/CustomButton/CustomButton';
import Seperator from '../../../Common/Seperator/Seperator';
import { sagaActions } from '../../../../../sagas/sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import PasswordInput from '../../../Common/PasswordInput/PasswordInput';
import ClickToResend from '../../components/ClickToResend';
import { showAlert, formatFirstCharBold } from '../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { isValid, isValidPassword } from '../../utils';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import _ from 'lodash-es';
import TryLogin from '../../components/TryLogin';

const ChangePassword = (props) => {
    const dispatch = useDispatch()
    const { themeSelected } = props;
    const [verficationCode, setVerificationCode] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [verficationError, setVerficationError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [isloading, setisloading] = useState(false)
    const user = useSelector(getAuthReducer);
    const navigate = useNavigate();
    const userPermission = useSelector(getUserSelector);
    const { branding } = userPermission;
    const [bgImage, setBgImage] = useState('')
    const [warehouseLogo, setWarehouseLogo] = useState('');
    const backgroundImage = AppConstant.commonStrings.bkgImgLink;

    useEffect(() => {
        if (user.isPasswordResetSuccess) {
            showAlert(toast.TYPE.SUCCESS, AppConstant.notifications.login.passwordResetSuccess);
            dispatch({ type: sagaActions.RESET_PASSWORD_SUCCESS })
            navigate("/")
            setisloading(false)
        } else {
            setisloading(false)
        }
    }, [user.isPasswordResetSuccess])

    useEffect(() => {
        if (isValid('password', password) && isValid('password', confirmPassword)) {
            setPasswordError(false)
        } else {
            setPasswordError(true)
        }
    }, [password, confirmPassword])

    useEffect(() => {
        if (verficationCode.length > 6) {
            setVerficationError(true)
        } else {
            setVerficationError(false)
        }
    }, [verficationCode])

    const initiateChangePassword = () => {
        if (isValid('password', password) && isValid('password', confirmPassword) && password === confirmPassword) {
            if (verficationCode) {
                setisloading(true)
                dispatch(({ type: sagaActions.RESET_PASSWORD_INTIATED, payload: { password: password, code: verficationCode, email: user.recoveryEmail, themeSelected } }))
            }
            else {
                showAlert(toast.TYPE.ERROR, AppConstant.notifications.login.verficationCodeBlank, themeSelected);
            }
        }
        else {
            if (!isValid('password', password) || !isValid('password', confirmPassword)) {
                showAlert(toast.TYPE.WARNING, AppConstant.login.passwordValidation, themeSelected)
            } else {
                showAlert(toast.TYPE.WARNING, AppConstant.notifications.login.passConformPassCheck, themeSelected)
            }
        }
    }
    useEffect(() => {
        if(!_.isEmpty(branding)){
            const {IsBranding, WarehouseLogo, BackgroundImage } = branding
            if(IsBranding){
                setBgImage(BackgroundImage)
            setWarehouseLogo(WarehouseLogo)
        }
    }
    }, [branding])
    return (
        <div className='d-flex justify-content-start align-items-center bgContainer bgContainer_verfication_screen px-0 mx-0'>
            <img className={'bgimage'} src={bgImage ? bgImage : backgroundImage} alt='Background Image' />
            <div className='d-flex parentContainer justify-content-center align-items-center'>
                <div className='d-flex flex-column loginContainer verification_loginContainer justify-content-center align-items-center'>
                    <div data-testid={AppConstant.dataTestId.logoContainer}
                        className='d-flex justify-content-center align-items-center logoContainer'>
                        <img className="login-logo" src={warehouseLogo ? warehouseLogo : AppConstant.commonStrings.logo_small_wordMark} />    
                    </div>
                    {/* <span data-testid={AppConstant.dataTestId.title}>
                        {formatFirstCharBold(AppConstant.login.loginTitle)}
                    </span> */}
                    <div className="passwordDivWrapper verfication_screen_passwordDivWrapper ms-4">
                        <div className='passwordDiv'>
                            <PasswordInput
                                errorMessage={AppConstant.login.verficationCodeIncorrect}
                                showError={verficationError}
                                title={AppConstant.login.verficationCodeTitle} placeHolder={AppConstant.login.enterVerificationCode}
                                onPasswordChange={(e) => setVerificationCode(e)} />
                        </div>
                        <div className='passwordDiv verfication_passwordDiv'>
                            <PasswordInput
                                errorMessage={AppConstant.login.passwordValidation}
                                showError={passwordError}
                                title={AppConstant.login.newPassword} placeHolder={AppConstant.login.enterHere}
                                onPasswordChange={(e) => setPassword(e)} disabled={verficationCode.length < 5 ? true : false} showInfo={true} passwordInfo={AppConstant.login.recover.passwordInfoTooltip} />
                        </div>
                        <div className='passwordDiv verfication_passwordDiv'>
                            <PasswordInput
                                errorMessage={AppConstant.login.passwordValidation}
                                showError={passwordError}
                                title={AppConstant.login.confirmNewPassword} placeHolder={AppConstant.login.enterHere}
                                onPasswordChange={(e) => setConfirmPassword(e)}
                                disabled={verficationCode.length < 5 ? true : false} />
                        </div>
                        <div className='d-flex justify-content-end loginButtonContainer mt-3'>
                            <CustomButton
                                isLoading={isloading}
                                title={AppConstant.commonStrings.submit} className={"loginButton"}
                                onClick={() => initiateChangePassword()}
                            />
                        </div>
                    </div>
                    <Seperator seperatorStyles={
                        { marginTop: "4%", width: "90%", height: "1px", backgroundColor: "#7E7E7E" }} />
                    <div className='d-flex flex-column justify-content-between align-items-center bottomContainer'>
                        <span className='tryLoginText'>{AppConstant.login.emailFailureTiltle}</span>
                        <div className='w-100 d-flex justify-content-around'>
                        <ClickToResend />
                        <TryLogin />
                        </div>  
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ChangePassword;
