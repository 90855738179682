import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: false,
    data:''
} 

export const GetTaxCodeReducer = createSlice({
    name: 'GetTaxCodeReducer',
    initialState,
    reducers: {
        initiateLoading: (state) => {
          state.loading = true
         },
        addSuccess: (state, action) => {
           state.loading = false;
          state.data = action.payload;
        },
        addFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
        },
        resetData:(state,action)=>{
            state.loading = false;
            state.data = ''; 
        }
    }
});

export const { addSuccess, addFailure,initiateLoading,resetData} = GetTaxCodeReducer.actions;

// Selectors
export const getTaxCodeReducer = (state) => {
    return state.GetTaxCodeReducer;
};

export default GetTaxCodeReducer.reducer;
