import React, { useEffect, useState } from 'react';
import TruckListSection from './TruckListSection/TruckListSection';
import TruckDetailsSection from './TruckDetailsSection/TruckDetailsSection';

import './TruckSection.css';
import { Default, Mobile } from '../../../../Lib/Media';
import { useSelector } from 'react-redux';
import { getTruckSelector } from '../../../../../redux/reducers/reducerSlices/getTruckReducer';

const TruckSection = (props) => {
    const { themeSelected, type } = props;
    const [showList, setShowList] = useState(true);
    const getTruck = useSelector(getTruckSelector);
    const { truckList } = getTruck;
    const [showTruckDetails, setshowTruckDetails] = useState(false)

    useEffect(() => {
        if (truckList && truckList.length > 0) {
            setshowTruckDetails(true)
        } else {
            setshowTruckDetails(false)
        }
    }, [truckList])

    const viewChangeHandler = () => {
        setShowList(!showList)
    }

    const oProps = {
        ...props,
        viewChangeHandler
    }

    return (
        <>
            <Default>
                <div className='row d-flex flex-row po-warpper px-1'>
                    <div className={`col-xs-12 ${showTruckDetails ? "col-sm-5 col-md-6 col-lg-6 px-2" : ""} ${themeSelected}`}>
                        <TruckListSection {...props} />
                    </div>
                    <div className={`col-sm-7 col-md-6 col-lg-6 px-1 ${themeSelected}`}>
                        {showTruckDetails && <TruckDetailsSection {...props} type={ type} />}
                    </div>
                </div>
            </Default>
            <Mobile>
                <div className='row d-flex flex-row po-warpper px-1'>
                    {showList ? <div className={`col-xs-12 px-2 ${themeSelected}`}>
                        <TruckListSection {...oProps} />
                    </div> :
                        <div className={`col-sm-12 px-0 ${themeSelected}`}>
                            {showTruckDetails && <TruckDetailsSection {...props} type={ type} />}
                        </div>}
                </div>
            </Mobile>
        </>

    )
}

export default TruckSection;