import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';
import NoData from '../../../DrillDownReports/Common/Nodata';
import CommonChart from '../../../Graphs/CommonChart';
import './DententionStatus.css';

const DetentionStatus = (props) => {
    const { detentionDonut, detentionLine, themeSelected, showAsWidget } = props;
    const [showDetention, setShowDetentions] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (detentionDonut) {
            const sum = detentionDonut?.series.reduce((a, b) => {
                return a + b
            }, 0)
            if (sum > 0) {
                setShowDetentions(true)
            } else {
                setShowDetentions(false)
            }
        }
    }, [detentionDonut])

    const getSubRoot = () => {
        const status = localStorage.getItem("dententionStatus")
        if (status === "0") {
            navigate('/analytics/reports/detentionstatus/NOT')
        } else if (status === "1") {
            navigate('/analytics/reports/detentionstatus/DETENTION')
        } else if (status === "2") {
            navigate('/analytics/reports/detentionstatus/NEAR')
        }
    }
    return (
        <div className={`col-6 me-2 detentionStatus ${themeSelected} ${showAsWidget ? 'bg-transparent col-12' : ''}`} style={{ marginRight: '10px' }}>
            <div onClick={() => getSubRoot()} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <div className='col-12 pt-3 '>
                    <span className='graphHeading'>{AppConstant.dashboard.defaultdashboard.detentionStatus.heading}</span><br />
                    <span className='subText'>{AppConstant.dashboard.defaultdashboard.detentionStatus.subHeading}</span>
                </div>

                <div className={`d-flex flex-row justify-content-center ${showAsWidget ? 'mt-4 w-100' : ''}`}>
                    {
                        showDetention ? <div className="responsive-chart">{detentionDonut ? <CommonChart data={detentionDonut} /> : ''}</div>
                            : <NoData themeSelected={themeSelected} />
                    }
                    {/* <div className="responsive-chart">{detentionLine ? <CommonChart  data={detentionLine} />: ''}</div> */}
                </div>
            </div>
        </div>
    );
}

export default DetentionStatus;