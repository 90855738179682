import React, { useEffect, useRef, useState } from 'react'
import './NavigationBarUpdated.css'
import { AppConstant } from '../../Assests/AppConstant'
import user from '../../Assests/Images/user-nav.png'
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { sagaActions } from '../../../sagas/sagaActions'
import CustomDropDown from '../Common/CustomDropDown/CustomDropDown'
import _ from 'lodash'
import PrimaryNavigation from './Children/Primary/PrimaryNavigation'
import Popup from '../Common/Popup/Popup'
import SecondaryNavigation from './Children/Secondary/SecondaryNavigation'
import { removeInterceptor } from '../../../redux/api/Interceptor'
import { showAlert } from '../../Assests/Utility'
import { toast } from 'react-toastify'
import SSPopup from '../Common/Popup/SSPopup/SSPopup'
import ModalSS from '../SmartSchedule/SchedulePro/Components/Common/ModalSS/ModalSS'
import AccountsSettingsModalSS from '../SmartSchedule/SchedulePro/Components/AccountsSettingsModalSS/AccountsSettingsModalSS'
import DefaultProfilePic from '../../Assests/Images/defaultProfilePic.png'
import HelpAndFeedbackSSInside from '../Login/Container/MainLogin/SchedulePro/MainLogin/Components/HelpAndFeedbackSS/HelpAndFeedbackSSInside'

export const NavigationBarUpdated = (props) => {

    const { themeSelected } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userPermission = useSelector(getUserSelector);
    const { navigationBarList, userType, parentWarehouse, permissions, branding, brandingSuccess, selectedWarehouse, userAccountDetails } = userPermission;
    const { FirstName, LastName, ProfilePicture, Email ,CarrierType} = userAccountDetails;
    const [isUserSubscribed, setIsUserSubscribed] = useState(true);
    const [pNavList, setPNavList] = useState([])
    const [sNavList, setSNavList] = useState([])
    const [showHelpAndFeedback, setShowHelpAndFeedback] = useState(false);

    const toggleHelpAndFeedbackModal = () => {
        setShowHelpAndFeedback(!showHelpAndFeedback)
      }

    useEffect(() => {
        if (navigationBarList.length) {
            formPSList()
        }
    }, [navigationBarList])

    /**
     * Carrier user disabled when subs is inactive - commented due to WP-2466 - ACME | WP-2579
     */
    // useEffect(() => {
    //     if (userType === AppConstant.userPermission.usertypes.carrier && userAccountDetails.SubscriptionStatus === "INACTIVE") {
    //         setIsUserSubscribed(false)
    //     }
    // }, [isUserSubscribed, userAccountDetails])

    const formPSList = () => {
        const getPList = _.flatten(_.filter(navigationBarList, { NavigationType: 'Primary' }))
        setPNavList([...getPList])
        const getSList = _.flatten(_.filter(navigationBarList, { NavigationType: 'Secondary' }))
        setSNavList([...getSList])
    }

    const clickHandler = (el) => {
        const { PageUrl, PageDesc } = el;
        if (PageDesc === 'Home' && PageUrl === '/warehousemanagement') {
            dispatch({ type: sagaActions.UPDATE_SELECTED_WAREHOUSE, payload: {} })
        }
        else if (PageDesc === 'Home' && PageUrl === '/') {
            dispatch({ type: sagaActions.RESET_USER_PERMISSIONS })
            dispatch({ type: sagaActions.PARENT_WAREHOUSE, payload: {} })
            dispatch({type: sagaActions.CLEAR_SMART_SCHEDULE_REDUCER_DATA})
        }
    }

    const onReportSelected = (event, obj, index) => {
        dispatch({ type: sagaActions.UPDATE_SELECTED_REPORT, payload: obj })
    }

    const getReportsArrayDropDown = (obj, index, dataArr, placeHolder, onItemSelected) => {
        const accessibleReportsArr = dataArr.filter((reportObj, index) => {
            if (reportObj.Permission) {
                return reportObj
            }
        })
        return accessibleReportsArr
        // return <CustomDropDown
        //     themeSelected={'light-mode'}
        //     data={accessibleReportsArr}
        //     titleStyle={'dropDownNavBarTitle'}
        //     placeHolder={placeHolder}
        //     onSelect={(event) => onItemSelected(event, obj, index)}
        //     keyStr={'ElementAccess'}
        //     hideSelectedItem={true} />
    }

    // const displayLogo = () => {
    //     if (location.pathname === '/') {
    //         if (brandingSuccess) {
    //             if (!_.isEmpty(branding) && branding.GroupLogo) {
    //                 return branding.GroupLogo
    //             } else {
    //                 return AppConstant.commonStrings.logo_small_wordMark
    //             }
    //         } else {
    //             return AppConstant.commonStrings.logo_small_wordMark
    //         }
    //     } else {
    //         if (brandingSuccess) {
    //             if (!_.isEmpty(branding) && branding.WarehouseLogo) {
    //                 return branding.WarehouseLogo
    //             } else {
    //                 return AppConstant.commonStrings.logo_small_wordMark
    //             }
    //         } else {
    //             return AppConstant.commonStrings.logo_small_wordMark
    //         }
    //     }

    // }

    //temporary for scheduler pro

    const displayLogo = () => {

        if (location.pathname === '/') {

            if (brandingSuccess) {

                if (!_.isEmpty(branding) && branding.GroupLogo) {

                    return branding.GroupLogo

                } else {

                    return AppConstant.commonStrings.schedulerProLogo

                }

            } else {

                return AppConstant.commonStrings.schedulerProLogo

            }

        } else {

            if (brandingSuccess) {

                if (!_.isEmpty(branding) && branding.WarehouseLogo) {

                    return branding.WarehouseLogo

                } else if (!_.isEmpty(branding) && branding.CompanyLogo) {

                    return branding.CompanyLogo

                } else if (!_.isEmpty(branding) && branding.GroupLogo) {

                    return branding.GroupLogo

                } else {

                    return AppConstant.commonStrings.schedulerProLogo

                }

            } else {

                return AppConstant.commonStrings.schedulerProLogo

            }
        }
    }

    const displayWHLogo=()=>{
        if (selectedWarehouse.WarehouseLogo) {
            return selectedWarehouse.WarehouseLogo
        }
        else{
            return AppConstant.commonStrings.schedulerProLogo
        }
    }

    const setHeaderLogo = () => {

        if (userType === AppConstant.userPermission.usertypes.carrier) {
            return (
                <img src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" className='pointer' onClick={() => navigate("/bookappointments")} />
            )
        } else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims') {
            return (
                <div className='d-flex'>
                    <img src={displayLogo()} alt="Primary Logo" />
                </div>
            )
        }
        else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ss') {
            return (
                <div className='d-flex'>
                    <img src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" className='pointer' onClick={() => navigate("/bookappointments")} />
                </div>
            )
        }
        else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims,ss') {
            return (
                <div className='d-flex'>
                    <img className=' me-3' src={displayLogo()} alt="Primary Logo" />
                    <img className='' src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" />
                </div>
            )
        } else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'imssp') {
            return (
                <div className='d-flex'>
                    <img className=' me-3' src={displayWHLogo()} alt="Primary Logo" />
                </div>
            )
        } else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'sp') {
            return (
                <div className='d-flex'>
                    <img className=' me-3' src={displayWHLogo()} alt="Primary Logo" />
                </div>
            )
        }
         else {
            return (
                <img className='' src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" />
            )
        }

    }

    // const setHeaderLogo = () => {

    //     if (userType === AppConstant.userPermission.usertypes.carrier) {
    //         return (
    //             <img src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" className='pointer' onClick={() => navigate("/bookappointments")} />
    //         )
    //     } else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims') {
    //         return (
    //             <div className='d-flex'>
    //                 <img src={displayLogo()} alt="Primary Logo" />
    //             </div>
    //         )
    //     }
    //     else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ss') {
    //         return (
    //             <div className='d-flex'>
    //                 <img src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" className='pointer' onClick={() => navigate("/bookappointments")} />
    //             </div>
    //         )
    //     }
    //     else if (selectedWarehouse && selectedWarehouse.WHType && selectedWarehouse.WHType.toString().toLowerCase() === 'ims,ss') {
    //         return (
    //             <div className='d-flex'>
    //                 <img className=' me-3' src={displayLogo()} alt="Primary Logo" />
    //                 <img className='' src={AppConstant.commonStrings.schedulerProLogo} alt="Primary Logo" />
    //             </div>
    //         )
    //     } else {
    //         return (
    //             <img className='' src={AppConstant.commonStrings.logo_small_wordMark} alt="Primary Logo" />
    //         )
    //     }

    // }

    const [showSubNav, setShowSubNav] = useState(false);
    const [showCustomSubNav, setShowCustomSubNav] = useState(false);

    const toggleSubNav = () => {
        setShowSubNav(!showSubNav);
    }

    const logoutPopupHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            removeInterceptor()
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
            dispatch({ type: sagaActions.RESET_USER })
            dispatch({type: sagaActions.CLEAR_SMART_SCHEDULE_REDUCER_DATA})
            showAlert(toast.TYPE.INFO, AppConstant.login.logout, 'light')
            window.localStorage.clear();
            navigate("/")
            // If logging out from UserWarehouse Page then trigger branding API again
            if (location.pathname === '/') {
                dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: true })
                const subdomain = window.location.hostname.split(".")[0];
                dispatch({
                    type: sagaActions.INITIATE_BRANDING, payload: {
                        subdomain: subdomain
                    }
                });
            }

        }
    }
    const [showModal, setShowModal] = useState(false);

    const closepopup = () => {
        setShowModal(false);
    }

    const showLogoutPopup = () => {
        return <SSPopup {...AppConstant.settings.logoutPopupAttr} popupBtnHandler={(text) => {
            setShowModal(false);
            logoutPopupHandler(text)
        }} themeSelected={themeSelected} showModal={showModal} closepopup={closepopup} />
    }

    const [showModalSS, setShowModalSS] = useState(false);
    const toggleModalSS = () => {
        setShowModalSS(!showModalSS)
    }
    const [showCommingSoon, setShowCommingSoon] = useState(false);

    const showCommingSoonPopup = () => {
        const popupObj = {
            id: 'commingSoon',
            modalLabel: 'commingSoon',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: 'Live Chat',
            alignType: 'center',
            type: 'info',
            bodyText: AppConstant.commonStrings.commingSoon,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={() => { setShowCommingSoon(false) }} themeSelected={themeSelected} showModal={showCommingSoon} />
    }

    const subMenu = useRef(null)
    const closeOpenMenus = (e) => {
        if (subMenu.current && showSubNav && !subMenu.current.contains(e.target)) {
            setShowSubNav(false)
        }
    }
    document.addEventListener('mousedown', closeOpenMenus)

    return (
        <div className='d-flex flex-column justify-content-between h-100 navbar-web'>
            <div>
                <div className='logo-wrapper'>
                    {setHeaderLogo()}
                </div>
                <PrimaryNavigation pNavList={pNavList} onReportSelected={(e, subEl) => onReportSelected(e, subEl)} getReportsArrayDropDown={(el, index, featureRole, PageDesc, onReportSelected) => getReportsArrayDropDown(el, index, featureRole, PageDesc, onReportSelected)} location={location} showCustomSubNav={showCustomSubNav} setShowCustomSubNav={setShowCustomSubNav} 
                clickHandler={(el) => clickHandler(el)}
                />

            </div>
            <ul className='nav-list py-0'>
                <li onClick={() => { setShowCommingSoon(true) }}>
                    <i className="fa-solid fa-robot"></i> Live Chat
                </li>
                <li className='d-flex justify-content-between align-items-center account-nav pos-rel' onClick={toggleSubNav}>
                    <img src={ProfilePicture || DefaultProfilePic} alt='user' />
                    <div className='ms-2'>
                        <p className='account-nav-head'>{`${FirstName || 'User'} ${LastName}`}</p>
                        <p className='account-nav-sub'>{Email || '-'}</p>
                    </div>
                    <i className="fa-solid fa-angle-right"></i>
                    {showSubNav &&
                        <div className='sub-nav-ss' ref={subMenu}>
                            <SecondaryNavigation sNavList={sNavList} isUserSubscribed={isUserSubscribed} setShowModal={setShowModal} clickHandler={clickHandler} showModalSS={showModalSS} toggleModalSS={toggleModalSS} CarrierType={CarrierType}
                            toggleHelpAndFeedbackModal={toggleHelpAndFeedbackModal} 
                            />
                        </div>
                    }
                </li>
            </ul>
            {showLogoutPopup()}
            {showCommingSoonPopup()}
            {showHelpAndFeedback &&
            <HelpAndFeedbackSSInside toggleModal={toggleHelpAndFeedbackModal}/>
            }
            {
                showModalSS ?
                    <AccountsSettingsModalSS toggleModal={toggleModalSS} /> : ''
            }
        </div >
    )
}
