import React from 'react'
import CreatePOSS from './CreatePoSS';
import CreatePO from './CreatePo';
import _ from 'lodash'
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';

const CreatePoWrapper = (props) => {
    const userPermission = useSelector(getUserSelector);
    const { UserType, RoleName } = userPermission.userAccountDetails;
    const { branding } = userPermission;

    return (
        <>
            {(UserType === 'USER' && RoleName.includes('SSADMIN')) ?
                <CreatePOSS {...props} /> :
                <CreatePO {...props} />
            }
        </>
    )
}

export default CreatePoWrapper