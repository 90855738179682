import React, { useEffect, useState } from 'react'
import "./Login.css"
import { sagaActions } from '../../../../../sagas/sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthReducer, isUserRememberedReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import { isValid } from '../../utils';
import { isNil } from 'lodash-es';
import { encryptString, decryptString, setCookies, createNavBar, formatFirstCharBold, showAlert } from '../../../../Assests/Utility'
import { interceptor, removeInterceptor } from '../../../../../redux/api/Interceptor';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import _ from 'lodash';
import { toast } from 'react-toastify';
import GaurdLogin from './GaurdLogin';
import CryptoJS from "crypto-js";
import { LoginSS } from './SchedulePro/MainLogin/LoginSS';
import LoginIMS from './LoginIMS';
import UserWarehouseSS from '../../../User/UserWarehouseSS';

// Login wrapper component which renders IMS/SS login based in logic below
const Login = (props) => {
    const dispatch = useDispatch()
    const { themeSelected, onThemeChangePass } = props;
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState(false)
    const [emailError, setemailError] = useState(false)
    const [isloading, setisloading] = useState(false)
    const [attemptsCount, setAttemptsCount] = useState(0)
    const user = useSelector(getAuthReducer);
    const { getSignInLoading } = user;
    const userPermission = useSelector(getUserSelector);
    const { userType, permissions, selectedWarehouse, userAccountDetails, error, branding, userWarehouseList, parentWarehouse } = userPermission;
    const {CarrierType} =userAccountDetails;
    const isUserRemembered = useSelector(isUserRememberedReducer);
    const navigate = useNavigate();
    const [warehouseLogo, setWarehouseLogo] = useState('')
    const [bgImage, setBgImage] = useState('');
    const backgroundImage = AppConstant.commonStrings.bkgImgLink;
    const [isGaurd, setIsGaurd] = useState(false);
    // function to decode password
    // 3rd party library - crypto-js used
    const getDecodedPassword = (password) => {
        return CryptoJS.AES.decrypt(password, process.env.REACT_APP_TOKEN_IDENTIFIER).toString(CryptoJS.enc.Utf8);
    }
// Function to handle signin
    const initiateSignIn = () => {
        // set all errors to false
        setLoginError(false)
        setemailError(false)
        // form data for payload

        const data = {
            username: email,
            password: password,
            rememberUser: false
        }
        // if Remember Me is checked
        if (isUserRemembered) {
            rememberUserFunc(data)
        } else {
            // Forgot user function
            forgetUser()
        }
        data.rememberUser = isUserRemembered
        if (data.username && data.password) {
            // dispatch action for signin
            dispatch({
                type: sagaActions.SIGNIN,
                payload: data
            })
        }
        else {
            // Validation alert
            showAlert(toast.TYPE.ERROR, AppConstant.login.bothRequired)
            return
        }

    }

    // if guard login then user should auto sign in
    const autoSignin = () => {
        // setEmail(branding?.email)
        //     setPassword('Wpgaurd@123')
        // Decode password received from server side
        let decodedPassword = getDecodedPassword(branding?.password)
        decodedPassword = decodedPassword.toString()
        const data = {
            username: branding?.email,
            password: decodedPassword,
            rememberUser: false
        }
        // dispatch action for signin - Flow remains same in signin and autosignin
        dispatch({ type: sagaActions.SIGNIN, payload: data })
    }
// function to store encrypted username/password in cookies
    const rememberUserFunc = (data) => {

        const encryptedUsername = encryptString('WHP', data.username, 'username');
        setCookies('username', encryptedUsername, '/');

        const encryptedPassword = encryptString('WHP', data.password, 'password');
        setCookies('password', encryptedPassword, '/');
    }

    /**
     * forgetUser function to remove username and password from localStorage
     */
    const forgetUser = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('password');
    }

    /**
     * Initial useEffect
     */
    useEffect(() => {
        // Commented to resolve Logo issue
        // dispatch({ type: sagaActions.INITIATE_BRANDING_SUCCESS, payload: false })
        setIsGaurd(false)
        if (error) {
            dispatch({ type: sagaActions.UPDATE_USER_ERROR, payload: false });
        }
    }, [])

    /**
     * Branding data useEffect
     */
    useEffect(() => {
        if (!_.isEmpty(branding)) {
            const { IsBranding, WarehouseLogo, BackgroundImage, CompanyLogo, GroupLogo, Subdomain } = branding
            if (IsBranding) {
                setWarehouseLogo(WarehouseLogo ? WarehouseLogo : CompanyLogo ? CompanyLogo : GroupLogo ? GroupLogo : AppConstant.commonStrings.logo_wordMark)
                setBgImage(BackgroundImage)
            }
            if (branding?.isGaurd === 'true') {
                // setIsGaurd(branding?.isGaurd)
                setIsGaurd(true)
            } else {
                setIsGaurd(false)
            }
        }
    }, [branding])

     /**
     * User Auth useEffect
     */
    useEffect(() => {
        if (!isNil(user.auth)) {
            setAttemptsCount(0)
            if (user.auth.challengeName === "NEW_PASSWORD_REQUIRED") {
                if (!_.isEmpty(branding) && branding?.Subdomain?.includes('schedulepro')) {
                    navigate("schedulepro/setPassword");
                } else {
                    navigate("/setPassword");
                }
                return
            }
            // if data has session token
            if (user.sessionToken) {
                // Remove earlier assigned session token
                removeInterceptor();
                // Assign new session token for API request
                interceptor(user.sessionToken);
                // Dispatch action getting user account details
                dispatch({
                    type: sagaActions.GET_USER_ACCOUNT_DETAILS, payload: {
                        cu_id: user?.auth?.username,
                        crud_type: 'S',
                        profile_picture: null
                    }
                })
                // API to update user Last Logon
                dispatch({
                    type: sagaActions.UPDATE_LAST_LOGON, payload: {
                        cu_id: user?.auth?.username,
                    }
                })
            }
        } else if (isUserRemembered) {
            populateData()
        } else {
            setEmail('')
            setPassword('')
        }

    }, [user.auth])

    /**
     * useEffect for Error
     */
    useEffect(() => {
        if (error) {
            showAlert(toast.TYPE.ERROR, AppConstant.userPermission.toast.userAccountDetailsFail)
            dispatch({ type: sagaActions.UPDATE_USER_ERROR, payload: false });
        }
    }, [error])

    /**
     * function if user is not enabled
     */
    const errorUserNotEnabled = () => {
        // Remove earlier assigned session token
        removeInterceptor();
        // Clear user data
        dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER });
        dispatch({ type: sagaActions.RESET_USER })
        // clear local storage
        window.localStorage.clear();
        setTimeout(() => {
            // Reload page
            window.location?.reload()
        },3000)
    }
    /**
     * 
     * @returns if user is Primary user or secondary user
     */
    const stopSecondary=()=>{
        // if (userAccountDetails.UserType === AppConstant.userPermission.usertypes.carrier && userAccountDetails.SubscriptionStatus === "INACTIVE" && CarrierType?.toUpperCase() === AppConstant.user.carrier.type.SECONDARY) {

        if (userAccountDetails.UserType===AppConstant.userPermission.usertypes.carrier && CarrierType?.toUpperCase()===AppConstant.user.carrier.type.SECONDARY && userAccountDetails.EnableStatus === false) {
            return true
        }
        return false
    }

    /**
     * useEffect for userAccountDetails
     */

    useEffect(() => {
        if (!_.isEmpty(userAccountDetails)) {
            if (userAccountDetails.EnableStatus) {
                dispatch({
                    type: sagaActions.GET_USER_TYPE,
                    payload: {
                        cu_id: user?.auth?.username
                    }
                })
            }
            else if (stopSecondary() && !userAccountDetails.EnableStatus) {
                showAlert(toast.TYPE.ERROR, AppConstant.user.carrier.errors.userDeactivated);
                errorUserNotEnabled()
               
            }
             else {
                // User in not Enabled, hence logging out
                showAlert(toast.TYPE.ERROR, AppConstant.userPermission.emptyUserWarehouseList);
                errorUserNotEnabled()
            }

        }
    }, [userAccountDetails])

    // After getting usertype get user permissions
    useEffect(() => {
        const { userPermission } = AppConstant;
        if (user.sessionToken) {
            dispatch({type:sagaActions.GET_CONFIGRATION})
            if (userType === userPermission.usertypes.superAdmin || userType === AppConstant.userPermission.usertypes.administrator || userType === userPermission.usertypes.carrier) {
                dispatchUserPermission()
            } else if (userType === userPermission.usertypes.user) {
                dispatch({ type: sagaActions.INITIATE_LOADING, payload: true })
                dispatch({
                    type: sagaActions.UPDATE_WAREHOUSE_LIST, payload: {
                        user_type: userType,
                        cu_id: user?.auth?.username
                    }
                })

            } else {
                removeInterceptor()
                dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
                dispatch({ type: sagaActions.RESET_USER })
                navigate("/")
                showAlert(toast.TYPE.ERROR, AppConstant.userPermission.toast.userTypeFail);
                window.localStorage.clear();
                return
            }
        }
    }, [userType])


    useEffect(() => {
        if (!_.isEmpty(selectedWarehouse)) {
            dispatchWarehouseUserPermission()
        }
    }, [selectedWarehouse])

    useEffect(() => {
        if (user.sessionToken && !_.isEmpty(permissions)) {
            if (permissions?.message === AppConstant.userPermission.userPermissionNoData) {
                removeInterceptor()
                dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
                dispatch({ type: sagaActions.RESET_USER })
                navigate("/")
                showAlert(toast.TYPE.ERROR, AppConstant.userPermission.toast.userPermissionFail);
                window.localStorage.clear();
                return
            }
            const dynamicNav = createNavBar(permissions, userType, userAccountDetails?.RoleName, userWarehouseList)
            dispatch({ type: sagaActions.UPDATE_NAV_BAR, payload: dynamicNav })
            setisloading(false)
            // WP-2466 - ACME warehouse
            // if (userType === AppConstant.userPermission.usertypes.carrier && userAccountDetails.SubscriptionStatus === "INACTIVE" && CarrierType?.toUpperCase()===AppConstant.user.carrier.type.PRIMARY) {
            //     navigate('/renewcarrsubsparent');
            // }
            // else if (stopSecondary()) {
            //     showAlert(toast.TYPE.ERROR, AppConstant.user.carrier.errors.userDeactivated);
            //     errorUserNotEnabled()
            // }
            //  else {
            //     redirectUser()
            // }
            if (stopSecondary()) {
                showAlert(toast.TYPE.ERROR, AppConstant.user.carrier.errors.userDeactivated);
                errorUserNotEnabled()
            }
             else {
                redirectUser()
            }
        } else {
            // console.log('Access issue: Logout User') 
        }
    }, [permissions])

      /**
     * Function to redirect user
     */
    const redirectUser = () => {
        const page = _.find(permissions, function (x) { return x.PageAccess === true && x.NavigationType === 'Primary' })
        if (!_.isEmpty(page)) {
            navigate(page.PageUrl);
        } else {
            console.log('No permission found');
        }
    }
    /**
     * Function to get permissions - SUPERADMIN/CARRIER
     */
    const dispatchUserPermission = () => {
        dispatch({
            type: sagaActions.GET_USER_PERMISSIONS,
            payload: {
                warehouse_id: null,
                user_type: userType,
                cu_id: user?.auth?.username,
            }
        })
    }

    /**
     * Function to get Warehouse user permissions - USER
     */
    const dispatchWarehouseUserPermission = () => {
        dispatch({
            type: sagaActions.GET_USER_PERMISSIONS,
            payload: {
                warehouse_id: selectedWarehouse?.ID,
                user_type: userType,
                cu_id: user?.auth?.username
            }
        })
    }

    /**
     * Function to populate email and password
     */
    const populateData = () => {
        const getUsername = decryptString('username');
        const getPassword = decryptString('password');
        setEmail(getUsername);
        setPassword(getPassword);
    }

    /**
     * useEffect for signin loader
     */
    useEffect(() => {
        user.getSignInLoading && setisloading(user.getSignInLoading)
    }, [user.getSignInLoading])
    
    /**
     * useEffect for any user related error
     */
    useEffect(() => {
        if (!isNil(user.error)) {
            setAttemptsCount(attemptsCount + 1)
            setLoginError(true)
            setemailError(true)
            dispatch({ type: sagaActions.CLEAR_SIGNIN_ERROR })
        }
    }, [user.error])

    
    /**
     * useEffect for to count signin attempts
     */
    useEffect(() => {
        if (attemptsCount === 3) {
            setAttemptsCount(0)
            navigate("/recover")
        }
    }, [attemptsCount])

    /**
     * Function to handle email change 
     * @param {*} emailId 
     * @returns 
     */
    const onEmailChange = (emailId) => {
        setEmail(emailId)
        if (isValid('email', emailId)) {
            return setemailError(false)
        }
        return setemailError(true)
    }
    /**
     * useEffect for user warehouse list
     */
    useEffect(() => {
        if (_.size(userWarehouseList) && isGaurd) {
            setIsGaurd(false)
        }
    }, [userWarehouseList])

    // Function to handle remember me
    const isRememberMeChecked = (e) => {
        dispatch({ type: sagaActions.REMEMBER_USER, payload: e.target.checked })
    }

    /**
     * 
     * @returns JSX to render on login screen based on userType
     */
    const renderScreen = () => {
        if (!userType) {
            if (!_.isEmpty(branding) && branding?.Subdomain?.includes('schedulepro')) {
                return <LoginSS emailError={emailError} loginError={loginError} email={email ? email : ''} onEmailChange={onEmailChange} password={password ? password : ''} setPassword={setPassword} isRememberMeChecked={(e) => isRememberMeChecked(e)} initiateSignIn={() => initiateSignIn()} {...props} getSignInLoading={getSignInLoading} isUserRemembered={isUserRemembered} />
            } else {
                return <LoginIMS emailError={emailError} loginError={loginError} email={email ? email : ''} onEmailChange={onEmailChange} password={password ? password : ''} setPassword={setPassword} isRememberMeChecked={(e) => isRememberMeChecked(e)} initiateSignIn={() => initiateSignIn()} {...props} getSignInLoading={getSignInLoading} bgImage={bgImage} backgroundImage={backgroundImage} warehouseLogo={warehouseLogo} isUserRemembered={isUserRemembered} branding={branding} />
            }
        }
        else if (userType === AppConstant.userPermission.usertypes.user &&(userAccountDetails?.RoleName).toUpperCase() !== AppConstant.userPermission.adminRoles.ccAdmin) {
            return validateUserWarehouse()
        }
    }
/**
 * 
 * @returns if user has access to 1 warehouse then directly allow to use the application 
 * if user has access to more than 1 warehouse then return JSX to show list of all warehouses
 */
    const validateUserWarehouse = () => {
        if (userWarehouseList.length > 1) {
            return <UserWarehouseSS themeSelected={themeSelected} onThemechange={() => onThemeChangePass()} isloading={isloading} setisloading={setisloading} />
        } else if (userWarehouseList.length === 1) {
            if (userType === AppConstant.userPermission.usertypes.user && _.isEmpty(parentWarehouse)) {
                dispatch({ type: sagaActions.PARENT_WAREHOUSE, payload: userWarehouseList[0] })
            }
            dispatch({ type: sagaActions.UPDATE_SELECTED_WAREHOUSE, payload: userWarehouseList[0] })
        }
    }

    return (
        <>
            {isGaurd ? (
                <GaurdLogin autoSignin={autoSignin} />
            ) : (
                <>{renderScreen()}</>
            )}
        </>

    )
};

export default Login
