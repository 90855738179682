import React, { useEffect } from 'react';
import POHeader from './POHeader/POHeader';
import POScheduled from './POScheduled/POScheduled';
import POUnscheduled from './POUnscheduled/POUnscheduled';

import './POSection.css'
import { useDispatch, useSelector } from 'react-redux';
import { getLoadSelector } from '../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import { shouldEnableDoorAssigment } from './utils';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import POUnscheduledIMSSP from './POUnscheduled/POUnscheduledIMSSP';

const POSection = (props) => {
    const { type } = props;
    const getLoad = useSelector(getLoadSelector);
    const dispatch = useDispatch()
    useEffect(() => {
        if (shouldEnableDoorAssigment(getLoad.selectedLoad)) {
            dispatch({ type: sagaActions.ENABLE_DOOR_SECTION, payload: true })
        } else {
            dispatch({ type: sagaActions.ENABLE_DOOR_SECTION, payload: false })
        }
    }, [getLoad.selectedLoad])
    return (
        <div className='poArropalSectionContainer py-2'>
            <POHeader {...props} />
            <POScheduled {...props} />
            {type === 'IMS' ? <POUnscheduled  {...props} />  : <POUnscheduledIMSSP {...props} />}
        </div>
    );
}

export default POSection;