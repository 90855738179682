import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { ReactComponent as NoDataLightMode } from '../../../../../../Assests/Svg/NoDataLightMode.svg';
import { ReactComponent as NoDataDarkMode } from '../../../../../../Assests/Svg/NoDataDarkMode.svg';
import './DockConflictMainScreen.css';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import ScheduleProWrapper from '../../../../../SmartSchedule/SchedulePro/ScheduleProWrapper';
import DockDoorConflictFormSS from '../DockConflict/DockDoorConflict/DockDoorConflictFormSS';
import FacilityClosureConflictFormSS from '../DockConflict/FacilityClosureConflict/FacilityClosureConflictFormSS'
import BreakConflictFormSS from '../DockConflict/BreaksConflict/BreakConflictFormSS'
import NotSchPosConflictFormSS from '../DockConflict/NotScheduledPOsConflict/NotSchPosConflictFormSS'
import ApptConflictFormSS from '../DockConflict/AppointmentConflict/ApptConflictFormSS'
import { getWhMetaDataReducerSlice } from '../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';

const DockConflictMainScreenSS = (props) => {
    const { themeSelected } = props;
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedButton, setSelectedButton] = useState('Appointment');
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, permissions } = user;
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { dockConflictObj } = whMetaReducer;
    const [allowPermissionApt, setAllowPermissionApt] = useState(false);
    const [allowPermissionDockTimeslot, setAllowPermissionDockTimeslot] = useState(false);
    const [allowPermissionFcClosure, setAllowPermissionFcClosure] = useState(false);
    const [allowPermissionBreaks, setAllowPermissionBreaks] = useState(false);
    const [allowPermissionNPs, setAllowPermissionNPs] = useState(false);
    const [getDockPayload, setDockPayload] = useState({});
 
    /**
     * used to set the default selected button based on the list data availability, while the initial screen hit
     */
    useEffect(() => {
        if (dockConflictObj.appointments.length) {
            setSelectedButton('Appointment');
        }
        else if (dockConflictObj.timeSlots.length) {
            setSelectedButton('TimeSlot');
        }
        else if (dockConflictObj.leaves.length) {
            setSelectedButton('Leaves');
        }
        else if (dockConflictObj.breaks.length) {
            setSelectedButton('Breaks');
        }
        else if (dockConflictObj.pos.length) {
            setSelectedButton('NotScheduledPOs');
        }
    }, [])

    /**
     * Used to set the buttons which have data only
     */
    useEffect(() => {
        if (dockConflictObj) {
            if (dockConflictObj.appointments.length) {
                setAllowPermissionApt(true);
            }
            if (dockConflictObj.timeSlots.length) {
                setAllowPermissionDockTimeslot(true);
            }
            if (dockConflictObj.leaves.length) {
                setAllowPermissionFcClosure(true);
            }
            if (dockConflictObj.breaks.length) {
                setAllowPermissionBreaks(true);
            }
            if (dockConflictObj.pos.length) {
                setAllowPermissionNPs(true);
            }
        }
    }, [dockConflictObj])


    useEffect(() => {
        if (location.state.dockObj) {
            setDockPayload(location.state.dockObj);
        }
    }, [location.state])

    useEffect(() => {
        showCurrentReport()
    }, [selectedButton])

    const onClickBack = () => {
        navigate('/whmetadata');
    }

    /**
     * function used to show the titles list
     * @returns 
     */
    const showTilesList = () => {
        return (

            <div
                className="d-flex justify-content-start"
                style={{ borderRadius: "10px" }}
            >
                <CustomButtonSS
                    disabled={false}
                    className={`no-border no-bg cb-p`}
                    onClick={() => { onClickBack() }}
                    isIcon={true}
                    iconClass={"fa fa-arrow-left"}
                />
                {allowPermissionApt && <button
                    onClick={() => { setSelectedButton('Appointment') }}
                    className={selectedButton === 'Appointment' ? "selected-button" : "unselected-button"}
                    disabled={allowPermissionApt ? '' : 'disabled'}
                >
                    {'Appointment'}
                </button>}
                {allowPermissionDockTimeslot && <button
                    onClick={() => { setSelectedButton('TimeSlot') }}
                    className={selectedButton === 'TimeSlot' ? "selected-button" : "unselected-button"}
                    disabled={allowPermissionDockTimeslot ? '' : 'disabled'}
                >
                    {'Timeslot'}
                </button>}
                {allowPermissionFcClosure && <button
                    onClick={() => { setSelectedButton('Leaves') }}
                    className={selectedButton === 'Leaves' ? "selected-button" : "unselected-button"}
                    disabled={allowPermissionFcClosure ? '' : 'disabled'}
                >
                    {'Facility Closures'}
                </button>}
                {allowPermissionBreaks && <button
                    onClick={() => { setSelectedButton('Breaks') }}
                    className={selectedButton === 'Breaks' ? "selected-button" : "unselected-button"}
                    disabled={allowPermissionBreaks ? '' : 'disabled'}
                >
                    {'Breaks'}
                </button>}
                {allowPermissionNPs && <button
                    onClick={() => { setSelectedButton('NotScheduledPOs') }}
                    className={selectedButton === 'NotScheduledPOs' ? "selected-button" : "unselected-button"}
                    disabled={allowPermissionNPs ? '' : 'disabled'}
                >
                    {'Not Scheduled POs'}
                </button>}
            </div>


        )
    }

    /**
     * function used to show no data, when there is no data
     * @returns 
     */
    const showNoDataError = () => {
        return (
            <div className=' d-flex justify-content-center'>
                <div className='flex-column my-5'>
                    <div className='no-data-svg'>{themeSelected === 'light-mode' ? <NoDataLightMode /> : <NoDataDarkMode />}</div>
                    <div className={`no-data-text1 ${themeSelected} graphHeading text-center`}>{AppConstant.reports.selectReportTitleString}</div>
                </div>
            </div>
        )
    }

    /**
     * Function used to open the component based on the click
     * @returns 
     */
    const showCurrentReport = () => {
        if ((dockConflictObj)) {
            switch (selectedButton) {
                case "Appointment":
                    return (<ApptConflictFormSS {...props} dockPayload={getDockPayload} />);
                case "TimeSlot":
                    return (<DockDoorConflictFormSS {...props} dockPayload={getDockPayload} />);
                case "Leaves":
                    return (<FacilityClosureConflictFormSS {...props} dockPayload={getDockPayload} />)
                case "Breaks":
                    return (<BreakConflictFormSS {...props} dockPayload={getDockPayload} />)
                case "NotScheduledPOs":
                    return (<NotSchPosConflictFormSS {...props} dockPayload={getDockPayload} />)
                default:
                    return showNoDataError();
            }
        } else {
            showNoDataError()
        }
    }

    /**
     * function returns the dock conflict obj status
     * @returns 
     */
    const getDockConflictObjStatus = () => {
        const allEmpty = Object.values(dockConflictObj).every(array => array.length === 0);
        if (allEmpty) {
            return true;
        } else {
            return false
        }

    }

    return (
        <ScheduleProWrapper>
            <div>
                <div className=' warehouseMeta-wrapper-ss p-3'>
                    <div className='subscriptions-ss-heading my-2 f-16 fw-600'>
                        Manage Dock Deletion
                    </div>
                    {!getDockConflictObjStatus() ? <div className="mb-4">
                        {showTilesList()}
                    </div> :
                        <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center gap-3'>
                            <p className='text-center'><strong className='f-16 fw-bold my-3'>All disputs resolved</strong>
                                <br />
                                <br />
                                <p className='text-center'>
                                    You can delete now.
                                </p>
                            </p>
                            <br />
                            <div className='d-flex justify-content-center'>
                                <CustomButtonSS
                                    isLoading={false}
                                    title={"Proceed"}
                                    className={"btn-highlighted f-14"}
                                    onClick={() => { navigate("/whmetadata") }}
                                    isIcon={false}
                                />
                            </div>
                        </div>
                    }
                    {showCurrentReport()}
                </div>
            </div>
        </ScheduleProWrapper>
    )
}

export default DockConflictMainScreenSS;