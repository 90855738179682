import React from "react";
import Header from "../../../../Header/Header";
import Navigationbar from "../../../../Navigationbar/Navigationbar";
import FaqAdminScreen from './Components/FaqAdminScreen/FaqAdminScreen';

const FaqAdmin = (props) => {

    return(
        <div>
            <Header />
            <Navigationbar />
            <FaqAdminScreen {...props}/>
        </div>
    )
}

export default FaqAdmin;