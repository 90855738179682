import {Uchain} from "../redux/api/agent";
import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { addSuccess, addFailure,initiateLoading,resetData } from '../redux/reducers/reducerSlices/GetTaxCodeReducer';
  function* getTaxCodeRequestHandler(data){
    try{
      yield put(initiateLoading());
     const response = yield call(Uchain.getTaxCodeDetails, data.payload);
      yield put(addSuccess(response));
    }catch(error){
     yield put(addFailure(error));
    }
}
export function*  GetTaxCodeRequest() {
  yield takeEvery(sagaActions.GET_TAX_CODE_DATA, getTaxCodeRequestHandler)
}

function* resetFormHandler() {
  yield put(resetData())
  
 }
 
 export function* resetTaxFormRequest() {
     yield takeEvery(sagaActions.RESET_TAX_CODE_DATA, resetFormHandler)
   }
   

