import React from 'react';
import {ReactComponent as AllDoorsFilterIcon } from '../../../Assests/Svg/all-doors-filters.svg';
import {ReactComponent as AvailableDoorsFilterIcon } from '../../../Assests/Svg/available-door-filters.svg';
import {ReactComponent as BlockedDoorsFilterIcon } from '../../../Assests/Svg/blocked-door-filters.svg';
import {ReactComponent as InOperableDoorsFilterIcon } from '../../../Assests/Svg/in-operable-door-filters.svg';
import {ReactComponent as InUseDoorsFitlerIcon } from '../../../Assests/Svg/in-use-door-filters.svg';
import {ReactComponent as SelectDoorsFilterIcon } from '../../../Assests/Svg/select-door-filters-icon.svg';
import {AppConstant} from '../../../Assests/AppConstant';
import './IconButtons.css';
/**
 * This is a reusable component that renders filter  icon and title for IMS doors
 * @param {*} props 
 * title = filter title
 * onClickHandler = filter select handler function
 * selectedDoorFilter = selected door
 * @returns 
 */
const IconButtons = (props) => {
    const {title, themeSelected, onClickHandler, selectedDoorFilter} = props;
    const appConstantPath = AppConstant.doorManagement.doorFilters
    /**
     * On filter select handler function
     * @param {*} e 
     */
    const selectFilter = (e) => {
        onClickHandler(e.target.innerHTML);
    }

    const selectedFilter = (e) => {
        onClickHandler(e);
    }
    /**
     * 
     * @returns Selected filter icon handler function
     */
    const setFilterIcon = () => {
        switch (title){
            case appConstantPath.door_filter_all:
                return <AllDoorsFilterIcon />
            case appConstantPath.door_filter_available:
                return <AvailableDoorsFilterIcon />
            case appConstantPath.door_filter_blocked:
                return <BlockedDoorsFilterIcon />
            case appConstantPath.door_filter_in_use:
                return <InUseDoorsFitlerIcon />
            case appConstantPath.door_filter_inoperable:
                return <InOperableDoorsFilterIcon />
            default:
                return ''
        }
    }

    return (
        <div>
            <div className="form-check buttonIcon" data-testid='icon_buttons'>
                <span className='d-flex' onClick={(e) => selectFilter(e)} data-testid='select_icon_filters'>
                    <div className="">
                        <div className="position-relative">
                            {setFilterIcon()}
                            {
                                selectedDoorFilter === title ? 
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    {selectedFilter(title)}
                                    <SelectDoorsFilterIcon />
                                </div> : ''
                            }
                            
                        </div>
                    </div>
                    
                    <label className="form-check-label ms-2" htmlFor="flexRadioDefault1" >
                        {title}
                    </label>
                </span>
            </div>
        </div>
    )
}

export default IconButtons;