import React, { useEffect, useState } from 'react';
import ScheduleProWrapper from '../../ScheduleProWrapper';
import '../UsersSS/UserWrapperSS.css'
import SPUserList from './Children/SPUserList';

/** This component is a wrapper Component render that Schedule Pro users */
const SPUserWrapper = (props) => {
    const { themeSelected } = props
    return (
        <ScheduleProWrapper>
            <div className='container-fluid user-ss-wrapper p-3'>
                <SPUserList themeSelected={themeSelected} />
            </div>
        </ScheduleProWrapper>
    )
}

export default SPUserWrapper;