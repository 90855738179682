import React, { useEffect, useState } from 'react'
import _ from 'lodash-es'
import './DashBoardSSWH.css'
import { SSBanner } from '../../SSBanner/SSBanner'
import { SSAppointmentSearch } from '../../SSAppointmentSearch/SSAppointmentSearch'
import { SSAppointmentTracker } from '../../SSAppointmentTracker/SSAppointmentTracker'
import { Tutorial } from '../../Tutorial/Tutorial'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer'
import { useSelector } from 'react-redux'
import { smartScheduleSelector } from '../../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer'
import { sagaActions } from '../../../../../../../../../sagas/sagaActions'  
import { SSCarrierApptListScreen } from '../../SSCarrierApptListScreen/SSCarrierApptListScreen'
import ScheduleProWrapper from '../../../../../ScheduleProWrapper'
import { createChartsProps, donutType } from '../../../../../../../../Assests/DashboardUtility'
import { appointmentTrackerDonut } from '../../../../../../../../Assests/GraphUtilities'
import moment from 'moment'

export const DashBoardSSWH = (props) => {
    const { themeSelected } = props;
    const [issearched, setIsSearched] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userPermission = useSelector(getUserSelector);
    const { userType, selectedWarehouse, cu_id, userAccountDetails } = userPermission;
    const ss = useSelector(smartScheduleSelector);
    const { initateLoading, searchedPOData, poForAppt, appointmentTrackerData, appointmentTrackerDataError, searchedPOErrorData, appointmentConfirmation } = ss;
    const [didSearched, setDidSearched] = useState(false);
    const [po_number, setPo_number] = useState(null);
    const [receivingZipcode, setReceivingZipcode] = useState(null);
    const [scheduleScreenType, setScheduleScreenType] = useState('');
    const [searchPoDataState, setSearchPoDataState] = useState(null);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [apptTrackerDonutData, setApptTrackerDonutData] = useState({})
    const [trackerBtnType, setTrackerBtnType] = useState(null)
    const toggleIsSearched = () => {
        setIsSearched(!issearched)
    }

    useEffect(() => {
        setDidSearched(false)
        setShowHelpModal(false);
        setShowSuccess(false)
        if (appointmentConfirmation) {
            dispatch({
                type: sagaActions.ADD_APPOINTMENT_CONFIRMATION,
                payload: false,
              });
        }
        dispatch({ type: sagaActions.CLEAR_APPOINTMENT_TRACKER })
        dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false })
        setTrackerBtnType({
            date: moment().format('MM/YYYY'),
            type: 'C'
        })
        if (userType === 'USER') {
            setReceivingZipcode(selectedWarehouse?.Zip)
        }

        // Moved to location useEffect
        // dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO });
        // Commented due API's firing 2 times on load(depends on trackerBtnType)
        // dispatch({ type: sagaActions.GET_APPOINTMENT_TRACKER, payload: { Date: trackerBtnType?.date, CUID: cu_id } })
        return () => {
            dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO });
            dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO_ERROR, payload: null });
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(appointmentTrackerData)) {
            const dataDonut = [appointmentTrackerData?.ScheduledCount || 0, appointmentTrackerData?.CompletedCount || 0]
            const apptTrackerData = createChartsProps(dataDonut, donutType.appointmentTrackerDonut, themeSelected, appointmentTrackerDonut)
            setApptTrackerDonutData({ ...apptTrackerData })
        }
    }, [appointmentTrackerData])

    useEffect(() => {
        if (trackerBtnType?.type) {
            dispatch({ type: sagaActions.GET_APPOINTMENT_TRACKER, payload: { Date: trackerBtnType?.date, ID: userType === 'CARRIER' ? userAccountDetails?.IDCarrier : selectedWarehouse?.ID, Type: userType } })
        }
    }, [trackerBtnType])

    useEffect(() => {
        dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: false });
        if (_.size(searchedPOData)) {
            setSearchPoDataState([...searchedPOData])
            setPo_number(searchedPOData[0].PoNumber)
        } else {
            // Clear state PO and ReceivingZipCode when searchedPOData has No Data
            setPo_number('')
            if (userType === 'USER') {
                setReceivingZipcode(selectedWarehouse?.Zip)
            } else {
                setReceivingZipcode('')
            }
        }
    }, [searchedPOData])

    useEffect(() => {
        if (location.state) {
            const { type } = location.state;
            if (type === 'rescheduling') {
                setDidSearched(true)
                navigate(location.pathname);
                if (_.size(searchedPOData)) {
                    const masterPO = searchedPOData[0];
                    const { PoNumber, ReceivingZipCode } = masterPO;
                    setPo_number(PoNumber);
                    setReceivingZipcode(ReceivingZipCode)
                    // Clear type after popup opens from MyAppointment
                    navigate({ state: { type: null } });
                }
            } else {
                dispatch({ type: sagaActions.CLEAR_SS_SEARCH_PO });
            }
        }
    }, [location.state])

    useEffect(() => {

        if (showSuccess && po_number && receivingZipcode) {
            dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: po_number, ReceivingZipCode: receivingZipcode, UserType: userType, ID: userType === 'CARRIER' ? userAccountDetails?.IDCarrier : selectedWarehouse?.ID } })
        }
    }, [showSuccess])
    const onEmailChange = (e, type) => {
        if (type === 'po') {
            setPo_number(e)
        } else if (type === 'zipcode') {
            setReceivingZipcode(e)
        }
    }

    const displayAppointmentsList = () => {
        setSearchPoDataState([])
        // dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO, payload: [] })
        // dispatch({ type: sagaActions.SET_SS_SELECTED_PO_FOR_APPT, payload: [] })
        if (po_number && receivingZipcode) {
            setDidSearched(true)
            dispatch({ type: sagaActions.INTIATE_SS_LOADER, payload: true })
            dispatch({ type: sagaActions.INTIATE_SS_SEARCH_PO, payload: { PoNumber: po_number, ReceivingZipCode: receivingZipcode, UserType: userType, ID: userType === 'CARRIER' ? userAccountDetails?.IDCarrier : selectedWarehouse?.ID } })
        } else {
            dispatch({ type: sagaActions.UPDATE_SS_SEARCH_PO_ERROR, payload: '* Both PO and Zip Code are mandatory' })
            // showAlert(toast.TYPE.WARNING, 'Both PO and Zip Code are mandatory', 'light')
        }
        dispatch({type: sagaActions.CLEAR_UPLOAD_APPT_DOCS})
    }
    const trackerBtnHandler = (e, el) => {
        let date = moment().format('MM/YYYY')
        switch (el?.type) {
            case "U":
                date = null
                break;
            case "P":
                const previousMonthDate = moment().subtract(1, 'months');
                const formattedDate = previousMonthDate.format('MM/YYYY');
                date = formattedDate;
                break;
            default:
                date = moment().format('MM/YYYY');
                break;
        }
        setTrackerBtnType({
            date: date,
            type: el?.type
        })
    }
    return (
        <ScheduleProWrapper showAppointments={true} >
            <div className='d-flex gap-2'>
                <div className='d-flex flex-column gap-2' data-testid="dashboardSSWH">
                    <SSBanner showApptSearch={issearched} />
                    <div className='d-flex gap-2 appt-container w-100'>
                        {searchedPOData && !_.size(searchedPOData) ?
                            <>
                                <div className='d-flex flex-column gap-2 book-appt-ss'>
                                    <SSAppointmentSearch toggleIsSearched={toggleIsSearched}
                                        {...props} searchAppointment={() => displayAppointmentsList()}
                                        onEmailChange={(e, type) => onEmailChange(e, type)} initateLoading={initateLoading}
                                        scheduleScreenType={scheduleScreenType} setScheduleScreenType={setScheduleScreenType}
                                        showPdfHelperPopup={() => setShowHelpModal(true)}
                                        userType={userType} po_number={po_number} receivingZipcode={receivingZipcode} searchedPOErrorData={searchedPOErrorData}
                                    />

                                    <Tutorial {...props}  userType={ userType} />
                                </div>
                                <SSAppointmentTracker appointmentTrackerData={appointmentTrackerData} apptTrackerDonutData={apptTrackerDonutData} appointmentTrackerDataError={appointmentTrackerDataError} trackerBtnHandler={(e, el) => trackerBtnHandler(e, el)} {...props} />

                                {/* {!_.isEmpty(appointmentTrackerData) ? <SSAppointmentTracker appointmentTrackerData={appointmentTrackerData} apptTrackerDonutData={apptTrackerDonutData} appointmentTrackerDataError={appointmentTrackerDataError} trackerBtnHandler={(e, el) => trackerBtnHandler(e, el)} /> : appointmentTrackerDataError ? <NoDataTracker /> : displayLoader(themeSelected, 60, 1)} */}
                            </> : <SSCarrierApptListScreen data={searchPoDataState} scheduleScreenType={scheduleScreenType} setScheduleScreenType={setScheduleScreenType} screenTypeClassname='ssappointmentListTable-bookappt' setShowSuccess={setShowSuccess} showSuccess={showSuccess} poForAppt={poForAppt} />
                        }
                    </div>
                </div>
            </div>
        </ScheduleProWrapper>
    )
}
