import React from "react";
import {ReactComponent as PhoneDialer} from "../../../../Assests/Svg/phone-dialer.svg"
import {ReactComponent as Closemodal} from "../../../../Assests/Svg/times-close.svg";
import HelpAndFeedbackCoponent from "../../components/helpAndFeedbackComponent";
import { AppConstant } from "../../../../Assests/AppConstant";
import "./help.css"
/**
 * This component render from the HELP feature on login screen, user can fill the help form
 * @param {*} param0 
 * @returns 
 */
const HelpContainer = ({formName, closeHelpModal }) => {
  return (
    <>
      <div className="marrgt-15 marlft-15 mobil-margin">
        <div className="display-inline margin-bottom-hero-text">
           <span className="hero-text">{formName===AppConstant.help.formName ? AppConstant.help.titleTextForHelp:AppConstant.help.titleTextForFeedback}</span>
          <span className="cursor-pointer" onClick={() => closeHelpModal()}>
           <Closemodal/>
           </span>
           
        </div>
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <div className="cust-support">
              <div className="support margin-bottom-equal">{AppConstant.help.heroTextForTechnicalSupport}</div>
              <div className="margin-bottom-equal">
                <i className="fa fa-envelope-o  marrgt-10" aria-hidden="true"></i>
                <a  className="support-details" href={`mailTo:${AppConstant.help.helpEmailCustAndTech}`}>
                {AppConstant.help.helpEmailCustAndTech}
                </a>
              </div>
              <div className="margin-bottom-equal">
              <PhoneDialer/>
            <a className="support-details marlft-10" href={`tel:${AppConstant.help.helpPhoneCustAndTech}`}>{AppConstant.help.helpPhoneCustAndTech}</a>
              </div>
            </div>
            <hr className="horizontal-ruler"/>
            <div className="tech-support">
              <div className="support margin-bottom-equal">{AppConstant.help.heroTextForApplicationFeedback}</div>
              <div className="margin-bottom-equal">
                <i className="fa fa-envelope-o marrgt-10" aria-hidden="true"></i>
                <a className="support-details"href={`mailTo:${AppConstant.help.helpEmailApplicationFeedback}`} >
                {AppConstant.help.helpEmailApplicationFeedback}  
                </a>
              </div>
              <div className="margin-bottom-equal">
              <PhoneDialer/>
                <a  className="support-details marlft-10" href={`tel:${AppConstant.help.helpPhoneApplicationFeedback}`}>  {AppConstant.help.helpPhoneApplicationFeedback}</a>
              </div>
            </div>
          </div>
          <div className="col-md-1 vb"></div>
          <div className="col-md-6 col-sm-12">
              <HelpAndFeedbackCoponent formName={formName} closeHelpModal={closeHelpModal}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpContainer;
