import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    arrangeOrder: 'asc',
    arrangeKey: 'CreatedOn',
    getEclipseOpsPaymentData: null,
    EclipseOpsPaymentList:[],
    isLoading: false,
    initiateLoading:false,
    LastEvaluatedKey:null,
    LastPage:false,
    eclipseServiceFee:null,
    btnLoading:false,
    manualPaymentMethodsList:[],
    fsPayPOHistoryList: []
}

export const EclipseOpsPaymentLoadReducer = createSlice({
    name: 'eclipseOpsPaymentLoadReducer',
    initialState,
    reducers: {
        getEclipseOpsPaymentSuccess: (state, action) => {
            state.isLoading = true;
            state.getEclipseOpsPaymentData = action.payload;
            state.initiateLoading = false;
        },
        getEclipseOpsPaymentFailure: (state, action) => {
            state.isLoading = false;
            state.getEclipseOpsPaymentData = null;
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        updateInitiateLoading: (state, action) => {
            state.initiateLoading = action.payload
        },
        updateEclipseOpsPaymentList: (state , action) => {
            state.EclipseOpsPaymentList.push(...action.payload)
            state.isLoading=false
        },
        clearEclipseOpsPaymentList: (state , action) => {
            state.EclipseOpsPaymentList=[]
        },
        updateLastEvaluatedKey: (state,action)=>{
            state.LastEvaluatedKey=action.payload
        },
        updateLastPage: (state,action)=>{
            state.LastPage=action.payload
        },
        updateRealTimePaymentData: ( state ,action) => {
            state.EclipseOpsPaymentList=[action.payload,...state.EclipseOpsPaymentList.filter(item => item.RecID !== action.payload.RecID)]
        },
        updateEclipseServiceFee: (state,action) =>{
            state.eclipseServiceFee=action.payload
        },
        updateBtnLoading: (state,action) =>{
            state.btnLoading=action.payload
        },
        updateManualPaymentMethodsList: (state,action) =>{
            state.manualPaymentMethodsList=action.payload;
        },
        updatedFsPayPOHistory: (state, action) => {
            state.isLoading = false;
            state.fsPayPOHistoryList = action.payload;
        },
    }
});

export const { getEclipseOpsPaymentSuccess, updateIsLoading, getEclipseOpsPaymentFailure, updateInitiateLoading,updateEclipseOpsPaymentList,updateLastEvaluatedKey,clearEclipseOpsPaymentList,updateLastPage,updateRealTimePaymentData,updateEclipseServiceFee,updateBtnLoading ,updateManualPaymentMethodsList, updatedFsPayPOHistory} = EclipseOpsPaymentLoadReducer.actions;

// Selectors
export const getEclipseOpsPaymentLoadselector = (state) => {
    return state.eclipseOpsPaymentLoadReducer;
};

export default EclipseOpsPaymentLoadReducer.reducer;