import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../../Header/Header';
import Navigationbar from '../../../Navigationbar/Navigationbar';
import CustomButton from '../../../Common/CustomButton/CustomButton';
import Popup from '../../../Common/Popup/Popup';
import _ from 'lodash-es';
import SsPOList from './SsPOList/SsPOList';
import WarehouseSearch from '../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import { changeDateToGivenFormat, getCurrentDate } from '../../../../../Utils/DateUtils';
import { CustomDynamicForm } from '../../../Common/CustomForm/CustomDynamicForm';
import { AppConstant } from '../../../../Assests/AppConstant';
import { ReactComponent as DownloadIconLight } from '../../../../Assests/Svg/download-icon-light.svg';
import { ReactComponent as DownloadIconDark } from '../../../../Assests/Svg/download-icon-dark.svg';
import { sagaActions } from '../../../../../sagas/sagaActions'
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { getPoSSselector } from '../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import { fetchPoListCommonFunc } from './Common/FetchPOList';
import { changeFormatWithoutTz, showAlert } from '../../../../Assests/Utility';
import html2pdf from 'html2pdf.js';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { fetchDockListCommonFunc } from '../WarehouseMetaData/Forms/DocksMeta/Common/FetchDockList';
import './CreatePo.css';
import { getWhMetaDataReducerSlice } from '../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import moment from 'moment-timezone';

const CreatePO = (props) => {
    const dispatch = useDispatch();
    const { themeSelected } = props;
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [editData, setEditData] = useState({});
    const [resetFormData, setResetFormData] = useState(0);
    const [selectedDate, setSelectedDate] = useState('');
    const [searchStr, setSearchStr] = useState('');
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id } = userPermission;
    moment.tz.setDefault(selectedWarehouse?.TimeZone); // Set Warehouse Timezone
    const [dockListArr, setDockListArr] = useState([]);
    const divRef = useRef(null);
    const pOSS = useSelector(getPoSSselector);
    const [particularFieldValue, setParticularFieldValue] = useState({});
    const [currentTable, setCurrentTable] = useState(null);
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const { whMetaDocksList } = whMetaData;
    const { poSSArr, operationObj } = pOSS;


    useEffect(() => {
        const container = divRef.current;
        setCurrentTable(container.children[0].children[0].children[0]);
    })

    const downloadPdfData = () => {
        const table = currentTable;
        const columnsToDownload = [0, 1, 2, 3, 4, 5, 6];
        const allColumns = currentTable.querySelectorAll('tr');
        allColumns.forEach((row) => {
            Array.from(row.cells).forEach((cell, index) => {
                if (!columnsToDownload.includes(index)) {
                    cell.style.display = 'none';
                }
            });
        });

        html2pdf()
            .set({
                margin: 10,
                filename: 'PO List.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { dpi: 192, letterRendering: true },
                jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
            })
            .from(currentTable)
            .save();
        setTimeout(() => {
            allColumns.forEach((row) => {
                Array.from(row.cells).forEach((cell) => {
                    cell.style.display = '';
                });
            });
        }, [100])
    }

    const downloadExcelData = () => {
        const columnsToDownload = [0, 1, 2, 3, 4, 5, 6];
        const filteredTable = Array.from(currentTable.rows).map(row => {
            const filteredCells = Array.from(row.cells)
                .filter((cell, index) => columnsToDownload.includes(index))
                .map(cell => cell.innerText);
            return filteredCells;
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(filteredTable);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
        const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelData, 'PO List.xlsx');
    };

    useEffect(() => {
        setLoading(false);
        setShowModal(false);
        setDockListArr([]);
        setTimeout(() => {
            fetchDockList()
        }, [1000])
    }, [])

    const fetchDockList = () => {
        const { apiParams, operationObj } = fetchDockListCommonFunc(selectedWarehouse);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    };

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Create PO':
                    setLoading(false);
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.poSection.createPoSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.poSection.createPoFailure + `: ${operationObj.message}`);
                    }
                    break;
                case 'Edit PO':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.poSection.updatePoSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.poSection.updatePoFailure + `: ${operationObj.message}`);
                    }
                    break;
                case 'Upload PO':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.poSection.uploadPoSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.poSection.uploadPoFailure + `: ${operationObj.message}`);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    const handleApiResponse = (result, message) => {
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            fetchPoList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }

    useEffect(() => {
        if (operationObj && operationObj.type && operationObj.type === 'Fetch POs') {
            if (poSSArr && poSSArr.length) {
                setLoading(false);
                setShowModal(false)
            }
        }
    }, [poSSArr])

    const fetchPoList = () => {
        const { apiParams, operationObj } = fetchPoListCommonFunc(selectedWarehouse);
        dispatch({ type: sagaActions.UPDATE_PO_LIST, payload: apiParams, operationObj: operationObj });
    }

    const editPO = (data, index) => {
        const selectedDock = (_.find(dockListArr, { ID: data.IDDock }));
        const editData1 = {
            "PoNumber": data.PoNumber,
            "OrderNumber": data.OrderNumber,
            "VendorName": data.VendorName,
            "BuyerName": data.BuyerName,
            "Commodity": data.Commodity,
            "Cases": data.ItemsCases,
            "Pallets": data.Pallets,
            "Weight": data.Weight,
            "Quantity": data.Quantity,
            "ProduceType": selectedDock.ID,
            "custom-date-picker": moment(data.DueDate).format('YYYY-MM-DD'),
            "ZipCode": data.ReceivingZipCode,
            "Remarks": data.Remarks,
            "PoCreatedOn": data.PoCreatedOn,
            "PoCreatedBy": data.PoCreatedBy,
            "WarehouseId": data.IDWarehouse,
            "IDPO": data.IDPO,
            "PoStatus": data.PoStatus,
            "Door": data.Door,
        }

        setShowModal(true);
        setEditData({ ...editData1 });
        setParticularFieldValue({ ...editData1 });
    }

    const getDocksList = () => {
        const docksList = whMetaDocksList && whMetaDocksList.length && whMetaDocksList.map((ele, index) => {
            if (ele && ele.DockStatus) {
                return (
                    <option key={ele.Name} value={ele.ID}>
                        {ele.Name}
                    </option>
                );
            } else {
                return null;
            }
        })
        return _.compact(docksList);
    }

    useEffect(() => {
        if (whMetaDocksList && whMetaDocksList.length) {
            setDockListArr(whMetaDocksList)
        }
    }, [whMetaDocksList])

    const TUSCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="customDatePickerInput" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const selectedDateValue = () => {
        return changeDateToGivenFormat(getCurrentDate(), AppConstant.commonStrings.MM_DD_YYYY_dateFormat)
    }

    const getFormFieldData = (data, name) => {
        if (name === 'custom-date-picker') {
            setSelectedDate(changeDateToGivenFormat(data, AppConstant.commonStrings.MM_DD_YYYY_dateFormat))
        }
    };

    const formFieldList = [
        {
            name: "PoNumber",
            type: "text",
            size: "col-sm-6",
            position: 1,
            placeholder: '',
            title: AppConstant.smartScheduler.poSection.poNumTitle,
            formValidation: {
                required: true,
            },
            disabledField: Object.keys(editData).length ? true : false
        },
        {
            name: "OrderNumber",
            type: "text",
            size: "col-sm-6",
            position: 2,
            title: AppConstant.smartScheduler.poSection.orderNumTitle,
            formValidation: {
                required: false,
            }
        },
        {
            name: "VendorName",
            type: "text",
            size: "col-sm-6",
            position: 3,
            title: AppConstant.loadManagement.purchaseOrderDetails.vendor_name,
            formValidation: {
                required: true,
            }
        },
        {
            name: "BuyerName",
            type: "text",
            size: "col-sm-6",
            position: 4,
            title: AppConstant.smartScheduler.poSection.buyerNameTitle,
            formValidation: {
                required: false,
            }
        },
        {
            name: "Commodity",
            type: "text",
            size: "col-sm-6",
            position: 7,
            title: AppConstant.smartScheduler.poSection.commodity,
            formValidation: {
                required: true,
            }
        },
        {
            name: "Pallets",
            type: "text",
            size: "col-sm-6",
            position: 14,
            title: AppConstant.smartScheduler.poSection.palletsCount,
            formValidation: {
                required: true,
            }
        },
        {
            name: "Quantity",
            type: "text",
            size: "col-sm-2 itemsCasesParentContainer",
            position: 9,
            title: AppConstant.smartScheduler.poSection.quantity,
            formValidation: {
                required: false,
            }
        },
        {
            name: "Cases",
            type: "text",
            size: "col-sm-2 itemsCasesParentContainer",
            position: 10,
            title: AppConstant.loadManagement.purchaseOrderDetails.cases,
            formValidation: {
                required: false,
            }
        },
        {
            name: "Weight",
            type: "text",
            size: "col-sm-6 ",
            position: 12,
            title: AppConstant.smartScheduler.poSection.weight,
            formValidation: {
                required: false,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "custom-date-picker",
            type: "date",
            customDatePickerConfig: {
                calendarType: 'Daily',
                selectedDate: selectedDate ? selectedDate : AppConstant.commonStrings.MM_DD_YYYY_dateFormat,
                customInput: <TUSCustomInput value={selectedDate} />,
                disableCalendar: false,
                maxDate: 'NA',
                startDate: getCurrentDate(),
            },
            position: 6,
            value: selectedDate,
            title: AppConstant.smartScheduler.poSection.dueDate,
            formValidation: {
                required: true,
            }
        },
        {
            name: "ProduceType",
            type: "select",
            size: "col-sm-6",
            position: 5,
            title: AppConstant.smartScheduler.poSection.produceType,
            formValidation: {
                required: true,
            },
            getSelectData: getDocksList,
        },
        {
            name: "ZipCode",
            type: "text",
            size: "col-sm-6",
            position: 8,
            title: AppConstant.smartScheduler.poSection.zipCodeRecvTitle,
            formValidation: {
                required: false,
            }
        },
        {
            name: "Remarks",
            type: "text",
            size: "col-sm-6 weightsParentContainer",
            position: 11,
            title: AppConstant.smartScheduler.poSection.remarks,
            formValidation: {
                required: false,
            }
        },
        {
            name: "",
            type: "emptyspace",
            size: "col-sm-6",
            position: 13,
            title: '',
            formValidation: {
                required: false,
            }
        },
    ]

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        const selectedDock = (_.find(dockListArr, { ID: data.ProduceType }));
        const operationObj = {
            type: '',
            status: ''
        }
        if (data.WarehouseId) {
            const apiTempParams = {
                "PoNumber": data.PoNumber,
                "OrderNumber": data.OrderNumber,
                "VendorName": data.VendorName,
                "BuyerName": data.BuyerName,
                "Commodity": data.Commodity,
                "Cases": data.Cases,
                "PalletsCount": data.Pallets,
                "Weight": data.Weight,
                "CRUDTYPE": "U",
                "Quantity": data.Quantity,
                "DueDate": changeDateToGivenFormat(data['custom-date-picker'], 'YYYY-MM-DD hh:mm:ss'),
                "ProduceType": data.ProduceType,
                "ZipCode": data.ZipCode ? data.ZipCode : selectedWarehouse.Zip,
                "PoCreatedOn": data.PoCreatedOn,
                "CreatedOn": data.PoCreatedOn,
                "CreatedBy": cu_id,
                "PoCreatedBy": data.PoCreatedBy,
                "WarehouseId": selectedWarehouse.ID,
                "IDPO": data.IDPO,
                "WarehouseName": selectedWarehouse.Name,
                "DockName": selectedDock.Name,
                "PoStatus": data.PoStatus,
                "Door": data.Door,
                "Remarks": data.Remarks,
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            }
            operationObj.type = 'Edit PO'
            apiParams = { ...data, ...apiTempParams }
        } else {
            apiParams = {
                "PoNumber": data.PoNumber,
                "OrderNumber": data.OrderNumber || null,
                "VendorName": data.VendorName,
                "BuyerName": data.BuyerName || null,
                "Commodity": data.Commodity,
                "Cases": data.Cases || null,
                "PalletsCount": data.Pallets,
                "Weight": data.Weight || null,
                "CRUDTYPE": "I",
                "Quantity": data.Quantity || null,
                "DueDate": changeDateToGivenFormat(data['custom-date-picker'], 'YYYY-MM-DD hh:mm:ss'),
                "ProduceType": data.ProduceType,
                "ZipCode": data.ZipCode ? data.ZipCode : selectedWarehouse.Zip,
                "CreatedBy": cu_id,
                "CreatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "WarehouseId": selectedWarehouse.ID,
                "IDPO": null,
                "WarehouseName": selectedWarehouse.Name,
                "DockName": selectedDock.Name,
                "PoStatus": null,
                "Door": null,
                "Remarks": data.Remarks,
                'PoCreatedOn': getCurrentDate(),
                'PoCreatedBy': cu_id,
                "UpdatedBy": cu_id,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
            }

            operationObj.type = 'Create PO'
        }
        dispatch({ type: sagaActions.UPDATE_PO_LIST, payload: apiParams, operationObj: operationObj })
    };

    const closeCurrentpopup = () => {
        setShowModal(false);
    }

    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    const showCreatePOPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: !Object.keys(editData).length ? AppConstant.smartScheduler.poSection.createPOTitle : AppConstant.smartScheduler.poSection.editPOTitle,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const openCreateForm = () => {
        setShowModal(true);
        setParticularFieldValue({ 'ZipCode': selectedWarehouse.Zip });
        setEditData({});
    }

    const downloadSectionForTable = () => {
        return (
            <div className='dropdown border border-dark rounded-2 p-1 px-2'>

                <div className='d-flex justify-content-start align-items-center me-2 downloadSection' data-bs-toggle="dropdown" aria-expanded="false" >
                    {themeSelected === 'light-mode' ? <DownloadIconLight className='me-2' /> : <DownloadIconDark className='me-2' />}
                    <div className={`downloadButton ${themeSelected}`}>{AppConstant.downloadSection.downloadTitleStr}</div>
                </div>
                <ul className="dropdown-menu text-center dropDownDownItemloadSection">
                    <li><a className="dropdown-item" onClick={() => downloadPdfData()} href="#">{AppConstant.downloadSection.pdfDownloadString}</a></li>
                    <div className='seperator' />
                    <li><a className="dropdown-item" onClick={() => downloadExcelData()} href="#">{AppConstant.downloadSection.excelDownloadString}</a></li>
                </ul>
            </div>
        )
    }

    return (
        <div className='container-fluid m-1'>
            <Header {...props} />
            <Navigationbar {...props} />
            <div className='d-flex justify-content-between'>
                <div className='mt-3 align-items-center'>
                    <div className="d-flex mb-2 align-items-center">
                        <div className="h-auto me-4">
                            <CustomButton
                                isLoading={false}
                                title={AppConstant.smartScheduler.poSection.createPOTitle}
                                className={"createPOButton me-3"}
                                onClick={() => openCreateForm()}
                            />
                        </div>
                        <div className="h-auto me-4">
                            <CustomButton
                                isLoading={false}
                                title={AppConstant.smartScheduler.poSection.uploadPoBulk}
                                className={"createPOButton me-3"}
                                onClick={() => navigate("/bulkUploadPO")}
                            />
                        </div>
                    </div>
                </div>

                <div className='d-flex w-auto m-3 justify-content-between align-items-center'>
                    <div className="me-4">
                        <WarehouseSearch
                            placeholder={"Search PO's"}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                    {downloadSectionForTable()}
                </div>
            </div>
            <div className='mt-3 appointmentListTable' ref={divRef}>
                <SsPOList searchStr={searchStr} editPOAction={(poObj, index) => editPO(poObj, index)} showPopup={() => openCreateForm()} themeSelected={themeSelected} />
            </div>
            {showCreatePOPopup()}
        </div>
    )
}

export default CreatePO;