import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getRemoteConfigSelector } from '../../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { smartScheduleSelector } from '../../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { getPoSSselector } from '../../../../../../../../../redux/reducers/reducerSlices/POSmartSchedulerReducer';
import { sagaActions } from '../../../../../../../../../sagas/sagaActions';
import moment from 'moment-timezone';
import DrilldownTableSS from '../../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import { AppConstant } from '../../../../../../../../Assests/AppConstant';

/**
 * Component is used in the appointment report, where this compnent rendered as pop-up to show the appointment history.
 * In this componenet calling appointment report history and getting the details
 * @param {*} props 
 * @returns 
 */
const AppointmentReportHistory = (props) => {
    const { themeSelected = 'light-mode', data } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pOSS = useSelector(getPoSSselector);
    const {arrangeOrder, arrangeKey, apptsReportHistoryList, isLoading } = pOSS;
    const divRef = useRef(null);
    const [showLoading, setShowLoading] = useState(true);
    const ss = useSelector(smartScheduleSelector);
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const [apptList, setapptReportHistoryList] = useState([]);
    const userObj = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id, userType, userAccountDetails } = userObj;
    moment.tz.setDefault(selectedWarehouse?.TimeZone)

    /**
     * used to populate the default table headers 
     */
    const apptsTitleDD = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'PoNumber', name: 'PO' },
        { id: userType === 'CARRIER' ? 'WarehouseName' : 'CarrierName', name: userType === 'CARRIER' ? 'Warehouse' : 'Carrier' },
        { id: 'DueDate', name: 'Due Date' },
        { id: 'TransDate', name: 'Created Date & Time' },
        { id: 'DockName', name: 'Dock' },
        { id: 'Pallets', name: 'Pallets' },
        { id: 'ApptCreatedName', name: 'Scheduled By' },
        { id: 'ApptScheStatus', name: 'Status' },
        { id: 'Reason', name: 'Reason' },
    ]

    /**
     * used to populate the default table headers 
     */
    const apptsTitleDDWH = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'PoNumber', name: 'PO' },
        { id: userType === 'CARRIER' ? 'WarehouseName' : 'CarrierName', name: userType === 'CARRIER' ? 'Warehouse' : 'Carrier' },
        { id: 'DueDate', name: 'Due Date' },
        { id: 'TransDate', name: 'Created Date & Time' },
        { id: 'DockName', name: 'Dock' },
        { id: 'Pallets', name: 'Pallets' },
        { id: 'ApptScheStatus', name: 'Status' },
        { id: 'Reason', name: 'Reason' },
    ]

    //used to reset appointments report history list
    useEffect(() => {
        dispatch({ type: sagaActions.RESET_APPOINTMENT_REPORT_HISTORY });
        setShowLoading(true);
        setapptReportHistoryList([]);
    }, [])

    //used to set the appt history list data to the state variable
    useEffect(() => {
        if (apptsReportHistoryList && apptsReportHistoryList.length) {
            setShowLoading(false);
            setapptReportHistoryList(apptsReportHistoryList)
        } else {
            setapptReportHistoryList([])
            setShowLoading(false)
        }

    }, [apptsReportHistoryList])

    useEffect(() => {
        fetchApptsReportHistoryDetails()
    }, [])

//used to fetch appointments report history list
const fetchApptsReportHistoryDetails = () => {
    const apiParams = {
        "AppointmentNumber": data
    }
    dispatch({ type: sagaActions.GET_APPOINTMENT_REPORT_HISTORY, payload: apiParams });
}

/**
 * Used to changet the text color based on the appt status type
 * @param {*} str 
 * @returns 
 */
const getApptSchStatusColor = (str) => {

    if (str === 'Completed') {
        return <p className='text-success'>{str}</p>
    } else if (str === 'Schedule') {
        str = 'Scheduled';
        return <p className='notScheStatusText'>{str}</p>
    } else if (str === 'Delete') {
        return <p className='text-danger'>{cancelObj[str]}</p>
    } else if (str === 'Re-Schedule') {
        return <p className='notScheStatusText'>{cancelObj[str]}</p>
    } else {
        return <p className='notScheStatusText'>{str}</p>
    }
}

/**
 * Used to format the appt details to show
 * @param {*} data 
 * @returns 
 */
const formatApptsReportHistoryListDataDrillDown = (data) => {
    return data.map((el, index) => {
        if (userType === 'CARRIER') {
            return {
                id: el.RoleID,
                0: index + 1,
                1: el.PONumber,
                2: userType === 'CARRIER' ? el.WarehouseDetails.Name || '-' : el.CarrierName || '-',
                3: el.DueDate ? moment(el.DueDate).utc().format('MM/DD/YYYY') : '-',
                4: el.Transdate ? moment(el.Transdate).utc().format('MM/DD/YYYY HH:mm:ss') : '-',
                5: el.DockName || '-',
                6: el.Pallets || '-',
                7: el.ApptCreatedName ? el.ApptCreatedName : '-',
                8: el.ApptScheStatus ? getApptSchStatusColor(el.ApptScheStatus) : '-',
                9: el.RescheduleReason ? el.RescheduleReason : '-',
            }
        }
        else {
            return {
                id: el.RoleID,
                0: index + 1,
                1: el.PONumber,
                2: userType === 'CARRIER' ? el.WarehouseDetails.Name || '-' : el.CarrierName || '-',
                3: el.DueDate ? moment(el.DueDate).utc().format('MM/DD/YYYY') : '-',
                4: el.Transdate ? moment(el.Transdate).utc().format('MM/DD/YYYY HH:mm:ss') : '-',
                5: el.DockName || '-',
                6: el.Pallets || '-',
                7: el.ApptScheStatus ? getApptSchStatusColor(el.ApptScheStatus) : '-',
                8: el.RescheduleReason ? el.RescheduleReason : '-',
            }
        }

    })
}

/**
 * helper object to make changes to the data 
 */
const cancelObj = {
'Delete': 'Cancelled',
'Re-Schedule': 'Rescheduled'
}

/**
 * Function to get the appt history body data
 * @returns 
 */
const getHistoryBodyData = () => {
    if (apptList && apptList.length) {
       return formatApptsReportHistoryListDataDrillDown(apptList)
    }
    else {
        return []
    }
}

return (
                    <div className='p-3' style={{ width: 'auto', height: 'auto' }}>
                        <div className='f-16'>{`Appointment History  ${data}`}</div>
                        <div className='allApptsListContainer-ss mt-3'>
                            <DrilldownTableSS
                                key={Math.random()}
                                themeSelected={themeSelected}
                                bodyData={getHistoryBodyData()}
                                titleData={userType === 'CARRIER' ? apptsTitleDD : apptsTitleDDWH}
                                showCollapse={false}
                                showInput={false}
                                showSort={false}
                                hasCollapsableContent={false}
                                arrangeOrder={arrangeOrder}
                                arrangeKey={arrangeKey}
                                drillDownReport={apptsReportHistoryList}
                                initiateDrillDownReport={isLoading}
                                loaderArray={10}
                                sortingAction={sagaActions.SORT_SS_USER_TABLE}
                                subRoot={{ pathName: 'allApptsList' }}
                                showUTCTime={true}
                            />
                        </div>
                    </div>
)
}

export default AppointmentReportHistory
