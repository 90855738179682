import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import CommonToggleSwitch from '../../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import DrilldownTable from '../../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import { initiateSearch } from '../../../../../../../Assests/Utility';
import './CompanyList.css';

const CompanyList = (props) => {

    const { themeSelected, editCompanyAction, searchStr, allowPermissionE, allowPermissionD } = props;
    const dispatch = useDispatch();
    const [companyArr, setCompanyArr] = useState([])
    const user = useSelector(getUserSelector);
    const { companyList, initateLoading, arrangeOrder, arrangeKey } = user;

    const companyTitleDD = [
        { id: 'serial_num', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.logo },
        { id: 'CompanyName', name: AppConstant.superUserAdmin.companyGroup.tableHeader.name },
        {id: 'WHTable', name: AppConstant.superUserAdmin.companyGroup.tableHeader.CompanyType},
        { id: 'CreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'ContactNumber', name: AppConstant.superUserAdmin.companyGroup.tableHeader.contactNum },
        { id: 'Email', name: AppConstant.superUserAdmin.companyGroup.tableHeader.email },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.status },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.actions },
    ]

    useEffect(() => {
        setTimeout(() => {
            getCompanyList();
        }, 0);
    }, [])

    useEffect(() => {
        if (searchStr.length > 3) {
            const searchedArr = initiateSearch(companyList, searchStr, 'CompanyName');
            setCompanyArr(searchedArr)
        } else if (searchStr === '') {
            setCompanyArr(companyList)
        }
    }, [searchStr])

    useEffect(() => {
        if (companyList && companyList[0] === 'updated company list successfully') {
            dispatch({ type: sagaActions.INITIATE_LOADING, payload: true });
            getCompanyList();
        } else if (companyList && companyList.length) {
            initateLoading && dispatch({ type: sagaActions.INITIATE_LOADING, payload: false });
            if (searchStr) {
                const searchedArr = initiateSearch(companyList, searchStr, 'CompanyName');
                setCompanyArr(searchedArr)
            } else {
                setCompanyArr(companyList);
            }
        } else if (!companyList.length) {
            getCompanyList();
        }
    }, [companyList])

    const getCompanyList = () => {
        dispatch({ type: sagaActions.INITIATE_LOADING, payload: true })
        const params = {
            "company_group_id": null
        }
        dispatch({ type: sagaActions.GET_COMPANY_LIST, payload: params });
    }

    const actionsButton = (companyObj, index) => {
        return (
            <div>
                <span className='' onClick={() => editCompanyAction(companyObj, index)}>
                    <button
                        className={allowPermissionE ? 'addButton' : 'addButton disabled'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createCompanyFormPopup1`} 
                        disabled={allowPermissionE ? '' : 'disabled'}
                        >
                        {AppConstant.commonStrings.edit}
                    </button>
                </span>
            </div>
        )
    }

    const updateCompanyApi = (companyObj) => {

        const params = {
            company_group_id: companyObj.CompanyGroupId,
            company_name: companyObj.CompanyName,
            email: companyObj.Email,
            contact_number: companyObj.ContactNumber,
            address: companyObj.Address,
            locale: companyObj.Locale,
            city: companyObj.city,
            zip: companyObj.Zip,
            state: companyObj.State,
            country: companyObj.Country,
            company_logo: companyObj.CompanyLogo,
            company_id: companyObj.CompanyId,
            enable_status: companyObj.EnableStatus,
            crud_type: 'U',
        };

        dispatch({ type: sagaActions.UPDATE_COMPANY, payload: params });
    }

    const toggleStatus = (index) => {
        const tempArr = [...companyArr];
        const tempObj = { ...tempArr[index] };
        tempObj.EnableStatus = !tempArr[index].EnableStatus;
        tempArr[index] = tempObj;
        setCompanyArr([...tempArr]);
        updateCompanyApi(tempObj)
    }

    const getToggleSwitch = (value, index) => {
        return (
            <div className='d-flex justify-content-center align-items-center py-1'>
                <CommonToggleSwitch value={value}
                    onChangeValue={(value) => toggleStatus(index)} 
                    allowPermissionD={allowPermissionD}
                    />
            </div>
        )
    }

    const getLogoImage = (imgLink) => {
        return (
            <div className=' d-flex justify-content-center'>
                <img className='companyLogoContainer' src={imgLink ? imgLink : AppConstant.superUserAdmin.company.placeholderIconLink} alt={'Logo'} />
            </div>
        )
    }

    const formatListDataDrillDown = (data) => {
        return data.map((el, index) => {
            return {
                id: el.CompanyId,
                0: el.serial_num,
                1: getLogoImage(el.CompanyLogo),
                2: el.CompanyName,
                3: el.WHTable === 'SPRO' ? AppConstant.commonStrings.spro : AppConstant.commonStrings.tt,
                4: el.CreatedOn,
                5: el.ContactNumber,
                6: el.Email,
                7: getToggleSwitch(el.EnableStatus, index),
                8: actionsButton(el, index),
            }
        })
    }

    return (
        companyArr &&
        <DrilldownTable
            themeSelected={themeSelected}
            bodyData={companyArr.length && formatListDataDrillDown(companyArr)}
            titleData={companyTitleDD}
            showCollapse={false}
            showInput={false}
            scroll={true}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={companyList}
            initiateDrillDownReport={initateLoading}
            loaderArray={9}
            sortingAction={sagaActions.SORT_USER_TABLE}
            subRoot={{ pathName: 'companyList' }}
            showUTCTime={false}
        />
    )

}

export default CompanyList;