import _ from 'lodash';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { AppConstant } from '../../../../Assests/AppConstant';
import ModalSS from '../../../SmartSchedule/SchedulePro/Components/Common/ModalSS/ModalSS';

const SecondaryNavigation = (props) => {
    const { sNavList, isUserSubscribed, location, setShowModal, showModalSS, toggleModalSS ,CarrierType ,toggleHelpAndFeedbackModal} = props;

    return (
        <>
            <ul className='nav-list py-0'>
                {
                    _.size(sNavList) && sNavList.map((el, index) => {

                        if (el.NavigationType === 'Secondary' && el.PageAccess) {
                            if (isUserSubscribed) {
                                return (<>
                                    {el.PageDesc === AppConstant.settings.dropdownMenu.accountSettings ?
                                        <li onClick={() => { toggleModalSS() }}>
                                            <i className="fa-solid fa-user-gear"></i> {AppConstant.settings.dropdownMenu.accountSettings}
                                        </li> :
                                        CarrierType?.toUpperCase()!=='PRIMARY' && (el?.PageName==='My Subscriptions' || el?.PageName==='Carrier Users')?" ":
                                        <li className={location?.pathname === el?.PageUrl ? "d-flex align-items-center w-100" : "d-flex align-items-center w-100"} key={el.PageDesc}>
                                            {el?.FeatureIcon && <i className={el?.FeatureIcon} aria-hidden="true"></i>}
                                            <Link className="w-100" aria-current="page" to={el.PageUrl}>
                                                <div className='ms-1'> {el.PageDesc}</div>
                                            </Link>
                                        </li>
                                    }

                                </>
                                )
                            }
                        }
                    })
                }
                   <li onClick={() => {
                        toggleHelpAndFeedbackModal()
                    }}>
                        <i className="fa-solid fa-dharmachakra"></i> {AppConstant.settings.dropdownMenu.helpAndFeedback}
                    </li>
                { 
                    <li data-bs-toggle="modal" data-bs-target={`#logoutPopup`} onClick={() => {
                        setShowModal(true);
                    }}>
                        <i className="fa-solid fa-file-export"></i> {AppConstant.settings.dropdownMenu.logout}
                    </li>

                }
            </ul>

        </>
    )
}

export default SecondaryNavigation