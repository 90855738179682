import React from 'react';
import Header from '../../../../Header/Header';
import Navigationbar from '../../../../Navigationbar/Navigationbar';
import CompanyAdminScreen from './Components/CompanyAdminScreen/CompanyAdminScreen';


const CompanyAdmin = (props) => {

    const { themeSelected } = props;

    return (
        <div className='container-fluid m-1'>
            <Header {...props} />
            <Navigationbar {...props} />
            <CompanyAdminScreen className='mt-3' themeSelected={themeSelected} />
        </div>
    )
}

export default CompanyAdmin;