import React from 'react';
import Header from '../../../../Header/Header';
import Navigationbar from '../../../../Navigationbar/Navigationbar';
import EclipseOpsPaymentLoad from './EclipseOpsPaymentLoad';


const EclipseOpsPayment = (props) => {

    const { themeSelected } = props;

    return (
        <div className='container-fluid m-1'>
            <Header {...props} />
            <Navigationbar {...props} />
            <EclipseOpsPaymentLoad className='mt-3' themeSelected={themeSelected} />
        </div>
    )
}

export default EclipseOpsPayment;