import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomDynamicForm } from '../../../Common/CustomForm/CustomDynamicForm';
import { AppConstant } from '../../../../Assests/AppConstant';
import { lightStyles, darkStyles } from './CreditCardFrameStyles/CreditCardFrameStyles';
import { useNavigate, useHistory } from 'react-router-dom';
import { sagaActions } from '../../../../../sagas/sagaActions';
import Auth from "@aws-amplify/auth";
import { Hub } from 'aws-amplify';
import { smartScheduleSelector } from '../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { showAlert, createNavBar, removeLastCharacterFromString, formatContactNumForAWS } from '../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { paytraceReducer } from '../../../../../redux/reducers/reducerSlices/PaytraceReducer';
import CustomButton from '../../../Common/CustomButton/CustomButton';
import Popup from '../../../Common/Popup/Popup';
import { getAuthReducer } from '../../../../../redux/reducers/reducerSlices/AuthReducer';
import { getUserSelector } from '../../../../../redux/reducers/reducerSlices/UserReducer';
import { interceptor, removeInterceptor } from '../../../../../redux/api/Interceptor';
import _, { isNil } from 'lodash-es';
import { customStylesLight } from '../FormCarrierSignUp/Components/Styles/Styles';
import PaymentCardsImage from '../../../../Assests/Images/paymentCardsAccepted.png';
import './FormCarrierSignUp.css';
import CustomTooltip from '../../../Common/Tooltip/CustomTooltip';
/**
 * Component to render carrier signup form with paytrace payment iframe
 * @param {*} props 
 * @returns 
 */
const FormCarrierSignUp = (props) => {
    const dispatch = useDispatch();
    const { themeSelected } = props;
    const navigate = useNavigate();
    const PTPayment = window.PTPayment;
    const [loading, setLoading] = useState(false);
    const [showIFrame, setShowIFrame] = useState(false);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [showIFrameLoader, setShowIFrameLoader] = useState(false);
    const [showPaymentSuccessView, setShowPaymentSuccessView] = useState(false);
    const [iFramePaymentCompletedFlag, setIFramePaymentCompletedFlag] = useState(false);
    const [resetFormData, setResetFormData] = useState(0);
    const [editData, setEditData] = useState({});
    const [paymentInstance, setInstance] = useState(null);
    const [styles, setStyles] = useState(themeSelected === 'dark-mode' ? darkStyles : lightStyles);
    const [passwordText, setPasswordText] = useState('');
    const [confirmPasswordText, setConfirmPasswordText] = useState('');
    const [userSignUpData, setUserSignUpData] = useState({});
    const [userFirstName, setUserFirstName] = useState('');
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const [iFrameValidationErrors, setIframValidationErrors] = useState([]);
    const userPermission = useSelector(getUserSelector);
    const { userType, permissions, userAccountDetails, carrierFromSignUpUserObj } = userPermission;
    const user = useSelector(getAuthReducer);
    const [carrierListState, setCarrierListState] = useState([]);
    const { paytraceClientId, paymentResponse } = useSelector(paytraceReducer);
    const [carrierOptionsArr, setCarrierOptionsArr] = useState([]);
    const [selectedCarrier, setSelectedCarrier] = useState(null)
    const ss = useSelector(smartScheduleSelector);
    const { carrierList } = ss;

    /**
     * initial useEffect on component load
     */
    useEffect(() => {
        window.localStorage.clear();
        deleteUser();
        setPasswordText('');
        setConfirmPasswordText('');
        dispatch({ type: sagaActions.RESET_PAYTRACE_DATA });
        setParticularFieldValue({
            'first_name': '',
            'last_name': '',
            'email': '',
            'contact_number': '',
            'password_text': '',
            'confirm_password_text': '',
            'mcr_code': '',
            'carrier_name': ''
        });
        setShowIFrame(false);
        setIsPaymentLoading(false);
        setShowIFrameLoader(false);
        setShowPaymentSuccessView(false);
        setConfirmPasswordText(false);
        setIFramePaymentCompletedFlag(false);
        dispatch({ type: sagaActions.RESET_CARRIER_FROM_SIGNUP_TEMP_DATA });

    }, [])
    /**
     * useEffect to render carrier list
     */
    useEffect(() => {
        if (_.size(carrierList)) {
            formCarrierListData()
        }
    }, [carrierList])

    useEffect(() => {
        //do this after user is created
        if (carrierFromSignUpUserObj && Object.keys(carrierFromSignUpUserObj).length > 0) {
            if (carrierFromSignUpUserObj && carrierFromSignUpUserObj.response && carrierFromSignUpUserObj.response.status !== 200) {
                showAlert(toast.TYPE.ERROR, carrierFromSignUpUserObj.response.data.message, 'light')
            } else {
                //show success sign up
                setIsPaymentLoading(false);
                setShowPaymentSuccessView(true);
                setIFramePaymentCompletedFlag(false);
                dispatch({
                    type: sagaActions.GET_USER_ACCOUNT_DETAILS, payload: {
                        cu_id: user?.auth?.username,
                        crud_type: 'S',
                        profile_picture: null
                    }
                })
                // API to update user Last Logon
                dispatch({
                    type: sagaActions.UPDATE_LAST_LOGON, payload: {
                        cu_id: user?.auth?.username,
                    }
                })

            }
        }
    }, [carrierFromSignUpUserObj])
    /** useEffect for user account details */
    useEffect(() => {
        if (!_.isEmpty(userAccountDetails)) {
            if (userAccountDetails.EnableStatus) {
                dispatch({
                    type: sagaActions.GET_USER_TYPE,
                    payload: {
                        cu_id: user?.auth?.username
                    }
                })
            } else {
            }
        } else {
        }
    }, [userAccountDetails])
    /**
     * useEffect to get user permissions if userType is CARRIER
     */
    useEffect(() => {
        if (user.sessionToken) {
            if (userType === 'CARRIER') {
                dispatch({
                    type: sagaActions.GET_USER_PERMISSIONS,
                    payload: {
                        warehouse_id: null,
                        user_type: userType,
                        cu_id: user?.auth?.username,
                    }
                })
            } else {
            }
        }
    }, [userType])
    /**
     * useEffect to create dynamic navigation bar once permission exist 
     * else show error alert and logout user
     */
    useEffect(() => {
        if (user.sessionToken && !_.isEmpty(permissions)) {
            if (permissions?.message === AppConstant.userPermission.userPermissionNoData) {
                removeInterceptor()
                dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
                dispatch({ type: sagaActions.RESET_USER })
                navigate("/")
                window.localStorage.clear();
                showAlert(toast.TYPE.ERROR, AppConstant.userPermission.toast.userPermissionFail);
                return
            }
            const dynamicNav = createNavBar(permissions, userType)
            dispatch({ type: sagaActions.UPDATE_NAV_BAR, payload: dynamicNav })
            dispatch({
                type: sagaActions.UPDATE_USER_ACCOUNT_DETAILS_RENEW, payload: {
                    ...userAccountDetails, "SubscriptionStatus": "ACTIVE"
                }
            })
            redirectUser();
        } else {
            // console.log('Access issue: Logout User')
        }
    }, [permissions])

      /**
     * Function to redirect user
     */
    const redirectUser = () => {
        const page = _.find(permissions, function (x) { return x.PageAccess === true && x.NavigationType === 'Primary' })
        if (!_.isEmpty(page)) {
            navigate(page.PageUrl);
            dispatch({ type: sagaActions.RESET_CARRIER_FROM_SIGNUP_TEMP_DATA });
        } else {
            console.log('No permission found after carrier signed up');
        }
    }
     /**
     * useEffect once session token exists
     */
    useEffect(() => {
        if (!isNil(user.auth) && user.sessionToken) {
            removeInterceptor();
            interceptor(user.sessionToken);
            hitPaymentApi();
        }
    }, [user])

    /**
     * function to handle paytrace payment API
     */
    const hitPaymentApi = () => {
        if (paymentInstance) {
            paymentInstance.process()
                .then((resPaytrace) => {
                    const paymentApiParams = {
                        "email": user.auth.attributes.email,
                        "hpf_token": resPaytrace.message.hpf_token,
                        "enc_key": resPaytrace.message.enc_key,
                        "customer_id": user.auth.username,
                        "csc": "",
                        "AccountNumber": carrierFromSignUpUserObj?.AccountNumber
                    }
                    dispatch({ type: sagaActions.HIT_PAYTRACE_PAYMENT_API, payload: paymentApiParams });
                    setIFramePaymentCompletedFlag(true);
                })
                .catch((err) => {
                    setIsPaymentLoading(false)
                    const errorStr = `${AppConstant.smartScheduler.carrierSignUp.paytrace.paymentFailureMessage}${err}`
                    showAlert(toast.TYPE.ERROR, errorStr, 'light')
                });
        }
    }
 /**
     * function to create user in database
     */
    const createNewUserInDb = () => {
        const selCarrier = _.find(carrierList, { carrier_id: particularFieldValue.carrier_name });
        let tempData = { ...particularFieldValue };
        tempData.Email = user.auth.attributes.email;
        tempData.CUID = user.auth.username;
        tempData.phone = user.auth.attributes.phone_number;
        tempData.MCR = particularFieldValue.mcr_code;
        tempData.CarrierName = selCarrier && selCarrier.carrier_name;
        tempData.FirstName = user.auth.attributes.given_name;
        tempData.UserName = user.auth.attributes.given_name;
        tempData.LastName = user.auth.attributes.family_name;
        tempData.UserType = 'CARRIER';
        tempData.EnableStatus = 1;
        tempData.CreatedOn = null;
        tempData.CreatedBy = null;
        tempData.CRUDTYPE = 'I';
        tempData.IDCarrier = particularFieldValue.carrier_name;
        dispatch({ type: sagaActions.CREATE_CARRIER_FROM_SIGNUP, payload: tempData });
    }
    /**
     * useEffect for paytrace payment success/fail
     */
    useEffect(() => {
        if (Object.keys(paymentResponse).length > 0) {

            if (paymentResponse && paymentResponse.response && paymentResponse.response.status !== 200) {
                deleteUser();
                showAlert(toast.TYPE.ERROR, `${AppConstant.smartScheduler.carrierSignUp.paytrace.paymentFailureMessage} ${AppConstant.smartScheduler.carrierSignUp.signUpAgainMessage}`, 'light')
            } else if (paymentResponse.success && showIFrame) {
                showAlert(toast.TYPE.SUCCESS, AppConstant.smartScheduler.carrierSignUp.paytrace.paymentSuccessMessage, 'light');
                createNewUserInDb();
            }
        }
    }, [paymentResponse])
    /** function to delete user */
    const deleteUser = () => {
        try {

            // await Auth.deleteUser();
            removeInterceptor()
            dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
            dispatch({ type: sagaActions.RESET_USER_WITHOUT_BRANDING, payload: true })
            setShowIFrame(false);
            setIFramePaymentCompletedFlag(false);
            setIsPaymentLoading(false);
            dispatch({ type: sagaActions.RESET_PAYTRACE_DATA });
            setParticularFieldValue({
                'first_name': '',
                'last_name': '',
                'email': '',
                'contact_number': '',
                'password_text': '',
                'confirm_password_text': '',
                'mcr_code': '',
                'carrier_name': ''
            });
            window.localStorage.clear();
        }
        catch (error) {
            console.log(AppConstant.smartScheduler.carrierSignUp.deleteUserError, error);
        }
    }
    /**
     * useEffect to render initialize paytrace UI when paytrace client exists
     */
    useEffect(() => {
        if (paytraceClientId) {
            paytraceUIForm();
        }
    }, [paytraceClientId])

    /** function to handle Carrier selection from drodown */
    const onCarrierSelcted = (props) => {
        if (props.length > 2) {
            dispatch({ type: sagaActions.SS_GET_CARRIER_LIST, payload: { carrier: props } })
        }
    }
    /** Drowdown change handler */
    const onChangeValue = (props) => {
        setSelectedCarrier(props)
    }

    const formatCreateLabel = (props) => {
    }
    /**
     * function to set carrier data inot state variable carrierListState
     */
    const formCarrierListData = () => {
        if (_.size(carrierList)) {
            const carrierData = carrierList.map(el => {
                return {
                    value: el.carrier_id,
                    label: el.carrier_name
                }
            })
            setCarrierListState([...carrierData])
        } else {
            setCarrierListState([])
        }
    }

    /** Custom Form field arary*/
    const formFieldList = [
        {
            name: "first_name",
            type: "text",
            size: "col-sm-6 mb-3",
            position: 1,
            title: AppConstant.user.popup.createUser.fields.given_name_title,
            formValidation: {
                required: true
            },
        },
        {
            name: "last_name",
            type: "text",
            size: "col-sm-6 mb-3",
            position: 2,
            title: AppConstant.user.popup.createUser.fields.family_name_title,
            formValidation: {
                required: true,
            },
        },
        {
            name: "email",
            type: "text",
            size: "col-sm-6 mb-3",
            position: 3,
            title: AppConstant.user.popup.createUser.fields.user_name,
            formValidation: {
                required: true,
                validateEmail: true,
            },
        },
        {
            name: "contact_number",
            type: "text",
            size: "col-sm-6 mb-3",
            position: 4,
            placeholder: AppConstant.user.popup.createUser.phone_placeHolder,
            title: AppConstant.settings.userAccountTitle.phone,
            formValidation: {
                required: true,
                validateContactNum: true,
                customError: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.contactNumErrorMessage
            },
        },
        {
            name: "password_text",
            type: "password",
            size: "col-sm-6 mb-3",
            position: 5,
            value: passwordText,
            title: AppConstant.login.password,
            formValidation: {
                checkPassword: true,
                required: true,
            },
        },
        {
            name: "confirm_password_text",
            type: "password",
            size: "col-sm-6 mb-3",
            position: 6,
            value: confirmPasswordText,
            title: AppConstant.login.confirm_password,
            formValidation: {
                required: true,
                passwordText: passwordText,
                confirmPassword: true
            },
        },
        {
            name: "mcr_code",
            type: "text",
            size: "col-sm-6 mb-3",
            position: 7,
            title: AppConstant.smartScheduler.carrierSignUp.carrierFormTitles.mcrCodeTitle,
            formValidation: {
            },
        },
        {
            name: "carrier_name",
            type: "select_with_input_search_add_button",
            size: "col-sm-6",
            parentClass: 'inputTitle',
            componentClassName: 'mt-1 inputDiv border border-1 rounded-1 border-dark',
            inputTitle: 'Select Carrier',
            position: 8,
            title: AppConstant.reports.scheduledLoadStatus.tableHeaders.carrierName,
            customStylesLight: customStylesLight,
            placeholderText: 'Type here...',
            onInputChange: onCarrierSelcted,
            onChange: onChangeValue,
            defaultValue: !_.isEmpty(selectedCarrier) ? selectedCarrier : 'Select a carrier from the list',
            isLoading: false,
            isClearable: true,
            isSearchable: true,
            options: carrierListState,
            formatCreateLabel: formatCreateLabel,
            loadingMessage: 'Please wait loading....',
            formValidation: {
                required: true,
            },
        },
    ]
    /** Cancel button data object */
    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };
    /** Submit button data object */
    const submitbtnData = {
        show: true,
        text: AppConstant.commonStrings.next,
    };
    /**
     * Form field data selection handler
     * @param {*} data 
     * @param {*} name 
     */
    const getFormFieldData = (data, name) => {
        if (name === 'password_text') {
            setPasswordText(data);
        } else if (name === 'confirm_password_text') {
            setConfirmPasswordText(data);
        } else if (name === "carrier_name") {
            setSelectedCarrier(data)
        }
    };
    /**
     * Submit function handler
     * @param {*} data 
     */
    const getSummitedFormData = async (data) => {
        setUserSignUpData({ ...data, 'carrier_name': selectedCarrier })
        setShowIFrame(!showIFrame);
        setUserFirstName(data.first_name)
        setShowIFrameLoader(true);
        dispatch({ type: sagaActions.GET_PAYTRACE_CLIENT_ID })
        setIFramePaymentCompletedFlag(false);
        let contactNumTemp = data.contact_number;
        if (data.contact_number.length > 14) {
            contactNumTemp = removeLastCharacterFromString(data.contact_number);
        }
        setParticularFieldValue({
            'first_name': data.first_name || '',
            'last_name': data.last_name || '',
            'email': data.email || '',
            'contact_number': contactNumTemp || '',
            'password_text': '',
            'confirm_password_text': '',
            'mcr_code': data.mcr_code || '',
            'carrier_name': selectedCarrier,
        });

        Hub.listen('auth', ({ payload }) => {
            const { event } = payload;
            if (event === 'autoSignIn') {
                const dataTemp = {
                    response: { ...payload.data, 'carrier_name': selectedCarrier },
                    email: particularFieldValue.email
                }
                dispatch({ type: sagaActions.SAVE_DATA_FROM_SIGNUP_LISTNER_TO_REDUX, payload: dataTemp });
            } else if (event === 'autoSignIn_failure') {
                showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.carrierSignUp.savingUserDataError, 'light');
            }
        })
    };
    /**
     * This function initializes paytrace with client key
     */
    const paytraceUIForm = () => {
        PTPayment.setup({
            styles,
            authorization: { clientKey: paytraceClientId }
        }).then((instance) => {
            setShowIFrameLoader(false);
            PTPayment.getControl("creditCard").label.text(AppConstant.smartScheduler.carrierSignUp.creditCardForm.cardNumber);
            setInstance(instance);
        })
            .catch((err) => {
                setShowIFrameLoader(false);
                showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.carrierSignUp.paytrace.initPaytraceError + err.toString(), 'light')
            });
    }
        /**
     * function to register user on AWS cognito
     * @returns 
     */
    const registerUserOnAWS = async () => {
        let contactNumTemp = userSignUpData.contact_number;
        if (userSignUpData.contact_number.length > 14) {
            contactNumTemp = removeLastCharacterFromString(userSignUpData.contact_number);
        }

        const userSignUp = await Auth.signUp({
            username: userSignUpData.email,
            password: userSignUpData.password_text,
            attributes: {
                given_name: userSignUpData.first_name,
                family_name: userSignUpData.last_name,
                phone_number: formatContactNumForAWS(contactNumTemp),
                email: userSignUpData.email,
                'custom:isCarrierSignup': "true"
            },
            autoSignIn: {
                enabled: true,
            }
        }).then((resp) => {
            return resp
        })
            .catch((err) => {
                setShowIFrame(false)
                setIsPaymentLoading(false)
                showAlert(toast.TYPE.ERROR, AppConstant.smartScheduler.carrierSignUp.carrierFormTitles.signUpError + ' ' + err.toString().split(':')[1].toString(), 'light');
                return err
            })

        return userSignUp;
    }
    /**
     * Handler function for Payment Submit button
     */
    const handlePayment = async () => {
        setIframValidationErrors([])
        PTPayment.validate(function (validationErrors) {
            if (validationErrors.length >= 1) {
                setIframValidationErrors(validationErrors)
                validationErrors.map((ele, index) => {
                    const errorStr = `${AppConstant.smartScheduler.carrierSignUp.paytrace.cardValidationError} ${ele.description}`;
                })
            } else {
                setIframValidationErrors([])
                setIsPaymentLoading(true);
                registerUserOnAWS()
                    .then((resp) => {

                    })
                    .catch((err) => {
                        setShowIFrame(false)
                        setIsPaymentLoading(false)
                        const errorStr = `${AppConstant.smartScheduler.carrierSignUp.registerUserError} ${err}`
                        showAlert(toast.TYPE.ERROR, errorStr, 'light')
                    })
            }
        });
    }
    /**
     * 
     * @returns JSX to static signup text
     */
    const showCancelSignUpText = () => {
        const arr = ['Easy, Accurate & detailed Scheduling Appointment Calendar',
            'Integrated Warehouse & Carrier Calendar Schedules',
            'Bulk Purchase Order Upload Template or Manual PO Scheduling',
            'Appointment Management & Views of upcoming & past appointments',
            'Real-time email confirmation of Appointments for scheduled, rescheduled, or Deleted'
        ]

        return (
            arr.map((ele, i) => {
                return (
                    <li>{ele}</li>
                )
            })
        )
    }
    /**
     * renders static data for payment process
     * @returns JSX 
     */
    const createForm = () => {
        return (
            <div className='fs-6 p-2'>
                <div className='fs-5 fw-bolder my-2'>
                    This is the last step for the sign-up process.
                    If you cancel now you will have to sign-up again.<br />
                </div>
                <div className='fs-5 fw-bolder my-2'>
                    You will miss out on the following benefits:<br />
                </div>
                <div className='paymentInfoText '>
                    <ul>
                        {showCancelSignUpText()}
                    </ul>
                </div>
                <div className='fs-5 my-2'>
                    {AppConstant.smartScheduler.carrierSignUp.signOutConfirmMessage}
                </div>
            </div>
        );
    };
     /**
     * 
     * @returns Cancel subscription confirmation popup
     */
    const showCancelConfirmationPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: false,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            title: AppConstant.smartScheduler.carrierSignUp.signOutConfirmMessage,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.no
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => popUpLogoutHandler(text)} closepopup={() => closeCurrentpopup()} themeSelected={themeSelected} showModal={showCancelModal} />
    }
     /**
     * function for confirmation logout popup
     */
    const popUpLogoutHandler = (title) => {
        if (title === AppConstant.commonStrings.no) {
            setShowCancelModal(false);
        } else {
            navigate('/')
        }
    }
     /**
     * function to close popup
     */
    const closeCurrentpopup = () => {
        setEditData({});
        setSelectedCarrier(null);
        showCancelModal(true);
    }
     /**
     * function for signup loader
     */
    const showFinalSignupLoader = () => {
        return (
            <div className="spinner-border" role="status"><span className="sr-only">{AppConstant.commonStrings.loading}...</span></div>
        )
    }
     /**
     * 
     * @returns iframe error JSX
     */
    const showValidationErros = () => {
        return iFrameValidationErrors.map((ele, index) => {
            return (<div className='text-danger iFrameErrorText'>&bull; {ele.description} </div>)
        })
    }
    /**
     * 
     * @returns iframe Loader
     */
    const showIFrameLoaderUI = () => {
        return (<div className='placeholder-glow'>
            <span className="placeholder mt-3 col-12" />
            <span className="placeholder mt-2 col-6" />
            <span className="placeholder mt-2 col-5 ms-5" /> </div>
        )
    }

    return (
        <div className='carrierSignUpParentContainer'>
            <div className='fw-semibold mb-3'>{AppConstant.smartScheduler.carrierSignUp.introText1}</div>
            {
                !showIFrame ?
                    <div className='carrierSignUpFormContainer rounded-3 p-3'>
                        <div className='fw-light '>{AppConstant.smartScheduler.carrierSignUp.createAccountTitle}</div>
                        <CustomDynamicForm
                            formFieldList={formFieldList}
                            sort={true}
                            cancelBtn={cancelbtndata}
                            submitbtn={submitbtnData}
                            themeSelected={themeSelected}
                            getFormFieldData={getFormFieldData}
                            getSummitedFormData={getSummitedFormData}
                            editFormData={editData}
                            loading={loading}
                            resetFormData={resetFormData}
                            setParticularFieldValue={particularFieldValue}
                        />
                    </div>
                    :
                    <div className='d-flex flex-column p-1'>
                        {
                            !showPaymentSuccessView ?
                                <div>
                                    {!isPaymentLoading ? <svg onClick={() => setShowIFrame(false)} className='bg-transparent mb-4 cursor-pointer' id="i-arrow-left" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 32 32" width="25" height="25" fill="none"
                                        stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                        <path d="M10 6 L2 16 10 26 M2 16 L30 16" />
                                    </svg> : ''}
                                    <div className='text-start'>{`${AppConstant.smartScheduler.carrierSignUp.hiTitle} ${userFirstName},`}</div>
                                    <div className='my-2 fs-6'>{AppConstant.smartScheduler.carrierSignUp.completeSignUpIntro1} <b className='fs-5'>: {AppConstant.smartScheduler.carrierSignUp.priceTitle}</b></div>
                                    {isPaymentLoading && <div className='my-2 fs-6 text-danger'>{AppConstant.smartScheduler.carrierSignUp.paytrace.paymentWarning1}</div>}
                                    <div className='paytraceIFrame d-flex justify-content-center flex-column mt-3'>
                                        {!iFramePaymentCompletedFlag ? <div id="pt_hpf_form" className='ms-1 p-0 mb-2' /> : <div>{AppConstant.smartScheduler.carrierSignUp.paytrace.paymentWarning2}</div>}
                                        {showIFrameLoader ? showIFrameLoaderUI() : ''}
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                {iFrameValidationErrors.length ? showValidationErros() : ''}
                                            </div>
                                            <div className='container-fluid d-flex justify-content-between m-0 p-0 mt-3 pe-4 '>
                                                <div>
                                                    <img src={PaymentCardsImage} alt="Cards" className='cardsImage' />
                                                </div>
                                                <span data-tip={"Security Code"} data-for='SecurityCode'>
                                                    <img src={AppConstant.commonStrings.cscReferenceImageLink} alt="Cards" className='cardsCVVImage' />
                                                    <CustomTooltip id='SecurityCode' position='top' type='light' multiline={true} html={true} />
                                                </span>
                                                <div>
                                                    <CustomButton
                                                        type="submit"
                                                        className={`cancelPaymentButton me-4 iFrameButtons ${isPaymentLoading ? 'disabled' : ''}`}
                                                        data-bs-toggle="modal"
                                                        onClick={() => !isPaymentLoading && setShowCancelModal(true)}
                                                        title={AppConstant.commonStrings.cancel}>
                                                    </CustomButton>
                                                    <CustomButton
                                                        type="submit"
                                                        isLoading={isPaymentLoading}
                                                        className={`loginButton iFrameButtons ${isPaymentLoading ? 'disabled' : ''}`}
                                                        onClick={() => !isPaymentLoading && handlePayment()}
                                                        title={AppConstant.commonStrings.subscribe}></CustomButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='mb-3 pb-3'>
                                    <div className='text-start fs-5 fw-bold'>{`${AppConstant.commonStrings.welcome} ${userFirstName},`}</div>
                                    <div className='my-4 fs-8'>{AppConstant.smartScheduler.carrierSignUp.signUpMessage1} <b className='fs-5'>{AppConstant.commonStrings.freightSmith}</b></div>
                                    <div className='mt-4 mb-3 fs-5'>{AppConstant.smartScheduler.carrierSignUp.signUpMessage2}</div>
                                    {showFinalSignupLoader()}
                                </div>
                        }
                        {showCancelConfirmationPopup()}
                        {
                            !showPaymentSuccessView &&
                            <></>
                            // <div className='mt-4 w-100 text-start paymentInfoText'>{AppConstant.smartScheduler.carrierSignUp.conditionMessage1}<br />{AppConstant.smartScheduler.carrierSignUp.conditionMessage1} <b>{AppConstant.smartScheduler.carrierSignUp.accountPage}</b>.</div>
                        }
                    </div>
            }
            {
                <div className="fs-5 d-flex justify-content-between mt-4 p-0">
                    {
                        !isPaymentLoading ?
                            <div className='d-flex fw-light'>
                                {AppConstant.smartScheduler.carrierSignUp.haveAccountAlready}
                                <a className='downloadPOTemplateLink ms-2 row align-items-end cursor-pointer my-2' href="/">{AppConstant.smartScheduler.carrierSignUp.loginHere}</a>
                            </div>
                            :
                            ''
                    }
                    {/* <a className='downloadPOTemplateLink me-3 row align-items-end my-2 ms-3' href='/'>{AppConstant.smartScheduler.carrierSignUp.tnC}</a> */}
                </div>
            }
        </div>
    )
}

export default FormCarrierSignUp;