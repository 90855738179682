import React, { useEffect, useState } from 'react'
import PasswordInputSS from '../../../../../Common/PasswordInput/PasswordInputSS'
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS'
import success from '../../../../../../Assests/Images/success-icon.png'

import errorIcon from '../../../../../../Assests/Images/error-icon.png'
import { AppConstant } from '../../../../../../Assests/AppConstant'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getAuthReducer } from '../../../../../../../redux/reducers/reducerSlices/AuthReducer'
import { toast } from 'react-toastify'
import { sagaActions } from '../../../../../../../sagas/sagaActions'
import { isValid } from '../../../../../Login/utils'
import { showAlert } from '../../../../../../Assests/Utility'
/**
 * This component provides user functionality to change his own password with new UI.
 * @param {*} props 
 * @returns 
 */
function SettingsChangePasswordSS(props) {
    const { themeSelected } = props;
    const dispatch = useDispatch();
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setnewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState(false)
    const [isloading, setisloading] = useState(false)
    const [passwordError, setpasswordError] = useState(null)
    const [oldpasswordError, setoldpasswordError] = useState(null)
    const auth = useSelector(getAuthReducer);
    const { changePasswordSuccess, changePasswordError } = auth;

    /**
     * useEffect for newPassword and confirmPassword
     */
    useEffect(() => {
        if (isValid('password', newPassword) && isValid('password', confirmPassword)) {
            setError(false)
        } else {
            setError(true)
        }
    }, [newPassword, confirmPassword])
    /**
     * useEffect for changePasswordSuccess and changePasswordError
     */
    useEffect(() => {
        if (changePasswordSuccess) {
            showAlert(toast.TYPE.SUCCESS, AppConstant.login.userChangePassword, themeSelected)
            setOldPassword('')
            setnewPassword('')
            setConfirmPassword('')
            dispatch({ type: sagaActions.UPDATE_CHANGE_PASSWORD_SUCCESS, payload: false })
            setisloading(false)
        }
        if (changePasswordError) {
            dispatch({ type: sagaActions.UPDATE_CHANGE_PASSWORD_ERROR, payload: false })
            setisloading(false)
        }
    }, [changePasswordSuccess, changePasswordError])

    /**
     * This function handler function for SUBMIT change password functionality
     * @returns 
     */
    const initiateChangePassword = () => {
        if (oldPassword === newPassword) {
            setError(true)
            setoldpasswordError(AppConstant.login.oldvsnewMismatch)
        }
        if (newPassword !== confirmPassword) {
            setError(true)
            return setpasswordError(AppConstant.login.passwordMismatch)
        }

        setError(false)
        setisloading(true)
        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword
        }


        dispatch({ type: sagaActions.USER_CHANGE_PASSWORD, payload: data })
    }


    return (
        <div className='acc-settings-body px-3 '>
            <div className='d-flex w-100 gap-3 acc-settings-security'>
                <div className='acc-settings-form'>
                    <h4>{AppConstant.login.changePassword}</h4>
                    <PasswordInputSS
                        errorMessage={AppConstant.login.oldvsnewMismatch}
                        showError={oldpasswordError}
                        title={AppConstant.login.oldPassword}
                        placeHolder={AppConstant.login.enterHere}
                        onPasswordChange={(e) => setOldPassword(e)}
                        value={oldPassword}
                        customClass={'passwordTextInput'}
                    />
                    <PasswordInputSS
                        errorMessage={AppConstant.login.oldvsnewMismatch}
                        showError={passwordError}
                        title={AppConstant.login.newPassword}
                        placeHolder={AppConstant.login.enterHere}
                        onPasswordChange={(e) => setnewPassword(e)}
                        passwordInfo={AppConstant.login.recover.passwordInfoTooltip}
                        value={newPassword}
                        customClass={'passwordTextInput'}
                    />
                    <PasswordInputSS
                        errorMessage={AppConstant.login.passwordValidation}
                        showError={passwordError}
                        title={AppConstant.login.confirmNewPassword}
                        placeHolder={AppConstant.login.enterHere}
                        onPasswordChange={(e) => setConfirmPassword(e)}
                        value={confirmPassword}
                        customClass={'passwordTextInput'}
                    />
                    <CustomButtonSS
                        isLoading={isloading}
                        title={AppConstant.commonStrings.update} className={"btn-highlighted w-50"}
                        onClick={() => initiateChangePassword()}
                    />
                </div>

                <div className='acc-settings-passwrod-indicator'>
                    <p className='f-12'>Password Requirements</p>
                    <div className='d-flex gap-1'>
                        <img src={success} alt='success' />
                        <span>at least 8 character long</span>
                    </div>
                    <div className='d-flex gap-1'>
                        <img src={errorIcon} alt='error' />
                        <span className='text-danger'>must contain a number</span>
                    </div>
                    <div className='d-flex gap-1'>
                        <img src={success} alt='success' />
                        <span>must contain a special character</span>
                    </div>
                    <div className='d-flex gap-1'>
                        <img src={errorIcon} alt='error' />
                        <span className='text-danger'>must contain one uppercase</span>
                    </div>
                    <div className='d-flex gap-1'>
                        <img src={errorIcon} alt='error' />
                        <span className='text-danger'>must contain one lowercase</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsChangePasswordSS