import React from 'react';
import DatePicker from "react-datepicker";
import './CustomDatePickerMMYYYYonly.css'

const CustomDatePickerMMYYYYonly = (props) => {
  const { date, setDate, handleCalendarChange, onChangeHandler, label, wrapperClassName, maxDate, themeSelected } = props;
  return (
    <div className={wrapperClassName}>
      <label className='boldText m-0 f-14'><i className="fa-regular fa-calendar"></i> {label} </label>
      <DatePicker
        className={`datepicker ${themeSelected} reports-select-date`}
        selected={date}
        onChange={onChangeHandler}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        onYearChange={(d) => handleCalendarChange(d.getFullYear())}
        maxDate={maxDate}
      />
    </div>
  )
}

export default CustomDatePickerMMYYYYonly