import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { sagaActions } from '../../../../../../sagas/sagaActions';
import { getEclipseOpsPaymentLoadselector } from '../../../../../../redux/reducers/reducerSlices/EclipseOpsPaymentLoadReducer';
import DrilldownTableSS from '../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import moment from 'moment';
import { AppConstant } from '../../../../../Assests/AppConstant';

/**
 * Component is used in the FS Pay Payments, where this compnent rendered as pop-up to show the FS PO history.
 * In this componenet calling FS Pay Payments PO history and getting the details
 * @param {*} props 
 * @returns 
 */
const FSPayPOHistory = (props) => {
    const { themeSelected = 'light-mode', data } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const eclipseOpsReducer = useSelector(getEclipseOpsPaymentLoadselector)
    const { arrangeOrder, arrangeKey, isLoading, initiateLoading, fsPayPOHistoryList  } = eclipseOpsReducer;
    const divRef = useRef(null);
    const [showLoading, setShowLoading] = useState(true);

    const [fsPayPOHistoryListState, setFsPayPOHistoryList] = useState([]);
 

    /**
     * used to populate the default table headers 
     */
    const apptsTitleDD = [
        { id: 'serial_num', name: 'S.No' },
        { id: 'poNumber', name: 'PO' },
        { id: 'paymentStatus', name: 'Comments' },
        { id: 'Field36', name: 'Driver Number' },
        { id: 'updatedDate', name: 'Updated Date' },
        { id: 'Login', name: 'Updated By' },
    ]


    //used to reset FSPay PO History list
    useEffect(() => {
        dispatch({ type: sagaActions.RESET_FS_PAY_PO_HISTORY });
        setShowLoading(true);
        setFsPayPOHistoryList([]);
    }, [])

    //used to set the FSPay PO history list data to the state variable
    useEffect(() => {
        if (fsPayPOHistoryList && fsPayPOHistoryList.length) {
            setShowLoading(false);
            setFsPayPOHistoryList(fsPayPOHistoryList)
        } else {
            setFsPayPOHistoryList([])
            setShowLoading(false)
        }

    }, [fsPayPOHistoryList])

    useEffect(() => {
        fetchApptsReportHistoryDetails()
    }, [])

//used to fetch FSPay PO history list
const fetchApptsReportHistoryDetails = () => {
    const apiParams = {
        "RecID": data.RecID
    }
    dispatch({ type: sagaActions.GET_FS_PAY_PO_HISTORY, payload: apiParams });
}

const mapTransactionStatus = (data) => {
    const { transactionStatus } = data
    // const phoneNumberUpdate = `Old Number: ${data.oldPhoneNumber}`
    switch (transactionStatus) {
        case 'PO_DELETED':
            return 'PO Deleted'
        case 'CCCOLLECT_UPDATED':
            return 'Amount Updated'
        case 'RESTACK_UPDATED':
            return 'Amount Updated'
        case 'PONUMBER_UPDATED':
            return 'PO Number Updated'
        case 'PHONE_NUMBER_UPDATED':
            return 'Phone Number Updated'
        case 'PAYMENT_METHOD_UPDATED':
            return `Payment Method Updated to ${data.Field31}`

        default:
            return transactionStatus || '-  '
        }
    }
    
    // const oldMapping = (data) => {
    //     const { transactionStatus } = data;
    //     switch (transactionStatus) {
    //         case 'PO_DELETED':
    //             return '-'
    //         case 'CCCOLLECT_UPDATED':
    //             return '-'
    //         case 'RESTACK_UPDATED':
    //             return '-'
    //         case 'PONUMBER_UPDATED':
    //             return '-'
    //         case 'PHONE_NUMBER_UPDATED':
    //             return data.oldPhoneNumber
    //         case 'PAYMENT_METHOD_UPDATED':
    //             return data.oldPaymentMethod
    //         default:
    //             return '-'
    //         }
    // }

    // const newMapping = (data) => {
    //     const { transactionStatus } = data;
    //     switch (transactionStatus) {
    //         case 'PO_DELETED':
    //             return '-'
    //         case 'CCCOLLECT_UPDATED':
    //             return '-'
    //         case 'RESTACK_UPDATED':
    //             return '-'
    //         case 'PONUMBER_UPDATED':
    //             return '-'
    //         case 'PHONE_NUMBER_UPDATED':
    //             return data.Field36
    //         case 'PAYMENT_METHOD_UPDATED':
    //             return data.Field31
    //         default:
    //             return '-'
    //         }
    // }

/**
 * Used to format the appt details to show
 * @param {*} data 
 * @returns 
 */
const formatFSPayHistoryListDataDrillDown = (data) => {
    return data.map((el, index) => {
            return {
                id: el.RecID,
                0: index + 1,
                1: el.PONumber,
                2: mapTransactionStatus(el),
                3: el.Field36,
                4: el.updatedDate ? `${moment(el.updatedDate).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.updatedDate).utc().format(AppConstant.commonStrings.HH_mm_dateFormat)}` : AppConstant.commonStrings.hypen,
                5: el.Login,
        }
    })
}



/**
 * Function to get the FSPay PO history body data
 * @returns 
 */
const getHistoryBodyData = () => {
    if (fsPayPOHistoryListState && fsPayPOHistoryListState.length) {
       return formatFSPayHistoryListDataDrillDown(fsPayPOHistoryListState)
    }
    else {
        return []
    }
}

return (
                    <div className='p-3' style={{ width: 'auto', height: 'auto' }}>
                        <div className='f-16'>{`PO History  ${data.PONumber}`}</div>
                        <div className='allApptsListContainer-ss mt-3'>
                            <DrilldownTableSS
                                key={Math.random()}
                                themeSelected={themeSelected}
                                bodyData={getHistoryBodyData()}
                                titleData={apptsTitleDD}
                                showCollapse={false}
                                showInput={false}
                                showSort={false}
                                hasCollapsableContent={false}
                                arrangeOrder={arrangeOrder}
                                arrangeKey={arrangeKey}
                                drillDownReport={fsPayPOHistoryListState}
                                initiateDrillDownReport={isLoading}
                                loaderArray={6}
                                sortingAction={sagaActions.SORT_SS_USER_TABLE}
                                subRoot={{ pathName: 'fsPayPoHistoryObj' }}
                                showUTCTime={true}
                            />
                        </div>
                    </div>
)
}

export default FSPayPOHistory
