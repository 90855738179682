import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoadSelector } from '../../../../../../../redux/reducers/reducerSlices/getLoadReducer';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import Loader from '../../../../../Loader/Loader';
import DoorAssignmentCard from './DoorAssignmentCard/DoorAssignmentCard';
import _ from 'lodash';
import './DoorAssignmentList.css'
import { AppConstant } from '../../../../../../Assests/AppConstant';
import Popupold from '../../../../../Common/Popup/Popupold';
import { toast } from "react-toastify";
import { mapDoorStatusToID, modifyDoorList, shouldHavePermission, showAlert } from '../../../../../../Assests/Utility';
import IconButtons from '../../../../../Common/IconButtons/IconButtons';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';

const DoorAssignmentList = (props) => {
    const dispatch = useDispatch();
    const { themeSelected } = props;
    const getLoad = useSelector(getLoadSelector);
    const [selectedDoorFilter, setSelectedDoorFilter] = useState(AppConstant.doorManagement.doorFilters.door_filter_all);
    const [selectedDoor, setSelectedDoor] = useState({});
    const { doorList, enableDoorSection, assignedDoor, getLoadLoading, dock_id, selectedLoad, releasedDoorLoadsArr } = getLoad;
    const [tempDoorList, setTempDoorList] = useState([])
    const [releasedDoorArr, setReleasedDoorArr] = useState([])
    const [sendDriverNotification, setSendDriverNotification] = useState(true)
    const userPermission = useSelector(getUserSelector);
    const { permissions } = userPermission;
    const [allowPermission, setAllowPermission] = useState(false);

    useEffect(() => {
        if(permissions.length){
            const warehouseManagementPermission = _.find(permissions, { 'FeatureID': '8', 'PageAccess': true });
            if(warehouseManagementPermission && !_.isEmpty(warehouseManagementPermission)){
                setAllowPermission(true)
            }else{
                let temp = shouldHavePermission(permissions, '702');
            setAllowPermission(temp)
            }            
        }

    },[])

    let className = '';

    useEffect(() => {
        if(releasedDoorLoadsArr){
            //show notification
            showAlert(toast.TYPE.ERROR, AppConstant.doorManagement.doorReleasedSuccessNotification, themeSelected)
            
            dispatch({ type: sagaActions.RESET_RELEASE_DOOR });
        }
    }, [releasedDoorLoadsArr])

    useEffect(() => {
        setTempDoorList([...modifyDoorList(doorList)]);
    }, [doorList])

    useEffect(() => {
        renderLoadDetails();
        renderChangeDoorOption()
    }, [assignedDoor, enableDoorSection])

    useEffect(() => {
        const { dock_id, door, door_status, door_type, load_control_num } = selectedLoad;
        // By Default Send Driver Notification in Popup should alway be checked
        setSendDriverNotification(true)
        if (door) {
            const nSDoor = {
                dock_id,
                load_control_num,
                door,
                door_status: door_status || AppConstant.doorManagement.assignDoorText.display_door_status_assigned,
                door_type,
                status_id: mapDoorStatusToID(AppConstant.doorManagement.assignDoorText.display_door_status_occupied),
            }
            dispatch({ type: sagaActions.ALREADY_ASSIGNED_DOOR, payload: nSDoor })
            return;
        }
        dispatch({ type: sagaActions.REMOVE_ASSIGN_DOOR })

        if(selectedLoad.truck_load_status === 'Released' || selectedLoad.bills_out_time || selectedLoad.outdoor_time){
            removeReleaseDoorFromState()
        }
    }, [selectedLoad])

    const removeReleaseDoorFromState = () => {
        const newArr = releasedDoorArr.filter(el => el.load_control_num !== selectedLoad.load_control_num);
        setReleasedDoorArr([...newArr])
    }

    const clickHandler = (sDoor) => {

        if (!_.isEmpty(sDoor)) {
            const { load_control_num } = selectedLoad;
            const nSDoor = {
                ...sDoor,
                dock_id,
                load_control_num,
                door: sDoor.door,
                status_id: mapDoorStatusToID(AppConstant.doorManagement.assignDoorText.display_door_status_occupied),
                driverNotified: sendDriverNotification
            }
            dispatch({ type: sagaActions.ASSIGN_DOOR, payload: [nSDoor] })
            setSendDriverNotification(true)
        }
    }
    const popupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.confirm) {
            if (assignedDoor.length) {
                const { dock_id, load_control_num, door, door_status, door_type } = assignedDoor[0];
                const nSDoor = {
                    door_type,
                    dock_id,
                    load_control_num,
                    door,
                    door_status,
                    status_id: mapDoorStatusToID(AppConstant.doorManagement.assignDoorText.api_door_status_available),
                }
                dispatch({ type: sagaActions.CHANGE_DOOR, payload: [nSDoor] });
            }
        }else if(text === AppConstant.commonStrings.cancel){
            setSendDriverNotification(true)
        }
    }

    const renderLoadDetails = () => {
        if (getLoadLoading) {
            return renderDoors(true)
        } else if (!getLoadLoading && _.isEmpty(assignedDoor)) {
            return renderDoors(false)
        } else {
            return renderDoors(false)
        }
    }

    const renderDoors = (showLoader) => {
        if (showLoader) return <div className="w-100"><Loader height={35} count={1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
            baseColor={themeSelected === 'dark-mode' ? '#575757'
                : ''}
        /></div>
        else {
            const noDoor = AppConstant.doorManagement.doorOption.noDoor
            if (!enableDoorSection) {
                className = 'disabled'
            }
            if(isLoadReleased()){
                className = 'disabled'
                
                return assignedDoor.length && assignedDoor.map((el, i) => {
                    if (i < 4) return <DoorAssignmentCard key={el.door_type + '_' + el.door} {...el} className={className} themeSelected={themeSelected} clickHandler={() => clickHandler(el)} />
                })
            } else if (!assignedDoor.length) {
                if (!checkifNoAvailableDoor()) {
                    return <DoorAssignmentCard key={noDoor.door_type + '_' + noDoor.door} {...noDoor} themeSelected={themeSelected} />
                }
                className = allowPermission ? '' : 'disabled'
                return doorList.length ? doorList.map((el, i) => {
                    if ((el.door_status.includes(AppConstant.doorManagement.assignDoorText.api_door_status_available) || el.door_status.includes(AppConstant.doorManagement.assignDoorText.api_door_status_empty)) && i < 4) {
                        return (
                            <DoorAssignmentCard key={el.door_type + '_' + el.door}
                                {...el} className={className}
                                themeSelected={themeSelected}
                                clickHandler={() => clickHandler(el)} />
                        )
                    }
                }) : ''
            } else if (assignedDoor.length && !isLoadReleased()) {
                className = 'active'
                if(!allowPermission){
                    className = 'disabled'
                }
                return assignedDoor.length && assignedDoor.map((el, i) => {
                    if (i < 4) return <DoorAssignmentCard key={el.door_type + '_' + el.door} {...el} className={className} themeSelected={themeSelected} clickHandler={() => clickHandler(el)} />
                })
            } else {
                className = '';
                if (!checkifNoAvailableDoor()) {
                    return <DoorAssignmentCard key={noDoor.door_type + '_' + noDoor.door} {...noDoor} themeSelected={themeSelected} />
                }
                return doorList.length ? doorList.map((el, i) => {
                    if ((el.door_status.includes(AppConstant.doorManagement.assignDoorText.api_door_status_available) || el.door_status.includes(AppConstant.doorManagement.assignDoorText.api_door_status_empty)) && i < 4) return <DoorAssignmentCard key={el.door_type + '_' + el.door} {...el} className={className} themeSelected={themeSelected} clickHandler={() => clickHandler(el)} />
                }) : ''
            }
        }
    }

    const renderChangeDoorOption = () => {
        const { id } = AppConstant.doorManagement.doorOption.popupAttr;
        const { changeDoor, more, releasedDoorTitle } = AppConstant.doorManagement.doorOption;

        const setDoorTitle = () => {
            if(isLoadReleased()){
                return 'Release Door'
            }else if (assignedDoor.length &&
                (selectedLoad.truck_load_status === 'Unloading End' || selectedLoad.lump_end_time) &&
                !selectedLoad.bills_out_time) {
                return releasedDoorTitle;
            } else if (assignedDoor.length) {
                return changeDoor;
            } else {
                return more;
            }
        }

        if (disableDoorPerStage() || isLoadReleased()) {
            className = 'disabled';
        }

        if (!isLoadReleased() && assignedDoor.length &&
            (selectedLoad.truck_load_status === 'Unloading End' || selectedLoad.lump_end_time) &&
            !selectedLoad.bills_out_time) {
            className = 'released';
        }


        return doorList.length ? <div className={`door-card-wrapper d-flex flex-column justify-content-center align-items-center m-2 ${themeSelected} ${className}`}>
            <button className={`doorOption text-center ${className}`} data-bs-toggle="modal" data-bs-target={`#${id}`}>{setDoorTitle()}</button>
        </div> : ''
    }

    const isLoadReleased = () => {

        if(_.includes(releasedDoorArr, selectedLoad.load_control_num) || selectedLoad.truck_load_status === 'Released' ){
            return true;
        } else {
            return false;
        }
    }

    const checkifNoAvailableDoor = () => {
        return doorList.length && doorList.some(el => !_.isEmpty(el) && el.door_status.includes(AppConstant.doorManagement.assignDoorText.api_door_status_available) || el.door_status.includes(AppConstant.doorManagement.assignDoorText.api_door_status_empty));
    }

    const disableDoorPerStage = () => {
        if (selectedLoad.lump_end_time || selectedLoad.lump_start_time) {
            return true
        }
    }


    const selectDoor = (el) => {
        if (el.className === 'operable') {
            let selectedDoor = { ...el };
            selectedDoor.className = 'selected';
            setSelectedDoor(selectedDoor);

            tempDoorList.map((doorObj) => {
                if (doorObj.door === el.door) {
                    doorObj.selected = true
                } else {
                    doorObj.selected = false
                }
            });
        }

    }

    const allDoorArr = () => {
        return tempDoorList.map((el) => {
            if (selectedDoorFilter === AppConstant.doorManagement.doorFilters.door_filter_all || selectedDoorFilter === '') {
                return (
                    <div className='col-1'>
                        <DoorAssignmentCard key={el.door_type + '_' + el.door} {...el}
                            themeSelected={themeSelected} type='more_doors'
                            clickHandler={() => selectDoor(el)} />
                    </div>
                )
            } else {
                if (el.className.toLowerCase() === selectedDoorFilter.toLowerCase().replaceAll(' ', '')) {
                    return (
                        <div className='col-1'>
                            <DoorAssignmentCard key={el.door_type + '_' + el.door} {...el}
                                themeSelected={themeSelected} type='more_doors'
                                clickHandler={() => selectDoor(el)} />
                        </div>
                    )
                } else {
                    return '';
                }
            }
        })
    }

    const selecteDoorFilter = (doorFilter) => {
        setSelectedDoorFilter(doorFilter);
    }

    const getDoorFilterArray = () => {
        const doorFilterList = [AppConstant.doorManagement.doorFilters.door_filter_all,
        AppConstant.doorManagement.doorFilters.door_filter_available,
        AppConstant.doorManagement.doorFilters.door_filter_blocked,
        AppConstant.doorManagement.doorFilters.door_filter_in_use,
        AppConstant.doorManagement.doorFilters.door_filter_inoperable]
        return doorFilterList.map((el) => {
            return (
                <div className='d-flex justify-content-start mx-1'>
                    <IconButtons title={el} themeSelected={themeSelected} selectedDoorFilter={selectedDoorFilter}
                        onClickHandler={() => selecteDoorFilter(el)} />
                </div>
            )
        })
    }

    const assignSelectedDoor = () => {
        selectedDoor.door && clickHandler(selectedDoor)
    }

    const showAllDoorsPopup = () => {

        const allDoorsDiv = () =>
        (
            <div className='container-fluid py-2'>
                <p className='text-start'>{AppConstant.doorManagement.additionalDoorFilterHeadingText}</p>
                <span className='d-flex flex-row mb-3 w-auto filtersSection'>
                    <div className='text-start mx-2 mb-3'>
                        {AppConstant.doorManagement.additionalDoorFilterSelectText}
                    </div>
                    {getDoorFilterArray()}
                </span>
                <span className='row mb-3 filtersSection allowScrolling'>
                    {(_.compact(allDoorArr())).length > 0 ? allDoorArr()
                        :
                        <div className='container-fluid text-center m-2 mt-5'>
                            {AppConstant.doorManagement.no_doors_available_title}
                        </div>
                    }
                </span>
            </div>
        )

        const popupObj = {
            id: 'changeDoorPopup',
            modalLabel: 'changeDoorPopup',
            showClose: true,
            showfooter: true,
            showHeader: true,
            modalSize: 'xl',
            title: AppConstant.doorManagement.doorAssignment,
            bodyText: allDoorsDiv(),
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.doorManagement.assignDoorText.display_door_status_available
                }
            },
        }

        return <Popupold {...popupObj} popupBtnHandler={(text) => assignSelectedDoor()} themeSelected={themeSelected} />
    }

    const releaseDoorPopupBtnHandler = (text) => {
        if (text === AppConstant.commonStrings.yes) {
            //Release Door
            const releaseDoorObj = {
                "load_control_num": selectedLoad.load_control_num,
            }
            setReleasedDoorArr([...releasedDoorArr, (selectedLoad.load_control_num)]);
            // dispatch({ type: sagaActions.REMOVE_ASSIGN_DOOR });
            dispatch({ type: sagaActions.RELEASE_DOOR, payload: releaseDoorObj });

        }
    }

    const showDoorActionPopup = () => {
        if (assignedDoor.length &&
            (selectedLoad.truck_load_status === 'Unloading End' || selectedLoad.lump_end_time) &&
            !selectedLoad.bills_out_time) {
            return <Popupold {...AppConstant.doorManagement.doorOption.popupReleaseDoorAttr} popupBtnHandler={(text) => releaseDoorPopupBtnHandler(text)} themeSelected={themeSelected} />
        } else {
            let oProps = {...AppConstant.doorManagement.doorOption.popupAttr, sendDriverNotification, setSendDriverNotification}
            return <Popupold {...oProps} popupBtnHandler={(text) => popupBtnHandler(text)} themeSelected={themeSelected} />
        }
    }

    return (
        <div className='d-flex justify-content-start flex-wrap pt-2'>
            {renderLoadDetails()}
            {renderChangeDoorOption()}
            {
                assignedDoor.length ?
                    showDoorActionPopup()
                    :
                    <div>
                        {showAllDoorsPopup()}
                    </div>
            }
        </div>
    );
}

export default DoorAssignmentList;