import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    loading: false,
    data:''
} 

export const SendEmailForAppointment = createSlice({
    name: 'SendEmailForAppointment',
    initialState,
    reducers: {
        initiateLoading: (state) => {
          state.loading = true;
          state.error=null;
          state.data = ''; 
         },
        addSendEmailForAppointmentSuccess: (state, action) => {
           state.loading = false;
           state.data = action.payload; 
           state.error=null;
        },
        addSendEmailForAppointmentFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.data = ''; 
        },
        resetSendEmailForAppointmentData:(state,action)=>{
            
            state.loading = false;
            state.data = ''; 
            state.error=null;
        }
    }
});

export const { addSendEmailForAppointmentSuccess, addSendEmailForAppointmentFailure,initiateLoading,resetSendEmailForAppointmentData} = SendEmailForAppointment.actions;

// Selectors
export const sendEmailForAppointment = (state) => {
    return state.SendEmailForAppointment;
};

export default SendEmailForAppointment.reducer;
