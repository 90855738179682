import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import CommonToggleSwitch from '../../../../../../Common/CommonToggleSwitch/CommonToggleSwitch';
import DrilldownTable from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTable';
import CustomButton from '../../../../../../Common/CustomButton/CustomButton';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import { initiateSearch, showAlert, changeFormatWithoutTz } from '../../../../../../../Assests/Utility';
import { toast } from 'react-toastify';
import { getFormattedDate } from '../../../../../../../../Utils/DateUtils';
import { fetchLeavesListCommonFunc, fetchLeavesDocksListCommonFunc } from '../Common/FetchLeavesList';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { getWhMetaDataReducerSlice } from '../../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import './LeavesMetaList.css';
import moment from 'moment';
import DrilldownTableSS from '../../../../../Components/Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import CustomButtonSS from '../../../../../../Common/CustomButton/CustomButtonSS';
import EditIcon from '../../../../../../../Assests/Images/edit-icon-new.png'
import SSPopup from '../../../../../../Common/Popup/SSPopup/SSPopup';
import { format } from 'date-fns';

const LeavesMetaListSS = (props) => {

    const { themeSelected, editGroupAction, searchStr, allowPermissionE, allowPermissionD, editLeaveAction, whObj, currentFilterSelected } = props;
    const dispatch = useDispatch();
    const whMetaReducer = useSelector(getWhMetaDataReducerSlice);
    const { whMetaLeavesList, operationObj, arrangeOrder, arrangeKey ,preCheckList} = whMetaReducer;
    const [isListLoading, setListLoading] = useState(false);
    const [metaLeavesArr, setMetaLeavesArr] = useState([]);
    const user = useSelector(getUserSelector);
    const { selectedWarehouse, cu_id } = user;
    const [currentItemIndex, setCurrentItemIndex] = useState('NA');
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');
    const [selectedObj,setSelectedObj]=useState(null)
    const [selectedIndex,setSelectedIndex]=useState(null)
    const [showDeleteModel,setShowDeleteModel]=useState(false);

    useEffect(()=>{
        if (preCheckList && preCheckList.length>0) {
            setCurrentItemIndex("NA")
            setCurrentDeleteIndex('NA')
        }
    },[preCheckList])

    const dockTimeslotsTitleDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'LeaveStart', name: AppConstant.reports.adfatReport.dropdownLabels.startDate },
        { id: 'LeaveEnd', name: AppConstant.reports.adfatReport.dropdownLabels.endDate },
        { id: 'Description', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.description },
        { id: 'CreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'status', name: AppConstant.superUserAdmin.companyGroup.tableHeader.status },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    const dockLeavesTitleDD = [
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.serialNum },
        { id: 'Name', name: AppConstant.poManagement.tableHeader.dock },
        { id: 'LeaveStart', name: AppConstant.reports.adfatReport.dropdownLabels.startDate },
        { id: 'LeaveEnd', name: AppConstant.reports.adfatReport.dropdownLabels.endDate },
        { id: 'Description', name: AppConstant.smartScheduler.docksTimeSlots.tableHeaders.description },
        { id: 'CreatedOn', name: AppConstant.superUserAdmin.companyGroup.tableHeader.createdOn },
        { id: 'status', name: AppConstant.superUserAdmin.companyGroup.tableHeader.status },
        { id: '', name: AppConstant.superUserAdmin.warehouseAdmin.warehouseTable.actionsColTitle },
    ]

    useEffect(() => {
        setListLoading(true);
        if (currentFilterSelected.name === 'Warehouse') {
            fetchLeavesList();
        } else if (currentFilterSelected.name === 'Docks') {
            fetchDocksList();
        }
    }, [currentFilterSelected])

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Update status':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.leaves.updateLeaveStatusSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.leaves.updateLeaveStatusFailure);
                    }
                    break;
                case 'Delete Leaves':
                        if (operationObj.status) {
                                handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.leaves.deleteLeaveSuccess);
                        } else {
                                handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.leaves.deleteLeaveFailure);
                            }
                        break;
                case 'Fetch Leaves':
                    if (!operationObj.status) {
                        setListLoading(false);
                        setMetaLeavesArr([]);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    const handleApiResponse = (result, message) => {
        setListLoading(false);
        setCurrentItemIndex('NA')
        setCurrentDeleteIndex('NA')
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            if (currentFilterSelected.name === 'Warehouse') {
                fetchLeavesList();
            } else if (currentFilterSelected.name === 'Docks') {
                fetchDocksList();
            }
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }

    const fetchLeavesList = () => {
        const { apiParams, operationObj } = fetchLeavesListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const fetchDocksList = () => {
        const { apiParams, operationObj } = fetchLeavesDocksListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    useEffect(() => {
        if (whMetaLeavesList && whMetaLeavesList.length) {
            setListLoading(false);
            setCurrentItemIndex('NA');
            setCurrentDeleteIndex('NA')
            setMetaLeavesArr(whMetaLeavesList);
        } else {
            setMetaLeavesArr([])
            //need to remove later - added for demo
            setListLoading(false);
        }
    }, [whMetaLeavesList])

    useEffect(() => {
        if (searchStr.length > 1) {
            const searchedArr = initiateSearch(whMetaLeavesList, searchStr, 'GroupName');
            setMetaLeavesArr(searchedArr);
        } else if (searchStr === '') {
            setMetaLeavesArr(whMetaLeavesList);
        }
    }, [searchStr])

    const disableLeaveAction = (data, index) => {
        setCurrentItemIndex(index)
        const apiParams = {
            "IDWHLeave": data.IDWHLeave,
            "ID": data.ID,
            "IDWarehouse": data.IDWarehouse,
            "Purpose": data.Purpose,
            "LeaveStart": data.LeaveStart,
            "LeaveEnd": data.LeaveEnd,
            "Status": !data.Status,
            "CreatedOn": data.CreatedOn,
            "CreatedBy": cu_id,
            "UpdatedOn": data.UpdatedOn,
            "UpdatedBy": cu_id,
            "CRUDTYPE": "D",
            "IDDockLeave": data.IDDockLeave,
            "IDDock": data.IDDock,
            "Description": data.Description
        }
        const operationObj = {
            type: 'Update status',
            status: ''
        }

        if (currentFilterSelected.name === "Warehouse") {
            dispatch({ type: sagaActions.WAREHOUSE_META_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj });
        } else {
            dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj });
        }
    }

    const deleteHandler=(data,index)=>{
        setSelectedObj(data,index)
        setSelectedIndex(index)
        setShowDeleteModel(true)
    }
    const popupBtnHandler=(text)=>{
        if (text===AppConstant.commonStrings.no) {
            setShowDeleteModel(false)
            setSelectedObj(null)
            setSelectedIndex(null)
        }
        else{
            setCurrentDeleteIndex(selectedIndex)
            const apiParams = {
                "IDWHLeave": selectedObj.IDWHLeave,
                "ID": selectedObj.ID,
                "IDWarehouse": selectedObj.IDWarehouse,
                "Purpose": selectedObj.Purpose,
                "LeaveStart": selectedObj.LeaveStart,
                "LeaveEnd": selectedObj.LeaveEnd,
                "Status": true,
                "CreatedOn": selectedObj.CreatedOn,
                "CreatedBy": cu_id,
                "UpdatedOn": selectedObj.UpdatedOn,
                "UpdatedBy": cu_id,
                "CRUDTYPE": "R",
                "IDDockLeave": selectedObj.IDDockLeave,
                "IDDock": selectedObj.IDDock,
                "Description": selectedObj.Description
            }
            const operationObj = {
                type: 'Delete Leaves',
                status: ''
            }
    
            if (currentFilterSelected.name === "Warehouse") {
                dispatch({ type: sagaActions.WAREHOUSE_META_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj });
            } else {
                dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION, payload: apiParams, operationObj: operationObj });
            }
        }
        setShowDeleteModel(false)
    }
    const showDeleteConfirmation = () => {
        const popupObj = {
            id: 'showDeleteConfirmation',
            modalLabel: 'showDeleteConfirmation',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title:  AppConstant.smartScheduler.leaves.deleteLeaveTitle,
            bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.smartScheduler.leaves.deleteLeaveTitle}`,            
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => {popupBtnHandler(text)} } closepopup={()=>{setShowDeleteModel(false)}} themeSelected={themeSelected} showModal={showDeleteModel} />
    }




    const actionsButton = (groupObj, index) => {
        return (
          <div>
            <span
              className={
                "d-flex justify-content-center align-items-center gap-2 w-max"
              }
            >
              <img
                src={EditIcon}
                alt="edit"
                className={
                  allowPermissionE ? "editIconPO-ss" : "editIconPO-ss disabled"
                }
                data-bs-toggle="modal"
                data-bs-target={`#createCompanyGroupFormPopup1`}
                disabled={allowPermissionE ? "" : "disabled"}
                onClick={() => editLeaveAction(groupObj)}
              />
              {/* <button
                        className={allowPermissionE ? 'addButton' : 'addButton disabled'}
                        data-bs-toggle="modal"
                        data-bs-target={`#createCompanyGroupFormPopup1`}
                        disabled={allowPermissionE ? '' : 'disabled'}
                        onClick={() => editLeaveAction(groupObj)} >
                        {AppConstant.commonStrings.edit}
                    </button> */}
              <CustomButtonSS
                disabled={false}
                isLoading={
                  currentDeleteIndex !== "NA" && currentDeleteIndex === index
                    ? true
                    : false
                }
                className={`no-border no-bg delete-btn-ss`}
                onClick={() => {
                  deleteHandler(groupObj, index);
                }}
                isIcon={true}
                iconClass={"fa-regular fa-trash-can fa-lg"}
              />
              <CustomButtonSS
                isLoading={
                  currentItemIndex !== "NA" && currentItemIndex === index
                    ? true
                    : false
                }
                title={`${
                  !groupObj.Status
                    ? AppConstant.commonStrings.enable
                    : AppConstant.commonStrings.disable
                }`}
                className={`${
                  !groupObj.Status ? " btnEnable-ss " : "btnDisable-ss"
                } mx-2`}
                onClick={() => disableLeaveAction(groupObj, index)}
              />
            </span>
          </div>
        );
    }

    const formatListDataDrillDown = (data) => {
        
        return data?.map((el, index) => {
            if (currentFilterSelected.name === 'Warehouse') {
                return {
                    id: index,
                    0: index + 1,
                    1: moment(el.LeaveStart).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat),
                    2: moment(el.LeaveEnd).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat),
                    3: el.Purpose,
                    4: `${moment(el.CreatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-',
                    5: el.Status?'Enabled':'Disabled',
                    6: actionsButton(el, index),
                }
            } else {
                return {
                    id: index,
                    0: index + 1,
                    1: el.Name || '-',
                    2: moment(el.LeaveStart).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat),
                    3: moment(el.LeaveEnd).utc().format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat),
                    4: el.Description,
                    5: `${moment(el.CreatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || '-',
                    6: el.Status?'Enabled':'Disabled',
                    7: actionsButton(el, index),
                }
            }

        })
    }

    return (
        <>
        {metaLeavesArr &&
        <DrilldownTableSS
            themeSelected={themeSelected}
            bodyData={metaLeavesArr.length && formatListDataDrillDown(metaLeavesArr)}
            titleData={currentFilterSelected.name === 'Docks' ? dockLeavesTitleDD : dockTimeslotsTitleDD}
            showCollapse={false}
            showInput={false}
            showSort={true}
            arrangeOrder={arrangeOrder}
            arrangeKey={arrangeKey}
            drillDownReport={metaLeavesArr}
            initiateDrillDownReport={isListLoading}
            loaderArray={currentFilterSelected.name === 'Docks' ? 8 : 7}
            sortingAction={sagaActions.SORT_WH_META_TABLE_ACTION}
            subRoot={{ pathName: 'whMetaLeavesArr' }}
            showUTCTime={false}
        />}
        {showDeleteConfirmation()}
        </>
    )
}

export default LeavesMetaListSS;