import { call, put, takeEvery } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { Uchain } from '../redux/api/agent';
import { updateADFATReport, updateCarrierList, updateLoader, updateTruckUnloadSummaryList, updateVendorList, updateTruckUnloadStatsList, updateScheduledLoadStatusList, updateSelectedReport, updateADFATReportResponse } from '../redux/reducers/reducerSlices/ReportsReducerSlice';

function* updateTruckUnloadSummarySaga(data) {
    try {
        const response = yield call(Uchain.getTruckUnloadSummary, data.payload);
        yield put(updateTruckUnloadSummaryList(response));
    } catch {
        yield put(updateTruckUnloadSummaryList([]));
    }
}

function* updateADFATSaga(data){
    try {
        const response = yield call(Uchain.adfatReport, data.payload)
        yield put(updateADFATReportResponse(response))
    } catch (error) {
        yield put(updateLoader(false))
    }
}

function* updateIntiateLoaderSaga(data){
    try {
        yield put(updateLoader(data.payload))
    } catch (error) {
        
    }
}

function* updateCarrierListSaga(data){
    try {
        const response = yield call(Uchain.getCarrier, data.payload);
        yield put(updateCarrierList(response))
    } catch (error) {
        
    }
}

function* updateVendorListSaga(data){
    try {
        const response = yield call(Uchain.getVendor, data.payload);
        yield put(updateVendorList(response))
    } catch (error) {
        
    }
}
function* updateTruckUnloadStatsSaga(data) {
    try {
        const response = yield call(Uchain.getTruckUnloadStats, data.payload);        
        yield put(updateTruckUnloadStatsList(response));
    }
    catch{
        yield put(updateTruckUnloadStatsList([]));
    }
}

function* updateScheduledLoadStatusSaga(data) {
    try {
        const response = yield call(Uchain.getScheduledLoadStatus, data.payload);    
        yield put(updateScheduledLoadStatusList(response));
    }
    catch{
        yield put(updateScheduledLoadStatusList([]));
    }
}

function* updateSelectedReportSaga(data){
    try {
        yield put(updateSelectedReport(data.payload));
    } catch (error) {
    }
}

function* clearADFATSaga(){
    try {
        yield put(updateADFATReport([]))
        yield put(updateLoader(false))
    } catch (error) {
    }
}

export function* updateADFATWatcher() {
    yield takeEvery(sagaActions.GET_ADFAT_REPORT_LIST, updateADFATSaga)
}
export function* intiateLoaderWatcher() {
    yield takeEvery(sagaActions.INTIATE_LOADER_REPORTS, updateIntiateLoaderSaga)
}   
export function* updateCarrierListWatcher() {
    yield takeEvery(sagaActions.GET_CARRIER_LIST, updateCarrierListSaga)
}
export function* updateVendorListWatcher() {
    yield takeEvery(sagaActions.GET_VENDOR_LIST, updateVendorListSaga)
}
export function* updateTruckUnloadSummaryWatcher() {
    yield takeEvery(sagaActions.GET_TRUCK_UNLOAD_SUMMARY_LIST, updateTruckUnloadSummarySaga)
}
export function* updateTruckUnloadStatsWatcher() {
    yield takeEvery(sagaActions.GET_TRUCK_UNLOAD_STATS_LIST, updateTruckUnloadStatsSaga)
}
export function* updateScheduledLoadStatusWatcher() {
    yield takeEvery(sagaActions.GET_SCHEDULED_LOAD_STATUS_LIST, updateScheduledLoadStatusSaga)
}
export function* clearADFATWatcher() {
    yield takeEvery(sagaActions.CLEAR_ADFAT_REPORT_LIST, clearADFATSaga)
}
export function* selectedReportWatcher() {
    yield takeEvery(sagaActions.UPDATE_SELECTED_REPORT, updateSelectedReportSaga)
}