import React from 'react';
import DoorAssignmentHeader from './DoorAssignmentHeader/DoorAssignmentHeader';
import DoorAssignmentList from './DoorAssignmentList/DoorAssignmentList';

import './DoorAssignment.css';

const DoorAssignment = (props) => {
    return <div className='doorSection py-2'>
       <DoorAssignmentHeader {...props}/>
       <DoorAssignmentList {...props}/>
    </div>
}
 
export default DoorAssignment;