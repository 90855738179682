import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { getDockListSelector } from '../../../../../../../../redux/reducers/reducerSlices/DockListReducer';
import { getSelectedDockFilterListSelector } from '../../../../../../../../redux/reducers/reducerSlices/FiltersReducerSlice';
import Header from '../../../../../../Header/Header';
import Navigationbar from '../../../../../../Navigationbar/Navigationbar';
import DrillDownSubNavigation from '../../../../../../Navigationbar/SubNavigation/DrillDownSubNavigation';
import { createDocksIdArr } from '../../../../POFilters/FilterUtilities';
import DrillDownAppointmentCompliance from '../Children/DrillDownAppointmentCompliance/DrillDownAppointmentCompliance';
import DrillDownDetentionStatus from '../Children/DrillDownDetentionStatus/DrillDownDetentionStatus';
import DrillDownDoorStatus from '../Children/DrillDownDoorStatus/DrillDownDoorStatus';
import DrillDownDoorUsageSummary from '../Children/DrillDownDoorUsageSummary/DrillDownDoorUsageSummary';
import DrillDownLoadStatusOverview from '../Children/DrillDownLoadStatusOverview/DrillDownLoadStatusOverview';
import DrillDownTop10LateLoads from '../Children/DrillDownTop10LateLoads/DrillDownTop10LateLoads';
import DrillDownUnloadingHours from '../Children/DrillDownUnloadingHours/DrillDownUnloadingHours';
import DrillDownUnloadingTypeSummary from '../Children/DrillDownUnloadingTypeSummary/DrillDownUnloadingTypeSummary';
import DrillDownUnscheduledPos from '../Children/DrillDownUnscheduledPos/DrillDownUnscheduledPos';
import { usePrevious } from '../../../../../../Common/CustomHooks/usePrevious';
import { getSelectedDateSelector } from '../../../../../../../../redux/reducers/reducerSlices/DateSelectorReducer';
import { YYYY_MM_DD_FORMAT, convertGmtToDateRange, getFormattedDate } from '../../../../../../../../Utils/DateUtils';
import { getUserSelector } from '../../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../../sagas/sagaActions';
import { useDispatch } from 'react-redux';
import { smartScheduleSelector } from '../../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { getDefaultDashboardSelector } from '../../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import DrillDownDoorAssignment from '../Children/DrillDownDoorAssignment/DrillDownDoorAssignment';
import { getRemoteConfigSelector } from '../../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';

const DrillDownWrapper = (props) => {
    const { themeSelected } = props;
    const [drilldownType, setDrilldownType] = useState({})
    const selectedDockFiltersList = useSelector(getSelectedDockFilterListSelector);
    const getDockList = useSelector(getDockListSelector);
    const { dockList } = getDockList;
    const [dockIdArr, setDockIdArr] = useState([]);
    const location = useLocation();
    const divRef = useRef(null);
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const previousTable = usePrevious(currentTable);
    const getSelectedDateSelectorObj = useSelector(getSelectedDateSelector);
    const { selectedDate } = getSelectedDateSelectorObj;
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const dispatch = useDispatch()
    const ss = useSelector(smartScheduleSelector);
    const { isDownloadLoading } = ss;
    const defaultDashboard = useSelector(getDefaultDashboardSelector)
    const { dateRangeObj, selectedButtonStatusOverview, customDashboardDockSelectedList } = defaultDashboard;
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration

    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })

    useEffect(() => {
        setDockIdArr([]);
        if (selectedDockFiltersList.length) {
            setDockIdArr(createDocksIdArr(selectedDockFiltersList, dockList));
        }
    }, [selectedDockFiltersList])

    useEffect(() => {
        if (location.pathname.includes('unloadinghours')) {
            setDrilldownType({
                name: 'Unloading Hours',
                pathName: 'unloadinghours'
            })
        } else if (location.pathname.includes('loadstatus')) {
            if (location?.pathname) {
                const splitLocation = location?.pathname.split('/');
                const drillDownTypeTemp = splitLocation[4];
                setDrilldownType({
                    name: 'Load Status',
                    pathName: 'loadstatus',
                    subPathName: drillDownTypeTemp === "Not" ? "Not Arrived" : drillDownTypeTemp
                })
            }
        }
        else if (location.pathname.includes('appointmentcompliance')) {
            const splitLocation = location?.pathname.split('/');
            let drillDownTypeTemp = _.upperCase(splitLocation[4]);
            if (drillDownTypeTemp === "ONTIME") {
                drillDownTypeTemp = "On time"
            } else if (drillDownTypeTemp === "EARLY") {
                drillDownTypeTemp = "Early"
            } else if (drillDownTypeTemp === "LATEGREATER") {
                drillDownTypeTemp = "Late greater than 1 hour"
            } else if (drillDownTypeTemp === "LATELESS") {
                drillDownTypeTemp = "Late less than 1 hour"
            }

            setDrilldownType({
                name: 'Appointments Compliance',
                pathName: 'appointmentcompliance',
                subPathName: drillDownTypeTemp
            })
        }
        else if (location.pathname.includes('detentionstatus')) {
            if (location?.pathname) {
                const splitLocation = location?.pathname.split('/');
                let drillDownTypeTemp = _.upperCase(splitLocation[4]);
                if (drillDownTypeTemp === "NOT") {
                    drillDownTypeTemp = "Not in detention"
                } else if (drillDownTypeTemp === "DETENTION") {
                    drillDownTypeTemp = "In Detention"
                } else if (drillDownTypeTemp === "NEAR") {
                    drillDownTypeTemp = "Nearing Detention"
                }
                setDrilldownType({
                    name: 'Detention Status',
                    pathName: 'detentionstatus',
                    subPathName: drillDownTypeTemp
                })
            }
        }
        else if (location.pathname.includes('unloadingtypesummary')) {
            setDrilldownType({
                name: 'Unloading Type Summary',
                pathName: 'unloadingtypesummary'
            })

        }
        else if (location.pathname.includes('doorusagesummary')) {
            const splitLocation = location?.pathname.split('/');
            const drillDownTypeTemp = splitLocation[4];
            setDrilldownType({
                name: 'Door Usage Summary',
                pathName: 'doorusagesummary',
                subPathName: _.capitalize(drillDownTypeTemp),
            })

        }
        else if (location.pathname.includes('doorstatus')) {
            const splitLocation = location?.pathname.split('/');
            const drillDownTypeTemp = splitLocation[4];
            const drillDownTypeTempLoad = splitLocation[5];
            setDrilldownType({
                name: 'Door Status',
                pathName: 'doorstatus',
                subPathName: _.capitalize(drillDownTypeTemp),
                queryElement: drillDownTypeTempLoad ? drillDownTypeTempLoad : null
            })

        }
        else if (location.pathname.includes('toplateloads')) {
            const splitLocation = location?.pathname.split('/');
            const drillDownTypeTemp = splitLocation[4];
            const drillDownTypeTempLoad = splitLocation[5];
            setDrilldownType({
                name: 'Top 10 Late Loads',
                pathName: 'toplateloads',
                subPathName: _.capitalize(drillDownTypeTemp),
                queryElement: drillDownTypeTempLoad ? drillDownTypeTempLoad : null
            })

        }
        else if (location.pathname.includes('unscheduledpo')) {
            setDrilldownType({
                name: 'Unscheduled Po',
                pathName: 'unscheduledpo'
            })

        }
        else if (location.pathname.includes('doorAssignment')) {
            const splitLocation = location?.pathname.split('/');
            const drillDownTypeTemp = splitLocation[4];
            setDrilldownType({
                name: 'Door Assignment',
                pathName: 'doorAssignment',
                subPathName: _.capitalize(drillDownTypeTemp),
            })

        }
        loadDrillDownReport()
    }, [location.pathname, dockIdArr])

    const loadDrillDownReport = () => {
        // Always send Loader array + 1 if first element in table is input
        let oProps = { ...props }
        switch (drilldownType.pathName) {
            case 'unloadinghours':
                oProps = { ...props, loaderArray: 7, subRoot: drilldownType, dockIdArr: dockIdArr }
                return <DrillDownUnloadingHours {...oProps} />
            case 'loadstatus':
                if (location?.pathname) {
                    const splitLocation = location?.pathname.split('/');
                    const drillDownTypeTemp = splitLocation[4];
                    oProps = { ...props, loaderArray: 11, subRoot: drilldownType, secondSubRoot: drillDownTypeTemp, dockIdArr: dockIdArr }
                    return <DrillDownLoadStatusOverview {...oProps} />
                } else {
                    return;
                }
            case 'appointmentcompliance':
                oProps = { ...props, loaderArray: 5, subRoot: drilldownType, dockIdArr: dockIdArr }
                return <DrillDownAppointmentCompliance {...oProps} />
            case 'detentionstatus':
                oProps = { ...props, loaderArray: 11, subRoot: drilldownType, dockIdArr: dockIdArr }
                return <DrillDownDetentionStatus {...oProps} />
            case 'unloadingtypesummary':
                oProps = { ...props, loaderArray: 9, subRoot: drilldownType, dockIdArr: dockIdArr }
                return <DrillDownUnloadingTypeSummary {...oProps} />
            case 'doorusagesummary':
                oProps = { ...props, loaderArray: 5, subRoot: drilldownType, dockIdArr: dockIdArr }
                return <DrillDownDoorUsageSummary {...oProps} />
            case 'doorstatus':
                oProps = { ...props, loaderArray: 4, subRoot: drilldownType, dockIdArr: dockIdArr }
                return <DrillDownDoorStatus {...oProps} />
            case 'toplateloads':
                oProps = { ...props, loaderArray: 16, subRoot: drilldownType, dockIdArr: dockIdArr }
                return <DrillDownTop10LateLoads {...oProps} />
            case 'unscheduledpo':
                oProps = { ...props, loaderArray: 10, subRoot: drilldownType, dockIdArr: dockIdArr }
                return <DrillDownUnscheduledPos {...oProps} />
            case 'doorAssignment':
                oProps = { ...props, loaderArray: 8, subRoot: drilldownType, dockIdArr: dockIdArr }
                return <DrillDownDoorAssignment {...oProps} />
            default:
                break;
        }
    }


    /**
     * Used to create manual refresh payload to dispatch data
     * @param {*} loadStr
     * @param {*} pageNo
     * @param {*} limit
     * @returns
     */
    const createManualRefreshPayload = (loadStr = "", pageNo = 1, limit = PaginationDefaultLimit) => {
        switch (drilldownType.pathName) {
            case 'loadstatus':
                const splitLocation = location?.pathname.split('/');
                const drillDownTypeTemp = splitLocation[4];
                return {
                    "dock_id": dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                    "warehouse_id": selectedWarehouse.ID,
                    "date": getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                    "load_type": loadStr,
                    "isDrillDown": "1",
                    "drillDownType": drillDownTypeTemp,
                    pageNo,
                    limit
                }
            case 'appointmentcompliance':
                return {
                    date: getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                    dock_id: dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                    isDrillDown: '1', warehouse_id: selectedWarehouse.ID,
                    drillDownType: drilldownType.subPathName, pageNo,
                    limit
                }
            case 'detentionstatus':
                return {
                    date: getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate),
                    dock_id: dockIdArr.toString() === '' ? null : dockIdArr.toString(),
                    isDrillDown: '1', warehouse_id: selectedWarehouse.ID,
                    drillDownType: drilldownType.subPathName,
                    pageNo,
                    limit
                }
            default:
                break;
        }
    }

    /**
     * ManualRefresh function is used as reusable function where it dispatch the drill down reports
     */
    const manualRefresh = () => {
        switch (drilldownType.pathName) {
            case 'loadstatus':
                fetchLoadStatusManualRefreshData();
                break;
            case 'appointmentcompliance':
                fetchAppointmentComplianceManualRefreshData();
                break;
            case 'detentionstatus':
                fetchDetentionStatusManualRefreshData();
                break;
            default:
                break;
        }
    }

    /**
     * Used to dispatch appointment compliance data
     */
    const fetchAppointmentComplianceManualRefreshData = () => {
        dispatch({ type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true })
        dispatch({
            type: sagaActions.GET_APPOINTMENT_COMPLIANCE_DRILLDOWN, payload: createManualRefreshPayload()
        });
    }
    
      /**
     * Used to dispatch Load status data
     */
    const fetchLoadStatusManualRefreshData = () => {
        dispatch({type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true})
        dispatch({
            type: sagaActions.GET_DASHBOARD_STATUS_DRILLDOWN, payload: createManualRefreshPayload()
        });
    }

      /**
     * Used to dispatch detention status data
     */
    const fetchDetentionStatusManualRefreshData = () => {
        dispatch({type: sagaActions.INITIATE_DRILLDOWN_REPORT, payload: true})
        dispatch({
            type: sagaActions.GET_DETENSTION_STATUS_DRILLDOWN, payload: createManualRefreshPayload()
        });
    }

    const downloadDetentionStatusReport = (type) => {

        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        let options = {};
        if (drilldownType?.pathName === 'doorAssignment') {
            const filterDoorAssignment = _.filter(customDashboardDockSelectedList, { name: 'Door Assignment' })
            if (_.size(filterDoorAssignment)) {
                options = {
                    dock_id: filterDoorAssignment[0]?.dock_id, isDrillDown: '1', warehouse_id: selectedWarehouse.ID, drillDownType: filterDoorAssignment[0]?.dock_id,
                    fromDate: start !== "Invalid date" ? start : null,
                    toDate: end !== "Invalid date" ? end : null,
                    selectedDoor: null
                }
            }

        } else {
            options = {
                date: getFormattedDate(YYYY_MM_DD_FORMAT, selectedDate), dock_id: dockIdArr.toString() === '' ? null : dockIdArr.toString(), isDrillDown: '1', warehouse_id: selectedWarehouse.ID, drillDownType: drilldownType.subPathName === "Not Arrived" ? "Not" : drilldownType.subPathName,
                fromDate: start !== "Invalid date" ? start : null,
                toDate: end !== "Invalid date" ? end : null,
                load_type: selectedButtonStatusOverview,
            }
        }
        let payload = {
            subDomain: drilldownType.pathName,
            downloadType: type,
            options: options
        }
        if (drilldownType?.pathName === 'doorAssignment') {
            dispatch({ type: sagaActions.DOWNLOAD_REPORT_COMMON, payload });
        }
        else {
            return payload
        }
        if (drilldownType?.pathName === 'doorAssignment') {
            dispatch({ type: sagaActions.DOWNLOAD_REPORT_COMMON, payload });
        }
        else{
        return payload
    }
    }

    return (
        <div className='container-fluid executor'>
            <Header {...props} />
            <Navigationbar {...props} />
            <DrillDownSubNavigation {...props} subRoot={drilldownType} root='Analytics' currentTable={currentTable} currentTableRef={currentTableRef} downloadDetentionStatusReport={downloadDetentionStatusReport} isDownloadLoading={isDownloadLoading} manualRefresh={manualRefresh}/>
            <div className="drillDownSection mt-3"  ref={divRef}>
            {
                loadDrillDownReport()
            }
            </div>      
        </div>
    );
}

export default DrillDownWrapper;