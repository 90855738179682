import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import configureStore from './redux/store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import "./GlobalCss/GlobalCss.css"
import ThemeContextWrapper from './Theme/ThemeWrapper'
import wp_aws from './aws-export';
import Amplify from 'aws-amplify';

Amplify.configure(wp_aws);

const root = ReactDOM.createRoot(document.getElementById('root'));
const { store, persistor } = configureStore();
root.render(
    <Provider store={store}>
        <ThemeContextWrapper>
            <PersistGate persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </ThemeContextWrapper>
    </Provider>
);

  
  

