import React from 'react';
import { AppConstant } from '../../../../../../../Assests/AppConstant';
import './RoleFeaturesHead.css';

const RoleFeaturesHead = (props) => {

    const { themeSelected } = props;
    const appConstantPath = AppConstant.superUserAdmin.rolesFeaturesAdmin;

    return (
        <thead className={`justify-content-center text-center ${themeSelected} `}>
            <tr>
                <th scope="col" className='col tableHeadTitle'>
                    <span>{appConstantPath.tableHeadTitle.serialNo}</span>
                </th>
                <th scope="col" className='col tableHeadTitle'>
                    <span>{appConstantPath.tableHeadTitle.feature}</span>
                </th>
                <th scope="col" className='col tableHeadTitle'>
                    <span>{appConstantPath.tableHeadTitle.pageAccess}</span>
                </th>
                <th scope="col" className='col tableHeadTitle'>
                    <span>{appConstantPath.tableHeadTitle.navigation}</span>
                </th>
                <th scope="col" className='col tableHeadTitle'>
                    <span>{appConstantPath.tableHeadTitle.task}</span>
                </th>
                <th scope="col" className='col tableHeadTitle'>
                    {appConstantPath.tableHeadTitle.access}
                </th>
            </tr>
        </thead>
    )

}

export default RoleFeaturesHead;