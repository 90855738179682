import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { AppConstant } from "../../Assests/AppConstant";
import { getFormattedDate } from '../../../Utils/DateUtils';
import { paytraceReducer } from '../../../redux/reducers/reducerSlices/PaytraceReducer';
import { getUserSelector } from '../../../redux/reducers/reducerSlices/UserReducer';
import { removeInterceptor } from '../../../redux/api/Interceptor';
import { sagaActions } from "../../../sagas/sagaActions";
import moment from 'moment';
import { toast } from 'react-toastify';
import { showAlert, calculateDaysDifference } from "../../Assests/Utility";
import CustomButton from '../Common/CustomButton/CustomButton';
import Popup from "../Common/Popup/Popup";
import UserPaymentsList from "./UserPaymentsList/UserPaymentsList";
import './MySubscriptions.css';
import ScheduleProWrapper from "../SmartSchedule/SchedulePro/ScheduleProWrapper";
import SSPopup from "../Common/Popup/SSPopup/SSPopup";
/** This component renders subscription in table view */
const MySubscriptions = (props) => {

    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const [editData, setEditData] = useState({});
    const [searchStr, setSearchStr] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showSecondPopupModal, setShowSecondPopupModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(getFormattedDate(AppConstant.commonStrings.MM_DD_YYYY_dateFormat, moment()));
    const [subsEndDate, setSubsEndDate] = useState('');
    const { cancelSubscriptionObj, mySubscriptionList } = useSelector(paytraceReducer);
    const userObj = useSelector(getUserSelector);
    const { cu_id } = userObj;
    const [userSubsObj, setUserSubsObj] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
     /** Initial useEffect */
    useEffect(() => {
        dispatch({ type: sagaActions.RESET_CANCEL_SUBCRIPTION_OBJ });
    }, [])
    /**
     * useEffect to render mySubscriptionList and set mySubscriptionList to local variable
     */
    useEffect(() => {
        if (mySubscriptionList && mySubscriptionList.subscriptionData && Object.keys(mySubscriptionList.subscriptionData).length) {
            setUserSubsObj(mySubscriptionList.subscriptionData)
        } else {
            setUserSubsObj([])
        }
    }, [mySubscriptionList])

     /** useEffect for cancel Subscription object */
    useEffect(() => {
        if (Object.keys(cancelSubscriptionObj).length) {
            setIsLoading(false);
            if (!cancelSubscription.Status) {
                showAlert(toast.TYPE.SUCCESS, AppConstant.smartScheduler.subscription.cancelledSubscriptionSuccessfull, themeSelected);
                removeInterceptor()
                dispatch({ type: sagaActions.CLEAR_SIGNEDIN_USER })
                dispatch({ type: sagaActions.RESET_USER })
                dispatch({ type: sagaActions.RESET_CANCEL_SUBCRIPTION_OBJ })
                dispatch({ type: sagaActions.RESET_PAYTRACE_DATA });
                window.localStorage.clear();
                navigate("/")
                // If logging out from UserWarehouse Page then trigger branding API again
                if (location.pathname === '/') {
                    dispatch({ type: sagaActions.INITIATE_BRANDING_LOADER, payload: true })
                    const subdomain = window.location.hostname.split(".")[0];
                    dispatch({
                        type: sagaActions.INITIATE_BRANDING, payload: {
                            subdomain: subdomain
                        }
                    });
                }
            }
        }
    }, [cancelSubscriptionObj])

     /** Cancel subscription modal handler */
    const cancelSubscription = () => {
        setShowModal(true);
    }
    /** Returns Cancel subscription JSX */
    const cancelSubscriptionBody = () => {
        return (
            <div>{AppConstant.smartScheduler.subscription.cancelSubMessage1}</div>
        );
    };
     /** Cancel subscription handler */
    const cancelSubscriptionFunc = () => {
        setIsLoading(false);
        setShowModal(false);
        setShowSecondPopupModal(true);
    }
    /**
     * Cancel subscription confirm popup handler
     * @param {*} text 
     */
    const popupBtnActionHnadler = (text) => {
        if (text === 'No') {
            setShowModal(false)
            setShowSecondPopupModal(false)
        } else {
            cancelSubscriptionFunc()
        }
    }
    /**
     * 
     * @returns Cancel subscription popup object
     */
    const showCancelSubscriptionSecondPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            title: 'Are you sure you want to cancel your subscription ?',
            bodyText: <div>You will be logged out of the application.
                <br />You will still have access to application without an oppurtunity to book appointment(s)</div>,
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }
        return <SSPopup {...popupObj} popupBtnHandler={(text) => popupSecBtnActionHandler(text)} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showSecondPopupModal} />
    }
    /**
     * Cancel subscription confirm popup handler
     * @param {*} text 
     */
    const popupSecBtnActionHandler = (btnStr) => {
        if (btnStr === AppConstant.commonStrings.yes) {
            setIsLoading(true);
            setShowModal(false);
            setShowSecondPopupModal(false);
            dispatch({ type: sagaActions.CANCEL_SUBSCRIPTION_ACTION, payload: { "IDCarrier": cu_id } });
        } else {
            closeCurrentpopup()
        }
    }
    /**
     * 
     * @returns Cancel subscription popup obj with new UI SSPopup component
     */ 
    const showCancelSubscriptionPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'lg',
            title: AppConstant.smartScheduler.subscription.cancleSubscription,
            bodyText: cancelSubscriptionBody(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.yes
                },
                btn2: {
                    show: true,
                    text: AppConstant.commonStrings.no
                }
            },
        }
        return <Popup {...popupObj} popupBtnHandler={(text) => popupBtnActionHnadler(text)} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }
    /**
     * function to close current popup
     */
    const closeCurrentpopup = () => {
        setEditData({});
        setShowModal(false);
        setShowSecondPopupModal(false);
    }
    /**
     * 
     * @returns retunr number of days left for subscription expiry
     */
    const calRemainingDays = () => {
        if (parseInt(userSubsObj.SubDays) > 0) {
            return <div>{`${AppConstant.smartScheduler.subscription.daysRemainingMessage} ${userSubsObj.SubDays} days`}</div>
        } else {
            return <div>{`${AppConstant.smartScheduler.subscription.expiredPlanMessage}`}</div>
        }
    }

    return (
        <ScheduleProWrapper>
            <div>
                {/* <Header {...props} /> */}
                {/* <Navigationbar {...props} /> */}
                <div className="m-4 d-flex flex-column">
                    <div className="m-0 p-0 fw-bold">My Subscriptions</div>
                    <div className="d-flex mt-1 w-100 justify-content-between fs-6">
                        <div className="">
                            {
                                userSubsObj && Object.keys(userSubsObj).length && calRemainingDays()
                            }
                        </div>
                        <div className="">
                            {
                                calculateDaysDifference(subsEndDate, currentDate) <= 0 ?
                                    '' :
                                    <CustomButton
                                        isLoading={isLoading}
                                        title={`${AppConstant.smartScheduler.subscription.cancleSubscription}`}
                                        className={`carrierApptBtn btnDelete`}
                                        onClick={() => cancelSubscription()}
                                    />
                            }
                        </div>
                    </div>
                    {showCancelSubscriptionPopup()}
                    {showCancelSubscriptionSecondPopup()}
                    <UserPaymentsList {...props} searchStr={searchStr} themeSelected={themeSelected} />
                </div>
            </div>
        </ScheduleProWrapper>
    )
}

export default MySubscriptions;