import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { changeFormatWithoutTz, formDropDownDataforGaurd, initiateSearch } from '../../../../../../Assests/Utility';
import { AppConstant } from '../../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import WarehouseSearch from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import { usePrevious } from '../../../../../Common/CustomHooks/usePrevious';
import './DriverList.css';
import { getDriverCheckinReducer } from '../../../../../../../redux/reducers/reducerSlices/DriverCheckinReducer';
import moment from 'moment';
import { CustomPaginationSS } from '../../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { getDefaultDashboardSelector } from '../../../../../../../redux/reducers/reducerSlices/DefaultDashboardReducer';
import { changeDateToGivenFormat, convertGmtToDateRange, getCurrentDate } from '../../../../../../../Utils/DateUtils';
import DownloadSectionBE from '../../../DownloadSection/DownloadSectionBE';
import { smartScheduleSelector } from '../../../../../../../redux/reducers/reducerSlices/SmartScheduleReducer';
import { getRemoteConfigSelector } from '../../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import Popup from '../../../../../Common/Popup/Popup';
import CustomTooltip from '../../../../../Common/Tooltip/CustomTooltip';
import DrilldownTableSS from '../../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import EditIcon from '../../../../../../Assests/Images/edit-icon-new.png'
import CustomDropDown from '../../../../../Common/CustomDropDown/CustomDropDown';
import CustomButtonSS from '../../../../../Common/CustomButton/CustomButtonSS';
import { LocationClient, SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";
import { Auth } from 'aws-amplify';
import { customStylesLight } from '../../../../../Login/SignUpCarrier/FormCarrierSignUp/Components/Styles/Styles';

const DriverList = (props) => {
    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allApptsArr, setAllApptsArr] = useState([]);

    const userObj = useSelector(getUserSelector);
    const [searchStr, setSearchStr] = useState('');
    const { selectedWarehouse, cu_id, userAccountDetails, userType } = userObj;
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableRef, setCurrentTableRef] = useState(null);
    const divRef = useRef(null);
    const [showLoading, setShowLoading] = useState(true);
    const previousTable = usePrevious(currentTable);
    const defaultDashboard = useSelector(getDefaultDashboardSelector);
    const { dateRangeObj } = defaultDashboard;
    const driverData = useSelector(getDriverCheckinReducer);
    const { editLoading } = driverData;
    const ss = useSelector(smartScheduleSelector);
    const { isDownloadLoading, cityData } = ss;
    const [stateData, setStateData] = useState([]);
    const [selectedState, setSelectedState] = useState({})
    //Get Config Data from Redux
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState({});
    const [resetFormData, setResetFormData] = useState(0);
    const [particularFieldValue, setParticularFieldValue] = useState({});
    const [selectedDate, setSelectedDate] = useState('');
    const [startDateState, setStartDateState] = useState(changeDateToGivenFormat(getCurrentDate(), AppConstant.commonStrings.datetimelocal));
    const [endDateState, setEndDateState] = useState(changeDateToGivenFormat(getCurrentDate(), AppConstant.commonStrings.datetimelocal));
    // Store Dropdown selected values for Trailer type
    const [currentFilterSelectedTrailerType, setCurrentFilterSelectedTrailerType] = useState({ name: "All Trailer" })
    // Store Dropdown selected values for memo
    const [currentFilterSelectedMemo, setCurrentFilterSelectedMemo] = useState({ name: "All Memo" })
    const [showMoreDetailsModal, setShowMoreDetailsModal] = useState(false);
    // Store Selected Data
    const [selectedData, setSelectedData] = useState(null)
    // Store Dropdown selected values for Trans type
    const [currentFilterSelectedTransType, setCurrentFilterSelectedTransType] = useState({ name: AppConstant.commonStrings.allLoad })
    //Get Trans Type Array
    const TransTypeList = allConfigration?.GuardSearchLoadTypeList?.slice(1, -1).split(',');
    // Form Report Header
    const apptsTitleDD = [
        { id: 'poId', name: 'PO' },
        { id: 'trailerNumber', name: 'Trailer No.' },
        // { id: 'poStatus', name: 'PO Status' },
        { id: 'poType', name: 'Inbound Type' },
        { id: 'checkindateandtime', name: 'Check In' },
        { id: 'checkoutDateAndTime', name: 'Check Out' },
        { id: 'DFirstName', name: 'Name' },
        { id: 'DPhoneNo', name: 'Phone' },
        { id: 'DLicenceNo', name: 'License' },
        { id: 'shipmentId', name: 'Vendor' },
        { id: 'carrierCode', name: 'Carrier Code' },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.actions }
    ];

    // Form Report Header
    const apptsTitleDDTrailer = [
        { id: 'trailerNumber', name: 'Trailer No.' },
        // { id: 'poId', name: 'PO' },
        // { id: 'poStatus', name: 'PO Status' },
        { id: 'poType', name: 'Inbound Type' },
        { id: 'checkindateandtime', name: 'Check In' },
        { id: 'checkoutDateAndTime', name: 'Check Out' },
        { id: 'DFirstName', name: 'Name' },
        { id: 'DPhoneNo', name: 'Phone' },
        { id: 'DLicenceNo', name: 'License' },
        { id: 'shipmentId', name: 'Vendor' },
        { id: 'memo', name: 'Memo' },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.actions }
    ];

    // Form Report Header
    const apptsTitleDDAllLoads = [
        { id: 'TransType', name: 'Load Type' },
        { id: 'trailerNumber', name: 'Trailer No.' },
        { id: 'poId', name: 'PO' },
        // { id: 'poStatus', name: 'PO Status' },
        { id: 'poType', name: 'Inbound Type' },
        { id: 'checkindateandtime', name: 'Check In' },
        { id: 'checkoutDateAndTime', name: 'Check Out' },
        { id: 'DFirstName', name: 'Name' },
        { id: 'DPhoneNo', name: 'Phone' },
        { id: 'DLicenceNo', name: 'License' },
        { id: 'carrierCode', name: 'Carrier Code' },
        { id: '', name: AppConstant.superUserAdmin.companyGroup.tableHeader.actions },
    ];

    // Data Array for Trailer filter dropdown
    const trailerType = [{
        label: AppConstant.driver.trailerTypeRadioBtn.AllTrailer,
        value: AppConstant.driver.trailerTypeRadioBtn.AllTrailer
    }, {
        label: AppConstant.driver.trailerTypeRadioBtn.Dropped,
        value: AppConstant.driver.trailerTypeRadioBtn.Dropped
    },
    {
        label: AppConstant.driver.trailerTypeRadioBtn.Live,
        value: AppConstant.driver.trailerTypeRadioBtn.Live
    },
        // {
        //     label: AppConstant.driver.trailerTypeRadioBtn.neither,
        //     value: AppConstant.driver.trailerTypeRadioBtn.neither
        // }
    ]

    useEffect(() => {
        const container = divRef.current;
        const tables = container.querySelectorAll('table');
        if (tables && tables.length && _.isEqual(currentTable, previousTable)) {
            setCurrentTableRef(container);
            setCurrentTable(tables);
        }
    })

    useEffect(() => {
        dispatch({ type: sagaActions.RESET_DRIVER_CHECKIN })
        setShowLoading(true);
        setAllApptsArr([]);
        dispatch({
            type: sagaActions.GET_STATE_WITH_ZIPCODE, payload: {
                Text: '',
                type: 'C'
            }
        })
        setStateData([])
        // setTimeout(() => {
        //     fetchAppts()
        // }, [500])
    }, [])

    useEffect(() => {
        if (showLoading) {
            if (driverData?.data && driverData.data.CRUDTYPE === "S") {
                setShowLoading(false);
                setAllApptsArr(driverData?.data?.data)
            }
        } else if (editLoading) {
            if (driverData?.data && driverData.data.CRUDTYPE === "E") {
                dispatch({ type: sagaActions.DRIVER_REPORT_EDIT_LOADER, payload: false })
                setAllApptsArr(driverData?.data?.data)
                closeCurrentpopup();
            }
        }
        else {
            if (driverData?.data) {
                setAllApptsArr(driverData?.data?.data)
            }
        }


    }, [driverData?.data])

    const fetchAppts = (searchParam, pageNo, limit = PaginationDefaultLimit) => {
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        setShowLoading(true);

        dispatch({
            type: sagaActions.DRIVER_CHECKIN, payload: {
                "CRUDTYPE": "S", "WhId": selectedWarehouse.ID, limit: PaginationDefaultLimit, pageNo: pageNo || 1, "fromDate": start !== "Invalid date" ? start : null,
                "endDate": end !== "Invalid date" ? end : null, "searchParam": searchParam,
                trailerType: currentFilterSelectedTrailerType?.name?.includes('All') ? null : currentFilterSelectedTrailerType?.name,
                memo: currentFilterSelectedMemo?.name?.includes('All') ? null : currentFilterSelectedMemo?.name,
                "transType": currentFilterSelectedTransType?.name === AppConstant.commonStrings.inBoundLoadTitle ? 'Load' : currentFilterSelectedTransType?.name === AppConstant.commonStrings.otherLoad ? 'Trailer' : "load','Trailer"
            }
        });
    }

    useEffect(() => {
        if (!_.isEmpty(driverData)) {
            fetchAppts()
        }
    }, [dateRangeObj])

    useEffect(() => {
        let timeout;
        if (searchStr && searchStr.length > 3) {
            timeout = setTimeout(() => {
                fetchAppts(searchStr)
            }, 1000)
        } else if (searchStr === '') {
            fetchAppts(null)
        }
        return () => { clearTimeout(timeout) }
    }, [searchStr, currentFilterSelectedMemo, currentFilterSelectedTrailerType, currentFilterSelectedTransType])

    useEffect(() => {
        if (cityData && _.size(cityData)) {
            setStateData([...cityData])
        }
    }, [cityData])


    //Get PoStatus Dropdown Data
    const getPOStatusList = () => formDropDownDataforGaurd(allConfigration, 'GuardPOStatusList')
    //Get PoType DropDown Data
    const getPOTypeList = () => formDropDownDataforGaurd(allConfigration, 'GuardPOTypeList')
    //Get Yard Location DropDown Data
    const getYardLocationList = () => formDropDownDataforGaurd(allConfigration, 'GuardYardLocationList')
    //Get Origin Location DropDown Data
    const getOriginLocationList = () => formDropDownDataforGaurd(allConfigration, 'GuardOriginLocationList')
    //Get Destination DropDown Data
    const getDestinationList = () => formDropDownDataforGaurd(allConfigration, 'GuardDestinationList')
    // Get memo DropDown Data
    const getMemoList = () => formDropDownDataforGaurd(allConfigration, 'GuardMemoList')

    // Destination
    const { GuardDestination } = allConfigration

    const editPO = (data, index) => {
        // const selectedDock = (_.find(dockListArr, { ID: data.IDDock }));
        const editData1 = {
            "poId": data.poId,
            "poStatus": data.poStatus,
            "poType": data.poType,
            "checkindateandtime": data?.checkindateandtime ? changeFormatWithoutTz(data.checkindateandtime, AppConstant.commonStrings.datetimelocal) : "",
            "checkoutDateAndTime": data?.checkoutDateAndTime ? changeFormatWithoutTz(data.checkoutDateAndTime, AppConstant.commonStrings.datetimelocal) : "",
            "DFirstName": data.DFirstName,
            "DlastName": data.DlastName,
            "DPhoneNo": data.DPhoneNo,
            "DLicenceNo": data.DLicenceNo,
            "shipmentId": data.shipmentId,
            "carrierCode": data.carrierCode,
            "originLocation": {
                label: data.originLocation,
                value: data.originLocation
            },
            "destination": selectedWarehouse?.Name,
            "trailerType": data.trailerType,
            "trailerName": data.trailerName,
            "trailerNumber": data.trailerNumber,
            "yardLocation": data.yardLocation,
            "memo": data.memo,
            "createdon": data.createdon,
            "createdby": data.createdby,
            "idTruck": data.idTruck,
            "TransType": data.TransType
        }

        setShowModal(true);
        setEditData({ ...editData1 });
        setParticularFieldValue({ ...editData1 });
    }
    // Form Data for View More 
    const formShowMoreDetailsData = (el) => {
        let data = {
            'PO': el.poId || '-',
            // 'PO Status': el.poStatus || '-',
            'Inbound Type': el.poType || '-',
            'Check In': el.checkindateandtime ? moment(el.checkindateandtime).utc().format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm) : '-',
            'Check Out': el.checkoutDateAndTime ? moment(el.checkoutDateAndTime).utc().format(AppConstant.commonStrings.MM_DD_YYYY__HH_mm) : '-',
            'Name': el.DFirstName ? el.DFirstName + " " + el.DlastName : '-',
            'Phone': el.DPhoneNo || '-',
            'License': el.DLicenceNo || '-',
            'Vendor': el.shipmentId || '-',
            'Carrier Code': el.carrierCode || '-',
            'Origin Loc.': el.originLocation || '-',
            'Destination': selectedWarehouse?.Name || '-',
            'Trailer Type': el.trailerType || '-',
            'Trailer': el.trailerName || '-',
            'Trailer No.': el.trailerNumber || '-',
            'Yard Loc.': el.yardLocation || '-',
            'Comments': el.memo || '-',
        }
        let keys = Object.keys(data)
        return keys.map((key) => {
            return (
                <div className='d-flex justify-content-between f-14 my-1'>
                    <p className='w-25 word-break'>{key}</p>
                    <p className='w-75 word-break text-end'>{data[key]}</p>
                </div>
            )
        })

    }

    //More Details Popup
    const showMoreDetailsPopup = () => {
        const popupObj = {
            id: 'showMoreDetailsPopup',
            modalLabel: 'showMoreDetailsPopup',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: AppConstant.loadManagement.loadheader,
            bodyText: selectedData && formShowMoreDetailsData(selectedData),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: false,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: false,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={() => setShowMoreDetailsModal(false)} themeSelected={themeSelected} showModal={showMoreDetailsModal} />
    }


    const actionButtons = (el, index, enableObj) => {
        const { editEnable, moreEnable } = enableObj;
        return (
            <div className='d-flex justify-content-center align-items-center gap-4 my-2'>
                {
                    editEnable ? <img src={!editEnable ? "" : EditIcon}
                        className={!editEnable ? 'editIconPO-ss disabled pointer-event-none' : 'editIconPO-ss'}
                        alt="Edit Po"
                        data-tip={'Edit'}
                        data-for={'tooltip_id'}
                        onClick={() => {

                            setStartDateState(changeFormatWithoutTz(el.checkindateandtime, AppConstant.commonStrings.datetimelocal))
                            setEndDateState(changeFormatWithoutTz(el.checkoutDateAndTime, AppConstant.commonStrings.datetimelocal))
                            editPO(el, index);

                        }} /> : <span style={{ width: 20 }}></span>
                }
                <CustomButtonSS
                    pointer-event-none={moreEnable}
                    isLoading={false}
                    isToolTip={true}
                    dataTip={"View more"}
                    dataFor={"tooltip_id"}
                    className={`carrier-po-btn-small `}
                    onClick={() => {
                        setSelectedData(el)
                        setShowMoreDetailsModal(true)
                    }}
                    isIcon={true}
                    iconClass={"fa-solid fa-ellipsis-vertical"}
                />
                <CustomTooltip
                    id={'tooltip_id'}
                    position='top'
                    type={themeSelected === AppConstant.commonStrings.lightModeFilterString ? 'light' : 'dark'}
                    multiline={false} />
            </div>
        )
    }



    // format Drilldown body data to render
    const formatListDataDrillDown = (data) => {
        if (currentFilterSelectedTransType.name === AppConstant.commonStrings.inBoundLoadTitle) {
            return data.map((el, index) => {
                return {
                    id: el.DCINId,
                    0: el.poId || '-',
                    1: el.trailerNumber || '-',
                    // 2: el.poStatus || '-',
                    2: el.poType || '-',
                    3: el.checkindateandtime ? el.checkindateandtime : '-',
                    4: el.checkoutDateAndTime ? el.checkoutDateAndTime : '-',
                    5: el.DFirstName ? el.DFirstName + " " + el.DlastName : '-',
                    6: el.DPhoneNo || '-',
                    7: el.DLicenceNo || '-',
                    8: el.shipmentId || '-',
                    // 10: el.memo || '-',
                    9: el.carrierCode || '-',
                    // 10: el.originLocation || '-',
                    // 11: el.destination || '-',
                    // 12: el.trailerType || '-',
                    // 13: el.trailerName || '-',
                    // 15: el.yardLocation || '-',
                    11: actionButtons(el, index, { editEnable: el.checkoutDateAndTime ? false : true, moreEnable: true })
                }
            })
        } else if (currentFilterSelectedTransType.name === AppConstant.commonStrings.otherLoad) {
            return data.map((el, index) => {
                return {
                    id: el.DCINId,
                    0: el.trailerNumber || '-',
                    // 1: el.poId || '-',
                    // 2: el.poStatus || '-',
                    1: el.poType || '-',
                    2: el.checkindateandtime ? el.checkindateandtime : '-',
                    3: el.checkoutDateAndTime ? el.checkoutDateAndTime : '-',
                    4: el.DFirstName ? el.DFirstName + " " + el.DlastName : '-',
                    5: el.DPhoneNo || '-',
                    6: el.DLicenceNo || '-',
                    7: el.shipmentId || '-',
                    8: el.memo || '-',
                    // 9: el.carrierCode || '-',
                    // 10: el.originLocation || '-',
                    // 11: el.destination || '-',
                    // 12: el.trailerType || '-',
                    // 13: el.trailerName || '-',
                    // 15: el.yardLocation || '-',
                    9: actionButtons(el, index, { editEnable: el.checkoutDateAndTime ? false : true, moreEnable: true })
                }
            })
        } else {
            return data.map((el, index) => {
                return {
                    id: el.DCINId,
                    0: el.TransType || '-',
                    1: el.trailerNumber || '-',
                    2: el.poId || '-',
                    // 3: el.poStatus || '-',
                    3: el.poType || '-',
                    4: el.checkindateandtime ? el.checkindateandtime : '-',
                    5: el.checkoutDateAndTime ? el.checkoutDateAndTime : '-',
                    6: el.DFirstName ? el.DFirstName + " " + el.DlastName : '-',
                    7: el.DPhoneNo || '-',
                    8: el.DLicenceNo || '-',
                    // 10: el.shipmentId || '-',

                    // 10: el.memo || '-',
                    9: el.carrierCode || '-',
                    // 10: el.originLocation || '-',
                    // 11: el.destination || '-',
                    // 12: el.trailerType || '-',
                    // 13: el.trailerName || '-',
                    // 15: el.yardLocation || '-',
                    10: actionButtons(el, index, { editEnable: false, moreEnable: true })
                }
            })
        }
    }

    const pageChange = (pageNo, limit = PaginationDefaultLimit) => {
        fetchAppts(searchStr, pageNo, limit)
    }

    const downloadData = (type) => {

        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        let options = {};
        options = {
            "CRUDTYPE": "S", "WhId": selectedWarehouse.ID, limit: null, pageNo: 1, "fromDate": start !== "Invalid date" ? start : null,
            "endDate": end !== "Invalid date" ? end : null, "searchParam": searchStr,
            trailerType: currentFilterSelectedTrailerType?.name?.includes('All') ? null : currentFilterSelectedTrailerType?.name,
            memo: currentFilterSelectedMemo?.name?.includes('All') ? null : currentFilterSelectedMemo?.name,
            "transType": currentFilterSelectedTransType?.name === AppConstant.commonStrings.inBoundLoadTitle ? 'Load' : currentFilterSelectedTransType?.name === AppConstant.commonStrings.otherLoad ? 'Trailer' : "load','Trailer"
        }
        let payload = {
            subDomain: 'DriverArrival',
            downloadType: type,
            fileName: 'DriverArrival-' + userAccountDetails?.UserName,
            options: options
        }

        dispatch({ type: sagaActions.DOWNLOAD_REPORT_COMMON, payload });
    }

    const TUSDatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <button className="customDatePickerInput" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const selectedDateValue = () => {
        return changeDateToGivenFormat(getCurrentDate(), AppConstant.commonStrings.MM_DD_YYYY_dateFormat)
    }
    
    const onInputChangeHandler = (e) => {
        if (e.length >= 3) {
            dispatch({
                type: sagaActions.GET_STATE_WITH_ZIPCODE, payload: {
                    Text: e,
                    type: 'G'
                }
            })
        }
    }

    /** Drowdown change handler */
    const onChangeValue = (props) => {
        // console.log("🚀 ~ onChangeValue ~ props:", props)
        // setSelectedState(props)
    }

    const formatCreateLabel = (props) => { };

    // Form Field List Inbound/All load
    const formFieldListLoad = [
        {
            name: "poId",
            type: "text",
            size: "col-sm-6",
            position: 1,
            placeholder: '',
            title: AppConstant.smartScheduler.poSection.poNumTitle,
            formValidation: {
                required: currentFilterSelectedTransType.name === AppConstant.commonStrings.inBoundLoadTitle ? true : false,
            },
            // disabledField: Object.keys(editData).length ? true : false
            isEditDisable: true,
            // hideElement:currentFilterSelectedTransType.name===AppConstant.commonStrings.otherLoad?true:false
        },
        // {
        //     name: "poStatus",
        //     type: "select",
        //     size: "col-sm-6",
        //     position: 2,
        //     title: AppConstant.driver.poStatus,
        //     formValidation: {
        //         required: false,
        //     },
        //     getSelectData: getPOStatusList,
        // },
        {
            name: "poType",
            type: "select",
            size: "col-sm-6",
            position: 2,
            title: AppConstant.driver.poType,
            formValidation: {
                required: false,
            },
            getSelectData: getPOTypeList,
        },
        {
            size: "col-sm-6 mt-1",
            name: "checkindateandtime",
            type: "datetime-local",
            customDatePickerConfig: {
                calendarType: 'Today',
                placeholder: '',
                selectedDate: startDateState,
                maxDate: 'NA',
                name: AppConstant.reports.gaurdReport.checkin,
                customInput: <TUSDatePickerInput />,
            },
            position: 3,
            value: startDateState,
            title:
                AppConstant.reports.gaurdReport.checkin,
            formValidation: {
                required: true,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "checkoutDateAndTime",
            type: "datetime-local",
            customDatePickerConfig: {
                calendarType: 'Today',
                placeholder: '',
                selectedDate: endDateState,
                startDate: startDateState,
                maxDate: 'NA',
                name: AppConstant.reports.gaurdReport.checkout,
                customInput: <TUSDatePickerInput />,
            },
            position: 4,
            value: endDateState,
            title: AppConstant.reports.gaurdReport.checkout,
            formValidation: {
                required: false,
            }
        },
        {
            name: "DFirstName",
            type: "text",
            size: "col-sm-6",
            position: 5,
            title: AppConstant.reports.ssFinanceReport.firstName,
            formValidation: {
                required: true,
            }
        },
        {
            name: "DlastName",
            type: "text",
            size: "col-sm-6",
            position: 6,
            title: AppConstant.reports.ssFinanceReport.lastName,
            formValidation: {
                required: true,
            }
        },
        {
            name: "DPhoneNo",
            type: "text",
            size: "col-sm-2 itemsCasesParentContainer",
            position: 7,
            title: AppConstant.driver.dPhone,
            formValidation: {
                required: false,
            }
        },
        {
            name: "DLicenceNo",
            type: "text",
            size: "col-sm-2 itemsCasesParentContainer",
            position: 8,
            title: AppConstant.driver.dLicence,
            formValidation: {
                required: false,
            }
        },
        {
            name: "shipmentId",
            type: "text",
            size: "col-sm-6 ms-4",
            position: 9,
            title: AppConstant.driver.vendor,
            formValidation: {
                required: false,
            }
        },
        {
            name: "carrierCode",
            type: "text",
            size: "col-sm-6",
            position: 10,
            title: AppConstant.driver.carrierCode,
            formValidation: {
                required: false,
            },
        },
        {
            name: "originLocation",
            type: "select_with_input_search_add_button_withoutcreate",
            size: "col-sm-4 mb-3 fs-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: AppConstant.driver.originLocation,
            position: 11,
            title: AppConstant.driver.originLocation,
            customStylesLight: customStylesLight,
            placeholderText: "Enter zip code",
            onInputChange: onInputChangeHandler,
            onChange: onChangeValue,
            defaultValue: !_.isEmpty(selectedState)
                ? selectedState
                : "Enter zip code",
            isLoading: false,
            isClearable: true,
            isSearchable: true,
            options: stateData,
            formatCreateLabel: formatCreateLabel,
            loadingMessage: "Please wait loading....",
            formValidation: {
                required: false,
            },
        },
        {
            name: "destination",
            type: "text",
            size: "col-sm-6",
            position: 12,
            title: AppConstant.driver.destination,
            formValidation: {
                required: false,
            },
            isEditDisable: true
        },
        {
            name: "trailerType",
            type: "radio",
            size: "col-sm-6 radioButtonText",
            position: 13,
            title: 'Trailer Type:',

            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: AppConstant.driver.trailerTypeRadioBtn.Dropped,
                value: AppConstant.driver.trailerTypeRadioBtn.Dropped
            },
            {
                label: AppConstant.driver.trailerTypeRadioBtn.Live,
                value: AppConstant.driver.trailerTypeRadioBtn.Live
            },
                // {
                //     label: "Neither",
                //     value: "Neither"
                // }
            ]

        },
        {
            name: "trailerName",
            type: "text",
            size: "col-sm-6",
            position: 14,
            title: AppConstant.driver.trailerName,
            formValidation: {
                required: false,
            }
        },
        {
            size: "col-sm-6",
            name: "trailerNumber",
            type: "text",
            position: 15,
            title: AppConstant.driver.trailerNumber,
            formValidation: {
                required: false,
            },
            isEditDisable: false
        },
        {
            name: "yardLocation",
            type: "select",
            size: "col-sm-6",
            position: 16,
            title: AppConstant.driver.yardLocation,
            formValidation: {
                required: false,
            },
            getSelectData: getYardLocationList,
        },
        {
            name: "memo",
            type: "text",
            size: "col-sm-6",
            position: 17,
            title: AppConstant.driver.comment,
            formValidation: {
                required: false,
            },
            // getSelectData: getMemoList
        },
    ]
    // Form Field List Other Load
    const formFieldListTrailer = [
        {
            size: "col-sm-6",
            name: "trailerNumber",
            type: "text",
            position: 1,
            title: AppConstant.driver.trailerNumber,
            formValidation: {
                required: false,
            },
            isEditDisable: false
        },
        // {
        //     name: "poStatus",
        //     type: "select",
        //     size: "col-sm-6",
        //     position: 2,
        //     title: AppConstant.driver.poStatus,
        //     formValidation: {
        //         required: false,
        //     },
        //     getSelectData: getPOStatusList,
        // },
        {
            name: "poType",
            type: "select",
            size: "col-sm-6",
            position: 2,
            title: AppConstant.driver.poType,
            formValidation: {
                required: false,
            },
            getSelectData: getPOTypeList,
        },
        {
            size: "col-sm-6 mt-1",
            name: "checkindateandtime",
            type: "datetime-local",
            customDatePickerConfig: {
                calendarType: 'Today',
                placeholder: '',
                selectedDate: startDateState,
                maxDate: 'NA',
                name: AppConstant.reports.gaurdReport.checkin,
                customInput: <TUSDatePickerInput />,
            },
            position: 3,
            value: startDateState,
            title:
                AppConstant.reports.gaurdReport.checkin,
            formValidation: {
                required: true,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "checkoutDateAndTime",
            type: "datetime-local",
            customDatePickerConfig: {
                calendarType: 'Today',
                placeholder: '',
                selectedDate: endDateState,
                startDate: startDateState,
                maxDate: 'NA',
                name: AppConstant.reports.gaurdReport.checkout,
                customInput: <TUSDatePickerInput />,
            },
            position: 4,
            value: endDateState,
            title: AppConstant.reports.gaurdReport.checkout,
            formValidation: {
                required: false,
            }
        },
        {
            name: "DFirstName",
            type: "text",
            size: "col-sm-6",
            position: 5,
            title: AppConstant.reports.ssFinanceReport.firstName,
            formValidation: {
                required: true,
            }
        },
        {
            name: "DlastName",
            type: "text",
            size: "col-sm-6",
            position: 6,
            title: AppConstant.reports.ssFinanceReport.lastName,
            formValidation: {
                required: true,
            }
        },
        {
            name: "DPhoneNo",
            type: "text",
            size: "col-sm-2 itemsCasesParentContainer",
            position: 7,
            title: AppConstant.driver.dPhone,
            formValidation: {
                required: false,
            }
        },
        {
            name: "DLicenceNo",
            type: "text",
            size: "col-sm-2 itemsCasesParentContainer",
            position: 8,
            title: AppConstant.driver.dLicence,
            formValidation: {
                required: false,
            }
        },
        {
            name: "shipmentId",
            type: "text",
            size: "col-sm-6 ms-4",
            position: 9,
            title: AppConstant.driver.vendor,
            formValidation: {
                required: false,
            }
        },
        {
            name: "carrierCode",
            type: "text",
            size: "col-sm-6",
            position: 10,
            title: AppConstant.driver.carrierCode,
            formValidation: {
                required: false,
            },
        },
        {
            name: "originLocation",
            type: "select_with_input_search_add_button_withoutcreate",
            size: "col-sm-4 mb-3 fs-6",
            parentClass: "inputTitle",
            componentClassName: "mt-1 inputDiv border border-1 rounded-1 border-dark",
            inputTitle: AppConstant.driver.originLocation,
            position: 11,
            title: AppConstant.driver.originLocation,
            customStylesLight: customStylesLight,
            placeholderText: "Enter zip code",
            onInputChange: onInputChangeHandler,
            onChange: onChangeValue,
            defaultValue: !_.isEmpty(selectedState)
                ? selectedState
                : "Enter zip code",
            isLoading: false,
            isClearable: true,
            isSearchable: true,
            options: stateData,
            formatCreateLabel: formatCreateLabel,
            loadingMessage: "Please wait loading....",
            formValidation: {
                required: false,
            },
        },
        {
            name: "destination",
            type: "text",
            size: "col-sm-6",
            position: 12,
            title: AppConstant.driver.destination,
            formValidation: {
                required: false,
            },
            isEditDisable: true
        },
        {
            name: "trailerType",
            type: "radio",
            size: "col-sm-6 radioButtonText",
            position: 13,
            title: 'Trailer Type:',

            formValidation: {
                required: true,
            },
            radiconfig: [{
                label: AppConstant.driver.trailerTypeRadioBtn.Dropped,
                value: AppConstant.driver.trailerTypeRadioBtn.Dropped
            },
            {
                label: AppConstant.driver.trailerTypeRadioBtn.Live,
                value: AppConstant.driver.trailerTypeRadioBtn.Live
            },
                // {
                //     label: "Neither",
                //     value: "Neither"
                // }
            ]

        },
        {
            name: "trailerName",
            type: "text",
            size: "col-sm-6",
            position: 14,
            title: AppConstant.driver.trailerName,
            formValidation: {
                required: false,
            }
        },
        {
            name: "yardLocation",
            type: "select",
            size: "col-sm-6",
            position: 15,
            title: AppConstant.driver.yardLocation,
            formValidation: {
                required: false,
            },
            getSelectData: getYardLocationList,
        },
        {
            name: "memo",
            type: "text",
            size: "col-sm-6",
            position: 16,
            title: AppConstant.driver.comment,
            formValidation: {
                required: false,
            },
            // getSelectData: getMemoList
        },
    ]



    const cancelbtndata = {
        show: false,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };


    const getSummitedFormData = (data) => {
        dispatch({ type: sagaActions.DRIVER_REPORT_EDIT_LOADER, payload: true })
        const start = convertGmtToDateRange(dateRangeObj).start;
        const end = convertGmtToDateRange(dateRangeObj).end;
        // const selectedDock = (_.find(dockListArr, { ID: data.ProduceType }));
        if (!_.isEmpty(data)) {
            const editData1 = {
                "poId": data.poId || "",
                "poStatus": data.poStatus || "",
                "poType": data.poType || "",
                "checkindateandtime": data?.checkindateandtime ? moment(data.checkindateandtime).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss) : "",
                // moment(data.checkindateandtime).tz(selectedWarehouse.WarehouseRegion).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "checkoutDateAndTime": data?.checkoutDateAndTime ? moment(data.checkoutDateAndTime).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss) : "",
                "DFirstName": data.DFirstName || "",
                "DlastName": data.DlastName || "",
                "DPhoneNo": data.DPhoneNo || "",
                "DLicenceNo": data.DLicenceNo || "",
                "shipmentId": data.shipmentId || "",
                "carrierCode": data.carrierCode || "",
                "originLocation": typeof data.originLocation === 'string' ? data.originLocation : data.originLocation?.label,
                "destination": selectedWarehouse?.Name || "",
                "trailerType": data.trailerType || "",
                "trailerName": data.trailerName || "",
                "trailerNumber": data.trailerNumber || "",
                "yardLocation": data.yardLocation || "",
                "memo": data.memo || "",
                "createdon": data.createdon || "",
                "createdby": data.createdby || "",
                "updatedby": cu_id || "",
                "updatedon": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss) || "",
                idTruck: data.idTruck || "",
                "transType": data.TransType || ""
            }
            let apiParams = {
                "CRUDTYPE": "E", "WhId": selectedWarehouse.ID, limit: PaginationDefaultLimit, pageNo: driverData.pageNo || 1, "fromDate": start !== "Invalid date" ? start : null,
                "endDate": end !== "Invalid date" ? end : null,
                "searchParamAcutal": searchStr,
                trailerTypeAcutal: currentFilterSelectedTrailerType?.name?.includes('All') ? null : currentFilterSelectedTrailerType?.name,
                memoAcutal: currentFilterSelectedMemo?.name?.includes('All') ? null : currentFilterSelectedMemo?.name,
                "transTypeAcutal": currentFilterSelectedTransType?.name === AppConstant.commonStrings.inBoundLoadTitle ? 'Load' : currentFilterSelectedTransType?.name === AppConstant.commonStrings.otherLoad ? 'Trailer' : "load','Trailer",
                reloadCRUD: 'S',
                ...editData1
            }
            dispatch({
                type: sagaActions.DRIVER_REPORT_EDIT_IN_OUT, payload: apiParams
            })
        }
    };


    const getFormFieldData = (data, name) => {
        if (name === 'custom-date-picker') {
            setSelectedDate(changeDateToGivenFormat(data, AppConstant.commonStrings.MM_DD_YYYY_dateFormat))
        }
    };

    const closeCurrentpopup = () => {
        setShowModal(false);
    }

    const createForm = () => {
        return (
            <CustomDynamicForm
                formFieldList={currentFilterSelectedTransType.name === AppConstant.commonStrings.inBoundLoadTitle ? formFieldListLoad.slice(0, -1) : currentFilterSelectedTransType.name === AppConstant.commonStrings.otherLoad ? formFieldListTrailer : formFieldListLoad}
                sort={true}
                cancelBtn={null}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={editLoading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };


    const showEditReportPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: AppConstant.commonStrings.edit + " " + AppConstant.reports.gaurdReport.checkin,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: false,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const openCreateForm = () => {
        setShowModal(true);
        setParticularFieldValue({ 'ZipCode': selectedWarehouse.Zip });
        setEditData({});
    }

    // Dropdown function to render filters(Trailer Type, Memo/Trailer type)
    const showCustomDropDownForFilters = () => {
        const filterDataArrTrailerType = []
        const filterDataArrMemoList = [{ name: 'All Memo' }]
        const filterDataArrTransTypeList = []
        trailerType && trailerType.length && trailerType.forEach(el => {
            let obj = {
                name: el?.value
            }
            filterDataArrTrailerType.push(obj)
        });
        // const memoList = allConfigration['GuardMemoList']
        // memoList && memoList.length && memoList.slice(1, -1).split(',').forEach(el => {
        //     let obj = {
        //         name: el
        //     }
        //     filterDataArrMemoList.push(obj)
        // });
        const filterDataArrTransType = []
        TransTypeList && TransTypeList.length && TransTypeList.forEach(el => {
            let obj = {
                name: el
            }
            filterDataArrTransType.push(obj)
        });
        return (
            <div className='d-flex justify-content-between align-items-center'>
                <div className='mx-2 w-200px'>
                    <CustomDropDown themeSelected={themeSelected} data={filterDataArrTransType} placeHolder={currentFilterSelectedTransType.name} onSelect={(event) => {
                        setCurrentFilterSelectedTransType(event)
                    }} keyStr={'name'} />
                </div>
                <div className='mx-2 w-100px'>
                    <CustomDropDown themeSelected={themeSelected} data={filterDataArrTrailerType} placeHolder={currentFilterSelectedTrailerType.name} onSelect={(event) => {
                        setCurrentFilterSelectedTrailerType(event)
                    }} keyStr={'name'} />
                </div>
                {/* {currentFilterSelectedTransType.name === AppConstant.commonStrings.inBoundLoadTitle ? '' : <div className='mx-2 w-100px'>
                    <CustomDropDown themeSelected={themeSelected} data={filterDataArrMemoList} placeHolder={currentFilterSelectedMemo.name} onSelect={(event) => {
                        setCurrentFilterSelectedMemo(event)
                    }} keyStr={'name'} />
                </div>} */}
            </div>
        )
    }

    /**
    * 
    * @returns  drilldown table title array
    */
    const getTitleDataHandler = () => {
        if (currentFilterSelectedTransType?.name === AppConstant.commonStrings.inBoundLoadTitle) {
            return apptsTitleDD
        } else if (currentFilterSelectedTransType?.name === AppConstant.commonStrings.otherLoad) {
            return apptsTitleDDTrailer
        } else {
            return apptsTitleDDAllLoads
        }
    }


    return (
        <div className=''>
            <div className='d-flex justify-content-between my-2 mx-3'>
                <div className='fw-bold'>{'Driver Arrivals'}</div>
                <div className='d-flex justify-content-end'>
                    <div className='me-2'>
                        {showCustomDropDownForFilters()}
                    </div>
                    <div className='w-75'>
                        <WarehouseSearch
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleStr}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                    <div className='d-flex align-items-center mx-3'>
                        <DownloadSectionBE isSchedulePro={false} downloadData={downloadData} isLoading={isDownloadLoading} isDisabled={isDownloadLoading} />
                    </div>
                </div>

            </div>
            <div className='driver-arrival-list' ref={divRef}>
                {<DrilldownTableSS
                    themeSelected={themeSelected}
                    bodyData={allApptsArr?.length ? formatListDataDrillDown(allApptsArr) : []}
                    titleData={getTitleDataHandler()}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    arrangeOrder={driverData?.arrangeOrder}
                    arrangeKey={driverData?.arrangeKey}
                    drillDownReport={driverData?.data?.data || []}
                    initiateDrillDownReport={showLoading}
                    loaderArray={12}
                    sortingAction={sagaActions.SORT_DRIVER_ARRIVAL_TABLE}
                    subRoot={{ pathName: 'allApptsList' }}
                    showUTCTime={false}
                // apiTime={selectedWarehouse.TimeZone}

                />}
            </div>
            {driverData && driverData?.data?.data?.length ?
                <div className='f-14 detention-status-pagination'>
                    <CustomPaginationSS
                        pageNo={driverData?.data?.pageNo}
                        pageSize={driverData?.data?.limit}
                        first={driverData?.data?.first}
                        last={driverData?.data?.last}
                        totalElements={driverData?.data?.totalElements}
                        totalPages={driverData?.data?.totalPages}
                        pageChange={(pageNo, limit) => { pageChange(pageNo, limit) }}
                    />
                </div> : ''
            }
            {showEditReportPopup()}
            {showMoreDetailsPopup()}
        </div>
    )
}

export default DriverList