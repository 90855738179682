import React, { useState } from 'react'
import _ from 'lodash'
import './SSAppointmentTracker.css'
import reschedule from '../../../../../../../Assests/Images/reschedule.png'
import completed from '../../../../../../../Assests/Images/completed.png'
import schedule from '../../../../../../../Assests/Images/schedule.png'
import CommonChart from '../../../../../../LandingPage/Components/Dashboard/DefaultDashboard/Graphs/CommonChart'
import NoDataTracker from '../Common/NoDataTracker/NoDataTracker'
import { displayLoader } from '../../../../../../../Assests/Utility'
export const SSAppointmentTracker = (props) => {
    const { apptTrackerDonutData, appointmentTrackerData, appointmentTrackerDataError, trackerBtnHandler, themeSelected } = props;
    const appointmentTrackerBtn = [{ text: 'Past Month', type: 'P' }, { text: 'Current Month', type: 'C' }, { text: 'Upcoming Month', type: 'U' }];
    const [selectedMonthTracker, setSelectedMonthTracker] = useState({ text: 'Current Month', type: 'C' })

    return (
        <div className='tracker-containre w-100'>
            <div className='p-3'>
                <i className="fa-regular fa-calendar"></i> Appointment Tracker
            </div>
            <hr />
            <div className='d-flex justify-content-around mt-2'>
                {
                    appointmentTrackerBtn.map(el => {
                        return <button className={el.type === selectedMonthTracker?.type ? 'filter-btn active' : 'filter-btn'} onClick={(e) => {
                            setSelectedMonthTracker(el)
                            trackerBtnHandler(e, el)
                        }
                        } >{el?.text}</button>
                    })
                }
            </div>
            {
                 !_.isEmpty(appointmentTrackerData) && appointmentTrackerData?.TotalCount > 0 && _.size(apptTrackerDonutData?.series) ? <>

                    <div id="chart">
                        {

                            <div className="responsive-chart"> <CommonChart data={apptTrackerDonutData} /> </div>
                        }
                    </div>
                    <div className='p-3 d-flex f-16 flex-column justify-content-between tracker-counts'>
                        <div className='d-flex justify-content-between'>
                            <p><img src={completed} alt='completed' /> Completed</p>
                            <div className='tracker-completed d-flex justify-content-center align-items-center appt-tracker-status'>{appointmentTrackerData?.CompletedCount}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p><img src={schedule} alt='Scheduled' /> Scheduled</p>
                            <div className='tracker-scheduled d-flex justify-content-center align-items-center appt-tracker-status'>{appointmentTrackerData?.ScheduledCount}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p><img src={reschedule} alt='Rescheduled' /> Rescheduled <span className='sub-text'>(to next month)</span></p>
                            <div className='tracker-rescheduled d-flex justify-content-center align-items-center appt-tracker-status'>{appointmentTrackerData?.RescheduledCount}</div>
                        </div>
                    </div></> : appointmentTrackerDataError ? <NoDataTracker /> : <div className='w-100 p-3'>
                    {displayLoader(themeSelected, 60, 5)}</div>
            }

        </div>
    )
}
